import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { TableColumns } from '@mediaseal-webui/types/generic';

export const profileUserHeader: TableColumns[] = [
    { label: 'ID', dataKey: 'id', flexGrow: 0, disableSort: true, order: 'asc' },
    { flexGrow: 0.8, width: 75, label: 'First Name', disableSort: true, dataKey: 'firstName', order: 'asc' },
    { flexGrow: 0.8, width: 75, label: 'Last Name', disableSort: true, dataKey: 'lastName', order: 'asc' },
    {
        flexGrow: 2,
        flexShrink: 0,
        width: 150,
        label: 'Email Address',
        dataKey: 'email',
        order: 'asc',
        disableSort: true,
        hidden: ['xs', 'sm'] as Breakpoint[]
    },
    {
        flexGrow: 1,
        flexShrink: 0,
        label: 'Created',
        dataKey: 'createDate',
        order: 'desc',
        disableSort: true,
        hidden: ['xs', 'sm'] as Breakpoint[]
    },
    {
        flexGrow: 1,
        flexShrink: 0,
        label: 'Last Modified',
        dataKey: 'updateDate',
        order: 'asc',
        disableSort: true,
        hidden: ['xs', 'sm', 'md'] as Breakpoint[]
    },
    { label: 'Status', flexGrow: 0, width: 90, dataKey: 'active', order: 'desc', disableSort: true },
    {
        flexGrow: 1,
        flexShrink: 0,
        order: 'asc',
        label: 'Actions',
        dataKey: 'id',
        justifyContent: 'flex-end',
        disableSort: true,
        hidden: ['xs'] as Breakpoint[]
    }
];
export const profileUserHeaderWithoutActions = profileUserHeader.slice(0, -1);

export const profileGenericHeader: TableColumns[] = [
    { label: 'ID', dataKey: 'id', flexGrow: 0, order: 'asc', disableSort: true },
    { flexGrow: 2, label: 'Name', dataKey: 'name', width: 150, display: 'flex', order: 'asc', disableSort: true },
    {
        flexGrow: 1,
        flexShrink: 0,
        label: 'Created',
        disableSort: true,
        dataKey: 'createDate',
        order: 'desc',
        hidden: ['xs', 'sm'] as Breakpoint[]
    },
    {
        flexGrow: 1,
        flexShrink: 0,
        label: 'Last Modified',
        dataKey: 'updateDate',
        disableSort: true,
        order: 'asc',
        hidden: ['xs', 'sm'] as Breakpoint[]
    },
    { label: 'Status', dataKey: 'active', disableSort: true, flexGrow: 0, width: 90, flexShrink: 0, order: 'desc' },
    { flexGrow: 1, flexShrink: 0, label: 'Actions', dataKey: 'id', hidden: ['xs'] as Breakpoint[], order: 'desc', disableSort: true }
];

export const selectableUserHeader: TableColumns[] = [
    { label: 'checkbox', flexGrow: 0, disableSort: true, width: 50, order: 'desc' },
    { label: 'ID', dataKey: 'id', flexGrow: 0, order: 'asc', hidden: ['xs'] as Breakpoint[] },
    { flexGrow: 0.8, width: 75, label: 'First Name', dataKey: 'firstName', order: 'asc' },
    { flexGrow: 0.8, width: 75, label: 'Last Name', dataKey: 'lastName', order: 'asc' },
    {
        flexGrow: 2,
        flexShrink: 0,
        width: 150,
        label: 'Email Address',
        dataKey: 'email',
        order: 'asc',
        hidden: ['xs', 'sm'] as Breakpoint[]
    },
    {
        flexGrow: 1,
        flexShrink: 0,
        label: 'Created',
        dataKey: 'createDate',
        order: 'desc',
        hidden: ['xs', 'sm'] as Breakpoint[]
    },
    {
        flexGrow: 1,
        flexShrink: 0,
        label: 'Last Modified',
        dataKey: 'updateDate',
        order: 'asc',
        hidden: ['xs', 'sm', 'md'] as Breakpoint[]
    },
    { label: 'Status', flexGrow: 0, width: 90, dataKey: 'active', disableSort: true, hidden: ['xs'] as Breakpoint[], order: 'desc' }
];

export const profileGenericHeaderWithoutActions = profileGenericHeader.slice(0, -1);

export const selectableGenericHeader: TableColumns[] = [
    { label: 'checkbox', flexGrow: 0, disableSort: true, width: 50, order: 'desc' },
    { label: 'ID', dataKey: 'id', flexGrow: 0, order: 'asc', hidden: ['xs'] as Breakpoint[] },
    { flexGrow: 2, label: 'Name', dataKey: 'name', width: 150, display: 'flex', order: 'asc' },
    {
        flexGrow: 1,
        flexShrink: 0,
        label: 'Created',
        dataKey: 'createDate',
        order: 'desc',
        hidden: ['xs', 'sm'] as Breakpoint[]
    },
    {
        flexGrow: 1,
        flexShrink: 0,
        label: 'Last Modified',
        dataKey: 'updateDate',
        order: 'desc',
        hidden: ['xs', 'sm'] as Breakpoint[]
    },
    {
        label: 'Status',
        dataKey: 'active',
        disableSort: true,
        flexGrow: 0,
        width: 90,
        flexShrink: 0,
        hidden: ['xs'] as Breakpoint[],
        order: 'desc'
    }
];
