import { definitions } from '@mediaseal-webui/types/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TokensService } from '../../../../services/src';
import { SpringBootResponse } from '../../../../types';

export const fetchTokens = createAsyncThunk('tokens/fetchTokens', async (service: TokensService, { rejectWithValue }) => {
    try {
        const { data } = await service.fetchTokens();
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const searchTokens = createAsyncThunk(
    'tokens/searchTokens',
    async ({ keyword, service }: { keyword: string; service: TokensService }, { rejectWithValue }) => {
        try {
            const { data } = await service.searchTokens(keyword);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const createTokens = createAsyncThunk(
    'tokens/createTokens',
    async ({ body, service }: { body: definitions['TokenPostDTO']; service: TokensService }, { rejectWithValue }) => {
        try {
            const response = await service.createToken(body);
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export interface TokensState extends SpringBootResponse<definitions['Page«TokenGetDTO»'][]> {
    pending: boolean;
    searchValue: string;
}

const initialState: TokensState = {
    content: [],
    searchValue: '',
    page: {
        totalElements: 0,
        totalPages: 0
    },
    pending: false
};

export const tokensSlice = createSlice({
    name: 'tokens',
    initialState,
    reducers: {
        setSearchValue: (state, action) => ({
            ...state,
            searchValue: action.payload
        }),
        clearSearchValue: (state) => ({
            ...state,
            searchValue: ''
        }),
        setTokens: (state, action) => ({
            ...state,
            ...action.payload
        }),
        setSelected: (state, action) => ({
            ...state,
            content: state.content.map((item) => (item.id === action.payload.id ? { ...item, selected: true } : item))
        }),
        deselect: (state, action) => ({
            ...state,
            content: state.content.map((item) => (item.id === action.payload.id ? { ...item, selected: false } : item))
        }),
        clearTokens: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTokens.fulfilled, (state, action) => ({
            ...state,
            page: {
                ...state.page,
                ...(action.payload.page ?? {
                    totalElements: 0,
                    totalPages: 0
                })
            },
            content: [...state.content, ...action.payload.content],
            pending: false,
            fulfilled: true
        }));

        builder.addCase(fetchTokens.pending, (state) => ({
            ...state,
            pending: true
        }));

        builder.addCase(searchTokens.fulfilled, (state, action) => {
            const payload = action.payload as definitions['Page«TokenGetDTO»'];

            return {
                ...state,
                content: [...action.payload.content],
                page: {
                    totalElements: payload.totalElements ?? 0,
                    totalPages: payload.totalPages ?? 0
                },
                pending: false,
                fulfilled: true
            };
        });

        builder.addCase(searchTokens.pending, (state) => ({
            ...state,
            pending: true
        }));
        builder.addCase(createTokens.pending, (state, action) => ({
            ...state,
            pending: true
        }));
        builder.addCase(createTokens.fulfilled, (state, action) => ({
            ...state,
            pending: false,
            fulfilled: true,
            tokenId: action.payload.data.apiToken
        }));
    }
});
