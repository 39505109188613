import { Job } from '@mediaseal-webui/types/jobs';

/**
 * returning true === job is readonly
 */
export const validateJob = (job: Job): boolean => {
    if (!job.securityRestrictions || job.securityRestrictions.length === 0) {
        return false;
    }
    if (job.securityRestrictions.every(({ entityId, entityType }) => entityId === 0 && entityType === 0)) {
        return false;
    }
    return true;
};
