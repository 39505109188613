import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getJobGroups, updateJob } from '../../../../actions/jobs';
import { MainTable } from '@mediaseal-webui/components';
import { profileGenericHeader, profileGenericHeaderWithoutActions } from '../../../../data/profile-table-headers';
import { removeEntityAndUpdate, removeDecryptorGroupFromSummary } from '../../../../actions/entity';
import { setConfirmDialogue } from '../../../../actions/misc';
import { hideRemoveGroupConfirmation } from '../../../../constants/preferences';
import { useAuth, useReadOnlyCheck, useTableSort } from '@mediaseal-webui/hooks';
import ActionContainer from '../../../../components/table-components/ActionContainer/ActionContainer';
import { Group } from '@mediaseal-webui/types';
import { GenericEntity } from '@mediaseal-webui/types/schemas';
import { validateJob } from '@mediaseal-webui/utils';
import { InitialState } from '@mediaseal-webui/types/redux';
import { actionableGroupHeader } from 'data';

export const Groups = () => {
    const { filteredDecryptorGroups, profile } = useSelector(({ entity }: InitialState) => entity);
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { isReadOnly } = useReadOnlyCheck(profile, validateJob);
    const [groupsPage, setGroupsPage] = useState(1);
    const auth = useAuth();
    // const isEditAuth = auth.edit || auth.administer
    const isCreateAuth = auth.create || auth.administer;
    const type = profile.type;
    const isOnlineJob = type === 'ONLINE';
    const { order, orderBy, createSortHandler, headCells } = useTableSort({
        cells: profileGenericHeader,
        setPage: setGroupsPage,
        fetchFn: getJobGroups,
        searchValue: ''
    });
    const handleRowClick = ({ id }: { id: GenericEntity['id'] }) => history.push(`/groups/decryptor/${id}/profile`);
    const removeHandler = (group: Group) => () =>
        dispatch(
            setConfirmDialogue({
                icon: 'warning',
                title: 'Remove Group',
                confirmButtonText: 'Remove Group',
                message: 'Are you sure you want to remove this group?',
                action: removeEntityAndUpdate({
                    entity: group,
                    id,
                    removeFn: removeDecryptorGroupFromSummary,
                    updateFn: updateJob
                }),
                memorable: hideRemoveGroupConfirmation
            })
        );
    const handleRemove = useCallback(removeHandler, [dispatch, id]);

    const fetchFn = () => {
        dispatch(getJobGroups({ id, page: 0, firstCall: true, sort: `${orderBy},${order}` }));
    };

    return (
        <div style={{ width: '100%', height: 400 }}>
            <MainTable
                entities={filteredDecryptorGroups}
                searchValue=''
                id='groups-table'
                pageControl={{ page: groupsPage, setPage: setGroupsPage }}
                tableActionsRenderer={(entity: GenericEntity) => (
                    <ActionContainer
                        create={isCreateAuth}
                        disabled={!isOnlineJob || profile.archived || isReadOnly}
                        //@ts-expect-error due to entity is not assignable ts(2345).
                        removeAction={handleRemove(entity)}
                    />
                )}
                // @ts-expect-error due to missing props ts(2322).
                fetchFn={fetchFn}
                sortData={{
                    sortBy: orderBy,
                    sortDirection: order || 'desc',
                    createSortHandler
                }}
                columns={headCells}
                onRowClick={handleRowClick}
                useContainer
                height={600}
                noResultsMessage='No groups are associated with this job'
            />
        </div>
    );
};
