//@ts-ignore
import { useSelector } from 'react-redux';
import { InitialState } from '@mediaseal-webui/types/redux';
import { EncryptFileWithIssues, FileIssue } from '@mediaseal-webui/types/encryptor';

/**
 * Issues to check through in priority order
 */
const OrderedIssues: FileIssue[] = ['AlreadyEncrypted', 'OutputCollision', 'OutputConflict'];

const getIssueFiles = (issues: EncryptFileWithIssues[]): EncryptFileWithIssues[] => {
    const output: EncryptFileWithIssues[] = [];
    OrderedIssues.every((issue) => {
        const files = issues.filter((file) => file.workIssue.errorType === issue);
        if (files.length > 0) {
            output.push(...files);
            return false;
        }
        return true;
    });
    return output;
};

/**
 * Returns an array of files with issues of the highest issue priority
 */
export const useFilesWithIssues = () => {
    const issues: EncryptFileWithIssues[] = useSelector(({ encryptor }: InitialState) => encryptor.issues) ?? [];

    return getIssueFiles(issues);
};
