import React, { useCallback } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import useStyles from './SimpleSelect.styles';
import { SimpleSelectProps } from '.';

const SimpleSelect = ({ options, setter, value, width = 300, label = 'Filter by', disabled, ...others }: SimpleSelectProps) => {
    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = React.useState<number>(0);
    const classes = useStyles();

    const handleChange = useCallback(
        (event: React.ChangeEvent<{ value: unknown }>, child: React.ReactNode) => {
            // const currentOption = options?.find((option: SelectOption) => option.id === (event.target.value as string));
            return setter(options[event.target.value as number]);
        },
        [setter, options]
    );

    React.useEffect(() => {
        if (inputLabel?.current?.offsetWidth) {
            setLabelWidth(inputLabel.current.offsetWidth);
        }
    }, []);

    return (
        <FormControl variant='outlined' className={classes.formControl} style={{ width }}>
            <InputLabel ref={inputLabel} id='simple-select-label'>
                {label}
            </InputLabel>
            <Select
                labelId='simple-select'
                id='simple-select'
                disabled={disabled}
                data-testid='simple-select'
                onChange={handleChange}
                value={value?.id ?? ''}
                labelWidth={labelWidth}
                {...others}>
                {options.map((option) => (
                    <MenuItem role='option' key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SimpleSelect;
