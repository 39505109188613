import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingRight: theme.spacing(3)
    },
    nameContainer: {
        paddingTop: theme.spacing(3)
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    successBorder: {
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: 4
    },
    doneIcon: {
        color: theme.palette.primary.main
    },
    title: {
        marginBottom: theme.spacing(1),
        fontWeight: 'bold'
    },
    inputContainer: {
        marginTop: theme.spacing(2),
        // width: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    tabSection: {
        background: theme.palette.background.default,
        height: theme.sizes.tables.forms,
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: '4px 4px 0 0',
        width: '100%',
        flexWrap: 'unset'
    },
    passwordContainer: {
        paddingBottom: theme.spacing(6),
        paddingTop: theme.spacing(3),
        scrollSnapAlign: 'start'
    },
    passwordInput: {
        paddingTop: theme.spacing(3)
    }
}));

export default useStyles;
