import React from 'react';
import { StyledTag, StyledClose, StyledName, StyledTagValue, TagBody } from './index.styles';
import { IFilterTag } from '@mediaseal-webui/hooks';
export interface FilterTagProps {
    tag: IFilterTag;
    handleClose: () => void;
}
export const FilterTag = ({ tag, handleClose }: FilterTagProps) => {
    return (
        <StyledTag>
            <TagBody>
                <StyledName>{tag.tagName}</StyledName>
                <StyledTagValue>{tag.tagValue}</StyledTagValue>
            </TagBody>
            <StyledClose onClick={handleClose} />
        </StyledTag>
    );
};
