import React, { useEffect, useState } from 'react';
import FormGroup from '../../../utility-components/FormGroup';
import { Checkbox, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import SimpleSelect, { SelectOption } from '../../../utility-components/SimpleSelect';
import { useDispatch } from 'react-redux';
import { ReauthTimeProps } from '.';
import { Actions } from '@mediaseal-webui/components';
import { updateJob } from '../../../../actions/jobs';
import { JobFeature } from '@mediaseal-webui/types/jobs';
import { useParams } from 'react-router';
import { useJobFeatures, useMobile } from '@mediaseal-webui/hooks';
import { ReportingActions } from '@mediaseal-webui/types';

const selectOptions: SelectOption[] = [
    { id: 0, name: '8 Hours', value: 28800 },
    { id: 1, name: '4 Hours', value: 14400 },
    { id: 2, name: '2 Hours', value: 7200 },
    { id: 3, name: '1 Hour', value: 3600 }
];

const ReauthTime = ({ features, active, setterFn, openProps = [false, () => null], rollOver, disabled = false, isOffline = false }: ReauthTimeProps) => {
    const [open, setOpen] = openProps;
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const mobile = useMobile();
    const [selectValue, setSelectValue] = useState<SelectOption>({ id: 0, name: '8 Hours', value: 28800 });
    const { addReauthTime, toggleRollover } = useJobFeatures(features);
    // const checkboxChangeHandler = (value: string) => (event: React.ChangeEvent<HTMLInputElement>) =>
    //     dispatch(setterFn({
    //         features: event.target.checked ? addReauthBehaviour() : removeReauthBehaviour()
    //     }));

    const handleTimeOutSelect = (option: SelectOption | ReportingActions) => {
        setSelectValue(option as SelectOption);
        dispatch(
            setterFn({
                features: addReauthTime(option as SelectOption)
            })
        );
    };
    const handleClose = () => setOpen(false);

    useEffect(() => {
        const current = features?.find((feature: JobFeature) => feature.type === 'ReauthBehaviour');
        const option = selectOptions.find((option: SelectOption) => option.value === current?.data.reauthorisationTime);
        if (option) {
            setSelectValue(option);
        }
    }, [features]);
    const rollOverHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setterFn({ features: toggleRollover(event) }));
    };
    const handleSave = () => {
        dispatch(updateJob(id));
        setOpen(false);
    };
    const isRolloverChecked = () => features?.some((feature: JobFeature) => feature.data?.rollover);
    const FormBody = () => (
        <FormGroup subtitle='Reauthenticate files every:'>
            <SimpleSelect
                disabled={!active || isOffline || disabled}
                label='Select Time'
                value={selectValue}
                options={selectOptions}
                setter={handleTimeOutSelect}
                width='100%'
            />
            <div style={{ marginLeft: -10, marginTop: 10 }}>
                <Checkbox id='rolloverCheck' disabled={!active || isOffline || disabled} checked={isRolloverChecked()} onChange={rollOverHandler} />
                <Typography variant='body2' color='textPrimary' style={{ display: 'inline' }}>
                    Background authentication
                </Typography>
            </div>
        </FormGroup>
    );
    return openProps[0] ? (
        <Dialog fullScreen={mobile} open={open}>
            <DialogContent>
                <FormBody />
            </DialogContent>
            <DialogActions>
                <Actions saveFn={handleSave} closeFn={handleClose} />
            </DialogActions>
        </Dialog>
    ) : (
        <FormBody />
    );
};

export default ReauthTime;
