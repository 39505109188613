import { SET_ENCRYPTION_CANCELLED } from '@mediaseal-webui/constants';
import { EncryptFile } from '@mediaseal-webui/types/encryptor';
import { ThunkResult } from '@mediaseal-webui/types/redux';
import { findFile } from '@mediaseal-webui/utils';
import { RootState } from 'packages/online/src/store';
import { processEncryptionWork } from '../processes';
/**
 *
 * @param id Encryption file Id
 * @returns void
 *
 * Triggered by QT when a file has been cancelled
 *
 */
export const encryptionWorkCancelled =
    (id: EncryptFile['id']): ThunkResult<void> =>
    (dispatch, getState: () => RootState) => {
        const { encryptionWork, issues } = getState().encryptor;
        console.log(`Received Signal encryptionWorkCancelled from QT with ID : ${id}`);

        const file = findFile(encryptionWork, id);
        const issue = issues?.find((item) => item.id === id);
        const totalIssues = issues?.length ?? 0;

        if (!issue && !file) {
            console.log(`No file found in issues with ID : ${id}`);
        }

        if (issues && issue && totalIssues) {
            dispatch({ type: 'REMOVE_ENC_FILE_ISSUE', payload: issue });
            if (totalIssues === 1) {
                dispatch({ type: 'ALL_ENC_FILE_ISSUES_RESOLVED' });
                // dispatch(processFileQueue());
                window.EncryptionWebInterface.clearDragDropBlock();
                dispatch(processEncryptionWork());
            }
        }

        if (file) {
            dispatch({ type: SET_ENCRYPTION_CANCELLED, payload: { id } });
        }
        dispatch({
            type: 'SET_SNACKBAR',
            payload: {
                warning: true,
                message: `File: ${file?.inputFileName} cancelled`
            }
        });
    };
