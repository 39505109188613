import { combineReducers } from 'redux';
import {
    ADD_DRAFT_INVITE,
    DELETE_DRAFT_INVITE,
    UPDATE_DRAFT_INVITE,
    CLEAR_DRAFT_INVITES,
    DELETE_INVITE,
    RESEND_INVITE,
    CLEAR_FILTERED_INVITES,
    SET_INVITE_PAGE_COUNT,
    SET_TOTAL_INVITES,
    SET_INVITE_PAGE_SEARCH_VALUE,
    CLEAR_INVITE_PAGE_SEARCH_VALUE,
    SET_INVITE_ORDER,
    SET_PENDING_INVITES,
    SET_REJECTED_INVITES,
    SET_ADDITIONAL_PENDING_INVITES,
    SET_ADDITIONAL_REJECTED_INVITES,
    SET_TOTAL_PENDING_INVITES,
    SET_TOTAL_REJECTED_INVITES,
    SET_REJECTED_PAGE_COUNT,
    SET_PENDING_PAGE_COUNT
} from '../../constants/invitations';
import { seedData } from '../../helpers';
import { CLEAR_SEARCHES } from '../../constants/misc';

const draftInvites = (invitations = [], { type, payload }) => {
    switch (type) {
        case ADD_DRAFT_INVITE:
            return [...invitations, payload];
        case DELETE_DRAFT_INVITE:
            return invitations.filter((invite) => invite.email !== payload.email);
        case UPDATE_DRAFT_INVITE:
            return invitations.map((invite) => (invite.email === payload.email ? payload : invite));
        case CLEAR_DRAFT_INVITES:
            return [];
        default:
            return invitations;
    }
};

const pendingInvites = (filteredInvites = seedData(), { type, payload }) => {
    switch (type) {
        case SET_PENDING_INVITES:
            return payload;
        case SET_ADDITIONAL_PENDING_INVITES:
            return [...filteredInvites, ...payload];
        case CLEAR_FILTERED_INVITES:
            return seedData();
        case DELETE_INVITE:
            return filteredInvites.filter((invite) => invite.email !== payload.email);
        case RESEND_INVITE:
            return filteredInvites.map((invite) => (invite.email === payload.email ? payload : invite));
        default:
            return filteredInvites;
    }
};

const rejectedInvites = (filteredInvites = seedData(), { type, payload }) => {
    switch (type) {
        case SET_REJECTED_INVITES:
            return payload;
        case SET_ADDITIONAL_REJECTED_INVITES:
            return [...filteredInvites, ...payload];
        case CLEAR_FILTERED_INVITES:
            return seedData();
        case DELETE_INVITE:
            return filteredInvites.filter((invite) => invite.email !== payload.email);
        case RESEND_INVITE:
            return filteredInvites.map((invite) => (invite.email === payload.email ? payload : invite));
        default:
            return filteredInvites;
    }
};

const invitePageSearchValue = (value = '', { type, payload }) => {
    switch (type) {
        case SET_INVITE_PAGE_SEARCH_VALUE:
            return payload;
        case CLEAR_INVITE_PAGE_SEARCH_VALUE:
        case CLEAR_SEARCHES:
            return '';
        default:
            return value;
    }
};

const inviteSort = (order = { sortDir: 'asc', sortBy: 'id' }, { type, payload }) => {
    switch (type) {
        case SET_INVITE_ORDER:
            return {
                sortDir: payload.sortDir,
                sortBy: payload.sortBy
            };
        default:
            return order;
    }
};

const totalInvites = (state = 0, { type, payload }) => {
    switch (type) {
        case SET_TOTAL_INVITES:
            return payload;
        default:
            return state;
    }
};

const totalPendingInvites = (state = 0, { type, payload }) => {
    switch (type) {
        case SET_TOTAL_PENDING_INVITES:
            return payload;
        default:
            return state;
    }
};

const totalRejectedInvites = (state = 0, { type, payload }) => {
    switch (type) {
        case SET_TOTAL_REJECTED_INVITES:
            return payload;
        default:
            return state;
    }
};

const totalPages = (pages = 0, { type, payload }) => {
    switch (type) {
        case SET_INVITE_PAGE_COUNT:
            return payload;
        default:
            return pages;
    }
};

const totalRejectedPages = (pages = 0, { type, payload }) => {
    switch (type) {
        case SET_REJECTED_PAGE_COUNT:
            return payload;
        default:
            return pages;
    }
};
const totalPendingPages = (pages = 0, { type, payload }) => {
    switch (type) {
        case SET_PENDING_PAGE_COUNT:
            return payload;
        default:
            return pages;
    }
};

export default combineReducers({
    draftInvites,
    pendingInvites,
    rejectedInvites,
    inviteSort,
    totalPages,
    invitePageSearchValue,
    totalInvites,
    totalPendingInvites,
    totalRejectedInvites,
    totalRejectedPages,
    totalPendingPages
});
