import React, { useState, useEffect } from 'react';
import { GenericEntity } from '@mediaseal-webui/types/schemas';
import { useSelector } from 'react-redux';
import { InitialState, SummaryState } from '@mediaseal-webui/types/redux';

export interface UseSummaryFilterProps {
    entities: GenericEntity[];
    type: keyof SummaryState;
}
export const useSummaryFilter = ({ entities, type }: UseSummaryFilterProps) => {
    const [filteredEntities, setFilteredEntities] = useState<GenericEntity[]>([]);
    const { summary } = useSelector((state: InitialState) => state.entity);

    useEffect(() => {
        if (entities.length > 0) {
            setFilteredEntities(filter(summary, type)(entities));
        } else {
            setFilteredEntities([]);
        }
    }, [summary, entities]);
    return {
        filteredEntities
    };
};

const filter =
    (summary: SummaryState, type: keyof SummaryState) =>
    (entities: GenericEntity[]): GenericEntity[] =>
        entities.filter((entity: GenericEntity): boolean => !(summary[type] ?? []).some((sum: GenericEntity) => sum.id === entity.id));
