import { makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    card: {
        minHeight: 200,
        maxHeight: 200,
        minWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: 5,
        color: theme.palette.primary.main,
        textAlign: 'center',
        border: '1px solid #e2e2e1',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:hover': {
            borderColor: theme.palette.primary.main,
            cursor: 'pointer',
            boxShadow: `${fade(theme.palette.primary.main, 0.5)} 0 0 0 2px`,
            color: theme.palette.primary.main
            // boxShadow: ' 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
        }
    },
    badge: {
        width: 24,
        height: 24,
        borderRadius: '50%'
    },
    isSelected: {
        boxShadow: `${fade(theme.palette.primary.main, 1)} 0 0 0 2px`,
        opacity: 1
    },
    badgeOuter: {
        width: '100%',
        height: '100%',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:hover': {
            boxShadow: `${fade(theme.palette.primary.main, 0.5)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
            cursor: 'pointer'
        }
    }
}));

export default useStyles;
