import React, { useState, useEffect, useRef, HTMLProps } from 'react';
import { TextField, IconButton, FormLabel, InputAdornment, Tooltip } from '@material-ui/core';
import { Edit as EditIcon, Check as SaveIcon, Close as CloseIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setEdit } from '@mediaseal-webui/actions';
import { InitialState, Dispatchable } from '@mediaseal-webui/types/redux';
import { useParams } from 'react-router';
// @ts-ignore
import debounce from 'debounce';
import useStyles, { StyledTextField } from './EditableEntityTitle.styles';
import clsx from 'clsx';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { useTheme } from 'styled-components';
import { useTooltipStyles } from '../FormField/FormField.styles';

interface EditableEntityTitleProps {
    value: string;
    setterFn: (args: any) => Dispatchable;
    label?: string;
    field: string;
    isReadOnly?: boolean;
    fullWidth?: boolean;
    showInputBackground?: boolean;
    inputClasses?: string;
    validation?: () => (value: string) => {
        passes: boolean;
        message?: string | JSX.Element;
    };
    alwaysShowTooltip?: boolean;
    className?: string;
    maxWidth?: string;
    name?: string;
    id?: string;
}

const useFocus = () => {
    const htmlElRef = useRef<HTMLInputElement>(null); // as React.MutableRefObject<HTMLInputElement>;
    return [htmlElRef];
};

interface ActionsProps {
    saveFn: (event: React.SyntheticEvent) => void;
    closeFn: () => void;
    disableSave?: boolean;
    classes?: {
        root: string;
    };
}

export const Actions = ({ saveFn, closeFn, disableSave = false, classes }: ActionsProps) => {
    const styles = useStyles();
    return (
        <div className={styles.actionsContaienr}>
            <IconButton
                disabled={disableSave}
                className={clsx(classes?.root, {
                    [styles.actionButtons]: true,
                    [styles.btnSuccess]: true
                })}
                data-testid='save'
                onClick={saveFn}>
                <SaveIcon />
            </IconButton>
            <IconButton
                className={clsx(classes?.root, {
                    [styles.actionButtons]: true,
                    [styles.btnError]: true
                })}
                data-testid='close'
                onClick={closeFn}>
                <CloseIcon />
            </IconButton>
        </div>
    );
};

const VALUE_LENGTH = 30;

export const EditableEntityTitle = ({
    value,
    setterFn,
    label,
    field,
    isReadOnly,
    fullWidth,
    alwaysShowTooltip,
    showInputBackground,
    className = '',
    inputClasses,
    validation,
    maxWidth,
    ...others
}: EditableEntityTitleProps) => {
    const [textValue, setTextValue] = useState<string>(value);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [valid, setValid] = useState<{
        passes: boolean;
        message?: string | JSX.Element;
    } | null>(null);
    const [tooltipShow, setTooltipShow] = useState<boolean>(!!alwaysShowTooltip);
    const classes = useStyles({ disabled });

    const theme = useTheme();

    const tooltipClasses = useTooltipStyles();
    const [inputRef] = useFocus();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const isFetching = useSelector((state: InitialState) => state.misc.isFetching) as boolean;
    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextValue(event.target.value);
        validation ? setValid(validation()(event.target.value)) : setValid({ passes: true });
    };
    const setFocus = (): void => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    const handleEditClick = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        setDisabled(false);
        setTooltipShow(false);
        debounce(setFocus, 300)();
    };
    const handleCloseClick = () => {
        setTextValue(value);
        setDisabled(true);
    };

    const handleSave = () => {
        setLoading(true);
        setDisabled(true);
        dispatch(setEdit({ [field]: textValue }));
        dispatch(setterFn(id));
    };
    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        valid?.passes && handleSave();
    };
    const handleMouseEnter = () => disabled && !loading && debounce(() => setTooltipShow(true), 300)();
    const handleMouseLeave = () => disabled && !loading && setTimeout(() => setTooltipShow(false), 5000);

    useEffect(() => {
        !isFetching && setLoading(false);
    }, [isFetching]);

    useEffect(() => {
        setTextValue(value);
        return () => {
            setTextValue('');
            setDisabled(true);
            setLoading(false);
        };
    }, [value]);

    return (
        <div
            className={clsx('editableInput', {
                [classes.editableRoot]: true,
                [classes.almostFullWidth]: textValue?.length > VALUE_LENGTH,
                [classes.withLabelRoot]: label,
                [className]: className
            })}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onTouchStart={handleMouseEnter}>
            <form className={classes.form} onSubmit={handleSubmit}>
                {label && (
                    <div className={classes.withLabelSpan}>
                        <FormLabel color='primary' className={classes.label}>
                            {label}
                        </FormLabel>
                        {tooltipShow && !isReadOnly && (
                            <IconButton data-testid='editIcon' onClick={handleEditClick} size='small' className={clsx(classes.actionButtons, classes.editIcon)}>
                                <EditIcon classes={{ root: label && classes.smallIcons }} data-testid='edit-icon' />
                            </IconButton>
                        )}
                    </div>
                )}
                <Tooltip title={valid?.message || ''} placement='bottom' open={!valid?.passes && !disabled} arrow classes={tooltipClasses}>
                    <StyledTextField
                        isEnabled={!disabled}
                        width={textValue?.length ?? 0}
                        maxWidth={maxWidth}
                        inputProps={{
                            className: clsx(inputClasses, {
                                [classes.text]: true,
                                [classes.enabledInput]: !disabled || showInputBackground
                            })
                        }}
                        inputRef={inputRef}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <>
                                        {valid?.passes === false && !disabled ? (
                                            <ErrorIcon
                                                data-testid='errorIcon'
                                                data-testid='errorIcon'
                                                fontSize='small'
                                                color='error'
                                                className={classes.icon}
                                            />
                                        ) : (
                                            valid?.passes &&
                                            !disabled && (
                                                <CheckCircle data-testid='successIcon' fontSize='small' className={`${classes.icon} ${classes.success}`} />
                                            )
                                        )}
                                    </>
                                </InputAdornment>
                            )
                        }}
                        value={textValue}
                        disabled={disabled}
                        onChange={handleTextChange}
                        {...others}
                    />
                </Tooltip>

                {!label && tooltipShow && !isReadOnly && (
                    <IconButton
                        onClick={handleEditClick}
                        data-testid='editButton'
                        size='small'
                        className={clsx(classes.actionButtons, classes.editIcon, 'editableInput')}>
                        <EditIcon data-testid='edit-icon' />
                    </IconButton>
                )}

                {!disabled && (
                    <Actions disableSave={!valid?.passes} classes={{ root: label ? classes.smallerBtns : '' }} saveFn={handleSave} closeFn={handleCloseClick} />
                )}
            </form>
        </div>
    );
};

EditableEntityTitle.defaultProps = {
    field: 'name'
} as Partial<EditableEntityTitleProps>;
