import { SET_ACTIVE_JOB_ID, SET_SECURITY_OPTIONS } from '@mediaseal-webui/constants';
export * from './removeJobFeature';
export const setSecurityOptions = (options: any) => ({
    type: SET_SECURITY_OPTIONS,
    payload: options
});

export const setActiveJobId = (id: number) => ({
    type: SET_ACTIVE_JOB_ID,
    payload: id
});
