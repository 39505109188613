import React from 'react';
import { Pass, Fail } from '..';

/**
 * Validates a given value's length is between the 2 arguments
 *
 * @param () Function from redux to access state
 * @param value String
 * @return { passes: Bool, message: String? }
 */
export const nameLength = () => (value: string) => {
    const min = 3;
    const max = 64;
    if (!value) {
        return Fail('Please enter a name');
    }
    if (value.length < min || value.length > max) {
        return Fail(`Name must be between ${min} and ${max} characters`);
    }
    return Pass;
};

const ValidationMessage = (
    <div>
        <p>File / Folder prefix may only include:</p>
        <ul>
            <li>A - Z / [0 - 9] / - _</li>
        </ul>
    </div>
);
export const prefixCheck = () => (value: string) => {
    // const regex = /[:\/\\]/g
    const regex = /[^A-Za-z0-9-_]/i;
    if (regex.test(value)) {
        console.log('fail');
        return {
            passes: false,
            message: ValidationMessage
        };
    }
    return {
        passes: true,
        message: ''
    };
};
