import { CircularProgress } from '@material-ui/core';
import React, { FC, HTMLProps, useEffect, useState } from 'react';
import {
    StyledCircularProgress,
    StyledNetworkAlert,
    StyledErrorIcon,
    StyledMessage,
    StyledSuccessIcon,
    StyledCheckMark,
    useStyles,
    ProgressContainer
} from './NetworkAlert.style';
/**
 * ErrorAlert - Needs Renaming to ConnectionAlert
 *  IDEA: Need a progress spinner that spins while offline... once connected we need to animate it to a checkmark and change the background
 *  gradient to a green success.. will look slick AF
 */
export interface NetworkAlertProps extends HTMLProps<HTMLDivElement> {
    offline: boolean;
    online: boolean;
    message: string;
}

export const NetworkAlert: FC<NetworkAlertProps> = React.memo(({ offline, online, message }) => {
    const classes = useStyles();
    return (
        <StyledNetworkAlert offline={offline} online={online}>
            {offline ? <StyledErrorIcon /> : <StyledSuccessIcon />}
            <StyledMessage>{message}</StyledMessage>
            <ProgressContainer>{offline ? <CircularProgress className={classes.progress} /> : <StyledCheckMark />}</ProgressContainer>
        </StyledNetworkAlert>
    );
});
