import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Send } from '@material-ui/icons';
import ScrollToTopButton from '../../../components/utility-components/ScrollToTopButton';
import CreateForm from '../../../components/jobs-components/creation-view-components/CreateForm/CreateForm';
import AdvancedOptions from '../../../components/jobs-components/creation-view-components/AdvancedOptions/AdvancedOptions';
import { Header, HeaderItemsConfig, HeaderItemStateKeys } from '../../../components/layout-components';
import { fetchGroups, clearGroups, fetchUsers, clearUsers } from '@mediaseal-webui/actions';
import { validateCreateJob, resetSecurityOptions } from '../../../actions/jobs';
import SummaryList from '../../../components/utility-components/SummaryList';
import { useForm, useMobile, useJobFeatures, useEntityCreation } from '@mediaseal-webui/hooks';
import SecurityModal from '../../../components/jobs-components/SecurityModal/SecurityModal';
import { clearEntity, setCreate } from '../../../actions/entity';
import useStyles from './Create.styles';
import { Content, SubmitButton as Submit } from '@mediaseal-webui/components';
import { addDays } from 'date-fns';
import { InitialState } from '@mediaseal-webui/types/redux';
import { titlesSlice } from 'reducers/titles';

const Create = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const mobile = useMobile();
    const { valid } = useEntityCreation();
    const { submit } = useForm();

    const { name, title, type, decryptorUsers, decryptorGroups, authMode, authMethod, features, batched, contactSelected, notesSelected, template } =
        useSelector(({ entity: { create } }: InitialState) => create);
    const { showSecurityHelpModal, securityOptions } = useSelector((state: InitialState) => state.jobs);
    const [open, setOpen] = useState(false);
    const { hasQrCode, hasSecurePlayer, isReauthEnabled } = useJobFeatures(features);
    const { clearTitles } = titlesSlice.actions;
    useEffect(() => {
        const args = {
            keyword: '',
            page: 0,
            isSort: true,
            firstCall: true
        };
        dispatch(fetchUsers(args));
        dispatch(fetchGroups(args));
        return () => {
            dispatch(resetSecurityOptions());
            dispatch(clearEntity());
        };
    }, [dispatch, clearTitles]);

    const handleSubmit = () => {
        submit(() => {
            dispatch(validateCreateJob(history));
        });
        setOpen(!open);
    };

    const SubmitButton = (props: object) => <Submit title='Save Job' aria-label='Post Job' Icon={Send} {...props} disabled={!valid} />;

    const headerItems: HeaderItemsConfig = {
        stateKey: 'create' as HeaderItemStateKeys,
        decUsers:
            decryptorUsers && decryptorUsers?.length > 0
                ? {
                      onClick: () => null
                  }
                : undefined,
        decGroups:
            decryptorGroups && decryptorGroups?.length > 0
                ? {
                      onClick: () => null
                  }
                : undefined,
        title: title?.name
            ? {
                  onClick: () => null
              }
            : undefined,
        authType: type
            ? {
                  title: authMethod
              }
            : undefined,
        jobNotes: notesSelected ? {} : undefined,
        jobContactInfo: contactSelected ? {} : undefined,
        sunriseSunset: !securityOptions.disableSunset ? {} : undefined,
        reauth: isReauthEnabled() ? {} : undefined,
        securePlayer: hasSecurePlayer() ? {} : undefined,
        qrCode: hasQrCode() ? {} : undefined,
        batched: batched ? {} : undefined,
        template: template ? {} : undefined
    };

    return (
        <div className={classes.viewRoot}>
            <Header
                defaultName='Create a Job'
                name={name}
                date={new Date()}
                onSubmit={handleSubmit}
                ActionButton={SubmitButton}
                breadcrumbProps={{
                    links: [{ id: 0, name: 'Jobs', to: '/jobs' }],
                    text: [{ id: 0, name: 'Create' }]
                }}
                headerItems={headerItems}
            />

            <Content>
                {(classes) => (
                    <div className={`${classes.wrapper} ${classes.cols} ${classes.clearfix}`}>
                        <CreateForm />
                        <div className={classes.summary}>
                            <AdvancedOptions authMode={authMode} sunsetProps={{ sunrise: new Date(), sunset: addDays(new Date(), 30) }} setterFn={setCreate} />
                            <SummaryList open={open} setOpen={setOpen} subtitleEntity='job' />
                        </div>
                    </div>
                )}
            </Content>

            {showSecurityHelpModal && <SecurityModal open={showSecurityHelpModal} />}

            {!mobile && <ScrollToTopButton scrollStepInPx='50' delayInMs={10} />}
        </div>
    );
};

export default Create;
