import { Dispatchable, ThunkResult } from '@mediaseal-webui/types/redux';
import { EncryptionWork } from '@mediaseal-webui/types/encryptor';

export const cancelAllWork =
    (currentWork: EncryptionWork): ThunkResult<Dispatchable> =>
    (dispatch) => {
        // get all non processed meats
        const filesToCancel = currentWork.fileList.filter((file) => !file.processed).map((file) => file.id);
        window.EncryptionWebInterface.cancelEncryptionWorkList(filesToCancel);
        return dispatch({ type: 'CANCELLED_ALL_WORK' });
    };
