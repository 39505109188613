import React from 'react';
import { ISerializedPermissions } from '@mediaseal-webui/utils';
import DisplayPermissions from 'components/utility-components/DisplayPermissions/DisplayPermissions';
interface PermissionsTabs {
    id: number;
    label: string;
    attrId: string;
    component: React.FC;
}

interface TabsProps {
    permissions: ISerializedPermissions;
    tokenPermissions?: string[];
}

export const tabs = (props: TabsProps): PermissionsTabs[] => [
    {
        id: 0,
        label: 'Jobs/Titles',
        attrId: 'job-titles-btn',
        component: () => <DisplayPermissions groups={props.permissions?.jobsTitles ?? []} allowedPermissions={props.tokenPermissions} />
    },
    {
        id: 1,
        label: 'Encryptor',
        attrId: 'encryptor-permissions-btn',
        component: () => <DisplayPermissions groups={props.permissions?.encryptor ?? []} allowedPermissions={props.tokenPermissions} />
    },
    {
        id: 2,
        label: 'Decryptor',
        attrId: 'decryptor-permissions-btn',
        component: () => <DisplayPermissions groups={props.permissions?.decryptor ?? []} allowedPermissions={props.tokenPermissions} />
    },
    {
        id: 3,
        label: 'Settings',
        attrId: 'settings-permissions-btn',
        component: () => <DisplayPermissions groups={props.permissions?.settings ?? []} allowedPermissions={props.tokenPermissions} />
    },
    {
        id: 4,
        label: 'Reporting',
        attrId: 'reporting-permissions-btn',
        component: () => <DisplayPermissions groups={props.permissions?.reporting ?? []} allowedPermissions={props.tokenPermissions} />
    }
];
