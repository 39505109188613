import { STORE_SERVER_SETTINGS } from '@mediaseal-webui/constants';
import { SettingsPayload } from './updateServerSettings';
export interface StoreServerSettingsArgs {
    encryptionPrefix: string;
    studioName: string;
}
const createPayload = (settings: SettingsPayload[]) =>
    settings.reduce(
        (acc, curr) => ({
            ...acc,
            [curr.name]: curr.value
        }),
        {}
    );

export const storeServerSettings = (settings: SettingsPayload[]) => {
    const payload = createPayload(settings);
    return { type: STORE_SERVER_SETTINGS, payload };
};
