import { InitialState } from '@mediaseal-webui/types/redux';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledSlider, useStyles, useTooltipStyles, useLabelStyles } from './PerformanceSlider.style';
import { updateApplicationSettings } from '@mediaseal-webui/actions';
import { marks } from './PerformanceSlider.data';
import { Tooltip } from '@material-ui/core';

export const CustomLabel = ({ value, children, open, ...others }: { value: number; children: any; open: boolean }) => {
    const tooltipClasses = useTooltipStyles();
    const labelClasses = useLabelStyles();
    const LabelValue = ({ value }: { value: number }) => (
        <span className={labelClasses.contentContainer}>
            <p className={labelClasses.desc}>{marks.find((mark) => mark.value === value)?.tooltip}</p>
        </span>
    );
    return (
        <Tooltip open={open} classes={tooltipClasses} enterTouchDelay={0} placement='top' title={<LabelValue value={value} />} {...others} arrow>
            {children}
        </Tooltip>
    );
};
export const PerformanceSlider = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const handleChange = (event: React.ChangeEvent<{}>, value: number | number[]) => dispatch(updateApplicationSettings(value));
    const { encPerformanceLevel } = useSelector((state: InitialState) => state.settings.application);
    return (
        <StyledSlider
            classes={{
                mark: classes.mark,
                track: classes.track,
                thumb: classes.thumb
            }}
            defaultValue={encPerformanceLevel}
            onChangeCommitted={handleChange}
            ValueLabelComponent={CustomLabel}
            aria-labelledby='performance-slider'
            marks={marks}
            valueLabelDisplay='auto'
            step={null}
            min={0}
            max={10}
        />
    );
};
