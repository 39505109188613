import React, { useCallback, ChangeEvent, useEffect } from 'react';
import { Tabs, Tab, debounce } from '@material-ui/core';
import { HeaderTabProps } from '..';
import clsx from 'clsx';
import useStyles from '../Header.styles';

export const HeaderTabs = (props: HeaderTabProps) => {
    const classes = useStyles();
    const { tabs, tabControl, wideTabs, setTabValue, tabValue } = props;

    const handleChange = (index: number) => (event: React.SyntheticEvent) => tabControl[index].handler();

    const tabChange = (event: ChangeEvent<{}>, newValue: number) => setTabValue(newValue);

    const changeHandler = useCallback(tabChange, [setTabValue]);

    const listener = debounce((event: KeyboardEvent) => {
        event.preventDefault();
        if (event.key === 'ArrowRight') {
            setTabValue((value) => (value < tabs.length - 1 ? value + 1 : value));
        }
        if (event.key === 'ArrowLeft') {
            setTabValue((value) => (value > 0 ? value - 1 : value));
        }
    }, 100);

    useEffect(() => {
        window.addEventListener('keydown', listener);
        return () => {
            window.removeEventListener('keydown', listener);
            // window.removeEventListener('scroll', handleScroll);
        };
        //eslint-disable-next-line
    }, []);

    return (
        <div tabIndex={1} className={clsx(classes.headerTabsRoot, { wideTabs })}>
            <Tabs
                variant='fullWidth'
                selectionFollowsFocus
                aria-label='tabselect tabs'
                data-testid='tabselect-tab'
                id='tabselect-tab'
                onChange={changeHandler}
                value={tabValue}
                classes={{ indicator: classes.tabIndicator }}>
                {tabs.map((tab, index) => (
                    <Tab disableRipple disableFocusRipple label={tab.label} id={tab.attrId} key={tab.id} value={index} onClick={handleChange(index)} />
                ))}
            </Tabs>
        </div>
    );
};
