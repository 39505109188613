import styled, { css } from 'styled-components';

const centered = css`
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
`;

const aboveStyle = css`
    bottom: 70px;

    &:after {
        border-top: 5px solid #d53e3e;
        bottom: -5px;
    }
`;

const belowStyle = css`
    top: 70px;

    &:after {
        border-bottom: 5px solid #d53e3e;
        top: -5px;
    }
`;

interface TooltipRootProps {
    show?: boolean;
    above?: boolean;
}
export const TooltipRoot = styled.div<TooltipRootProps>(
    ({ show, theme, above }) => css`
        border-radius: 5px;
        padding: 0px 8px;
        z-index: 10001;
        text-align: center;
        max-width: 300px;
        background: #ff6666;
        color: white;
        position: absolute;
        ${centered};
        visibility: ${show ? 'visible' : 'hidden'};
        opacity: ${show ? 1 : 0};
        transition: all 0.35s linear;
        font-family: 'Raleway', sans-serif;
        font-size: 10pt;

        &:after {
            content: ' ';
            width: 0;
            height: 0;
            z-index: 2;
            visibility: ${show ? 'visible' : 'hidden'};
            opacity: ${show ? 1 : 0};
            position: absolute;
            ${centered};
            transition: all 0.35s linear;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
        }

        ${above ? aboveStyle : belowStyle}
    `
);

export const TooltipText = styled.p``;
