import React, { useCallback, useEffect, useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import { resetConfirmDialogue } from '../../../actions/misc';
import useStyles from './ConfirmDialogue.styles';
import { ConfirmDialogueProps, ConfirmDialogIconProps } from '.';
import { AnimatedArchiveIcon, AnimatedRestoreIcon, AnimatedActivateIcon, InfoIcon, AnimatedDeactivateIcon, WarningIcon, DeleteIcon } from './Icons';

const Icon = ({ icon, loading, complete }: ConfirmDialogIconProps) => {
    switch (icon) {
        case 'info':
            return <InfoIcon />;
        case 'archive':
            return <AnimatedArchiveIcon {...{ loading, complete }} />;
        case 'restore':
            return <AnimatedRestoreIcon {...{ loading, complete }} />;
        case 'reactivate':
            return <AnimatedActivateIcon {...{ loading, complete }} />;
        case 'deactivate':
            return <AnimatedDeactivateIcon {...{ loading, complete }} />;
        case 'warning':
            return <WarningIcon />;
        case 'delete':
            return <DeleteIcon />;
        default:
            // just a backup in case someone ruins my beautiful typing
            // ^^^ Not correct in approach: This would be better if we had an icon return by default (info for example) instead of a blank <div />
            return <div />;
    }
};

const ConfirmDialogue = memo((props: ConfirmDialogueProps) => {
    const { message = '', action = {}, open = false, setOpen = () => {}, confirmButtonText = 'Confirm', title, loadingText, icon = 'warning' } = props;

    const dispatch = useDispatch();
    const classes = useStyles(props);

    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState(false);
    const [titleText, setTitleText] = useState(title);
    const [elipsis, setElipsis] = useState('');

    const handleClose = () => {
        dispatch(resetConfirmDialogue());
        setOpen(false);
    };

    const confirmAction = () => {
        /** If loading option, then delay execution to display in the UI */
        if (loadingText) {
            setLoading(true);
            setTimeout(() => {
                dispatch(action);
                setComplete(true);
                setTimeout(() => {
                    dispatch(resetConfirmDialogue());
                    setOpen(false);
                }, 500);
            }, 700);
        } else {
            dispatch(resetConfirmDialogue());
            dispatch(action);
            setOpen(false);
        }
    };

    const handleConfirm = useCallback(confirmAction, [action, dispatch, loadingText, setOpen]);

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (loading) {
            setTitleText(loadingText);

            interval = setInterval(() => {
                setElipsis((e) => (e === '...' ? '' : e + '.'));
            }, 200);
        }

        return () => {
            interval && clearInterval(interval);
        };
    }, [loading, loadingText]);

    const warning = ['delete', 'warning'].includes(icon ?? '');

    return (
        <Dialog open={open} classes={{ paper: classes.dialogRoot }}>
            <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
                <div className='titleContainer'>
                    {icon && <Icon icon={icon} loading={loading} complete={complete} />}
                    <span>
                        <Typography color='textPrimary' variant='h6' aria-label={titleText}>
                            {titleText}
                        </Typography>
                        <Typography color='textPrimary' className={clsx('elipsis', { loading })}>
                            {elipsis}
                        </Typography>
                    </span>
                </div>
            </DialogTitle>

            <DialogContent classes={{ root: clsx(classes.content, { loading }) }}>
                <DialogContentText id='confirm-dialog-message' aria-label={message}>
                    {message}
                </DialogContentText>
            </DialogContent>

            <DialogActions classes={{ root: clsx(classes.actions, { loading }) }}>
                <Button
                    id='cancel-button'
                    data-testid='cancel'
                    aria-label='cancel'
                    onClick={handleClose}
                    variant='contained'
                    disableElevation
                    disabled={loading}>
                    Cancel
                </Button>

                <Button
                    id='confirm-button'
                    data-testid='confirm'
                    aria-label={`confirm ${confirmButtonText}`}
                    onClick={handleConfirm}
                    color='primary'
                    disabled={loading}
                    disableElevation
                    variant='contained'>
                    {confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default ConfirmDialogue;
