import { makeStyles } from '@material-ui/core';

// import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
    widgetItem: {
        '& .fileAvatar': {
            width: '10%'
        },
        '& .fileActions': {
            width: '35%',
            justifyContent: 'flex-end',
            display: 'flex'
        },
        '& .progress': {
            display: 'inline-flex',
            width: '90%'
        },
        '& .encryptionInfoItem': {
            width: '100%',
            maxWidth: 285,
            paddingLeft: 10
        },
        '& .progressContainer': {
            display: 'flex',
            alignItems: 'flex-start'
        },
        '& .percentage': {
            margin: 0,
            width: '10%',
            paddingLeft: 15,
            lineHeight: 0,
            display: 'inline-flex'
        },
        height: 50,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        '&:nth-child(even)': {
            borderTop: `2px solid ${theme.palette.secondary.superlight}`
        }
    },
    avatar: {
        background: theme.palette.progress.main,
        width: 30,
        height: 30
    },
    summary: {
        margin: 0,
        padding: 0,
        width: '75%',
        display: 'inline-flex',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    }
}));

export default useStyles;
