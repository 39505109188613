import React from 'react';
import { Breadcrumbs as BCrumbs, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import useStyles from './Breadcrumbs.styles';
import { useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import { BreadcrumbsProps } from '.';
import { InitialState } from '@mediaseal-webui/types/redux';
import { AccountWidget } from './AccountWidget';
export const Breadcrumbs = ({ links, text, displayingText, isFetching, ...others }: BreadcrumbsProps) => {
    const classes = useStyles();

    const { isFetching: _fetching } = useSelector(({ misc }: InitialState) => misc);

    return (
        <div className={classes.breadCrumbsContainer}>
            <BCrumbs className={classes.breadCrumbs} aria-label='breadcrumb' separator='/' {...others}>
                {links.map((link) => (
                    <NavLink key={link.id} className={classes.link} to={link.to} data-testid='primary-breadcrumb'>
                        {link.name}
                    </NavLink>
                ))}
                {text.map(
                    (item) =>
                        item.name && (
                            <Typography color='textPrimary' key={item.id} data-testid='secondary-breadcrumb'>
                                {item.name}
                            </Typography>
                        )
                )}
                {displayingText &&
                    (isFetching || _fetching ? (
                        <Skeleton width={100} height={12.5} />
                    ) : (
                        <Typography
                            className={classes.displayingText}
                            color='textSecondary'
                            variant='subtitle2'
                            id='header-date'
                            data-testid='secondary-breadcrumb'>
                            {displayingText}
                        </Typography>
                    ))}
            </BCrumbs>
            <AccountWidget />
        </div>
    );
};
