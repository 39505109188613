import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Tooltip, Badge, Typography, Card } from '@material-ui/core';
import clsx from 'clsx';
import Help from '@material-ui/icons/HelpOutline';
import Done from '@material-ui/icons/Done';
import useStyles from './Option.styles';
import { useDispatch } from 'react-redux';
import { openJobHelpModal } from '../../../../../actions/jobs';

const Option = ({ children, selected, overlap, title, ...others }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(openJobHelpModal(true));
    };
    return (
        <Grid item xs={12} lg={4}>
            <Tooltip placement='top-end' title='Whats this?'>
                <Badge
                    color='primary'
                    className={`${classes.badgeOuter}`}
                    overlap={overlap}
                    classes={{
                        badge: classes.badge // class name, e.g. `classes-nesting-root-x`
                    }}
                    badgeContent={
                        selected ? <Done className={classes.iconBadge} /> : <Help onClick={handleClick} className={`${classes.iconBadge} whats-this-badge`} />
                    }>
                    <Card {...others} className={clsx({ [classes.isSelected]: selected, isSelected: selected }, classes.card)}>
                        <Grid alignItems='center' container>
                            <Grid item xs={12} md={12} lg={12}>
                                {children}
                                <Typography color='textPrimary' variant='body1'>
                                    {title}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Badge>
            </Tooltip>
        </Grid>
    );
};

Option.propTypes = {
    overlap: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]).isRequired
};

Option.defaultProps = {
    selected: undefined
};

export default Option;
