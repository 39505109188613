import React from 'react';
import { InitialState } from '@mediaseal-webui/types/redux';
import { useSelector } from 'react-redux';
import { EditableHeaderItem, EditableHeaderChildProps, BadgeIcon, HeaderItem, GenericHeaderItemProps } from '../..';
import { Attachment as AttachmentIcon } from '@material-ui/icons';

export const FilesHeaderItems = ({ onClick, isFetching, tooltipOpen }: GenericHeaderItemProps) => {
    const { files, totalFiles } = useSelector(({ entity: { profile } }: InitialState) => profile);

    return files && files.length > 0 ? (
        <EditableHeaderItem title='file' open={tooltipOpen} total={totalFiles} onViewClick={onClick}>
            {({ hovering }: EditableHeaderChildProps) => (
                <HeaderItem
                    hovering={hovering}
                    Icon={() => <BadgeIcon Icon={AttachmentIcon} isHovered={hovering} content={totalFiles} />}
                    isFetching={isFetching}
                    id='job-files'
                />
            )}
        </EditableHeaderItem>
    ) : null;
};
