import React, { useMemo } from 'react';
import { tabs } from './tabs';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import TabSection from 'components/utility-components/TabSection';
import { useAppSelector } from 'store';

interface PermissionsModalProps {
    open: boolean;
    handleClose: () => void;
    tokenPermissions?: string[];
}

const PermissionsModal = ({ open, handleClose, tokenPermissions }: PermissionsModalProps): JSX.Element => {
    const permissions = useAppSelector((state) => state.groups.permissions);
    const permissionTabs = useMemo(() => tabs({ permissions: permissions, tokenPermissions: tokenPermissions }), [permissions, tokenPermissions]);

    return (
        <Dialog open={open} onClose={handleClose} aria-label='Permissions list'>
            <DialogTitle>Token Permissions</DialogTitle>
            <DialogContent>
                {/* @ts-expect-error due to missing properties "ts(2739)". */}
                <TabSection tabs={permissionTabs} variant='scrollable' />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='primary' autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export { PermissionsModal };
