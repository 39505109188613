import React, { useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { TabPanel } from '../../../utility-components/TabPanel/TabPanel';
import { RecipientSelection } from 'components/table-components/RecipientSelection';
import { RecipientGroupSelection } from 'components/table-components/RecipientGroupSelection';

const TabArea = () => {
    const [value, setValue] = useState(0);
    const handleChange = (_, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Tabs value={value} onChange={handleChange} indicatorColor='primary' textColor='secondary' variant='fullWidth'>
                <Tab label='Users' id='usersTab' data-testid='usersTab' value={0} />
                <Tab label='Groups' id='groupsTab' data-testid='groupsTab' value={1} />
            </Tabs>
            <TabPanel value={value} index={0} component={RecipientSelection} />
            <TabPanel value={value} index={1} component={RecipientGroupSelection} />
        </>
    );
};

export default React.memo(TabArea);
