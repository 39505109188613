import React, { useCallback, useContext } from 'react';
import { Column, TableCellProps } from 'react-virtualized';
import clsx from 'clsx';
import { InfiniteScrollTable } from '..';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { useBreakPointListener, useMobile } from '@mediaseal-webui/hooks';
import useStyles from './MainTable.styles';
import { GenericEntity } from '@mediaseal-webui/types/schemas';
import { MainTableProps } from '.';
import { cellRenderer } from './CellRenderer';
import { headerRenderer } from './HeaderRenderer';
import { CalenderContext } from '../InfiniteScrollTable';

export const MainTable = React.memo((props: MainTableProps) => {
    const {
        entities,
        searchValue,
        totalPages = 0,
        pageControl = {
            page: 0,
            setPage: () => null
        },
        onRowClick = () => null,
        entityLink,
        fetchFn,
        sortData = {
            sortBy: 'createDate',
            sortDirection: 'desc',
            createSortHandler: (data) => (event) => undefined
        },
        columns,
        filterFetch,
        noResultsMessage,
        isFetching = false,
        tableActionsRenderer = () => null,
        ...others
    } = props;
    const classes = useStyles();
    const { sortBy, createSortHandler } = sortData;
    const { page, setPage } = pageControl;
    const rowGetter = ({ index }: { index: number }) => entities[index]; // returns current index from Table
    // const { isFetching } = useSelector((state: InitialState) => state.misc)
    const rowClickHandler = (entity: GenericEntity) => onRowClick(entity); // returns the rowData obj from Table
    const handleRowClick = useCallback(rowClickHandler, []);
    const { hide } = useBreakPointListener();
    const isMobile = useMobile();
    const { calenderShown } = useContext(CalenderContext);

    /**
     * Render column header with TableSortLabel for sortable columns, using the current sortBy/sortDirection for rendering indicators
     * @private
     */

    return (
        <InfiniteScrollTable
            rowCount={entities.length}
            rowGetter={rowGetter}
            rowHeight={45}
            data={entities}
            pageControl={{ page, setPage }}
            onRowClick={handleRowClick}
            fetchFn={fetchFn}
            totalPages={totalPages}
            rowClassName={({ index }: { index: number }) => (index !== -1 ? clsx([classes.tableRow, classes.tableRowHover, 'tableRow']) : classes.tableRow)}
            entityLink={entityLink}
            columns={columns}
            {...others}>
            {(width: number | string, height: number | string) =>
                columns.map(({ className = null, hidden, dataKey = '', ...other }, index) => {
                    const renderer = (cellRendererProps: TableCellProps) =>
                        cellRenderer({
                            ...cellRendererProps,
                            columns,
                            classes,
                            tableActionsRenderer,
                            isFetching,
                            isMobile,
                            entities,
                            width,
                            height,
                            calenderShown,
                            noResultsMessage
                        });
                    const getWidth = () => (hide.some((bp: Breakpoint) => columns[index].hidden?.includes(bp)) ? 0 : other.width || 90);
                    const flexGrow = () => (hide.some((bp: Breakpoint) => columns[index].hidden?.includes(bp)) ? 0 : other.flexGrow);
                    return (
                        <Column
                            key={index}
                            dataKey={dataKey}
                            headerRenderer={({ dataKey, label }) =>
                                headerRenderer({
                                    dataKey,
                                    label,
                                    columnIndex: index,
                                    classes,
                                    columns,
                                    sortProps: {
                                        sortBy,
                                        createSortHandler
                                    }
                                })
                            }
                            cellRenderer={renderer}
                            className={clsx([classes.flexContainer, others.className])}
                            {...other}
                            width={getWidth()}
                            flexGrow={flexGrow()}
                        />
                    );
                })
            }
        </InfiniteScrollTable>
    );
});
