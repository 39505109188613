import React, { memo } from 'react';
import TableCell from '@material-ui/core/TableCell';
import { Checkbox } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import useStyles from './Check.styles';
import { Dispatchable } from '@mediaseal-webui/types/redux';
import { AnyAction } from 'redux';
import { SelectableRowData } from '../Table';

export interface CheckProps {
    entity: SelectableRowData;
    selected: boolean;
    selectFn: (entity: SelectableRowData) => Dispatchable | AnyAction;
    deselectFn: (entity: SelectableRowData) => Dispatchable | AnyAction;
}

export const Check = memo(({ entity, selected, selectFn, deselectFn }: CheckProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleChange = (entity: SelectableRowData) => (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        const { checked } = event.target;
        dispatch(checked ? selectFn(entity) : deselectFn(entity));
    };

    return (
        <TableCell component='td' align='center' classes={{ root: classes.tableCell }} scope='row'>
            <Checkbox onChange={handleChange(entity)} checked={selected} data-testid='select-file' />
        </TableCell>
    );
});
