import { Action, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { v4 } from 'uuid';
export const offlineMiddleware: Middleware =
    ({ dispatch, getState }: MiddlewareAPI) =>
    (next: Dispatch) =>
    <A extends Action>(action: A) => {
        const { offline, online } = getState().network.networkState;
        if (action.type === 'ONLINE' && !online) {
            setTimeout(() => {
                // artifical wait for the online alert
                //@ts-expect-error "Property '$msDispatchQueue' does not exist on type 'Window & typeof globalThis'.ts(2339)"
                window.$msDispatchQueue.forEach(({ payload: { fn } }) => {
                    typeof fn === 'function' ? fn(dispatch, getState) : next(fn);
                });
                //@ts-expect-error "Property '$msDispatchQueue' does not exist on type 'Window & typeof globalThis'.ts(2339)"
                window.$msDispatchQueue = [];
            }, 2100);
        }
        if (action && action.type !== 'ONLINE' && offline) {
            //@ts-expect-error "Property '$msDispatchQueue' does not exist on type 'Window & typeof globalThis'.ts(2339)"
            window.$msDispatchQueue.push({
                type: 'OFFLINE_ACTION',
                payload: {
                    id: v4(),
                    fn: action
                }
            });
        } else {
            return next(action);
        }
    };
