import React from 'react';
import useStyles from './UpdateModal.styles';
import { Typography, DialogActions, Dialog, Link } from '@material-ui/core';
import { IOpenProps } from '@mediaseal-webui/types/generic';
import { Dispatchable } from '@mediaseal-webui/types/redux';

export interface UpdateModalProps extends IOpenProps {
    title: string;
    subtitle: string;
    Action?: React.ElementType | null;
}

export const UpdateModal = (props: UpdateModalProps) => {
    const {
        title,
        subtitle,
        Action,
        openProps: [open, setOpen]
    } = props;
    const classes = useStyles();

    return (
        <Dialog id='updateModal' className={classes.modal} open={open}>
            <Typography color='textPrimary' className={classes.title} variant='h5'>
                {title}
            </Typography>
            <Typography color='textPrimary' className={classes.description} variant='h6'>
                {subtitle}
            </Typography>
            {Action ? <Action /> : null}
        </Dialog>
    );
};
