import { combineReducers } from 'redux';
import {
    CLEAR_ERRORS,
    SET_CONFIRM_DIALOGUE,
    RESET_CONFIRM_DIALOGUE,
    STOP_FETCHING,
    INIT_FETCH,
    SET_PREFERENCE,
    SET_VERSION_NUMBER,
    SHOW_ABOUT_MODAL,
    SET_API_VERSION,
    SET_USER_PERMISSIONS,
    START_FETCHING,
    SELECT_ENC_USER,
    SELECT_DEC_USER,
    SELECT_ENC_GROUP,
    SELECT_DEC_GROUP,
    DESELECT_ENC_USER,
    DESELECT_DEC_USER,
    DESELECT_ENC_GROUP,
    DESELECT_DEC_GROUP,
    CANCEL_SELECTION,
    SUBMIT_SELECTION,
    SET_ERROR,
    MOBILE_NAV_VISIBLE,
    UPDATE_USER,
    SW_UPDATE_AVAILABLE,
    SW_UPDATE_COMPLETE,
    SELECT_TOKEN,
    DESELECT_TOKEN
} from '@mediaseal-webui/constants';
/* eslint-disable no-param-reassign */
const userCredentials = (credentials = { username: '', password: '' }, { type, payload }) => {
    switch (type) {
        case 'USER_LOGIN':
            return {
                username: payload.username,
                password: payload.password,
                profile: Object.assign({}, payload.data)
            };
        case 'LOGIN_ATTEMPT':
            return Object.assign(Object.assign({}, payload), { loggedIn: false });
        case 'LOGIN_ERROR':
            return {
                loggedIn: false,
                error: true,
                info: Object.assign({}, payload)
            };
        case UPDATE_USER:
            return {
                ...credentials,
                password: payload.password
            };

        case SET_USER_PERMISSIONS:
            return Object.assign(Object.assign({}, credentials), { loggedIn: true });

        default:
            return credentials;
    }
};
export const isFetching = (state = false, { type }) => {
    switch (type) {
        case START_FETCHING:
            return true;
        case STOP_FETCHING:
            return false;
        default:
            return state;
    }
};
const errors = (state = { error: false, message: '' }, { type, payload }) => {
    switch (type) {
        case 'UPDATE_TITLE_ERROR':
        case 'USERS_ERROR':
        case 'JOB_EDIT_ERROR':
        case 'FETCH_ERROR':
        case SET_ERROR:
            return Object.assign(Object.assign({}, state), { message: payload.message, error: true });
        case 'RESET_ERRORS':
        case CLEAR_ERRORS:
            return {
                error: false,
                message: ''
            };
        default:
            return state;
    }
};
const snackbar = (state = { error: false, message: '', success: false }, { type, payload }) => {
    switch (type) {
        case 'RESET_SNACKBAR':
            return {
                error: false,
                message: '',
                success: false
            };
        case 'SET_SNACKBAR':
            return Object.assign(Object.assign({}, state), payload);
        default:
            return state;
    }
};
const confirmDialogue = (state = {}, { type, payload }) => {
    switch (type) {
        case SET_CONFIRM_DIALOGUE:
            return payload;
        case RESET_CONFIRM_DIALOGUE:
            return {};
        default:
            return state;
    }
};

export const initialFetch = (state = false, { type, payload }) => {
    switch (type) {
        case INIT_FETCH:
            return true;
        case STOP_FETCHING:
            return false;
        default:
            return state;
    }
};
export const preferences = (state = {}, { type, payload }) => {
    switch (type) {
        case SET_PREFERENCE:
            return Object.assign(Object.assign({}, state), payload);
        default:
            return state;
    }
};
export const applicationInformation = (state = {}, { type, payload }) => {
    switch (type) {
        case SET_VERSION_NUMBER:
            return Object.assign(Object.assign({}, state), payload);
        case SET_API_VERSION:
            return Object.assign(Object.assign({}, state), payload);
        default:
            return state;
    }
};
export const showAboutModal = (state = false, { type, payload }) => {
    switch (type) {
        case SHOW_ABOUT_MODAL:
            return payload;
        default:
            return state;
    }
};
/**
 * Simple counter for ease of total selected counting
 */
export const totalSelected = (state = 0, { type, payload }) => {
    switch (type) {
        case SELECT_ENC_USER:
        case SELECT_DEC_USER:
        case SELECT_ENC_GROUP:
        case SELECT_DEC_GROUP:
        case SELECT_TOKEN:
            return state + 1;
        case DESELECT_ENC_USER:
        case DESELECT_DEC_USER:
        case DESELECT_ENC_GROUP:
        case DESELECT_DEC_GROUP:
        case DESELECT_TOKEN:
            return state - 1;
        case CANCEL_SELECTION:
        case SUBMIT_SELECTION:
            return 0;
        default:
            return state;
    }
};
export const offline = (state = false, { type }) => {
    switch (type) {
        case 'OFFLINE':
            return true;
        default:
            return state;
    }
};

export const mobileSearchVisible = (state = false, { type, payload }) => {
    switch (type) {
        case MOBILE_NAV_VISIBLE:
            return payload;
        default:
            return state;
    }
};

const initialState = {
    available: false,
    message: ''
};
export const swUpdateData = (state = initialState, action) => {
    switch (action.type) {
        case SW_UPDATE_AVAILABLE:
            return {
                ...state,
                available: true,
                message: action.payload.message,
                worker: action.payload.worker
            };
        case SW_UPDATE_COMPLETE:
            return {
                ...state,
                available: false,
                message: action.payload.message,
                worker: action.payload.worker
            };
        default:
            return state;
    }
};

export default combineReducers({
    userCredentials,
    errors,
    snackbar,
    isFetching,
    confirmDialogue,
    initialFetch,
    preferences,
    showAboutModal,
    applicationInformation,
    totalSelected,
    offline,
    mobileSearchVisible,
    swUpdateData
});
