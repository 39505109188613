/**
 * Fetch
 */
export const SET_RECIPIENTS = 'SET_RECIPIENTS';
export const SET_FILTERED_ENC_USERS = 'SET_FILTERED_ENC_USERS';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_ENC_USERS = 'FETCH_ENC_USERS';

/**
 * Update
 */
export const UPDATE_USER = 'UPDATE_USER';

/**
 * Totals
 */
export const SET_TOTAL_DEC_USER = 'SET_TOTAL_DEC_USER';
export const SET_TOTAL_ENC_USERS = 'SET_TOTAL_ENC_USERS';

/**
 * Search
 */
export const SET_USER_PAGE_SEARCH_VALUE = 'SET_USER_PAGE_SEARCH_VALUE';
export const CLEAR_USER_PAGE_SEARCH_VALUE = 'CLEAR_USER_PAGE_SEARCH_VALUE';

/**
 * Pages
 */
export const SET_USER_PAGE_COUNT = 'SET_USER_PAGE_COUNT';
export const SET_ENCRYPTOR_USER_PAGE_COUNT = 'SET_ENCRYPTOR_USER_PAGE_COUNT';

/**
 * Misc
 */
export const CLEAR_USERS = 'CLEAR_USERS';
