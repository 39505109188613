import { InitialState } from '@mediaseal-webui/types';
import { useDispatch, useSelector } from 'react-redux';
import { JobsService } from '@mediaseal-webui/services/src/JobsService/JobsService';
import { useCallback } from 'react';
import { definitions } from '@mediaseal-webui/types/api';
import { SET_PROFILE, STOP_FETCHING } from '@mediaseal-webui/constants';

export const useJobService = () => {
    const { username, password } = useSelector((state: InitialState) => state.misc.userCredentials);
    const dispatch = useDispatch();
    const service = new JobsService('/jobs', {
        auth: {
            username,
            password
        }
    });

    const updateJob = useCallback(
        async (id: string, body: Partial<definitions['Job']>) => {
            try {
                await service.update(id, body);
                dispatch({
                    type: 'SET_SNACKBAR',
                    payload: {
                        success: true,
                        message: `Successfully updated job`
                    }
                });
                const { data } = await service.getIndividualJob(id);
                dispatch({ type: SET_PROFILE, payload: data });
            } catch (error) {
                dispatch({ type: 'FETCH_ERROR', payload: error });
                console.error(error);
            } finally {
                dispatch({ type: STOP_FETCHING });
            }
        },
        [service]
    );
    return {
        updateJob
    };
};
