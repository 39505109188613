import React from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Fab, Tooltip } from '@material-ui/core';
import useStyles from './ScrollToTopButton.styles';

const ScrollToTopButton = (props) => {
    const { scrollStepInPx, delayInMs } = props;
    const classes = useStyles();
    let intervalId;
    const scrollStep = () => {
        if (window.pageYOffset === 0) {
            clearInterval(intervalId);
        }
        window.scroll(0, window.pageYOffset - scrollStepInPx);
    };

    const scrollToTop = () => {
        intervalId = setInterval(scrollStep, delayInMs);
    };

    return (
        <Tooltip title='Back To Top' placement='top'>
            <Fab
                color='primary'
                classes={{
                    primary: classes.primary
                }}
                variant='round'
                size='large'
                className={classes.fab}
                aria-label='to top'
                onClick={scrollToTop}>
                <KeyboardArrowUpIcon />
            </Fab>
        </Tooltip>
    );
};

ScrollToTopButton.propTypes = {
    delayInMs: PropTypes.number.isRequired,
    scrollStepInPx: PropTypes.string.isRequired
};
export default ScrollToTopButton;
