import { useCallback, useMemo, useState } from 'react';
import { SortOptions, SortOrders, TableColumns } from '@mediaseal-webui/types/generic';
import { BaseService } from '@mediaseal-webui/services';
import { useAppDispatch } from '@mediaseal-webui/online/src/store';
import { AsyncThunkAction } from '@reduxjs/toolkit';

interface UseTableSortV2Props<T> {
    service: T;
    fetchFn: (args: any) => AsyncThunkAction<any, any, any>;
    cells: TableColumns[];
}
export function useTableSortV2<T extends BaseService<any, any>>({ service, fetchFn, cells }: UseTableSortV2Props<T>) {
    const dispatch = useAppDispatch();
    const [orderBy, setOrderBy] = useState<SortOptions>('createDate');
    const [order, setOrder] = useState<SortOrders>('desc');
    const [headCells, setHeadCells] = useState<TableColumns[]>(cells);
    const sortFn = useCallback(
        (property: TableColumns) => (event: React.SyntheticEvent) => {
            service.setParams({
                page: 0,
                sortBy: property.dataKey,
                sortOrder: property.order,
                sort: `${property.dataKey},${property.order}`
            });
            const isDesc = property.order === 'desc';
            setOrderBy(property.dataKey ?? 'createDate');
            setOrder(property.order);
            setHeadCells((ps) =>
                ps.map(
                    (cell: TableColumns): TableColumns =>
                        cell.dataKey === property.dataKey
                            ? {
                                  ...cell,
                                  order: isDesc ? ('asc' as SortOrders) : ('desc' as SortOrders)
                              }
                            : cell
                )
            );
            dispatch(fetchFn);
        },
        [service, dispatch, cells]
    );
    return {
        sortFn,
        headCells,
        order,
        orderBy
    };
}
