import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { TableModal } from '@mediaseal-webui/components';
import { Dispatchable, InitialState } from '@mediaseal-webui/types/redux';
import { IOpenProps } from '@mediaseal-webui/types/generic';
import { TokensService } from '@mediaseal-webui/services';
import { useAppDispatch } from 'store';
import { searchTokens } from 'reducers/tokens/tokens';
import { clearTokenPageSearchValue, setCheckedEntities, setGroupPageSearchValue } from '@mediaseal-webui/actions';
import { useSelector } from 'react-redux';
import { CLEAR_SUMMARY } from '@mediaseal-webui/constants';
import { useKeycloakConditionally } from '@mediaseal-webui/hooks';
import { useGenAuthTokenFn } from '@mediaseal-webui/hooks/src/useGenAuthTokenFn';

export interface TokenSelectionProps extends IOpenProps {
    updateFn: (id: string | number) => Dispatchable;
    title?: string;
    encryptor?: boolean;
}
export const TokenSelection = ({ updateFn, title, ...others }: TokenSelectionProps) => {
    const updater = () => updateFn(id);
    const { id } = useParams<{ id: string }>();
    const service = useMemo(() => new TokensService(), []);
    const dispatch = useAppDispatch();
    const searcher = useMemo(() => searchTokens, []);
    const { keycloak } = useKeycloakConditionally() ?? {};

    const { tokenPageSearchValue } = useSelector(({ tokens }: InitialState) => tokens);

    const searchFn = useCallback(
        async ({ keyword }: { keyword: string }) => {
            await dispatch(searcher({ service: service as TokensService, keyword }));
            if (keyword === '') {
                service.resetParams();
                dispatch(setCheckedEntities('allowedTokens'));
            }
        },
        [dispatch, searcher, service]
    );

    useEffect(
        () => () => {
            dispatch({ type: CLEAR_SUMMARY });
        },
        [dispatch]
    );

    const searchProps = {
        setPage: () => null,
        searchFn,
        token: useGenAuthTokenFn(),
        value: tokenPageSearchValue,
        clearFn: clearTokenPageSearchValue,
        setterFn: setGroupPageSearchValue as (value: string) => Dispatchable,
        placeholder: 'Search for a token'
    };

    return (
        <>
            <TableModal service={service} entityKey={'allowedTokens'} title='Add new token' updateFn={updater} searchProps={searchProps} {...others} />
        </>
    );
};
