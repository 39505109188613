import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    icon: {
        [theme.breakpoints.up('md')]: {
            marginRight: theme.spacing(1)
        }
    },

    iconButton: {
        opacity: 0,
        position: 'fixed',
        right: 0,
        top: 0,
        height: theme.sizes.header.closed
    },
    showIconButton: {
        animation: '$fade-in 0.4s forwards',
        animationDelay: '1.2s'
    },

    fab: {
        width: 'auto',
        height: '48px',
        padding: '0 16px',
        minWidth: '110px',
        maxWidth: '250px',
        maxHeight: 'auto',
        background: `linear-gradient(90deg, ${theme.palette.primary.dark}, ${theme.palette.primary.light})`,
        color: '#ffffff',
        opacity: 1,
        transition: 'opacity 0.4s ease',
        boxShadow: 'none',
        '&:not($hideFab)': {
            transitionDelay: '1s'
        },
        '&:hover': {
            background: theme.palette.primary.dark
        },
        '&:disabled': {
            background: theme.palette.buttons.disabled,
            color: theme.palette.text.main,
            fontWeight: '600'
        },
        '&:focus': {
            background: theme.palette.primary.light,
            boxShadow: 'none',
            transition: '0.2s'
        }
    },

    hideFab: {},

    progress: {
        color: theme.palette.common.white,
        width: 20,
        height: 20
    },

    '@keyframes fade-in': {
        from: {
            opacity: 0
        },
        to: {
            opacity: 1
        }
    }
}));

export default useStyles;
