import { SET_VERSION_NUMBER, SET_SPA_NOT_SUPPORTED, SET_APP_NOT_SUPPORTED } from '@mediaseal-webui/constants';
import { AppVersionDTO } from '@mediaseal-webui/types/versioning';
export const sendCurrentQtVersions = (appVersionDTO: AppVersionDTO) => {
    let APP_VERSION;
    let MIN_ENGINE_VERSION;

    console.log('Received Version Data');
    console.log(appVersionDTO);
    if (window.Cypress) {
        APP_VERSION = window.Cypress.env('REACT_APP_VERSION');
        MIN_ENGINE_VERSION = window.Cypress.env('REACT_APP_MIN_ENGINE_VERSION');
    } else {
        APP_VERSION = process?.env?.REACT_APP_VERSION || '1.0.0';
        MIN_ENGINE_VERSION = process?.env?.REACT_APP_MIN_ENGINE_VERSION || '1.0.0';
    }
    /**
     * Check that the current version of SPA is supported by QT
     */
    if (APP_VERSION && appVersionDTO.spaMinSupportedVersion > APP_VERSION) {
        // dispatch dialogue
        console.log('CURRENT SPA IS NOT SUPPORTED');
        return {
            type: SET_SPA_NOT_SUPPORTED,
            payload: {
                title: 'Update Required',
                subtitle: 'It looks like your user interface is currently not supported and needs to be updated. Please contact your servers admin'
            }
        };
    }

    if (MIN_ENGINE_VERSION && appVersionDTO.engineVersion < MIN_ENGINE_VERSION) {
        //dispatch dialogue
        console.log('PLEASE UPDATE CLIENT');
        return {
            type: SET_APP_NOT_SUPPORTED,
            payload: {
                title: 'Update Required',
                subtitle: 'It looks like your client is out of date, please download a new version of the application and try again',
                actionable: true
            }
        };
    }

    return { type: SET_VERSION_NUMBER, payload: appVersionDTO };
};
