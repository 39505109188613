import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { MainTable } from '@mediaseal-webui/components';
import { fetchDecUsersForDecGroup, updateDecGroup } from '@mediaseal-webui/actions';
import { profileUserHeader, profileUserHeaderWithoutActions } from '../../../../../data/profile-table-headers';
import { removeEntityAndUpdate, removeDecryptorUserFromSummary } from '../../../../../actions/entity';
import ActionContainer from '../../../../table-components/ActionContainer/ActionContainer';
import { useAuth } from '@mediaseal-webui/hooks';
import { InitialState } from '@mediaseal-webui/types/redux';
import { GenericEntity } from '@mediaseal-webui/types/schemas';
import { hideRemoveUserConfirmation } from '@mediaseal-webui/constants';
import { setConfirmDialogue } from '@mediaseal-webui/actions';
import { useAppSelector } from 'store';

export const DecryptorTable = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const { userPageCount, userPageSearchValue } = useSelector(({ users }: InitialState) => users);
    const { decryptorUsers } = useSelector(({ entity: { profile } }: InitialState) => profile);

    const { items: permissions } = useAppSelector((state) => state.permissions);
    const hasEditDecryptorGroupsPermission = permissions.some((permission) => permission.permissionName === 'canEditDecryptorGroups');

    const auth = useAuth();
    const isCreateAuth = auth.create || auth.administer;

    const fetchFn = () => fetchDecUsersForDecGroup(id);

    const handleRowClick = ({ id }: { id: GenericEntity['id'] }) => history.push(`/users/decryptor/${id}/profile`);

    const removeHandler = (user: GenericEntity) => () =>
        dispatch(
            setConfirmDialogue({
                icon: 'warning',
                title: 'Remove User',
                confirmButtonText: 'Remove User',
                message: 'Are you sure you want to remove this user?',
                action: removeEntityAndUpdate({
                    entity: user,
                    id,
                    removeFn: removeDecryptorUserFromSummary,
                    updateFn: updateDecGroup
                }),
                memorable: hideRemoveUserConfirmation
            })
        );
    const handleRemove = useCallback(removeHandler, [dispatch, id]);

    return (
        <MainTable
            id='decUsersTable'
            entities={decryptorUsers || []}
            searchValue={userPageSearchValue}
            totalPages={userPageCount}
            //@ts-expect-error due to missing props ts(2322).
            fetchFn={fetchFn}
            tableActionsRenderer={(entity: GenericEntity) => <ActionContainer create={isCreateAuth} removeAction={handleRemove(entity)} />}
            columns={hasEditDecryptorGroupsPermission ? profileUserHeader : profileUserHeaderWithoutActions}
            onRowClick={handleRowClick}
            noResultsMessage='No Decryptor Users are assigned to this group'
        />
    );
};
