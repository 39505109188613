import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    editableItemRoot: {
        height: 40,
        width: 40,
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all 0.5s ease',
        marginLeft: theme.spacing(1),
        '&:hover': {
            cursor: 'pointer'
        }
    },

    childWrapper: {
        display: 'flex'
    },
    titleTooltip: {
        display: 'flex',
        alignItems: 'center'
    },
    editIcon: {
        color: theme.palette.background.paper,
        fontSize: '0.7em'
    },
    arrow: {
        color: '#fff'
    },
    tooltipRoot: {
        [theme.breakpoints.down('sm')]: {
            zIndex: 100
        }
    },
    tooltip: {
        fontSize: 'small',
        color: theme.palette.text.primary,
        borderRadius: 10,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid #eee`
    }
}));
