import React from 'react';
import { entityCreationInitialState } from './initialState';
import { EntityCreationInitialState } from '@mediaseal-webui/types';

/**
 *
 * @function setEntityCreationContext
 *  this is the initial representation of a react SetState action
 */
const setEntityCreationContext: React.Dispatch<React.SetStateAction<EntityCreationInitialState>> = () => entityCreationInitialState;

/**
 * @instance JobCreationContext
 *
 * This is the initial context, it represents the state of the forms validty and the set state action to update
 */
export interface IEntityCreationContext {
    entityCreationContext: EntityCreationInitialState;
    // setJobCreationContext: (args: JobCreationInitialState) => JobCreationInitialState
    setEntityCreationContext: React.Dispatch<React.SetStateAction<EntityCreationInitialState>>;
}
export const EntityCreationContext = React.createContext({
    entityCreationContext: entityCreationInitialState,
    setEntityCreationContext
});
