import { InitialState } from '@mediaseal-webui/types/redux';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { EditableHeaderChildProps, EditableHeaderItem } from '../../EditableHeaderItem';
import { HeaderItem, GenericHeaderItemProps } from '..';
import { format } from 'date-fns';
import { MdBrightnessMedium } from 'react-icons/md';
import { useAuth } from '@mediaseal-webui/hooks';

export const SunsetSunriseHeaderItems = ({ isFetching, modal: Modal, tooltipOpen, onModalChange, stateKey = 'profile' }: GenericHeaderItemProps) => {
    const profile = useSelector(({ entity }: InitialState) => entity.profile);
    const create = useSelector(({ entity }: InitialState) => entity.create);

    const { securityRestrictions, archived } = profile;
    const { sunset, sunrise } = create;

    const auth = useAuth();
    const isEditAuth = auth.edit || auth.administer;

    const [showModal, setShowModal] = useState(false);

    const sunRiseDate = (securityRestrictions ?? []).map((option) => format(new Date(option.sunrise), 'dd-MM-yyyy'));
    const sunSetDate = (securityRestrictions ?? []).map((option) => format(new Date(option.sunset), 'dd-MM-yyyy'));

    const onClick = () => setShowModal(true);

    const SunsetIcon = () => <MdBrightnessMedium size={24} />;

    const title = () =>
        stateKey === 'create'
            ? `Sunrise: ${format(sunrise ? new Date(sunrise) : new Date(), 'dd-MM-yyyy')} / Sunset: ${format(
                  sunset ? new Date(sunset) : new Date(),
                  'dd-MM-yyyy'
              )}`
            : securityRestrictions?.length > 0
            ? `Sunrise: ${sunRiseDate} / Sunset: ${sunSetDate}`
            : 'Sunrise/Sunset: No Restrictions';

    useEffect(() => {
        onModalChange(showModal);
    }, [showModal]);

    return (
        <>
            <EditableHeaderItem title={title()} onViewClick={onClick} open={tooltipOpen}>
                {({ hovering }: EditableHeaderChildProps) => <HeaderItem hovering={hovering} Icon={SunsetIcon} isFetching={isFetching} id='sunset-item' />}
            </EditableHeaderItem>
            {Modal && stateKey !== 'create' && <Modal disabled={archived || !isEditAuth} openProps={[showModal, setShowModal]} />}
        </>
    );
};
