import React from 'react';
import PropTypes from 'prop-types';
import { Fade, TableCell, Chip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import useStyles from './ReportStatus.styles';
import { useMobile } from '@mediaseal-webui/hooks';

const ReportStatus = ({ isFetching, label, ...others }) => {
    const classes = useStyles();
    const isMobile = useMobile();
    const determineClassName = () => {
        switch (label) {
            case 'granted':
                return `${classes.pill} ${classes.success} success`;
            case 'denied':
                return `${classes.pill} ${classes.error} denied `;
            case 'save-out':
                return `${classes.pill} ${classes.info} info`;
            default:
                break;
        }
    };
    if (isFetching) {
        return (
            <Fade in timeout={1000}>
                <TableCell component='div' align='center' {...others} classes={{ root: classes.tableCell }} scope='row'>
                    <Skeleton
                        data-testid='loading-skeleton'
                        animation='pulse'
                        variant='rect'
                        width={70}
                        height={32}
                        style={{ marginBottom: 6, borderRadius: '16px' }}
                    />
                </TableCell>
            </Fade>
        );
    } else {
        return (
            <TableCell component='div' align='center' {...others} classes={{ root: classes.tableCell }} scope='row'>
                <Chip variant='default' className={determineClassName()} size='small' label={isMobile ? '' : label} />
            </TableCell>
        );
    }
};

ReportStatus.propTypes = {
    // bla: PropTypes.string,
    isFetching: PropTypes.bool,
    label: PropTypes.string
};

ReportStatus.defaultProps = {
    // bla: 'test',
    isFetching: false,
    label: ''
};

export default ReportStatus;
