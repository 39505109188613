import React, { useState, FC } from 'react';
import { IconButton } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

export interface AddItemButtonProps extends React.HTMLProps<HTMLButtonElement> {
    Modal: React.ElementType;
    Icon?: React.ElementType;
    title?: string;
    disabled?: boolean;
}

export const AddItemButton: FC<AddItemButtonProps> = ({ Modal, Icon = AddIcon, title = 'Add', disabled, id }) => {
    const [showModal, setShowModal] = useState<boolean>(false);

    const handleClick = () => setShowModal(true);

    return (
        <>
            <IconButton id={id} onClick={handleClick} disabled={disabled}>
                <Icon />
            </IconButton>
            {Modal && <Modal openProps={[showModal, setShowModal]} />}
        </>
    );
};
