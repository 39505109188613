import React, { CSSProperties } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import { useSideNav } from '@mediaseal-webui/hooks';
import useStyles from './Link.styles';
import { NavItemProps, LinkProps } from '.';
import { useAppSelector } from 'store';
import { Skeleton } from '@material-ui/lab';
import { ListItemAvatar } from '@material-ui/core';

export const Item = ({ link, classes, style, popper, ...others }: NavItemProps) => {
    const { sideNavOpen } = useSideNav();
    return (
        //@ts-expect-error due to many prop related issues ts(2769)
        <ListItem
            button
            className={clsx(classes.linkContainer, {
                [classes.helpContainer]: popper
            })}
            disableRipple={true}
            {...others}>
            {!sideNavOpen ? (
                <Tooltip title={link.text} aria-label='add' placement='right'>
                    <Icon
                        style={style}
                        className={clsx(classes.icon, {
                            helpIcon: true,
                            [link.icon]: true
                        })}
                    />
                </Tooltip>
            ) : (
                <Icon style={style} className={clsx(classes.icon, popper && classes.popperText, `${link.icon}`)} />
            )}
            <ListItemText
                style={style}
                primary={link.text.toLocaleUpperCase()}
                disableTypography
                classes={{
                    root: clsx(classes.textLink, {
                        [classes.textWhenClosed]: !sideNavOpen && !popper,
                        [classes.popperText]: popper
                    })
                }}
            />
        </ListItem>
    );
};

const Link = ({ link, id, disableLink, authCheck, authOverride, isRoot, useClass, ...others }: LinkProps) => {
    const classes = useStyles();
    const { items: permissions, pending } = useAppSelector((state) => state.permissions);
    const { pathname } = useLocation();

    const isAuthed = () => permissions.some((permission) => permission.permissionName === authCheck);

    const activeStyle = {
        color: 'rgba(255,255,255,0.8)',
        borderLeftStyle: 'solid' as CSSProperties['borderLeftStyle'],
        borderLeftWidth: 'medium',
        borderLeftColor: 'rgba(255,255,255,0.8)',
        display: 'block',
        backgroundColor: 'rgba(29,28,29,0.8)',
        transition: '0.4s'
    };

    const to = isRoot && pathname === '/' ? '/' : link?.link;
    if (pending) {
        return (
            <ListItem>
                <ListItemAvatar>
                    <Skeleton variant='circle' width={15} height={15} />
                </ListItemAvatar>
                <ListItemText>
                    <Skeleton width={80} height={15} />
                </ListItemText>
            </ListItem>
        );
    }
    if (isAuthed() || authOverride) {
        return disableLink ? (
            <div id={id} className={classes.disableContainer}>
                <Item link={link} classes={classes} {...others} />
            </div>
        ) : (
            <NavLink id={id} className={useClass ? useClass : classes.inactive} activeStyle={activeStyle} to={to} key={link.id}>
                <Item link={link} classes={classes} {...others} />
            </NavLink>
        );
    }
    return null;
};

export default Link;
