import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    histogramFigures: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: theme.spacing(8),
        display: 'flex',
        flexWrap: 'wrap'
    },
    monthFigure: {
        textAlign: 'center',
        width: 'calc(100% / 3 - 1px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    figureTotal: {
        fontWeight: 'bold'
    },
    figureLabel: {
        opacity: 0.65,
        lineHeight: 0.5
    },
    overallTitle: {
        width: '100%',
        textAlign: 'center'
    },
    borderRight: {
        borderRight: `1px solid ${theme.palette.grey[100]}`
    }
}));

export default useStyles;
