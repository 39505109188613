import DateRangePicker from './DateRangePicker';

export interface IDateRangePickerInputLabels {
    startLabel: string;
    endLabel: string;
}

export interface DateRangePickerProps {
    /**
     *  callback for setting Date end date
     */
    toDateSetter: (date: Date) => void;
    /**
     * callback used for setting start date
     */
    fromDateSetter: (date: Date) => void;
    /**
     * Optional bool to show the time -- default: false
     */
    showTime?: boolean;
    /**
     * Optional bool to set the form to fullwidth -- default: false
     */
    fullWidth?: boolean;
    /**
     * Optional bool to disable the datepickers -- default: false
     */
    disabled?: boolean;
    /**
     * Optional bool to show portal version of datepickers -- default: false
     */
    portal?: boolean;
    /**
     * Optional prop to set input labels -- defaults: 'To' & 'From'
     */
    inputLabels?: IDateRangePickerInputLabels;
    /**
     * Optional bool to show inline calenders -- default: false
     */
    inline?: boolean;
    /**
     * Optonal date to be passed for a preset startDate -- defaults: Today (new Date())
     */
    defaultStartDate?: Date;
    /**
     * Optonal date to be passed for a preset endDate -- defaults: Today (new Date())
     */
    defaultEndDate?: Date;
    [string: string]: any;
    disableMinDate?: boolean;
}

export default DateRangePicker;
