//TODO: Figure out why fallthrough route causes rerender loop

import React from 'react';
import Jobs from '../views/Jobs';
import DecryptorUsers from '../views/Users/Decryptor';
import EncryptorUsers from '../views/Users/Encryptor';
import Create from '../views/Jobs/Create';
import Job from '../views/Jobs/Job';
import EncryptorGroups from '../views/Groups/Encryptor';
import CreateEncryptor from '../views/Users/Encryptor/Create';
import DecryptorGroups from '../views/Groups/Decryptor';
import Titles from '../views/Titles';
import Title from '../views/Titles/Title';
import TitleCreate from '../views/Titles/Create';
import CreateEncryptorGroup from '../views/Groups/Encryptor/Create';
import GroupsProfile from '../views/Groups/Encryptor/Profile';
import DecryptorProfile from '../views/Groups/Decryptor/Profile';
import Reporting from '../views/Reporting';
import CreateDecryptorGroup from '../views/Groups/Decryptor/Create';
import EncUserProfile from '../views/Users/Encryptor/Profile';
import DecUserProfile from '../views/Users/Decryptor/Profile';
import { Settings } from '../views/Settings';
import { AccountPage } from 'views/Account';
import { Tokens } from '../views/Tokens/Tokens';
import CreateToken from 'views/Tokens/Create/Create';
// import PageNotFound from '../views/PageNotFound';

export interface INavLink {
    id: number;
    text: string;
    link: string;
    icon: string;
    navId: string;
    authCheck?: string;
    isKeycloakOnlyComponent?: boolean;
}
interface Routes {
    id: number;
    exact: boolean;
    path: string;
    component: React.FC;
    isKeycloakOnlyComponent: boolean;
}
const routes = (defaultRoute): Routes[] => [
    {
        id: 0,
        exact: true,
        path: '/jobs',
        component: Jobs,
        isKeycloakOnlyComponent: false
    },
    {
        id: 1,
        exact: true,
        path: '/jobs/:id/profile',
        component: Job,
        isKeycloakOnlyComponent: false
    },
    {
        id: 2,
        exact: true,
        path: '/jobs/create',
        component: Create,
        isKeycloakOnlyComponent: false
    },
    {
        id: 3,
        exact: true,
        path: '/users/decryptor',
        component: DecryptorUsers,
        isKeycloakOnlyComponent: false
    },
    {
        id: 4,
        exact: true,
        path: '/users/encryptor',
        component: EncryptorUsers,
        isKeycloakOnlyComponent: false
    },
    {
        id: 6,
        exact: true,
        path: '/groups/decryptor',
        component: DecryptorGroups,
        isKeycloakOnlyComponent: false
    },
    {
        id: 7,
        exact: true,
        path: '/groups/encryptor',
        component: EncryptorGroups,
        isKeycloakOnlyComponent: false
    },
    {
        id: 8,
        exact: true,
        path: '/tokens',
        component: Tokens,
        isKeycloakOnlyComponent: true
    },
    {
        id: 9,
        exact: true,
        path: '/',
        component: defaultRoute,
        isKeycloakOnlyComponent: false
    },
    {
        id: 10,
        exact: true,
        path: '/titles',
        component: Titles,
        isKeycloakOnlyComponent: false
    },
    {
        id: 11,
        exact: true,
        path: '/titles/:id/profile',
        component: Title,
        isKeycloakOnlyComponent: false
    },
    {
        id: 12,
        exact: true,
        path: '/titles/create',
        component: TitleCreate,
        isKeycloakOnlyComponent: false
    },
    {
        id: 14,
        exact: true,
        path: '/groups/encryptor/create',
        component: CreateEncryptorGroup,
        isKeycloakOnlyComponent: false
    },
    {
        id: 15,
        exact: true,
        path: '/users/encryptor/create',
        component: CreateEncryptor,
        isKeycloakOnlyComponent: false
    },
    {
        id: 16,
        exact: true,
        path: '/groups/encryptor/:id/profile',
        component: GroupsProfile,
        isKeycloakOnlyComponent: false
    },
    {
        id: 20,
        exact: true,
        path: '/groups/decryptor/:id/profile',
        component: DecryptorProfile,
        isKeycloakOnlyComponent: false
    },
    {
        id: 22,
        exact: true,
        path: '/reporting',
        component: Reporting,
        isKeycloakOnlyComponent: false
    },
    {
        id: 23,
        exact: true,
        path: '/groups/decryptor/create',
        component: CreateDecryptorGroup,
        isKeycloakOnlyComponent: false
    },
    {
        id: 25,
        exact: true,
        path: '/users/encryptor/:id/profile',
        component: EncUserProfile,
        isKeycloakOnlyComponent: false
    },
    {
        id: 26,
        exact: true,
        path: '/users/decryptor/:id/profile',
        component: DecUserProfile,
        isKeycloakOnlyComponent: false
    },
    {
        id: 27,
        exact: true,
        path: '/settings',
        component: Settings,
        isKeycloakOnlyComponent: false
    },
    {
        id: 28,
        exact: true,
        path: '/account',
        component: AccountPage,
        isKeycloakOnlyComponent: false
    },
    {
        id: 29,
        exact: true,
        path: '/tokens/create',
        component: CreateToken,
        isKeycloakOnlyComponent: true
    }
];

export default routes;
