import { TextField } from '@material-ui/core';
import React, { KeyboardEvent } from 'react';
import { useTextFieldStyles } from './Input.styles';

interface InputProps {
    hasError: (value: string | undefined) => boolean;
    value: string | undefined;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: () => void;
}

const Input: React.FC<InputProps> = ({ hasError, value, handleChange, onSubmit }): JSX.Element => {
    const textFieldClasses = useTextFieldStyles();

    const onKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.code === 'Enter' && !hasError(value)) {
            onSubmit();
        }
    };

    return (
        <TextField
            value={value}
            onInput={handleChange}
            name='Add address'
            data-testid='email-invite'
            placeholder='Add address'
            variant='outlined'
            classes={textFieldClasses}
            color='primary'
            error={hasError(value)}
            type='text'
            onKeyPress={onKeyPress}
        />
    );
};
export { Input };
