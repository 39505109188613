import TextArea from './TextArea';

export interface TextAreaProps {
    name: string;
    onChange?: (args: any) => void;
    rows?: number;
    placeholder?: string;
    value?: string;
    className?: string;
    disabled?: boolean;
}

export default TextArea;
