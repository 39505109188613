import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.background.paper,
        borderRadius: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0 0`,
        borderLeft: '1px solid #bdbdbd',
        borderTop: '1px solid #bdbdbd',
        borderRight: '1px solid #bdbdbd',
        display: 'flex',
        alignItems: 'center',
        // flexWrap: 'wrap',
        [theme.breakpoints.down('xs')]: {
            // justifyContent: 'space-between'
        }
    },
    filterText: {
        width: '100%'
    }
}));

export default useStyles;
