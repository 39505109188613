import React from 'react';
import { DialogContent, DialogActions, useMediaQuery } from '@material-ui/core';
import { Actions } from '../EditableEntityTitle';
import { useDispatch } from 'react-redux';
import { useEntityCreation, useLogout, usePasswordScore, useProfileCheck } from '@mediaseal-webui/hooks';
import { setEdit } from '@mediaseal-webui/actions';
import { PasswordModalProps } from '.';
import { useParams } from 'react-router';
import { useTheme } from '@material-ui/core/styles';
import FormGroup from '../FormGroup';
import { PasswordInput } from '../Inputs';
import { StyledDialogue } from './PasswordModal.styles';
import { useAppSelector } from '@mediaseal-webui/online/src/store';

const CONFIRM_MESSAGE = 'Please ensure both passwords are the same.';

export const PasswordModal = ({
    openProps,
    updateFn,
    onClose = () => null,
    subtitle = "Edit the job's password",
    confirmSubtitle = "Confirm the job's new password",
    disabled
}: PasswordModalProps) => {
    const [open, setOpen] = openProps;
    const { confirmPassword, passwordValidation, valid } = useEntityCreation(['password', 'confirmPassword']);
    const { id } = useParams<{ id: string }>();
    const loggedInUser = useAppSelector((state) => state.user);
    const dispatch = useDispatch();
    const { isOwnProfile } = useProfileCheck(loggedInUser.apiUser);
    const handleLogout = useLogout();
    const theme = useTheme();
    const { updatePassword } = usePasswordScore();
    const matches = useMediaQuery<boolean>(theme.breakpoints.down('xs'));
    const handleSave = async () => {
        await dispatch(updateFn(id));
        setOpen(false);
        setTimeout(() => {
            isOwnProfile && handleLogout();
        }, 2000);
    };

    const handleChange = (name: string) => (value: string) => {
        if (name === 'password') {
            updatePassword(value);
        }
        dispatch(setEdit({ [name]: value }));
    };

    const handleClose = () => {
        onClose();
        setOpen(false);
    };

    return (
        <StyledDialogue open={open} fullScreen={matches} maxWidth='xs' id='change-password-modal'>
            <DialogContent style={{ overflow: 'visible' }}>
                <FormGroup heading='Change Password' subtitle={subtitle}>
                    <PasswordInput
                        disabled={disabled}
                        enableScore
                        name='editable-password'
                        placeholder='Enter a new password'
                        validation={passwordValidation}
                        setterFn={handleChange('password')}
                    />
                </FormGroup>
                <FormGroup heading='Confirm' subtitle={confirmSubtitle}>
                    <PasswordInput
                        disabled={disabled}
                        name='editable-confirmPassword'
                        placeholder='Confirm password'
                        validation={confirmPassword}
                        // setterFn={handleChange('confirmPassword')}
                        tooltipText={CONFIRM_MESSAGE}
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Actions saveFn={handleSave} disableSave={!valid} closeFn={handleClose} />
            </DialogActions>
        </StyledDialogue>
    );
};
