import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    moreMenuRoot: {
        backgroundColor: '#2a2d3e',
        width: '100vw',
        height: '100vh',
        overflow: 'scroll',
        zIndex: 101
    },
    header: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center'
    },
    close: {
        color: theme.palette.common.white,
        opacity: 0.55,
        position: 'fixed',
        left: theme.spacing(2)
    },
    closedText: {
        color: theme.palette.common.white,
        marginTop: 25,
        fontSize: 10,
        fontWeight: 'lighter',
        textAlign: 'center',
        textTransform: 'uppercase',
        opacity: 0.5
    },
    icon: {
        fontSize: '1.2em',
        opacity: 0.5,
        paddingRight: 15,
        textAlign: 'center'
    },
    navItems: {
        backgroundColor: '#2a2d3e',
        color: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        height: `calc(100% - ${theme.spacing(2 + 3 + 3)}px)` // accounts for the header with logo + padding top & bottom
    },
    listItems: {
        marginTop: 50
    },
    utils: {
        marginTop: 20
    }
}));

export default useStyles;
