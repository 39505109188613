import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
    titleTooltip: {
        display: 'flex'
    },
    opacityIcon: {
        opacity: 0.5
    },
    additionalItems: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        right: theme.spacing(2),
        bottom: theme.spacing(2),
        transition: `all 0.5s ease`,
        zIndex: -1,
        // '@media (max-width: 1000px)': {
        //     bottom: 120,
        // }
        [theme.breakpoints.down('sm')]: {
            bottom: 0,
            top: 5,
            zIndex: 1,
            height: 50,
            width: 200
        },
        borderedHeaderItem: {
            height: 42, // to account for padding on siblings
            width: 42,
            borderRadius: 40,
            border: `1px solid ${theme.palette.divider}`,
            '&:hover': {
                border: `1px solid ${theme.palette.primary.light}`
            }
        },
        buttonLabel: {
            width: 24
        },
        overflowItem: {
            display: 'none',
            border: `1px solid #eee`,
            width: theme.spacing(5),
            height: theme.spacing(5),
            padding: theme.spacing(1),
            transition: `1.5s`,
            alignItems: 'center',
            borderRadius: theme.spacing(5),
            justifyContent: 'space-between',
            marginLeft: theme.spacing(1),
            cursor: 'pointer',
            fontSize: 20,
            '&:hover': {
                border: `1px solid ${theme.palette.primary.main}`
            },
            '& svg': {
                width: 40,
                color: theme.palette.error.main
            },
            '@media (max-width: 1305px)': {
                display: 'flex',
                marginLeft: 0
            },
            [theme.breakpoints.down('sm')]: {
                marginRight: 6,
                backgroundColor: theme.palette.background.paper
            }
        }
    }
}));
interface HeaderItemsContainerProps {
    open?: boolean;
}

export const OverflowItem = styled.div<HeaderItemsContainerProps>(
    ({ theme, open }) => `
    display: none;
    border: 1px solid #eee;
    width: ${theme.spacing(5)}px;
    height: ${theme.spacing(5)}px;
    padding: ${theme.spacing(1)}px;
    transition: 1.5s;
    position: relative;
    align-items: center;
    border-radius: ${theme.spacing(5)}px;
    background: ${theme.palette.background.paper};
    cursor: pointer;
    font-size: 20px;
    &:hover {
        border: 1px solid ${theme.palette.primary.main};
    }
    & svg {
        width: 40px;
        color: ${theme.palette.grey[600]};
    }
    @media (max-width: 1305px) {
        display: flex;
    }
`
);

export const HeaderItemsContainer = styled.div<HeaderItemsContainerProps>(
    ({ theme, open }) => `
    transition: all 0.4s linear;
    display: flex;
    height: 54px;
    border-radius: 26px;
    background: ${open ? theme.palette.background.paper : '#ffffff00'};
    z-index: ${open ? 10 : 1};;
    justify-content: flex-end;
    align-items: center;
    width: auto;
    @media (max-width: 1305px) {
        width: ${open ? 'auto' : '54px'};
        ${
            open &&
            `
            &:before {
                content: '';
                background-color: ${theme.palette.common.black}24;
                width: 100vw;
                height: 100vh;
                position: absolute;
                left: 0;
                top: 0;
                z-index: -1;
            }
        `
        }    
    }
    @media (max-width: 600px) {
        flex-direction: column-reverse;
        height: ${open ? 'auto' : 0};
    }
    
`
);

interface HeaderItemsInnerProps extends HeaderItemsContainerProps {
    sideNavOpen?: boolean;
    actionButtonWidth?: string | number;
}

export const HeaderItemsInner = styled.div<HeaderItemsInnerProps>(
    ({ open, theme, sideNavOpen, actionButtonWidth }) => `
    display: flex;
    height: 54px;
    align-items: center;
    transition: all 0.25s linear;
    background: ${open ? theme.palette.background.paper : '#ffffff00'};
    border-radius: 26px;
    padding: ${open ? theme.spacing(1) : 0}px;
    @media (min-width: 1306px) {
        padding-right: 1px;
    }
    @media (max-width: 1305px) {
        max-width: calc(100vw - ${
            //@ts-expect-error due to "Property 'sizes' does not exist on type 'DefaultTheme'.ts(2339)".
            sideNavOpen ? theme.sizes.sideNav.open : theme.sizes.sideNav.closed
        }px - ${actionButtonWidth}px - 100px);
        overflow-x: scroll;
        position: absolute;
        visibility: ${open ? 'show' : 'hidden'};
        margin-right: ${theme.spacing(6)}px;
        flex: ${open ? 1 : 0};
        opacity: ${open ? 1 : 0};
        z-index: ${open ? 1 : -1};
        border: ${open ? '1px solid #eee' : 'none'};
    }
    @media (max-width: 1305px) and (min-width: 601px) {
        div:nth-child(1) {
            margin-left: 0px;
        }
    }
    @media (max-width: 600px) {
        position: unset;
        flex: ${open ? 1 : 0};
        flex-direction: column;
        width: 54px;
        margin-right: 0px;
        max-height: calc(100vh - 200px);
        overflow: scroll;
        margin-top: ${theme.spacing(1.5)}px;
        background-color: ${theme.palette.background.paper};
    }
`
);

export default useStyles;
