import React, { useState } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { JobsStatusFilter, TriStateData } from '@mediaseal-webui/types/jobs';
import { Tooltip } from '@material-ui/core';
import { IconContainer } from './TriStateFilterButton.style';
import { useStyles } from './TriStateFilterButton.style';
import { FilterProps } from '../../Table/Filter';
import { useAppDispatch } from '@mediaseal-webui/online/src/store';
export interface TriStateFilterButtonProps extends Pick<FilterProps, 'fetchFn' | 'setterFn'> {
    width?: string;
    setterKey: string;
    data: TriStateData[];
    defaultValue: JobsStatusFilter;
}

export const TriStateFilterButton = ({ width, data, setterFn, fetchFn, setterKey, defaultValue }: TriStateFilterButtonProps) => {
    const [filter, setFilter] = useState<string>(defaultValue);
    const dispatch = useAppDispatch();
    const LeftIcon = data[0].icon;
    const MiddleIcon = data[1].icon;
    const RightIcon = data[2].icon;
    const classes = useStyles();
    const handleChange = (event: React.SyntheticEvent, newFilter: string) => {
        if (newFilter === null) return;
        setFilter(newFilter);
        setterFn((service) => {
            if (/archive/gi.test(newFilter)) {
                service.jobsParams = {
                    ...service.jobsParams,
                    //@ts-ignore
                    isArchived: data.find((item) => item.value === newFilter)?.queryString
                };
            }
            service.baseParams = {
                ...service.baseParams,
                [setterKey]: newFilter ? data.find((item) => item.value === newFilter)?.queryString : ''
            };
            dispatch(fetchFn({ keyword: service.baseParams.keyword ?? '', service }));
        });
    };
    return (
        <ToggleButtonGroup size='large' value={filter} exclusive onChange={handleChange}>
            <ToggleButton
                classes={{
                    selected: classes.selected
                }}
                value={data[0].value}>
                <Tooltip placement='top' title={data[0].tooltipText}>
                    <IconContainer>
                        <LeftIcon data-testid='left-icon' />
                    </IconContainer>
                </Tooltip>
            </ToggleButton>

            <ToggleButton
                classes={{
                    selected: classes.selected
                }}
                value={data[1].value}>
                <Tooltip placement='top' title={data[1].tooltipText}>
                    <IconContainer>
                        <MiddleIcon data-testid='middle-icon' />
                    </IconContainer>
                </Tooltip>
            </ToggleButton>

            <ToggleButton
                classes={{
                    selected: classes.selected
                }}
                value={data[2].value}>
                <Tooltip placement='top' title={data[2].tooltipText}>
                    <IconContainer>
                        <RightIcon data-testid='right-icon' />
                    </IconContainer>
                </Tooltip>
            </ToggleButton>
        </ToggleButtonGroup>
    );
};
