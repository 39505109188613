import React, { useCallback, useEffect, useMemo } from 'react';
import { TableModal } from '@mediaseal-webui/components';
import { IOpenProps } from '@mediaseal-webui/types/generic';
import { useParams } from 'react-router';
import { InitialState, Dispatchable } from '@mediaseal-webui/types/redux';
import { clearGroupPageSearchValue, setCheckedEntities, setGroupPageSearchValue } from '@mediaseal-webui/actions';
import { useSelector } from 'react-redux';
import { DecryptorGroupService, EncryptorGroupService } from '@mediaseal-webui/services';
import { searchDecryptorGroups, searchEncryptorGroups } from 'reducers/groups/groups';
import { useAppDispatch } from 'store';
import { CLEAR_SUMMARY } from '@mediaseal-webui/constants';
import { useKeycloakConditionally } from '@mediaseal-webui/hooks';
import { useGenAuthTokenFn } from '@mediaseal-webui/hooks/src/useGenAuthTokenFn';

export interface GroupSelectionProps extends IOpenProps {
    updateFn: (id: string | number) => Dispatchable;
    title?: string;
    encryptor?: boolean;
}

export const GroupSelection = ({ updateFn, title, encryptor, ...others }: GroupSelectionProps) => {
    const { id } = useParams<{ id: string }>();
    const updater = () => updateFn(id);
    const service = useMemo(() => (encryptor ? new EncryptorGroupService() : new DecryptorGroupService()), [encryptor]);
    const { keycloak } = useKeycloakConditionally() ?? {};
    const searcher = useMemo(() => (encryptor ? searchEncryptorGroups : searchDecryptorGroups), [encryptor]);
    const dispatch = useAppDispatch();
    const { groupPageSearchValue } = useSelector(({ groups }: InitialState) => groups);
    const searchFn = useCallback(
        async ({ keyword }: { keyword: string }) => {
            await dispatch(searcher({ service: service as DecryptorGroupService & EncryptorGroupService, keyword }));
            if (keyword === '') {
                service.resetParams();
                dispatch(setCheckedEntities(encryptor ? 'encryptorGroups' : 'decryptorGroups'));
            }
        },
        [dispatch, encryptor, searcher, service]
    );
    const searchProps = {
        setPage: () => null,
        searchFn,
        token: useGenAuthTokenFn(),
        value: groupPageSearchValue,
        clearFn: clearGroupPageSearchValue,
        setterFn: setGroupPageSearchValue as (value: string) => Dispatchable,
        placeholder: 'Search for a group'
    };

    useEffect(
        () => () => {
            dispatch({ type: CLEAR_SUMMARY }); //clear the users in state
        },
        [dispatch]
    );
    return (
        <TableModal
            service={service}
            entityKey={encryptor ? 'encryptorGroups' : 'decryptorGroups'}
            title={title ?? `Add new ${encryptor ? 'encryptor' : 'decryptor'} groups`}
            updateFn={updater}
            searchProps={searchProps}
            {...others}
        />
    );
};
