import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Column, ColumnProps } from 'react-virtualized';

import { Hidden } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { InfiniteScrollTable, TextCell, RowData } from '@mediaseal-webui/components';
import { useBreakPointListener } from '@mediaseal-webui/hooks';
import { AuditService, keycloak } from '@mediaseal-webui/services';
import { InitialState, TableColumns } from '@mediaseal-webui/types';
import { definitions } from '@mediaseal-webui/types/api';

import { formatDateFns, processAuthMode } from '../../../helpers';
import FileNameCell from './FileNameCell/FileNameCell';
import ReportStatus from '../ReportStatus/ReportStatus';

import { useStyles } from './ReportingTable.styles';

import { useAppSelector } from 'store';
import { genBasicAuth, genBearerAuth } from '@mediaseal-webui/utils';
import { isKeycloakActive } from '@mediaseal-webui/hooks/src/isKeycloakActive';
import { useWindowStore } from '@mediaseal-webui/hooks/src/useWindowStore';

/**
 * @param {entities} array -- data to be presented
 * @param {totalPages} number -- used for infiniteScroll control and to ensure no overfetch errors
 * @param {entityLink} string -- this is the main pathname of the view, links are then created from it for edit and profile views: e.g entityLink="/jobs"
 * @param {pageControl} object -- this object should contain the page,setPage state from the view to control current page of requests
 * @param {fetchFn} func -- the raw action to be dispatched to fetch entities
 * @param {sortData} obj -- Sort data passed from parent, used in the table for sorting in the header
 * @param {columns} array -- Header data for sortable table header.. passed from parent due to different header labels for user views
 */
interface ReportingTableProps {
    entities: definitions['AuditLogFileAccessDTO'][] & definitions['AuditSaveFileOpenAssetsDTO'][] & definitions['AuditLogEntityActivityDTO'];
    fetchFn: () => Promise<void>;
    sortData: {
        createSortHandler: (header: TableColumns) => () => Promise<void>;
    };
    id: string;
    totalPages: number;
    columns: TableColumns[];
    onRowClick: (entity: definitions['FileAccessDTO'] & definitions['AuditSaveFileOpenAssetsDTO']) => void;
}

interface CellRenderProps {
    cellData?: string | number;
    columnIndex: number;
    rowData?: RowData;
}

interface HeaderRenderProps extends Omit<ColumnProps, 'width'> {
    columnIndex: number;
}

// eslint-disable-next-line
const ReportingTable = React.forwardRef((props: ReportingTableProps, ref) => {
    const { entities, onRowClick, fetchFn, columns, sortData, totalPages, ...others } = props;
    const classes = useStyles();
    const { createSortHandler } = sortData;
    const { isFetching } = useSelector(({ misc }: InitialState) => misc);
    const { isDecryptorTab } = useAppSelector((state) => state.reporting);
    const rowGetter = ({ index }) => entities[index]; // returns current index from Table
    const rowClickHandler = (entity: definitions['FileAccessDTO'] | definitions['AuditSaveFileOpenAssetsDTO']) => onRowClick(entity); // returns the rowData obj from Table
    const handleRowClick = useCallback(rowClickHandler, [onRowClick]);
    const windowStore = useWindowStore();
    const auditService = useMemo(
        () =>
            new AuditService('/audits/fileAccess', {
                headers: {
                    authorization: isKeycloakActive()
                        ? genBearerAuth(keycloak.token)
                        : genBasicAuth({
                              username: windowStore.misc.userCredentials.username,
                              password: windowStore.misc.userCredentials.password
                          })
                }
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const processUsers = (openFiles) => {
        const arr = openFiles && [...new Set(openFiles.map((file) => file.user.fullName))];
        return arr.length === 1 ? arr[0] : 'Multiple';
    };
    const { hide } = useBreakPointListener();

    const userCellOutput = (rowData) =>
        isDecryptorTab ? rowData?.user?.fullName || (rowData?.openAssets && processUsers(rowData?.openAssets)) || '' : rowData?.actionBy?.name || '';

    const headerRenderer = ({ label, columnIndex, dataKey }: HeaderRenderProps) => {
        const inner = !columns[columnIndex].disableSort ? (
            <TableSortLabel
                active={Boolean(auditService.auditParams.sort.match(new RegExp(dataKey, 'g')))}
                direction={auditService.auditParams.sortOrder}
                onClick={createSortHandler(columns[columnIndex])}>
                {label}
            </TableSortLabel>
        ) : (
            label
        );
        return (
            <Hidden only={columns[columnIndex].hidden}>
                <TableCell
                    component='div'
                    id={dataKey}
                    className={clsx([classes.tableCell, classes.flexContainer], {
                        [classes.centered]: columns[columnIndex].label === 'Status'
                    })}
                    variant='head'>
                    {inner}
                </TableCell>
            </Hidden>
        );
    };

    const cellRenderer = ({ cellData, rowData, columnIndex }: CellRenderProps) => {
        if (columns[columnIndex].label === 'Accessed') {
            return (
                <TextCell
                    output={(rowData?.date && formatDateFns(rowData?.date)) || ''}
                    subtitle={processAuthMode(cellData) || ' '}
                    isFetching={isFetching}
                    skeletonHeight={15}
                    className={clsx([classes.flexContainer])}
                />
            );
        }
        if (columns[columnIndex].label === 'User') {
            return (
                <TextCell
                    output={userCellOutput(rowData)}
                    subtitle={processAuthMode(cellData) || ' '}
                    isFetching={isFetching}
                    skeletonHeight={15}
                    className={clsx([classes.flexContainer])}
                />
            );
        }
        if (columns[columnIndex].label === 'File Name') {
            return <FileNameCell entry={rowData} />;
        }
        if (columns[columnIndex].label === 'Status') {
            return <ReportStatus label={rowData?.accessType || 'save-out'} isFetching={isFetching} />;
        }
        if (columns[columnIndex].label === 'Date') {
            return (
                <TextCell
                    output={rowData?.actionDate ? formatDateFns(rowData?.actionDate) : ''}
                    subtitle={processAuthMode(cellData) || ' '}
                    isFetching={isFetching}
                    skeletonHeight={15}
                    className={clsx([classes.flexContainer])}
                />
            );
        }
        return <TextCell output={cellData || ''} isFetching={isFetching} skeletonHeight={15} className={clsx([classes.flexContainer])} />;
    };

    return (
        <InfiniteScrollTable
            rowCount={entities.length}
            rowGetter={rowGetter}
            rowHeight={45}
            data={entities}
            totalPages={totalPages}
            onRowClick={handleRowClick}
            fetchFn={fetchFn}
            rowClassName={({ index }) => (index !== -1 ? clsx([classes.tableRow, classes.tableRowHover]) : classes.tableRow)}
            columns={columns}
            {...others}>
            {() =>
                columns.map(({ cellContentRenderer = null, hidden, dataKey, className, ...other }, index) => {
                    const renderer =
                        cellContentRenderer === null
                            ? cellRenderer
                            : (cellRendererProps) =>
                                  cellRenderer({
                                      cellData: cellContentRenderer(cellRendererProps),
                                      columnIndex: index
                                  });
                    const columnProps = { cellRenderer: renderer, ...other };
                    const width = () => (hide.some((bp) => columns[index].hidden?.includes(bp)) ? 0 : 90);
                    const flexGrow = () => (hide.some((bp) => columns[index].hidden?.includes(bp)) ? 0 : other.flexGrow);
                    return (
                        <Column
                            key={index}
                            dataKey={dataKey || ''}
                            headerRenderer={(headerProps) =>
                                headerRenderer({
                                    ...headerProps,
                                    columnIndex: index
                                })
                            }
                            className={clsx([classes.flexContainer, className])}
                            {...columnProps}
                            width={width()}
                            flexGrow={flexGrow()}
                        />
                    );
                })
            }
        </InfiniteScrollTable>
    );
});

export default ReportingTable;
