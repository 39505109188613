import { CREATE_ENCRYPTION_WORK, ADD_FILE_TO_EXISTING_WORK } from '@mediaseal-webui/constants';
import { EncryptFileWithIssues, EncryptorFileList } from '@mediaseal-webui/types/encryptor';
import { ThunkResult } from '@mediaseal-webui/types/redux';
import { constructFilePayload } from '@mediaseal-webui/utils';
import { RootState } from 'packages/online/src/store';
import { handleBatchAcl } from '../batching';
import { processEncryptionWork } from '../processes';
import { v4 as uuidv4 } from 'uuid';

/**
 *
 * @param work
 * @param workWithIssues
 * @returns void
 *
 * Triggered by QT after files have been selected / dropped
 */
export const encryptionWorkCreated =
    (work: EncryptorFileList, workWithIssues: EncryptFileWithIssues[] = []): ThunkResult<void> =>
        (dispatch, getState: () => RootState) => {
            // get job information from state
            const { additionalDataVersion, headerVersion, contactName, contactInfo, features, batched, type, id } = getState().entity.profile;
            const { activeJobId } = getState().jobs;
            const { encryptionWork } = getState().encryptor;
            // get a common id for both lists
            const firstFile = work[0] || workWithIssues[0];
            const batchMediaKey = firstFile.mediaKeyBase64;
            const batchFileId = firstFile.id;
            const encryptionSessionId: string = uuidv4();

            // process each file and add id / percentage
            work.forEach((item) => {
                item.processed = false;
                item.percentage = 0;
                item.jobId = activeJobId;
                item.fileId = batched ? batchFileId : item.id;
                item.mediaKey = batched ? batchMediaKey : item.mediaKeyBase64;
                item.encryptionSessionId = encryptionSessionId;
                console.log(`received item with fileDirectory: ${item.fullFilePath}`);
                console.log(`sessionid vs mediakey: ${item.encryptionSessionId} vs ${item.mediaKeyBase64}`);
            });

            // same with issues
            workWithIssues.forEach((item) => {
                item.processed = false;
                item.percentage = 0;
                item.jobId = activeJobId;
                item.fileId = batched ? batchFileId : item.id;
                item.mediaKey = batched ? batchMediaKey : item.mediaKeyBase64;
                item.encryptionSessionId = encryptionSessionId;
                console.log(`received item with issue with fileDirectory: ${item.fullFilePath}`);
            });

            if (workWithIssues.length > 0) {
                /** Triggers opening of `FileIssuesModal` */
                dispatch({ type: 'ADD_ENC_FILE_ISSUES', payload: workWithIssues });
            }

            // check that current activejobId doesnt exist in the work list
            const exists = encryptionWork.some((work) => work.jobData.id === Number(activeJobId));
            if (exists) {
                // add it to already existing work
                dispatch({
                    type: ADD_FILE_TO_EXISTING_WORK,
                    payload: {
                        id: Number(activeJobId),
                        fileList: work
                    }
                });
            } else {
                // Lets go ahead and create a encryption job from the work
                dispatch({
                    type: CREATE_ENCRYPTION_WORK,
                    payload: {
                        id,
                        posted: false,
                        batchEncryptedAcl: null,
                        jobData: {
                            type,
                            id,
                            additionalDataVersion,
                            headerVersion,
                            contactName,
                            contactNumber: contactInfo,
                            features
                        },
                        fileList: work
                    }
                });
            }

            // if no issues... then lets check if offline and show the password dialogue else start to process the files for encryption
            if (!workWithIssues.length) {

                if (batched) {
                    //get an ACL
                    const fileInfo = constructFilePayload(work[0], getState().jobs.activeJobId);
                    dispatch(handleBatchAcl(fileInfo, id));
                } else {
                    dispatch(processEncryptionWork());
                }
            }
        };
