import React, { useEffect, useState } from 'react';
import { IconButton, Popover, List, ListItem, ListItemIcon, Checkbox, ListItemText, ListSubheader } from '@material-ui/core';
import { FetchArgs, FilterData } from '@mediaseal-webui/types';
import { BsFilter } from 'react-icons/bs';
import { useStyles } from './Filter.style';
import { useSelector } from 'react-redux';
import { JobsStatusFilter, StatusFilterPayload } from '@mediaseal-webui/types/jobs';
import { TriStateFilterButton } from '../../Button/TriStateFilterButton';
import { InitialState } from '@mediaseal-webui/types/redux';
import { MdClose } from 'react-icons/md';
import { JobsService } from 'packages/services/src';
export interface FilterProps {
    data: FilterData[];
    setterFn: (cb: (serv: JobsService) => void) => void;
    fetchFn: ({ keyword: string, service: JobService }) => void;
    getterKey: string;
    getterSubKey: string;
}
export const Filter = ({ data, setterFn, fetchFn, getterKey, getterSubKey }: FilterProps) => {
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
    const [selectedValues, setSelectedValues] = useState<JobsStatusFilter[]>([]);
    const classes = useStyles();
    const defaults = useSelector((state: InitialState) => state.filters[getterKey][getterSubKey]);
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    useEffect(() => {
        setSelectedValues(Object.values(defaults));
    }, [defaults]);
    return (
        <>
            <IconButton data-testid='filter-icon' onClick={handleClick}>
                <BsFilter />
            </IconButton>
            <Popover
                open={open}
                PaperProps={{
                    //@ts-ignore
                    'data-tour': 'statusFilter'
                }}
                className={classes.popover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}>
                <IconButton size='small' onClick={handleClose} className={classes.close}>
                    <MdClose />
                </IconButton>
                <List dense>
                    <ListSubheader>Filter by</ListSubheader>
                    {data.map(({ name, buttonData, setterKey }: FilterData, index) => (
                        <ListItem key={index}>
                            <ListItemText
                                classes={{
                                    root: classes.listText
                                }}
                                primary={name}
                            />
                            <ListItemIcon className={classes.checkboxContainer}>
                                <TriStateFilterButton
                                    setterFn={setterFn}
                                    defaultValue={selectedValues[index]}
                                    setterKey={setterKey}
                                    fetchFn={fetchFn}
                                    data={buttonData}
                                />
                                {/* <Checkbox checked={} onChange={handleChange({ fetchFn, setterFn, setterKey })} /> */}
                            </ListItemIcon>
                        </ListItem>
                    ))}
                </List>
            </Popover>
        </>
    );
};
