import { EncryptFile, EncryptionWork } from '@mediaseal-webui/types/encryptor';
import { ThunkResult } from '@mediaseal-webui/types/redux';
import { RootState } from 'packages/online/src/store';
import { setError } from '../../entity';
import { setStartJobType } from '../processes';

export const handleBatchedFiles =
    (item: EncryptFile, work: EncryptionWork): ThunkResult<void> =>
    async (dispatch, getState: () => RootState) => {
        // first we need to find out if any of the files have been processed.. if one has.. then we dont need to POST anymore
        const { encryptionWork } = getState().encryptor;
        const currentWork = encryptionWork.find((found) => found.id === work.id);
        // if none are processed POST a file
        if (currentWork) {
            console.log('Calling SET_ACTIVE_FILE no POST');

            await setStartJobType(
                {
                    id: item.id,
                    type: work.jobData.type,
                    currentWork,
                    mediaKey: item.mediaKey,
                    fileId: item.fileId
                },
                dispatch
            );
        } else {
            dispatch(
                setError({
                    message: 'No current encryption work associated with this file'
                })
            );
        }
    };
