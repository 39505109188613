import { SET_ENCRYPTION_PROGRESS } from '@mediaseal-webui/constants';
import { FileProgress, ThunkResult, ProgressProps } from '@mediaseal-webui/types';

export const encryptionWorkProgress =
    ({ id, amount }: ProgressProps): ThunkResult<void> =>
    (dispatch, getState) => {
        console.log(`Received Signal encryptionWorkProgressed from QT with ID : ${id} and % ${amount}`);
        dispatch({
            type: SET_ENCRYPTION_PROGRESS,
            payload: { id, percentage: amount }
        });
    };

/**
 *
 * @param list File[]
 * @returns void
 *
 *
 * Triggered by QT when updating progress of multiple files => processed individually by encryptionWorkProgress
 */

export const encryptionWorkProgressed =
    (list: FileProgress): ThunkResult<void> =>
    (dispatch) => {
        const keys = Object.keys(list);
        const values = Object.values(list);
        console.log(`Received object keys: ${keys}`);
        console.log(`Received object values: ${values}`);
        if (list) {
            for (let index = 0; index < keys.length; index++) {
                dispatch(encryptionWorkProgress({ id: keys[index], amount: values[index] }));
            }
        }
    };
