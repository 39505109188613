import axios from 'axios';
import { msApi } from '@mediaseal-webui/api';
import { stopFetching } from '../misc';
import {
    SET_PROFILE,
    SET_ERROR,
    CLEAR_ERRORS,
    OPEN_SECURITY_METHOD_MODAL,
    SET_SECURITY_OPTIONS,
    RESET_SECURITY_OPTIONS,
    SET_FILTERED_PROFILE_DECRYPTOR_USERS,
    SET_FILTERED_PROFILE_DECRYPTOR_GROUPS
} from '../../constants';
import { TitleError, NoEntitiesError } from '../../errors';
import { setError, setEdit } from '../entity';
import { fetchUsers, fetchGroups } from '@mediaseal-webui/actions';
import { processFileSize } from '../../helpers';
import { format } from 'date-fns';
import { createJob as createJobNew } from './create';
import { NEW_FILES_AVAILABLE } from '@mediaseal-webui/constants';
import { genBasicAuth } from '@mediaseal-webui/utils';
export * from './statusFilter';
// /**
//  * Fetch
//  */
// export const getJobs = (payload) => async (dispatch, getState) => {
//     try {
//         const { statusFilter, jobPageSearchValue } = getState().jobs
//         const { archived, liveFolders, active } = statusFilter
//         const { data } = await msApi.get(
//             `/jobs/search/findAllWithQueryParameters?${archived || ''}${liveFolders || ''}${active || ''}${jobPageSearchValue !== '' ? `&keyword=${window.encodeURI(jobPageSearchValue.trim())}` : ''
//             }&projection=simple&page=${payload.page}&size=${payload.size ? payload.size : 100
//             }&sort=${payload.sort ? payload.sort : 'id,desc'} `,
//         );
//         // const { data } = await msApi.get('/jobs?projection=compact&sort=createDate,desc')
//         const { content } = data;
//         const { page } = data;
//         const { firstCall } = payload;
//         const { totalPages } = page;
//         const { totalElements } = page;

//         dispatch({ type: firstCall ? 'FETCH_JOBS_FILTERED' : 'FETCH_JOBS', payload: content });
//         dispatch(setTotalJobPages(totalPages));
//         dispatch({ type: 'SET_TOTAL_JOB_ELEMENTS', payload: totalElements });
//     } catch (error) {
//         dispatch(setError(error));
//     }
//     dispatch(stopFetching());
// };

export const fetchAllJobData = (id) => async (dispatch, getState) => {
    try {
        const jobReq = msApi.get(`/jobs/${id}?projection=simple`);
        // const jobReq = Axios.get('https://f7519ec6-4d75-4105-a767-6f39e5756512.mock.pstmn.io/jobs/1?projection=simple')
        const titleReq = msApi.get(`/jobs/${id}/title?projection=compact`);
        const usersReq = msApi.get(`/jobs/${id}/recipients`);
        const groupReq = msApi.get(`/jobs/${id}/stationGroups`);
        const pswd = msApi.get(`/jobs/${id}/password`);
        const access = msApi.get(`/jobs/${id}/stats`);
        const hist = msApi.get(`/jobs/${id}/histogram`);

        const [job, title, users, groups, password, accessed, histogram] = await axios.all([jobReq, titleReq, usersReq, groupReq, pswd, access, hist], {
            headers: {
                Authorization: genBasicAuth({
                    username: getState().misc.userCredentials.username,
                    password: getState().misc.userCredentials.password
                })
            }
        });

        const jobData = {
            ...job.data,
            title: title.data,
            password: password.data.password,
            decryptorUsers: users.data.content,
            decryptorGroups: groups.data.content,
            // files: job.data.content,
            timesGranted: accessed.data.timesAccessed.granted,
            timesDenied: accessed.data.timesAccessed.denied,
            histogram: histogram.data.histogram || []
        };

        dispatch({ type: SET_PROFILE, payload: jobData });
        dispatch({ type: SET_FILTERED_PROFILE_DECRYPTOR_USERS, payload: users.data.content });
        dispatch({ type: SET_FILTERED_PROFILE_DECRYPTOR_GROUPS, payload: groups.data.content });
        if (job.data.securityRestrictions && job.data.securityRestrictions.length > 0) dispatch(setSecurtyOptions({ disableSunset: false }));
        dispatch(
            fetchUsers({
                keyword: '',
                page: 0,
                firstCall: true,
                filter: true
            })
        );
        dispatch(
            fetchGroups({
                keyword: '',
                page: 0,
                firstCall: true,
                filter: true
            })
        );
        dispatch(fetchJobFiles({ id, firstCall: true }));
        dispatch(setEdit());
    } catch (error) {
        dispatch(setError(error));
        console.log(error);
    }
    dispatch(stopFetching());
};

export const getJobsTitle = (id) => async (dispatch) => {
    try {
        const { data } = await msApi.get(`/jobs/${id}/title`);
        dispatch({ type: 'GET_JOB_TITLE', payload: data });
    } catch (error) {
        dispatch(setError(error));
    }
    dispatch(stopFetching());
};

export const getJobRecipients =
    ({ id, page = 0, sort = 'createDate,desc', firstCall }) =>
    async (dispatch) => {
        try {
            const { data } = await msApi.get(`/jobs/${id}/recipients`);
            const { content, totalElements, totalPages } = data;
            dispatch({ type: 'GET_JOB_USERS', payload: content });
            dispatch({ type: 'SET_JOB_RECIPIENTS_PAGES', payload: totalPages });
        } catch (error) {
            dispatch(setError(error));
        }
        dispatch(stopFetching());
    };

export const getJobGroups =
    ({ id, page = 0, sort = 'createDate,desc', firstCall }) =>
    async (dispatch) => {
        try {
            const { data } = await msApi.get(`/jobs/${id}/stationGroups`);
            const { content, totalElements, totalPages } = data;
            dispatch({ type: 'FETCH_JOB_GROUPS', payload: content });
        } catch (error) {
            dispatch(setError(error));
        }
        dispatch(stopFetching());
    };

export const fetchSortedJobUsers = (id, order, orderBy) => async (dispatch) => {
    try {
        const { data } = await msApi.get(`/jobs/${id}/recipients/search/findAllContainingKeyword?keyword=&page=0&size=100&sort=${orderBy},${order}`);
        const { content } = data;
        dispatch({ type: 'SET_ORDERED_FILE_USERS', payload: content });
    } catch (error) {
        dispatch(setError(error));
    }
    dispatch(stopFetching());
};

export const fetchJobFiles =
    ({ id, page = 0, sort = 'createDate,desc', firstCall }) =>
    async (dispatch) => {
        try {
            const { data } = await msApi.get(`files/stats?jobId=${id}&size=100&sort=${sort}&page=${page}`);
            const { content, totalElements, totalPages } = data;
            // const { totalPages, totalElements } = data.page

            const newArr = content.map((file) => {
                const reducer = (acc, key, index) => {
                    const value = file[key];

                    if (key === 'stats') {
                        return {
                            ...acc,
                            ...value,
                            lastGrantedAccessUserFullName: value.lastGrantedAccess?.user?.fullName || 'N/A',
                            lastGrantedAccessUserId: value.lastGrantedAccess?.user?.id || 'N/A'
                        };
                    }

                    if (key === 'file') {
                        return {
                            ...acc,
                            ...value,
                            size: processFileSize(value.size),
                            createDate: new Date(value.createDate)
                        };
                    }
                };

                const flattenedObj = Object.keys(file).reduce(reducer, {});

                return flattenedObj;
            });

            dispatch({ type: firstCall ? 'FETCH_JOB_FILES_SORTED' : 'FETCH_JOB_FILES', payload: newArr }); // appends
            dispatch({ type: 'SET_JOB_FILE_PAGES', payload: totalPages });
            dispatch({ type: 'SET_TOTAL_FILES', payload: totalElements });
            firstCall && dispatch({ type: NEW_FILES_AVAILABLE, payload: false });
        } catch (error) {
            dispatch(setError(error));
            console.log(error);
        }
        dispatch(stopFetching());
    };

/**
 * Filter
 */
export const filterJobRecipients = () => (dispatch, getState) => {
    const { summary } = getState().entity;
    const { users } = summary;
    users?.length > 0 && users.forEach((user) => dispatch({ type: 'REMOVE_RECIPIENT', payload: user }));
};

export const filterJobRecipientGroups = () => (dispatch, getState) => {
    const { summary } = getState().entity;
    const { groups } = summary;
    groups?.length > 0 && groups.forEach((group) => dispatch({ type: 'REMOVE_RECIPIENT_GROUP', payload: group }));
};

export const setTotalJobPages = (pages) => ({ type: 'SET_TOTAL_JOB_PAGES', payload: pages });

export const removeSelectedGroup = (group) => ({ type: 'REMOVE_GROUP', payload: group });

export const clearTitles = () => ({ type: 'CLEAR_TITLES' });

export const displayJobCreationSuccess = (name) => ({
    type: 'SET_SNACKBAR',
    payload: { success: true, message: `Successfully created ${name}` }
});

export const clearJobList = () => ({ type: 'CLEAR_JOB_LIST' });

export const setActiveJobId = (id) => ({ type: 'SET_ACTIVE_JOB_ID', payload: id });

/**
 * Delete
 */
export const setDeleteJob = (id, history, name) => async (dispatch) => {
    try {
        await msApi.delete(`/jobs/${id}`);
        history.push('/jobs');
        dispatch({ type: 'SET_SNACKBAR', payload: { id, message: `Successfully deleted ${name}` } });
    } catch (error) {
        dispatch(setError(error));
    }
    dispatch(stopFetching());
};

/**
 * Search
 */
export const setJobPageSearchValue = (value) => ({ type: 'SET_JOB_PAGE_SEARCH_VALUE', payload: value });
export const setJobPageSerachValueGroups = (value) => ({ type: 'SET_JOB_PAGE_SEARCH_VALUE_GROUPS', payload: value });
export const setJobPageSearchValueUsers = (value) => ({ type: 'SET_JOB_PAGE_SEARCH_VALUE_USERS', payload: value });
export const clearJobPageSearchValue = () => ({ type: 'CLEAR_JOB_PAGE_SEARCH_VALUE' });
export const clearJobPageSearchValueUsers = () => ({ type: 'CLEAR_JOB_PAGE_SEARCH_VALUE_USER' });
export const clearJobPageSearchValueGroups = () => ({ type: 'CLEAR_JOB_PAGE_SEARCH_VALUE_GROUP' });

export const validateCreateJob = (history) => (dispatch, getState) => {
    dispatch({ type: CLEAR_ERRORS });
    const { authMethod, title, decryptorUsers, decryptorGroups } = getState().entity.create;
    const state = getState().entity.create;

    const titleError = !title?.id;
    const usersAdded = (decryptorUsers && decryptorUsers.length >= 1) ?? false;
    const groupsAdded = (decryptorGroups && decryptorGroups.length >= 1) ?? false;

    const doesNotMeetUserRequirements = authMethod !== 'Password Only' && !usersAdded && !groupsAdded;

    if (titleError) {
        dispatch({ type: SET_ERROR, payload: new TitleError('Please select a title to associate the job to') });
    }

    if (doesNotMeetUserRequirements) {
        dispatch({ type: SET_ERROR, payload: new NoEntitiesError('Please assign users and groups to the job') });
    }

    if (!titleError && !doesNotMeetUserRequirements) {
        dispatch(createJobNew(history));
    }
};

/**
 * Update
 */
export const determineSunriseTimezoneString = (edit, timezone) => {
    const { sunrise, securityRestrictions } = edit;
    if (sunrise) {
        // did they set new sunrise ?
        return `${format(sunrise, 'dd-MM-yyyy HH:mm')},${timezone}`;
    } else if (securityRestrictions && securityRestrictions[0]?.sunriseDateTimeZoneString) {
        // if not is one already sert
        return `${edit.securityRestrictions[0]?.sunriseDateTimeZoneString.split(',')[0]},${timezone}`;
    } else {
        // default
        return `${format(new Date(), 'dd-MM-yyyy HH:mm')},${timezone}`;
    }
};

export const determineSunsetTimezoneString = (edit, timezone) => {
    const { sunset, securityRestrictions } = edit;
    const [restrictions] = securityRestrictions;
    if (sunset) {
        return `${format(sunset, 'dd-MM-yyyy HH:mm')},${timezone}`;
    } else if (restrictions) {
        if (restrictions?.sunsetDateTimeZoneString) {
            return `${restrictions?.sunsetDateTimeZoneString.split(',')[0]},${timezone}`;
        } else {
            if (!restrictions?.sunsetDateTimeZoneString && restrictions?.sunsetDateTimeZoneString === '') {
                return '';
            } else {
                return `${format(new Date(), 'dd-MM-yyyy HH:mm')},${timezone}`;
            }
        }
    }
};
export const updateJob = (id, params) => async (dispatch, getState) => {
    const { edit } = getState().entity;
    const {
        name,
        title,
        password,
        decryptorGroups,
        decryptorUsers,
        contactName,
        contactInfo,
        sunrise,
        sunset,
        timezone,
        active,
        features,
        notes,
        template,
        batched,
        archived
    } = edit;
    const { disableSunset, defaultTimeZone } = getState().jobs.securityOptions;
    let patchData = {};
    try {
        if (params) {
            patchData = {
                ...params
            };
        } else {
            const userID = decryptorUsers?.map((user) => `/api/recipients/${user.id}`);
            const stationGroups = decryptorGroups?.map((group) => `/api/recipientgroups/${group.id}`);
            const sunriseTimezone =
                timezone ||
                (edit.securityRestrictions && edit.securityRestrictions[0]?.sunriseDateTimeZoneString
                    ? edit?.securityRestrictions[0]?.sunriseDateTimeZoneString.split(',')[1]
                    : defaultTimeZone);
            const sunsetTimezone =
                timezone ||
                (edit.securityRestrictions && edit.securityRestrictions[0]?.sunsetDateTimeZoneString
                    ? edit?.securityRestrictions[0]?.sunsetDateTimeZoneString.split(',')[1]
                    : defaultTimeZone);
            const sunriseDateTimeZoneString = edit.securityRestrictions && determineSunriseTimezoneString(edit, sunriseTimezone);
            const sunsetDateTimeZoneString = edit.securityRestrictions && determineSunsetTimezoneString(edit, sunsetTimezone);

            patchData = {
                name,
                active,
                features,
                contactName,
                contactInfo,
                notes: notes?.replace('\n', ','),
                title: `/api/titles/${title.id}`,
                decryptorUsers: userID,
                decryptorGroups: stationGroups,
                password,
                template,
                batched,
                archived,
                securityRestrictions: !disableSunset
                    ? [
                          {
                              entityType: 0,
                              entityId: 0,
                              sunrise: (sunrise && new Date(sunrise)) || edit?.securityRestrictions[0]?.sunrise || new Date(),
                              sunset: (sunset && new Date(sunset)) || edit?.securityRestrictions[0]?.sunset || new Date(),
                              password,
                              sunriseDateTimeZoneString,
                              sunsetDateTimeZoneString
                          }
                      ]
                    : []
            };
        }
        await msApi.patch(`/jobs/${id}?projection=simple`, patchData);
        dispatch({ type: 'SET_SNACKBAR', payload: { success: true, message: `Successfully updated job: ${name}` } });
    } catch (error) {
        dispatch({ type: 'FETCH_ERROR', payload: error });
        console.error(error);
    } finally {
        dispatch(stopFetching());
        dispatch(fetchAllJobData(id));
    }
};

export const updateJobAndRedirect = (id, history) => async (dispatch) => {
    await dispatch(updateJob(id));
    history.push('/jobs');
};

export const openJobHelpModal = (bool) => ({ type: OPEN_SECURITY_METHOD_MODAL, payload: bool });

export const setSecurtyOptions = (options) => ({ type: SET_SECURITY_OPTIONS, payload: options });

export const resetSecurityOptions = () => ({ type: RESET_SECURITY_OPTIONS });
