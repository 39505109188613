import { stopFetching, fetchApiVersion, fetchUserPermissions } from '../misc';
import { msApi } from '@mediaseal-webui/api';
import { getServerSettings } from '@mediaseal-webui/actions';
export const loginUser = (values) => async (dispatch, getState) => {
    dispatch({
        type: 'LOGIN_ATTEMPT',
        payload: {
            username: values.username,
            password: values.password
        }
    });
    try {
        const { data } = await msApi.get(`users/search/findByLoginName?loginName=${values.username}`);
        dispatch(await fetchUserPermissions(data.id));
        dispatch({
            type: 'USER_LOGIN',
            payload: {
                data,
                username: values.username,
                password: values.password
            }
        });
        dispatch(fetchApiVersion());
        dispatch(getServerSettings());
    } catch (error) {
        console.error(error);
        dispatch({
            type: 'LOGIN_ERROR',
            payload: {
                ...error,
                message: 'We could not find a username or password that match the details provided'
            }
        });
    }
    dispatch(stopFetching());
};

export const setLoginStatus = (bool) => ({ type: bool ? 'USER_LOGIN' : 'USER_LOGGED_OUT' });

export const logoutUser = (data) => ({ type: 'USER_LOGGED_OUT', data });

export const setStudioName = (studioName) => ({ type: 'SET_STUDIO_NAME', payload: studioName });

export default loginUser;
