import React from 'react';
import PropTypes from 'prop-types';
import { Fade, IconButton, Chip, Tooltip, Paper, Table, TableHead, TableBody, TableCell, TableRow, Hidden } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './InviteTable.styles';
import NoResults from '../../../utility-components/NoResults';
import { useMobile } from '@mediaseal-webui/hooks';

const InviteTable = ({ invites, deleteDraftInvite }) => {
    const classes = useStyles();
    const isMobile = useMobile();
    return (
        <Paper className={classes.root}>
            <Table stickyHeader className={classes.table} data-testid='invite-table' id='invite-table'>
                <TableHead>
                    <TableRow>
                        <TableCell>Email</TableCell>
                        <Hidden only={['xs', 'sm']}>
                            <TableCell>First Name</TableCell>
                        </Hidden>
                        <Hidden only={['xs', 'sm']}>
                            <TableCell>Last Name</TableCell>
                        </Hidden>
                        <TableCell />
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                    {invites.length > 0
                        ? invites
                              .filter((invite) => invite.status !== 'ACCEPTED' && invite.status !== 'PENDING')
                              .map((invite, index) => (
                                  <Fade key={index} in timeout={1000}>
                                      <TableRow className={classes.tableRow} data-testid='invite-record'>
                                          <TableCell classes={{ root: classes.tableCell }}>{invite.email}</TableCell>
                                          <Hidden only={['xs', 'sm']}>
                                              <TableCell classes={{ root: classes.tableCell }}>{invite.firstName}</TableCell>
                                          </Hidden>
                                          <Hidden only={['xs', 'sm']}>
                                              <TableCell classes={{ root: classes.tableCell }}>{invite.lastName}</TableCell>
                                          </Hidden>
                                          <TableCell>
                                              {invite.status && (
                                                  <Tooltip
                                                      title={
                                                          invite.status === 'CANCELLED'
                                                              ? 'This invitation was cancelled. Please contact support.'
                                                              : invite.status === 'ERROR'
                                                              ? 'An error occurred whilst processing this invitation. Please contact support.'
                                                              : 'This user declined a previous invitation. Please contact support.'
                                                      }
                                                      placement='top'>
                                                      <Chip
                                                          size='small'
                                                          className={classes.pill}
                                                          data-testid='invite-status'
                                                          color='default'
                                                          label={invite.status}
                                                      />
                                                  </Tooltip>
                                              )}
                                          </TableCell>
                                          <TableCell classes={{ root: classes.tableCell }}>
                                              <Tooltip title='Remove this invite' placement='top'>
                                                  <IconButton
                                                      onClick={() => deleteDraftInvite(invite)}
                                                      data-testid='delete-invite'
                                                      id='delete-btn'
                                                      className={classes.iconButton}>
                                                      {invite.status ? <CloseIcon /> : <DeleteIcon />}
                                                  </IconButton>
                                              </Tooltip>
                                          </TableCell>
                                      </TableRow>
                                  </Fade>
                              ))
                        : !isMobile && <NoResults message='No new invites to display' />}
                </TableBody>
            </Table>
        </Paper>
    );
};

InviteTable.propTypes = {
    invites: PropTypes.arrayOf(
        PropTypes.shape({
            email: PropTypes.string.isRequired,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            status: PropTypes.oneOf(['PENDING', 'ACCEPTED', 'REJECTED', 'CANCELLED'])
        })
    ).isRequired,
    deleteDraftInvite: PropTypes.func.isRequired
};

InviteTable.defaultProps = {
    invites: []
};

export default InviteTable;
