import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Drawer, BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { Assignment as ClipboardIcon, LocalOffer as TagIcon } from '@material-ui/icons';
import { FaTasks } from 'react-icons/fa';
import { FiMoreHorizontal } from 'react-icons/fi';
import MoreMenu from './MoreMenu';
import { useMobile } from '@mediaseal-webui/hooks';
import useBottomNavStyles from './BottomNav.styles';
import { BottomNavLinks } from '.';
import { v4 as uuid } from 'uuid';
const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const BottomNav = () => {
    const classes = useBottomNavStyles();
    const history = useHistory();
    const mobile = useMobile();

    const [value, setValue] = useState<string>('');
    const [open, setOpen] = useState(false);

    useEffect(() => (mobile ? setOpen(false) : setOpen(true)), [mobile]);

    const moreOpen = () => setOpen(true);
    const moreClose = () => setOpen(false);

    const tabs = [
        { title: 'jobs', Icon: ClipboardIcon },
        { title: 'titles', Icon: TagIcon },
        { title: 'audit', Icon: FaTasks },
        { title: '', Icon: FiMoreHorizontal, onClick: moreOpen, className: classes.more }
    ];

    const renderNavAction = ({ title, Icon, onClick, className }: BottomNavLinks) => (
        <BottomNavigationAction
            id={title}
            value={title}
            key={uuid()}
            label={capitalize(title)}
            className={classes.navLinks}
            classes={{ selected: classes.active, label: classes.label }}
            icon={<Icon className={`${classes.normalize} ${className}`} />}
            onClick={onClick}
        />
    );

    return (
        <>
            <BottomNavigation
                id='bottomNav'
                classes={{ root: classes.bottomNav }}
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    switch (newValue) {
                        case 'jobs':
                            history.push('/jobs');
                            break;
                        case 'titles':
                            history.push('/titles');
                            break;
                        case 'audit':
                            history.push('/reporting');
                            break;
                        default:
                            break;
                    }
                    moreClose();
                }}>
                {tabs.map(renderNavAction)}
            </BottomNavigation>

            <Drawer id='menuDraw' anchor='bottom' open={open}>
                <MoreMenu moreClose={moreClose} />
            </Drawer>
        </>
    );
};

export default React.memo(BottomNav);
