import React from 'react';
import { EditableHeaderItem } from '../../EditableHeaderItem';
import { HeaderItem } from '../HeaderItem';
import { GiFiles } from 'react-icons/gi';
import { GenericHeaderItemProps } from '..';

export const BatchedHeaderItems = ({ tooltipOpen }: GenericHeaderItemProps) => {
    const filesIcon = () => <GiFiles size={24} />;
    return (
        <EditableHeaderItem title='Batching Enabled' open={tooltipOpen}>
            {() => <HeaderItem id='batched-header-item' hovering Icon={filesIcon} />}
        </EditableHeaderItem>
    );
};
