import React, { useState } from 'react';
import { Alert } from '@material-ui/lab';
import { Collapse, IconButton } from '@material-ui/core';
import { useStyles } from './WarningAlert.style';
import { Close as CloseIcon } from '@material-ui/icons';
import { useMobile, useSideNav } from '@mediaseal-webui/hooks';

export interface WarningAlertProps {
    message: string;
    show: boolean;
}

export const WarningAlert = ({ message, show }: WarningAlertProps) => {
    const { width } = useSideNav();
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(true);
    const handleClose = () => setOpen(false);
    const mobile = useMobile();
    return show ? (
        <Collapse in={open}>
            <Alert
                id='warning-alert'
                style={{ width: `calc(100% - ${mobile ? 0 : width}px`, zIndex: 100 }}
                action={
                    <IconButton aria-label='close' color='inherit' size='small' onClick={handleClose}>
                        <CloseIcon fontSize='inherit' />
                    </IconButton>
                }
                className={classes.alert}
                title={message}
                variant='standard'
                severity='warning'>
                {message}
            </Alert>
        </Collapse>
    ) : null;
};
