import React from 'react';
import { JobsTable, UsersTable, GroupsTable } from '..';
import { GroupSelection, UserSelection } from '../../../utility-components/Modals';
import { IOpenProps } from '@mediaseal-webui/types/generic';
import { AddGroup, AddUser } from '@mediaseal-webui/components/src/icons';
import { updateTitle } from '../../../../actions/titles';
import { ITab } from '../../../layout-components';
import { TokensTable } from '../Tables/TokensTable';
import { TokenSelection } from 'components/utility-components/Modals/TokenSelection';
import { isKeycloakActive } from '@mediaseal-webui/hooks/src/isKeycloakActive';

export const tabs: ITab[] = [
    {
        id: 1,
        attrId: 'jobs-tab',
        label: 'Jobs',
        cardId: 'jobs-panel',
        component: <JobsTable />
    },
    {
        id: 2,
        cardId: 'users-panel',
        label: 'Users',
        attrId: 'users-tab',
        btnId: 'add-users',

        modal: ({ openProps }: IOpenProps) => <UserSelection encryptor openProps={openProps} updateFn={updateTitle} />,
        Icon: AddUser,
        component: <UsersTable />
    },
    {
        id: 3,
        cardId: 'groups-panel',
        label: 'Groups',
        attrId: 'groups-tab',
        btnId: 'add-groups',

        modal: ({ openProps }: IOpenProps) => <GroupSelection encryptor openProps={openProps} updateFn={updateTitle} />,
        Icon: AddGroup,
        component: <GroupsTable />
    }
].concat(
    isKeycloakActive()
        ? [
              {
                  id: 4,
                  cardId: 'tokens-panel',
                  label: 'Tokens',
                  attrId: 'tokens-tab',
                  btnId: 'add-tokens',

                  modal: ({ openProps }: IOpenProps) => <TokenSelection openProps={openProps} updateFn={updateTitle} />,
                  Icon: AddGroup,
                  component: <TokensTable />
              }
          ]
        : []
);
