import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    '&:global': {
        p: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    },
    tableCell: {
        flex: 1,
        minWidth: 0,
        borderBottom: 'unset',
        flexWrap: 'wrap',
        padding: '0 10px',
        // padding: '10px 10px 10px 0',
        [theme.breakpoints.down('sm')]: {
            padding: 5
        }
    },
    subtitleIcon: {
        width: 15,
        display: 'inline-flex'
    },
    authSubtitle: {
        display: 'flex',
        alignItems: 'center'
    }
}));

export default useStyles;
