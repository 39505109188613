import React from 'react';
import { Pass, Fail } from '..';
import { InitialState, PassFlag, FailFlag } from '@mediaseal-webui/types/redux';

const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$£€%&\'()*+,-./:;<=>?@[\\]^_`{|}~])(?=.{8,})');

const PASSWORD_STRENGTH = (
    <span>
        <p>The password must contain:</p>
        <p>&#8226; a minimum eight characters</p>
        <p>&#8226; one uppercase and lowercase letter</p>
        <p>&#8226; one number and one special character</p>
    </span>
);

/**
 * Validates against the password input
 *
 * @param () curried function for redux
 * @param value String password input
 * @return {passes: Bool, message: String? }
 */
export const passwordStrength =
    () =>
    (value: string): PassFlag | FailFlag => {
        if (strongRegex.test(value)) {
            return Pass;
        }
        return Fail(PASSWORD_STRENGTH);
    };

/**
 * Takes the confirm passwsord input value and compares against the initial password value
 *
 * @param getState Function from redux to access state
 * @param value String
 * @return {passes: Bool, message: String? }
 */
export const confirmPassword =
    (getState: () => InitialState) =>
    (value: string): PassFlag | FailFlag => {
        if (!value) {
            return Fail('Please confirm the password');
        }
        // if (!strongRegex.test(value)) {
        //     return Fail(PASSWORD_STRENGTH)
        // }
        const compare = getState().form.fields?.find((field) => new RegExp('password', 'ig').test(field.name))?.value;
        if (value !== compare) {
            return Fail('Passwords do not match');
        }

        return Pass;
    };
