import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { UsersService } from '@mediaseal-webui/services';
import { GenericEntity } from '@mediaseal-webui/types';
import { useAppSelector } from 'store';
import { encryptorUsersSlice, fetchEncryptorUsers, searchEncryptorUsers } from 'reducers/users/encryptorUsers';
import { useSummaryFilter } from '@mediaseal-webui/hooks';
import { addEncryptorUserToSummary } from '@mediaseal-webui/actions';
import { useSearchWorker } from 'hooks';
import { MainTable, MSSearchBar as SearchBar } from '@mediaseal-webui/components';
import { actionableUserHeader } from 'data';
import useStyles from '../../titles-components/creation-view-components/TabArea/TabArea.styles';

export const UsersSection = memo(() => {
    const { content: encryptorUsers, page, searchValue, pending } = useAppSelector((state) => state.encryptorUsers);
    const service = useMemo(() => new UsersService(), []);
    const { setSearchValue, clearSearchValue, clearUsers } = encryptorUsersSlice.actions;
    const classes = useStyles();
    const { filteredEntities } = useSummaryFilter({ entities: encryptorUsers as GenericEntity[], type: 'users' });
    const { searchWorker, token } = useSearchWorker();
    const dispatch = useDispatch();
    const handleEncUserClick = useCallback((user) => dispatch(addEncryptorUserToSummary(user)), [dispatch]);
    const fetchNextPage = useCallback(async () => {
        if (service.baseParams.page <= (page?.totalPages ?? 0)) {
            service.setParams({
                page: service.baseParams.page + 1
            });
            await dispatch(fetchEncryptorUsers(service));
        }
    }, [dispatch, page?.totalPages, service]);
    useEffect(() => {
        dispatch(searchEncryptorUsers({ service, keyword: '' }));
        return () => {
            dispatch(clearUsers());
        };
    }, [dispatch, service, clearUsers]);
    return (
        <div className={classes.stack}>
            <SearchBar
                id='search'
                setPage={() => null}
                searchUtils={{
                    searchFn: searchWorker.searchEncryptorUsers,
                    token
                }}
                value={searchValue}
                clearSearchValue={clearSearchValue}
                setSearchValue={setSearchValue}
                placeholder='Search for a user'
            />
            <MainTable
                id='encUsersTable'
                entities={filteredEntities}
                isFetching={pending}
                searchValue={searchValue}
                noResultsMessage='No users returned'
                pageControl={{ page: service.baseParams.page, setPage: () => null }}
                fetchFn={fetchNextPage}
                totalPages={page?.totalPages}
                columns={actionableUserHeader}
                filterFetch
                onRowClick={handleEncUserClick}
            />
        </div>
    );
});
