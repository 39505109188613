import axios from 'axios';
import { msApi } from '@mediaseal-webui/api';
import { stopFetching, fetchGroups } from '@mediaseal-webui/actions';
import { setError, setEdit } from '../entity';
import {
    SET_TOTAL_DEC_USER,
    SET_SNACKBAR,
    SET_RECIPIENTS,
    FETCH_USERS,
    SET_FILTERED_ENC_USERS,
    SET_TOTAL_ENC_USERS,
    UPDATE_USER,
    CLEAR_USERS,
    SET_PROFILE,
    SET_USER_PAGE_COUNT,
    SET_ENCRYPTOR_USER_PAGE_COUNT,
    CLEAR_USER_PAGE_SEARCH_VALUE,
    SET_USER_PAGE_SEARCH_VALUE,
    FETCH_ENC_USERS
} from '@mediaseal-webui/constants';
export const fetchUsers = (payload) => async (dispatch, getState) => {
    try {
        const { data } = await msApi.get(
            `recipients/search/findAllContainingKeyword?keyword=${payload.keyword ? window.encodeURI(payload.keyword) : ''}&page=${payload.page}&size=${
                payload.size ? payload.size : 100
            }&sort=${payload.sort ? payload.sort : 'createDate,desc'}`
        );
        const { page, content } = data;
        const { totalPages, totalElements } = page;
        // const summaryIds = payload.filter ? getState().entity.summary.decryptorUsers : [];
        // const fetchedUsers = content.filter((user) => !summaryIds.find((u) => user.id === u.id));
        dispatch({
            type: payload.firstCall ? SET_RECIPIENTS : FETCH_USERS,
            payload: content
        });
        dispatch({ type: SET_TOTAL_DEC_USER, payload: totalElements });
        dispatch(setUserPages(totalPages));
    } catch (error) {
        console.error(error);
        dispatch(setError(error));
    }
    dispatch(stopFetching());
};

export const fetchEncryptorUsers = (payload) => async (dispatch, getState) => {
    try {
        const { data } = await msApi.get(
            `users/search/findAllContainingKeyword?keyword=${payload.keyword ? window.encodeURI(payload.keyword) : ''}&page=${payload.page}&size=${
                payload.size ? payload.size : 100
            }&sort=${payload.sort ? payload.sort : 'createDate,desc'}`
        );
        const { page, content } = data;
        const { totalPages, totalElements } = page;
        // const summaryIds = payload.filter ? getState().entity.summary?.users : [];
        // const fetchedUsers = content.filter((user) => !summaryIds.find((u) => user.id === u.id));
        dispatch({
            type: payload.firstCall ? SET_FILTERED_ENC_USERS : FETCH_ENC_USERS,
            payload: content
        });
        dispatch(setEncryptorUserPages(totalPages));
        dispatch({ type: SET_TOTAL_ENC_USERS, payload: totalElements });
    } catch (error) {
        dispatch(setError(error));
        console.log(error);
    }
    dispatch(stopFetching());
};

// export const fetchDecryptorUser = id => async (dispatch) => {
//   try {
//     const { data } = await msApi.get(`/recipients/${id}`)
//     dispatch({ type: SET_PROFILE, payload: data })
//     dispatch(fetchGroupsForDecUser(id))
//   } catch (error) {
//     dispatch(setError(error))
//   }
//   dispatch(stopFetching())
// }

export const fetchGroupsForDecUser = (id) => async (dispatch, getState) => {
    try {
        const { data } = await msApi.get(`/recipients/${id}/stationGroupCollection`);
        const { content } = data;
        const { profile } = getState().entity;
        const payload = {
            ...profile,
            decryptorGroups: content
        };
        dispatch({ type: SET_PROFILE, payload });
    } catch (error) {
        console.error(error);
        dispatch(setError(error));
    }
    dispatch(stopFetching());
};

export const fetchEncryptorUser = (id) => async (dispatch) => {
    try {
        const { data } = await msApi.get(`/users/${id}`);
        dispatch({ type: SET_PROFILE, payload: data });
        dispatch(setEdit());
        dispatch(fetchGroupsForEncUser(id));
    } catch (error) {
        console.error(error);
        dispatch(setError(error));
    }
    dispatch(stopFetching());
};

export const fetchGroupsForEncUser = (id) => async (dispatch, getState) => {
    try {
        const { data } = await msApi.get(`users/${id}/groupCollection`);
        const { content } = data;
        const { profile } = getState().entity;
        const payload = {
            ...profile,
            encryptorGroups: content
        };
        dispatch({ type: SET_PROFILE, payload });
        dispatch(setEdit());
    } catch (error) {
        dispatch(setError(error));
    }
    dispatch(stopFetching());
};

export const fetchDecryptorUser = (id) => async (dispatch) => {
    try {
        let userData = {};
        const userId = await msApi.get(`/recipients/${id}`);
        const groupsReq = msApi.get(`/recipients/${id}/stationGroupCollection`);
        const [user, groups] = await axios.all([userId, groupsReq]);
        userData = {
            ...user.data,
            decryptorGroups: groups.data.content
        };
        dispatch({ type: SET_PROFILE, payload: userData });
        dispatch(
            fetchGroups({
                keyword: '',
                sort: 'createDate,asc',
                page: 0,
                firstCall: true,
                filter: true
            })
        );
        dispatch(setEdit());
    } catch (error) {
        console.error(error);
        dispatch(setError(error));
    }
    dispatch(stopFetching());
};

/**
 * Update
 */
export const updateUser =
    (id, method = 'patch') =>
    async (dispatch, getState) => {
        try {
            const { edit } = getState().entity;
            const { loginName, firstName, lastName, email, contactNumber, encryptorGroups, password, active } = edit;
            const groupId = encryptorGroups?.length > 0 ? encryptorGroups.map((group) => `api/groups/${group.id}`) : [];
            const patchData = {
                loginName,
                firstName,
                lastName,
                email,
                contactNumber,
                active,
                password,
                groupCollection: [...groupId]
            };
            await msApi[method](`users/${id}`, patchData);
            // we use put to update the current user who is signed in so theres no permission issue
            if (method === 'put') dispatch({ type: UPDATE_USER, payload: { password: password } });
            dispatch({
                type: SET_SNACKBAR,
                payload: {
                    success: true,
                    message: `Successfully updated user: ${firstName} ${lastName}`
                }
            });
        } catch (error) {
            console.error(error);
            dispatch(setError(error));
        }
        dispatch(stopFetching());
        dispatch(fetchEncryptorUser(id));
    };

export const updateEncryptorUserAndRedirect = (id, history) => async (dispatch) => {
    await dispatch(updateUser(id));
    history.push('/users/encryptor');
};

export const updateDecryptorUser = (id) => async (dispatch, getState) => {
    try {
        const { edit } = getState().entity;
        const { firstName, lastName, email, decryptorGroups, active } = edit;
        const groupId = decryptorGroups?.length > 0 ? decryptorGroups.map((group) => `api/recipientGroups/${group.id}`) : [];
        const patchData = {
            firstName,
            lastName,
            email,
            active,
            stationGroupCollection: [...groupId]
        };
        await msApi.patch(`/recipients/${id}`, patchData);
        dispatch({
            type: SET_SNACKBAR,
            payload: {
                success: true,
                message: `Successfully updated user: ${firstName} ${lastName}`
            }
        });
    } catch (error) {
        console.log(error.response);
        dispatch(setError(error));
    }
    dispatch(stopFetching());
    dispatch(fetchDecryptorUser(id));
};

export const updateDecryptorUserAndRedirect = (id, history) => async (dispatch) => {
    await dispatch(updateDecryptorUser(id));
    history.push('/users/decryptor');
};
/**
 * Create
 */
export const createUser = (history) => async (dispatch, getState) => {
    try {
        const { loginName, firstName, lastName, emailAddress, password, contactNumber, encryptorGroups } = getState().entity.create;
        const groupIds = encryptorGroups?.length > 0 ? encryptorGroups.map((group) => `/api/groups/${group.id}`) : [];
        const body = {
            active: true,
            contactNumber,
            createDate: new Date().toISOString(),
            email: emailAddress,
            firstName,
            groupCollection: [...groupIds],
            id: 0,
            lastName,
            loginName,
            passHash: password
        };
        await msApi.post('/users', body);
        history.push('/users/encryptor');
        dispatch({
            type: SET_SNACKBAR,
            payload: {
                success: true,
                message: `Successfully created user: ${loginName}`
            }
        });
    } catch (error) {
        console.log(error.response);
        dispatch(setError({ message: error.response.data.errors[0] || error.message }));
    }
    dispatch(stopFetching());
};

/**
 * Search
 */
export const setUserPageSearchValue = (value) => ({
    type: SET_USER_PAGE_SEARCH_VALUE,
    payload: value
});
export const clearUserPageSearchValue = () => ({
    type: CLEAR_USER_PAGE_SEARCH_VALUE
});

/**
 * Pages
 */
export const setUserPages = (pages) => ({
    type: SET_USER_PAGE_COUNT,
    payload: pages
});
export const setEncryptorUserPages = (pages) => ({
    type: SET_ENCRYPTOR_USER_PAGE_COUNT,
    payload: pages
});

/**
 * Misc
 */
export const clearUsers = () => ({ type: CLEAR_USERS });
