import React from 'react';
import { useSelector } from 'react-redux';
import { InitialState } from '@mediaseal-webui/types/redux';
import { EditableHeaderChildProps, EditableHeaderItem } from '../../EditableHeaderItem';
import { BadgeIcon } from '../../BadgeIcon';
import { GenericHeaderItemProps } from '..';
import { Group as GroupIcon } from '@material-ui/icons';
import { HeaderItem } from '../HeaderItem';

export const EncGroupsHeaderItems = ({ isFetching, tooltipOpen, stateKey = 'profile', onClick }: GenericHeaderItemProps) => {
    const { encryptorGroups } = useSelector(({ entity }: InitialState) => entity[stateKey]);

    return encryptorGroups ? (
        <EditableHeaderItem title='Encryptor Group' open={tooltipOpen} total={encryptorGroups.length} onViewClick={onClick}>
            {({ hovering }: EditableHeaderChildProps) => (
                <HeaderItem
                    hovering={hovering}
                    Icon={() => <BadgeIcon Icon={GroupIcon} isHovered={hovering} content={encryptorGroups?.length} />}
                    isFetching={isFetching}
                    id='enc-groups-item'
                />
            )}
        </EditableHeaderItem>
    ) : null;
};
