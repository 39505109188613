import React from 'react';
import { LargeLogo } from '../LargeLogo/LargeLogo';
import { LargeLogoAlt } from '../LargeLogo/LargeLogoAlt';
import { StyledContainer, StyledProgress, StyledSubText } from './index.styles';
import { useSelector } from 'react-redux';
import { InitialState } from 'packages/types';

export const InitAnimation = () => {
    const currentTheme = useSelector((state: InitialState) => state.settings.misc.theme);

    return (
        <StyledContainer>
            {currentTheme === 'light' ? <LargeLogo /> : <LargeLogoAlt />}
            <StyledProgress variant='indeterminate' />
            <StyledSubText variant='body2' color='textPrimary'>
                Initializing application...
            </StyledSubText>
        </StyledContainer>
    );
};
