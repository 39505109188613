import React, { useEffect, useCallback } from 'react';

// import ErrorDialogue from '../../utility-components/ErrorDialogue';
import { Routes } from '../Routes';
import useStyles from './Main.styles';
import { useKeycloakConditionally, useSideNav } from '@mediaseal-webui/hooks';
import clsx from 'clsx';
import { fetchUserProfile } from 'reducers/user';
import { InitAnimation } from '@mediaseal-webui/components';
import { fetchUserPermissions } from 'reducers/permissions';
import { useAppDispatch, useAppSelector } from '../../../store/index';
import { unwrapResult } from '@reduxjs/toolkit';
import { fetchApiVersion, getServerSettings } from '@mediaseal-webui/actions';
import { useSelector } from 'react-redux';
import { InitialState } from '@mediaseal-webui/types';
import { isKeycloakActive } from '@mediaseal-webui/hooks/src/isKeycloakActive';

const Main: React.FC<{}> = () => {
    const { userCredentials } = useSelector((state: InitialState) => state.misc);
    const { loggedIn } = userCredentials;
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const { sideNavOpen } = useSideNav();
    const user = useAppSelector((state) => state.user);
    const permissions = useAppSelector((state) => state.permissions);
    const { keycloak } = useKeycloakConditionally() ?? {};
    const initialFetch = useCallback(async () => {
        dispatch(fetchUserProfile())
            //@ts-expect-error due to prop does not exist ts(2339).
            .then(unwrapResult)
            .then((result) => {
                dispatch(fetchUserPermissions(result.id ?? ''));
                // fetches the API version
                dispatch(fetchApiVersion());
                // fetches settings from the server -- Studio Name etc
                dispatch(getServerSettings());
            });
    }, [dispatch]);
    useEffect(() => {
        initialFetch();
    }, [dispatch, initialFetch]);
    const determineLayoutClass = () =>
        clsx(classes.mainLayout, {
            [classes.sideNavClosed]: !sideNavOpen
        });
    if (isKeycloakActive() && !user.fulfilled && !permissions.fulfilled) {
        return <InitAnimation />;
    }

    if (loggedIn || keycloak?.authenticated) {
        return (
            <div className={determineLayoutClass()} id='routeContainer'>
                <Routes />
            </div>
        );
    }
    return null;
};

export default Main;
