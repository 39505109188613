import { EncryptFile } from '@mediaseal-webui/types/encryptor';
import { ThunkResult } from '@mediaseal-webui/types/redux';
import { startPasswordOnlyWithPasswordEncryptionWork } from '../slots';

export const handleOfflineStart =
    (item: EncryptFile): ThunkResult<void> =>
    (dispatch, getState) => {
        const { password } = getState().entity.edit;
        password
            ? dispatch(
                  startPasswordOnlyWithPasswordEncryptionWork({
                      id: item.id,
                      fileSettings: {
                          headerVersion: 4,
                          additionalDataVersion: 2,
                          contactName: '',
                          contactNumber: '',
                          mediaKey: item.mediaKeyBase64,
                          fileId: item.id
                      },
                      fileFeatures: [],
                      password
                  })
              )
            : dispatch({ type: 'ERROR' });
    };
