import { TableCell } from '@material-ui/core';
import React from 'react';
import useStyles from './VerifiedCell.styles';
import clsx from 'clsx';
import { VerifiedUser, HourglassEmpty } from '@material-ui/icons';

const VerifiedCell: React.FC<{ isVerified: boolean }> = ({ isVerified }): JSX.Element => {
    const classes = useStyles();

    const Icon = () => {
        return isVerified ? <VerifiedUser className={clsx(classes.icon)} /> : <HourglassEmpty className={clsx(classes.icon, classes.notVerified)} />;
    };

    return (
        <TableCell component='div' align='center' classes={{ root: classes.tableCell }} scope='row'>
            <Icon />
        </TableCell>
    );
};
export { VerifiedCell };
