//TODO: Implement tag for fildId filter

import React, { FC, useState, useEffect, useMemo } from 'react';
import {
    StyledDialogue,
    StyledDropMessage,
    FileOptions,
    StyledSection,
    StyledAvatar,
    TextContainer,
    DropZone,
    DropInput,
    ErrorContainer,
    ErrorLottie,
    ErrorMessage,
    StyledCancel,
    StyledActions,
    FileData,
    FileName,
    FileId,
    StyledCloseBtn,
    SpinnerContainer,
    StyledCircularProgress
} from './FileDropDialgoue.styles';
import { Button, Typography } from '@material-ui/core';
import { FaClipboard, FaFile, FaFileDownload, FaFileImport } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { SET_AUDIT_TAB, SET_REPORT_DATA, SET_REPORT_ELEMENTS, SET_TOTAL_REPORT_PAGES, START_FETCHING, STOP_FETCHING } from '@mediaseal-webui/constants';
import { useAuditFileDrop } from '@mediaseal-webui/hooks';
import ErrorGif from './error.json';
import { useHistory } from 'react-router';
import { FileMetaInfo } from './FileMetaInfo/FileMetaInfo';
import { IoMdCloseCircle } from 'react-icons/io';
import { AuditService, keycloak } from '@mediaseal-webui/services';
import { genBasicAuth, genBearerAuth } from '@mediaseal-webui/utils';
import { isKeycloakActive } from '@mediaseal-webui/hooks/src/isKeycloakActive';
import { useWindowStore } from '@mediaseal-webui/hooks/src/useWindowStore';
import { SET_REPORTING_FILTER } from 'constants/reporting';

export const FileDropDialogue: FC = () => {
    const [showDiaglogue, setShowDialogue] = useState<boolean>(false);
    // const { username, password } = useSelector((state: InitialState) => state.misc.userCredentials);
    const [fileAccessFetching, setFileAccessFetching] = useState<boolean>(false);
    const [saveOutAccessFetching, setSaveOutAccessFetching] = useState<boolean>(false);
    // const service = new AuditService('/audit');
    const windowStore = useWindowStore();
    const auditService = useMemo(
        () =>
            new AuditService('/audits/fileAccess', {
                headers: {
                    authorization: isKeycloakActive()
                        ? genBearerAuth(keycloak.token)
                        : genBasicAuth({
                              username: windowStore.misc.userCredentials.username,
                              password: windowStore.misc.userCredentials.password
                          })
                }
            }),
        []
    );
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        file,
        getRootProps,
        getInputProps,
        isComplete,
        setGenericError,
        setIsComplete,
        genericError,
        filePermissionError,
        setFilePermissionError,
        setFile,
        mediaSealFileId,
        job
    } = useAuditFileDrop();
    // auditService.setAuth({ username, password });
    useEffect(() => {
        window.qt && window.GeneralWebInterface.allowFileDrop(false, { dropBehaviour: 'off' });
        return () => {
            window.qt && window.GeneralWebInterface.allowFileDrop(false, { dropBehaviour: 'passthrough' });
            setFileAccessFetching(false);
            setSaveOutAccessFetching(false);
        };
    }, []);
    const handleFetchClick = (type: 'fileAccess' | 'fileSave') => async () => {
        dispatch({ type: SET_AUDIT_TAB, payload: true });
        if (type === 'fileAccess') {
            setFileAccessFetching(true);
            dispatch({ type: SET_REPORTING_FILTER, payload: 0 });
        } else {
            setSaveOutAccessFetching(true);
            dispatch({ type: SET_REPORTING_FILTER, payload: 3 });
        }

        if (file) {
            auditService.url = `/audits/${type}`;
            auditService.setParams({
                fileId: mediaSealFileId,
                access: type === 'fileAccess' ? 'ANY' : ''
            });
        }
        setTimeout(() => {
            setShowDialogue(false);
        }, 1000);
        dispatch({ type: START_FETCHING });
        const { data } = await auditService.getAudits();
        dispatch({ type: SET_TOTAL_REPORT_PAGES, payload: data.totalPages });
        dispatch({ type: SET_REPORT_ELEMENTS, payload: data.totalElements });
        dispatch({ type: SET_REPORT_DATA, payload: data.content });
        dispatch({ type: STOP_FETCHING });
        type === 'fileAccess' ? setFileAccessFetching(false) : setSaveOutAccessFetching(false);
        setFile(null);
        setIsComplete(false);
    };
    const handleClose = () => {
        setIsComplete(false);
        setGenericError(null);
        setFilePermissionError(null);
        setFile(null);
        setShowDialogue(false);
    };
    useEffect(() => {
        window.addEventListener('dragenter', function dragenter(e) {
            setShowDialogue(true);
        });

        window.addEventListener('dragend', function dragend(e) {
            setShowDialogue(false);
        });
    }, []);
    const determineJobLinkText = () => (!!filePermissionError ? 'You do not have permissions to view the job' : 'Go to associated job');
    const handleJobClick = () => {
        history.push(`/jobs/${job?.id}/profile`);
    };
    const determineJobName = () => (job && job.name ? `Job - (${job.name})` : 'Job');
    return (
        <>
            {genericError ? (
                <StyledDialogue id='generic-error' open={showDiaglogue}>
                    <ErrorContainer>
                        <ErrorLottie animationData={ErrorGif} />
                        <ErrorMessage>{genericError?.message || filePermissionError?.message}</ErrorMessage>
                    </ErrorContainer>
                    <StyledActions>
                        <StyledCancel onClick={handleClose} variant='text' disableElevation>
                            Cancel
                        </StyledCancel>
                    </StyledActions>
                </StyledDialogue>
            ) : (
                <StyledDialogue id='file-drop-dialogue' open={showDiaglogue}>
                    <DropZone id='dropzone' {...getRootProps()}>
                        <DropInput type='file' {...getInputProps()} />
                        {!isComplete && (
                            <>
                                <StyledDropMessage show={!isComplete}>Drop file here to see audit data</StyledDropMessage>
                                <StyledCloseBtn show={!isComplete} onClick={handleClose}>
                                    Close
                                </StyledCloseBtn>
                            </>
                        )}
                        {isComplete && file && (
                            <FileData id='file-data-modal'>
                                <FileName>
                                    <FaFile />
                                    {file?.name}
                                </FileName>
                                <FileId>ID: {mediaSealFileId || ''}</FileId>
                                {!filePermissionError && <FileMetaInfo job={job} />}
                                <FileOptions>
                                    <StyledSection
                                        id='job-btn'
                                        disabled={!!filePermissionError}
                                        index={1}
                                        className={!!filePermissionError ? 'disabled' : ''}
                                        onClick={handleJobClick}>
                                        <StyledAvatar>
                                            <FaClipboard />
                                        </StyledAvatar>
                                        <TextContainer>
                                            <Typography variant='overline'>{determineJobName()}</Typography>
                                            <Typography variant='body2'>{determineJobLinkText()}</Typography>
                                        </TextContainer>
                                    </StyledSection>
                                    <StyledSection index={2} id='file-access' onClick={handleFetchClick('fileAccess')}>
                                        <StyledAvatar>
                                            <FaFileDownload />
                                        </StyledAvatar>
                                        <TextContainer>
                                            <Typography variant='overline'>FILE ACCESS</Typography>
                                            <Typography variant='body2'>View file access data for the file</Typography>
                                        </TextContainer>
                                        <SpinnerContainer>{fileAccessFetching && <StyledCircularProgress color='primary' />}</SpinnerContainer>
                                    </StyledSection>
                                    <StyledSection index={3} id='save-out' onClick={handleFetchClick('fileSave')}>
                                        <StyledAvatar>
                                            <FaFileImport />
                                        </StyledAvatar>
                                        <TextContainer>
                                            <Typography variant='overline'>SAVE OUT</Typography>
                                            <Typography variant='body2'>View save out data for the file</Typography>
                                        </TextContainer>
                                        {saveOutAccessFetching && <StyledCircularProgress />}
                                    </StyledSection>
                                    <StyledActions>
                                        <StyledCancel onClick={handleClose} variant='text' disableElevation>
                                            Cancel
                                        </StyledCancel>
                                    </StyledActions>
                                </FileOptions>
                            </FileData>
                        )}
                    </DropZone>
                </StyledDialogue>
            )}
        </>
    );
};
