import React from 'react';
import PropTypes from 'prop-types';
import { MSSearchBar as SearchBarWrapper } from '@mediaseal-webui/components';
import { useSearchWorker } from '../../../../hooks';
import useStyles from './SearchBar.styles';

/**
 *
 * @param {setPage} Func -- From parent view useState function
 * @param {searchFn} String -- The string name of the method on the searchworker to retrieve more results
 * @param {value} String -- The search value in redux state
 * @param {clear} Func -- The redux action to clear search state
 * @param {set} Func -- The redux action to set search state
 * @param {name} String -- The name of the entity as used in the Header
 * -- All other props will be passed down to the SearchBarWrapper component
 */
const SearchBar = ({ setPage, searchFn, value, clear, set, name, filters, ...others }) => {
    const classes = useStyles();
    const { searchWorker, token } = useSearchWorker();

    const placeholder = () => `Search for a ${name.toLowerCase().slice(0, name.length - 1)}`;

    return (
        <div className={`search ${classes.searchContainer}`}>
            <SearchBarWrapper
                fullWidth
                searchPadding={0}
                setPage={setPage}
                searchUtils={{
                    searchFn: searchWorker[searchFn],
                    token,
                    filters
                }}
                value={value}
                clearSearchValue={clear}
                setSearchValue={set}
                placeholder={placeholder()}
                data-testid='search'
                {...others}
            />
        </div>
    );
};

SearchBar.propTypes = {
    setPage: PropTypes.func,
    searchFn: PropTypes.string,
    value: PropTypes.string,
    set: PropTypes.func,
    name: PropTypes.string,
    clear: PropTypes.func
};

export default SearchBar;
