import React from 'react';
import { ISerializedPermissions } from '@mediaseal-webui/utils';
import PermissionSelection from '../../../utility-components/PermissionSelection';

export const tabs = (props: { permissions: ISerializedPermissions; readOnly: boolean }) => {
    return [
        {
            id: 0,
            label: 'Jobs/Titles',
            attrId: 'job-titles-btn',
            component: () => <PermissionSelection stateKey='jobsTitles' groups={props.permissions?.jobsTitles ?? []} readOnly={props.readOnly} />
        },
        {
            id: 1,
            label: 'Encryptor',
            attrId: 'encryptor-permissions-btn',
            component: () => <PermissionSelection stateKey='encryptor' groups={props.permissions?.encryptor ?? []} readOnly={props.readOnly} />
        },
        {
            id: 2,
            label: 'Decryptor',
            attrId: 'decryptor-permissions-btn',
            component: () => <PermissionSelection stateKey='decryptor' groups={props.permissions?.decryptor ?? []} readOnly={props.readOnly} />
        },
        {
            id: 3,
            label: 'Settings',
            attrId: 'settings-permissions-btn',
            component: () => <PermissionSelection stateKey='settings' groups={props.permissions?.settings ?? []} readOnly={props.readOnly} />
        },
        {
            id: 4,
            label: 'Reporting',
            attrId: 'reporting-permissions-btn',
            component: () => <PermissionSelection stateKey='reporting' groups={props.permissions?.reporting ?? []} readOnly={props.readOnly} />
        }
    ];
};
