import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    viewRoot: {
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            overflow: 'hidden'
        }
    },
    tabPanel: {},
    content: {
        top: theme.spacing(28),
        [theme.breakpoints.down('xs')]: {
            width: '100vw'
        }
    },

    widgetPadding: {
        paddingLeft: theme.spacing(2),
        overflow: 'scroll',
        marginTop: theme.spacing(3)
    },

    wrapper: {
        display: '',
        height: '100%',
        width: '100%',
        position: 'sticky'
    }
}));

export default useStyles;
