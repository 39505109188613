import React from 'react';
import { MdDelete } from 'react-icons/md';
import useSharedStyles from './sharedStyles';

export const DeleteIcon = () => {
    const classes = useSharedStyles();
    return (
        <div className={classes.iconRoot}>
            <MdDelete className={`${classes.singleIcon} danger`} />
        </div>
    );
};
