import { SET_ENCRYPTION_STARTED } from '@mediaseal-webui/constants';
import { EncryptFile } from '@mediaseal-webui/types/encryptor';
import { ThunkResult } from '@mediaseal-webui/types/redux';
import { findFile } from '@mediaseal-webui/utils';
import { RootState } from 'packages/online/src/store';

/**
 *
 * @param id string
 * @returns void
 * \
 * Triggered by QT whenever a file has started encrypting
 */
export const encryptionWorkStarted =
    (id: EncryptFile['id']): ThunkResult<void> =>
    (dispatch, getState: () => RootState) => {
        console.log(`Received Signal encryptionWorkStarted from QT with ID : ${id}`);
        const { encryptionWork } = getState().encryptor;
        const file = findFile(encryptionWork, id);
        dispatch({ type: SET_ENCRYPTION_STARTED, payload: { id } }); //update state
        dispatch({
            type: 'SET_SNACKBAR',
            payload: {
                info: true,
                message: `File: ${file?.inputFileName} has started encrypting`
            }
        }); //alert user
    };
