import React from 'react';
import { ISerializedPermissions } from '@mediaseal-webui/utils';
import PermissionSelection from '../../utility-components/PermissionSelection';
import DisplayPermissions from 'components/utility-components/DisplayPermissions/DisplayPermissions';

export const tabs = (props: {
    permissions: ISerializedPermissions;
    canAdminister: {
        value: boolean;
        allowedPermissions?: string[];
    };
}) => {
    const component = (groups, stateKey) => () => {
        return !props.canAdminister.value ? (
            <PermissionSelection stateKey={stateKey} groups={groups ?? []} />
        ) : (
            <DisplayPermissions groups={groups ?? []} allowedPermissions={props.canAdminister.allowedPermissions} />
        );
    };

    return [
        {
            id: 0,
            label: 'Jobs/Titles',
            attrId: 'job-titles-btn',
            component: component(props.permissions?.jobsTitles, 'jobsTitles')
        },
        {
            id: 1,
            label: 'Encryptor',
            attrId: 'encryptor-permissions-btn',
            component: component(props.permissions?.encryptor, 'encryptor')
        },
        {
            id: 2,
            label: 'Decryptor',
            attrId: 'decryptor-permissions-btn',
            component: component(props.permissions?.decryptor, 'decryptor')
        },
        {
            id: 3,
            label: 'Settings',
            attrId: 'settings-permissions-btn',
            component: component(props.permissions?.settings, 'settings')
        },
        {
            id: 4,
            label: 'Reporting',
            attrId: 'reporting-permissions-btn',
            component: component(props.permissions?.reporting, 'reporting')
        }
    ];
};
