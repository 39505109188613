import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    modalWrapper: {
        display: 'flex',
        flexFlow: 'row',
        textAlign: 'center',
        minHeight: theme.sizes.securityModal.desktop.minHeight,
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        }
    },
    thirds: {
        width: theme.sizes.securityModal.desktop.cols,
        padding: theme.spacing(2),
        position: 'relative',
        height: theme.spacing(53),
        [theme.breakpoints.down('md')]: {
            height: theme.spacing(67)
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: theme.spacing(44)
            // height: theme.spacing(44),
            // marginBottom: theme.spacing(7.5)
        },
        [theme.breakpoints.down('xs')]: {
            height: '100%'
        }
    },
    button: {
        marginTop: 30,
        position: 'absolute',
        bottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            marginTop: 0,
            bottom: 0
        }
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    icon: {
        opacity: 0.75,
        textAlign: 'center',
        color: theme.palette.primary.main
    },
    borders: {
        //   borderWidth: 2,
        //   borderStyle: 'solid',
        //   borderImage: `linear-gradient(white, ${theme.palette.secondary.light}, white})`
        //   borderLeft: `1px solid linear-gradient(180deg, rgba(255,255,255,0.8155637254901961) 0%, rgba(147,149,151,0.7567401960784313) 50%, rgba(255,255,255,0.8183648459383753) 100%)`,
        //   borderRight: `1px solid linear-gradient(180deg, rgba(255,255,255,0.8155637254901961) 0%, rgba(147,149,151,0.7567401960784313) 50%, rgba(255,255,255,0.8183648459383753) 100%)`,
    }
}));
