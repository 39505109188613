import React, { useCallback, memo } from 'react';
import { Typography, ListItemIcon, Avatar, Grid, ListItemText, ListItemAvatar, IconButton, Tooltip } from '@material-ui/core/';
import ClearIcon from '@material-ui/icons/Clear';
import useUserItemStyles from './UserItem.styles';
import { Skeleton } from '@material-ui/lab';
import { User } from '@mediaseal-webui/types/redux';

interface UserItemProps {
    data: User;
    setterFn: Function;
    isFetching: boolean;
}

export const UserItem = memo(({ data, setterFn, isFetching, ...others }: UserItemProps) => {
    const itemClasses = useUserItemStyles();
    const handleClick = useCallback((data) => setterFn(data), [setterFn]);
    if (isFetching) {
        return (
            <div className={itemClasses.itemContainer}>
                <div className={itemClasses.avatarContainer}>
                    <Skeleton animation='pulse' variant='circle' width={30} height={30} />
                </div>
                <div className={itemClasses.nameContainer}>
                    <Skeleton animation='pulse' height={15} width='75%' />
                    <Skeleton animation='pulse' height={15} width='50%' />
                </div>
            </div>
        );
    } else {
        return (
            <Grid alignItems='center' container>
                <Grid item xs={2}>
                    <ListItemAvatar>
                        <Avatar className={itemClasses.userAvatar}>
                            {data.firstName ? `${data.firstName.charAt(0).toUpperCase()}` : `${data.name.charAt(0).toUpperCase()}`}
                        </Avatar>
                    </ListItemAvatar>
                </Grid>
                <Grid item className={itemClasses.nameEmail} xs={9}>
                    <ListItemText>
                        <Typography color='textPrimary' variant='body2' display='block'>
                            {data.firstName ? `${data.firstName} ${data.lastName}` : `${data.name}`}
                        </Typography>
                        <Typography color='textPrimary' variant='body2' display='block'>
                            {data.email}
                        </Typography>
                    </ListItemText>
                </Grid>
                <Grid item className={itemClasses.clearIconContainer} xs={1}>
                    <ListItemIcon>
                        <Tooltip placement='top' title='Remove'>
                            <IconButton id='remove-user' className={itemClasses.removeIcon} data-testid='remove' onClick={handleClick(data)}>
                                <ClearIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                    </ListItemIcon>
                </Grid>
            </Grid>
        );
    }
});
