import { createMuiTheme } from '@material-ui/core/styles';

export const createTheme = (type) =>
    createMuiTheme({
        sizes: {
            summaryList: {
                desktopHeight: '70vh'
            },
            tables: {
                user: {
                    forms: 500
                },
                forms: 400
            },
            securityModal: {
                desktop: {
                    cols: '33%',
                    minHeight: 300
                },
                mobile: {},
                pills: {
                    width: 72,
                    height: 32
                }
            },
            histogram: {
                width: 350,
                height: 122
            },
            sideNav: {
                open: 200,
                closed: 72,
                accountNav: 250
            },
            header: {
                mobile: 14 * 8,
                open: 160,
                withTabs: 170,
                closed: 56
            },
            searchBar: {
                normal: 'calc(100% - 300px)'
                // small: 108
            },
            bottomNav: 60
        },
        palette: {
            type,
            ...(type === 'light'
                ? {
                      primary: {
                          main: '#007E9C',
                          light: '#0BB0BE', // #20a8d8
                          dark: '#024B78'
                      },
                      secondary: {
                          main: '#1D1C1D',
                          light: 'rgba(29,28,29,0.25)'
                      },
                      text: {
                          primary: '#1D1C1D'
                      },
                      paper: {
                          dark: 'rgba(0,0,0,0.5)'
                      },

                      info: {
                          light: '',
                          dark: '',
                          main: '#007E9C'
                      },
                      progress: {
                          main: '#13a1f3',
                          light: '#af68ae',
                          dark: '#49bbc6'
                      },
                      pills: {
                          main: '#007E9C',
                          inactive: 'rgba(0,0,0,0.25)'
                      },
                      buttons: {
                          main: '#007E9C',
                          dark: '#ff6666',
                          light: '#0BB0BE',
                          disabled: 'rgba(29,28,29,0.25)'
                      },
                      table: {
                          main: 'rgba(29, 28, 29, 0.25)'
                      },
                      success: {
                          main: '#007E9C'
                      },
                      error: {
                          main: '#ff6666'
                      }
                  }
                : {
                      primary: {
                          main: '#007E9C',
                          light: '#0BB0BE', // #20a8d8
                          dark: '#024B78'
                      },
                      secondary: {
                          main: '#ffffff',
                          light: 'rgba(255,255,255,0.25)'
                      },
                      text: {
                          primary: '#ffffff'
                      },
                      background: {
                          default: '#2b2a2b', // sets most backward layer of background
                          paper: '#1D1C1D' // sets most forward layer of background
                      },
                      info: {
                          light: '',
                          dark: '',
                          main: '#007E9C'
                      },
                      progress: {
                          main: '#13a1f3',
                          light: '#af68ae',
                          dark: '#49bbc6'
                      },
                      pills: {
                          active: '#007E9C',
                          inactive: 'rgba(255,255,255,0.25)'
                      },
                      buttons: {
                          main: '#007E9C',
                          dark: '#ff6666',
                          light: '#0BB0BE',
                          disabled: 'rgba(255,255,255,0.25)'
                      },
                      table: {
                          main: 'rgba(255, 255, 255, 0.25)'
                      },
                      success: {
                          main: '#007E9C'
                      },
                      error: {
                          main: '#ff6666'
                      }
                  })
        },
        typography: {
            fontWeightMedium: 500,
            h1: {
                fontWeight: 800
            },
            h2: {
                fontWeight: 700
            },
            h3: {
                fontWeight: 600
            },
            h4: {
                fontWeight: 600
            },
            h5: {
                fontWeight: 600
            },
            h6: {
                fontWeight: 600
            },
            body1: {
                fontWeight: 500,
                fontFamily: [
                    '"Montserrat", "Segoe UI"',
                    '"Helvetica Neue"',
                    'Arial',
                    'sans-serif',
                    '"Apple Color Emoji"',
                    '"Segoe UI Emoji"',
                    '"Segoe UI Symbol"'
                ].join(',')
            },
            body2: {
                fontWeight: 500,
                fontFamily: [
                    '"Montserrat", "Segoe UI"',
                    '"Helvetica Neue"',
                    'Arial',
                    'sans-serif',
                    '"Apple Color Emoji"',
                    '"Segoe UI Emoji"',
                    '"Segoe UI Symbol"'
                ].join(','),
                wordWrap: 'break-word'
            },
            fontFamily: [
                '"Montserrat", "Segoe UI"',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"'
            ].join(',')
        }
    });

const theme = createTheme('light');

export const lightTheme = {
    ...theme,
    palette: {
        ...theme.palette,
        type: 'light'
    }
};

export const darkTheme = {
    ...theme,
    palette: {
        ...theme.palette,
        type: 'dark',
        background: {
            paper: ''
        }
    }
};
export default theme;
