import { ExportToCsv, Options } from 'export-to-csv';
import { ActivityAuditData, AuditData, CSVInitArgs, OpenAssets, WorkerData } from './types';
var flatMap = require('array.prototype.flatmap');

export class CSVService extends ExportToCsv {
    data: AuditData[];
    tabRegex: RegExp;
    doubleQuoteRegex: RegExp;
    dateCheck: RegExp;
    commaRegex: RegExp;
    newLineRegex: RegExp;
    progress: number;
    constructor(public extraOptions?: Partial<Options>) {
        super(extraOptions);
        this.data = [];
        this.tabRegex = /\+|-|@|=/;
        this.doubleQuoteRegex = /"/g;
        this.commaRegex = /,/g;
        this.newLineRegex = /\n/g;
        this.dateCheck = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d/;
        this.progress = 0;
        this.options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            ...this.extraOptions
        };
    }

    public async fetchData({ service, pages, type, messenger }: CSVInitArgs) {
        try {
            for (let index = 0; index < pages; index++) {
                const { data } = await service.getAudits();
                this.data.push(...data.content);
                this.progress = this._calcProgress(index, pages);
                messenger &&
                    messenger({
                        progress: this.progress,
                        status: 'Fetching audit data...'
                    });
                service.setParams({
                    page: service?.auditParams?.page + 1
                });
            }
            service.setParams({
                page: 0
            });

            const sanitized = this._sanitizeData(this.data);

            let formatted;

            const formatType = () => {
                if (type === 'fileAccess') formatted = this.formatFileAccess(sanitized);
                if (type === 'saveOut') formatted = this.formatSaveOut(sanitized);
                if (type === 'activity') formatted = this.formatActivity(sanitized);
            };

            formatType();

            this.data = [];
            return formatted;
        } catch (error) {
            console.error(error);
            messenger && this.messengerService(error as Error, messenger);
            this.clearState();
            throw new Error('Error initiating service');
        }
    }

    public generateReport(data: AuditData[], fileName) {
        this.options.title = fileName;
        this.options.filename = fileName;
        this.generateCsv(data);

        this.clearState();
    }

    public formatActivity(data: ActivityAuditData[]) {
        try {
            const format = data.map((audit) => {
                const filtered = this._nullCheck(audit) as ActivityAuditData;
                return {
                    id: filtered.auditId,
                    action: filtered.action,
                    entityType: filtered.entityType,
                    entityId: filtered.entityId,
                    actionDate: filtered.actionDate,
                    tokenId: filtered.actionBy.tokenId,
                    userId: filtered.actionBy.userId,
                    name: filtered.actionBy.name,
                    machineInfo: filtered.machineInfo
                };
            });
            return format;
        } catch (error) {
            console.error(error);
            throw new Error('Error formatting data!');
        }
    }

    public formatFileAccess(data: AuditData[]) {
        try {
            const format = data.map((audit) => {
                const filtered = this._nullCheck(audit) as AuditData;
                return {
                    id: filtered.id,
                    date: audit.date,
                    user: audit?.user?.fullName || '',
                    job: audit?.job?.name,
                    fileId: audit.file ? audit.file.id : audit.fileId,
                    type: filtered.accessType,
                    message: this._constructFileAccessMessage(filtered as AuditData),
                    macAddress: audit.macAddresses || '',
                    ipAddresses: audit.ipAddresses || '',
                    hostUsername: audit.hostUserName || ''
                };
            });
            return format;
        } catch (error) {
            console.error(error);
            throw new Error('Error formatting data!');
        }
    }

    public formatSaveOut(data: AuditData[]) {
        const self = this;
        try {
            return flatMap(data, (audit) =>
                audit.openAssets.map((asset) => {
                    const filtered = this._nullCheck(audit) as AuditData;
                    return {
                        id: audit.id,
                        date: audit.date,
                        user: asset?.user?.fullName || '',
                        type: filtered.accessType,
                        message: self._createSaveOutMessage(audit, asset),
                        macAddress: audit.macAddresses || '',
                        ipAddresses: audit.ipAddresses || '',
                        hostUsername: audit.hostUserName || '',
                        saveFileSize: self._formatFileSize(audit?.saveFileSize)
                    };
                })
            );
        } catch (error) {
            console.error(error);
        }
    }

    public clearState() {
        this.data = [];
        this.progress = 0;
    }

    public _nullCheck(audit: AuditData | ActivityAuditData) {
        return Object.entries(audit).reduce((acc, [key, value]) => ({ ...acc, [key]: value === null ? '' : value }), {});
    }

    public _constructFileAccessMessage(data: AuditData) {
        return `Access to file:(id=${data.fileId}) from Job: "${data.job.name}(id=${data.job.id})" was "${data.accessType}" for process "${data.processName}" `;
    }
    public _createSaveOutMessage(audit: AuditData, openAsset: OpenAssets) {
        return `Saved out file: "${audit.outFilePath}" (${audit.saveFileSize}) by process "${audit.processName}"\nOpen Assets:\n- File Name: "${
            openAsset.userFilePath
        }", opened by: "${openAsset.user?.id || ''}", with process: "${openAsset.processName}"\nFile Id: "${openAsset.fileId}"\nJob: "${openAsset.job.name}-${
            openAsset.job.id
        }"`;
    }

    public _sanitizeData(data: AuditData[]) {
        return data.map((audit) => {
            return Object.entries(audit).reduce((acc, [key, value]: [string, string | {}]) => {
                if (value && Array.isArray(value)) {
                    return {
                        ...acc,
                        [key]: this._sanitizeData(value)
                    };
                }
                if (value && typeof value === 'object') {
                    return {
                        ...acc,
                        [key]: Object.entries(value).reduce(
                            (acc, [k, v]: [string, unknown]) => ({
                                ...acc,
                                [k]: this.sanitizeEntry(v as string)
                            }),
                            {}
                        )
                    };
                } else {
                    return {
                        ...acc,
                        [key]: this.sanitizeEntry(value as string)
                    };
                }
            }, {} as AuditData);
        });
    }

    public sanitizeEntry(value: string) {
        if (value && typeof value === 'string') {
            if (this.dateCheck.test(value)) {
                return value;
            } else {
                const clean = value.replace(this.doubleQuoteRegex, '""');
                const rinse = this.commaRegex.test(clean) || this.newLineRegex.test(clean) ? `"${clean}"` : clean;
                const dry = this.tabRegex.test(rinse) ? '\t' + rinse + '\t' : rinse;
                return dry;
            }
        } else {
            return value;
        }
    }

    public _formatFileSize(fileSize: number, idx: number = 0) {
        const units = ['B', 'KB', 'MB', 'GB'];
        if (fileSize < 1024 || idx === units.length - 1) {
            return fileSize.toFixed(1) + units[idx];
        }
        return this._formatFileSize(fileSize / 1024, ++idx);
    }

    public _calcProgress(index, pages) {
        return Math.floor((index / pages) * 100);
    }

    public messengerService(error: Error, messenger: (data: WorkerData) => void) {
        messenger({ error });
    }
}

export const csvService = new CSVService();

if (!Object.entries) {
    Object.entries = function (obj) {
        var ownProps = Object.keys(obj),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array
        while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

        return resArray;
    };
}
