import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        top: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 1s ease'
    },
    logo: {
        width: theme.spacing(6)
    },
    title: {
        fontSize: '1.16rem',
        fontWeight: 'bolder',
        color: 'white'
    },
    subtitle: {
        fontSize: '0.75rem',
        opacity: 0.5,
        color: 'white'
    },
    titleContainer: {
        marginLeft: theme.spacing(1)
    },
    black: {
        color: 'black',
        opacity: 1
    }
}));

export default useStyles;
