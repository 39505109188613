import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    widgets: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    module: {
        padding: theme.spacing(4),
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center'
    },
    widget: {
        margin: theme.spacing(1.5),
        color: theme.palette.common.white,
        [theme.breakpoints.down('sm')]: {
            width: `calc(50% - ${theme.spacing(3)}px)`
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    totalWidget: {
        backgroundColor: theme.palette.primary.main
    },
    grantedWidget: {
        backgroundColor: theme.palette.primary.light
    },
    deniedWidget: {
        backgroundColor: theme.palette.error.main
    },
    moduleSubtitle: {
        width: '100%',
        marginLeft: theme.spacing(3)
    },
    circleContainer: {
        width: '50%',
        display: 'flex',
        justifyContent: 'center'
    }
}));

export default useStyles;
