import { makeStyles, LinearProgress } from '@material-ui/core';
import { withStyles, lighten } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    fileListItem: {
        marginTop: theme.spacing(1),
        width: '100%'
    },
    fileType: {
        color: theme.palette.secondary.light
    },
    fileName: {
        fontSize: '0.75em'
    },
    progressCont: {
        width: '100%'
    },
    metaInfo: {
        display: 'flex',
        alignItems: 'center'
    },
    actionsContainer: {
        textAlign: 'right'
    },
    progressContainer: {
        marginTop: 10
    },
    filePercentage: {
        display: 'inline'
    }
}));

export const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 2.5,
        width: '100%',
        backgroundColor: lighten(theme.palette.primary.light, 0.5)
    },
    bar: {
        borderRadius: 5,
        // backgroundColor: '#ff6c5c',
        backgroundColor: theme.palette.primary.light
    }
}))(LinearProgress);
export default useStyles;
