import React from 'react';
import { TableCell } from '@material-ui/core';
import useStyles from './PermissionCell.styles';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { BadgeIcon } from '../../../BadgeIcon/BadgeIcon';

interface PermissionCellProps {
    count: number;
    permissions: string[];
}

export const PermissionsCell = React.memo(({ count }: PermissionCellProps) => {
    const classes = useStyles();

    return (
        <TableCell component='div' align='center' classes={{ root: classes.tableCell }} scope='row'>
            <BadgeIcon Icon={VpnKeyIcon} content={count} />
        </TableCell>
    );
    // }
});
