import { SET_FILE_PROCESSED } from '@mediaseal-webui/constants';
import { IEncryptionStartProps } from '@mediaseal-webui/types/encryptor';
/**
 *
 * @param param0
 * @returns
 *
 *
 * Used to signal to QT to start offline encryption work
 */
export const startOfflineEncryptionWork = ({ id, fileSettings, fileFeatures = [], encryptedAcl }: IEncryptionStartProps) => {
    console.log('calling startOfflineEncryptionWork');
    window.EncryptionWebInterface.startOfflineEncryptionWork(id, fileSettings, fileFeatures, encryptedAcl || '');
    return { type: SET_FILE_PROCESSED, payload: { id } };
};
