import React, { useState } from 'react';
import clsx from 'clsx';
import { TextareaAutosize } from '@material-ui/core';
import useStyles from './TextArea.styles';
import { TextAreaProps } from '.';

const TextArea = ({
    name,
    onChange = () => null,
    rows,
    placeholder,
    // validation,
    value = '',
    // required = false,
    className,
    disabled
}: TextAreaProps) => {
    const classes = useStyles();

    // const { change, addField, removeField, values } = useForm();

    const [focussed, setFocussed] = useState<boolean>(false);
    const [fieldValue, setFieldValue] = useState<string>(value);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(event);
        setFieldValue(event.target.value);
    };

    const handleBlur = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(event);
        setFocussed(false);
    };

    const handleFocus = (event: React.FocusEvent): void => setFocussed(true);

    return (
        <div
            className={clsx(classes.wrapper, className, {
                [classes.focus]: focussed
            })}>
            <TextareaAutosize
                id='description'
                rows={rows}
                className={classes.textArea}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                name={name}
                placeholder={placeholder}
                value={fieldValue}
                disabled={disabled}
                data-testid='description'
            />
        </div>
    );
};

export default TextArea;
