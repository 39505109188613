import React from 'react';
import { Summary, Files, Users, Groups } from '..';
import { AddGroup, AddUser } from '@mediaseal-webui/components/src/icons';
import { updateJob } from '../../../../actions/jobs';
import { UserSelection, GroupSelection } from '../../../utility-components/Modals';
import { ITab } from '../../../layout-components/Header';

export const tabs: ITab[] = [
    {
        id: 1,
        attrId: 'analytics-tab',
        label: 'Analytics',
        boxDisplay: 'flex',
        mobileOnly: false,
        component: <Summary />
    },
    {
        id: 2,
        attrId: 'file-tab',
        label: 'Files',
        mobileOnly: false,
        component: <Files />
    },
    {
        id: 3,
        attrId: 'users-tab',
        btnId: 'add-users',
        label: 'Users',
        cardId: 'users-panel',
        mobileOnly: false,
        component: <Users />,
        modal: ({ openProps }) => <UserSelection openProps={openProps} title='Add decryptor users' updateFn={updateJob} />,
        Icon: AddUser,
        addButtonTitle: 'Add users'
    },
    {
        id: 4,
        attrId: 'groups-tab',
        btnId: 'add-groups',
        cardId: 'groups-panel',
        label: 'Groups',
        mobileOnly: false,
        component: <Groups />,
        modal: ({ openProps }) => <GroupSelection openProps={openProps} title='Add decryptor groups' updateFn={updateJob} />,
        Icon: AddGroup,
        addButtonTitle: 'Add groups'
    }
];
