import styled, { css } from 'styled-components';
import { makeStyles, lighten } from '@material-ui/core';

export const IconContainer = styled.span`
    display: flex;
    width: 100%
    height: 100%;
`;
export const useStyles = makeStyles((theme) => ({
    selected: {
        // backgroundColor: `rgb(67 167 186 / 29%) !important`,
        color: `${theme.palette.common.white} !important`,
        backgroundColor: '#4f9db3 !important'
    }
}));
