import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(1)
    },
    mainSubtitle: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    item: {
        marginRight: theme.spacing(1),
        display: 'inline-flex',
        alignItems: 'center'
    },
    scrollContainer: {
        width: '100%',
        maxHeight: 500,
        overflow: 'scroll'
    },
    modalHeader: {
        border: `1px 1px 0 1px solid ${theme.palette.secondary.light}`,
        display: 'flex',
        justifyContent: 'space-between'
    },
    blur: {
        backdropFilter: 'blur(10px)'
    },
    button: {
        background: theme.palette.secondary.light,
        borderRadius: '30px',
        color: theme.palette.text.primary,
        height: '40px',
        padding: '0 16px'
    }
}));
