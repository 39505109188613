import styled from 'styled-components';

export const PasswordPoints = styled.ul`
    text-align: left;
    margin: 0;
    margin-bottom: 8px;

    li {
        padding-bottom: 4px;
    }
`;
