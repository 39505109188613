import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    settingsCard: {
        display: 'flex',
        // flexWrap: 'wrap',
        minHeight: 200,
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2),
        margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: '0',
        [theme.breakpoints.down('sm')]: {
            overflow: 'unset !important',
            flexDirection: 'column',
            justifyContent: 'unset',
            height: '100%'
        }
    },
    settingTitle: {
        width: '35%'
    },
    cardContent: {
        display: 'flex',
        alignItems: 'center',
        // with: '60%'
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    formField: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: theme.spacing(2)
        }
    },
    headingContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '70%'
    },
    heading: {
        marginBottom: theme.spacing(1)
    }
}));

export const useInputStyles = makeStyles((theme) => ({
    input: {
        fontSize: 'x-large',
        padding: theme.spacing(2)
    }
}));
