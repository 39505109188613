import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import { fetchInvites, clearInvites, deleteInvite, resendInvite } from '@mediaseal-webui/actions';
import { inviteHeader } from '../../../../data';
import useStyles from './InviteDecryptorUserTableStyle.styles';
import CustomActionsTable from '../../../table-components/CustomActionsTable/CustomActionsTable';
import { useAuth } from '@mediaseal-webui/hooks';
import { setConfirmDialogue } from '../../../../actions/misc';

const InviteDecryptorUserTable = ({ status, id }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const auth = useAuth();
    const isCreateAuth = auth.create || auth.administer;
    const { pendingInvites, rejectedInvites, totalRejectedPages, totalPendingPages } = useSelector(({ invitations }) => invitations);

    const filteredInvites = status === 'PENDING' ? pendingInvites : rejectedInvites;
    const [pendingPage, setPendingPage] = useState(1);
    const [rejectedPage, setRejectedPage] = useState(1);

    const fetchFn = () => {
        const page = status === 'PENDING' ? pendingPage : rejectedPage;
        dispatch(fetchInvites({ status, page: page, firstCall: false }));
    };

    useEffect(() => {
        dispatch(fetchInvites({ status, page: 0, firstCall: true }));
        return () => dispatch(clearInvites());
    }, [dispatch, status]);

    const handleDelete = (invite) => (event) => {
        const action = () => {
            dispatch(deleteInvite(invite));
            dispatch(fetchInvites({ status, page: 0, firstCall: true }));
        };

        dispatch(
            setConfirmDialogue({
                action,
                icon: 'delete',
                title: 'Delete Invitation',
                message: 'Are you sure you want to delete this invitation?',
                confirmButtonText: 'Delete'
            })
        );
    };

    const handleResend = (invite) => (event) => {
        dispatch(resendInvite(invite));
        dispatch(fetchInvites({ status, firstCall: true }));
    };

    const onRowClick = () => {};

    const TableActions = (entity) =>
        isCreateAuth && (
            <>
                <Tooltip title='Delete invite' aria-label='delete'>
                    <IconButton onClick={handleDelete(entity)} id='delete-invite' data-testid='deleteIcon' className={classes.iconButton}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Resend invite' aria-label='resend'>
                    <IconButton onClick={handleResend(entity)} id='resend-invite' className={classes.iconButton}>
                        <SendIcon />
                    </IconButton>
                </Tooltip>
            </>
        );

    return (
        <CustomActionsTable
            entities={filteredInvites}
            id={id || 'invites-table'}
            totalPages={status === 'PENDING' ? totalPendingPages : totalRejectedPages}
            pageControl={status === 'PENDING' ? { page: pendingPage, setPage: setPendingPage } : { page: rejectedPage, setPage: setRejectedPage }}
            fetchFn={fetchFn}
            onRowClick={onRowClick}
            noResultsMessage={status === 'PENDING' ? 'There are currently no pending invites' : 'There are currently no rejected invites'}
            columns={inviteHeader}
            actions={TableActions}
            status={status}
        />
    );
};

InviteDecryptorUserTable.propTypes = {
    status: PropTypes.oneOf(['PENDING', 'REJECTED']).isRequired
};

export default InviteDecryptorUserTable;
