import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        // boxShadow: ' 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
        zIndex: 3,
        // background: 'white',
        transition: 'all 1s ease',
        padding: theme.spacing(2),
        width: '100%'
    },
    fileList: {
        zIndex: 7,
        position: 'fixed',
        left: '50%',
        bottom: 0,
        transform: 'translateX(-50%)',
        width: '60%',
        // boxShadow: ' 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
        display: 'flex',
        background: theme.palette.background.paper,
        minHeight: 100,
        boxShadow: theme.shadows[1],
        [theme.breakpoints.down('xs')]: {
            minWidth: '100%'
        }
    },
    summary: {
        fontSize: '1.0em',
        display: 'flex',
        width: '50%'
    },
    iconContainer: {
        textAlign: 'right'
    },
    margin: {
        marginTop: theme.spacing(1),
        borderRadius: 5
    },
    subtitle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    button: {
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#fff'
        },
        color: theme.palette.info.main,
        fontSize: '1em',
        marginLeft: theme.spacing(0.5)
    },
    textContainer: {
        display: 'flex',
        width: '100%'
    },
    launchIcon: {
        marginLeft: theme.spacing(1),
        color: theme.palette.info.main,
        width: theme.spacing(2),
        height: theme.spacing(2)
    },
    items: {
        justifyContent: 'center',
        display: 'flex',
        width: '50%',
        alignItems: 'center'
    },
    finishedItems: {
        color: theme.palette.success.main,
        marginRight: theme.spacing(2)
    },
    inProcessItems: {
        color: theme.palette.info.main,
        marginRight: theme.spacing(4)
    },
    cancelledItems: {
        color: theme.palette.error.main,
        marginRight: theme.spacing(4)
    },
    finishIcon: {
        color: theme.palette.success.main,
        marginRight: 5
    },
    closeIcon: {
        marginRight: 5
    }
}));

export default useStyles;
