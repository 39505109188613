import React, { useRef, FC, RefObject, useEffect } from 'react';
import useStyles from './CreateForm.styles';
import AuthMethods from '../AuthMethods';
import TabArea from '../TabArea';
import { Typography, Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setCreate } from '../../../../actions/entity';
import { TextInput, PasswordInput } from '@mediaseal-webui/components';
import { useEntityCreation } from '@mediaseal-webui/hooks';
import { useTooltipStyles } from '../../../utility-components/FormField/FormField.styles';
import { InitialState } from '@mediaseal-webui/types/redux';
import { usePasswordScore } from '@mediaseal-webui/hooks/src/usePasswordScore';
import { TitleSelection } from 'components/jobs-components/creation-view-components/TitleSelection';
import { clearSummary } from '@mediaseal-webui/actions';

const CONFIRM_MESSAGE = 'Please ensure both passwords are the same.';

interface CreateFormProps {}

const CreateForm: FC<CreateFormProps> = () => {
    const classes = useStyles();
    const tooltipClasses = useTooltipStyles();

    const dispatch = useDispatch();
    const { create, errors } = useSelector(({ entity }: InitialState) => entity);
    const { updatePassword } = usePasswordScore();
    const { genericNameValidation, jobPasswordValidation, confirmPassword, titleValidation } = useEntityCreation([
        'name',
        'title',
        'password',
        'confirmPassword'
    ]);
    const { authMethod } = create;

    const authRef = useRef<HTMLDivElement>(null);
    const passwordRef = useRef<HTMLDivElement>(null);

    const scrollToRef = (ref: RefObject<HTMLDivElement>) => ref?.current && window.scrollTo(0, ref.current.offsetTop);
    const handleAuthChange = () => scrollToRef(authRef);

    const handleChange = (name: string) => (value: string) => {
        if (name === 'password') {
            updatePassword(value);
        }
        dispatch(setCreate({ [name]: value }));
    };

    useEffect(
        () => () => {
            dispatch(clearSummary());
        },
        [dispatch]
    );
    return (
        <form className={classes.root} id='jobCreationForm'>
            <div className={classes.nameContainer}>
                <Typography color='textPrimary' variant='h5' className={classes.title}>
                    Name
                </Typography>
                <Typography variant='body1' color='textSecondary'>
                    Enter a descriptive name that describes the current job
                </Typography>
            </div>

            <div className={classes.inputContainer}>
                <TextInput
                    id='job-name'
                    name='name'
                    validation={genericNameValidation}
                    setterFn={handleChange('name')}
                    tooltipText='Please enter a name between 2 and 64 characters'
                />
                {/* <FormField name="name" validation={nameLength} value={name} onChange={handleChange('name')} onSubmit={handleSubmit} required /> */}
            </div>

            <div className={classes.nameContainer}>
                <Typography color='textPrimary' variant='h5' className={classes.title}>
                    Title
                </Typography>
                <Typography variant='body1' color='textSecondary'>
                    Search for a relevant title that this job will be associated to
                </Typography>
            </div>

            <Tooltip
                title={errors?.TitleError?.message ?? ''}
                placement='top'
                open={Object.keys(errors ?? {}).includes('TitleError')}
                arrow
                classes={tooltipClasses}>
                <div>
                    <TitleSelection validation={titleValidation} />
                    {/* <AsyncSelectSearch
                        //@ts-ignore
                        id="title-select"
                        searchUtils={{ searchFn: fetchTitles }}
                        label="Select"
                        data={titles}
                        setterFn={handleSelect}
                        width="100%"
                    /> */}
                </div>
            </Tooltip>
            <div ref={authRef} className={classes.nameContainer}>
                <AuthMethods
                    //@ts-expect-error due to property onchange does not exist ts(2322)
                    onChange={handleAuthChange}
                />
            </div>

            <div ref={passwordRef} className={classes.passwordContainer}>
                <Typography color='textPrimary' variant='h5' className={classes.title}>
                    Password
                </Typography>
                <Typography variant='body1' color='textSecondary'>
                    Please enter the password for the job
                </Typography>
                <div className={classes.passwordInput}>
                    <PasswordInput
                        id='job-password'
                        disabled={!authMethod || authMethod === ''}
                        name='password'
                        enableScore
                        validation={jobPasswordValidation}
                        setterFn={handleChange('password')}
                    />
                </div>
                <div className={classes.passwordInput}>
                    <PasswordInput
                        id='job-confirm-password'
                        disabled={!authMethod || authMethod === ''}
                        name='confirmPassword'
                        validation={confirmPassword}
                        setterFn={handleChange('confirmPassword')}
                        tooltipText={CONFIRM_MESSAGE}
                    />
                </div>
            </div>

            {authMethod !== 'Password Only' && authMethod !== '' && authMethod && (
                <div className={classes.tabSection}>
                    <TabArea />
                </div>
            )}
        </form>
    );
};

export default React.memo(CreateForm);
