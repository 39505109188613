import React from 'react';
import { TableCell, Chip, Fade, Tooltip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import useStyles, { useTooltipStyles } from './StatusCell.styles';
import { StatusCellProps, StatusCellToolTip } from '.';
import { useMobile } from '@mediaseal-webui/hooks';
import { GiFiles } from 'react-icons/gi';
import { AiFillFolder } from 'react-icons/ai';
export const StatusCell = React.memo(
    ({ isFetching = false, subtitle = '', skeletonWidth = 100, skeletonHeight = 10, enableToolTip = false, data, hidden, ...others }: StatusCellProps) => {
        const classes = useStyles();
        const tooltipClasses = useTooltipStyles();
        const isMobile = useMobile();
        const determineLabel = () => {
            if (isMobile) {
                return '';
            }
            if (data.archived) {
                return 'Archived';
            }
            if (data.title) {
                return data.active ? (data.title?.active ? 'Active' : 'Inactive') : 'Inactive';
            } else {
                return data.active ? 'Active' : 'Inactive';
            }
        };
        const determineClassName = () => {
            if (data.archived) return classes.archived;
            if (data.title) {
                return data.active
                    ? data.title?.active
                        ? `${classes.active} active`
                        : `${classes.implicitInactive} implicitInactive`
                    : `${classes.inactive} inactive`;
            } else {
                return data.active ? classes.active : classes.inactive;
            }
        };
        if (isFetching) {
            return (
                <Fade in timeout={1000}>
                    <TableCell component='div' align='left' {...others} classes={{ root: classes.tableCell }} scope='row'>
                        <Skeleton
                            data-testid='loading-skeleton'
                            animation='pulse'
                            variant='rect'
                            width={70}
                            height={skeletonHeight}
                            style={{ marginBottom: 6, borderRadius: '16px' }}
                        />
                    </TableCell>
                </Fade>
            );
        } else {
            return (
                <TableCell component='div' align='center' classes={{ root: classes.tableCell }} scope='row'>
                    {data.template ? (
                        <Tooltip arrow placement='top' title='Live Folders Enabled'>
                            <span className={`${classes.icon} template-job`}>
                                <AiFillFolder />
                            </span>
                        </Tooltip>
                    ) : null}
                    {data.batched ? (
                        <Tooltip arrow placement='top' title='Batched Job'>
                            <span className={`${classes.icon} batched-job`}>
                                <GiFiles />
                            </span>
                        </Tooltip>
                    ) : null}
                    {enableToolTip ? (
                        <Tooltip
                            classes={{
                                tooltip: tooltipClasses.tooltip,
                                arrow: tooltipClasses.arrow
                            }}
                            interactive
                            arrow
                            placement='top'
                            title={<StatusCellToolTip data={data} />}>
                            <Chip variant='default' className={determineClassName()} label={determineLabel()} />
                        </Tooltip>
                    ) : (
                        <Chip variant='default' className={determineClassName()} size='small' label={determineLabel()} />
                    )}
                </TableCell>
            );
        }
    }
);
