import styled from 'styled-components';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core';

export const StyledSlider = styled(Slider)`
    display: flex;
    width: 100%;
    min-width: 300px;
    margin: 8px;
`;

export const useStyles = makeStyles((theme) => ({
    mark: {
        backgroundColor: '#bfbfbf',
        height: 10,
        width: 2,
        marginTop: -2
    },
    track: {
        height: 6
    },
    thumb: {
        height: 15,
        width: 15
    }
}));

export const useTooltipStyles = makeStyles((theme) => ({
    tooltip: {
        background: theme.palette.primary.main,
        borderRadius: 15,
        color: theme.palette.common.white
    },
    arrow: {
        color: theme.palette.primary.main
    }
}));

export const useLabelStyles = makeStyles((theme) => ({
    contentContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    desc: {
        textAlign: 'center',
        width: '100%'
    }
}));
