import React, { useEffect, useState } from 'react';
import { Header } from '../../../../components/layout-components';
import { fetchSpecificGroup, fetchAndCheckPermissions, updateGroup } from '@mediaseal-webui/actions';
import { nameLength, useAuth, useMobile } from '@mediaseal-webui/hooks/src';
import { useProfileState } from '@mediaseal-webui/hooks/src';
import {
    // EncHeaderItems as HeaderItems,
    encTabs as tabs
} from '../../../../components/groups-components/profile-view-components';
import { Content, AddItemButton } from '@mediaseal-webui/components';
import useStyles from './Profile.styles';
import { Card, Typography } from '@material-ui/core';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTabControl } from '@mediaseal-webui/hooks/src';
import { CLEAR_ENTITY } from '@mediaseal-webui/constants';
import { useAppSelector } from 'store';

const Profile = () => {
    const classes = useStyles();
    const mobile = useMobile();
    const { id } = useParams();
    const dispatch = useDispatch();

    const [tabValue, setTabValue] = useState(0);
    const auth = useAuth();
    const isEditAuth = auth.edit || auth.administer;

    const { items: permissions } = useAppSelector((state) => state.permissions);
    const hasEditEncryptorGroupsPermission = permissions.some((permission) => permission.permissionName === 'canEditEncryptorGroups');
    const hasActivateEncryptorGroupsPermission = permissions.some((permission) => permission.permissionName === 'canActivateEncryptorGroups');

    const { name, createDate } = useProfileState();
    const { tabContext: tabControl, cardRef } = useTabControl(
        setTabValue,
        tabs.map((tab) => tab.label)
    );

    useEffect(() => {
        dispatch(fetchAndCheckPermissions(id));
        dispatch(fetchSpecificGroup(id));

        return () => {
            // clears profile state
            dispatch({ type: CLEAR_ENTITY });
        };
    }, [id, dispatch]);

    const headerItems = {
        permissions: {},
        encUsers: {
            onClick: tabControl[0]?.handler
        },
        decUsers: {
            onClick: tabControl[1]?.handler
        },
        decGroups: {
            onClick: tabControl[2]?.handler
        },
        active: {
            updateFn: () => updateGroup(id),
            entity: 'group',
            isReadOnly: !hasActivateEncryptorGroupsPermission
        }
    };

    return (
        <div className={classes.viewRoot}>
            <Header
                pageControl={{ tabValue, setTabValue }}
                editable
                name={name}
                date={createDate ? new Date(createDate) : undefined}
                skeleton
                titleProps={{ setterFn: updateGroup }}
                breadcrumbProps={{
                    links: [{ id: 0, name: 'Encryptor Groups', to: '/groups/encryptor' }],
                    text: [{ id: 0, name }]
                }}
                tabProps={{
                    tabValue,
                    setTabValue,
                    tabs: !mobile ? tabs.filter((tab) => !tab.mobileOnly) : tabs,
                    wideTabs: true,
                    tabControl
                }}
                headerItems={headerItems}
                titleValidation={nameLength}
            />
            <Content>
                {(cx) => (
                    <div ref={cardRef} className={cx.cardWrapper}>
                        {tabs.map(({ label, modal, component, addButtonTitle, Icon, cardId, btnId }, index) => (
                            <Card id={cardId} elevation={0} className={cx.profileCard}>
                                <div ref={tabControl[index]?.ref} className={cx.titleBlock}>
                                    <Typography color='textPrimary' className={cx.cardTitle} variant='h5'>
                                        {label}
                                    </Typography>
                                    {modal && isEditAuth && hasEditEncryptorGroupsPermission && (
                                        <AddItemButton id={btnId} Modal={modal} Icon={Icon} title={addButtonTitle} />
                                    )}
                                </div>
                                {component}
                            </Card>
                        ))}
                    </div>
                )}
            </Content>
        </div>
    );
};

export default React.memo(Profile);
