import { createContext, useContext } from 'react';

export type ShowTabType = {
    showTab: number;
    setShowTab: React.Dispatch<React.SetStateAction<number>>;
};

/**
 * Needs to be init empty to avoid type errors
 */
export const ShowTabContext = createContext<ShowTabType>({
    showTab: 0,
    setShowTab: () => {}
});

export type UseShowTabType = [number, React.Dispatch<React.SetStateAction<number>>];

export const useShowTab = (): UseShowTabType => {
    const { showTab, setShowTab } = useContext(ShowTabContext);

    return [showTab, setShowTab];
};
