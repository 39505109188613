import { FormGroup, FormLabel, Typography, useMediaQuery } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import useStyles from './TokensCreate.styles';
import { useDispatch } from 'react-redux';
import { TextInput } from '@mediaseal-webui/components';
import { useEntityCreation } from '@mediaseal-webui/hooks';
import { setCreate } from '../../../actions/entity';
import TextArea from 'components/utility-components/TextArea';
import { tabs } from 'views/Tokens/Create/tabs';
import { useAppSelector } from 'store';
import { useTheme } from '@material-ui/core/styles';
import TabSection from 'components/utility-components/TabSection';
import DateFilter from 'components/token-components/DatePicker/DateFilter';
import { addDays } from 'date-fns';

const CreateForm = ({ handleSubmit }: { handleSubmit: () => void }) => {
    const [tokenLife, setTokenLife] = useState({
        start: new Date(),
        end: addDays(new Date(), 30)
    });
    const classes = useStyles();
    const dispatch = useDispatch();

    const { genericNameValidation } = useEntityCreation(['name']);

    const handleChange = (name) => (value) => dispatch(setCreate({ [name]: value }));
    const handleTextArea = (event) => dispatch(setCreate({ description: event.target.value }));

    const permissions = useAppSelector((state) => state.groups.permissions);
    const permissionTabs = useMemo(() => tabs({ permissions: permissions, readOnly: false }), [permissions]);

    const theme = useTheme();
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const toDateSetter = (date: Date) => dispatch(setCreate({ sunset: date }));
    const fromDateSetter = (date: Date) => setTokenLife({ ...tokenLife, end: date });

    return (
        <form className={classes.root}>
            <div className={classes.container}>
                <Typography color='textPrimary' variant='h5' className={classes.title}>
                    Name and Description
                </Typography>
                <Typography variant='body1' className={classes.subtitle} color='textSecondary'>
                    Create a name and description for the token
                </Typography>
            </div>

            <div className={classes.container}>
                <FormLabel className={classes.label} htmlFor='token-name'>
                    Name
                </FormLabel>
                <TextInput
                    data-testid='create-token-name'
                    id='token-name'
                    name='name'
                    validation={genericNameValidation}
                    setterFn={handleChange('name')}
                    tooltipText='Please enter a name between 2 and 64 characters'
                />
            </div>

            <div className={classes.container}>
                <FormLabel className={classes.label} htmlFor='description'>
                    Description
                </FormLabel>
                <TextArea onChange={handleTextArea} name='description' />
            </div>

            <div className={classes.container}>
                <DateFilter data-testid='date-range-pickers' responsive={matchesMd} toDateSetter={toDateSetter} fromDateSetter={fromDateSetter} />
            </div>

            <FormGroup className={classes.container}>
                <Typography color='textPrimary' variant='h5' className={classes.title}>
                    Permissions
                </Typography>
                {/* @ts-expect-error due to missing props ts(2739). */}
                <TabSection tabs={permissionTabs} variant='scrollable' />
            </FormGroup>
        </form>
    );
};

export { CreateForm };
