import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { TableModal } from '@mediaseal-webui/components';
import { clearUserPageSearchValue, setUserPageSearchValue, setCheckedEntities } from '@mediaseal-webui/actions';
import { InitialState, Dispatchable } from '@mediaseal-webui/types/redux';
import { useSelector } from 'react-redux';
import { IOpenProps } from '@mediaseal-webui/types/generic';
import { UsersService, RecipientService } from '@mediaseal-webui/services';
import { searchEncryptorUsers } from 'reducers/users/encryptorUsers';
import { searchRecipients } from 'reducers/users/recipients';
import { useAppDispatch } from 'store';
import { CLEAR_SUMMARY } from '@mediaseal-webui/constants';
import { useKeycloakConditionally } from '@mediaseal-webui/hooks';
import { useGenAuthTokenFn } from '@mediaseal-webui/hooks/src/useGenAuthTokenFn';
import { selectEntity } from 'actions/entity';
import { GenericEntity } from '@mediaseal-webui/types';

export interface UserSelectionProps extends IOpenProps {
    updateFn: (id: string | number) => Dispatchable;
    title?: string;
    encryptor?: boolean;
}
export const UserSelection = ({ updateFn, title, encryptor, ...others }: UserSelectionProps) => {
    const { id } = useParams<{ id: string }>();
    const updater = () => updateFn(id);
    const service = useMemo(() => (encryptor ? new UsersService() : new RecipientService()), [encryptor]);
    const searcher = useMemo(() => (encryptor ? searchEncryptorUsers : searchRecipients), [encryptor]);
    const [, setUserPage] = useState(1);
    const { keycloak } = useKeycloakConditionally() ?? {};
    const dispatch = useAppDispatch();

    const searchFn = useCallback(
        async ({ keyword }: { keyword: string }) => {
            await dispatch(searcher({ service: service as UsersService & RecipientService, keyword }));
            if (keyword === '') {
                service.resetParams();
                dispatch(setCheckedEntities(encryptor ? 'users' : 'decryptorUsers'));
            }
        },
        [dispatch, encryptor, searcher, service]
    );
    const { userPageSearchValue } = useSelector(({ users }: InitialState) => users);

    useEffect(
        () => () => {
            dispatch({ type: CLEAR_SUMMARY }); //clear the users in state
        },
        [dispatch]
    );

    return (
        <TableModal
            service={service}
            entityKey={encryptor ? 'encryptorUsers' : 'decryptorUsers'}
            title={title ?? `Add new ${encryptor ? 'encryptor' : 'decryptor'} users`}
            updateFn={updater}
            searchProps={{
                setPage: setUserPage,
                searchFn,
                token: useGenAuthTokenFn(),
                value: userPageSearchValue,
                clearFn: clearUserPageSearchValue,
                setterFn: setUserPageSearchValue as (value: string) => Dispatchable,
                placeholder: 'Search for a user'
            }}
            {...others}
        />
    );
};
