import { combineReducers } from 'redux';
import { getUserTimeZone } from '@mediaseal-webui/utils';
import {
    OPEN_SECURITY_METHOD_MODAL,
    SET_SECURITY_OPTIONS,
    RESET_SECURITY_OPTIONS,
    NEW_FILES_AVAILABLE,
    SET_STATUS_FILTER,
    CLEAR_STATUS_FILTER
} from '@mediaseal-webui/constants';
import { FilterDataPayload, Job } from '@mediaseal-webui/types/jobs';
import {
    SetJobSuccessSnackBarAction,
    ActiveJobArrayAction,
    ActiveJobId,
    TotalFilePagesAction,
    ShowSecurityHelpModalAction,
    SecurityOptionsActions,
    NewFilesAvailableActions,
    StatusFilterActions
} from './types';
import { jobsSlice } from './jobs';

const setJobSuccessSnackBar = (jobSuccess = {}, action: SetJobSuccessSnackBarAction) => {
    switch (action.type) {
        case 'DISPLAY_SUCCESS_SNACKBAR':
            return {
                ...jobSuccess,
                complete: true,
                message: `${action.payload} created successfully!`
            };
        default:
            return {
                ...jobSuccess,
                complete: false,
                message: ''
            };
    }
};

const activeJobId = (state = null, action: ActiveJobId) => {
    switch (action.type) {
        case 'SET_ACTIVE_JOB_ID':
            return action.payload;
        default:
            return state;
    }
};

const activeJobArray = (state = [] as Job[], action: ActiveJobArrayAction) => {
    switch (action.type) {
        case 'SET_ACTIVE_JOB_IN_PROGRESS':
            return [...state, action.payload];
        default:
            return state;
    }
};

const totalFilePages = (state = 0, action: TotalFilePagesAction) => {
    switch (action.type) {
        case 'SET_JOB_FILE_PAGES':
            return action.payload;
        default:
            return state;
    }
};
const showSecurityHelpModal = (state = false, action: ShowSecurityHelpModalAction) => {
    switch (action.type) {
        case OPEN_SECURITY_METHOD_MODAL:
            return action.payload;
        default:
            return state;
    }
};

const securityOptions = (state = { defaultTimeZone: getUserTimeZone(), disableSunset: true }, action: SecurityOptionsActions) => {
    switch (action.type) {
        case SET_SECURITY_OPTIONS:
            return {
                ...state,
                ...action.payload
            };
        case RESET_SECURITY_OPTIONS:
            return {
                defaultTimeZone: getUserTimeZone(),
                disableSunset: true
            };
        default:
            return state;
    }
};

const newFilesAvailable = (state = false, action: NewFilesAvailableActions) => {
    switch (action.type) {
        case NEW_FILES_AVAILABLE:
            return action.payload;
        default:
            return state;
    }
};

const initialStatusFilterState = {
    archived: '&isArchived=false'
};
const statusFilter = (state: FilterDataPayload = initialStatusFilterState, action: StatusFilterActions) => {
    switch (action.type) {
        case SET_STATUS_FILTER:
            return {
                ...state,
                ...action.payload
            };
        case CLEAR_STATUS_FILTER:
            return initialStatusFilterState;
        default:
            return state;
    }
};

export default combineReducers({
    // titles,
    activeJobArray,
    setJobSuccessSnackBar,
    activeJobId,

    totalFilePages,
    newFilesAvailable,
    showSecurityHelpModal,
    securityOptions,
    statusFilter,
    jobs: jobsSlice.reducer
});
