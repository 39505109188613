import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingRight: theme.spacing(3),
        width: '100%'
    },
    container: {
        paddingTop: theme.spacing(3)
    },
    label: {
        marginBottom: theme.spacing(2)
    },
    title: {
        marginBottom: theme.spacing(1),
        fontWeight: 'bold'
    },
    subtitle: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}));

export default useStyles;
