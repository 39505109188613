import FormGroup from './FormGroup';

export interface FormGroupProps {
    children?: React.ReactChild[] | React.ReactChild;
    heading: string | React.ReactNode;
    subtitle?: string;
    className?: string;
}

export default FormGroup;
