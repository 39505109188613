import { SettingsCardData } from '@mediaseal-webui/types/settings';
import { BiFileBlank } from 'react-icons/bi';
import { GiDirectorChair } from 'react-icons/gi';
import { GoBrowser } from 'react-icons/go';
import { PerformanceSlider } from './PerformanceSlider';
import { prefixCheck } from '@mediaseal-webui/hooks';
import { ThemeSelection } from './ThemeSelection/ThemeSelection';
import { IdentityProviders } from './IdentityProviders';
import { Email } from './Email/Email';

export const serverSettingData: SettingsCardData[] = [
    {
        icon: GiDirectorChair,
        heading: 'Studio name',
        inputId: 'studio-name',
        stateKey: 'studioName',
        description: 'The full name of your studio for display and identity purposes (this value will be shown in the application sidebar).',
        isKeycloakOnlyComponent: false
    },
    {
        icon: GoBrowser,
        heading: 'Configure Identity Provider',
        stateKey: 'identityProvider',
        permissions: ['canAdministerIdentityProviders', 'canViewEncryptorGroups'],
        Component: IdentityProviders,
        description: 'Configure an identity provider for server authentication.',
        isKeycloakOnlyComponent: true
    }
];

export const encryptSettingData: SettingsCardData[] = [
    {
        icon: BiFileBlank,
        heading: 'Encrypted file prefix',
        stateKey: 'encryptionPrefix',
        inputId: 'prefix-value',
        validation: prefixCheck,
        description: `Encrypted files and folders will be prefixed with this value. e.g. encrypting a file called media.mp4 will now become enc-media.mp4.`,
        isKeycloakOnlyComponent: false
    }
];
export const appSettings: SettingsCardData[] = [
    {
        icon: BiFileBlank,
        heading: 'Encryption engine performance',
        stateKey: 'encryptionPerformance',
        Component: PerformanceSlider,
        description:
            'Toggle this setting to set how performant the encryption process is. Increasing this value may compromise interface performance while encryption is in process.',
        isKeycloakOnlyComponent: false
    }
];

export const emailSettings: SettingsCardData[] = [
    {
        icon: BiFileBlank,
        heading: 'Notification Addresses',
        stateKey: 'email',
        Component: Email,
        description: 'Add up to three addresses for receiving mail notifications',
        isKeycloakOnlyComponent: false
    }
];

export const themeSettings = [
    {
        icon: GoBrowser,
        heading: 'Application Theme',
        stateKey: 'applicationTheme',
        Component: ThemeSelection,
        description: 'Select the theme option for the application.',
        isKeycloakOnlyComponent: false
    }
];
