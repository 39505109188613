import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { InitialState } from '@mediaseal-webui/types';

const OFFLINE = 'OFFLINE';
const ONLINE = 'ONLINE';

const handleOffline = (dispatch: Dispatch) => () => dispatch({ type: OFFLINE });
const handleOnline = (dispatch: Dispatch) => () => {
    dispatch({ type: ONLINE });
};

export const useOffline = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        window.addEventListener('online', handleOnline(dispatch));
        window.addEventListener('offline', handleOffline(dispatch));
        return () => {
            window.removeEventListener('online', handleOnline(dispatch));
            window.removeEventListener('offline', handleOffline(dispatch));
        };
    }, []);
    return;
};
