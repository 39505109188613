import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    lockBody: {
        position: 'absolute',
        bottom: 0,
        width: 50,
        fill: theme.palette.grey[400],
        transition: `all 0.7s linear`,
        animationDelay: '1s',
        '&.loading': {
            fill: theme.palette.primary.main,
            animation: `$lock-body 1.4s backwards`
        }
    },
    lockArm: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        width: 47,
        transition: `all 0.7s linear`,
        animationDelay: '1s',
        transformOrigin: '72% 0%',
        perspective: 1000,
        transform: `scale(1.0) scaleZ(1.0) rotateY(180deg)`,
        fill: theme.palette.grey[400],
        '&.loading': {
            transform: `scale(1.0) scaleZ(1.0) rotateY(0deg) translateY(4px)`,
            fill: theme.palette.primary.main,
            animation: `$lock 1.4s backwards`
        }
    },
    '@keyframes lock': {
        '0%': {
            transform: `scale(1.0) scaleZ(1.0) rotateY(180deg)`,
            fill: theme.palette.grey[400]
        },
        '50%': {
            transform: `scale(1.0) scaleZ(1.0) rotateY(0deg)`,
            fill: theme.palette.grey[400]
        },
        '100%': {
            transform: `scale(1.0) scaleZ(1.0) rotateY(0deg) translateY(4px)`
        }
    },
    '@keyframes lock-body': {
        '0%': {
            fill: theme.palette.grey[400]
        },
        '50%': {
            fill: theme.palette.grey[400]
        },
        '100%': {
            fill: theme.palette.primary.main
        }
    }

    // loading: {
    //     position: 'absolute',
    //     bottom: 8,
    //     left: 18,
    //     color: theme.palette.primary.main
    // }
}));

export default useStyles;
