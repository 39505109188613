import { IOfflinePasswordEncryption } from '@mediaseal-webui/types/encryptor';
/**
 *
 * @param param0
 * @returns
 *
 *
 * Used to start password only encryption
 */

export const startPasswordOnlyWithPasswordEncryptionWork = ({ id, fileSettings, fileFeatures = [], password }: IOfflinePasswordEncryption) => {
    window.EncryptionWebInterface.startPasswordOnlyWithPasswordEncryptionWork(id, fileSettings, fileFeatures, password);
    // setEncryptionStart(id, 'dadwaa', 'mp3', 'dwadwadawda', 'dwadawdwd', 0)
    return { type: '' };
};
