import React from 'react';
import FormGroup from '../../../../utility-components/FormGroup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { setCreate } from '@mediaseal-webui/actions';
import { useDispatch, useSelector } from 'react-redux';
import timezones from '../../../../../data/timezones';
import { useStyles } from './SunriseSunset.style';
import { InitialState } from '@mediaseal-webui/types/redux';
import { TextField } from '@material-ui/core';
import DateRangePicker from '../../../../utility-components/DateRangePicker/DateRangePicker';
import { addDays } from 'date-fns';
import { useMobile } from '@mediaseal-webui/hooks';

export const SunsetSunrise = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { defaultTimeZone, disableSunset } = useSelector((state: InitialState) => state.jobs.securityOptions);
    const isMobile = useMobile();
    const sunsetProps = { sunrise: new Date(), sunset: addDays(new Date(), 30) };
    const handleTimeZone = (event: React.ChangeEvent<{}>, zone: string | null) => dispatch(setCreate({ timezone: zone }));
    const handleSunriseSunset = (value: string) => (date: Date) => {
        dispatch(setCreate({ [value]: date }));
    };

    return (
        <FormGroup subtitle='Determine the times in which Mediaseal Users may access encrypted files'>
            <Autocomplete
                id='timezone-select'
                options={timezones}
                className={classes.timeZone}
                defaultValue={defaultTimeZone || Intl.DateTimeFormat().resolvedOptions().timeZone}
                onChange={handleTimeZone}
                getOptionLabel={(option) => option}
                style={{ width: '100%' }}
                renderInput={(params) => (
                    <TextField {...params} disabled={disableSunset} style={{ width: '100%', zIndex: 0 }} label='Select Timezone' variant='outlined' />
                )}
            />
            <DateRangePicker
                disabled={disableSunset}
                showTime
                portal={isMobile}
                fullWidth
                defaultStartDate={sunsetProps?.sunrise}
                defaultEndDate={sunsetProps?.sunset}
                inputLabels={{
                    startLabel: 'Sunrise',
                    endLabel: 'Sunset'
                }}
                toDateSetter={handleSunriseSunset('sunset')}
                fromDateSetter={handleSunriseSunset('sunrise')}
            />
        </FormGroup>
    );
};
