import React, { useLayoutEffect } from 'react';
import debounce from 'debounce';

declare global {
    interface Window {
        scrollPosition: number;
    }
}

export const useTableScroller = () => {
    const table: HTMLDivElement | null = document.querySelector('.ReactVirtualized__Table__Grid');
    const scrollTableToTop = () => table?.scrollTo({ top: 0, behavior: 'smooth' });
    const keyNav = debounce((event: KeyboardEvent) => {
        event.preventDefault();
        switch (event.key) {
            case 'ArrowDown':
                table?.scrollTo({ top: window.scrollPosition, behavior: 'smooth' });
                window.scrollPosition += 100;
                break;
            case 'ArrowUp':
                table?.scrollTo({ top: window.scrollPosition, behavior: 'smooth' });
                window.scrollPosition -= 100;
                break;
            case 'Home':
                table?.scrollTo({ top: 0, behavior: 'smooth' });
                window.scrollPosition = 0;
                break;
            case 'End':
                table?.scrollTo({ top: table?.scrollHeight, behavior: 'smooth' });
                window.scrollPosition = table?.scrollHeight || 0;
                break;
            case 'PageDown':
                const heightDown = window.scrollPosition + (table ? table?.offsetHeight : 0);
                table?.scrollTo({ top: heightDown, behavior: 'smooth' });
                window.scrollPosition += table?.offsetHeight || 0;
                break;
            case 'PageUp':
                const heightUp = window.scrollPosition - (table ? table?.offsetHeight : 0);
                table?.scrollTo({ top: heightUp, behavior: 'smooth' });
                window.scrollPosition -= table?.offsetHeight || 0;
                break;
        }
    }, 100);

    useLayoutEffect(() => {
        window.scrollPosition = 0;
        window.addEventListener('keydown', keyNav);
        return () => {
            window.removeEventListener('keydown', keyNav);
        };
    }, []);

    return {
        scrollPosition: window.scrollPosition,
        scrollTableToTop
    };
};
