import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { MainTable } from '@mediaseal-webui/components';
import { useDispatch } from 'react-redux';
import { clearUserPageSearchValue } from '@mediaseal-webui/actions';
import { genericUserNoActions } from '../../../data';
import { Header } from '../../../components/layout-components';
import { useTableSortV2 } from '@mediaseal-webui/hooks';
import { Content, SubmitButton } from '@mediaseal-webui/components';
import useStyles from './Encryptor.styles';
import { useAuth } from '@mediaseal-webui/hooks';
import ActionContainer from '../../../components/table-components/ActionContainer/ActionContainer';
import { useTableScroller } from '@mediaseal-webui/hooks';
import { UsersService } from '@mediaseal-webui/services';
import { encryptorUsersSlice, fetchEncryptorUsers, searchEncryptorUsers } from 'reducers/users/encryptorUsers';
import { useAppSelector } from 'store';

const Encryptor = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useAuth();
    const service = useMemo(() => new UsersService(), []);
    // const isEditAuth = auth.edit || auth.administer
    const isCreateAuth = auth.create;
    const { setSearchValue, clearSearchValue, clearUsers } = encryptorUsersSlice.actions;

    const { content: users, page, searchValue: userPageSearchValue, pending } = useAppSelector((state) => state.encryptorUsers);
    useTableScroller();
    const fetchFn = useCallback(() => dispatch(searchEncryptorUsers({ keyword: service.baseParams.keyword ?? '', service })), [service, dispatch]);
    const { headCells, sortFn, order, orderBy } = useTableSortV2({
        cells: genericUserNoActions,
        fetchFn,
        service
    });

    useEffect(() => {
        window.qt && window.GeneralWebInterface.allowFileDrop(false, { dropBehaviour: 'off' });
        dispatch(searchEncryptorUsers({ service, keyword: '' }));
        return () => {
            dispatch(clearUsers());
            dispatch(clearUserPageSearchValue());
        };
    }, [dispatch, service, clearUsers]);

    const onRowClick = ({ id }) => history.push(`/users/encryptor/${id}/profile`); // returns the rowData obj from Table

    const handleCreateClick = () => history.push('/users/encryptor/create');

    const CreateButton = () => <SubmitButton title='Create User' id='create-enc-user-btn' disabled={false} onClick={handleCreateClick} />;
    const fetchNextPage = useCallback(async () => {
        if (service.baseParams.page <= (page?.totalPages ?? 0)) {
            service.setParams({
                page: service.baseParams.page + 1
            });
            await dispatch(fetchEncryptorUsers(service));
        }
    }, [dispatch, page?.totalPages, service]);

    return (
        <div className={classes.viewRoot}>
            <Header
                list
                skeleton
                name='Encryptor Users'
                displayingText={`Displaying ${users.length} of ${page?.totalElements} users`}
                ActionButton={auth.create && CreateButton}
                breadcrumbProps={{
                    links: [{ id: 0, name: 'Encryptor Users', to: '/users/encryptor' }],
                    text: [{ id: 0, name: '' }],
                    isFetching: pending
                }}
                searchProps={{
                    setPage: () => null,
                    searchFn: 'searchEncryptorUsers',
                    value: userPageSearchValue,
                    set: setSearchValue,
                    clear: clearSearchValue
                }}
            />

            <Content>
                {() => (
                    <MainTable
                        entities={users}
                        id='encryptor-users-table'
                        isFetching={pending}
                        totalPages={page?.totalPages}
                        onRowClick={onRowClick}
                        searchValue={userPageSearchValue}
                        pageControl={{ page: service.baseParams.page, setPage: () => null }}
                        entityLink='/users/encryptor'
                        fetchFn={fetchNextPage}
                        noResultsMessage='No users returned'
                        tableActionsRenderer={(entity) => <ActionContainer create={isCreateAuth} />}
                        sortData={{
                            sortBy: orderBy,
                            sortDirection: order,
                            createSortHandler: sortFn
                        }}
                        columns={headCells}
                    />
                )}
            </Content>
        </div>
    );
};

export default Encryptor;
