import React, { useCallback, useEffect, useMemo } from 'react';
import { IdentityProvider } from './IdentityProvider';
import { IdentityProviderModal } from './Modal';
import { ProviderContainer } from './index.style';
import { GrWindows } from 'react-icons/gr';
import { SiAuth0 } from 'react-icons/si';
import { useIdentityProvider } from 'hooks/useIdentityProviders';
import { IdentityProviderService } from '@mediaseal-webui/services/src/IdentityProviderService';

export const providers = [
    { id: 1, type: 'microsoft', Icon: GrWindows },
    { id: 2, type: 'okta', Icon: SiAuth0 }
] as const;

export const IdentityProviders = () => {
    const { configuredProvider, setData } = useIdentityProvider();
    const service = useMemo(() => new IdentityProviderService(), []);
    // if the user can view groups and can administer providers
    const configured = useMemo(() => providers.find((prov) => prov.type === configuredProvider.type), [configuredProvider]);
    const fetchIdentityProvider = useCallback(async () => {
        try {
            const { data } = await service.fetchProvider();
            if (data.clientId) {
                setData((ps) => ({ ...ps, configuredProvider: { ...ps.configuredProvider, ...data } }));
            }
        } catch (error) {
            console.log(error);
        }
    }, [service, setData]);

    useEffect(() => {
        // GET /identity-providers
        fetchIdentityProvider();
    }, [fetchIdentityProvider]);
    return (
        <ProviderContainer>
            {configuredProvider.clientId !== '' ? (
                <IdentityProvider provider={configured ?? providers[0]} configured />
            ) : (
                providers.map((provider) => <IdentityProvider key={provider.id} provider={provider} />)
            )}

            <IdentityProviderModal />
        </ProviderContainer>
    );
};
