import { usePasswordScore } from '@mediaseal-webui/hooks';
import React, { FC, HTMLProps, useState, useEffect, useContext, useRef } from 'react';
import { InputTooltip } from '../../Tooltips';
import { StyledTextInput, SuccessIcon, FailIcon, StyledContainer, StyledInputProgress } from './TextInput.style';

export interface TextInputProps extends Omit<HTMLProps<HTMLInputElement>, 'ref' | 'as'> {
    /** Function to decide if input is valid */
    validation?: (value: string, score: number | null) => boolean | null;
    /** Tooltip component to render */
    Tooltip?: FC<any>;
    /** Text to display in the default tooltip if input invalid */
    tooltipText?: string;
    /** Function to pass input state changes up to the parent */
    setterFn?: (value: string) => void;
    disabled?: boolean;
    /** value for input, this will override the state... used for controlling input via context or redux */
    value?: string;
    enableScore?: boolean;

    disableValidation?: boolean;
    // added as workaround for recipients invites repopulating inputs after add draft invite
    onBlurDisabled?: boolean;
}

export const TextInput: FC<TextInputProps> = (props) => {
    const {
        validation = () => null,
        id,
        Tooltip,
        tooltipText,
        setterFn,
        name,
        type = 'text',
        disabled,
        value,
        disableValidation,
        onBlurDisabled,
        ...others
    } = props;
    const [inputValue, setInputValue] = useState<string>('');
    const [valid, setValid] = useState<boolean | null>(null);
    const { score } = usePasswordScore();
    const timeout = useRef<NodeJS.Timeout | null>(null);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        clearTimeout(timeout.current as NodeJS.Timeout);
        !value && setInputValue(event.target.value);
        setterFn && setterFn(event.target.value);
        if (validation) {
            timeout.current = setTimeout(() => {
                setValid(validation(event.target.value, score));
            }, 300);
        }
    };

    const determineClassname = () => (valid === null ? '' : valid ? 'valid' : 'invalid');

    const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();

        if (!disableValidation && validation && !onBlurDisabled) {
            clearTimeout(timeout.current as NodeJS.Timeout);
            timeout.current = setTimeout(() => {
                setValid(validation(inputValue, score));
            }, 300);
        }
    };

    useEffect(
        () => () => {
            clearTimeout(timeout.current as NodeJS.Timeout);
        },
        []
    );
    return (
        <>
            <StyledContainer valid={valid}>
                <StyledTextInput
                    disabled={disabled}
                    value={value}
                    id={id}
                    onChange={handleChange}
                    onBlur={onBlurDisabled ? () => {} : handleBlur}
                    valid={valid}
                    name={name}
                    type={type}
                    enableScore={others.enableScore}
                    {...others}
                    className={`${determineClassname()} ${others.className}`}
                />
                {others.enableScore ? (
                    <StyledInputProgress score={score} />
                ) : (
                    <>
                        <SuccessIcon valid={valid} data-testid='valid-check' />
                        <FailIcon valid={valid} data-testid='invalid-check' />
                    </>
                )}
                {tooltipText ? (
                    <InputTooltip title={tooltipText} show={valid !== null && !valid} />
                ) : Tooltip ? (
                    <Tooltip show={valid !== null && !valid} disabled={disabled} />
                ) : null}
            </StyledContainer>
        </>
    );
};
