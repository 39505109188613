import { combineReducers } from 'redux';
import {
    SET_SUMMARY,
    SET_PROFILE,
    CLEAR_ERRORS,
    SET_EDIT,
    ADD_DEC_GROUP_TO_SUMMARY,
    ADD_DEC_USER_TO_SUMMARY,
    ADD_ENC_USER_TO_SUMMARY,
    ADD_ENC_GROUP_TO_SUMMARY,
    REMOVE_ENC_GROUP_FROM_SUMMARY,
    REMOVE_DEC_USER_FROM_SUMMARY,
    REMOVE_DEC_GROUP_FROM_SUMMARY,
    REMOVE_ENC_USER_FROM_SUMMARY,
    CLEAR_ENTITY,
    SET_ERROR,
    SET_CREATE,
    CLEAR_SUMMARY,
    FETCH_JOB_FILES,
    FETCH_JOB_FILES_SORTED,
    SET_TOTAL_FILES,
    SET_PERMISSION,
    REMOVE_PERMISSION,
    SET_FILTERED_PROFILE_DECRYPTOR_USERS,
    SET_FILTERED_PROFILE_DECRYPTOR_GROUPS,
    REMOVE_JOB_FEATURE,
    SET_UNSERIALIZED_PERMISSIONS,
    SELECT_ENC_GROUP,
    SELECT_DEC_GROUP,
    SELECT_DEC_USER,
    SELECT_ENC_USER,
    DESELECT_DEC_USER,
    DESELECT_ENC_USER,
    DESELECT_DEC_GROUP,
    DESELECT_ENC_GROUP,
    SELECT_TOKEN,
    DESELECT_TOKEN,
    ADD_TOKEN_TO_SUMMARY,
    REMOVE_TOKEN_FROM_SUMMARY
} from '@mediaseal-webui/constants';

const profile = (state = {}, { type, payload }) => {
    switch (type) {
        case SET_PROFILE:
            return {
                ...state,
                ...payload
            };
        case FETCH_JOB_FILES:
            return {
                ...state,
                files: [...state.files, ...payload]
            };
        case FETCH_JOB_FILES_SORTED:
            return {
                ...state,
                files: [...payload]
            };
        case SET_TOTAL_FILES:
            return {
                ...state,
                totalFiles: payload
            };
        case SET_UNSERIALIZED_PERMISSIONS:
            return {
                ...state,
                permissions: payload
            };
        case SET_PERMISSION:
            return {
                ...state,
                permissions: [...(state.permissions ?? []).filter((permission) => permission.permissionName !== payload.permissionName), payload]
            };
        case REMOVE_PERMISSION:
            return {
                ...state,
                permissions: (state.permissions ?? []).filter((permission) => permission.id !== payload.id)
            };
        case REMOVE_JOB_FEATURE:
            return {
                ...state,
                features: (state.features ?? []).filter((feature) => feature.type !== payload)
            };
        case CLEAR_ENTITY:
            return {};
        default:
            return state;
    }
};

const create = (state = {}, { type, payload }) => {
    switch (type) {
        case SET_CREATE:
            return {
                ...state,
                ...payload
            };
        case ADD_DEC_GROUP_TO_SUMMARY:
            return {
                ...state,
                decryptorGroups: [...(state.decryptorGroups ?? []), payload]
            };
        case ADD_ENC_GROUP_TO_SUMMARY:
            return {
                ...state,
                encryptorGroups: [...(state.encryptorGroups ?? []), payload]
            };
        case ADD_DEC_USER_TO_SUMMARY:
            return {
                ...state,
                decryptorUsers: [...(state.decryptorUsers ?? []), payload]
            };
        case ADD_ENC_USER_TO_SUMMARY:
            return {
                ...state,
                users: [...(state.users ?? []), payload]
            };
        case SET_PERMISSION:
            return {
                ...state,
                permissions: [...(state.permissions ?? []).filter((permission) => permission.permissionName !== payload.permissionName), payload]
            };
        case REMOVE_PERMISSION:
            return {
                ...state,
                permissions: (state.permissions ?? []).filter((permission) => permission.id !== payload.id)
            };
        case REMOVE_DEC_USER_FROM_SUMMARY:
            return {
                ...state,
                decryptorUsers: (state.decryptorUsers ?? []).filter((user) => user.id !== payload.id)
            };
        case REMOVE_ENC_USER_FROM_SUMMARY:
            return {
                ...state,
                users: (state.users ?? []).filter((user) => user.id !== payload.id)
            };
        case REMOVE_DEC_GROUP_FROM_SUMMARY:
            return {
                ...state,
                decryptorGroups: (state.decryptorGroups ?? []).filter((group) => group.id !== payload.id)
            };
        case REMOVE_ENC_GROUP_FROM_SUMMARY:
            return {
                ...state,
                encryptorGroups: (state.encryptorGroups ?? []).filter((group) => group.id !== payload.id)
            };
        case REMOVE_JOB_FEATURE:
            return {
                ...state,
                features: (state.features ?? []).filter((feature) => feature.type !== payload)
            };
        case CLEAR_SUMMARY:
            return {
                ...state,
                users: [],
                decryptorUsers: [],
                decryptorGroups: [],
                encryptorGroups: [],
                allowedTokens: []
            };
        case CLEAR_ENTITY:
            return {};
        default:
            return state;
    }
};

const edit = (state = {}, { type, payload }) => {
    switch (type) {
        case SET_EDIT:
            return {
                ...state,
                ...payload
            };
        case ADD_DEC_GROUP_TO_SUMMARY:
        case SELECT_DEC_GROUP:
            return {
                ...state,
                decryptorGroups: [...(state.decryptorGroups ?? []).filter((user) => user.id !== payload.id), payload]
            };
        case ADD_ENC_GROUP_TO_SUMMARY:
        case SELECT_ENC_GROUP:
            return {
                ...state,
                encryptorGroups: [...(state.encryptorGroups ?? []).filter((user) => user.id !== payload.id), payload]
            };
        case ADD_DEC_USER_TO_SUMMARY:
        case SELECT_DEC_USER:
            return {
                ...state,
                decryptorUsers: [...(state.decryptorUsers ?? []).filter((user) => user.id !== payload.id), payload]
            };
        case ADD_ENC_USER_TO_SUMMARY:
        case SELECT_ENC_USER:
            return {
                ...state,
                users: [...(state.users ?? []).filter((user) => user.id !== payload.id), payload]
            };
        case ADD_TOKEN_TO_SUMMARY:
        case SELECT_TOKEN:
            return {
                ...state,
                allowedTokens: [...(state.allowedTokens ?? []).filter((token) => token.id !== payload.id), payload]
            };
        case REMOVE_DEC_USER_FROM_SUMMARY:
        case DESELECT_DEC_USER:
            return {
                ...state,
                decryptorUsers: (state.decryptorUsers ?? []).filter((user) => user.id !== payload.id)
            };
        case REMOVE_ENC_USER_FROM_SUMMARY:
        case DESELECT_ENC_USER:
            return {
                ...state,
                users: (state.users ?? []).filter((user) => user.id !== payload.id)
            };
        case REMOVE_DEC_GROUP_FROM_SUMMARY:
        case DESELECT_DEC_GROUP:
            return {
                ...state,
                decryptorGroups: (state.decryptorGroups ?? []).filter((group) => group.id !== payload.id)
            };
        case REMOVE_ENC_GROUP_FROM_SUMMARY:
        case DESELECT_ENC_GROUP:
            return {
                ...state,
                encryptorGroups: (state.encryptorGroups ?? []).filter((group) => group.id !== payload.id)
            };
        case REMOVE_TOKEN_FROM_SUMMARY:
        case DESELECT_TOKEN:
            return {
                ...state,
                allowedTokens: (state.allowedTokens ?? []).filter((token) => token.id !== payload.id)
            };
        case SET_UNSERIALIZED_PERMISSIONS:
            return {
                ...state,
                permissions: payload
            };
        case SET_PERMISSION:
            return {
                ...state,
                permissions: [...(state.permissions ?? []).filter((permission) => permission.permissionName !== payload.permissionName), payload]
            };
        case REMOVE_PERMISSION:
            return {
                ...state,
                permissions: (state.permissions ?? []).filter((permission) => permission.id !== payload.id)
            };
        case CLEAR_SUMMARY:
            return {
                ...state,
                users: [],
                decryptorUsers: [],
                decryptorGroups: [],
                encryptorGroups: []
            };
        case CLEAR_ENTITY:
            return {};
        default:
            return state;
    }
};

const summary = (state = { files: [], jobs: [], users: [], decryptorUsers: [], decryptorGroups: [], encryptorGroups: [] }, { type, payload }) => {
    switch (type) {
        case SET_SUMMARY:
            return {
                ...state,
                ...payload
            };
        case ADD_DEC_GROUP_TO_SUMMARY:
            return {
                ...state,
                decryptorGroups: [...(state.decryptorGroups ?? []), payload]
            };
        case ADD_ENC_GROUP_TO_SUMMARY:
            return {
                ...state,
                encryptorGroups: [...(state.encryptorGroups ?? []), payload]
            };
        case ADD_DEC_USER_TO_SUMMARY:
            return {
                ...state,
                decryptorUsers: [...(state.decryptorUsers ?? []), payload]
            };
        case ADD_ENC_USER_TO_SUMMARY:
            return {
                ...state,
                users: [...(state.users ?? []), payload]
            };
        case REMOVE_DEC_USER_FROM_SUMMARY:
            return {
                ...state,
                decryptorUsers: state.decryptorUsers.filter((user) => user.id !== payload.id)
            };
        case REMOVE_ENC_USER_FROM_SUMMARY:
            return {
                ...state,
                users: state.users.filter((user) => user.id !== payload.id)
            };
        case REMOVE_DEC_GROUP_FROM_SUMMARY:
            return {
                ...state,
                decryptorGroups: state.decryptorGroups.filter((group) => group.id !== payload.id)
            };
        case REMOVE_ENC_GROUP_FROM_SUMMARY:
            return {
                ...state,
                encryptorGroups: (state.encryptorGroups ?? []).filter((group) => group.id !== payload.id)
            };
        case CLEAR_SUMMARY:
            return {};
        case CLEAR_ENTITY:
            return {};
        default:
            return state;
    }
};

const filteredDecryptorUsers = (state = [], { type, payload }) => {
    switch (type) {
        case SET_FILTERED_PROFILE_DECRYPTOR_USERS:
            return [...payload];
        case REMOVE_DEC_USER_FROM_SUMMARY:
            return state.filter((user) => user.id !== payload.id);
        default:
            return state;
    }
};

const filteredDecryptorGroups = (state = [], { type, payload }) => {
    switch (type) {
        case SET_FILTERED_PROFILE_DECRYPTOR_GROUPS:
            return [...payload];
        case REMOVE_DEC_GROUP_FROM_SUMMARY:
            return state.filter((group) => group.id !== payload.id);
        default:
            return state;
    }
};

/**
 *
 * @param {name: String, message: String} payload
 */
const errors = (state = {}, { type, payload }) => {
    switch (type) {
        case SET_ERROR:
            return {
                ...state,
                [payload.name]: payload
            };
        case CLEAR_ERRORS:
            return {};
        default:
            return state;
    }
};

export default combineReducers({
    profile,
    edit,
    create,
    summary,
    errors,
    filteredDecryptorUsers,
    filteredDecryptorGroups
});
