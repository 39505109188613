import styled from 'styled-components';
import { Dialog as DialogContainer, Divider } from '@material-ui/core';

export const Dialog = styled(DialogContainer)`
    & .MuiDialog-paper {
        padding: 20px;
    }
`;

export const ActionContainer = styled.div`
    padding: 16px;
`;

export const Seperator = styled(Divider)`
    margin-bottom: 16px;
`;

export const CalenderContainer = styled.div`
    margin-left: -12px;
`;
