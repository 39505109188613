import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, FormLabel } from '@material-ui/core';
import { Title, NotesContainer, StyledFormGroup, StyledDialogContent } from './ContactInfoModal.styles';
import { useDispatch, useSelector } from 'react-redux';
import { InitialState } from '@mediaseal-webui/types/redux';
import { Actions, TextInput } from '@mediaseal-webui/components';
import { setEdit } from '@mediaseal-webui/actions';
import TextArea from '../../../utility-components/TextArea';
import { useMobile, useJobService } from '@mediaseal-webui/hooks';

interface ContactModalProps {
    openProps: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    notesModal?: boolean;
    disabled?: boolean;
}

interface ContactInfo {
    contactName: string;
    contactInfo: string;
}
export const ContactInfoModal = ({ openProps, notesModal, disabled }: ContactModalProps) => {
    const { contactName, contactInfo, notes, id } = useSelector((state: InitialState) => state.entity.edit);
    const [open, setOpen] = openProps;
    const [contact, setContact] = useState<ContactInfo>({
        contactName,
        contactInfo
    });
    const dispatch = useDispatch();
    const mobile = useMobile();
    const { updateJob } = useJobService();
    const handleChange = (key: string) => (value: string) => {
        setContact((ps) => ({ ...ps, [key]: value }));
    };
    const handleNotes = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        dispatch(setEdit({ notes: event.target.value }));
    };
    const handleSave = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        await updateJob(id, {
            contactName: contact.contactName,
            //@ts-expect-error due to arg not assignable to type ts(2345).
            contactInfo: contact.contactInfo,
            notes
        });
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        setContact({
            contactName,
            contactInfo
        });
    }, [contactName, contactInfo]);
    return (
        <Dialog open={open} fullScreen={mobile} onClose={handleClose} maxWidth='xl'>
            <Title variant='h5'>{notesModal ? 'Notes' : 'Contact Details'}</Title>
            <StyledDialogContent>
                {!notesModal ? (
                    <form onSubmit={handleSave}>
                        <StyledFormGroup>
                            <FormLabel>Contact Name</FormLabel>
                            <TextInput
                                disabled={disabled}
                                value={contact.contactName}
                                validation={() => true}
                                setterFn={handleChange('contactName')}
                                label='Contact Name'
                            />
                        </StyledFormGroup>
                        <StyledFormGroup>
                            <FormLabel>Contact Information</FormLabel>
                            <TextInput
                                disabled={disabled}
                                value={contact.contactInfo}
                                validation={() => true}
                                setterFn={handleChange('contactInfo')}
                                label='Contact Info'
                            />
                        </StyledFormGroup>
                    </form>
                ) : (
                    <NotesContainer>
                        <TextArea disabled={disabled} aria-label='job notes' value={notes} name='notes' onChange={handleNotes} />
                    </NotesContainer>
                )}
            </StyledDialogContent>
            <DialogActions>
                <Actions saveFn={handleSave} disableSave={disabled} closeFn={handleClose} />
            </DialogActions>
        </Dialog>
    );
};

export default ContactInfoModal;
