import RemoveAction from './RemoveAction';

export interface RemoveActionProps {
    removeAction?: () => void;
    disabled?: boolean;
    RemoveIcon?: React.ElementType<any>;
    tooltipTitle?: string;
}

export default RemoveAction;
