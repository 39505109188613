import React from 'react';
import { Typography, Grid, Fade } from '@material-ui/core';
import { ReactComponent as Logo } from '../LargeLogo/Mediaseal.svg';
import clsx from 'clsx';
import useStyles from './SmallLogo.styles';

interface SmallLogoProps {
    showText?: boolean;
    black?: boolean;
    className?: string;
}

export const SmallLogo = (props: SmallLogoProps) => {
    const { showText = true, black = false, ...others } = props;
    const classes = useStyles();
    return (
        <Fade in timeout={1000}>
            <div className={classes.root} {...others}>
                <Logo data-testid='logo-image' className={classes.logo} />
                {showText && (
                    <Fade in timeout={1200}>
                        <Grid className={classes.titleContainer} item xl='auto' lg='auto' md='auto' sm='auto' xs='auto'>
                            <Typography
                                color='textPrimary'
                                variant='h1'
                                className={clsx(classes.title, {
                                    [classes.black]: black
                                })}>
                                MediaSeal
                            </Typography>
                        </Grid>
                    </Fade>
                )}
            </div>
        </Fade>
    );
};

export default SmallLogo;
