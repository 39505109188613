import { LinearProgress, Dialog } from '@material-ui/core';
import styled, { css, DefaultTheme } from 'styled-components';

interface StyledProps {
    theme: DefaultTheme;
}

export const StyledDialogue = styled(Dialog)`
    ${({ theme }: StyledProps) => css`
        padding: ${theme.spacing(2)}px;
        text-align: center;
        .MuiPaper-root {
            background: transparent;
            box-shadow: none;
        }
    `}
`;

export const Spinner = styled.div``;
