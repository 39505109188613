export const marks = [
    {
        value: 1,
        label: 'Low',
        tooltip: 'focus on usability, and background services'
    },
    {
        value: 5,
        label: 'Medium',
        tooltip: 'balance of usability and encryption performance'
    },
    {
        value: 10,
        label: 'High',
        tooltip: 'focus on encryption performance, usability may be slow during encryption'
    }
];
