import styled, { css } from 'styled-components';
import { Dialog } from '@material-ui/core';

export const StyledDialogue = styled(Dialog)`
    ${({ theme }) => css`
        .MuiPaper-root {
            overflow: visible;
        }
    `}
`;
