import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    iconRoot: {
        position: 'relative',
        marginBottom: theme.spacing(2),
        fontSize: '2em',
        overflow: 'hidden',
        width: 68,
        height: 74,
        '& svg': {
            color: '#777'
        }
    },
    singleIcon: {
        width: '100%',
        height: '100%',
        '&.danger': {
            fill: theme.palette.error.main
        }
    }
}));

export default useStyles;
