import { EncryptFile } from '@mediaseal-webui/types/encryptor';
import { Action } from 'redux';
import { ThunkResult } from '@mediaseal-webui/types/redux';
import { ALLOW_ENCRYPTION } from '@mediaseal-webui/constants';
export * from './issues';
export * from './batching';
export * from './processes';
export * from './signals';
export * from './slots';

/*
  ENCRYPTOR ACTIONS
  */

/*
  ENCRYPTOR ACTIONS
  */
export const setEncryptionStart =
    ({ id, inputFileName, fileType, fullFilePath, fileOutput, fileSize, mediaKeyBase64, percentage = 0, jobId }: EncryptFile): ThunkResult<void> =>
    (dispatch, getState) => {
        console.log(`Received Signal encryptionWorkCreated from QT with ID : ${id}`);
        // before this we need to post the file
        const activeJobId = jobId || getState().jobs.activeJobId;
        dispatch({ type: 'SET_ACTIVE_JOB_IN_PROGRESS', payload: activeJobId });
        const file = {
            id,
            name: inputFileName,
            inputFileName,
            fullFilePath,
            fileType,
            fileOutput,
            percentage,
            fileSize,
            mediaKeyBase64,
            jobId: activeJobId
        };
        dispatch({ type: 'ENCRYPT_FILE_LIST', payload: file });
    };

export const setEncryptionFileComplete =
    (file: EncryptFile): ThunkResult<void> =>
    (dispatch) => {
        dispatch({ type: 'SET_COMPLETED_FILE', payload: file });
        dispatch(removeEncryptionFile(file));
    };

export const generalError =
    (message: string): ThunkResult<void> =>
    (dispatch) => {
        console.log(`Received Signal generalError from QT with ID : ${message}`);
        dispatch({
            type: 'SET_ENCRYPTION_ERROR',
            payload: { error: true, message }
        });
        dispatch({ type: 'SET_SNACKBAR', payload: { error: true, message } });
    };

export const removeEncryptionFile =
    (file: { id: EncryptFile['id'] }): ThunkResult<void> =>
    (dispatch) => {
        dispatch({ type: 'REMOVE_ENCRYPTION_FILE', payload: file });
        // dispatch({ type: 'SET_SNACKBAR', payload: { success: true, message: 'File: successfully removed file' } })
        // dispatch(processFileQueue());
    };

export const clearStaticFileList = (): Action => ({
    type: 'CLEAR_STATIC_FILES'
});
export const clearCompletedFiles = (): Action => ({
    type: 'CLEAR_COMPLETED_FILES'
});

export const setAllowEncryption = (allow: boolean) => {
    if (window.qt) {
        console.log(`Setting Allow Encryption: ${allow}`);
        window.GeneralWebInterface.allowFileDrop(allow, {
            dropBehaviour: allow ? 'encryption' : 'off'
        });
    }
    return { type: ALLOW_ENCRYPTION, payload: allow };
};
