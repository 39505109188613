import React, { FC, HTMLProps, useState, useEffect } from 'react';
import { StyledFigure, StyledCircle, StyledBaseCircle } from './InputProgress.style';

export interface InputProgressProps extends Omit<HTMLProps<HTMLDivElement>, 'ref' | 'as'> {
    score: number | null;
}

export const InputProgress: FC<InputProgressProps> = ({ score, ...others }) => {
    const findPercentage = () => (score ? (score / 4) * 100 : 0);
    return (
        <StyledFigure score={score} {...others}>
            <svg data-percent={findPercentage()} width='30' height='30' viewBox='0 0 30 30'>
                <StyledBaseCircle cx='15' cy='15' r='10' />
                <StyledCircle cx='15' cy='15' r='10' />
            </svg>
        </StyledFigure>
    );
};
