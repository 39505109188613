import SunsetSunrise from './SunsetSunrise';

export interface SunsetSunriseProps {
    openProps: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    startDate?: Date;
    endDate?: Date;
    disabled?: boolean;
}

export interface SecurityRestriction {
    sunset: string;
    sunrise: string;
    sunriseDateTimeZoneString: string;
}

export interface EntityState {
    entity: {
        profile: {
            securityRestrictions: SecurityRestriction[];
        };
    };
}

export interface Theme {
    breakpoints: {
        down: (size: string) => string;
    };
}

export default SunsetSunrise;
