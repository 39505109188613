import React, { useMemo } from 'react';
import { Autocomplete, AutocompleteInputChangeReason } from '@material-ui/lab';
import { setCreate } from 'actions/entity';
import { useCallback, useEffect, useRef, useState } from 'react';
import { searchTitles } from 'reducers/titles';
import { useAppDispatch, useAppSelector } from 'store';
import { RenderInput } from './Input';
import { Title } from '@mediaseal-webui/types';
import { TitleService } from '@mediaseal-webui/services';

export const TitleSelection = ({ validation }: { validation: (value: Title) => boolean }) => {
    const dispatch = useAppDispatch();
    const [value, setValue] = useState<string>('');
    const [submitting, setSubmitting] = useState<boolean>(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const service = useMemo(() => new TitleService(), []);
    const { content: titles } = useAppSelector((state) => state.titles);

    useEffect(() => {
        dispatch(searchTitles({ keyword: value, service }));
    }, [dispatch, service, value]);

    const handleInputChange = useCallback(
        (event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
            clearTimeout(timeoutRef.current as NodeJS.Timeout);
            setValue(value);
            setSubmitting(true);
            timeoutRef.current = setTimeout(async () => {
                await dispatch(searchTitles({ keyword: value, service }));
            }, 300);
            setSubmitting(false);
        },
        [dispatch, service]
    );
    const handleSelection = useCallback(
        (event: React.ChangeEvent<{}>, value: Title | null) => {
            if (value) {
                validation(value);
                dispatch(
                    setCreate({
                        title: value
                    })
                );
                setValue((ps) => value?.name ?? ps);
            }
        },
        [dispatch, validation]
    );
    useEffect(
        () => () => {
            clearTimeout(timeoutRef.current as NodeJS.Timeout);
        },
        []
    );

    return (
        <Autocomplete
            id='title-select'
            // Handle input value change
            onInputChange={handleInputChange}
            //handle the selection change
            onChange={handleSelection}
            //handle the input value
            inputValue={value}
            //loading state
            loading={submitting}
            // loading text
            loadingText='Searching titles...'
            // feed it title state items
            options={titles as Title[]}
            // No options text
            noOptionsText="Couldn't find any titles.."
            //handle the input
            renderInput={RenderInput({ submitting })}
            //handle the options in dropdown
            renderOption={(option) => option.name}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            // set this to stop the filtering
            filterOptions={(options) => options}
        />
    );
};
