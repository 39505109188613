import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    dialogRoot: {
        maxWidth: 'unset'
    },
    dialogTitle: {
        '&> *': {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.warning.main
        },
        '& svg': {
            marginRight: theme.spacing(1)
        }
    },
    listWrapper: {
        padding: theme.spacing(3),
        paddingTop: 0
    },
    list: {
        border: `1px solid ${theme.palette.grey[400]}`,
        marginTop: theme.spacing(2)
    },

    listItem: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1)
    },
    firstRow: {
        justifyContent: 'flex-end'
    },
    listItemText: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        '& > svg': {
            marginRight: theme.spacing(1)
        }
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
        width: 125
    },
    buttonsWithOverwrite: {
        justifyContent: 'space-around',
        width: 250
    },
    itemCancelButton: {
        marginLeft: theme.spacing(2),
        color: theme.palette.error.main
    },
    warning: {
        color: theme.palette.warning.main
    }
}));
