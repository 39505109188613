import { INavLink } from '.';

export const links: INavLink[] = [
    // {
    //   id: 1,
    //   text: 'Home',
    //   link: '/main',
    //   icon: 'fas fa-home',
    //   navId: 'homeLink'
    // },
    {
        id: 2,
        text: 'JOBS',
        link: '/jobs',
        icon: 'fas fa-clipboard-list',
        navId: 'jobsLink',
        authCheck: 'canViewJobs',
        isKeycloakOnlyComponent: false
    },
    {
        id: 3,
        text: 'TITLES',
        link: '/titles',
        icon: 'fas fa-tags',
        navId: 'titlesLink',
        authCheck: 'canViewTitles',
        isKeycloakOnlyComponent: false
    },
    {
        id: 4,
        text: 'AUDIT',
        link: '/reporting',
        icon: 'fas fa-tasks',
        navId: 'reporting',
        authCheck: 'canViewReporting',
        isKeycloakOnlyComponent: false
    }
];
export const encryptorLinks: INavLink[] = [
    {
        id: 1,
        text: 'USERS',
        link: '/users/encryptor',
        icon: 'fas fa-user',
        navId: 'encryptorUsersLink',
        authCheck: 'canViewEncryptorUsers',
        isKeycloakOnlyComponent: false
    },
    {
        id: 2,
        text: 'GROUPS',
        link: '/groups/encryptor',
        icon: 'fas fa-users',
        navId: 'encryptorGroupsLink',
        authCheck: 'canViewEncryptorGroups',
        isKeycloakOnlyComponent: false
    },
    {
        id: 3,
        text: 'TOKENS',
        link: '/tokens',
        icon: 'fas fa-key',
        navId: 'tokensLink',
        authCheck: 'canAdministerApiTokens',
        isKeycloakOnlyComponent: true
    }
];
export const decryptorLinks: INavLink[] = [
    {
        id: 1,
        text: 'USERS',
        link: '/users/decryptor',
        icon: 'fas fa-user',
        navId: 'decryptorUsersLink',
        authCheck: 'canViewDecryptorUsers',
        isKeycloakOnlyComponent: false
    },
    {
        id: 2,
        text: 'GROUPS',
        link: '/groups/decryptor',
        icon: 'fas fa-users',
        navId: 'decryptorGroupsLink',
        authCheck: 'canViewDecryptorGroups',
        isKeycloakOnlyComponent: false
    }
];
export const utilLinks = [
    // {
    //   id: 1,
    //   text: 'Settings',
    //   link: '/settings',
    //   icon: 'fas fa-cog',
    //   navId: 'settingsLink'
    // }
    // {
    //   id: 2,
    //   text: 'Help',
    //   link: '/help',
    //   icon: 'fas fa-question-circle',
    //   navId: 'helpLink'
    // }
];
