import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getJobRecipients, updateJob } from '../../../../actions/jobs';
import { MainTable } from '@mediaseal-webui/components';
import { profileUserHeaderWithoutActions, profileUserHeader } from '../../../../data/profile-table-headers';
import { removeDecryptorUserFromSummary, removeEntityAndUpdate } from '../../../../actions/entity';
import { setConfirmDialogue } from '../../../../actions/misc';
import { hideRemoveUserConfirmation } from '../../../../constants/preferences';
import { useAuth, useReadOnlyCheck, useTableSort } from '@mediaseal-webui/hooks';
import ActionContainer from '../../../../components/table-components/ActionContainer/ActionContainer';
import { InitialState, User, Entity } from '@mediaseal-webui/types/redux';
import { GenericEntity } from '@mediaseal-webui/types/schemas';
import { validateJob } from '@mediaseal-webui/utils';

export const Users = () => {
    const { filteredDecryptorUsers, profile } = useSelector(({ entity }: InitialState) => entity);
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { isReadOnly } = useReadOnlyCheck(profile, validateJob);
    const [usersPage, setUsersPage] = useState(1);
    const auth = useAuth();
    const type = profile.type;
    const isOnlineJob = type === 'ONLINE';
    // const isEditAuth = auth.edit || auth.administer
    const isCreateAuth = auth.create || auth.administer;
    const { order, orderBy, createSortHandler, headCells } = useTableSort({
        cells: profileUserHeader,
        setPage: setUsersPage,
        fetchFn: getJobRecipients,
        searchValue: ''
    });

    const handleRowClick = ({ id }: { id: Entity['id'] }) => history.push(`/users/decryptor/${id}/profile`);
    const removeHandler = (user: User) => () =>
        dispatch(
            setConfirmDialogue({
                icon: 'warning',
                title: 'Remove User',
                confirmButtonText: 'Remove User',
                message: 'Are you sure you want to remove this user?',
                action: removeEntityAndUpdate({
                    entity: user,
                    id,
                    removeFn: removeDecryptorUserFromSummary,
                    updateFn: updateJob
                }),
                memorable: hideRemoveUserConfirmation
            })
        );
    const handleRemove = useCallback(removeHandler, [dispatch, id]);

    const fetchFn = () => {
        dispatch(getJobRecipients({ id, page: 0, firstCall: true, sort: `${orderBy},${order}` }));
    };

    return (
        <div style={{ width: '100%', height: 400 }}>
            <MainTable
                entities={filteredDecryptorUsers}
                searchValue=''
                id='users-table'
                pageControl={{ page: usersPage, setPage: setUsersPage }}
                // @ts-expect-error due to missing props ts(2322).
                fetchFn={fetchFn}
                tableActionsRenderer={(entity: GenericEntity) => (
                    <ActionContainer
                        create={isCreateAuth}
                        disabled={!isOnlineJob || profile.archived || isReadOnly}
                        //@ts-expect-error due to entity not assignable to type ts(2345).
                        removeAction={handleRemove(entity)}
                    />
                )}
                sortData={{
                    sortBy: orderBy,
                    sortDirection: order || 'desc',
                    createSortHandler
                }}
                columns={headCells}
                onRowClick={handleRowClick}
                noResultsMessage='No users are associated with this job'
            />
        </div>
    );
};
