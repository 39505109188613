import React, { createContext, useState } from 'react';

export interface ProviderContext {
    showModal: boolean;
    type: string;
    clientId: string;
    clientSecret: string;
    redirectUrl: string;
    domain?: string | null;
    tenantId?: string;
}

export interface IIdentityProviderContext {
    setData: React.Dispatch<React.SetStateAction<Omit<IIdentityProviderContext, 'setData'>>>;
    configuredProvider: ProviderContext; // this one is updated for any set on the server
    configureProvider: ProviderContext; // this one is updated for new provider
    defaultGroup?: {
        id: string | number;
        name: string;
    }; // optional context if a default group is configured
}
const providerData = {
    showModal: false,
    type: '',
    clientId: '',
    clientSecret: '',
    redirectUrl: '',
    domain: null
};

export const initialContext = {
    setData: () => {},
    configuredProvider: providerData,
    configureProvider: providerData
};
export const IdentityProviderContext = createContext<IIdentityProviderContext>(initialContext);

export const IdentityProviderContextProvider: React.FC = ({ children }) => {
    const [data, setData] = useState<Omit<IIdentityProviderContext, 'setData'>>({
        configuredProvider: providerData,
        configureProvider: providerData
    });
    return <IdentityProviderContext.Provider value={{ ...data, setData }}>{children}</IdentityProviderContext.Provider>;
};
