import styled, { css, DefaultTheme } from 'styled-components';
import { AiFillCheckCircle } from 'react-icons/ai';
import { MdCancel } from 'react-icons/md';
import { InputProgress } from '../../icons/InputProgress/InputProgress';

export interface StyledTextInputProps extends React.HTMLProps<HTMLInputElement> {
    valid: boolean | null;
    enableScore?: boolean;
}

export const StyledTextInput = styled.input<StyledTextInputProps>(
    ({ theme, valid, disabled, enableScore }) => css`
        width: 100%;
        outline: none;
        padding: 16px;
        overflow: visible;
        position: relative;
        padding-left: ${enableScore ? '48px' : '16px'};
        height: 55px;
        border: ${valid === null ? '1px solid #ccc' : valid ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.error.main}`};
        transition: all 0.35s linear;
        border-radius: 10px;
        color: ${theme.palette.text.primary};
        background: ${disabled ? theme.palette.background.default : theme.palette.background.paper};
        font-size: 12pt;
        font-weight: lighter;
        font-family: 'Montserrat', sans-serif;
    `
);
interface ContainerProps extends Pick<StyledTextInputProps, 'valid'> {}
interface IconProps extends Pick<StyledTextInputProps, 'valid'> {
    theme: DefaultTheme;
}

const centered = css`
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
`;
export const StyledContainer = styled.div<ContainerProps>(
    ({ theme, valid }) => css`
        display: flex;
        width: 100%;
        position: relative;
    `
);

const iconStyles = css`
    position: absolute;
    right: 10px;
    top: calc(50% - 10px);
    width: 20px;
    height: 20px;
`;

export const SuccessIcon = styled(AiFillCheckCircle)`
    ${({ theme, valid }: IconProps) => css`
        ${iconStyles};
        visibility: ${valid ? 'visible' : 'hidden'};
        color: #007e9c;
    `}
`;

export const FailIcon = styled(MdCancel)(
    ({ theme, valid }: IconProps) => css`
        ${iconStyles};
        visibility: ${valid === null ? 'hidden' : valid ? 'hidden' : 'visible'};
        color: #ff6666;
    `
);

export const StyledInputProgress = styled(InputProgress)`
    position: absolute;
    left: 10px;
    top: calc(50% - 15px);
`;
