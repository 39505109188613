import {
    AuthModes,
    CreateJobSchema,
    Endpoints,
    DetermineAuthModeProps,
    DataFlag,
    AuthTimes,
    ThunkResult,
    InitialState,
    User,
    Group
} from '@mediaseal-webui/types';
import { format } from 'date-fns';
import { msApi } from '@mediaseal-webui/api';
import { clearJobList, displayJobCreationSuccess } from '.';
import { setError } from '../entity';

interface CreateJobPayload extends CreateJobSchema {}
interface ContstructStringArrArgs {
    entities: User[];
    endpoint: Endpoints;
}
export const constructStringArr = ({ entities, endpoint }: ContstructStringArrArgs): string[] =>
    entities.map((entity: User | Group) => `/api/${endpoint}/${entity.id}`);
export const determineAuthMode = ({ current, flags }: DetermineAuthModeProps): AuthModes =>
    flags.securePlayerRequired && flags.qrOverlayEnabled
        ? ((current + 24) as AuthModes)
        : flags.securePlayerRequired && !flags.qrOverlayEnabled
        ? ((current + 8) as AuthModes)
        : current;

export const DISABLED_AUT0_AUTH: DataFlag = 1;
export const ENABLED_AUTO_AUTH: DataFlag = 0;
export const DEFAULT_REAUTH_TIME: AuthTimes = 28800;

export const createJobPayload = (state: InitialState): CreateJobPayload | boolean => {
    const { entity, jobs } = state;
    const { securityOptions } = jobs;
    const { create } = entity;
    const { title, decryptorGroups, decryptorUsers, sunrise, sunset, timezone, template } = create;
    if (create.type) {
        const userLinkArray = decryptorUsers && decryptorUsers.length > 0 ? constructStringArr({ entities: decryptorUsers, endpoint: 'recipients' }) : [];
        const groupLinkArray = decryptorGroups && decryptorGroups.length > 0 ? constructStringArr({ entities: decryptorGroups, endpoint: 'recipients' }) : [];
        return {
            ...create,
            title: `/api/titles/${title.id}`,
            decryptorUsers: userLinkArray,
            decryptorGroups: groupLinkArray,
            active: true,
            template: template || false,
            securityRestrictions: securityOptions?.disableSunset // This logic needs to be refactored to a component level...
                ? []
                : [
                      {
                          entityId: 0,
                          entityType: 0,
                          sunrise: sunrise || new Date(),
                          sunset: sunset || new Date(),
                          password: create.password,
                          sunriseDateTimeZoneString: `${format(new Date(sunrise || ''), 'dd-MM-yyyy HH:mm')},${
                              timezone || securityOptions?.defaultTimeZone || securityOptions?.defaultTimeZone
                          }`,
                          sunsetDateTimeZoneString: `${format(new Date(sunset || ''), 'dd-MM-yyyy HH:mm')},${
                              timezone || securityOptions?.defaultTimeZone || securityOptions?.defaultTimeZone
                          }`
                      }
                  ]
        } as CreateJobPayload;
    } else {
        return false;
    }
};
export const createJob =
    (history: any): ThunkResult<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            const payload = createJobPayload(getState());
            if (typeof payload === 'object') {
                await msApi.post('/jobs?projection=simple', payload);
                dispatch(clearJobList());
                history.push('/jobs');
                dispatch(displayJobCreationSuccess(payload.name));
            } else {
                dispatch(setError({ message: 'Could not construct appropriate payload' }));
            }
        } catch (error) {
            dispatch({ type: 'FETCH_ERROR', payload: error });
        }
    };
