import { combineReducers } from 'redux';
import {
    REMOVE_DEC_USER_FROM_SUMMARY,
    REMOVE_ENC_USER_FROM_SUMMARY,
    ADD_DEC_USER_TO_SUMMARY,
    ADD_ENC_USER_TO_SUMMARY,
    FILTER_DECRYPTOR_USER_ASSIGNED_TO_ENTITY,
    FILTER_USER_ASSIGNED_TO_ENTITY,
    FILTER_USERS_AND_GROUPS
} from '../../constants';
import { SET_DEFAULT_DECRYPTOR_USER } from '../../constants/reporting';
import {
    SET_RECIPIENTS,
    CLEAR_USERS,
    SET_USER_PAGE_COUNT,
    SET_TOTAL_ENC_USERS,
    SET_TOTAL_DEC_USER,
    SET_ENCRYPTOR_USER_PAGE_COUNT,
    FETCH_USERS,
    SET_USER_PAGE_SEARCH_VALUE,
    CLEAR_USER_PAGE_SEARCH_VALUE,
    FETCH_ENC_USERS,
    SET_FILTERED_ENC_USERS
} from '../../constants/users';
import { seedData } from '../../helpers';
import { CLEAR_SEARCHES } from '../../constants/misc';
import { SELECT_DEC_USER, DESELECT_DEC_USER, CANCEL_SELECTION, SUBMIT_SELECTION, SELECT_ENC_USER, DESELECT_ENC_USER } from '../../constants/entity';

const filteredUsers = (filteredDecUsers = seedData(), { type, payload }) => {
    switch (type) {
        case SET_RECIPIENTS:
            return payload;
        case FETCH_USERS:
            return [...filteredDecUsers, ...payload];
        case 'REMOVE_SELECTED_USER':
        case 'REMOVE_RECIPIENT':
        case FILTER_USERS_AND_GROUPS:
        case 'REMOVE_FILTERED_DEC_USERS_FROM_GROUP':
        case ADD_DEC_USER_TO_SUMMARY:
            return filteredDecUsers.filter((user) => user.id !== payload.id);
        case FILTER_DECRYPTOR_USER_ASSIGNED_TO_ENTITY:
            return filteredDecUsers.filter((user) => user.id !== payload.id);
        case REMOVE_DEC_USER_FROM_SUMMARY:
        case SET_DEFAULT_DECRYPTOR_USER:
            return [...filteredDecUsers, payload];
        case 'REMOVE_FILTERED_DEC_USERS_FROM_DEC_GROUP':
            return filteredDecUsers.filter((user) => user.id !== payload.id);
        case SELECT_DEC_USER:
            return filteredDecUsers.map((group) => {
                if (group.id === payload) {
                    return { ...group, selected: true };
                }
                return group;
            });
        case DESELECT_DEC_USER:
            return filteredDecUsers.map((group) => {
                if (group.id === payload) {
                    return { ...group, selected: false };
                }
                return group;
            });
        case CANCEL_SELECTION:
        case SUBMIT_SELECTION:
            return filteredDecUsers.map((group) => ({ ...group, selected: false }));
        case CLEAR_USERS:
            return seedData();
        default:
            return filteredDecUsers;
    }
};

const userPageCount = (userPages = 0, { type, payload }) => {
    switch (type) {
        case SET_USER_PAGE_COUNT:
            return payload;
        default:
            return userPages;
    }
};

const userPageSearchValue = (value = '', { type, payload }) => {
    switch (type) {
        case SET_USER_PAGE_SEARCH_VALUE:
            return payload;
        case CLEAR_USER_PAGE_SEARCH_VALUE:
        case CLEAR_SEARCHES:
            return '';
        default:
            return value;
    }
};

const totalPages = (pages = 0, { type, payload }) => {
    switch (type) {
        case SET_USER_PAGE_COUNT:
            return payload;
        default:
            return pages;
    }
};

const encryptorUsers = (encUsers = seedData(), { type, payload }) => {
    switch (type) {
        case SET_FILTERED_ENC_USERS:
            return payload;
        case FETCH_ENC_USERS:
            return [...encUsers, ...payload];
        case ADD_ENC_USER_TO_SUMMARY:
            return encUsers.filter((user) => user.id !== payload.id);
        case FILTER_USERS_AND_GROUPS:
        case FILTER_USER_ASSIGNED_TO_ENTITY:
            return encUsers.filter((user) => user.id !== payload.id);
        case REMOVE_ENC_USER_FROM_SUMMARY:
            return [...encUsers, payload];
        case SELECT_ENC_USER:
            return encUsers.map((group) => {
                if (group.id === payload) {
                    return { ...group, selected: true };
                }
                return group;
            });
        case DESELECT_ENC_USER:
            return encUsers.map((group) => {
                if (group.id === payload) {
                    return { ...group, selected: false };
                }
                return group;
            });
        case CANCEL_SELECTION:
        case SUBMIT_SELECTION:
            return encUsers.map((group) => ({ ...group, selected: false }));
        case CLEAR_USERS:
            return seedData();
        default:
            return encUsers;
    }
};

const totalEncryptorUserPages = (totalEncUsersPages = 0, { type, payload }) => {
    switch (type) {
        case SET_ENCRYPTOR_USER_PAGE_COUNT:
            return payload;
        default:
            return totalEncUsersPages;
    }
};

const totalDecUsers = (state = 0, { type, payload }) => {
    switch (type) {
        case SET_TOTAL_DEC_USER:
            return payload;
        default:
            return state;
    }
};
const totalEncUsers = (state = 0, { type, payload }) => {
    switch (type) {
        case SET_TOTAL_ENC_USERS:
            return payload;
        default:
            return state;
    }
};

export default combineReducers({
    filteredUsers,
    userPageCount,
    totalEncryptorUserPages,
    encryptorUsers,
    totalPages,
    userPageSearchValue,
    totalDecUsers,
    totalEncUsers
});
