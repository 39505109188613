import React from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, Typography, DialogActions, Button } from '@material-ui/core';
import { Warning as WarningIcon } from '@material-ui/icons';
import { List, AutoSizer, ListRowRenderer, Index } from 'react-virtualized';
import { useStyles, FileRow, fileIssueMessage, FirstRow } from '.';
import { cancelEncryptionJob } from '@mediaseal-webui/actions';
import { useFilesWithIssues } from '@mediaseal-webui/hooks';

export const FileIssuesModal = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const files = useFilesWithIssues();

    const handleCancel = () => dispatch(cancelEncryptionJob());
    //@ts-ignore
    const rowRenderer: ListRowRenderer = ({ index, key }) => {
        const file = files[index];
        const issue = files[0].workIssue.errorType;
        return (
            <>
                {index === 0 && files.length > 1 && <FirstRow issue={issue} />}
                <FileRow file={file} key={key} />
            </>
        );
    };

    const listHeight = () => (files.length === 1 ? 100 : files.length < 5 ? 350 : 500);
    const rowHeight: (info: Index) => number = ({ index }) => (index === 0 ? 250 : 50);

    return (
        <Dialog fullWidth open={!!files.length} classes={{ paperFullWidth: classes.dialogRoot }}>
            <DialogTitle classes={{ root: classes.dialogTitle }}>
                <WarningIcon fontSize='large' className={classes.warning} />
                <Typography color='textPrimary' variant='h6'>
                    Warning Encrypting File{files.length > 1 && 's'}
                </Typography>
            </DialogTitle>
            <DialogContent classes={{ root: classes.listWrapper }}>
                <Typography color='textPrimary' variant='body1'>
                    {fileIssueMessage(files && files[0] && files[0].workIssue.errorType)}
                </Typography>
                <AutoSizer disableHeight>
                    {({ width }) => (
                        <List
                            height={listHeight()}
                            rowCount={files.length}
                            rowHeight={rowHeight}
                            rowRenderer={rowRenderer}
                            width={width}
                            style={{ outline: 'none' }}
                            className={classes.list}
                        />
                    )}
                </AutoSizer>
                {
                    // check if overwrite just confirmed or whatever - if so undo option
                    // cancelled x of y files
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color='primary' data-testid='cancel-all-button'>
                    Cancel Encryption Job
                </Button>
            </DialogActions>
        </Dialog>
    );
};
