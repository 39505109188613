import { Dispatchable, InitialState } from '@mediaseal-webui/types';

declare global {
    interface Window {
        $store?: {
            getState: () => InitialState;
            dispatch: (arg: Dispatchable) => void;
        };
    }
}

export const useWindowStore = () => {
    return window.$store?.getState();
};
