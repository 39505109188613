import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InitialState } from '@mediaseal-webui/types/redux';

export interface HistogramData {
    label: string;
    granted: number;
    denied: number;
}

export const useHistogramData = () => {
    const { histogram, timesGranted, timesDenied } = useSelector(({ entity }: InitialState) => entity.profile);
    const [monthData, setMonthData] = useState<HistogramData[]>([
        { label: '', granted: 0, denied: 0 },
        { label: '', granted: 0, denied: 0 },
        { label: '', granted: 0, denied: 0 }
    ]);

    useEffect(() => {
        if (histogram && histogram.length > 0) {
            const totals = Object.entries(histogram[0].months)
                .filter((month, index) => index >= new Date().getMonth() - 2 && index <= new Date().getMonth())

                //@ts-ignore
                .map(
                    ([key, value]): HistogramData => ({
                        label: key.toLocaleUpperCase(),
                        granted: value?.granted,
                        denied: value?.denied
                    })
                );
            setMonthData(totals);
        }
    }, [histogram]);

    useEffect(() => () => setMonthData([] as HistogramData[]), []);

    return { monthData, timesGranted, timesDenied };
};
