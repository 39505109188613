import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    editableRoot: {
        // width: '50%',
        transition: 'all 1s ease',
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        display: 'flex',
        minWidth: 250,
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.8em',
            maxWidth: '90%',
            // overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            paddingTop: theme.spacing(1),
            width: '90%'
        },
        '& input': {
            '&::before': {
                border: 'none'
            }
        },
        '& .Mui-disabled': {
            color: 'unset'
        }
    },
    icon: {
        position: 'absolute',
        right: 10,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.shorter
        })
    },
    success: {
        color: theme.palette.success.main
    },
    failure: {
        color: theme.palette.error.main
    },
    withLabelRoot: {
        paddingLeft: theme.spacing(2),
        marginTop: `${theme.spacing(2)}px !important`,
        '& > form': {
            display: 'flex !important',
            flexWrap: 'wrap'
        },
        '& $text': {
            fontSize: '1.2rem !important'
        }
    },
    withLabelSpan: {
        display: 'flex',
        height: 30,
        position: 'relative',
        width: '100%',
        alignItems: 'flex-end',
        '& $editIcon': {
            marginLeft: theme.spacing(3),
            width: 30
        }
    },
    almostFullWidth: {
        [theme.breakpoints.up('sm')]: {
            width: '100%'
        }
    },
    text: {
        fontSize: '2.125rem',
        transition: 'all 1s ease',
        fontFamily: theme.typography.fontFamily,
        textOverflow: 'ellipsis',
        whitespace: 'nowrap',
        overflow: 'hidden',
        minWidth: 200
    },
    editIcon: {
        '&:hover': {
            // @ts-ignore we had to YOLO it
            background: theme.palette.buttons.light,
            color: theme.palette.common.white
        },
        [theme.breakpoints.down('xs')]: {
            //@ts-ignore
            background: `${theme.palette.buttons.light} !important`,
            color: theme.palette.common.white
        }
    },
    actionButtons: {
        // opacity: 0.75,
        background: theme.palette.action.hover,
        borderRadius: 30,
        marginLeft: theme.spacing(1),
        width: 60,
        // height: (label) => label ? 60 : 60
        [theme.breakpoints.down('xs')]: {
            maxHeight: 50
        }
    },
    btnSuccess: {
        '&:hover': {
            //@ts-ignore this one too
            background: theme.palette.buttons.main,
            color: theme.palette.common.white
        }
    },
    btnError: {
        '&:hover': {
            //@ts-ignore typescript doesnt know about this one for some strange reason, its lying to make friends. Typescript needs more friends. [INSERT ROSS GIF HERE]
            background: theme.palette.buttons.dark,
            color: theme.palette.common.white
        }
    },
    actionsContaienr: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    loader: {
        width: `${theme.spacing(3)}px!important`,
        height: `${theme.spacing(3)}px!important`
    },
    enabledInput: {
        background: theme.palette.action.hover,
        borderRadius: theme.spacing(1)
    },
    form: {
        display: 'flex',
        width: '100%'
    },
    smallBtns: {
        width: 30
    },
    smallIcons: {
        width: '0.75em'
    },
    smallerBtns: {
        height: 36,
        padding: 0,
        [theme.breakpoints.down('xs')]: {
            width: 30
        }
    },
    label: {
        display: 'block'
    }
}));

export default useStyles;

interface StyledTextFieldProps {
    width: number;
    maxWidth?: string;
    isEnabled?: boolean;
}

export const StyledTextField = styled(TextField)<StyledTextFieldProps>(
    ({ theme, width, maxWidth, isEnabled }) => `
    width: ${width ? `${(width + 1) * 19}px` : 'auto'};
    min-width: 250px;
    max-width: ${maxWidth};
    color: ${theme.palette.text.primary};
    ${theme.breakpoints.down('sm')} {
        max-width: 400px;
    }
    & input {
        padding-right: ${isEnabled ? '30px' : 0} !important;
        //@ts-ignore
        background-color: ${theme.palette.background.light};
    }
`
);
