import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import MenuBook from '@material-ui/icons/MenuBookTwoTone';
import { Typography } from '@material-ui/core';
import useStyles from './NoResults.styles';

/**
 *
 * @param {message} string -- Optional error message override
 */
const NoResults = ({ message, assoc }) => {
    const classes = useStyles();

    return (
        <div className={classes.centered}>
            <div className={classes.icon}>
                <SearchIcon classes={{ root: classes.search }} />
                <MenuBook classes={{ root: classes.book }} />
            </div>
            <Typography color='textPrimary' component='div' variant='body2' className='errorMessage' classes={{ root: classes.errorMessage }}>
                {message}
            </Typography>
        </div>
    );
};

NoResults.propTypes = {
    message: PropTypes.string
};

NoResults.defaultProps = {
    message: "Sorry we can't find anything matching that search"
};

export default React.memo(NoResults);
