import React, { ElementType } from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from '../Header.styles';

export interface HeaderItemProps extends React.HTMLAttributes<HTMLDivElement> {
    Icon: ElementType;
    /** flag for whether to display "selected" */
    newItem?: boolean;
    /** flag for whether content is fetching */
    isFetching?: boolean;
    className: string;
    hovering: boolean;
    TooltipComponent?: React.ElementType;
    classes?: Record<'icon', string>;
}

export const HeaderItem = ({ Icon, newItem, isFetching, onClick, className, hovering, TooltipComponent, classes: cx, ...others }: HeaderItemProps) => {
    const classes = useStyles();
    return (
        <Typography
            className={clsx(classes.subtitle, {
                [classes.clickable]: onClick,
                [className]: className
            })}
            display='inline'
            variant='subtitle2'
            onClick={onClick || (() => {})}
            //@ts-expect-error due to Type 'string' is not assignable ts(2769).
            color='textSecondary'
            {...others}>
            {Icon && (
                <Icon
                    className={clsx({
                        [cx?.icon ?? '']: cx?.icon
                    })}
                />
            )}
        </Typography>
    );
};

HeaderItem.defaultProps = {
    className: '',
    hovering: false
} as Partial<HeaderItemProps>;

export default React.memo(HeaderItem);
