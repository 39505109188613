import { ISetJobStartTypeProps } from '@mediaseal-webui/types/encryptor';
import { ThunkResult } from '@mediaseal-webui/types/redux';
import { startOnlineEncryptionWork } from '../slots';
import { getACL } from './getACL';

export const setStartJobType = async ({ currentWork, id, type, mediaKey, fileId }: ISetJobStartTypeProps, dispatch) => {
    const { jobData } = currentWork;
    if (window.qt) {
        switch (type) {
            case 'ONLINE':
                startOnlineEncryptionWork(
                    {
                        id,
                        fileFeatures: jobData.features,
                        fileSettings: {
                            fileId,
                            mediaKey,
                            headerVersion: jobData.headerVersion,
                            additionalDataVersion: jobData.additionalDataVersion,
                            contactName: jobData.contactName || '',
                            contactNumber: jobData.contactNumber || ''
                        }
                    },
                    dispatch
                );
                break;
            case 'OFFLINE':
                await getACL(
                    {
                        currentWork,
                        id,
                        type: currentWork.jobData.type,
                        mediaKey,
                        fileId
                    },
                    false,
                    dispatch
                );
                break;
            case 'PASSWORD_ONLY':
                await getACL(
                    {
                        currentWork,
                        id,
                        type: currentWork.jobData.type,
                        mediaKey,
                        fileId
                    },
                    true,
                    dispatch
                );
                break;
            default:
                break;
        }
    }
};
