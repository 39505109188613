import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    viewRoot: {
        // overflow: 'hidden',
    },

    nameFields: {
        display: 'inline-flex'
    },
    additionalItems: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        right: theme.spacing(2),
        bottom: theme.spacing(1),
        transition: `all 0.5s ease`,
        [theme.breakpoints.down('md')]: {
            bottom: 120
        }
    }
}));

export default useStyles;
