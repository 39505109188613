import { MdClose } from 'react-icons/md';
import styled, { css } from 'styled-components';
import { fadeIn } from '../Button';

export const StyledTag = styled.div`
    ${({ theme }) => css`
        /* height: 50px; */
        border-radius: calc((25px / 2) + 2px);
        width: fit-content;
        background: ${theme.palette.primary.main};
        box-shadow: ${theme.shadows[5]};
        display: flex;
        opacity: 0;
        margin: ${theme.spacing(0.5)}px;
        animation: ${fadeIn} 0.35s ease;
        animation-fill-mode: both;
        align-items: center;
        p {
            color: ${theme.palette.common.white};
        }
        padding: ${theme.spacing(0.5)}px;
        padding-left: ${theme.spacing(1)}px;
        padding-right: ${theme.spacing(1)}px;
        font-face: 'Raleway', sans-serif;
        transition: transform 0.35s ease;
        font-size: 12px;
        &:hover {
            cursor: default;
            transform: scale(1.015);
        }
    `}
`;
export const StyledClose = styled(MdClose)`
    ${({ theme }) => css`
        margin-left: ${theme.spacing(1)}px;
        color: ${theme.palette.common.white};
        opacity: 0.5;
        &:hover {
            opacity: 1;
            cursor: pointer;
        }
    `}
`;

export const TagBody = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledName = styled.p`
    ${({ theme }) => css`
        margin: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
        color: ${theme.palette.text.primary};
    `}
`;
export const StyledTagValue = styled.p`
    ${({ theme }) => css`
        font-size: 10px;
        margin: 0;
        color: ${theme.palette.text.secondary};
    `}
`;
