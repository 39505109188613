import styled, { css } from 'styled-components';
import { LinearProgress } from '@material-ui/core';
import { fadeIn } from '../Button';
import { Typography } from '@material-ui/core';

export const StyledContainer = styled.div`
    ${({ theme }) => css`
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    background ${theme.palette.background.paper};
    animation: ${fadeIn} 0.55s ease;
    animation-fill-mode: both;
    align-items: center;
    justify-content: center;
  `}
`;

export const StyledProgress = styled(LinearProgress)`
    ${({ theme }) => css`
    width: 150px;
    height: 10px;
    border-radius: 5px;
   
    background ${theme.palette.grey[300]};
    border-radius: 10px;
    margin-top: ${theme.spacing(3)}px;
    .MuiLinearProgress-barColorPrimary {
        background-color: ${theme.palette.primary.light};
    }
  `}
`;

export const StyledSubText = styled(Typography)`
    ${({ theme }) => css`
        margin-top: ${theme.spacing(2)}px;
    `}
`;
