import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { PlayArrow } from '@material-ui/icons';
import { BtnProps } from '..';
import { EncryptFile } from '@mediaseal-webui/types/encryptor';
// import { Test } from './ResumeBtn.styles';

export const ResumeBtn = ({ file }: BtnProps) => {
    const handleResumeClick = (file: EncryptFile) => {
        if (window.qt) {
            window.EncryptionWebInterface.resumeEncryptionWork(file.id);
        }
    };
    return (
        <Tooltip title='Resume Job' placement='top'>
            <IconButton
                id='resume-btn'
                onClick={() => {
                    handleResumeClick(file);
                }}>
                <PlayArrow fontSize='small' />
            </IconButton>
        </Tooltip>
    );
};

export default ResumeBtn;
