import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEntityDelete, useTableSortV2, useTableScroller } from '@mediaseal-webui/hooks';
import { useAppDispatch, useAppSelector } from 'store';
import { TokensService } from '@mediaseal-webui/services';
import { fetchTokens, searchTokens, tokensSlice } from 'reducers/tokens/tokens';
import { Header } from 'components/layout-components';
import { Content, MainTable, SubmitButton } from '@mediaseal-webui/components';
import { definitions } from '../../../../types/api';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveAction, { RemoveActionProps } from 'components/table-components/RemoveAction';
import { fetchPermissions, setConfirmDialogue } from '@mediaseal-webui/actions';
import { PermissionsModal } from 'components/token-components/PermissionsModal/PermissionsModal';
import { tokensHeader } from 'data';

const Tokens: React.FC = () => {
    const [rowPermissions, setRowPermissions] = useState();
    const [isPermissionsModalOpen, setIsPermissionsModalOpen] = useState(false);

    useTableScroller();

    const dispatch = useAppDispatch();
    const history = useHistory();

    const service = useMemo(() => new TokensService(), []);

    const fetchFn = useCallback(() => dispatch(searchTokens({ keyword: service.baseParams.keyword ?? '', service })), [service, dispatch]);

    const { memoDeleteEntityAndReFetch } = useEntityDelete({ fetchFn });
    const { setSearchValue, clearSearchValue, clearTokens } = tokensSlice.actions;
    const { searchValue, content: tokens, pending, page } = useAppSelector((state) => state.tokens);
    const { headCells, sortFn, order, orderBy } = useTableSortV2<TokensService>({
        service,
        fetchFn,
        cells: tokensHeader
    });

    useEffect(() => {
        window.qt && window.GeneralWebInterface.allowFileDrop(false, { dropBehaviour: 'off' });
        dispatch(clearTokens());
    }, [clearTokens, dispatch]);

    useEffect(() => {
        dispatch(fetchPermissions());
    }, [dispatch]);

    const onRowClick = (data) => {
        setRowPermissions(data.permissionNames);

        setIsPermissionsModalOpen(!isPermissionsModalOpen);
    };

    const handleCreateClick = () => history.push('/tokens/create');

    const CreateButton = () => <SubmitButton title='Create Token' id='create-token-btn' disabled={false} onClick={handleCreateClick} />;

    const fetchNextPage: unknown = useCallback(() => {
        if (service.baseParams.page <= (page?.totalPages ?? 0) && tokens.length >= 100) {
            service.setParams({
                page: service.baseParams.page + 1
            });
            dispatch(fetchTokens(service));
        }
    }, [tokens.length, dispatch, page?.totalPages, service]);

    const initFetch = useCallback(() => {
        dispatch(searchTokens({ service, keyword: '' }));
    }, [dispatch, service]);

    useEffect(() => {
        initFetch();
    }, [initFetch]);

    const newTokens = tokens.map((token) => {
        const typedToken = token as definitions['TokenGetDTO'];

        return {
            ...token,
            createDate: typedToken.createDate,
            createdBy: typedToken.createdBy?.fullName,
            lastUsed: typedToken.lastUsed,
            expirationDate: typedToken.expirationDate
        };
    });

    const RevokeContainer = ({ ...others }: RemoveActionProps) => {
        return (
            <div tabIndex={-1} style={{ display: 'flex' }}>
                <RemoveAction {...others} />
            </div>
        );
    };

    const handleTokenDelete = (entity) => () =>
        dispatch(
            setConfirmDialogue({
                icon: 'delete',
                title: 'Revoke Token',
                confirmButtonText: 'Revoke Token',
                message: 'Are you sure you want to revoke this token?',
                action: memoDeleteEntityAndReFetch({ entity, endpoint: 'tokens' })
            })
        );

    return (
        <div>
            <PermissionsModal
                open={isPermissionsModalOpen}
                handleClose={() => {
                    setIsPermissionsModalOpen(!isPermissionsModalOpen);
                    setRowPermissions(undefined);
                }}
                tokenPermissions={rowPermissions}
            />
            <Header
                list
                name='Tokens'
                ActionButton={CreateButton}
                displayingText={`Displaying ${tokens.length} of ${page?.totalElements} tokens`}
                breadcrumbProps={{
                    links: [{ id: 0, name: 'Tokens', to: '/tokens' }],
                    text: [{ id: 0, name: '' }],
                    isFetching: pending
                }}
                searchProps={{
                    setPage: () => null,
                    searchFn: 'searchTokens',
                    value: searchValue,
                    clear: clearSearchValue,
                    set: setSearchValue
                }}
            />

            <Content>
                {() => (
                    <MainTable
                        entities={newTokens}
                        id='tokens-table'
                        isFetching={pending}
                        totalPages={page?.totalPages}
                        onRowClick={onRowClick}
                        noResultsMessage='No results returned'
                        tableActionsRenderer={(entity) => {
                            return <RevokeContainer tooltipTitle={'Revoke'} removeAction={handleTokenDelete(entity)} RemoveIcon={DeleteIcon} />;
                        }}
                        searchValue={searchValue}
                        pageControl={{ page: service.baseParams.page, setPage: () => null }}
                        entityLink='/tokens'
                        sortData={{
                            sortBy: orderBy,
                            sortDirection: order,
                            createSortHandler: sortFn
                        }}
                        fetchFn={fetchNextPage as () => Promise<void>}
                        columns={headCells}
                    />
                )}
            </Content>
        </div>
    );
};

export { Tokens };
