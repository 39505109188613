import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { TableColumns } from '@mediaseal-webui/types/generic';
export * from './headers/jobHeader.data';
export const data01 = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 }
];
export const data02 = [
    { name: 'Downloads', value: 100 },
    { name: 'A2', value: 300 },
    { name: 'B1', value: 100 },
    { name: 'B2', value: 80 },
    { name: 'B3', value: 40 },
    { name: 'B4', value: 30 },
    { name: 'B5', value: 50 },
    { name: 'C1', value: 100 },
    { name: 'C2', value: 200 },
    { name: 'D1', value: 150 },
    { name: 'D2', value: 50 }
];
export const data = [
    {
        name: 'M',
        files: 4000,
        pv: 2400,
        amt: 2400
    },
    {
        name: 'T',
        files: 3000,
        pv: 1398,
        amt: 2210
    },
    {
        name: 'W',
        files: 2000,
        pv: 9800,
        amt: 2290
    },
    {
        name: 'T',
        files: 2780,
        pv: 3908,
        amt: 2000
    },
    {
        name: 'F',
        files: 1890,
        pv: 4800,
        amt: 2181
    },
    {
        name: 'S',
        files: 2390,
        pv: 3800,
        amt: 2500
    },
    {
        name: 'S',
        files: 3490,
        pv: 4300,
        amt: 2100
    }
];

export const permissionData = [
    {
        permissionName: 'canAdministerEncryptorGroups',
        description: null,
        id: 73,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/73'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/73'
            }
        ]
    },
    {
        permissionName: 'CANVIEW_TITLES',
        description: null,
        id: 1,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/1'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/1'
            }
        ]
    },
    {
        permissionName: 'canAdministerJobs',
        description: null,
        id: 17,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/17'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/17'
            }
        ]
    },
    {
        permissionName: 'canAdministerTitles',
        description: null,
        id: 18,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/18'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/18'
            }
        ]
    },
    {
        permissionName: 'canAdministerDecryptorDepartments',
        description: null,
        id: 51,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/51'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/51'
            }
        ]
    },
    {
        permissionName: 'canAdministerReporting',
        description: null,
        id: 74,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/74'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/74'
            }
        ]
    },
    {
        permissionName: 'canAdministerGlobalSettings',
        description: null,
        id: 75,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/75'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/75'
            }
        ]
    },
    {
        permissionName: 'canAdministerLocalSettings',
        description: null,
        id: 76,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/76'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/76'
            }
        ]
    },
    {
        permissionName: 'canAdministerDecryptorGroups',
        description: null,
        id: 77,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/77'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/77'
            }
        ]
    },
    {
        permissionName: 'canAdministerDecryptorUsers',
        description: null,
        id: 78,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/78'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/78'
            }
        ]
    },
    {
        permissionName: 'canAdministerEncryptorUsers',
        description: null,
        id: 79,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/79'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/79'
            }
        ]
    },
    {
        permissionName: 'canAllowNoJobPassword',
        description: null,
        id: 80,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/80'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/80'
            }
        ]
    },
    {
        permissionName: 'canAdministerOwnPersonalDetails',
        description: null,
        id: 81,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/81'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/81'
            }
        ]
    },
    {
        permissionName: 'canCreateJobs',
        description: null,
        id: 82,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/82'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/82'
            }
        ]
    },
    {
        permissionName: 'canArchiveJobs',
        description: null,
        id: 83,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/83'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/83'
            }
        ]
    },
    {
        permissionName: 'canEditJobs',
        description: null,
        id: 84,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/84'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/84'
            }
        ]
    },
    {
        permissionName: 'canCreateTitles',
        description: null,
        id: 85,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/85'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/85'
            }
        ]
    },
    {
        permissionName: 'canEditTitles',
        description: null,
        id: 86,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/86'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/86'
            }
        ]
    },
    {
        permissionName: 'canArchiveTitles',
        description: null,
        id: 87,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/87'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/87'
            }
        ]
    },
    {
        permissionName: 'canCreateDecryptorUsers',
        description: null,
        id: 88,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/88'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/88'
            }
        ]
    },
    {
        permissionName: 'canEditDecryptorUsers',
        description: null,
        id: 89,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/89'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/89'
            }
        ]
    },
    {
        permissionName: 'canActivateDecryptorUsers',
        description: null,
        id: 90,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/90'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/90'
            }
        ]
    },
    {
        permissionName: 'canCreateDecryptorGroups',
        description: null,
        id: 91,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/91'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/91'
            }
        ]
    },
    {
        permissionName: 'canEditDecryptorGroups',
        description: null,
        id: 92,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/92'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/92'
            }
        ]
    },
    {
        permissionName: 'canActivateDecryptorGroups',
        description: null,
        id: 93,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/93'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/93'
            }
        ]
    },
    {
        permissionName: 'canCreateEncryptorUsers',
        description: null,
        id: 94,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/94'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/94'
            }
        ]
    },
    {
        permissionName: 'canEditEncryptorUsers',
        description: null,
        id: 95,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/95'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/95'
            }
        ]
    },
    {
        permissionName: 'canActivateEncryptorUsers',
        description: null,
        id: 96,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/96'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/96'
            }
        ]
    },
    {
        permissionName: 'canCreateEncryptorGroups',
        description: null,
        id: 97,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/97'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/97'
            }
        ]
    },
    {
        permissionName: 'canEditEncryptorGroups',
        description: null,
        id: 98,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/98'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/98'
            }
        ]
    },
    {
        permissionName: 'canActivateEncryptorGroups',
        description: null,
        id: 99,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/99'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/99'
            }
        ]
    },
    {
        permissionName: 'canCreateDecryptorDepartments',
        description: null,
        id: 100,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/100'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/100'
            }
        ]
    },
    {
        permissionName: 'canEditDecryptorDepartments',
        description: null,
        id: 101,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/101'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/101'
            }
        ]
    },
    {
        permissionName: 'canActivateDecryptorDepartments',
        description: null,
        id: 102,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/102'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/102'
            }
        ]
    },
    {
        permissionName: 'canExportReports',
        description: null,
        id: 103,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/103'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/103'
            }
        ]
    },
    {
        permissionName: 'canExportReports',
        description: null,
        id: 104,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/104'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/104'
            }
        ]
    },
    {
        permissionName: 'canViewJobs',
        description: null,
        id: 105,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/105'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/105'
            }
        ]
    },
    {
        permissionName: 'canViewTitles',
        description: null,
        id: 106,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/106'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/106'
            }
        ]
    },
    {
        permissionName: 'canViewEncryptorUsers',
        description: null,
        id: 107,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/107'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/107'
            }
        ]
    },
    {
        permissionName: 'canViewEncryptorGroups',
        description: null,
        id: 108,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/108'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/108'
            }
        ]
    },
    {
        permissionName: 'canViewDecryptorUsers',
        description: null,
        id: 109,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/109'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/109'
            }
        ]
    },
    {
        permissionName: 'canViewDecryptorGroups',
        description: null,
        id: 110,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/110'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/110'
            }
        ]
    },
    {
        permissionName: 'canViewDecryptorDepartments',
        description: null,
        id: 111,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/111'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/111'
            }
        ]
    },
    {
        permissionName: 'canViewReporting',
        description: null,
        id: 112,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/112'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/112'
            }
        ]
    },
    {
        permissionName: 'canCopyOnJobs',
        description: null,
        id: 113,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/113'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/113'
            }
        ]
    },
    {
        permissionName: 'canTranscodeOnJobs',
        description: null,
        id: 114,
        content: [],
        links: [
            {
                rel: 'self',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/114'
            },
            {
                rel: 'permission',
                href: 'https://mst1s2.fortiumtech.local/EvsServices/api/permissions/114'
            }
        ]
    }
];

export const genericHeader: TableColumns[] = [
    { label: 'ID', dataKey: 'id', flexGrow: 0, order: 'asc' },
    { flexGrow: 2, label: 'Name', dataKey: 'name', width: 150, display: 'flex', order: 'asc' },
    {
        flexGrow: 1,
        flexShrink: 0,
        label: 'Created',
        dataKey: 'createDate',
        order: 'asc',
        hidden: ['xs', 'sm'] as Breakpoint[]
    },
    {
        flexGrow: 1,
        flexShrink: 0,
        label: 'Last Modified',
        dataKey: 'updateDate',
        order: 'asc',
        hidden: ['xs', 'sm'] as Breakpoint[]
    },
    {
        label: 'Status',
        dataKey: 'active',
        disableSort: true,
        flexGrow: 0,
        width: 150,
        flexShrink: 0,
        order: 'asc'
    },
    {
        flexGrow: 0,
        flexShrink: 0,
        label: 'Actions',
        dataKey: 'id',
        disableSort: true,
        hidden: ['xs'] as Breakpoint[],
        order: 'asc'
    }
];

export const genericUserHeader: TableColumns[] = [
    { label: 'ID', dataKey: 'id', flexGrow: 0, order: 'asc' },
    { flexGrow: 0.8, width: 75, label: 'First Name', dataKey: 'firstName', order: 'asc' },
    { flexGrow: 0.8, width: 75, label: 'Last Name', dataKey: 'lastName', order: 'asc' },
    {
        flexGrow: 2,
        flexShrink: 0,
        width: 150,
        label: 'Email Address',
        dataKey: 'email',
        order: 'asc',
        hidden: ['xs', 'sm'] as Breakpoint[]
    },
    {
        flexGrow: 1,
        flexShrink: 0,
        label: 'Created',
        dataKey: 'createDate',
        order: 'asc',
        hidden: ['xs', 'sm'] as Breakpoint[]
    },
    {
        flexGrow: 1,
        flexShrink: 0,
        label: 'Last Modified',
        dataKey: 'updateDate',
        order: 'asc',
        hidden: ['xs', 'sm', 'md'] as Breakpoint[]
    },
    { label: 'Status', flexGrow: 0, width: 150, dataKey: 'active', disableSort: true, order: 'desc' },
    {
        flexGrow: 0,
        flexShrink: 0,
        label: 'Actions',
        dataKey: 'id',
        justifyContent: 'flex-end',
        disableSort: true,
        hidden: ['xs'] as Breakpoint[],
        order: 'asc'
    }
];

export const genericUserNoActions = genericUserHeader.filter((item: TableColumns) => item.label !== 'Actions');

export const genericNoActions = genericHeader.filter((item: TableColumns) => item.label !== 'Actions');
export const actionableUserHeader: TableColumns[] = [
    { label: 'ID', dataKey: 'id', flexGrow: 0, order: 'asc' },
    { flexGrow: 0.8, width: 75, label: 'First Name', dataKey: 'firstName', order: 'asc' },
    { flexGrow: 0.8, width: 75, label: 'Last Name', dataKey: 'lastName', order: 'asc' },
    { label: 'Status', flexGrow: 0, width: 150, dataKey: 'active', disableSort: true, order: 'desc' }
];

export const actionableGeneralHeader = [
    { id: 'avatar', label: '', order: 'asc', hiddenDesktop: false, hiddenMobile: false },
    { id: 'id', disablePadding: false, label: 'ID', order: 'asc', hiddenDesktop: false },
    { id: 'name', disablePadding: false, label: 'Name', hiddenMobile: false, hiddenDesktop: false, order: 'asc' },
    { id: 'active', numeric: false, disablePadding: false, label: 'Status', hiddenMobile: true, hiddenDesktop: false }
];

export const actionableGroupHeader: TableColumns[] = [
    { label: 'ID', order: 'asc', dataKey: 'id', width: 50, flexGrow: 0 },
    {
        dataKey: 'name',
        label: 'Name',
        order: 'asc',
        width: 150,
        flexGrow: 2
    },
    {
        dataKey: 'active',
        width: 150,
        label: 'Status',
        order: 'asc'
    }
];

export const reportingDecryptorHeader = (fileSave: boolean): TableColumns[] => [
    { label: 'ID', dataKey: 'id', flexGrow: 0, order: 'asc' },
    {
        flexGrow: 2,
        width: 450,
        label: 'File Name',
        dataKey: fileSave ? 'outFilePath' : 'userFilePath',
        display: 'flex',
        order: 'desc',
        disableSort: false
    },
    {
        flexGrow: 1,
        flexShrink: 0,
        label: 'Accessed',
        dataKey: fileSave ? 'saveDate' : 'accessDate',
        order: 'desc',
        disableSort: false,
        hidden: ['xs', 'sm']
    },
    {
        flexGrow: 1,
        flexShrink: 0,
        label: 'User',
        dataKey: 'fullName',
        display: 'flex',
        order: 'desc',
        disableSort: true,
        hidden: ['xs']
    },
    { flexGrow: 0, width: 75, label: 'Status', dataKey: 'accessType', order: 'desc', disableSort: false }
];

export const reportingEncryptorHeader = (): TableColumns[] => [
    {
        label: 'User',
        dataKey: 'name',
        flexGrow: 3,
        order: 'asc',
        disableSort: true
    },
    {
        flexGrow: 1,
        label: 'Action',
        dataKey: 'action',
        display: 'flex',
        order: 'desc',
        disableSort: true
    },
    {
        flexGrow: 1,
        flexShrink: 0,
        label: 'Type',
        dataKey: 'entityType',
        order: 'desc',
        disableSort: true,
        hidden: ['xs', 'sm']
    },
    {
        flexGrow: 1,
        flexShrink: 0,
        label: 'Date',
        dataKey: 'actionDate',
        display: 'flex',
        order: 'desc',
        disableSort: true,
        hidden: ['xs']
    },
    {
        flexGrow: 1,
        label: 'IP',
        dataKey: 'machineInfo',
        order: 'desc',
        disableSort: true
    }
];

export const jobFileHeader: TableColumns[] = [
    {
        flexGrow: 2,
        label: 'File Name',
        dataKey: 'source',
        width: 200,
        enableTooltip: true,
        utilFn: 'shortFilename',
        order: 'desc'
    },
    {
        flexGrow: 0.5,
        flexShrink: 0,
        label: 'Size',
        dataKey: 'size',
        order: 'desc',
        width: 75,
        hidden: ['xs', 'sm'] as Breakpoint[]
    },
    {
        flexGrow: 0.5,
        flexShrink: 1,
        width: 75,
        label: 'Created',
        dataKey: 'createDate',
        order: 'desc',
        hidden: ['xs', 'sm', 'md'] as Breakpoint[]
    },
    {
        flexGrow: 0.5,
        flexShrink: 0,
        width: 75,
        label: 'Times Granted',
        dataKey: 'totalTimesGranted',
        order: 'desc',
        hidden: ['xs', 'sm', 'md'] as Breakpoint[]
    },
    {
        flexGrow: 0.5,
        width: 75,
        flexShrink: 1,
        label: 'Last Accessed By',
        dataKey: 'lastGrantedAccessUserFullName',
        order: 'desc',
        disableSort: true,
        hidden: ['xs', 'sm'] as Breakpoint[]
    }
];

export const inviteHeader: TableColumns[] = [
    { label: 'ID', dataKey: 'id', flexGrow: 0, order: 'asc' },
    { flexGrow: 0.8, width: 75, label: 'First Name', dataKey: 'firstName', order: 'asc' },
    { flexGrow: 0.8, width: 75, label: 'Last Name', dataKey: 'lastName', order: 'asc' },
    {
        flexGrow: 2,
        flexShrink: 0,
        width: 150,
        label: 'Email Address',
        dataKey: 'email',
        order: 'asc',
        hidden: ['xs', 'sm'] as Breakpoint[]
    },
    { label: 'Status', flexGrow: 0, width: 90, dataKey: 'active', disableSort: true, order: 'desc' },
    {
        flexGrow: 1,
        flexShrink: 0,
        label: 'Actions',
        dataKey: 'id',
        justifyContent: 'flex-end',
        disableSort: true,
        order: 'desc',
        hidden: ['xs'] as Breakpoint[]
    }
];

export const tokensHeader: TableColumns[] = [
    { label: 'Name', dataKey: 'name', flexGrow: 2, order: 'asc' },
    { flexGrow: 0.8, width: 75, label: 'Created', dataKey: 'createDate', order: 'asc' },
    { flexGrow: 0.8, width: 75, label: 'Created By', dataKey: 'createdBy', order: 'asc' },
    {
        flexGrow: 0.8,
        width: 75,
        label: 'Last Used',
        dataKey: 'lastUsed',
        order: 'asc',
        hidden: ['xs', 'sm'] as Breakpoint[]
    },
    { label: 'Expires', flexGrow: 0.8, width: 75, dataKey: 'expires', disableSort: true, order: 'desc' },
    {
        flexGrow: 0.8,
        width: 25,
        label: 'Permissions',
        dataKey: 'permissions',
        justifyContent: 'center',
        disableSort: true,
        order: 'desc',
        hidden: ['xs'] as Breakpoint[]
    },
    {
        flexGrow: 0.8,
        width: 75,
        label: 'Actions',
        dataKey: 'actions',
        justifyContent: 'center',
        disableSort: true,
        order: 'desc',
        hidden: ['xs'] as Breakpoint[]
    }
];

export const emailInviteHeader: TableColumns[] = [
    {
        label: 'Email',
        dataKey: 'emailAddress',
        flexGrow: 5,
        order: 'asc'
    },
    {
        label: 'Verified',
        dataKey: 'verified',
        flexGrow: 0.5,
        order: 'asc'
    },
    {
        flexGrow: 1,
        flexShrink: 0,
        label: 'Actions',
        dataKey: 'id',
        justifyContent: 'flex-end',
        disableSort: true,
        order: 'desc',
        hidden: ['xs'] as Breakpoint[]
    }
];
