import React, { useCallback, memo } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useCheckAllSelected } from '@mediaseal-webui/hooks';
import useStyles from './PermissionSelection.styles';
import { useAppDispatch, useAppSelector } from 'store';
import { removeGroupPermissions, setGroupPermissions } from '@mediaseal-webui/actions';
import { ISerializedPermissions, SerializedPermissions } from '@mediaseal-webui/utils';
import { Permission } from '@mediaseal-webui/types';
import { Tooltip } from '@material-ui/core';

interface PermissionSelectionProps {
    groups: SerializedPermissions[];
    stateKey: keyof ISerializedPermissions;
}

const PermissionSelection = memo(({ groups, stateKey }: PermissionSelectionProps) => {
    const allowedPermissions = useAppSelector((state) => state.permissions).items.map((allowedPermission) => allowedPermission.permissionName);
    const hasPermissionMatch = (group: SerializedPermissions) =>
        group.options.some((groupedPermission: Permission) =>
            allowedPermissions?.find((allowedPermissionName: string) => allowedPermissionName === groupedPermission.permissionName)
        );
    const filteredGroups = allowedPermissions ? groups.filter((group) => hasPermissionMatch(group)) : groups;
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const { checked, setChecked } = useCheckAllSelected(groups);
    const handleChange = (c, option) => (event) => {
        dispatch({
            type: 'UPDATE_PERMISSIONS',
            payload: {
                stateKey,
                item: {
                    ...c,
                    options: c.options.map((op) => (op.id === option.id ? { ...op, checked: event.target.checked } : op))
                }
            }
        });

        event.target.checked
            ? dispatch(setGroupPermissions({ ...option, checked: event.target.checked }))
            : dispatch(removeGroupPermissions({ ...option, checked: event.target.checked }));
    };
    const changeHandler = useCallback(handleChange, [dispatch, stateKey]);
    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);

        filteredGroups.forEach((group) => {
            dispatch({
                type: 'UPDATE_PERMISSIONS',
                payload: {
                    stateKey,
                    item: {
                        ...group,
                        options: group.options.map((op) => {
                            const foundPermission = allowedPermissions.some((ap) => ap === op.permissionName);
                            const checkPermission = foundPermission ? event.target.checked : false;

                            event.target.checked && allowedPermissions.includes(op.permissionName)
                                ? dispatch(setGroupPermissions({ ...op, checked: checkPermission }))
                                : dispatch(removeGroupPermissions({ ...op, checked: false }));

                            return { ...op, checked: checkPermission };
                        })
                    }
                }
            });
        });
    };
    const selectAllHandler = useCallback(handleSelectAll, [setChecked, filteredGroups, dispatch, stateKey, allowedPermissions]);
    const isDisabled = (option) => !!(allowedPermissions.indexOf(option.permissionName) === -1);

    return (
        <>
            {filteredGroups.length > 0 && (
                <FormControlLabel
                    className={classes.selectAllLabel}
                    control={<Checkbox onChange={selectAllHandler} checked={checked} value={!checked ? 'Select All' : 'Deselect All'} />}
                    label={!checked ? 'Select All' : 'Deselect All'}
                />
            )}
            <div className={classes.root}>
                {groups
                    .sort((a, b) => a.id - b.id)
                    .map((category) => (
                        <FormControl key={category.id} required error={false} component='fieldset' className={classes.formControl}>
                            <FormLabel component='label'>{category.label}</FormLabel>
                            <FormGroup data-testid='selection-group'>
                                <>
                                    {category.options.map((option) => (
                                        <Tooltip title={`${isDisabled(option) ? 'You do not have this permission' : ''}`}>
                                            <FormControlLabel
                                                disabled={isDisabled(option)}
                                                key={option.id}
                                                control={<Checkbox checked={!!option.checked} onChange={changeHandler(category, option)} />}
                                                label={option.permissionName}
                                            />
                                        </Tooltip>
                                    ))}
                                </>
                            </FormGroup>
                        </FormControl>
                    ))}
            </div>
        </>
    );
});

export default PermissionSelection;
