import { useState, useEffect } from 'react';
import { GenericEntity } from '@mediaseal-webui/types/schemas';

export const useReadOnlyCheck = <T extends GenericEntity>(entity: T, cb: (entity: T) => boolean) => {
    const [isReadOnly, setIsReadOnly] = useState<boolean>(false);

    useEffect(() => {
        entity && setIsReadOnly(cb(entity));
    }, [entity]);

    return {
        isReadOnly
    };
};
