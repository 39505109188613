import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { InitialState } from '@mediaseal-webui/types/redux';
import { EditableHeaderItem, EditableHeaderChildProps, BadgeIcon, HeaderItem, GenericHeaderItemProps } from '../..';
import { Group as GroupIcon } from '@material-ui/icons';
import { updateDecryptorUser } from '@mediaseal-webui/actions';

export const DecryptorGroupsHeaderItems = ({ isFetching, onClick = () => {}, tooltipOpen, stateKey = 'profile' }: GenericHeaderItemProps) => {
    const { decryptorGroups } = useSelector(({ entity }: InitialState) => entity[stateKey]);

    return decryptorGroups && decryptorGroups.length > 0 ? (
        <EditableHeaderItem title='group' total={decryptorGroups.length} onViewClick={onClick} open={tooltipOpen}>
            {({ hovering }: EditableHeaderChildProps) => (
                <HeaderItem
                    hovering={hovering}
                    Icon={() => <BadgeIcon isHovered={hovering} Icon={GroupIcon} content={decryptorGroups.length} />}
                    isFetching={isFetching}
                    id='job-groups'
                />
            )}
        </EditableHeaderItem>
    ) : null;
};
