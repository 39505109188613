import React, { useState } from 'react';
import { definitions } from '../../../../../../types/api';
import { GenericEntity } from '@mediaseal-webui/types';
import { InitialState } from '@mediaseal-webui/types/redux/state';
import { MainTable } from '@mediaseal-webui/components';
import { removeEntityAndUpdate, removeAllowedTokenFromSummary } from '../../../../actions/entity';
import { setConfirmDialogue } from '../../../../actions/misc';
import { updateTitle } from 'actions/titles';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveAction, { RemoveActionProps } from 'components/table-components/RemoveAction';
import { tokensHeader } from '../../../../data';

export const TokensTable = () => {
    const [tokensPages, setTokensPages] = useState(1);
    const dispatch = useDispatch();
    const { allowedTokens } = useSelector((state: InitialState) => state.entity.profile);
    const { id } = useParams<{ id: string }>();
    const tableHeader = tokensHeader.map((col) => ({ ...col, disableSort: true }));

    const RevokeContainer = ({ ...others }: RemoveActionProps) => {
        return (
            <div tabIndex={-1} style={{ display: 'flex' }}>
                <RemoveAction {...others} />
            </div>
        );
    };

    const handleTokenDelete = (token: GenericEntity) => () =>
        dispatch(
            setConfirmDialogue({
                icon: 'delete',
                title: 'Revoke Token',
                confirmButtonText: 'Revoke Token',
                message: 'Are you sure you want to revoke this token?',
                action: removeEntityAndUpdate({
                    entity: token,
                    id,
                    removeFn: removeAllowedTokenFromSummary,
                    updateFn: updateTitle
                })
            })
        );

    const formattedTokens = (allowedTokens ?? []).map((token) => {
        const typedToken = token as definitions['TokenGetDTO'];
        return {
            ...token,
            createDate: typedToken.createDate,
            createdBy: typedToken.createdBy?.fullName,
            lastUsed: typedToken.lastUsed,
            expirationDate: typedToken.expirationDate
        };
    });

    return (
        <>
            <MainTable
                entities={formattedTokens}
                pageControl={{ page: tokensPages, setPage: setTokensPages }}
                fetchFn={() => Promise.resolve()}
                totalPages={1}
                tableActionsRenderer={(entity) => <RevokeContainer tooltipTitle={'Revoke'} removeAction={handleTokenDelete(entity)} RemoveIcon={DeleteIcon} />}
                columns={tableHeader}
                noResultsMessage='No tokens are associated with this title'
                onRowClick={() => () => null}
                id='tokens-table'
            />
        </>
    );
};
