import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { TableColumns, SortOptions, FetchArgs, SortOrders } from '@mediaseal-webui/types/generic';
import { ReportingFilterData } from '@mediaseal-webui/types/reporting';
import { Dispatchable } from '@mediaseal-webui/types/redux';
import { AsyncThunk } from '@reduxjs/toolkit';

export interface UseTableSortProps {
    cells: TableColumns[];
    setPage: React.Dispatch<React.SetStateAction<number>>;
    fetchFn: any;
    searchValue?: string;
    sortOverride?: SortOptions;
    reporting?: boolean;
    fetchData?: ReportingFilterData;
}
export const useTableSort = ({ cells, setPage, fetchFn, searchValue, sortOverride, reporting, fetchData }: UseTableSortProps) => {
    const [headCells, setHeadCells] = useState<TableColumns[]>(cells);
    const [orderBy, setOrderBy] = useState<SortOptions>(sortOverride || 'createDate');
    const [order, setOrder] = useState<SortOrders>('desc');
    const [initial, setInitial] = useState<boolean>(true);
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const clickHandler = (property: TableColumns) => (event: React.SyntheticEvent) => {
        const isDesc = property.order === 'desc';
        setInitial(false);
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property?.dataKey as SortOptions);
        setHeadCells([
            ...headCells.map(
                (cell: TableColumns): TableColumns =>
                    cell.dataKey === property.dataKey
                        ? {
                              ...cell,
                              order: isDesc ? ('asc' as SortOrders) : ('desc' as SortOrders)
                          }
                        : cell
            )
        ]);
        setPage(1);
    };

    useEffect(() => {
        if (reporting) {
            if (!initial)
                dispatch(
                    fetchFn({
                        ...fetchData,
                        firstCall: true,
                        sort: `${orderBy},${order}`,
                        page: 0
                    })
                );
        } else {
            dispatch(
                fetchFn({
                    id,
                    page: 0,
                    sort: `${orderBy},${order}`,
                    firstCall: true,
                    keyword: searchValue || ''
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, orderBy, initial]);

    const createSortHandler = useCallback(clickHandler, []);

    return {
        order,
        orderBy,
        headCells,
        createSortHandler
    };
};
