import React, { useState, useContext } from 'react';
import DatePicker from 'react-datepicker';
import { TextField, FormControl, useMediaQuery } from '@material-ui/core';
import useStyles from './DatePicker.styles';
import 'react-datepicker/dist/react-datepicker.css';
import { CalenderContext } from '@mediaseal-webui/components';
import { DateRangePickerProps } from '.';
import { useTheme } from '@material-ui/core/styles';

const DateRangePicker = ({
    toDateSetter,
    defaultStartDate = new Date(),
    defaultEndDate,
    showTime = false,
    fullWidth = false,
    disabled,
    portal = false,
    disableMinDate = false,
    ...others
}: DateRangePickerProps) => {
    const [endDate, setEndDate] = useState<Date>(defaultEndDate);
    const { setCalenderShown } = useContext(CalenderContext);
    const theme = useTheme();
    const betweenMdLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));

    const handleEndDate = (date: Date) => {
        if (disabled) return;
        if (date) {
            setEndDate(date);
            toDateSetter(date);
        }
    };

    const classes = useStyles();
    const handleCalenderOpen = () => setCalenderShown(true);
    const handleCalenderClose = () => setCalenderShown(false);

    return (
        <FormControl className={fullWidth ? `${classes.fullWidth} ${classes.timeContainer}` : classes.timeContainer}>
            <div className={classes.calenderContainer}>
                <DatePicker
                    selected={endDate}
                    onChange={handleEndDate}
                    endDate={endDate}
                    minDate={new Date()}
                    dateFormat={'yyyy-MM-dd'}
                    onCalendarClose={handleCalenderClose}
                    onCalendarOpen={handleCalenderOpen}
                    popperClassName={`${classes.popper} popper`}
                    popperModifiers={{
                        offset: {
                            enabled: true,
                            offset: betweenMdLg ? '-35px, 0px' : ''
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: 'viewport'
                        },
                        hide: {
                            enabled: false // turn off since needs preventOverflow to be enabled
                        }
                    }}
                    customInput={
                        <TextField
                            name='to-date'
                            disabled={disabled}
                            inputProps={{ 'data-testid': 'to-date', id: 'to-date' }}
                            className={classes.fromInput}
                            variant='outlined'
                            label='Expire (UTC)'
                        />
                    }
                    {...others}
                />
            </div>
        </FormControl>
    );
};

export default DateRangePicker;
