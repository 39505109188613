import { useState, useEffect } from 'react';
import { InitialState } from '@mediaseal-webui/types/redux';
import { useSelector } from 'react-redux';
import { EncryptionWork } from '@mediaseal-webui/types/encryptor';

export const useActiveEncryptionWork = () => {
    const { encryptionWork } = useSelector((state: InitialState) => state.encryptor);
    const { activeJobId } = useSelector((state: InitialState) => state.jobs);
    const [work, setWork] = useState<EncryptionWork>({} as EncryptionWork);

    useEffect(() => {
        setWork(encryptionWork.find((work) => work.jobData.id === Number(activeJobId)) || ({} as EncryptionWork));
    }, [encryptionWork, activeJobId]);
    return { activeJobWork: work, allActiveWork: encryptionWork };
};
