import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    titlePadding: {
        marginBottom: theme.spacing(2)
    },
    checkboxPadding: {
        marginLeft: '-12px'
    },
    formGroup: {
        marginBottom: theme.spacing(1.5)
    },
    inputContainer: {
        marginTop: theme.spacing(2)
    }
}));

export default useStyles;
