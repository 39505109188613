import React, { useState } from 'react';
import { Dialog, Button, Fade, DialogActions, useMediaQuery } from '@material-ui/core';
import DateRangePicker from '../../utility-components/DateRangePicker/DateRangePicker';
import TodayIcon from '@material-ui/icons/Today';
import { subDays } from 'date-fns';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from './DateFilter.styles';

export interface DateFilterProps {
    to: Date;
    from: Date;
    toDateSetter: (time: Date) => void;
    fromDateSetter: (time: Date) => void;
    matches?: boolean;
    responsive: boolean;
}
const DateFilter = (props: DateFilterProps) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    if (props.responsive) {
        return (
            <div>
                <Fade in timeout={1000}>
                    <Button size='large' onClick={handleOpen} startIcon={<TodayIcon />} disableElevation>
                        dates
                    </Button>
                </Fade>
                <Dialog
                    PaperProps={{
                        className: classes.dialogue
                    }}
                    onClose={handleClose}
                    aria-labelledby='date-picker'
                    maxWidth='xl'
                    open={open}>
                    <DateRangePicker
                        readOnly={true}
                        data-testid='date-range-pickers'
                        responsive={props.matches}
                        toDateSetter={props.toDateSetter}
                        // to={props.to}
                        // from={props.from}
                        showTime
                        disableMinDate
                        fromDateSetter={props.fromDateSetter}
                        defaultStartDate={subDays(new Date(), 365)}
                    />
                    <DialogActions>
                        <Button fullWidth={matches} onClick={handleClose}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    } else {
        return (
            <DateRangePicker
                data-testid='date-range-pickers'
                responsive={props.matches}
                toDateSetter={props.toDateSetter}
                to={props.to}
                from={props.from}
                showTime
                disableMinDate
                fromDateSetter={props.fromDateSetter}
                defaultStartDate={subDays(new Date(), 365)}
            />
        );
    }
};

export default DateFilter;
