import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    inactive: {
        display: 'block',
        color: 'white',
        textDecoration: 'none',
        borderLeft: 'medium solid transparent',
        '&:hover': {
            borderLeft: 'medium solid rgba(255,255,255,0.5)'
        }
    },
    linkContainer: {
        padding: 0,
        fontSize: '0.9em',
        paddingLeft: theme.spacing(2),
        width: theme.sizes.sideNav.open,
        overflow: 'hidden',
        '&:hover': {
            background: 'rgba(29,28,29,0.5)'
        }
    },
    helpContainer: {
        background: theme.palette.background.default,
        width: '100%',
        '&:hover': {
            background: theme.palette.background.paper
        }
    },
    popperText: {
        color: `${theme.palette.text.primary} !important`,
        width: '100%'
    },
    icon: {
        fontSize: '1.2em',
        marginTop: 17,
        color: '#fff',
        marginBottom: 17,
        display: 'block',
        textAlign: 'center',
        width: theme.spacing(4),
        marginRight: theme.spacing(1),
        flexShrink: 0,
        overflow: 'visible !important'
    },
    textLink: {
        color: theme.palette.common.white,
        transition: 'all 0.4s',
        transitionDelay: '0.4s'
    },
    textWhenClosed: {
        paddingLeft: theme.spacing(5)
    },
    disableContainer: {
        paddingLeft: theme.spacing(0.5),
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.5)'
        }
    }
}));

export default useStyles;
