import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Tooltip, Typography, IconButton } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { useAuth, useHeaderItemsMoved } from '@mediaseal-webui/hooks';
import { EditableHeaderItemProps } from '.';
import { useStyles } from './EditableHeaderItem.styles';

interface TitleTooltip {
    handleEditClick: (event: React.SyntheticEvent) => void;
    title: string;
    disabled?: boolean;
}
const TitleTooltip = ({ handleEditClick, title, disabled = false }: TitleTooltip) => {
    const classes = useStyles();

    return (
        <div className={classes.titleTooltip}>
            <Typography color='textPrimary' variant='subtitle2'>
                {title}
            </Typography>
            {!disabled && (
                <IconButton disabled={disabled} onClick={handleEditClick}>
                    <EditIcon className={classes.editIcon} />
                </IconButton>
            )}
        </div>
    );
};

export const EditableHeaderItem = React.memo(
    ({ children, ModalComponent, className, onViewClick, title, disabled = false, total = 0, open }: EditableHeaderItemProps) => {
        const [showModal, setShowModal] = useState<boolean>(false);
        const [showTooltip, setShowTooltip] = useState<boolean>(false);
        const classes = useStyles();
        const itemRef = useRef<HTMLDivElement | null>(null);
        const { edit, administer } = useAuth();

        const handleEditClick = (event: React.SyntheticEvent) => {
            event.stopPropagation();
            edit || administer ? setShowModal(true) : null;
        };

        const handleClick = () => {
            onViewClick && onViewClick();
        };
        const getTitleText = () => (total > 0 ? `${total} ${title}${total > 1 ? 's' : ''}` : title);
        const handleMouseEvent = (value: boolean) => () => {
            !open && setShowTooltip(value);
        };
        const listener = useCallback(() => {
            if (itemRef.current) {
                const item = itemRef.current.getBoundingClientRect();
                if (item.top < 106 || item.bottom > window.innerHeight - 200) {
                    setShowTooltip(false);
                } else {
                    setShowTooltip(!!open);
                }
            }
        }, [itemRef, open]);
        useHeaderItemsMoved(listener);

        useEffect(() => {
            listener();
        }, [open]);

        return (
            <>
                <Tooltip
                    interactive
                    classes={{
                        tooltip: classes.tooltip,
                        popper: classes.tooltipRoot,
                        arrow: classes.arrow
                    }}
                    open={showTooltip}
                    PopperProps={{
                        popperOptions: {
                            modifiers: {
                                offset: '10%'
                            }
                        }
                    }}
                    title={ModalComponent ? <TitleTooltip disabled={disabled} handleEditClick={handleEditClick} title={title} /> : getTitleText()}
                    placement={open ? 'left' : 'top'}>
                    <div
                        onMouseLeave={handleMouseEvent(false)}
                        onMouseEnter={handleMouseEvent(true)}
                        onClick={handleClick}
                        ref={itemRef}
                        className={clsx(classes.editableItemRoot, className)}>
                        <div className={classes.childWrapper}>{children({ hovering: false })}</div>
                    </div>
                </Tooltip>
                {ModalComponent && <ModalComponent openProps={[showModal, setShowModal]} />}
            </>
        );
    }
);
