import React, { useState } from 'react';
import { Dialog, Button, Fade, DialogActions, useMediaQuery } from '@material-ui/core';
import DateRangePicker from './DatePicker';
import TodayIcon from '@material-ui/icons/Today';
import { addDays } from 'date-fns';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from './DateFilter.styles';

export interface DateFilterProps {
    toDateSetter: (time: Date) => void;
    fromDateSetter: (time: Date) => void;
    matches?: boolean;
    responsive: boolean;
}
const DateFilter = ({ responsive, toDateSetter, fromDateSetter }: DateFilterProps) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const toDate = addDays(new Date(), 30);
    const fromDate = new Date();

    if (responsive) {
        return (
            <div>
                <Fade in timeout={1000}>
                    <Button size='large' onClick={handleOpen} startIcon={<TodayIcon />} disableElevation>
                        dates
                    </Button>
                </Fade>
                <Dialog
                    PaperProps={{
                        className: classes.dialogue
                    }}
                    onClose={handleClose}
                    aria-labelledby='date-picker'
                    maxWidth='xl'
                    open={open}>
                    <DateRangePicker
                        data-testid='date-range-picker'
                        responsive={matches}
                        toDateSetter={toDateSetter}
                        inline
                        disableMinDate
                        fromDateSetter={fromDateSetter}
                        defaultStartDate={fromDate}
                        defaultEndDate={toDate}
                    />
                    <DialogActions>
                        <Button fullWidth={matches} onClick={handleClose}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
    return (
        <DateRangePicker
            data-testid='date-range-picker'
            responsive={matches}
            toDateSetter={toDateSetter}
            disableMinDate
            fromDateSetter={fromDateSetter}
            defaultStartDate={fromDate}
            defaultEndDate={toDate}
        />
    );
};

export default DateFilter;
