import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ListItem as LItem, Grid, Typography, IconButton, Tooltip, Fade } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';
import { Clear, Pause, PlayArrow, Delete } from '@material-ui/icons';
import useStyles, { BorderLinearProgress } from './ListItem.styles';
import { LoaderAvatar } from './';
//@ts-ignore
import { useDispatch } from 'react-redux';
import { removeEncryptionFile } from '@mediaseal-webui/actions';
import { EncryptFile } from '@mediaseal-webui/types/encryptor';

type StatusText = 'Waiting' | 'Encrypting' | 'Paused' | 'Cancelled' | 'Completed';

interface ListItemData {
    file: EncryptFile;
    style: { color: string; fontSize: string };
    secondaryText: StatusText | string;
}
type ListItemProps = {
    data: ListItemData;
};

export const ListItem = memo((props: ListItemProps) => {
    const { data } = props;
    const { style, file, secondaryText } = data;
    const classes = useStyles();
    const dispatch = useDispatch();
    const handleRemoveClick = (file: EncryptFile) => {
        console.log('Removing file');
        dispatch(removeEncryptionFile(file));
    };
    const handleCancelClick = (file: EncryptFile) => {
        if (window.qt) {
            window.EncryptionWebInterface.cancelEncryptionWork(file.id);
            // setEncryptionCancelled(file.id);
        }
    };
    const handlePauseClick = (file: EncryptFile) => {
        if (window.qt) {
            window.EncryptionWebInterface.pauseEncryptionWork(file.id);
        }
    };
    const handleResumeClick = (file: EncryptFile) => {
        if (window.qt) {
            window.EncryptionWebInterface.resumeEncryptionWork(file.id);
        }
    };
    return (
        <Fade in timeout={500}>
            <LItem className={clsx(classes.fileListItem, 'fileItem')}>
                <Grid alignItems='center' container>
                    <Grid className={classes.metaInfo} item xs={8}>
                        <LoaderAvatar file={file} />
                        <ListItemText
                            primaryTypographyProps={{ style: { fontSize: '1.19em' } }}
                            className={classes.fileName}
                            secondaryTypographyProps={{ style }}
                            primary={file.inputFileName}
                            secondary={secondaryText}
                        />
                    </Grid>
                    <Grid className={classes.actionsContainer} item xs={4}>
                        <Typography color='textPrimary' className={classes.filePercentage} variant='body2'>
                            {file.percentage}%
                        </Typography>
                        {file.cancelled || file.error || file.done ? (
                            <IconButton
                                className='delete-btn'
                                onClick={() => {
                                    handleRemoveClick(file);
                                }}>
                                <Delete fontSize='small' data-testid='delete-btn' />
                            </IconButton>
                        ) : file.paused ? (
                            <>
                                <Tooltip title='Resume Job' placement='top'>
                                    <IconButton
                                        id='resume-btn'
                                        onClick={() => {
                                            handleResumeClick(file);
                                        }}>
                                        <PlayArrow fontSize='small' data-testid='resume-btn' />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Cancel Job' placement='top'>
                                    <IconButton
                                        id='cancel-btn'
                                        onClick={() => {
                                            handleCancelClick(file);
                                        }}>
                                        <Clear fontSize='small' data-testid='clear-btn' />
                                    </IconButton>
                                </Tooltip>
                            </>
                        ) : (
                            <>
                                <Tooltip title='Pause Job' placement='top'>
                                    <IconButton
                                        className='pause-btn'
                                        onClick={() => {
                                            handlePauseClick(file);
                                        }}>
                                        <Pause fontSize='small' data-testid='pause-btn' />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Cancel Job' placement='top'>
                                    <IconButton
                                        id='cancel-btn'
                                        onClick={() => {
                                            handleCancelClick(file);
                                        }}>
                                        <Clear fontSize='small' data-testid='clear-btn' />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                        {/* <Actions file={file}  /> */}
                    </Grid>
                    <Grid className={classes.progressContainer} item xs={6}>
                        {file.percentage !== 100 ? <BorderLinearProgress variant='determinate' color='secondary' value={file.percentage} /> : null}
                    </Grid>
                </Grid>
            </LItem>
        </Fade>
    );
});

export default React.memo(ListItem);
