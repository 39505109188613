import { Avatar } from '@material-ui/core';
import styled, { css, DefaultTheme } from 'styled-components';

interface AccountWidget {
    external: boolean;
    theme: DefaultTheme;
}

export const StyledAccountWidget = styled.div`
    ${({ theme, external }: AccountWidget) => css`
        display: flex;
        align-items: center;
        padding: ${theme.spacing(2)}px;
        /* background: ${theme.palette.background.default}; */
        border-radius: 5px;
        transition: transform 0.35s ease;
        :hover {
            cursor: ${external ? 'default' : 'pointer'};
            transform: ${external ? '' : 'scale(1.025)'};
        }
    `}
`;

export const StyledName = styled.h5`
    ${({ theme }) => css`
        color: ${theme.palette.text.primary};
        margin: ${theme.spacing(1)}px;
    `}
`;

export const StyledAvatar = styled(Avatar)`
    ${({ theme }) => css`
        background: linear-gradient(90deg, rgba(2, 75, 120, 1), rgba(11, 176, 190, 1)) !important;
        color: ${theme.palette.common.white} !important;
    `}
`;
