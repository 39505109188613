import React from 'react';
import { Grid, Hidden, Divider, Typography } from '@material-ui/core';
import useStyles from './FormGroup.styles';
import { FormGroupProps } from '.';

const FormGroup = (props: FormGroupProps) => {
    const classes = useStyles();
    const { children, heading, subtitle, className } = props;

    return (
        <Grid className={`${classes.formGroup} ${className}`} alignItems='center' container>
            <Grid item xs={12} md={12} lg={12}>
                <Typography color='textPrimary' variant='body1' className={`${classes.titlePadding}`}>
                    {heading}
                </Typography>
                {subtitle && (
                    <Typography color='textPrimary' variant='body1'>
                        {subtitle}
                    </Typography>
                )}
            </Grid>
            <Hidden smDown>
                <Divider />
            </Hidden>
            <Grid className={classes.inputContainer} item xs={12} md={12} lg={12}>
                {children}
            </Grid>
        </Grid>
    );
};

export default FormGroup;
