import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, FormLabel, Snackbar, TextField, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './Login.styles';
import { UpdateModal, LargeLogo, SmallLogo } from '@mediaseal-webui/components';
import { GetApp } from '@material-ui/icons';
import { InitialState } from '@mediaseal-webui/types/redux';
import { useHistory } from 'react-router-dom';
import { useThemeSelection } from '@mediaseal-webui/hooks';
import { loginUser } from '../../actions/login';
import { getServerSettings } from '@mediaseal-webui/actions';
import { StyledErrorIcon } from '@mediaseal-webui/components/src/Alerts/NetworkAlert/NetworkAlert.style';
import LargeLogoAlt from '@mediaseal-webui/components/src/LargeLogo/LargeLogoAlt';

/**
 * account for different dPi screens, using 2 as the reference point (the dpr of my screen)
 */
const WRONG_STUDIO_URL = 'https://gs.cloud.mediaseal.com/studio/connect';
// const DOWNLOAD_LINK = 'https://gs.cloud.mediaseal.com/studio/connect'

const wHeight = () => (window.innerHeight / 2) * (window.devicePixelRatio ?? 1);
const wWidth = () => (window.innerWidth / 2) * (window.devicePixelRatio ?? 1);

const showSidebar = () => wHeight() >= 800 || wWidth() >= 1200;
const Login = () => {
    const timeout = useRef<any>(null);
    const previousWindowSize = useRef([window.innerWidth, window.innerHeight]);
    const [updateOpen, setUpdateOpen] = useState<boolean>(false);
    const { title, subtitle, actionable } = useSelector((state: InitialState) => state.versioning.updateDetails);
    const { userCredentials } = useSelector((state: InitialState) => state.misc);
    const classes = useStyles();
    const { theme } = useThemeSelection();
    const dispatch = useDispatch();
    const history = useHistory();
    const { error, info } = userCredentials;
    const preFillUsername = useSelector((state: InitialState) => state.login.loginName);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(true);
    const [loginError, setLoginError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [formInputs, setFormInputs] = useState({
        username: preFillUsername,
        password: ''
    });

    const [formSidebarView, setFormSidebarView] = useState<boolean>(false);
    const handleChange = (value: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormInputs({ ...formInputs, [value]: event.currentTarget.value });
    };

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 120000);
        dispatch(await loginUser(formInputs));
        dispatch(await getServerSettings());
    };

    useEffect(() => setFormInputs((f) => ({ ...f, username: preFillUsername })), [preFillUsername]);

    useEffect(() => {
        if (title !== '' || subtitle !== '') {
            setUpdateOpen(true);
        }
        return () => {
            setUpdateOpen(false);
        };
    }, [title, subtitle]);

    useEffect(() => {
        if (error) {
            setLoginError(true);
            setSnackbarOpen(true);
            setLoading(false);
        }
    }, [error]);

    const handleWindowResize = useCallback(() => {
        clearTimeout(timeout.current);
        const delay = previousWindowSize.current[0] > window.innerWidth ? 1000 : 500;
        timeout.current = setTimeout(() => setFormSidebarView(showSidebar()), delay);
        previousWindowSize.current = [window.innerWidth, window.innerHeight];
    }, [setFormSidebarView]);

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, [handleWindowResize]);

    const handleDownload = () => window.qt && window.GeneralWebInterface.showDownloadCenter();
    const DownloadBtn = () => (
        <Button fullWidth onClick={handleDownload} color='primary' variant='contained' endIcon={<GetApp />}>
            Download
        </Button>
    );
    const currentTheme = useSelector((state: InitialState) => state.settings.misc.theme);
    return (
        <div id='loginContainer' className={classes.pageContainer}>
            <div className={classes.logoContainer}>{currentTheme === 'light' ? <LargeLogo /> : <LargeLogoAlt />}</div>

            <div className={classes.smallLogoWrap}>
                <SmallLogo black />
            </div>

            <div
                id='loginFormContainer'
                className={classes.loginFormContainer}
                style={{
                    right: formSidebarView ? 0 : ''
                }}>
                <form id='loginForm' className={classes.loginForm} onSubmit={handleSubmit}>
                    {error && (
                        <Snackbar
                            open={snackbarOpen}
                            onClose={() => {
                                setSnackbarOpen(false);
                            }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            ContentProps={{
                                'aria-describedby': 'error-snackbar',
                                className: classes.snackbar
                            }}
                            className='errorMessage'
                            message={
                                <span className={classes.errorMessage} id='snackbar-error-message'>
                                    <StyledErrorIcon className={classes.errorIcon} fontSize='large' />
                                    <Typography color='textPrimary' variant='body2'>
                                        {info.message}
                                    </Typography>
                                </span>
                            }
                            action={
                                <Button
                                    color='inherit'
                                    size='small'
                                    onClick={() => {
                                        setSnackbarOpen(false);
                                    }}>
                                    <Typography color='textPrimary' variant='body2'>
                                        Close
                                    </Typography>
                                </Button>
                            }
                        />
                    )}

                    <Typography color='textPrimary' className={classes.formElements} variant='h6' id='studio-title'>
                        Login
                    </Typography>
                    <FormLabel color='primary' htmlFor='username'>
                        Username
                    </FormLabel>
                    <TextField
                        className={classes.formElements}
                        variant='outlined'
                        type='text'
                        id='username'
                        name='username'
                        value={formInputs.username}
                        onChange={handleChange('username')}
                    />
                    <FormLabel color='primary' htmlFor='password'>
                        Password
                    </FormLabel>
                    <TextField
                        className={classes.formElements}
                        variant='outlined'
                        name='password'
                        id='password'
                        type='password'
                        error={loginError}
                        value={formInputs.password}
                        onChange={handleChange('password')}
                    />
                    <Button
                        type='submit'
                        fullWidth
                        className={classes.button}
                        variant='contained'
                        size='large'
                        color='primary'
                        id='login-btn'
                        disabled={loading}>
                        LOGIN
                    </Button>
                    <Typography color='textPrimary' variant='body1'>
                        Not the correct studio?
                        <a href={WRONG_STUDIO_URL}> Click here to choose a new one!</a>
                    </Typography>
                </form>
            </div>
            <UpdateModal title={title} subtitle={subtitle} openProps={[updateOpen, setUpdateOpen]} Action={actionable ? DownloadBtn : null} />
        </div>
    );
};

export default Login;
