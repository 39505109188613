import React, { useState, memo } from 'react';
import { Paper, LinearProgress, IconButton, Slide, Tooltip } from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import { WidgetItem } from './WidgetItem';
import { useActiveEncryptionWork, useGetTotalPercentage } from '@mediaseal-webui/hooks';
import useStyles from './Widget.styles';
export const Widget = memo(() => {
    const location = useLocation();
    const [open, setOpen] = useState(false);
    // const { activeFileList: fileList, tempFileList, staticFileList, completedFileList, } = useSelector((state: InitialState) => state.encryptor)
    const { allActiveWork } = useActiveEncryptionWork();
    const { allTotalPercentage } = useGetTotalPercentage();
    const classes = useStyles();
    const totalActiveFiles = allActiveWork.flatMap((work) => work.fileList);
    const totalFilesWithoutCancel = totalActiveFiles.filter((file) => !file.cancelled);
    const totalCompletedFiles = totalActiveFiles.filter((file) => file.done && !file.cancelled);
    const startedFiles = totalActiveFiles.filter((file) => file.processed && !file.done);
    // useEffect(() => setPercentage(getTotalPercentage(fileList)), [fileList]);
    const handleClick = () => {
        setOpen(!open);
    };
    return (
        // fileList.length > 0 ?
        <Slide direction='up' in={startedFiles.length > 0 && !location.pathname.match(/profile.*jobs|jobs.*profile/g)} timeout={1500}>
            <Paper className={classes.paper}>
                {open ? totalActiveFiles.filter((file) => !file.done).map((file) => <WidgetItem key={file.id} file={file} />) : null}
                <div className='encryptionInfo'>
                    <p className='summary'>{`Encrypted ${totalCompletedFiles?.length} / ${totalFilesWithoutCancel.length} files`}</p>
                    <div className='progressContainer'>
                        <LinearProgress variant='determinate' data-testid='progress' color='secondary' value={allTotalPercentage || 0} className='progress' />
                        <p className='percentage'>{allTotalPercentage}%</p>
                    </div>
                </div>
                <div className='iconButton'>
                    <IconButton onClick={handleClick}>
                        {!open ? (
                            <Tooltip placement='top' title='Show Jobs'>
                                <KeyboardArrowUp />
                            </Tooltip>
                        ) : (
                            <Tooltip placement='top' title='Hide Jobs'>
                                <KeyboardArrowDown />
                            </Tooltip>
                        )}
                    </IconButton>
                </div>
            </Paper>
        </Slide>
    );
});
