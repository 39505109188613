import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MainTable } from '@mediaseal-webui/components';
import { genericUserHeader, genericUserNoActions } from '../../../../data';
import { useParams } from 'react-router';
import { updateTitle } from '../../../../actions/titles';
import { removeEncryptorUserFromSummary, removeEntityAndUpdate } from '../../../../actions/entity';
import { hideRemoveUserConfirmation } from '../../../../constants/preferences';
import { setConfirmDialogue } from '../../../../actions/misc';
import ActionContainer from '../../../table-components/ActionContainer/ActionContainer';
import { useAuth } from '@mediaseal-webui/hooks';
import { InitialState } from '@mediaseal-webui/types/redux';
import { GenericEntity } from '@mediaseal-webui/types/schemas';
import { useAppSelector } from 'store';

export const UsersTable = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const { items: permissions } = useAppSelector((state) => state.permissions);
    const hasEditTitlePermission = permissions.some((permission) => permission.permissionName === 'canEditTitles');

    const noSortHeader = hasEditTitlePermission
        ? genericUserHeader.map((col) => ({ ...col, disableSort: true }))
        : genericUserNoActions.map((col) => ({ ...col, disableSort: true }));

    const auth = useAuth();
    const isCreateAuth = auth.edit || auth.administer;

    const [encryptorUserPage, setEncryptorUserPage] = useState(1);

    const { users } = useSelector((state: InitialState) => state.entity.profile);

    const userRemoveHandler = (user: GenericEntity) => () =>
        dispatch(
            setConfirmDialogue({
                icon: 'warning',
                title: 'Remove User',
                confirmButtonText: 'Remove User',
                message: 'Are you sure you want to remove this user?',
                action: removeEntityAndUpdate({
                    entity: user,
                    id,
                    removeFn: removeEncryptorUserFromSummary,
                    updateFn: updateTitle
                }),
                memorable: hideRemoveUserConfirmation
            })
        );
    const handleUserRemove = useCallback(userRemoveHandler, [dispatch, id]);

    return (
        <MainTable
            entities={users || []}
            searchValue=''
            pageControl={{ page: encryptorUserPage, setPage: setEncryptorUserPage }}
            tableActionsRenderer={(entity: GenericEntity) => <ActionContainer create={isCreateAuth} removeAction={handleUserRemove(entity)} />}
            fetchFn={() => Promise.resolve()}
            totalPages={1}
            columns={noSortHeader}
            noResultsMessage='No users are associated with this title'
            onRowClick={() => () => null}
            id='users-table'
        />
    );
};
