import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    viewRoot: {
        // width: '100%',
        [theme.breakpoints.down('xs')]: {
            overflow: 'hidden'
        }
    },
    tabPanel: {},
    tabSection: {
        background: theme.palette.background.default,
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: '4px 4px 0 0',
        width: '100%',
        position: 'fixed',
        zIndex: 1,
        marginLeft: '-18px',
        marginTop: '-25px'
    },
    actionContainer: {
        display: 'flex',
        alignItems: 'center'
    },

    wrapper: {
        display: '',
        height: '100%',
        width: '100%',
        position: 'sticky',
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(5)
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(9)
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(0)
        }
    },

    titleContent: {
        top: theme.spacing(28),
        [theme.breakpoints.down('xs')]: {
            width: '100vw'
        }
    },

    widgetPadding: {
        paddingLeft: theme.spacing(2),
        overflow: 'scroll'
    }
}));

export default useStyles;
