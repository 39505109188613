import { genBasicAuth, genBearerAuth } from '@mediaseal-webui/utils';
import { isKeycloakActive } from './isKeycloakActive';

import { keycloak } from '@mediaseal-webui/services';
import { useWindowStore } from './useWindowStore';

export const useGenAuthTokenFn = () => {
    const windowStore = useWindowStore();
    const token = isKeycloakActive()
        ? genBearerAuth(keycloak.token)
        : genBasicAuth({ username: windowStore.misc.userCredentials.username, password: windowStore.misc.userCredentials.password });
    return token;
};
