import { createSlice } from '@reduxjs/toolkit';
import { MediaSealState } from '@mediaseal-webui/types';
import { definitions } from '@mediaseal-webui/types/api';
import { fetchJobsByTitle, fetchTitle, searchJobsByTitle } from './thunks';

export interface TitleState extends MediaSealState<definitions['Title']> {
    jobs: MediaSealState<definitions['Job'][]>;
    pending: boolean;
    fulfilled: boolean;
    content: {
        name: string;
        createDate: string;
        users: MediaSealState<definitions['User'][]>;
        groups: MediaSealState<definitions['Group'][]>;
    };
}
const initialTitleState: TitleState = {
    jobs: {
        content: [],
        page: {
            totalElements: 0,
            totalPages: 0
        },
        fulfilled: false,
        pending: false
    },
    fulfilled: false,
    pending: false,
    content: {
        name: '',
        createDate: '',
        users: {
            content: [],
            pending: false,
            fulfilled: false
        },
        groups: {
            content: [],
            pending: false,
            fulfilled: false
        }
    },
    page: {
        totalPages: 0,
        totalElements: 0
    }
};

export const titleSlice = createSlice({
    name: 'title',
    initialState: initialTitleState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchJobsByTitle.fulfilled, (state, action) => ({
            ...state,
            jobs: {
                ...state.jobs.content,
                ...action.payload,
                pending: false,
                fulfilled: true
            }
        }));
        builder.addCase(searchJobsByTitle.fulfilled, (state, action) => ({
            ...state,
            jobs: {
                ...action.payload,
                pending: false,
                fulfilled: true
            }
        }));
        builder.addCase(fetchJobsByTitle.pending, (state) => ({
            ...state,
            jobs: {
                ...state.jobs,
                pending: true
            }
        }));
        builder.addCase(searchJobsByTitle.pending, (state) => ({
            ...state,
            jobs: {
                ...state.jobs,
                pending: true
            }
        }));
        builder.addCase(fetchTitle.fulfilled, (state, action) => ({
            ...state,
            content: {
                ...state.content,
                ...action.payload.content,
                users: {
                    ...state.content.users,
                    content: [...state.content.users.content, ...action.payload.content.users.content]
                },
                groups: {
                    ...state.content.groups,
                    content: [...state.content.groups.content, ...action.payload.content.groups.content]
                }
            },
            pending: false,
            fulfilled: true
        }));
        builder.addCase(fetchTitle.pending, (state, action) => ({
            ...state,
            pending: true
        }));
    }
});
