import React, { useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MainTable } from '@mediaseal-webui/components';
import { Header } from '../../../components/layout-components';
import { clearGroupPageSearchValue, setConfirmDialogue } from '@mediaseal-webui/actions';
import { genericHeader } from '../../../data';
import { useTableSortV2 } from '@mediaseal-webui/hooks';
import ActionContainer from '../../../components/table-components/ActionContainer/ActionContainer';
import { Content, SubmitButton } from '@mediaseal-webui/components';
import { useTableScroller, useAuth, useEntityDelete } from '@mediaseal-webui/hooks';
import { DecryptorGroupService } from '@mediaseal-webui/services';
import { useAppSelector } from 'store';
import { decryptorGroupsSlice, fetchDecryptorGroups, searchDecryptorGroups } from 'reducers/groups/groups';
import { TableColumns } from '@mediaseal-webui/types';

const Decryptor = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useAuth();
    const service = useMemo(() => new DecryptorGroupService(), []);

    const { content: groups, page, searchValue: groupPageSearchValue, pending } = useAppSelector((state) => state.decryptorGroups);

    const { setSearchValue, clearSearchValue, clearGroups } = decryptorGroupsSlice.actions;

    const fetchFn = useCallback(() => dispatch(searchDecryptorGroups({ keyword: service.baseParams.keyword ?? '', service })), [service, dispatch]);

    const { memoDeleteEntityAndReFetch } = useEntityDelete({ fetchFn });

    const { headCells, sortFn, order, orderBy } = useTableSortV2({
        cells: genericHeader,
        fetchFn,
        service
    });

    useEffect(() => {
        window.qt && window.GeneralWebInterface.allowFileDrop(false, { dropBehaviour: 'off' });
        dispatch(searchDecryptorGroups({ service, keyword: '' }));
        return () => {
            dispatch(clearGroups());
            dispatch(clearGroupPageSearchValue());
        };
    }, [dispatch, clearGroups, service]);

    useTableScroller();

    const handleCreateClick = () => history.push('/groups/decryptor/create');

    const handleRowClick = (group) => history.push(`/groups/decryptor/${group.id}/profile`);

    const handleGroupDelete = (entity) => () =>
        dispatch(
            setConfirmDialogue({
                icon: 'warning',
                title: 'Delete Group',
                confirmButtonText: 'Delete Group',
                message: 'Are you sure you want to delete this group?',
                action: memoDeleteEntityAndReFetch({ entity, endpoint: 'recipientgroups' })
            })
        );

    const CreateButton = () => <SubmitButton title='Create Group' id='create-dec-group-button' disabled={false} onClick={handleCreateClick} />;

    const { items: permissions } = useAppSelector((state) => state.permissions);
    const hasEditDecryptorGroupsPermission = permissions.some((permission) => permission.permissionName === 'canEditDecryptorGroups');
    const hasCreateEncryptorGroupsPermission = permissions.some((permission) => permission.permissionName === 'canCreateEncryptorGroups');

    const fetchNextPage = useCallback(async () => {
        if (service.baseParams.page <= (page?.totalPages ?? 0)) {
            service.setParams({
                page: service.baseParams.page + 1
            });
            await dispatch(fetchDecryptorGroups(service));
        }
    }, [dispatch, page?.totalPages, service]);

    return (
        <div className='decryptor groups container'>
            <Header
                list
                name='Decryptor Groups'
                ActionButton={(auth.create || auth.administer || hasCreateEncryptorGroupsPermission) && CreateButton}
                displayingText={`Displaying ${groups.length} of ${page?.totalElements} decryptor groups`}
                breadcrumbProps={{
                    links: [{ id: 0, name: 'Decryptor Groups', to: '/groups/decryptor' }],
                    text: [{ id: 0, name: '' }],
                    isFetching: pending
                }}
                searchProps={{
                    setPage: () => null,
                    searchFn: 'searchDecryptorGroups',
                    value: groupPageSearchValue,
                    clear: clearSearchValue,
                    set: setSearchValue
                }}
            />
            <Content>
                {(classes) => (
                    <MainTable
                        isFetching={pending}
                        entities={groups}
                        id='decryptor-group-table'
                        totalPages={page?.totalPages}
                        noResultsMessage='No groups returned'
                        searchValue={groupPageSearchValue}
                        pageControl={{ page: service.baseParams.page, setPage: () => null }}
                        fetchFn={fetchNextPage}
                        tableActionsRenderer={(entity) => <ActionContainer create={auth.create || auth.administer} removeAction={handleGroupDelete(entity)} />}
                        sortData={{
                            sortBy: orderBy,
                            sortDirection: order,
                            createSortHandler: sortFn
                        }}
                        columns={hasEditDecryptorGroupsPermission ? headCells : headCells.filter((item: TableColumns) => item.label !== 'Actions')}
                        entityLink='/groups/decryptor'
                        onRowClick={handleRowClick}
                    />
                )}
            </Content>
        </div>
    );
};

export default Decryptor;
