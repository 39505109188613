import { useState, useEffect } from 'react';
import { SerializedPermissions } from '@mediaseal-webui/utils';
import { useAppSelector } from '@mediaseal-webui/online/src/store';

export interface ICategory {
    options: [
        {
            checked: boolean;
        }
    ];
}

export const useCheckAllSelected = (categories: SerializedPermissions[]) => {
    const [checked, setChecked] = useState(false);
    const allowedPermissions = useAppSelector((state) => state.permissions).items.map((allowedPermission) => allowedPermission.permissionName);

    useEffect(() => {
        const arr: boolean[] = [];
        categories.forEach((category) => {
            category.options.forEach((option) => {
                const foundPermission = allowedPermissions && allowedPermissions.some((ap) => ap === option.permissionName);
                foundPermission && arr.push(option.checked);
            });
        });
        setChecked(arr.every((item) => item));
        return () => {
            setChecked(false);
        };
    }, [allowedPermissions, categories]);
    return { checked, setChecked };
};
