import React, { FC, useState } from 'react';
import { PasswordTooltip } from '../../Tooltips';
import { TextInput, TextInputProps } from '../TextInput';
import { EyeToggle, StyledContainer } from './PasswordInput.style';
import { PasswordScore } from './PasswordScore/PasswordScore';

export interface PasswordInputProps extends TextInputProps {
    enableScore?: boolean;
}

export const PasswordInput: FC<PasswordInputProps> = ({ tooltipText, enableScore, ...others }) => {
    const [type, setType] = useState<string>('password');
    const handleToggle = () => setType((type) => (type === 'password' ? 'text' : 'password'));

    return (
        <>
            <StyledContainer>
                <TextInput
                    {...others}
                    enableScore={enableScore}
                    tooltipText={tooltipText ? tooltipText : undefined}
                    Tooltip={tooltipText ? undefined : PasswordTooltip}
                    type={type}
                />
                <EyeToggle onClick={handleToggle} data-testid='show-password-toggle' />
            </StyledContainer>
            {enableScore && <PasswordScore />}
        </>
    );
};
