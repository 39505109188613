import React, { useEffect, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { clearJobList as clearJobs, clearJobPageSearchValue } from '../../actions/jobs';
import { clearStatusFilter } from '../../actions/jobs/statusFilter';
import ArchiveIcon from '@material-ui/icons/Archive';
import { MdUnarchive } from 'react-icons/md';
import { jobHeader } from '../../data';
import { Header } from '../../components/layout-components';
import { Content, MainTable, SubmitButton } from '@mediaseal-webui/components';
import { RESET_STATUS_FLAGS } from '@mediaseal-webui/constants';
import useStyles from './Jobs.styles';
import ActionContainer from '../../components/table-components/ActionContainer/ActionContainer';
import { useTableScroller, useArchiveJob, useAuth, useJobArchiveStatusChange, useTableSortV2 } from '@mediaseal-webui/hooks';
import { InitialState } from '@mediaseal-webui/types/redux';
import { GenericEntity } from '@mediaseal-webui/types/schemas';
import { useAppDispatch, useAppSelector } from 'store';
import { fetchJobs, jobsSlice, searchJobs } from 'reducers/jobs/jobs';
import { JobsService } from '@mediaseal-webui/services';
const Jobs = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [archiveJob] = useArchiveJob();
    const archiveStatusChange = useJobArchiveStatusChange();

    const auth = useAuth();
    const isCreateAuth = auth.create;

    const { setSearchValue, clearSearchValue, clearJobsState } = jobsSlice.actions;
    const { statusFilter } = useSelector((state: InitialState) => state.jobs);
    const { content: jobs, page, searchValue: jobPageSearchValue, pending } = useAppSelector((state) => state.jobs.jobs);
    // const { permissions } = useSelector((state: InitialState) => state.misc);\
    const { items: permissions } = useAppSelector((state) => state.permissions);
    const { archived, liveFolders, active } = statusFilter;
    const hasArchiveAuth = permissions.some((permission) => permission.permissionName === 'canArchiveJobs');
    const service = useMemo(() => new JobsService(), []);
    const tableHeader = useMemo(() => jobHeader(service), [service]);
    const fetchFn = useCallback(() => dispatch(searchJobs({ keyword: service.baseParams.keyword ?? '', service })), [service, dispatch]);
    const { headCells, sortFn, order, orderBy } = useTableSortV2({
        cells: tableHeader,
        fetchFn,
        service
    });

    useTableScroller();
    useEffect(() => {
        window.qt && window.GeneralWebInterface.allowFileDrop(false, { dropBehaviour: 'off' });
        return () => {
            dispatch(clearJobs());
            dispatch(clearJobPageSearchValue());
            dispatch(clearStatusFilter());
            dispatch({ type: RESET_STATUS_FLAGS });
        };
    }, [dispatch]);
    const archiveFn = useCallback(
        (entity: GenericEntity, setArchived: boolean, setActive: boolean) => async () => {
            await archiveJob(entity, setArchived, setActive);
            await dispatch(searchJobs({ keyword: '', service }));
        },
        [dispatch, archiveJob, service]
    );

    const handleJobArchive = (entity: GenericEntity, setArchived: boolean) =>
        archiveStatusChange.handleArchival(setArchived, archiveFn(entity, setArchived, entity.type !== 'ONLINE'));

    const onRowClick = ({ id }: Pick<GenericEntity, 'id'>) => history.push(`jobs/${id}/profile`); // returns the rowData obj from Table

    const handleCreateClick = () => history.push('/jobs/create');

    const CreateButton = () => <SubmitButton title='Create Job' id='create-jobs-btn' disabled={false} onClick={handleCreateClick} />;

    const fetchNextPage = useCallback(async () => {
        if (service.baseParams.page <= (page?.totalPages ?? 0)) {
            service.setParams({
                page: service.baseParams.page + 1
            });
            await dispatch(fetchJobs(service));
        }
    }, [dispatch, page?.totalPages, service]);

    useEffect(() => {
        dispatch(searchJobs({ service, keyword: '' }));
    }, [dispatch, service]);

    useEffect(
        () => () => {
            service.resetParams();
            dispatch(clearJobsState());
        },
        [dispatch, clearJobsState, service]
    );
    return (
        <div className={classes.viewRoot}>
            <Header
                list
                name='Jobs'
                ActionButton={isCreateAuth && CreateButton}
                displayingText={`Displaying ${jobs.length} of ${page?.totalElements ?? 0} jobs`}
                breadcrumbProps={{
                    links: [{ id: 0, name: 'Jobs', to: '/jobs' }],
                    text: [{ id: 0, name: '' }],
                    isFetching: pending
                }}
                searchProps={{
                    setPage: () => null,
                    searchFn: 'searchJobs',
                    filters: {
                        archived,
                        liveFolders,
                        active
                    },
                    value: jobPageSearchValue,
                    set: setSearchValue,
                    clear: clearSearchValue
                }}
            />

            <Content data-tour='content'>
                {() => (
                    <MainTable
                        entities={jobs}
                        isFetching={pending}
                        id='jobs-table'
                        totalPages={page?.totalPages ?? 0}
                        onRowClick={onRowClick}
                        searchValue={jobPageSearchValue}
                        pageControl={{ page: service.baseParams.page, setPage: () => null }}
                        tabIndex={1}
                        entityLink='/jobs'
                        sortData={{
                            sortBy: orderBy,
                            sortDirection: order,
                            createSortHandler: sortFn
                        }}
                        noResultsMessage='No jobs returned'
                        tableActionsRenderer={(entity) => (
                            <ActionContainer
                                create={hasArchiveAuth && isCreateAuth}
                                tooltipTitle={entity.archived ? 'Unarchive' : 'Archive'}
                                removeAction={entity.archived ? handleJobArchive(entity, false) : handleJobArchive(entity, true)}
                                RemoveIcon={entity.archived ? MdUnarchive : ArchiveIcon}
                            />
                        )}
                        columns={headCells}
                        fetchFn={fetchNextPage}
                    />
                )}
            </Content>

            {/* {!mobile && <ScrollToTopButton scrollStepInPx="1000" delayInMs={10} />} */}
        </div>
    );
};

export default Jobs;
