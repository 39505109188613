/**
 *  Use this file in future
 */
import { JobsService } from '@mediaseal-webui/services';
// @ts-expect-error due Module '"@mediaseal-webui/types/api"' has no exported member 'SpringBootResponse'.ts(2305).
import { definitions, SpringBootResponse } from '@mediaseal-webui/types/api';
import { seedData } from '@mediaseal-webui/utils';
// import { seedData } from '@mediaseal-webui/utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const fetchJobs = createAsyncThunk('jobs/fetchJobs', async (service: JobsService, { rejectWithValue }) => {
    try {
        const { data } = await service.fetchJobs('findAllWithQueryParameters');
        return data;
    } catch (error) {
        // @ts-expect-error due to "Object is of type 'unknown'.ts(2571)"
        return rejectWithValue(error.response.data);
    }
});
export const searchJobs = createAsyncThunk('jobs/searchJobs', async ({ service, keyword }: { service: JobsService; keyword: string }, { rejectWithValue }) => {
    try {
        const { data } = await service.searchJobs({ extraParams: 'findAllWithQueryParameters', keyword });
        return data;
    } catch (error) {
        // @ts-expect-error due to error is unknown ts(2571).
        return rejectWithValue(error.response.data);
    }
});

export interface JobsState extends SpringBootResponse<definitions['Job'][]> {
    pending: boolean;
    searchValue: string;
    rejected: boolean;
}
const initialJobState: JobsState = {
    // @ts-expect-error due to "Object literal may only specify known properties, and 'content' does not exist in type 'JobsState'.ts(2322)".
    content: seedData(),
    page: {
        totalElements: 0,
        totalPages: 0
    },
    pending: false,
    rejected: false,
    searchValue: ''
};
export const jobsSlice = createSlice({
    name: 'jobs',
    initialState: initialJobState,
    reducers: {
        setSearchValue: (state, action) => ({
            ...state,
            searchValue: action.payload
        }),
        clearSearchValue: (state) => ({
            ...state,
            searchValue: ''
        }),
        clearJobsState: (state) => initialJobState
    },
    extraReducers: (builder) => {
        builder.addCase(fetchJobs.fulfilled, (state, action) => ({
            ...state,
            page: {
                // @ts-expect-error due to prop page does not exist ts(2339).
                totalElements: action.payload.page?.totalElements ?? state.page?.totalElements,
                // @ts-expect-error due to prop page does not exist ts(2339).
                totalPages: action.payload.page?.totalPages ?? state.page?.totalPages
            },
            // @ts-expect-error due to prop content does not existts(2339).
            content: [...state.content, ...action.payload.content],
            pending: false
        }));
        builder.addCase(fetchJobs.pending, (state, action) => ({
            ...state,
            pending: true
        }));
        builder.addCase(fetchJobs.rejected, (state, action) => ({
            ...state,
            pending: false,
            rejected: true
        }));
        builder.addCase(searchJobs.fulfilled, (state, action) => ({
            ...state,
            page: {
                totalElements: action.payload.page?.totalElements,
                totalPages: action.payload.page?.totalPages
            },
            content: [...action.payload.content],
            pending: false
        }));
        builder.addCase(searchJobs.pending, (state, action) => ({
            ...state,
            pending: true
        }));
    }
});
