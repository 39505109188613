import { combineReducers } from 'redux';
import { SET_STATUS_FLAGS, RESET_STATUS_FLAGS } from '@mediaseal-webui/constants';
import { JobsFiltersAction } from './types';

const initialJobFilterState = {
    status: {
        archived: 'unarchived',
        liveFolders: 'allLiveFolders',
        active: 'allActive',
        batched: 'allBatched'
    }
};

const jobs = (state = initialJobFilterState, action: JobsFiltersAction) => {
    switch (action.type) {
        case SET_STATUS_FLAGS:
            return {
                ...state,
                status: {
                    ...state.status,
                    ...action.payload
                }
            };
        case RESET_STATUS_FLAGS:
            return initialJobFilterState;
        default:
            return state;
    }
};

export default combineReducers({
    jobs
});
