import React from 'react';
import { TableSortLabel, Hidden, TableCell } from '@material-ui/core';
import clsx from 'clsx';
import { HeaderRendererProps } from '.';
import { FilterData, SortOrders } from '@mediaseal-webui/types';
import { Filter } from '../Filter';

export const headerRenderer = ({ label, columnIndex, dataKey, columns, classes, sortProps }: HeaderRendererProps) => {
    const { sortBy, createSortHandler } = sortProps;
    const direction: SortOrders = columns[columnIndex].order;
    const inner = !columns[columnIndex].disableSort ? (
        <TableSortLabel active={dataKey === sortBy} direction={direction} onClick={createSortHandler(columns[columnIndex])}>
            {label}
        </TableSortLabel>
    ) : (
        label
    );
    return (
        <Hidden only={columns[columnIndex].hidden}>
            <TableCell
                component='div'
                id={dataKey}
                className={clsx({
                    [classes.alignFlexEnd]: columns[columnIndex].label === 'Status' || columns[columnIndex].label === 'Actions',
                    [classes.tableCell]: true,
                    [classes.flexContainer]: true
                })}
                variant='head'>
                {inner}
                {columns[columnIndex].filter?.enabled && (
                    <Filter
                        fetchFn={columns[columnIndex].filter?.fetchFn}
                        getterKey={columns[columnIndex].filter?.getterKey || ''}
                        getterSubKey={columns[columnIndex].filter?.getterSubKey || ''}
                        setterFn={columns[columnIndex].filter?.setterFn}
                        data={columns[columnIndex].filter?.data || ([] as FilterData[])}
                    />
                )}
            </TableCell>
        </Hidden>
    );
};
