import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, IconButton } from '@material-ui/core';
import { Pause } from '@material-ui/icons';
import { BtnProps } from '..';
// import { Test } from './PauseBtn.styles';

export const PauseBtn = ({ file }: BtnProps) => {
    const handlePauseClick = () => {
        if (window.qt) {
            window.EncryptionWebInterface.pauseEncryptionWork(file.id);
        }
    };
    return (
        <Tooltip title='Pause Job' placement='top'>
            <IconButton
                className='pause-btn'
                onClick={() => {
                    handlePauseClick();
                }}>
                <Pause fontSize='small' />
            </IconButton>
        </Tooltip>
    );
};
