import { useCallback } from 'react';
import { setError } from '@mediaseal-webui/actions/src';
import { msApi } from '@mediaseal-webui/api';
import { GenericEntity } from '@mediaseal-webui/types/schemas';
import { useDispatch } from 'react-redux';
import { Dispatchable } from '@mediaseal-webui/types/redux';
import { FetchArgs } from '@mediaseal-webui/types/generic';

export interface DeleteFnArgs {
    entity: GenericEntity;
    endpoint: string;
}
export interface UseEntityDeleteArgs {
    fetchFn: (args: FetchArgs) => void;
}
export const useEntityDelete = ({ fetchFn }: UseEntityDeleteArgs) => {
    const dispatch = useDispatch();

    const fn = async ({ entity, endpoint }: DeleteFnArgs) => {
        try {
            await msApi.delete(`/${endpoint}/${entity.id}`);
            dispatch({
                type: 'SET_SNACKBAR',
                payload: {
                    success: true,
                    message: `Successfully deleted: ${entity.name}`
                }
            });
        } catch (error) {
            dispatch(setError(error));
        }
    };

    const safeFn = useCallback(
        ({ entity, endpoint }: DeleteFnArgs) =>
            async () => {
                await fn({ entity, endpoint });
                fetchFn();
                // dispatch(fetchFn({ keyword: '', page: 0, firstCall: true }))
            },
        [dispatch, fetchFn]
    );

    return {
        deleteEntity: fn,
        memoDeleteEntityAndReFetch: safeFn
    };
};
