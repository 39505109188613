import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Button, Fade, Dialog, DialogActions, FormControl } from '@material-ui/core';
import Person from '@material-ui/icons/Person';
import useStyles from './FilterUsers.styles';
import { FilterUsersProps } from '.';
import { UserSelection } from './UserSelection';
const FilterUsers = ({ pageControl, setterFn, responsive, ...others }: FilterUsersProps) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    if (responsive) {
        return (
            <div>
                <Fade in timeout={1000}>
                    <Button size='large' onClick={handleOpen} startIcon={<Person />} disableElevation>
                        user
                    </Button>
                </Fade>
                <Dialog
                    PaperProps={{
                        className: classes.dialogue
                    }}
                    onClose={handleClose}
                    aria-labelledby='date-picker'
                    open={open}>
                    <FormControl className={classes.formControl}>
                        <UserSelection setterFn={setterFn} />
                    </FormControl>
                    <DialogActions>
                        <Button fullWidth={matches} onClick={handleClose}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
    return (
        <FormControl className={classes.formControl}>
            <UserSelection setterFn={setterFn} />
        </FormControl>
    );
};

export default React.memo(FilterUsers);
