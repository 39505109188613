import React from 'react';
import { definitions } from '@mediaseal-webui/types/api';

import { InfoHeader, InfoField, Half, MetaInfo } from './FileMetaInfo.styles';
import { MdContactPhone } from 'react-icons/md';
import { JobTypes } from '@mediaseal-webui/types';
import { Typography } from '@material-ui/core';
import { FaClipboard } from 'react-icons/fa';

interface FileMetaInfoProps {
    job: definitions['Job'] & definitions['File'];
}
export const FileMetaInfo = ({ job }: FileMetaInfoProps) => {
    const processAuthMode = (type: JobTypes) => {
        switch (type) {
            case 'PASSWORD_ONLY':
                return 'Password Only';
            case 'ONLINE':
                return 'Online';
            case 'OFFLINE':
                return 'Offline';
            default:
                break;
        }
    };
    return (
        <MetaInfo>
            <Half>
                <InfoHeader>
                    <FaClipboard />
                    Job Details
                </InfoHeader>
                <Typography variant='body2' color='textPrimary'>
                    Job Name
                </Typography>
                <InfoField id='dialog-job-name'>{job.name}</InfoField>
                <Typography variant='body2' color='textPrimary'>
                    Job Type
                </Typography>
                {/* @ts-expect-error due to prop type does not exist ts(2339) */}
                <InfoField id='dialog-job-type'>{processAuthMode(job.type)}</InfoField>
                <Typography variant='body2' color='textPrimary'>
                    Additional Data Version
                </Typography>
                <InfoField id='dialog-additional-data'>{job.additionalDataVersion}</InfoField>
                <Typography variant='body2' color='textPrimary'>
                    Header Version
                </Typography>
                <InfoField id='dialog-header-version'>{job.headerVersion}</InfoField>
            </Half>
            <Half>
                <InfoHeader>
                    <MdContactPhone />
                    Contact Information
                </InfoHeader>
                <label>Name</label>
                <InfoField id='dialog-contact-name'>{job.contactName || 'No contact name'}</InfoField>
                <label>Email / Phone number</label>
                {
                    //@ts-expect-error due to property InfoField does not exist ts(2339).
                    <InfoField id='dialog-contact-info'>{job.InfoField || 'No contact address'}</InfoField>
                }
            </Half>
        </MetaInfo>
    );
};
