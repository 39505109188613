import FormGroup from './FormGroup';

export interface FormGroupProps {
    children: React.ReactChild[] | React.ReactChild;
    heading?: string | React.ReactNode;
    subtitle?: string;
    className?: string;
    checkbox?: boolean;
    classes?: Record<string, string>;
}

export default FormGroup;
