import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    modalPaper: {
        background: theme.palette.background.paper,
        borderRadius: theme.spacing(1 / 2),
        width: `100%`,
        height: '100%',
        minHeight: theme.spacing(75)
    },

    buttons: {
        width: '100%',
        textAlign: 'right'
    },

    tabSection: {
        paddingTop: theme.spacing(4)
    }
}));

export default useStyles;
