import { TitleService } from '@mediaseal-webui/services';
import { definitions, SpringBootResponse } from '@mediaseal-webui/types/api';
import { seedData } from '@mediaseal-webui/utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const fetchTitles = createAsyncThunk('titles/fetchTitles', async (service: TitleService, { rejectWithValue }) => {
    try {
        const { data } = await service.fetchTitles();
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const searchTitles = createAsyncThunk(
    'titles/searchTitles',
    async ({ keyword, service }: { keyword: string; service: TitleService }, { rejectWithValue }) => {
        try {
            const { data } = await service.searchTitles(keyword);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export interface TitlesState extends SpringBootResponse<definitions['Title'][]> {
    pending: boolean;
    searchValue: string;
}
const initialState: TitlesState = {
    content: seedData(),
    searchValue: '',
    page: {
        totalElements: 0,
        totalPages: 0
    },
    pending: false
};
export const titlesSlice = createSlice({
    name: 'titles',
    initialState,
    reducers: {
        setSearchValue: (state, action) => ({
            ...state,
            searchValue: action.payload
        }),
        clearSearchValue: (state) => ({
            ...state,
            searchValue: ''
        }),
        setTitles: (state, action) => ({
            ...state,
            ...action.payload
        }),
        clearTitles: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTitles.fulfilled, (state, action) => ({
            ...state,
            page: {
                ...state.page,
                ...(action.payload.page ?? {
                    totalElements: 0,
                    totalPages: 0
                })
            },
            content: [...state.content, ...action.payload.content],
            pending: false
        }));
        builder.addCase(fetchTitles.pending, (state, action) => ({
            ...state,
            pending: false
        }));
        builder.addCase(searchTitles.fulfilled, (state, action) => ({
            ...state,
            page: {
                ...(action.payload.page ?? {
                    totalPages: 0,
                    totalElements: 0
                })
            },
            content: [...action.payload.content],
            pending: false
        }));
        builder.addCase(searchTitles.pending, (state, action) => ({
            ...state,
            pending: false
        }));
    }
});
