import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Fab, CircularProgress, Typography, useMediaQuery } from '@material-ui/core';
import { useForm, useAuth } from '@mediaseal-webui/hooks';
import { HeaderTitle, Breadcrumbs, SearchBar, DesktopHeaderBodyProps, HeaderItems } from '..';
import { EditableEntityTitle } from '@mediaseal-webui/components';
import { InitialState } from '@mediaseal-webui/types/redux';
import { format } from 'date-fns';
import useStyles from '../Header.styles';
import { CalendarToday, Person } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { DesktopLeftContainer } from './Desktop.style';

export const DesktopHeaderBody = (props: DesktopHeaderBodyProps) => {
    const {
        ActionButton,
        date,
        afterDate = '',
        name,
        defaultName,
        displayingText,
        list = false,
        skeleton = false,
        breadcrumbProps,
        titleProps,
        loaderText = 'Saving...',
        searchProps,
        editable = false,
        pageControl,
        isReadOnly,
        loading,
        submitHandler,
        headerItems,
        titleValidation,
        // tabProps,
        // profile,
        author
    } = props;

    const actionButtonRef = useRef<HTMLDivElement | null>(null);
    const classes = useStyles();
    const { invalid } = useForm();
    const { isFetching, mobileSearchVisible } = useSelector(({ misc }: InitialState) => misc);
    const { edit, administer } = useAuth();
    const [maxTitleWidth, setMaxTitleWidth] = useState<string>('');
    const overflowing = useMediaQuery('@media (max-width: 1305px)');

    const ActionButtonContainer = () => (
        <div
            ref={actionButtonRef}
            className={clsx(classes.buttonContainer, {
                listView: !date
            })}>
            {loading ? (
                <Fab variant='extended' type='submit' color='primary'>
                    <CircularProgress data-testid='loader' size={24} className={classes.buttonProgress} />
                    {loaderText}
                </Fab>
            ) : (
                typeof ActionButton !== 'boolean' && <ActionButton onClick={submitHandler} disabled={invalid || (skeleton && isFetching)} />
            )}
        </div>
    );
    const actionButtonWidth = actionButtonRef.current ? actionButtonRef.current.getBoundingClientRect().width : 0;

    const text = date ? `${format(date, 'PPPP')}${afterDate}` : '';

    const visibleItemCount = (headerItems: object) => Object.values(headerItems).reduce((acc, item) => (item ? acc + 1 : acc), 0);

    useEffect(() => {
        if (headerItems) {
            const offset = overflowing ? 100 : 57 * visibleItemCount(headerItems);
            const buttonOffset = ActionButton ? 200 : 0;
            setMaxTitleWidth(`calc(100% - ${offset + buttonOffset}px)`);
        }
    }, [headerItems, overflowing, ActionButton]);

    return (
        <div className={classes.desktopBodyRoot}>
            <Breadcrumbs links={breadcrumbProps.links} isFetching={breadcrumbProps.isFetching} text={breadcrumbProps.text} displayingText={displayingText} />
            <div className={classes.desktopMiddleContainer}>
                <DesktopLeftContainer width={maxTitleWidth}>
                    {editable && (edit || administer) ? (
                        <EditableEntityTitle
                            value={name}
                            setterFn={titleProps?.setterFn}
                            isReadOnly={isReadOnly}
                            validation={titleValidation}
                            className={classes.editableTitle}
                            // maxWidth={maxTitleWidth}
                        />
                    ) : (
                        <HeaderTitle
                            text={name || defaultName}
                            skeleton={false}
                            className={clsx(classes.title, {
                                [classes.opacity]: mobileSearchVisible,
                                [classes.singleTitle]: list
                            })}
                        />
                    )}
                </DesktopLeftContainer>

                <div
                    className={clsx(classes.desktopRightContainer, {
                        list: list
                    })}>
                    {searchProps && <SearchBar {...searchProps} name={name} />}

                    {/* ActionButton for list views */}
                    {ActionButton && !date ? <ActionButtonContainer /> : <div />}

                    {date && (
                        <div className={clsx(classes.stats)}>
                            {headerItems && (
                                <HeaderItems isFetching={isFetching} pageControl={pageControl} data={headerItems} actionButtonWidth={actionButtonWidth} />
                            )}
                            {ActionButton && <ActionButtonContainer />}
                        </div>
                    )}
                </div>
            </div>

            <div className={classes.metaInfo}>
                {date && (
                    /* !hide.includes('md') && */ <Typography className={classes.headerDate} color='textSecondary' variant='subtitle2' id='header-date'>
                        <CalendarToday />
                        <span>{text}</span>
                    </Typography>
                )}
                {author && (
                    <Typography className={classes.headerDate} color='textSecondary' variant='subtitle2' id='header-date'>
                        <Person />
                        <Link
                            className={classes.userLink}
                            to={`/users/encryptor/${author.id}/profile`}>{`Created By: ${author.firstName} ${author.lastName}`}</Link>
                    </Typography>
                )}
            </div>
        </div>
    );
};
