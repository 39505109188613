import { Dispatchable } from '@mediaseal-webui/types/redux';
import { SETTINGS_SENT_TO_QT } from '@mediaseal-webui/constants';

export interface SendSettingsToQtArgs {
    encryptionPrefix: string;
    studioName: string;
    username?: string;
}
export const sendSettingsToQt = ({ encryptionPrefix, studioName, username }: SendSettingsToQtArgs): Dispatchable => {
    try {
        if (window.qt) {
            username && window.StudioWebInterface.successfulLoginAttempt(username);
            console.log(`sending studio name: ${studioName}`);
            console.log(`sending prefix: ${encryptionPrefix}`);
            window.StudioWebInterface.updateSettings({
                encryptionPrefix,
                studioName
            });
        }
    } catch (error) {
        //silently handle it
        console.error(error);
    }
    return { type: SETTINGS_SENT_TO_QT };
};
