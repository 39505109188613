import { clearEntity } from "@mediaseal-webui/actions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const useCleanEntity = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        return () => {
            dispatch(clearEntity())
        } 
    }, []);
};