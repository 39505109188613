import { setEdit } from '@mediaseal-webui/actions/src';
import { useDispatch } from 'react-redux';

type UpdateFn = (id: number) => void;

export const useStatusChange = (id: number, updateFn: UpdateFn) => {
    const dispatch = useDispatch();

    const handleStatusChange = (type: string) => () => {
        dispatch(setEdit({ active: type === 'active' }));
        dispatch(updateFn(id));
    };

    return handleStatusChange;
};
