//TODO: Add selected users and groups to edit state
// TODO: Whenever a new call is made search / fetch.. we need to check edit state for the item and apply checked / not checked

import React, { memo, useEffect, useCallback, Key } from 'react';
import { SelectableTable } from '../../Table';
import useStyles from './Table.styles';
import { EntityKey, InitialState } from '@mediaseal-webui/types/redux';
import { useDispatch, useSelector } from 'react-redux';
import { useEntity } from '@mediaseal-webui/hooks';
import { DecryptorGroupService, EncryptorGroupService, RecipientService, TokensService, UsersService } from 'packages/services/src';
import { useAppSelector } from '@mediaseal-webui/online/src/store';
import { selectEntity } from '../../../../actions';
import { GenericEntity } from 'packages/types';

export interface EntityTableProps {
    entityKey: EntityKey;
    service: UsersService & RecipientService & EncryptorGroupService & DecryptorGroupService & TokensService;
}

export const Table = memo(({ entityKey, service }: EntityTableProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleEntityKey = (key: EntityKey) => {
        if (key === 'decryptorUsers') return 'recipients';
        if (key === 'allowedTokens') return 'tokens';

        return key;
    };

    const entityType = entityKey === 'encryptorUsers' ? 'users' : entityKey;

    const { cells, fetchFn, items, searchFn } = useEntity(entityKey);
    const { page, pending } = useAppSelector((state) => state[handleEntityKey(entityKey)]);

    const entityData = useSelector((state: InitialState) => state.entity);
    let summaryKey: any;

    switch (entityKey) {
        case 'decryptorUsers':
            summaryKey = entityData.summary.decryptorUsers;
            break;
        case 'decryptorGroups':
            summaryKey = entityData.summary.decryptorGroups;
            break;
        case 'encryptorUsers':
            summaryKey = entityData.summary.users;
            break;
        case 'encryptorGroups':
            summaryKey = entityData.summary.encryptorGroups;
            break;
        case 'allowedTokens':
            summaryKey = entityData.profile.allowedTokens;
    }

    useEffect(() => {
        if (!pending) {
            const filteredEntities = summaryKey;
            filteredEntities &&
                filteredEntities.forEach((entity: GenericEntity) => {
                    dispatch(selectEntity(entityKey)(entity));
                });
        }
    }, [pending]);

    const noResultsMessage = () => {
        if (entityKey === 'decryptorGroups' || entityKey === 'encryptorGroups') {
            return 'There are no more groups to assign';
        }
        if (entityKey === 'allowedTokens') {
            return 'There are no more tokens to assign';
        }
        return 'There are no more users to assign';
    };

    const fetchNextPage = useCallback(async () => {
        if (service.baseParams.page <= (page?.totalPages ?? 0)) {
            service.setParams({
                page: service.baseParams.page + 1
            });
            fetchFn && (await dispatch(fetchFn(service)));
        }
    }, [dispatch, page?.totalPages, service]);

    useEffect(() => {
        dispatch(searchFn && searchFn({ service, keyword: '' }));
        // eslint-disable-next-line
        return () => {
            service.resetParams();
        };
    }, [dispatch]);

    return (
        <div className={classes.tableWrapper}>
            <SelectableTable
                filterFetch
                isFetching={pending}
                entities={items}
                entityKey={entityKey}
                pageControl={{ page: service.baseParams.page, setPage: () => null }}
                fetchFn={fetchNextPage}
                columns={cells}
                noResultsMessage={noResultsMessage()}
            />
        </div>
    );
});
