import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
import useStyles from './TabSection.styles';
import { TabPanel } from '../TabPanel/TabPanel';

const TabSection = ({ tabs, scrollButtons, tabsClasses, tabClasses, variant, onTabChanged, tabOverride, ...others }) => {
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const classes = useStyles();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        onTabChanged(newValue);
    };

    const changeHandler = useCallback(handleChange, [onTabChanged]);

    useEffect(() => {
        if (tabOverride !== null) {
            setValue(tabOverride);
        }
    }, [tabOverride, setValue]);

    const wrapper = tabsClasses?.wrapper || classes.wrapper;

    return (
        <div className={wrapper}>
            <Tabs
                value={value}
                onChange={changeHandler}
                variant={variant}
                scrollButtons={scrollButtons}
                aria-label='tabsection tabs'
                data-testid='tabsection-tabs'
                classes={tabsClasses}>
                {tabs.map((tab) => (
                    <Tab label={tab.label} id={tab.attrId} key={tab.id} value={tabs.id} classes={tabClasses} />
                ))}
            </Tabs>
            {tabs.map((tab, index) => (
                <TabPanel key={tab.id} value={value} component={tab.component} dir={theme.direction} index={index} {...others} />
            ))}
        </div>
    );
};

TabSection.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            component: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.func]),
            label: PropTypes.string
        })
    ),
    scrollButtons: PropTypes.oneOf(['auto', 'desktop', 'on', 'off']),
    tabsClasses: PropTypes.object,
    tabClasses: PropTypes.object,
    variant: PropTypes.oneOf(['scrollable', 'fullWidth', 'standard']),
    onTabChanged: PropTypes.func,
    tabOverride: PropTypes.number
};

TabSection.defaultProps = {
    tabs: [
        {
            id: 0,
            component: () => <div />,
            label: ''
        }
    ],
    scrollButtons: 'on',
    variant: 'fixed',
    onTabChanged: () => {},
    tabOverride: 0
};

export default TabSection;
