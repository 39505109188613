import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    wrapper: {
        zIndex: 100
    },

    root: {
        color: theme.palette.text.primary,
        opacity: 0.8,
        '&:hover': {
            opacity: 1,
            color: theme.palette.text.primary
        }
    }
}));
