import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useBreakPointListener, useHeaderItemsMoved } from '@mediaseal-webui/hooks';
import { IconType } from 'react-icons';
import { StyledSlider, ToggleOption, useTooltipStyles } from './ToggleSlider.style';
import { Tooltip } from '@material-ui/core';

export interface ToggleData {
    value: string;
    text: string;
    id: string;
    setterFn: (event: React.MouseEvent) => void;
    icon?: IconType;
}

export interface ToggleSliderProps {
    data: ToggleData[];
    selectedValue: string;
    disabled: boolean;
    tooltipText?: string;
    tooltipOpen?: boolean;
}

export const ToggleSlider = ({ data, selectedValue, disabled, tooltipText, tooltipOpen }: ToggleSliderProps) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(!!tooltipOpen);
    const [selected, setSelected] = useState<string>(selectedValue);
    const { hide } = useBreakPointListener();
    const tooltipStyles = useTooltipStyles();
    const itemRef = useRef<HTMLDivElement | null>(null);

    const listener = useCallback(() => {
        if (itemRef.current) {
            const item = itemRef.current.getBoundingClientRect();
            if (item.top < 106 || item.bottom > window.innerHeight - 86) {
                setShowTooltip(false);
            } else {
                setShowTooltip(!!tooltipOpen);
            }
        }
    }, [itemRef, tooltipOpen]);

    useHeaderItemsMoved(listener);

    useEffect(() => {
        setSelected(selectedValue);
    }, [selectedValue]);

    useEffect(() => {
        listener();
    }, [tooltipOpen]);

    return (
        <Tooltip
            interactive
            open={showTooltip}
            PopperProps={{
                popperOptions: {
                    modifiers: {
                        offset: '10%'
                    }
                }
            }}
            title={tooltipText || ''}
            placement='left'
            classes={{
                popper: tooltipStyles.popper,
                tooltip: tooltipStyles.tooltip
            }}>
            <StyledSlider disabled={disabled} selectedValue={selectedValue} data={data} ref={itemRef}>
                {data.map(({ value, setterFn, text, icon: Icon, id }, index) => (
                    <ToggleOption
                        id={id}
                        disabled={disabled}
                        key={index}
                        selected={value === selected}
                        onClick={disabled || value === selected ? () => null : setterFn}>
                        {!hide.includes('md') || !Icon ? text || value : <Icon />}
                    </ToggleOption>
                ))}
            </StyledSlider>
        </Tooltip>
    );
};
