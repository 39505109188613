import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    '@global': {
        html: {
            background: theme.palette.background.default
        },
        body: {
            background: theme.palette.background.default
        }
    },
    content: {
        top: theme.sizes.header.open,
        width: `calc(100% - ${theme.spacing(4)}px)`,
        background: theme.palette.background.default,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        display: 'flex',
        height: ` calc(100vh - ${theme.sizes.header.open}px)`,
        // calc(100vh - ${theme.sizes.header.open}px)
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        transition: 'all 0.2s ease',
        paddingTop: theme.spacing(3),
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginLeft: 0,
            marginRight: 0,
            width: '100%'
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
            top: theme.sizes.header.mobile,
            height: ` calc(100vh - ${theme.sizes.header.mobile - theme.spacing(6)}px)`
            // height: '100%'
        }
    },
    sidebarOpen: {
        // width: `calc(100% - ${theme.sizes.sideNav.open}px)`
    },
    wrapper: {
        background: theme.palette.background.default,
        // border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0 0`,
        width: '100%',
        padding: theme.spacing(2)
    },
    cols: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    summary: {
        minWidth: theme.spacing(45), // 360px
        maxWidth: '35%',
        zIndex: 9,
        paddingLeft: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            marginTop: 250,
            marginBottom: 75,
            minWidth: 'unset',
            maxWidth: 'unset'
        }
    },
    stack: {
        // display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        width: '100%',
        height: 'calc(100% - 75px)'
    },
    tabContent: {
        overflow: 'scroll',
        width: '100%'
    },
    tabPanel: {
        // height: '100%',
    },
    tabSection: {
        background: theme.palette.background.default,
        height: '100%',
        // border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: '4px 4px 0 0',
        width: '100%'
    },
    contained: {
        height: theme.sizes.tables.forms
    },
    noWrap: {
        flexWrap: 'nowrap'
    },
    widgets: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(4),
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    label: {
        marginLeft: theme.spacing(2)
    },
    table: {
        margin: `${theme.spacing(2)}px !important`,
        padding: theme.spacing(2),
        width: `calc(100% - ${theme.spacing(4)}px) !important`
    },
    nameFields: {
        display: 'inline-flex',
        position: 'absolute'
    },
    profileCard: {
        width: '100%',
        height: `calc(100% - ${theme.spacing(3)}px)`,
        minHeight: 450,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            height: 'unset'
        }
    },
    smallProfileCard: {
        width: '50%',
        padding: theme.spacing(1)
    },
    leftCard: {
        margin: `0 ${theme.spacing(1)}px ${theme.spacing(2)}px 0`
    },
    rightCard: {
        margin: `0 0 ${theme.spacing(2)}px ${theme.spacing(1)}px`
    },
    cardWrapper: {
        height: 'calc(100% - 40px)',
        width: '100%',
        overflowY: 'scroll',
        marginTop: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            height: 'calc(100% - 140px)'
        }
    },
    titleBlock: {
        height: 50,
        paddingBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    flex: {
        display: 'flex'
    },
    clearfix: {
        paddingBottom: theme.spacing(8)
    },

    userTable: {
        width: '100%',
        height: `100vh`,
        minHeight: 450,
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            height: 'unset'
        }
    }
}));

export default useStyles;
