import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export const StyledMenu = styled(Menu)`
    font-size: 0.5rem;

    & > li {
        font-size: small !important;
    }
`;

export const StyledMenuItem = styled(MenuItem)`
    font-size: small !important;
`;
