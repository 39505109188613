import React, { useMemo } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { useCallback, useEffect, useRef, useState } from 'react';
import { definitions } from '@mediaseal-webui/types/api';
import { searchRecipients } from '../../../../reducers/users/recipients';
import { useAppSelector, useAppDispatch } from '../../../../store';
import { RecipientService, UsersService } from '@mediaseal-webui/services';
import { searchEncryptorUsers } from 'reducers/users/encryptorUsers';
import { RenderInput } from './RenderInput';

interface UserSelectionProps {
    validation?: (value: definitions['Recipient']) => boolean;
    setterFn: (value: { id: number | string }) => Promise<void>;
}

export const UserSelection = ({ setterFn }: UserSelectionProps) => {
    const { isDecryptorTab } = useAppSelector((state) => state.reporting);
    const dispatch = useAppDispatch();
    const [value, setValue] = useState<string>('');
    const service = useMemo(() => (isDecryptorTab ? new RecipientService() : new UsersService()), [isDecryptorTab]);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const { content: recipients } = useAppSelector((state) => state.recipients);
    const { content: users } = useAppSelector((state) => state.encryptorUsers);
    const options = isDecryptorTab ? (recipients as definitions['Recipient'][]) : users;

    const search = useCallback(
        async (val = '') =>
            dispatch(
                isDecryptorTab
                    ? searchRecipients({ service: service as RecipientService, keyword: val })
                    : searchEncryptorUsers({ service: service as UsersService, keyword: val })
            ),
        [dispatch, isDecryptorTab, service]
    );

    const handleInputChange = useCallback(
        (_: React.ChangeEvent<{}>, value: string) => {
            clearTimeout(timeoutRef.current as NodeJS.Timeout);
            setValue(value);
            if (value === '') {
                setterFn({ id: value });
            }
            setSubmitting(true);
            timeoutRef.current = setTimeout(async () => {
                await search(value);
            }, 300);
            setSubmitting(false);
        },
        [setterFn, search]
    );

    const handleSelection = useCallback(
        (_: React.ChangeEvent<{}>, value) => {
            if (value) {
                setterFn({ id: value.id });
                setValue((ps) => `${value?.firstName} ${value?.lastName}` ?? ps);
            }
        },
        [setterFn]
    );

    useEffect(() => {
        search();
        return () => {
            clearTimeout(timeoutRef.current as NodeJS.Timeout);
            setValue('');
        };
    }, [dispatch, isDecryptorTab, search]);

    const label = isDecryptorTab ? 'Recipients' : 'Users';

    return (
        <Autocomplete
            id='receipient-selection'
            // Handle input value change
            onInputChange={handleInputChange}
            //handle the selection change
            onChange={handleSelection}
            //handle the input value
            inputValue={value}
            //loading state
            loading={submitting}
            // loading text
            loadingText='Searching recipients...'
            // feed it title state items
            options={options}
            // No options text
            noOptionsText="Couldn't find any recipients.."
            //handle the input
            renderInput={RenderInput({ submitting, label })}
            //handle the options in dropdown
            // renderOption={RenderOption}
            getOptionSelected={(option, value) => option.firstName === value.firstName}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
            // set this to stop the filtering
            filterOptions={(options) => options}
            //handle close event
        />
    );
};
