import { SET_AUDIT_TAB, SET_REPORTING_FILTER } from '@mediaseal-webui/constants';
import { combineReducers } from 'redux';
import {
    SET_REPORT_DATA,
    SET_TOTAL_REPORT_PAGES,
    SET_MORE_REPORT_DATA,
    DISPLAY_ENTITY_SUMMARY,
    SET_REPORT_ELEMENTS,
    CLEAR_REPORT_DATA
} from '../../constants/reporting';
import { seedReportData } from '../../helpers';

export const data = (state = seedReportData(), { type, payload }) => {
    switch (type) {
        case SET_REPORT_DATA:
            return [...payload];
        case SET_MORE_REPORT_DATA:
            return [...state, ...payload];
        case CLEAR_REPORT_DATA:
            return seedReportData();
        default:
            return state;
    }
};

export const totalPages = (state = 0, { type, payload }) => {
    switch (type) {
        case SET_TOTAL_REPORT_PAGES:
            return payload;
        default:
            return state;
    }
};
export const totalElements = (state = 0, { type, payload }) => {
    switch (type) {
        case SET_REPORT_ELEMENTS:
            return payload;
        default:
            return state;
    }
};

export const showSummary = (state = false, { type, payload }) => {
    switch (type) {
        case DISPLAY_ENTITY_SUMMARY:
            return payload;
        default:
            return state;
    }
};

export const isDecryptorTab = (state = true, { type, payload }) => {
    switch (type) {
        case SET_AUDIT_TAB:
            return payload;
        default:
            return state;
    }
};

export const reportingFilter = (state = 0, { type, payload }) => {
    switch (type) {
        case SET_REPORTING_FILTER:
            return payload;
        default:
            return state;
    }
};

export default combineReducers({
    data,
    totalPages,
    showSummary,
    totalElements,
    isDecryptorTab,
    reportingFilter
});
