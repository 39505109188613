import { Title, EntityCreationInitialState } from '@mediaseal-webui/types';
import { useCallback, useContext } from 'react';
import { IEntityCreationContext, EntityCreationContext } from './context';
import { entityCreationInitialState } from './initialState';

const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$£€%&\'()*+,-./:;<=>?@[\\]^_`{|}~])(?=.{8,})');

interface ISetterPayload {
    key: keyof EntityCreationInitialState;
    value: string | Title;
    valid: boolean;
}
const setterPayload = ({ key, value, valid }: ISetterPayload) => ({
    [key]: {
        valid,
        value
    }
});
export const useEntityCreationValidators = () => {
    const { entityCreationContext, setEntityCreationContext } = useContext<IEntityCreationContext>(EntityCreationContext);

    const setter = (field: typeof entityCreationInitialState) =>
        setEntityCreationContext((prevState) => ({
            ...prevState,
            ...field
        }));

    const nameValidation = useCallback(
        (value: string, key: keyof EntityCreationInitialState = 'name') => {
            const min = 2;
            const max = 64;
            if (value.length < min || value.length > max) {
                setter(setterPayload({ key, valid: false, value }));
                return false;
            }
            setter(setterPayload({ key, valid: true, value }));
            return true;
        },
        [entityCreationContext]
    );

    const passwordValidation = useCallback(
        (value: string) => {
            if (strongRegex.test(value)) {
                setter(setterPayload({ key: 'password', valid: true, value }));
                return true;
            }
            setter(setterPayload({ key: 'password', valid: false, value }));
            return false;
        },
        [entityCreationContext]
    );

    const confirmPassword = useCallback(
        (value: string) => {
            if (entityCreationContext?.password?.valid && entityCreationContext.password.value === value) {
                setter(setterPayload({ key: 'confirmPassword', valid: true, value }));
                return true;
            }
            setter(setterPayload({ key: 'confirmPassword', valid: false, value }));
            return false;
        },
        [entityCreationContext]
    );

    const titleValidation = useCallback(
        (value: Title) => {
            if (value) {
                setter(setterPayload({ key: 'title', valid: true, value }));
                return true;
            }
            setter(setterPayload({ key: 'title', valid: false, value }));
            return false;
        },
        [entityCreationContext]
    );

    const emailValidation = useCallback(
        (value: string) => {
            const emailRegex =
                /^^[a-z0-9!#$%&'*/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
            if (!emailRegex.test(value)) {
                setter(setterPayload({ key: 'email', valid: false, value }));
                return false;
            }
            setter(setterPayload({ key: 'email', valid: true, value }));
            return true;
        },
        [entityCreationContext]
    );
    const jobPasswordValidation = useCallback(
        (value: string, score: number | null) => {
            if (score && score >= 3) {
                setter(setterPayload({ key: 'password', valid: true, value }));
                return true;
            } else {
                setter(setterPayload({ key: 'password', valid: false, value }));
                return false;
            }
        },
        [entityCreationContext]
    );

    const loginNameValidation = useCallback((value) => nameValidation(value, 'loginName'), [entityCreationContext]);
    const lastNameValidation = useCallback((value) => nameValidation(value, 'lastName'), [entityCreationContext]);
    const firstNameValidation = useCallback((value) => nameValidation(value, 'firstName'), [entityCreationContext]);
    const genericNameValidation = useCallback((value) => nameValidation(value, 'name'), [entityCreationContext]);

    return {
        nameValidation,
        passwordValidation,
        confirmPassword,
        titleValidation,
        emailValidation,
        loginNameValidation,
        lastNameValidation,
        firstNameValidation,
        jobPasswordValidation,
        genericNameValidation
    };
};
