import { useState, useEffect } from 'react';
import { JobFeature } from '@mediaseal-webui/types/jobs';
import { SelectOption } from '@mediaseal-webui/types/generic';
import { useSelector } from 'react-redux';
import { InitialState } from '@mediaseal-webui/types/redux';

interface IUseJobFeatures {
    isReauthEnabled: () => boolean;
    isRolloverEnabled: () => boolean;
    addReauthBehaviour: () => JobFeature[];
    removeReauthBehaviour: (features: JobFeature[]) => JobFeature[] | [];
    removeSecurePlayback: (features: JobFeature[]) => JobFeature[];
    addReauthTime: (option: SelectOption) => JobFeature[];
    toggleRollover: (event: React.ChangeEvent<HTMLInputElement>) => JobFeature[];
    addSecurePlayback: () => JobFeature[];
    toggleQRFeature: (state: boolean) => JobFeature[];
    hasSecurePlayer: () => boolean;
    hasQrCode: () => boolean;
    reauthTime: () => number;
}

export type EntityKey = 'profile' | 'create';

/**
 * For backwards compat.
 * If features value needs to be stateful, pass ${null} as first arg
 * @example
 * useJobFeatures(null)
 * useJobFeatures(null, 'create')
 */
export const useJobFeatures = (features: JobFeature[]) => {
    // const [feats, setFeats] = useState<JobFeature[]>(features)
    // useEffect(() => setFeats(features), [features])
    const isReauthEnabled = () => features?.some((feature: JobFeature) => feature.type === 'ReauthBehaviour');
    const isRolloverEnabled = () => features?.some((feature: JobFeature) => feature.type === 'ReauthBehaviour' && feature.data.rollover);
    const addReauthBehaviour = () => [
        ...(features ?? []),
        {
            type: 'ReauthBehaviour',
            data: {
                reauthorisationTime: 28800,
                rollover: true
            }
        }
    ];

    /**
     *
     * Passing in features here so we can ensure most recent features
     */

    const removeReauthBehaviour = (features: JobFeature[]) => [...features?.filter((feature) => feature.type !== 'ReauthBehaviour')];
    const removeSecurePlayback = (features: JobFeature[]) => [...features?.filter((feature: JobFeature) => feature.type !== 'SecurePlayback')];

    const addReauthTime = (option: SelectOption) => [
        ...features.map((feature: JobFeature) =>
            feature.type === 'ReauthBehaviour'
                ? {
                      ...feature,
                      data: {
                          ...feature.data,
                          reauthorisationTime: option.value
                      }
                  }
                : feature
        )
    ];
    const toggleRollover = (event: React.ChangeEvent<HTMLInputElement>) => [
        ...features.map((feature: JobFeature) =>
            feature.type === 'ReauthBehaviour'
                ? {
                      ...feature,
                      data: {
                          ...feature.data,
                          rollover: event.target.checked
                      }
                  }
                : feature
        )
    ];
    const toggleQRFeature = (state: boolean) => [
        ...features.map((feature: JobFeature) =>
            feature.type === 'SecurePlayback'
                ? {
                      ...feature,
                      data: {
                          ...feature.data,
                          qr: state
                      }
                  }
                : feature
        )
    ];
    const addSecurePlayback = () => [
        ...(features ?? []),
        {
            type: 'SecurePlayback',
            data: {
                qr: false
            }
        }
    ];
    const hasSecurePlayer = (): boolean => features?.some((feature: JobFeature) => feature.type === 'SecurePlayback');
    const hasQrCode = (): boolean => features?.some((feature: JobFeature) => feature.type === 'SecurePlayback' && feature.data.qr);
    const reauthTime = () => features?.find((feature) => feature.type === 'ReauthBehaviour')?.data.reauthorisationTime;
    return {
        isReauthEnabled,
        isRolloverEnabled,
        addReauthBehaviour,
        removeReauthBehaviour,
        addReauthTime,
        toggleRollover,
        addSecurePlayback,
        toggleQRFeature,
        hasSecurePlayer,
        hasQrCode,
        removeSecurePlayback,
        reauthTime
    } as IUseJobFeatures;
};
