import React, { ChangeEvent, useMemo } from 'react';
import { Card, IconButton, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSettingsCardData } from '@mediaseal-webui/hooks';
import { useEmailStyles } from './EmailSettingsCard.style';
import { SettingsCardData } from '@mediaseal-webui/types/settings';
import { useAppSelector } from 'store';
import { Add } from '@material-ui/icons';
import clsx from 'clsx';
import { Input } from '../Input/Input';
import { sendEmailInvite } from '@mediaseal-webui/actions/src';
import { InitialState } from '@mediaseal-webui/types';

export interface SettingsCardProps {
    cardData: SettingsCardData;
    Component?: React.ElementType | null;
}

const EmailSettingsCard = ({ cardData, Component }: SettingsCardProps) => {
    const [input, setInput] = useState('');

    const classes = useEmailStyles();
    const statefulData = useSettingsCardData(cardData);
    const dispatch = useDispatch();
    const { items: permissions } = useAppSelector((state) => state.permissions);
    const { emails } = useAppSelector((state: InitialState) => state.settings);
    const canView = useMemo(
        () => (cardData.permissions ? cardData.permissions.every((perm) => permissions.some((p) => p.permissionName === perm)) : true),
        [permissions, cardData.permissions]
    );

    const classNames = clsx(classes.actionButtons, classes.editIcon, 'Add email button');

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setInput(event.target.value);
    };

    const onSubmit = () => {
        dispatch(sendEmailInvite(input ?? ''));
        setInput('');
    };

    const hasError = (email: string | undefined): boolean => {
        if (!/\S+@\S+\.\S+/.test(email ?? '') && input.length > 0) {
            return true;
        } else {
            return false;
        }
    };

    return canView && Component ? (
        <>
            <Card elevation={0} className={classes.settingsCard}>
                <div className={classes.headingContainer}>
                    <Typography color='textPrimary' className={classes.heading} variant='h5'>
                        {statefulData.heading}
                        <span> ({emails?.invites?.length}/3)</span>
                    </Typography>
                    <Typography color='textPrimary' variant='body1'>
                        {statefulData.description}
                    </Typography>
                </div>
                <div className={classes.formField}>
                    <div className={classes.input}>
                        <Input hasError={hasError} value={input} handleChange={handleChange} onSubmit={onSubmit} />
                        <IconButton data-testid='add-email-address' className={classNames} onClick={onSubmit} disabled={hasError(input) || input.length === 0}>
                            <Add />
                        </IconButton>
                    </div>
                    <Component />
                </div>
            </Card>
        </>
    ) : null;
};
export { EmailSettingsCard };
