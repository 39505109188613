import { makeStyles, fade } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    '@global': {
        'div:focus': {
            outline: 'none !important'
        },
        '.ReactVirtualized__Table__Grid': {
            scrollBehavior: 'smooth !important'
        }
    },
    root: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'hidden',
        boxShadow: 'none'
    },
    mainTableWrapper: {
        width: '100%'
    },
    centered: {
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(2.5)
        }
    },
    table: {
        minWidth: 650,
        borderCollapse: 'collapse',
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%'
        }
    },
    alignFlexEnd: {
        justifyContent: 'flex-end'
    },
    alignCentred: {
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(2.5)
        }
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box'
    },
    tableCell: {
        flex: 1,
        borderBottom: 'unset',
        padding: '0 10px',
        [theme.breakpoints.down('sm')]: {
            padding: 5
        }
    },
    tableRow: {
        borderBottom: `1px solid ${theme.palette.table.main}`,

        borderRight: `1px solid ${theme.palette.grey[400]}`,
        background: theme.palette.background.paper,
        '&:focus': {
            outline: 'none !important'
        },
        [theme.breakpoints.down('sm')]: {
            height: 75
        }
    },
    tableRowHover: {
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            boxShadow: `inset 0px 11px 8px -10px ${fade('#2f9eb3', 0.25)}, inset 0px -11px 8px -10px ${fade('#2f9eb3', 0.25)}`
        }
    },
    link: {
        color: '#2a2d3e'
    },
    linkCell: {
        width: '100%'
    },
    mobilePadding: {
        [theme.breakpoints.down('sm')]: {
            padding: '10px 5px 10px 0',
            width: '5%'
        }
    },
    tableBody: {
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    navIcon: {
        color: '#2f9eb3'
    },
    selected: {
        boxShadow: 'inset 0px 0px 14px 1px rgba(0,0,0,0.36)',
        padding: '3px 0px 3px 3px',
        borderRadius: 5,
        margin: '5px 1px 3px 0px',
        animation: '$pulse 2s infinite'
    },
    '@keyframes pulse': {
        '0%': {
            boxShadow: 'inset 0 0 0 0 rgba(105,22,122,0.7)'
        },
        '70%': {
            boxShadow: 'inset 0 0 0 5px rgba(105,22,122,0)'
        },
        '100%': {
            boxShadow: 'inset 0 0 0 0 rgba(105,22,122,0)'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    subtitleIcon: {
        width: 15,
        display: 'inline-flex'
    },
    authSubtitle: {
        display: 'flex',
        alignItems: 'center'
    },
    iconButton: {
        opacity: 0.5,
        '&:hover': {
            opacity: 1
        }
    }
}));
