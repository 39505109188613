// https://github.com/bjerkio/oidc-react/blob/master/guides/QUICKSTART.md

import React, { FC } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { keycloak } from './keycloak';
const loader = <div />;
const KeycloakProvider: FC = ({ children }) => {
    return (
        <ReactKeycloakProvider
            LoadingComponent={loader}
            authClient={keycloak}
            initOptions={{
                pkceMethod: 'S256'
            }}>
            {children}
        </ReactKeycloakProvider>
    );
};

export default KeycloakProvider;
