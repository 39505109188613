import styled, { css, keyframes } from 'styled-components';
import { InputProgressProps } from './InputProgress';

export const StyledBaseCircle = styled.circle`
    fill: transparent;
    stroke: #eee;
    stroke-width: 3;
`;

export const StyledCircle = styled.circle`
    fill: transparent;
    stroke: #eee;
    stroke-width: 3;
    stroke-dasharray: 534;
    transition: stroke-dashoffset 1s;
    transform: rotate(270deg);
    transform-origin: center;
`;
const fullTing = keyframes`
  from {
    stroke-dashoffset: 487;
  }

  to {
    stroke-dashoffset: 450;
}

`;
const showThreeQuarters = keyframes`
  from {
    stroke-dashoffset: 502;
  }
  
  to {
    stroke-dashoffset: 487;
  }
`;
const showFifty = keyframes`
  from {
    stroke-dashoffset: 518;
  }
  
  to {
    stroke-dashoffset: 502;
  }
`;
const showQuarter = keyframes`
  from {
    stroke-dashoffset: 537;
  }
  
  to {
    stroke-dashoffset: 518;
  }
`;
const quarter = css`
    stroke: #ff6666;
    stroke-dashoffset: 518;
    animation: ${showQuarter} 0.35s;
`;
const half = css`
    stroke: #ff6666;
    stroke-dashoffset: 502;
    animation: ${showFifty} 0.35s;
`;
const threeQuarters = css`
    stroke: #ffb366;
    stroke-dashoffset: 487;
    animation: ${showThreeQuarters} 0.35s;
`;
const oneHundy = css`
    stroke: #007e9c;
    stroke-dashoffset: 450;
    animation: ${fullTing} 0.35s;
`;

interface FigureProps {
    score: number | null;
}
export const StyledFigure = styled.figure<FigureProps>(
    ({ theme, score }) => css`
        position: relative;
        display: inline-block;
        color: #999;
        font-size: 20px;
        text-align: center;
        width: 20px;
        height: 20px;
        margin: 0;
        ${StyledCircle} {
            ${score ? (score === 4 ? oneHundy : score === 3 ? threeQuarters : score === 2 ? half : score === 1 ? quarter : '') : ''};
        }
    `
);
