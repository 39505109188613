import React from 'react';
import { Typography } from '@material-ui/core';
import { ReactComponent as Logo } from './MediasealAlt.svg';
import useStyles from './LargeLogo.styles';

export interface LargeLogoProps {
    style?: {};
    size?: number;
}
export const LargeLogoAlt = ({ style, size = 250 }: LargeLogoProps) => {
    const classes = useStyles();
    return (
        <div className={classes.container} style={style}>
            <Logo className={classes.logo} width={size} height={size} />
            <Typography color='textPrimary' variant='h4'>
                MediaSeal
            </Typography>
            <Typography color='textPrimary' variant='body1'>
                Seamless content security
            </Typography>
        </div>
    );
};

export default LargeLogoAlt;
