/* eslint import/no-webpack-loader-syntax: off */
import report from 'workerize-loader!../../workers/report';
import { useSelector } from 'react-redux';

const reportWorker = report();

export const useReportWorker = () => {
    const { username, password } = useSelector((state) => state.misc.userCredentials);
    return {
        reportWorker,
        username,
        password
    };
};
