import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.action.hover,
        minHeight: theme.sizes.summaryList.desktopHeight,
        // width: '350px',
        paddingTop: theme.spacing(2),
        borderRadius: 20,
        maxHeight: '60vh',
        overflowY: 'scroll',
        position: 'sticky',
        top: `calc(${theme.sizes.header.open}px + 20px)`,
        paddingLeft: 25,
        [theme.breakpoints.down('sm')]: {
            marginTop: 50,
            marginBottom: 50,
            padding: theme.spacing(3),
            width: '100%',
            maxHeight: '100%'
        }
    },
    avatarContainer: {
        marginLeft: '40%'
    },
    avatarHead: {
        width: 80,
        height: 80
    },
    label: {
        color: 'white'
    },
    avatar: {
        fontSize: '1.5em',
        color: theme.palette.primary.light,
        display: 'block',
        width: 30,
        height: 30
    },
    userList: {
        width: '100%',
        height: '100%'
    },
    clearIconContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    noGutters: {
        padding: 0
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    listItem: {
        paddingRight: 30
    },

    paper: {
        position: 'relative',
        width: `calc(100% - ${theme.spacing(8)}px)`,
        height: `calc(100% - ${theme.spacing(30)}px)`,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
        marginTop: theme.spacing(9),
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4)
    },
    titleContainer: {
        zIndex: 10,
        display: 'flex',
        position: 'sticky',
        background: theme.palette.background.paper,
        paddingBottom: 15,
        marginLeft: -10,
        paddingLeft: 10,
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        alignContent: 'flex-end'
    },
    headerIcon: {
        marginRight: 5,
        width: 20,
        height: 14
    },
    bottomButtons: {
        width: `calc(100% - ${theme.spacing(8)}px)`,
        bottom: 0,
        height: theme.spacing(8),
        opacity: 1,
        zIndex: 5,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white'
    },
    tables: {
        maxHeight: `calc(100% - ${theme.spacing(11)}px)`,
        overflowY: 'scroll'
    },
    noResults: {
        opacity: 0.65,
        textAlign: 'center',
        width: '100%'
    }
}));

export default useStyles;
