import { useKeycloak } from '@react-keycloak/web';
import { KeycloakInstance } from 'keycloak-js';
import { isKeycloakActive } from '../isKeycloakActive';

type KeycloakHookType = {
    initialized: boolean;
    keycloak: KeycloakInstance;
};

export function useKeycloakConditionally(): KeycloakHookType | null {
    if (!isKeycloakActive()) {
        return null;
    }
    // Its acceptable to call this conditionally called hook as it is a buildtime condition.
    //eslint-disable-next-line
    return useKeycloak();
}
