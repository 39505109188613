import React, { FC } from 'react';
import { InputTooltip } from '..';
import { InputTooltipProps } from '../InputTooltip/InputTooltip';
import { PasswordPoints } from './PasswordTooltip.style';

export interface PasswordTooltipProps extends InputTooltipProps {
    /** Passed through from the TextInput. Just hides the tooltip for now */
    disabled?: boolean;
}

const title = 'Good passwords contain:';

export const PasswordTooltip: FC<PasswordTooltipProps> = ({ show, disabled }) => {
    return (
        <InputTooltip title={title} show={show && !disabled} above>
            <PasswordPoints>
                <li>a minimum eight characters</li>
                <li>one uppercase and lowercase letter</li>
                <li>one number and one special character</li>
                <li>Uncommon words or phrases such as, inside jokes or memorable events</li>
            </PasswordPoints>
        </InputTooltip>
    );
};
