import { MediaSealState, SpringBootResponse } from '@mediaseal-webui/types';
import { definitions } from '@mediaseal-webui/types/api';
import { AxiosRequestConfig } from 'axios';
import { BaseService } from '..';

//we cannot use : <SpringBootResponse<definitions['User']> beacuse its a user projection, example contains userId not id.
export interface ApiUser {
    active: boolean;
    contactNumber: string;
    email: string;
    external?: boolean;
    firstName: string;
    lastName: string;
    loginName: string;
    id: number;
}
export class UsersService extends BaseService<ApiUser, {}> {
    constructor(public config?: AxiosRequestConfig) {
        super('/users', config);
    }

    public async fetchUser(username: string) {
        return await this.get(`/search/findByLoginName?loginName=${username}`);
    }

    public async fetchUsers() {
        return await this.get(`/search/findAllContainingKeyword${this._createQueryString(this.baseParams)}`);
    }

    public async searchUsers(keyword: string) {
        this.baseParams = {
            ...this.baseParams,
            keyword
        };
        return await this.fetchUsers();
    }
}
