import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { InitialState } from '@mediaseal-webui/types/redux';

/**
 * Checks an input versiion against the current engine to determine if a component should load.
 * Is included at the job view level and checks against every job
 */
export interface UseJobCompatibleProps {
    additionalDataVersion: number;
    headerVersion: number;
}
export const useJobCompatible = ({ additionalDataVersion = 4, headerVersion = 5 }: UseJobCompatibleProps) => {
    const [isCompatible, setIsCompatible] = useState<boolean>(true);
    const { applicationInformation } = useSelector((state: InitialState) => state.versioning);

    useEffect(() => {
        applicationInformation.maxAdditionalDataVersion >= additionalDataVersion && applicationInformation.maxHeaderVersion >= headerVersion
            ? setIsCompatible(true)
            : setIsCompatible(false);
        return () => {
            setIsCompatible(false);
        };
    }, [additionalDataVersion, headerVersion, applicationInformation]);
    return {
        isCompatible
    };
};
