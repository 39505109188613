import React, { useEffect, useState } from 'react';
import { Checkbox, Typography } from '@material-ui/core';
import { setCreate } from '@mediaseal-webui/actions';
import { useJobFeatures } from '@mediaseal-webui/hooks';
import { InitialState } from '@mediaseal-webui/types/redux';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './QrOverlay.style';

export const QrOverlay = () => {
    const classes = useStyles();
    const [active, setActive] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { features, template } = useSelector((state: InitialState) => state.entity.create);
    const { toggleQRFeature } = useJobFeatures(features);

    const handleQRSelection = () => {
        setActive(!active);
        dispatch(
            setCreate({
                features: toggleQRFeature(!active)
            })
        );
    };

    useEffect(() => {
        template && setActive(false);
    }, [template]);
    return (
        <>
            <Checkbox className={classes.qrCheckBox} checked={active} onChange={handleQRSelection} />
            <Typography variant='body2' color='textPrimary' style={{ display: 'flex', alignItems: 'center' }}>
                Enable QR Watermark protection
            </Typography>
        </>
    );
};
