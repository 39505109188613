import React from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { LinearProgress, Avatar, Divider } from '@material-ui/core';
import useStyles from './WidgetItem.styles';
import { ResumeBtn } from '../ResumeBtn';
import { PauseBtn } from '../PauseBtn';
import { CancelBtn } from '../CancelBtn';
import { RemoveBtn } from '../RemoveBtn';
import { LinkToJob } from '../LinkToJob/LinkToJob';
import { EncryptFile } from '@mediaseal-webui/types/encryptor';

interface WidgetItemProps {
    file: EncryptFile;
}
export const WidgetItem = ({ file }: WidgetItemProps) => {
    const { jobId } = file;
    const classes = useStyles();
    return (
        <div className={classes.widgetItem}>
            <Divider />
            <div className='fileAvatar'>
                <Avatar className={classes.avatar}>
                    <AssignmentIcon />
                </Avatar>
            </div>
            <div className='encryptionInfoItem'>
                <p className={classes.summary}>{file.inputFileName}</p>
                <p className='percentage'>{file.percentage}%</p>
                <div className='progressContainer'>
                    <LinearProgress variant='determinate' color='secondary' value={file.percentage} className='progress' />
                </div>
            </div>
            <div className='fileActions'>
                {file.cancelled || file.error || file.done ? (
                    <RemoveBtn file={file} />
                ) : file.paused ? (
                    <>
                        <ResumeBtn file={file} />
                        <CancelBtn file={file} />
                    </>
                ) : (
                    <>
                        <PauseBtn file={file} />
                        <CancelBtn file={file} />
                    </>
                )}
                <LinkToJob jobId={jobId} />
            </div>
        </div>
    );
};
