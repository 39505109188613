import { AccountService, UsersService, ApiUser } from '@mediaseal-webui/services';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isKeycloakActive } from '@mediaseal-webui/hooks/src/isKeycloakActive';

const service = new AccountService();
const userService = new UsersService();

export const fetchUserProfile = createAsyncThunk('user/fetchUserProfile', async (_, { rejectWithValue, getState }) => {
    try {
        let username = '';

        if (isKeycloakActive()) {
            const kcData = await service.getUser();
            if (typeof kcData === 'undefined') {
                return rejectWithValue('Could not get user login information');
            }
            username = kcData.username as string;
        } else {
            const state: any = getState();
            username = state.misc.userCredentials.username;
        }

        const data: ApiUser = await userService.fetchUser(username);
        return { ...data.data };
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export interface UserAction {
    type: string;
    payload: IUser;
}

export interface IUser {
    sub: string;
    pending: boolean;
    fulfilled: boolean;
    external?: boolean;
    apiUser?: ApiUser;
}

const initialUserState = {
    sub: '',
    pending: false,
    fulfilled: false
} as IUser;
export const userSlice = createSlice({
    initialState: initialUserState,
    name: 'user',
    reducers: {},
    extraReducers: (builder) => {
        // The `builder` callback form is used here because it provides correctly typed reducers from the action creators
        builder.addCase(fetchUserProfile.fulfilled, (state, { payload }) => {
            return {
                ...state,
                apiUser: payload,
                pending: false,
                fulfilled: true
            };
        });
        builder.addCase(fetchUserProfile.pending, (state) => {
            return {
                ...state,
                pending: true
            };
        });
    }
});
//eslint-disable-next-line import/no-anonymous-default-export
export default {
    userSlice
};
