import { useLocation } from 'react-router';
import { Permission, AppAuth, InitialState } from '@mediaseal-webui/types';
import { useAppSelector } from '@mediaseal-webui/online/src/store';
export enum Actions {
    view = 'view',
    edit = 'edit',
    create = 'create',
    administer = 'administer'
}

const matcher = (permission: Permission, path: string): boolean => new RegExp(path, 'ig').test(permission.permissionName);

const permFrom =
    (path: string) =>
    (permission: Permission): boolean =>
        matcher(permission, path);

export const useAuth = (path?: string): AppAuth => {
    const location = useLocation();
    // check all names
    const pathnames: string[] = (path ?? location.pathname).split('/');
    const firstPath: string = pathnames[1];
    const lastPath: string = pathnames[pathnames.length - 1];
    const metaPath: string[] = ['search', 'settings'];
    let finalPermissions: Permission[] = [];
    const { items: permissions } = useAppSelector((state) => state.permissions);
    // const permissions: Permission[] = useSelector((state: InitialState) => state.misc.permissions)
    // const filteredPermissions: any = permissions.find(permFrom(path))
    // check pathnames length, if > 2 && === encryptor or decryptor then filter again
    // '', 'jobs', ''
    if (metaPath.includes(firstPath)) {
        return {
            view: true
        } as AppAuth;
    }
    if (pathnames.length > 2 && (lastPath === 'encryptor' || lastPath === 'decryptor')) {
        const filteredPermissions: Permission[] = permissions.filter(permFrom(firstPath));
        const refiltered: Permission[] = filteredPermissions.filter(permFrom(lastPath));
        finalPermissions = refiltered;
    } else {
        const filteredPermissions: Permission[] = permissions?.filter(permFrom(firstPath));
        finalPermissions = filteredPermissions;
    }
    const filter = finalPermissions.reduce<AppAuth>((acc, curr, i): any => {
        for (const action in Actions) {
            if (matcher(curr, action)) {
                return {
                    ...acc,
                    [action]: true
                };
            }
        }
        return acc;
    }, {} as AppAuth);

    return filter;
};
