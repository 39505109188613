import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    titlePadding: {
        marginBottom: theme.spacing(0.5)
    },
    formGroup: {
        marginTop: theme.spacing(2),
        marginBottom: 0
    },
    inputContainer: {
        marginTop: theme.spacing(2)
    }
}));

export default useStyles;
