import { FailFlag, PassFlag } from '@mediaseal-webui/types/redux';

export * from './email';
export * from './password';
export * from './generic';

export const Pass: PassFlag = { passes: true };

export const Fail = (message: string | JSX.Element): FailFlag => ({
    message,
    passes: false
});

export const passing = () => () => Pass;
export const failing = (message: string | JSX.Element) => () => () => Fail(message);
