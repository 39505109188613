import React, { useCallback, useEffect, useState } from 'react';
import { Fade, Button, Dialog, DialogActions, useMediaQuery } from '@material-ui/core';
import SimpleSelect, { SelectOption } from '../../utility-components/SimpleSelect';
import { useTheme } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import { ActionsFilterProps } from '.';
import { useStyles } from './ActionsFilter.styles';
import { useMobile } from '@mediaseal-webui/hooks';
import { ReportingActions } from '@mediaseal-webui/types';
import { useAppSelector } from 'store';
import { useDispatch } from 'react-redux';
import { SET_REPORTING_FILTER } from '@mediaseal-webui/constants';

const decryptorOptions: ReportingActions[] = [
    { id: 0, name: 'Attempted', query: 'ANY', url: '/fileAccess', value: 'Attempted' },
    { id: 1, name: 'Denied', query: 'DENIED', url: '/fileAccess', value: 'Denied' },
    { id: 2, name: 'Granted', query: 'GRANTED', url: '/fileAccess', value: 'Granted' },
    { id: 3, name: 'Saved File with Open Assets', url: '/fileSave', query: '', value: 'Saved File with Open Assets' }
];

const encryptorOptions: ReportingActions[] = [
    { id: 0, name: 'Created', query: 'CREATE', url: '/activity', value: 'Created' },
    { id: 1, name: 'Updated', query: 'UPDATE', url: '/activity', value: 'Updated' },
    { id: 2, name: 'Deleted', query: 'DELETE', url: '/activity', value: 'Deleted' },
    { id: 3, name: 'Login', query: 'LOGIN', url: '/activity', value: 'Login' },
    { id: 4, name: 'Login Granted', query: 'LOGIN_GRANTED', url: '/activity', value: 'Login Granted' },
    { id: 5, name: 'Login Denied', query: 'LOGIN_DENIED', url: '/activity', value: 'Login Denied' },
    { id: 6, name: 'Logout', query: 'LOGOUT', url: '/activity', value: 'Logout' }
];

const ActionsFilter = ({ setterFn, responsive }: ActionsFilterProps) => {
    const { isDecryptorTab, reportingFilter } = useAppSelector((state) => state.reporting);
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const mobile = useMobile();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const dispatch = useDispatch();
    const [selectedValue, setSelectedValue] = useState<ReportingActions>(
        isDecryptorTab ? decryptorOptions[reportingFilter] : encryptorOptions[reportingFilter]
    );
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        setSelectedValue(isDecryptorTab ? decryptorOptions[reportingFilter] : encryptorOptions[reportingFilter]);
    }, [reportingFilter, isDecryptorTab]);

    const onChange = useCallback(
        (option: ReportingActions | SelectOption) => {
            dispatch({ type: SET_REPORTING_FILTER, payload: option.id });
            setterFn(option as ReportingActions);
            setSelectedValue(isDecryptorTab ? (decryptorOptions[option.id] as ReportingActions) : (encryptorOptions[option.id] as ReportingActions));
        },
        [setterFn]
    );

    if (responsive) {
        return (
            <div>
                <Fade in timeout={1000}>
                    <Button size='large' onClick={handleOpen} startIcon={<DescriptionIcon />} disableElevation>
                        access
                    </Button>
                </Fade>
                <Dialog
                    PaperProps={{
                        className: classes.dialogue
                    }}
                    onClose={handleClose}
                    aria-labelledby='access-type'
                    open={open}>
                    <SimpleSelect
                        options={isDecryptorTab ? decryptorOptions : encryptorOptions}
                        setter={onChange}
                        value={selectedValue}
                        defaultValue={0}
                        width={mobile ? 300 - theme.spacing(2) : 300}
                        label='Access'
                    />
                    <DialogActions>
                        <Button fullWidth={matches} onClick={handleClose}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
    return <SimpleSelect options={isDecryptorTab ? decryptorOptions : encryptorOptions} value={selectedValue} setter={onChange} label='Access' />;
};

export default ActionsFilter;
