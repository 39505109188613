import React from 'react';
import { IconButton, Slide } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import RefreshIcon from '@material-ui/icons/Refresh';
import { RefreshAlertProps } from '.';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    alert: {
        marginBottom: theme.spacing(2),
        '& .MuiAlert-icon': {
            alignItems: 'center',
            '& > .MuiSvgIcon-root': {
                width: '1.25em',
                height: '1.25em'
            }
        }
    },
    refreshIcon: {
        color: theme.palette.info.main
    }
}));

export const RefreshAlert = ({ refreshFn, text, title, show }: RefreshAlertProps) => {
    const classes = useStyles();
    return show ? (
        <Slide in={show} timeout={1000} direction={show ? 'up' : 'down'}>
            <Alert
                severity='info'
                className={classes.alert}
                action={
                    <IconButton onClick={refreshFn}>
                        <RefreshIcon className={classes.refreshIcon} color='inherit' />
                    </IconButton>
                }>
                {title ? <AlertTitle>{title}</AlertTitle> : ''}
                {text}
            </Alert>
        </Slide>
    ) : null;
};
