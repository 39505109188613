interface IState<T> {
    page?: { totalElements?: number; totalPages?: number };
    content: T[];
    pending?: boolean;
}
interface IAction<T> {
    payload: {
        page?: { totalElements?: number; totalPages?: number };
        content: T[];
    };
}

const appendToPagedState = <T>(action: IAction<T>, state: IState<T>): IState<T> => ({
    ...state,
    page: {
        ...state.page,
        ...(action.payload?.page ?? {
            totalElements: 0,
            totalPages: 0
        })
    },
    content: [...state.content, ...action.payload.content],
    pending: false
});

export default appendToPagedState;
