import React, { useState } from 'react';
import { SideNavContext, ShowTabContext, EntityCreationContext, entityCreationInitialState } from '@mediaseal-webui/hooks';
import { QueryClient, QueryClientProvider } from 'react-query';
import { EntityCreationInitialState } from '@mediaseal-webui/types';
import { IconButton } from '@material-ui/core';
import { CalenderContext } from '@mediaseal-webui/components';
import { SnackbarProvider, ProviderContext, MaterialDesignContent } from 'notistack';
import { Close } from '@material-ui/icons';
import { ThemeProvider } from 'styled-components';
import { TabControlProvider, PasswordScoreProvider, useThemeSelection } from '@mediaseal-webui/hooks';
import { ThemeProvider as MaterialThemeProvider, StylesProvider, styled } from '@material-ui/core/styles';
import { AuditFileContextProvider } from '@mediaseal-webui/hooks/src/useAuditFileDrop/context';
import { IdentityProviderContextProvider } from 'hooks/useIdentityProviders/context';

export const ContextWrapper: React.FC = ({ children }) => {
    /**
     * useSideNav
     */
    const [sideNavOpen, setSideNavOpen] = useState<boolean>(true);

    /**
     *  useCalender
     */
    const [calenderShown, setCalenderShown] = useState<boolean>(false);

    /**
     * useShowTab
     */
    const [showTab, setShowTab] = useState<number>(0);

    /**
     * useEntityCreation
     */
    const [entityCreationContext, setEntityCreationContext] = useState<EntityCreationInitialState>(entityCreationInitialState);
    // add action to all snackbars
    const notistackRef = React.useRef<ProviderContext | null>(null);
    const { theme } = useThemeSelection();
    const queryClient = new QueryClient();
    const onClickDismiss = (key) => () => {
        if (notistackRef && notistackRef.current) {
            notistackRef.current.closeSnackbar(key);
        }
    };

    //snackbar styling customization

    const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
        '&.notistack-MuiContent-success': {
            backgroundColor: '#007E9C'
        },
        '&.notistack-MuiContent-error': {
            backgroundColor: '#ff6666'
        }
    }));

    return (
        <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
                <MaterialThemeProvider theme={theme}>
                    <SnackbarProvider
                        Components={{
                            success: StyledMaterialDesignContent,
                            error: StyledMaterialDesignContent
                        }}
                        ref={notistackRef}
                        action={(key) => (
                            <IconButton onClick={onClickDismiss(key)}>
                                <Close />
                            </IconButton>
                        )}
                        preventDuplicate
                        maxSnack={1}
                        autoHideDuration={3000}
                        anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'bottom'
                        }}>
                        <QueryClientProvider client={queryClient}>
                            <EntityCreationContext.Provider value={{ entityCreationContext, setEntityCreationContext }}>
                                <SideNavContext.Provider value={{ sideNavOpen, setSideNavOpen }}>
                                    <CalenderContext.Provider value={{ calenderShown, setCalenderShown }}>
                                        <TabControlProvider>
                                            <ShowTabContext.Provider value={{ showTab, setShowTab }}>
                                                <PasswordScoreProvider>
                                                    <AuditFileContextProvider>
                                                        <IdentityProviderContextProvider>{children}</IdentityProviderContextProvider>
                                                    </AuditFileContextProvider>
                                                </PasswordScoreProvider>
                                            </ShowTabContext.Provider>
                                        </TabControlProvider>
                                    </CalenderContext.Provider>
                                </SideNavContext.Provider>
                            </EntityCreationContext.Provider>
                        </QueryClientProvider>
                    </SnackbarProvider>
                </MaterialThemeProvider>
            </ThemeProvider>
        </StylesProvider>
    );
};
