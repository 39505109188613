import { EntityCreationInitialState } from '@mediaseal-webui/types';
import { useContext, useEffect, useState } from 'react';
import { IEntityCreationContext, EntityCreationContext } from './context';
import { useEntityCreationValidators } from './validators';

export * from './context';
export * from './initialState';
export { useEntityCreationValidators };

/**
 * @function useEntityCreation
 * @usage
 * ```
 *  const { valid, entityCreationContext, setEntityCreationContext } = useJobCreation()
 *  // this indicates all fields are true and valid -> complete the form
 *  if(valid){
 *  setFormComplete()
 * }
 *
 * // we can access the context of the forms validity and update
 *
 * const updateFn = (field: string) => setJobCreationContext(prevState => ({
 *      ...prevState,
 *      [field]: true
 *  }))
 * ```
 */

type UseEntityArgs = (keyof EntityCreationInitialState)[];

export const useEntityCreation = (fields?: UseEntityArgs) => {
    const { entityCreationContext, setEntityCreationContext } = useContext<IEntityCreationContext>(EntityCreationContext);
    const [valid, setValid] = useState<boolean>(false);
    const [isOneValid, setIsOneValid] = useState<boolean>(false);
    const validators = useEntityCreationValidators();

    useEffect(() => {
        setValid(Object.values(entityCreationContext).every((val) => val.valid));
        setIsOneValid(Object.values(entityCreationContext).some((val) => val.valid));
    }, [entityCreationContext]);

    useEffect(() => {
        fields &&
            setEntityCreationContext(
                fields.reduce(
                    (acc, name) => ({
                        ...acc,
                        [name]: {
                            valid: false,
                            value: ''
                        }
                    }),
                    {}
                )
            );
    }, []);

    return {
        entityCreationContext,
        setEntityCreationContext,
        valid,
        isOneValid,
        ...validators
    };
};
