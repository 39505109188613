import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { MainTable, MSSearchBar as SearchBar } from '@mediaseal-webui/components';
import { useSummaryFilter } from '@mediaseal-webui/hooks';
import { RecipientService } from '@mediaseal-webui/services';
import { GenericEntity } from '@mediaseal-webui/types';
import { addDecryptorUserToSummary } from 'actions/entity';
import { actionableUserHeader } from 'data';
import { useSearchWorker } from 'hooks';
import { useDispatch } from 'react-redux';
import { fetchRecipients, recipientSlice, searchRecipients } from 'reducers/users/recipients';
import { useAppSelector } from 'store';
import useStyles from '../../titles-components/creation-view-components/TabArea/TabArea.styles';
export const RecipientSelection = memo(() => {
    const [userPage, setUserPage] = useState(1);
    const classes = useStyles();
    const { searchWorker, token } = useSearchWorker();
    const { content: filteredUsers, page, searchValue, pending } = useAppSelector((state) => state.recipients);
    const { clearSearchValue, setSearchValue, clearUsers } = recipientSlice.actions;
    const { filteredEntities } = useSummaryFilter({
        entities: filteredUsers as GenericEntity[],
        type: 'decryptorUsers'
    });
    const service = useMemo(() => new RecipientService(), []);
    const dispatch = useDispatch();
    const handleDecUserClick = useCallback((user) => dispatch(addDecryptorUserToSummary(user)), [dispatch]);
    const fetchNextPage = useCallback(async () => {
        if (service.baseParams.page <= (page?.totalPages ?? 0)) {
            service.setParams({
                page: service.baseParams.page + 1
            });
            await dispatch(fetchRecipients(service));
        }
    }, [dispatch, page?.totalPages, service]);
    useEffect(() => {
        dispatch(searchRecipients({ service, keyword: '' }));
        return () => {
            dispatch(clearUsers());
        };
    }, [dispatch, service, clearUsers]);
    return (
        <div className={classes.stack}>
            <SearchBar
                id='search'
                setPage={setUserPage}
                searchUtils={{
                    searchFn: searchWorker.searchDecryptorUsers,
                    token
                }}
                value={searchValue}
                clearSearchValue={clearSearchValue}
                setSearchValue={setSearchValue}
                placeholder='Search for a user'
            />
            <MainTable
                id='decUsersTable'
                entities={filteredEntities}
                isFetching={pending}
                searchValue={searchValue}
                pageControl={{ page: userPage, setPage: setUserPage }}
                fetchFn={fetchNextPage}
                totalPages={page?.totalPages}
                columns={actionableUserHeader}
                noResultsMessage='No recipients returned'
                filterFetch
                onRowClick={handleDecUserClick}
            />
        </div>
    );
});
