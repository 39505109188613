import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    viewRoot: {
        [theme.breakpoints.down('xs')]: {
            overflow: 'hidden'
        }
    },

    tabPanel: {},

    additionalItems: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        right: theme.spacing(2),
        bottom: theme.spacing(2),
        transition: `all 0.5s ease`
    },

    tabSection: {
        background: theme.palette.background.default,
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: '4px 4px 0 0',
        width: '100%',
        position: 'fixed',
        zIndex: 1,
        marginLeft: '-18px',
        marginTop: '-25px'
    }
}));

export default useStyles;
