import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import MenuBook from '@material-ui/icons/MenuBookTwoTone';
import { Typography } from '@material-ui/core';
import useStyles from './NoResults.styles';
import { NoResultsProps } from '.';

export const NoResults = React.memo(({ message = "Sorry we can't find anything matching that search" }: NoResultsProps) => {
    const classes = useStyles();

    return (
        <div className={classes.centered}>
            <div className={classes.icon}>
                <SearchIcon classes={{ root: classes.search }} />
                <MenuBook classes={{ root: classes.book }} />
            </div>
            <Typography color='textPrimary' component='div' variant='body2' className='errorMessage' classes={{ root: classes.errorMessage }}>
                {message}
            </Typography>
        </div>
    );
});
