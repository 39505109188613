import React from 'react';
import { useDispatch } from 'react-redux';
import { openJobHelpModal } from '../../../actions/jobs';
import { Typography, Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import { Person } from '@material-ui/icons';
import DnsIcon from '@material-ui/icons/Dns';
import useStyles from './SecurityModal.styles';
import clsx from 'clsx';
import { useConfirmDialogue } from '@mediaseal-webui/hooks';

interface securityModalProps {
    open: boolean;
}

const SecurityModal = ({ open }: securityModalProps) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const handleClose = () => {
        dispatch(openJobHelpModal(false));
    };
    const { openDialog } = useConfirmDialogue();

    const handleSelected = (authType: string) => () => {
        openDialog(authType);
        dispatch(openJobHelpModal(false));
    };

    return (
        <Dialog
            aria-labelledby='security-options-modal'
            aria-describedby='a-simple-modal-to-explain-security-options'
            open={open}
            onClose={handleClose}
            maxWidth='lg'>
            <DialogContent>
                <div className={classes.modalWrapper}>
                    <div className={classes.thirds}>
                        <EnhancedEncryptionIcon fontSize='large' className={classes.icon} />
                        <Typography color='textPrimary' variant='h6'>
                            One Factor Authentication
                        </Typography>
                        <Typography color='textPrimary' variant='overline'>
                            (Password Only)
                        </Typography>
                        <DialogContentText>
                            Our simplest form of securing your content. Set the password for the current job. To gain file access, MediaSeal user requires a
                            password. Can be used in all workflows. File audit capabilities and file revocation is not possible.
                        </DialogContentText>
                        <div className={classes.buttonWrapper}>
                            <Button className={classes.button} onClick={handleSelected('password')} color='primary' size='large'>
                                Choose This Method
                            </Button>
                        </div>
                    </div>
                    <div className={clsx([classes.thirds, classes.borders])}>
                        <EnhancedEncryptionIcon fontSize='large' className={classes.icon} />
                        <Person fontSize='large' className={classes.icon} />
                        <Typography color='textPrimary' variant='h6'>
                            Two Factor Authentication
                        </Typography>
                        <Typography color='textPrimary' variant='overline'>
                            (Password + User)
                        </Typography>
                        <DialogContentText>
                            A MediaSeal user must be assigned file access via job creation. Access is granted to an encrypted file if they have the set password
                            and can prove they are an assigned user (via a physical iLok or soft license). Recommended for air-gapped networks where server
                            authentication is not possible. File audit and file revocation is not possible.
                        </DialogContentText>
                        <div className={classes.buttonWrapper}>
                            <Button className={classes.button} color='primary' size='large' onClick={handleSelected('offline')}>
                                Choose This Method
                            </Button>
                        </div>
                    </div>
                    <div className={classes.thirds}>
                        <EnhancedEncryptionIcon fontSize='large' className={classes.icon} />
                        <Person fontSize='large' className={classes.icon} />
                        <DnsIcon fontSize='large' className={classes.icon} />
                        <Typography color='textPrimary' variant='h6'>
                            Three Factor Authentication
                        </Typography>
                        <Typography color='textPrimary' variant='overline'>
                            (Password + User + Server Auth)
                        </Typography>
                        <DialogContentText>
                            A MediaSeal user must be assigned file access via job creation. MediaSeal user must be connected to our Global Services platform to
                            verify file access is authorized upon each file access attempt. Access is only granted if user has the set password and can prove
                            they are an assigned user (via a physical iLok or soft license). File audit, file revocation and sunset/sunrise options are
                            available.
                        </DialogContentText>
                        <Typography color='textPrimary' variant='caption'>
                            **Requires an active server connection to authenticate
                        </Typography>
                        <div className={classes.buttonWrapper}>
                            <Button className={classes.button} color='primary' size='large' onClick={handleSelected('online')}>
                                Choose This Method
                            </Button>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color='primary'>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SecurityModal;
