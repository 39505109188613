import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    '@global': {
        body: {
            backgroundColor: `${theme.palette.background.default} !important`,
            color: 'black'
        },
        a: {
            color: theme.palette.primary.main,
            textDecoration: 'none'
        }
    },
    pageContainer: {
        width: '100%',
        height: '100vh',
        transition: 'all 1s',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        overflow: 'hidden'
    },

    // logo
    logoContainer: {
        position: 'absolute',
        opacity: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transform: 'scale(1)', // start at normal size
        transition: 'all 1s',
        '@media (min-height: 850px)': {
            top: 'unset',
            bottom: '28%',
            opacity: 1
        },
        '@media (min-width: 1200px)': {
            right: '20%',
            left: 'unset',
            bottom: 0,
            top: 0,
            opacity: 1
        },
        '@media (min-width: 1200px) and (min-height: 800px)': {
            right: 400
        }
    },
    smallLogoWrap: {
        opacity: 0,
        position: 'absolute',
        top: 20,
        transition: 'all 1s',
        display: 'flex',
        justifyContent: 'center',
        left: 0,
        right: 0,
        '@media (max-height: 550px)': {
            left: 20,
            right: 'unset'
        },
        '@media (max-height: 849px) and (max-width: 1199px)': {
            opacity: 1
        }
    },

    // login form
    loginFormContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all 1s',
        transitionDelay: '1s',
        '@media (min-height: 850px)': {
            top: '20%'
        },
        '@media (min-width: 1200px)': {
            top: 0,
            left: '20%'
        },
        '@media (min-height: 800px) and (min-width: 1200px)': {
            backgroundColor: theme.palette.background.primary,
            width: 400,
            left: '65%'
        },
        '@media (min-width: 3000px)': {
            width: '35%'
        }
    },
    loginForm: {
        display: 'flex',
        flexWrap: 'wrap',
        width: 360
    },
    formElements: {
        marginBottom: 39,
        height: 50,
        flex: '0 0 100%',
        zIndex: 40
    },
    button: {
        height: 50,
        marginBottom: 39,
        borderRadius: '30px',
        background: `linear-gradient(90deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.light} 100%)`,
        '&:hover': {
            background: `linear-gradient(90deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.dark} 100%)`
        },
        '&:active': {
            background: `linear-gradient(90deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.light} 100%)`
        }
    },
    buttonProgress: {
        color: 'white',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },

    // snackbar
    snackbar: {
        margin: theme.spacing(1),
        background: theme.palette.background.paper,
        color: 'black'
    },
    errorIcon: {
        color: theme.palette.error.main,
        marginRight: 10
    },
    errorMessage: {
        display: 'flex',
        alignItems: 'center'
    }
}));

export default useStyles;
