import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    viewRoot: {
        [theme.breakpoints.down('xs')]: {
            overflow: 'hidden'
        }
    },
    widget: {
        height: '100%'
    },
    nameFields: {
        display: 'inline-flex'
    },
    additionalItems: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        right: theme.spacing(2),
        bottom: theme.spacing(1),
        transition: `all 0.5s ease`
    }
}));

export default useStyles;
