import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    centered: {
        position: 'absolute',
        top: '40%',
        width: '100%',
        textAlign: 'center'
    },
    errorMessage: {
        fontSize: '1.5rem',
        fontWeight: 300,
        color: theme.palette.grey['300'],
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.25em'
        }
    },
    icon: {
        position: 'relative'
    },
    search: {
        position: 'absolute',
        transition: 'all 0.2s ease',
        transform: 'rotate(90deg) translate(-5px, 28px)',
        fontSize: '2em',
        animation: `
      $search-move 1s, 
      $searching 3s
    `,
        animationDelay: '0s, 1s',
        animationFillMode: 'forwards'
    },
    book: {
        fontSize: '3em',
        color: theme.palette.grey['300']
    },
    '@keyframes search-move': {
        from: {
            transform: 'rotate(90deg) translate(-5px, 28px)'
        },
        to: {
            transform: 'rotate(80deg) translate(9px, -8px)'
        }
    },
    '@keyframes searching': {
        from: {
            transform: 'rotate(80deg) translate(9px, -8px)'
        },
        [`${(1 / 5) * 100}%`]: {
            transform: 'rotate(65deg) translate(16px, -18px)'
        },
        [`${(2 / 5) * 100}%`]: {
            transform: 'rotate(80deg) translate(15px, -9px)'
        },
        [`${(3 / 5) * 100}%`]: {
            transform: 'rotate(65deg) translate(22px, -14px)'
        },
        [`${(4 / 5) * 100}%`]: {
            transform: 'rotate(80deg) translate(20px, -7px)'
        },
        to: {
            transform: 'rotate(20deg) translate(32px, 10px)'
        }
    }
}));

export default useStyles;
