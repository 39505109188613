import React, { FC, HTMLProps } from 'react';
import { StyledSlider, StyledCaption, StyledContainer } from './PasswordScore.style';
import { usePasswordScore } from '@mediaseal-webui/hooks';

export interface PasswordSliderProps extends HTMLProps<HTMLDivElement> {
    /**
     * Number from 0 - 4,
     * 0 | null = nothing entered
     * 1/2 = weak
     * 3 = medium
     * 4 = strong
     * */
    score: number | null;
    caption: string;
}

export const PasswordScore: FC<{}> = ({}) => {
    const { score, caption } = usePasswordScore();
    return <PasswordSlider score={score} caption={caption} />;
};

export const PasswordSlider: FC<PasswordSliderProps> = ({ score, caption }) => (
    <StyledContainer>
        <StyledSlider score={score} />
        <StyledCaption>{caption}</StyledCaption>
    </StyledContainer>
);
