import { useState, useEffect } from 'react';
import { User, InitialState } from '@mediaseal-webui/types/redux';
import { useSelector } from 'react-redux';

export const useProfileCheck = (profile: User) => {
    const [isOwnProfile, setIsOwnProfile] = useState<boolean>(false);
    const { id } = useSelector((state: InitialState) => state.entity.profile);
    useEffect(() => {
        setIsOwnProfile(id === profile?.id);
        return () => {
            setIsOwnProfile(false);
        };
    }, [profile, id]);

    return {
        isOwnProfile
    };
};
