import { createAsyncThunk } from '@reduxjs/toolkit';
import { JobsService, TitleService } from '@mediaseal-webui/services/src';

export const fetchJobsByTitle = createAsyncThunk(
    'titles/fetchJobsByTitle',
    async ({ id, service }: { id: string; service: JobsService }, { rejectWithValue }) => {
        try {
            const { data } = await service.fetchJobsByTitle(id);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const searchJobsByTitle = createAsyncThunk(
    //TODO: Add keyword here for searching
    'titles/searchJobsByTitle',
    async ({ id, service }: { id: string; service: JobsService }, { rejectWithValue }) => {
        try {
            const { data } = await service.fetchJobsByTitle(id);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const fetchTitle = createAsyncThunk('titles/fetchTitle', async ({ id, service }: { id: string; service: TitleService }, { rejectWithValue }) => {
    try {
        const [title, groups, users] = await service.fetchTitle(id);
        return {
            content: {
                ...title.data,
                users: {
                    content: users.data.content
                },
                groups: {
                    content: groups.data.content
                }
            }
        };
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
