import React from 'react';
import { Paper, useTheme, Typography } from '@material-ui/core';
import Histogram from '../../../../components/utility-components/Histogram';
import Widget from '../../../../components/utility-components/Widget';
import { useHistogramData } from '@mediaseal-webui/hooks';
import useStyles from './Summary.styles';
import { HistogramFigures } from '../index';

export const Summary = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { monthData } = useHistogramData();

    return (
        <>
            <Paper className={classes.module} elevation={0}>
                <div className={classes.widgets}>
                    <Typography color='textPrimary' variant='h6' component='div' className={classes.moduleSubtitle}>
                        Last 90 days
                    </Typography>
                    <Widget
                        width={`calc(100% / 3 - ${theme.spacing(3)}px)`}
                        height={theme.spacing(220 / 8)}
                        className={`${classes.widget} ${classes.totalWidget}`}
                        showSettings={false}>
                        <Histogram data={monthData} total />
                        <HistogramFigures data={monthData} total />
                    </Widget>
                    <Widget
                        width={`calc(100% / 3 - ${theme.spacing(3)}px)`}
                        height={theme.spacing(220 / 8)}
                        className={`${classes.widget} ${classes.grantedWidget}`}
                        showSettings={false}>
                        <Histogram data={monthData} granted />
                        <HistogramFigures data={monthData} granted />
                    </Widget>
                    <Widget
                        width={`calc(100% / 3 - ${theme.spacing(3)}px)`}
                        height={theme.spacing(220 / 8)}
                        className={`${classes.widget} ${classes.deniedWidget}`}
                        showSettings={false}>
                        <Histogram data={monthData} denied />
                        <HistogramFigures data={monthData} denied />
                    </Widget>
                </div>
            </Paper>
        </>
    );
};
