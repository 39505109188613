import { SET_ENCRYPTION_FAIL } from '@mediaseal-webui/constants';
import { EncryptFile } from '@mediaseal-webui/types/encryptor';
import { ThunkResult } from '@mediaseal-webui/types/redux';
/**
 *
 * @param id string
 * @param message string
 * @returns void
 *
 * Triggered by QT whenever an encryption job fails
 *
 */
export const encryptionWorkFailed =
    (id: EncryptFile['id'], message: string): ThunkResult<void> =>
    (dispatch, getState) => {
        console.log(`Received Signal encryptionWorkFailed from QT with ID : ${id}`);
        dispatch({ type: 'SET_SNACKBAR', payload: { error: true, message } }); //set a snackbar to alert the user
        dispatch({ type: SET_ENCRYPTION_FAIL, payload: { id, message } });
    };
