import { Avatar, Dialog, DialogContent, DialogActions, fade, Button, IconButton, CircularProgress } from '@material-ui/core';
import Lottie from 'lottie-react';
import styled, { css, DefaultTheme, keyframes } from 'styled-components';

export const StyledLottie = styled(Lottie)`
    ${({ theme, show }: StyledDropMessageProps) => css`
        width: 300px;
        opacity: ${show ? 1 : 0};
        visibility: ${show ? 'visible' : 'hidden'};
        transition: opacity 0.35s ease;
    `}
`;

interface StyledContentProps {
    theme: DefaultTheme;
}
export const StyledDialogue = styled(Dialog)`
    ${({ theme }: StyledContentProps) => css`
        text-align: center;
        backdrop-filter: blur(6px);
        .MuiPaper-root {
            background-color: ${theme.palette.background.paper} !important;
            /* box-shadow: unset; */
            overflow: unset;
        }
    `}
`;

export const FileId = styled.p`
    ${({ theme }) => css`
        color: ${theme.palette.text.secondary};
        font-size: 14px;
        margin-left: ${theme.spacing(4)}px;
    `}
`;

export const SpinnerContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-content: center;
`;
export const FileName = styled.h3`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        margin-bottom: 0;
        color: ${theme.palette.text.primary};
        font-size: 24px;
        svg {
            margin-right: ${theme.spacing(1)}px;
        }
    `}
`;

export const StyledContent = styled(DialogContent)``;

interface StyledDropMessageProps {
    theme: DefaultTheme;
    show: boolean;
}
export const StyledDropMessage = styled.h1`
    ${({ theme, show }: StyledDropMessageProps) => css`
        font-size: 30px;
        font-weight: bold;
        opacity: ${show ? 1 : 0};
        width: 100%;
        text-align: center;
        visibility: ${show ? 'visible' : 'hidden'};
        font-family: 'Open Sans', sans-serif;
        transition: opacity 0.35s ease;
        color: ${theme.palette.text.primary};
    `}
`;
interface StyledSectionProps {
    index: number;
    theme: DefaultTheme;
    disabled?: boolean;
}
export const StyledSection = styled.button`
    ${({ theme, index, disabled }: StyledSectionProps) => css`
        display: flex;
        width: 100%;
        min-height: 40px;
        outline: none;
        background: ${theme.palette.background.paper};
        align-items: center;
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(2)}px;
        border-radius: 5px;
        margin: ${theme.spacing(1)}px;
        border: 1px solid ${theme.palette.divider};
        transition: transform 0.35s ease;
        animation: ${slideInEllipticTopFwd} 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation-delay: ${index * 0.5}s;
        ${disabled &&
        css`
            pointer-events: none;
            background: ${theme.palette.background.default};
            color: ${theme.palette.text.disabled};
            .MuiAvatar-root {
                background: ${fade(theme.palette.action.disabled, 0.75)} !important;
            }
        `};
        &:hover {
            transform: scale(1.05);
            cursor: pointer;
            border: 1px solid ${theme.palette.text.primary};
        }
        svg {
            font-size: 20px;
            /* opacity: 0.25; */
            color: ${theme.palette.background.paper};
        }
    `}
`;

export const FileOptions = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 500px;
        /* max-width: 650px; */
        /* background: ${theme.palette.background.default}; */
        flex-direction: column;
        .MuiAvatar-root {
            background: ${fade(theme.palette.primary.main, 0.75)} !important;
        }
        ${theme.breakpoints.down('sm')} {
            min-width: unset;
            text-align: initial;
        }
    `}
`;

export const StyledAvatar = styled(Avatar)`
    ${({ theme }) => css`
        margin-right: ${theme.spacing(2)}px;
    `}
`;
export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
`;

interface StyledProgressProps {
    theme: DefaultTheme;
    progress: number;
}

export const StyledProgress = styled.h4`
    ${({ theme, progress }: StyledProgressProps) => css`
        font-size: 30px;
        color: ${theme.palette.common.white};
        @property --num {
            syntax: '<integer>';
            initial-value: 0;
            inherits: false;
        }
        --num: ${progress};
        margin: 0;
        transition: --num 0.25s;
        counter-reset: num var(--num);
        &::before {
            content: counter(num);
        }
    `}
`;

export const StyledProgressText = styled.h5`
    ${({ theme }) => css`
        font-size: 16px;
        color: ${theme.palette.common.white};
    `}
`;

/* ----------------------------------------------
 * Generated by Animista on 2021-2-1 17:18:7
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-elliptic-top-fwd
 * ----------------------------------------
 */
const slideInEllipticTopFwd = keyframes`
    0% {
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform-origin: 50% 100%;
    opacity: 0;
    }
    100% {
    transform: translateY(0) rotateX(0) scale(1);
    transform-origin: 50% 1400px;
    opacity: 1;
    }
`;

const fs = css`
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
export const DropZone = styled.div`
    ${fs}
`;

export const DropInput = styled.input`
    ${fs}
`;
export const ErrorContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        min-width: 400px;
        align-items: center;
        text-align: center;
        flex-direction: column;
        position: relative;
    `}
`;

export const ErrorMessage = styled.h2`
    ${({ theme }) => css`
        max-width: 400px;
        text-align: center;
        padding: ${theme.spacing(1)}px;
    `}
`;
export const ErrorLottie = styled(Lottie)`
    width: 150px;
`;
export const StyledCancel = styled(Button)`
    ${({ theme }) => css`
        .MuiButton-textPrimary {
            color: ${theme.palette.text.primary} !important;
            opacity: 0.5;

            &:hover {
                opacity: 1;
            }
        }
    `}
`;

export const StyledActions = styled(DialogActions)`
    ${({ theme }) => css`
        width: 100%;
        border-top: 1px solid ${theme.palette.divider};
    `}
`;

export const FileData = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        min-width: 700px;
        box-shadow: ${theme.shadows[10]};
        border-radius: 10px;
        text-align: left;
        padding: ${theme.spacing(3)}px;
        background: ${theme.palette.background.default};
        ${theme.breakpoints.down('sm')} {
            width: 90%;
            height: 100vh;
            overflow: scroll;
            min-width: unset;
        }
    `}
`;

export const StyledCircularProgress = styled(CircularProgress)`
    ${({ theme }) => css`
        width: 20px !important;
        height: 20px !important;
        svg {
            color: ${theme.palette.primary.main};
        }
    `}
`;

interface StyledCloseBtnProps {
    theme: DefaultTheme;
    show: boolean;
}

export const StyledCloseBtn = styled(Button)`
    ${({ theme, show }: StyledCloseBtnProps) => css`
        background: ${theme.palette.background.default};
        color: ${theme.palette.text.primary};
        font-weight: 600;
        border-radius: 30px;
        height: 40px;
        padding: 6px 16px;
        opacity: ${show ? 1 : 0};
        text-align: center;
        visibility: ${show ? 'visible' : 'hidden'};
        font-family: 'Montserrat', sans-serif;
        transition: opacity 0.35s ease;
        line-height: 1;
    `}
`;
