export function AuthError(message = 'An Authenication Error occurred!') {
    this.name = 'AuthError';
    this.message = message;
}
AuthError.prototype = Error.prototype;

export function TitleError(message = 'An error occurred with the title!') {
    this.name = 'TitleError';
    this.message = message;
}
TitleError.prototype = Error.prototype;

export function NoEntitiesError(message = 'No users or groups present') {
    this.name = 'NoEntitiesError';
    this.message = message;
}
NoEntitiesError.prototype = Error.prototype;

export function DuplicateInviteError(message = 'Duplicate invite entry') {
    this.name = 'DuplicateInviteError';
    this.message = message;
}
DuplicateInviteError.prototype = Error.prototype;
