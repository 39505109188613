import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    /** Header root <section /> */
    headerRoot: {
        width: `calc(100% - ${theme.sizes.sideNav.closed}px)`,
        minHeight: theme.sizes.header.open,
        background: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-evenly',
        position: 'fixed',
        top: 0,
        zIndex: 12,
        transition: 'all 0.3s ease',
        // mobile
        [theme.breakpoints.down('xs')]: {
            width: `100%`,
            minHeight: 'unset',
            height: theme.spacing(14),
            paddingRight: theme.spacing(2)
        }
    },
    mobile: {
        width: '100vw',
        display: 'flex',
        justifyContent: 'flex-start'
    },
    sidebarOpen: {
        width: `calc(100% - ${theme.sizes.sideNav.open}px)`
    },
    headerWithTabs: {
        minHeight: theme.sizes.header.withTabs
    },

    title: {
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        animation: '$fade-in 0.4s forwards',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('xs')]: {
            fontSize: '2.125rem',
            fontWeight: 400,
            maxWidth: '90%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginTop: 'unset',
            paddingTop: theme.spacing(2)
        }
    },
    singleTitle: {
        fontSize: '2.5em',
        opacity: 1
    },
    subText: {
        paddingLeft: theme.spacing(2)
    },
    stats: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
        transition: 'all 0.2s ease'
    },
    subtitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
        transition: '1.5s',
        borderRadius: 40,
        border: `1px solid #eee`,
        [theme.breakpoints.down('xs')]: {
            marginRight: theme.spacing(1)
        },
        '&:hover': {
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.light}`
        }
    },
    editableTitle: {
        paddingTop: 0,
        paddingBottom: 0
    },
    headerDate: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        maxWidth: 600,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        transition: 'all 1s ease',
        cursor: 'default',
        display: 'flex',
        alignItems: 'center',
        fontSize: 12,
        paddingTop: 4,
        paddingBottom: 4,

        '& > svg': {
            marginRight: theme.spacing(1),
            [theme.breakpoints.down('xs')]: {
                marginRight: 0
            },
            fontSize: 16
        },
        '& > span': {
            transition: 'all 1s ease'
        }
    },

    buttonContainer: {
        marginLeft: theme.spacing(1),
        '&.listView': {
            marginBottom: theme.spacing(1),
            minWidth: 142
        }
    },

    buttonProgress: {
        color: theme.palette.common.white,
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(1)
        }
    },
    menuIcon: {
        position: 'absolute',
        right: theme.spacing(2),
        cursor: 'pointer',
        opacity: 0.5,
        '&:hover': {
            opacity: 1
        }
    },
    search: {
        marginTop: theme.spacing(3)
    },
    searchIcon: {
        marginRight: theme.spacing(1),
        opacity: 0.5,
        '&:hover': {
            opacity: 1
        }
    },
    clickable: {
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.main
        }
    },
    opacity: {
        opacity: 0,
        visibility: 'hidden'
    },
    '@keyframes fade-in': {
        from: {
            opacity: 0
        },
        to: {
            opacity: 1
        }
    },
    desktopMiddleContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    desktopBodyRoot: {
        marginTop: theme.spacing(2),
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    desktopRightContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        flexDirection: 'column',
        width: 'auto',
        paddingRight: theme.spacing(2),
        '&.list': {
            flexDirection: 'row',
            width: '50%',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            '& > .search': {
                marginRight: theme.spacing(2),
                marginBottom: theme.spacing(1.5)
            }
        }
    },
    headerTabsRoot: {
        marginLeft: theme.spacing(2),
        width: '55%',
        [theme.breakpoints.down('md')]: {
            width: '80%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            zIndex: -1
        },
        padding: '0 5px',

        '& .MuiTab-fullWidth': {
            flexGrow: 'unset',
            borderRadius: '10px 10px 0 0',
            minWidth: 100,
            color: theme.palette.text.primary,
            fontWeight: 600,
            transition: '0.3s',
            '&:hover': {
                backgroundColor: theme.palette.background.default
            }
        },

        '&.wideTabs .MuiTab-fullWidth': {
            minWidth: 180
        },

        '& span.MuiTab-wrapper': {
            whiteSpace: 'nowrap'
        },
        '& .Mui-selected span.MuiTab-wrapper': {
            width: 'unset',
            position: 'relative',
            '&::before': {
                content: "''",
                height: 2,
                width: '120%',
                background: `linear-gradient(90deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.light} 100%)`,
                position: 'absolute',
                bottom: -12
            }
        }
    },
    tabIndicator: {
        opacity: 0
    },

    mobileSubmit: {
        position: 'fixed',
        bottom: theme.spacing(11), // 16px above bottomNav
        right: theme.spacing(2)
    },

    headerTabHeightPlaceholder: {
        height: 48
    },
    metaInfo: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start'
    },
    userLink: {
        textDecoration: 'none',
        border: 'none',
        outline: 'none',
        color: theme.palette.grey[600]
    }
}));

export default useStyles;
