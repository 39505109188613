// import styled from 'styled-components';

import { makeStyles } from '@material-ui/core';

// export const Test = styled.div`
//  display: flex;
// `;
//
export const useStyles = makeStyles((theme) => ({
    dialogue: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        margin: theme.spacing(1)
    }
}));
