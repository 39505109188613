import { stringify } from 'uuid';

export class FileParserService {
    reader: FileReader;
    binaryStr: ArrayBuffer | null;
    byteArr: Uint8Array | null;
    fileId: string;
    mediaSealSignature: number[];
    constructor() {
        this.reader = new FileReader();
        this.__init();
        this.binaryStr = null;
        this.byteArr = null;
        this.mediaSealSignature = [239, 95, 17, 224, 224, 17, 95, 239];
        this.fileId = '';
    }

    private async __init() {
        this.reader.onabort = this.handleAbort;
    }
    private handleAbort() {
        console.log('file reading was aborted');
    }

    public isMediasealFile(byteArr: Uint8Array) {
        return byteArr.every((value, index) => value === this.mediaSealSignature[index]);
    }

    public extractFileId(byteArr: Uint8Array) {
        return stringify(byteArr, 26);
    }

    public parseFile(file: File | Blob) {
        this.reader.readAsArrayBuffer(file);
    }
}
