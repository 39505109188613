import { setCreate } from '@mediaseal-webui/actions';
import { useJobFeatures } from '@mediaseal-webui/hooks';
import { InitialState } from '@mediaseal-webui/types/redux';
import React from 'react';
import { useSelector } from 'react-redux';
import ReauthTime from '../../ReauthTime';

export const ReauthComponent = () => {
    const { features } = useSelector((state: InitialState) => state.entity.create);
    const { isReauthEnabled, isRolloverEnabled } = useJobFeatures(features);
    return <ReauthTime features={features} active={isReauthEnabled()} rollOver={isRolloverEnabled()} setterFn={setCreate} />;
};
