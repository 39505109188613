import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Header } from '../../../../components/layout-components';
import { DecryptorTable } from '../../../../components/groups-components/profile-view-components';
import { Content, AddItemButton } from '@mediaseal-webui/components';
import { Typography, Card } from '@material-ui/core';
import { nameLength, useAuth } from '@mediaseal-webui/hooks';
import { updateDecGroup, fetchSpecificDecGroup } from '@mediaseal-webui/actions';
import { UserSelection } from '../../../../components/utility-components/Modals';
import { AddUser } from '@mediaseal-webui/components/src/icons';
import useStyles from './Profile.styles';
import { useCleanEntity } from 'hooks/useCleanEntity';
import { useAppSelector } from 'store';

const Modal = ({ openProps }) => <UserSelection openProps={openProps} title='Add decryptor users' updateFn={updateDecGroup} />;
const Profile = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const auth = useAuth();
    const isEditAuth = auth.edit || auth.administer;
    const [, setUserOpen] = useState(false);
    const { name, createDate } = useSelector(({ entity: { profile } }) => profile);

    const { items: permissions } = useAppSelector((state) => state.permissions);
    const hasEditDecryptorGroupsPermission = permissions.some((permission) => permission.permissionName === 'canEditDecryptorGroups');

    useCleanEntity();

    useEffect(() => {
        dispatch(fetchSpecificDecGroup(id));
    }, [dispatch, id]);

    const handleUserOpen = () => setUserOpen(true);

    const headerItems = {
        decUsers: {
            onClick: handleUserOpen
        },
        active: {
            updateFn: () => updateDecGroup(id),
            entity: 'group',
            isReadOnly: !hasEditDecryptorGroupsPermission
        }
    };

    return (
        <div className={classes.viewRoot}>
            <Header
                editable={hasEditDecryptorGroupsPermission}
                name={name}
                skeleton
                profile
                date={createDate ? new Date(createDate) : undefined}
                titleProps={{ setterFn: updateDecGroup }}
                breadcrumbProps={{
                    links: [{ id: 0, name: 'Decryptor Groups', to: '/groups/decryptor' }],
                    text: [{ id: 0, name }]
                }}
                headerItems={headerItems}
                titleValidation={nameLength}
            />
            <Content>
                {(classes) => (
                    <div className={classes.cardWrapper}>
                        <Card className={classes.profileCard} elevation={0}>
                            <div className={classes.titleBlock}>
                                <Typography color='textPrimary' variant='h5' className={classes.moduleTitle}>
                                    Users
                                </Typography>
                                {isEditAuth && hasEditDecryptorGroupsPermission && (
                                    <AddItemButton id='add-users' Modal={Modal} Icon={AddUser} title='Add decryptor users' />
                                )}
                            </div>
                            <DecryptorTable />
                        </Card>
                    </div>
                )}
            </Content>
        </div>
    );
};

export default React.memo(Profile);
