/**
 * Fetching
 */
export const START_FETCHING = 'START_FETCHING';
export const STOP_FETCHING = 'STOP_FETCHING';
export const INIT_FETCH = 'INIT_FETCH';
export const FETCH_ERROR = 'FETCH_ERROR';
/**
 * Preferences
 */
export const SET_PREFERENCE = 'SET_PREFERENCE';

/**
 * Search
 */
export const CLEAR_SEARCHES = 'CLEAR_SEARCHES';

/**
 * QT Version
 */

export const SET_VERSION_NUMBER = 'SET_VERSION_NUMBER';
export const SHOW_ABOUT_MODAL = 'SHOW_ABOUT_MODAL';

/**
 * API Version
 */

export const SET_API_VERSION = 'SET_API_VERSION';

/**
 * Permissions
 */

export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';

/**
 *  MOBILE NAV
 */

export const MOBILE_NAV_VISIBLE = 'MOBILE_NAV_VISIBLE';

export const SW_UPDATE_COMPLETE = 'SW_UPDATE_COMPLETE';
export const SW_UPDATE_AVAILABLE = 'SW_UPDATE_AVAILABLE';
