import { useEffect } from 'react';
import { useMobile } from '.';

export const useHeaderItemsMoved = (listener: () => void) => {
    const mobile = useMobile();
    useEffect(() => {
        if (mobile) {
            window.addEventListener('headerItemMoved', listener);
            return () => {
                window.removeEventListener('headerItemMoved', listener);
            };
        }
    }, [listener, mobile]);
};
