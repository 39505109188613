import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
    return {
        buttonContainer: {
            textAlign: 'center',
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
                justifyContent: 'center'
            }
        },
        formContainer: {
            maxWidth: '100%',
            [theme.breakpoints.down('xs')]: {
                width: `calc(100% - ${theme.spacing(2)}px)`
            }
        },
        form: {
            display: 'flex',
            marginTop: theme.spacing(1),
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column'
            }
        },
        optionalInputs: {
            display: 'flex',
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column'
            }
        },
        field: {
            margin: theme.spacing(1)
        },
        emailField: {
            margin: theme.spacing(1),
            flexGrow: 1
        },
        extendedIcon: {
            marginRight: theme.spacing(1)
        },
        formActions: {
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%'
        },
        addButton: {
            display: 'flex',
            margin: theme.spacing(3)
        }
    };
});

export default useStyles;
