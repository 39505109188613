import React, { useState } from 'react';
import { StyledMultiButton, SelectedOption, DropdownArrow } from '.';
import { RiArrowDropDownLine as DropdownArrowIcon } from 'react-icons/ri';
import { FlyoutMenu } from './FlyoutMenu';

export interface MultiOptionBtnOptions {
    text: string;
    fn: () => void;
    default?: boolean;
    icon: React.ElementType;
}
export interface MultiOptionBtnProps {
    options: MultiOptionBtnOptions[];
}
export const MultiOptionBtn = React.memo(({ options }: MultiOptionBtnProps) => {
    const [selectedOption, setSelectedOption] = useState<MultiOptionBtnOptions>(options.find((option) => option.default) || ({} as MultiOptionBtnOptions));
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

    const handleClick = (event: React.SyntheticEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleBtnClick = () => selectedOption.fn();
    return (
        <>
            <StyledMultiButton>
                <SelectedOption data-testid='select-option' id='select-option' onClick={handleBtnClick}>
                    <selectedOption.icon style={{ marginRight: 5 }} />
                    {selectedOption.text.toLocaleUpperCase()}
                </SelectedOption>
                <DropdownArrow onClick={handleClick} data-testid='dropdown-arrow' id='dropdown-arrow'>
                    <DropdownArrowIcon />
                </DropdownArrow>
            </StyledMultiButton>
            <FlyoutMenu
                option={options.find((option) => option.text !== selectedOption.text) || selectedOption}
                anchor={anchorEl}
                setter={setAnchorEl}
                setStateFn={setSelectedOption}
            />
        </>
    );
});
