import { msApi } from '@mediaseal-webui/api';
import { SET_SNACKBAR } from '@mediaseal-webui/constants';
import { Dispatchable, EditState } from '@mediaseal-webui/types/redux';
import { setError } from '../entity';
import { stopFetching } from '../generic';
import { sendSettingsToQt } from './sendSettingsToQt';
import { storeServerSettings } from './storeServerSettings';

const acceptedSettings = ['encryptionPrefix', 'studioName'];

export interface SettingsPayload {
    name: string;
    value: string;
}
const createSettingsPayload = (edit: EditState): SettingsPayload[] =>
    Object.entries(edit)
        .filter((entry) => acceptedSettings.indexOf(entry[0]) !== -1)
        .map((accepted) => ({
            name: accepted[0],
            value: accepted[1]
        }));
export const updateServerSettings = (): Dispatchable => async (dispatch, getState) => {
    try {
        const { encryptionPrefix, studioName } = getState().settings.server;
        const edit = getState().entity.edit;
        const settings = createSettingsPayload(edit);
        const newEncryptionPrefix = settings.find((setting) => setting.name === 'encryptionPrefix')?.value || encryptionPrefix;
        const newStudioName = settings.find((setting) => setting.name === 'studioName')?.value || studioName;
        await msApi.put('settings', settings);
        if (window.qt)
            dispatch(
                sendSettingsToQt({
                    encryptionPrefix: newEncryptionPrefix,
                    studioName: newStudioName
                })
            );
        dispatch(storeServerSettings(settings));
        dispatch({
            type: SET_SNACKBAR,
            payload: { success: true, message: `Successfully updated settings` }
        });
    } catch (error) {
        console.error(error);
        dispatch(setError(error));
    }
    dispatch(stopFetching());
};
