import { Typography } from '@material-ui/core';
import { setCreate } from '@mediaseal-webui/actions';
import { TextInput } from '@mediaseal-webui/components';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useStyles } from './ContactDetails.style';

export const ContactDetails = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const handleChange = (key: string) => (value: string) => dispatch(setCreate({ [key]: value }));

    return (
        <div className={classes.contactDetailsContainer}>
            <div className={classes.nameContainer}>
                <Typography color='textPrimary' variant='body2' className={classes.title}>
                    Contact Name
                </Typography>
                <TextInput id='editContactName' name='contactName' validation={() => null} setterFn={handleChange('contactName')} />
            </div>

            <div className={classes.nameContainer}>
                <Typography color='textPrimary' variant='body2' className={classes.title}>
                    Contact Info
                </Typography>
                <TextInput id='editContactInfo' name='contactInfo' validation={() => null} setterFn={handleChange('contactInfo')} />
            </div>
        </div>
    );
};
