import { fade, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    search: {
        display: 'flex',
        borderRadius: theme.shape.borderRadius,
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        padding: ({ searchPadding }) => theme.spacing(searchPadding ?? 1),
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            minWidth: '30%'
        }
    },
    searchIcon: {
        '&hover': {
            cursor: 'pointer'
        }
    },
    input: {
        flex: 1,
        width: '90%'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 3),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '750px'
        }
    }
}));

export default useStyles;
