import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    description: {
        opacity: 0.65,
        padding: theme.spacing(3),
        paddingTop: theme.spacing(1),
        fontSize: '1em'
    },

    modal: {
        padding: theme.spacing(3)
    },

    title: {
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(1)
    }
}));

export default useStyles;
