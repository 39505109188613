import { combineReducers } from 'redux';
import { UpdateDetailsAction, AppVersionStateAction, AllowEncryptionAction } from './types';
import { SET_SPA_NOT_SUPPORTED, SET_APP_NOT_SUPPORTED, SET_API_VERSION, SET_VERSION_NUMBER, ALLOW_ENCRYPTION } from '@mediaseal-webui/constants';
import { IUpdateDetails, AppVersionState } from '@mediaseal-webui/types/versioning';

export const updateDetails = (state: IUpdateDetails = { title: '', subtitle: '' }, action: UpdateDetailsAction) => {
    switch (action.type) {
        case SET_SPA_NOT_SUPPORTED:
            return action.payload;
        case SET_APP_NOT_SUPPORTED:
            return action.payload;
        default:
            return state;
    }
};

export const applicationInformation = (
    state: AppVersionState = {
        engineVersion: '',
        spaMinSupportedVersion: '',
        maxHeaderVersion: 0,
        maxAdditionalDataVersion: 0
    },
    action: AppVersionStateAction
) => {
    switch (action.type) {
        case SET_VERSION_NUMBER:
            return {
                ...state,
                ...action.payload
            };
        case SET_API_VERSION:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export const allowEncryption = (state: boolean = true, action: AllowEncryptionAction) => {
    switch (action.type) {
        case ALLOW_ENCRYPTION:
            return action.payload;
        default:
            return state;
    }
};

export default combineReducers({
    updateDetails,
    applicationInformation,
    allowEncryption
});
