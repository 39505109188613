import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    subtitle: {
        color: theme.palette.text.primary,
        fontSize: '1.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1),
        transition: '1.5s',
        borderRadius: 40,
        border: `1px solid #eee`,
        [theme.breakpoints.down('xs')]: {
            marginRight: theme.spacing(1)
        },
        height: '40px',
        width: '40px',
        '&:hover': {
            border: `1px solid ${theme.palette.primary.light}`
        }
    },

    clickable: {
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.main
        }
    }
}));

export default useStyles;
