import styled, { css } from 'styled-components';
import { Paper as PaperContent, Typography, Button, Divider, FormGroup, DialogContent } from '@material-ui/core';

export const Paper = styled(PaperContent)`
    padding: 20px;
    width: 525px;
`;

export const Title = styled(Typography)`
    margin-bottom: 12px;
    margin-left: 14px;
    margin-top: 14px;
`;

export const CloseButton = styled(Button)`
    color: #6a167a;
`;

export const Seperator = styled(Divider)`
    margin-bottom: 8px;
    margin-top: 16px;
`;

export const NotesContainer = styled.div`
    margin: 14px;
    margin-top: 40px;
    margin-bottom: 40px;
`;

export const StyledFormGroup = styled(FormGroup)`
    ${({ theme }) => css`
        padding: ${theme.spacing(2)}px;
    `}
`;
export const StyledDialogContent = styled(DialogContent)`
    ${({ theme }) => css`
        min-width: 400px;
    `}
`;
