import { EncryptFile } from '@mediaseal-webui/types/encryptor';

export const filePause = (file: EncryptFile): EncryptFile => ({
    ...file,
    paused: true
});

export const fileCancel = (file: EncryptFile): EncryptFile => ({
    ...file,
    started: false,
    cancelled: true,
    paused: false,
    percentage: 100,
    done: true
});

export const fileSuccess = (file: EncryptFile): EncryptFile => ({
    ...file,
    started: false,
    done: true,
    pending: false,
    percentage: 100
});

export const fileStart = (file: EncryptFile): EncryptFile => ({
    ...file,
    started: true,
    pending: false,
    paused: false,
    cancelled: false
});

export const fileFail = (file: EncryptFile, message: string): EncryptFile => ({
    ...file,
    error: true,
    started: false,
    paused: false,
    pending: false,
    errorMessage: message
});

export const fileProgress = (file: EncryptFile, amount: number): EncryptFile => ({
    ...file,
    percentage: amount
});
