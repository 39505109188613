import { setCreate } from '@mediaseal-webui/actions';
import React from 'react';
import { useDispatch } from 'react-redux';
import FormGroup from '../../../../utility-components/FormGroup';
import TextArea from '../../../../utility-components/TextArea';

export const Notes = () => {
    const dispatch = useDispatch();
    const handleChange = (value: string) => (event: React.ChangeEvent<HTMLInputElement>) => dispatch(setCreate({ [value]: event.target.value }));
    return (
        <FormGroup subtitle='Add notes to this job for future reference'>
            <TextArea aria-label='job notes' placeholder='Enter notes here ...' name='notes' onChange={handleChange('notes')} />
        </FormGroup>
    );
};
