import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tabs, Tab } from '@material-ui/core';
import useStyles from './TabArea.styles';
import { clearSearches } from '../../../../actions/misc';
import { TabPanel } from '../../../utility-components/TabPanel/TabPanel';
import { UsersSection } from '../../../table-components/UserSelection';
import { RecipientSelection } from '../../../table-components/RecipientSelection';
import { RecipientGroupSelection } from '../../../table-components/RecipientGroupSelection';
import { clearSummary } from '@mediaseal-webui/actions';

const TabArea = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        dispatch(clearSearches());
        setValue(newValue);
    };

    useEffect(
        () => () => {
            dispatch(clearSummary());
        },
        [dispatch]
    );

    return (
        <div className='tabArea container'>
            <Tabs value={value} onChange={handleChange} indicatorColor='primary' textColor='secondary' variant='fullWidth'>
                <Tab label='Encryptor Users' id='usersTab' data-testid='usersTab' value={0} />
                <Tab label='Decryptor Users' id='decUsersTab' data-testid='decUsersTab' value={1} />
                <Tab label='Decryptor Groups' id='groupsTab' data-testid='groupsTab' value={2} />
            </Tabs>
            <TabPanel className={classes.tabZone} value={value} index={0} component={UsersSection} />
            <TabPanel className={classes.tabZone} value={value} index={1} component={RecipientSelection} />
            <TabPanel className={classes.tabZone} value={value} index={2} component={RecipientGroupSelection} />
        </div>
    );
};

export default React.memo(TabArea);
