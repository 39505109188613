import { EncryptFileWithIssues, FileIssue } from '@mediaseal-webui/types/encryptor';

export * from './FileIssuesModal';
export * from './FileIssuesModal.styles';
export * from './Rows/FileRow';
export * from './Rows/FirstRow';

export interface FileIssuesRowProps {
    file: EncryptFileWithIssues;
}

export interface FirstRowProps {
    issue: FileIssue;
}

export const fileIssueMessage = (issue: FileIssue): string => {
    switch (issue) {
        case 'AlreadyEncrypted':
            return 'Files have already been encrypted';
        case 'OutputCollision':
            return 'Encryption of files conflicts with current encryption';
        case 'OutputConflict':
            return 'Conflicting files already exist in output directory';
    }
};
