import React from 'react';
// import PropTypes from 'prop-types';
import { Dialog, Paper, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import useModalStyles from './ApplicationInformation.styles';
import { setShowAboutModal } from '../../../../actions/misc';

// const JENKINS_URL_WIN =
//     'https://jenkins.fortium.com/job/MediaSeal%20Apps/job/webcryptor/lastSuccessfulBuild/artifact/MediaSeal_3.9.5.19.0bd4d4-web-Windows%20x86%20VS2015%20Qt5.9.zip';
// const JENKINS_URL_MAC =
//     'https://jenkins.fortium.com/job/MediaSeal%20Apps/job/webcryptor/lastSuccessfulBuild/artifact/MediaSeal_3.9.5.19.0bd4d4-web-Windows%20x86%20VS2015%20Qt5.9.zip';

const ApplicationInformation = ({ open }) => {
    const { applicationInformation } = useSelector((state) => state.versioning);
    const { engineVersion, apiVersion } = applicationInformation;
    const dispatch = useDispatch();
    const handleModalClose = () => dispatch(setShowAboutModal(false));
    const modalClasses = useModalStyles();
    return (
        <Dialog maxWidth='lg' onClose={handleModalClose} aria-labelledby='about-dialog' open={open}>
            <Paper className={modalClasses.wrapper}>
                <Typography color='textPrimary' className={modalClasses.item} variant='h5'>
                    Application Information
                </Typography>
                {window.qt && (
                    <Typography component='div' variant='overline'>
                        Client Version: {engineVersion || 'N/A'}
                    </Typography>
                )}
                <Typography color='textPrimary' className={modalClasses.item} variant='overline'>
                    API Version: {apiVersion || 'v1'}
                </Typography>
                <Typography color='textPrimary' className={modalClasses.item} variant='overline'>
                    UI Version: {process.env.REACT_APP_VERSION || '1.0.150'}
                </Typography>
            </Paper>
        </Dialog>
    );
};

// ApplicationInformation.propTypes = {
//   // bla: PropTypes.string,
// };

// ApplicationInformation.defaultProps = {
//   // bla: 'test',
// };

export default ApplicationInformation;
