import { EncryptorGroupService } from '@mediaseal-webui/services';
import { definitions, SpringBootResponse } from '@mediaseal-webui/types/api';
import { seedData } from '@mediaseal-webui/utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import appendToPagedState from 'reducers/helpers/appendToPagedState';

export const fetchEncryptorGroups = createAsyncThunk('groups/fetchEncryptorGroups', async (service: EncryptorGroupService, { rejectWithValue }) => {
    try {
        const { data } = await service.fetchGroups();
        return data as EncryptorGroupsState;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const searchEncryptorGroups = createAsyncThunk(
    'groups/searchEncryptorGroups',
    async ({ keyword, service }: { keyword: string; service: EncryptorGroupService }, { rejectWithValue }) => {
        try {
            const { data } = await service.searchGroups(keyword);
            return data as EncryptorGroupsState;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const fetchDecryptorGroups = createAsyncThunk('groups/fetchDecryptorGroups', async (service: EncryptorGroupService, { rejectWithValue }) => {
    try {
        const { data } = await service.fetchGroups();
        return data as DecryptorGroupsState;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const searchDecryptorGroups = createAsyncThunk(
    'groups/searchDecryptorGroups',
    async ({ keyword, service }: { keyword: string; service: EncryptorGroupService }, { rejectWithValue }) => {
        try {
            const { data } = await service.searchGroups(keyword);
            return data as DecryptorGroupsState;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export interface DecryptorGroupsState extends EncryptorGroupsState {}

export interface EncryptorGroupsState extends SpringBootResponse<definitions['Group'][]> {
    pending: boolean;
    searchValue: string;
}
const initialState: EncryptorGroupsState = {
    content: seedData(),
    searchValue: '',
    page: {
        totalElements: 0,
        totalPages: 0
    },
    pending: false
};
export const encryptorGroupsSlice = createSlice({
    name: 'encryptorGroups',
    initialState,
    reducers: {
        setSearchValue: (state, action) => ({
            ...state,
            searchValue: action.payload
        }),
        clearSearchValue: (state) => ({
            ...state,
            searchValue: ''
        }),
        setGroups: (state, action) => ({
            ...state,
            ...action.payload
        }),
        setSelected: (state, action) => ({
            ...state,
            content: state.content.map((item) => (item.id === action.payload.id ? { ...item, selected: true } : item))
        }),
        deselect: (state, action) => ({
            ...state,
            content: state.content.map((item) => (item.id === action.payload.id ? { ...item, selected: false } : item))
        }),
        clearGroups: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(fetchEncryptorGroups.fulfilled, (state, action) => {
            return appendToPagedState(action, state);
        });
        builder.addCase(fetchEncryptorGroups.pending, (state, action) => ({
            ...state,
            pending: true
        }));
        builder.addCase(searchEncryptorGroups.fulfilled, (state, action) => ({
            ...state,
            page: {
                ...(action.payload.page ?? {
                    totalPages: 0,
                    totalElements: 0
                })
            },
            content: [...action.payload.content],
            pending: false
        }));
        builder.addCase(searchEncryptorGroups.pending, (state, action) => ({
            ...state,
            pending: true
        }));
    }
});
export const decryptorGroupsSlice = createSlice({
    name: 'decryptorGroups',
    initialState,
    reducers: {
        setSearchValue: (state, action) => ({
            ...state,
            searchValue: action.payload
        }),
        clearSearchValue: (state) => ({
            ...state,
            searchValue: ''
        }),
        setGroups: (state, action) => ({
            ...state,
            ...action.payload
        }),
        setSelected: (state, action) => ({
            ...state,
            content: state.content.map((item) => (item.id === action.payload.id ? { ...item, selected: true } : item))
        }),
        deselect: (state, action) => ({
            ...state,
            content: state.content.map((item) => (item.id === action.payload.id ? { ...item, selected: false } : item))
        }),
        clearGroups: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDecryptorGroups.fulfilled, (state, action) => {
            return appendToPagedState(action, state);
        });
        builder.addCase(fetchDecryptorGroups.pending, (state, action) => ({
            ...state,
            pending: true
        }));
        builder.addCase(searchDecryptorGroups.fulfilled, (state, action) => ({
            ...state,
            page: {
                ...(action.payload.page ?? {
                    totalPages: 0,
                    totalElements: 0
                })
            },
            content: [...action.payload.content],
            pending: false
        }));
        builder.addCase(searchDecryptorGroups.pending, (state, action) => ({
            ...state,
            pending: true
        }));
    }
});
