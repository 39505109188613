import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { fetchJobFiles } from '../../../../actions/jobs';
import { MainTable, RefreshAlert } from '@mediaseal-webui/components';
import { jobFileHeader } from '../../../../data';
import { InitialState } from '@mediaseal-webui/types/redux';
import { useTableSort } from '@mediaseal-webui/hooks';

export const Files = () => {
    const { files } = useSelector(({ entity }: InitialState) => entity.profile);
    const { totalFilePages, newFilesAvailable } = useSelector(({ jobs }: InitialState) => jobs);
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const [filesPage, setFilesPage] = useState(1);
    const { order, orderBy, createSortHandler, headCells } = useTableSort({
        cells: jobFileHeader,
        setPage: setFilesPage,
        fetchFn: fetchJobFiles,
        searchValue: ''
    });
    const fetchFn = () => {
        dispatch(fetchJobFiles({ id, page: filesPage, firstCall: false, sort: `${orderBy},${order}` }));
    };
    const refreshFn = () => dispatch(fetchJobFiles({ id, page: 0, firstCall: true, sort: `${orderBy},${order}` }));
    return (
        <div style={{ width: '100%', height: 450, position: 'relative' }}>
            <RefreshAlert
                text='Refresh to see the latest encrypted files'
                show={newFilesAvailable || false}
                title='New files added to job'
                refreshFn={refreshFn}
            />
            <MainTable
                entities={files || []}
                searchValue=''
                id='files-table'
                pageControl={{ page: filesPage, setPage: setFilesPage }}
                //@ts-expect-error due to missing props ts(2322).
                fetchFn={fetchFn}
                totalPages={totalFilePages}
                columns={headCells}
                sortData={{
                    sortBy: orderBy,
                    sortDirection: order || 'desc',
                    createSortHandler
                }}
                noResultsMessage='No files are associated with this job'
            />
        </div>
    );
};
