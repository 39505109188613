import React, { useCallback, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useTooltipStyles } from './StatusCell.styles';
import { useHistory } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { StatusCellData, StatusCellTooltipProps } from '.';
import { Title } from '@mediaseal-webui/types/titles';

export const StatusCellToolTip = ({ data }: StatusCellTooltipProps) => {
    const history = useHistory();
    const [showIconTitle, setShowIconTitle] = useState(false);
    const [showIconJob, setShowIconJob] = useState(false);
    const tooltipClasses = useTooltipStyles();

    const clickAction = (url: string) => (event: React.SyntheticEvent) => {
        event.stopPropagation();
        history.push(url);
    };

    const statusCheck = (item: Title | StatusCellData) => (item.active ? 'Active' : 'Inactive');
    const determineTooltipClass = (data: Title) =>
        data.active ? `${tooltipClasses.tooltipActive} activeTooltip` : `${tooltipClasses.tooltipInactive} inactiveTooltip`;

    const handleClick = useCallback(clickAction, []);
    const handleMouseEnterTitle = useCallback(() => setShowIconTitle(true), []);
    const handleMouseExitTitle = useCallback(() => setShowIconTitle(false), []);
    const handleMouseEnterJob = useCallback(() => setShowIconJob(true), []);
    const handleMouseExitJob = useCallback(() => setShowIconJob(false), []);
    return data.title ? (
        <>
            <Typography
                color='textPrimary'
                onMouseEnter={handleMouseEnterJob}
                onMouseLeave={handleMouseExitJob}
                className={tooltipClasses.tooltipHover}
                variant='subtitle1'
                onClick={handleClick(`/jobs/${data.id}/profile`)}>
                Job: <span className={determineTooltipClass(data.title)}>{statusCheck(data)}</span>
                {/* {showIconJob && <OpenInNewIcon className={tooltipClasses.icon} />} */}
            </Typography>
            <Typography
                color='textPrimary'
                onMouseEnter={handleMouseEnterTitle}
                onMouseLeave={handleMouseExitTitle}
                variant='subtitle1'
                className={tooltipClasses.tooltipHover}
                onClick={handleClick(`/titles/${data.title.id}/profile`)}>
                Title: <span className={determineTooltipClass(data.title)}>{statusCheck(data.title)}</span>
                {/* {showIconTitle && <OpenInNewIcon className={tooltipClasses.icon} />} */}
            </Typography>
        </>
    ) : null;
};
