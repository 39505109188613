import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './HistogramFigures.styles';

export interface HistogramFiguresProps {
    data: any;
    total?: boolean;
    granted?: boolean;
    denied?: boolean;
}

type Month = {
    label: string;
    granted: number;
    denied: number;
};

export const HistogramFigures = ({ data, total, granted, denied }: HistogramFiguresProps) => {
    const classes = useStyles();
    const figure = (month: Month) => {
        switch (true) {
            case granted:
                return month.granted;
            case denied:
                return month.denied;
            // case total:
            default:
                return month.granted + month.denied;
        }
    };
    return (
        <Paper className={classes.histogramFigures} elevation={0}>
            {data.map((month: Month, index: number) => (
                <div
                    key={index}
                    className={clsx(classes.monthFigure, {
                        [classes.borderRight]: index < 2
                    })}>
                    <Typography color='textPrimary' variant='subtitle1' className={classes.figureTotal}>
                        {figure(month)}
                    </Typography>
                    <Typography color='textPrimary' variant='overline' className={classes.figureLabel}>
                        {month.label}
                    </Typography>
                </div>
            ))}
            <Typography color='textPrimary' variant='overline' className={classes.overallTitle}>
                {total ? 'Attempted' : granted ? 'Granted' : 'Denied'}
            </Typography>
        </Paper>
    );
};
