import { Dispatchable } from '@mediaseal-webui/types/redux';
import { SET_ENCRYPTION_PERFORMANCE } from '@mediaseal-webui/constants';
import { sendEncryptionPerformanceToQt } from './sendEncryptionPerformanceToQt';

export const updateApplicationSettings =
    (value: number | number[]): Dispatchable =>
    (dispatch, getState) => {
        dispatch({
            type: SET_ENCRYPTION_PERFORMANCE,
            payload: { encPerformanceLevel: value }
        });
        dispatch(sendEncryptionPerformanceToQt(value as number));
    };
