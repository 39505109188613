import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {},
    tabZone: {
        minHeight: '400px',
        maxHeight: 800,
        overflow: 'scroll'
    },
    tabs: {},
    stack: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 500
    }
}));

export default useStyles;
