import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    viewRoot: {
        [theme.breakpoints.down('xs')]: {
            overflow: 'hidden'
        }
    }
}));

export default useStyles;
