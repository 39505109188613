import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        // height: '100%',
        display: 'flex',
        border: 'none'
    },
    logoChevContainer: {
        position: 'relative',
        width: '100%'
    },
    logo: {
        transition: 'all 0.5s ease!important',
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        paddingLeft: 11.5,
        zIndex: 10,
        backgroundColor: 'linear-gradient(0deg, rgba(42,45,62,1) 0%, rgba(48,51,71,1) 45%, rgba(61,63,91,1) 100%)'
    },
    logoOpen: {
        paddingLeft: 11.5
    },
    chevron: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        transition: 'all 0.5s ease',
        position: 'fixed',
        left: theme.sizes.sideNav.open - theme.spacing(1.5),
        zIndex: 1400,
        top: `calc(50% - ${theme.spacing(1.5)}px)`,
        border: `1px solid rgba(29,28,29,0.5)`,
        width: theme.spacing(3),
        height: theme.spacing(3),
        minWidth: 'unset',
        minHeight: 'unset',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark
        }
    },
    chevronClosed: {
        left: theme.sizes.sideNav.closed - theme.spacing(1.5),
        transform: 'rotate(180deg)'
    },
    drawer: {
        width: theme.sizes.sideNav.open,
        flexShrink: 0,
        overflow: 'unset',
        alignItems: 'unset',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    drawerClose: {
        width: theme.sizes.sideNav.closed,
        alignItems: 'center',
        '& $subHeading': {
            paddingLeft: 6
        }
    },
    paper: {
        paddingTop: theme.spacing(2),
        background: `linear-gradient(90deg, ${theme.palette.primary.dark}, ${theme.palette.primary.light})`,
        height: '100vh',
        position: 'fixed',
        borderRight: 'none'
    },
    navContainer: {
        overflowY: 'scroll',
        flexWrap: 'nowrap',
        scrollbarWidth: 'none',
        paddingTop: theme.spacing(10),
        // height: `calc(100% - 170px)`,
        height: '100vh'
    },
    navLink: {
        padding: '0px',
        margin: '0px',
        width: '100%',
        marginTop: '-45px'
    },
    navLinkApp: {
        marginTop: '-90px'
    },
    navItems: {},
    logOutLink: {
        height: 50,
        width: '100%',
        opacity: 0.4,
        color: theme.palette.common.white,
        '&:hover': {
            opacity: 0.85
        }
    },
    utils: {
        // position: 'fixed',
        display: 'flex',
        justifySelf: 'flex-end',
        width: theme.sizes.sideNav.open,
        bottom: 0,
        backgroundColor: '#1D1C1D',
        paddingBottom: theme.spacing(1),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    utilsLink: {
        borderLeftStyle: 'solid',
        borderLeftColor: 'transparent',
        display: 'block',
        color: 'white',
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.5)',
            transition: '0.2s'
        },
        '&:active': {
            backgroundColor: 'rgba(255,255,255,0.8)',
            borderLeftColor: 'rgba(255,255,255,0.5)',
            transition: '0.2s'
        }
    },

    utilsClosed: {
        width: theme.sizes.sideNav.closed
    },
    menuIconContainer: {
        height: theme.spacing(8),
        justifyContent: 'flex-end'
    },
    subHeading: {
        color: 'white',
        marginTop: theme.spacing(3),
        fontSize: '10px',
        fontWeight: 'lighter',
        textTransform: 'uppercase',
        opacity: 0.5,
        paddingLeft: 26
    },
    routes: {
        width: '100%',
        height: '100vh',
        background: theme.palette.grey[200]
    },

    divider: {
        height: theme.spacing(0.25),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },

    logoContainer: {
        width: '100%',
        height: '50px'
    }
}));
export default useStyles;
