import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Send } from '@material-ui/icons';
import { Header } from '../../../../components/layout-components';
import ScrollToTopButton from '../../../../components/utility-components/ScrollToTopButton';
import CreateForm from '../../../../components/groups-components/creation-view-components/CreateForm';
import SummaryList from '../../../../components/utility-components/SummaryList/index';
import { postEncGroup, clearGroups, fetchPermissions, fetchGroups, clearUsers, fetchEncryptorUsers, fetchUsers } from '@mediaseal-webui/actions';
import { useEntityCreation, useMobile } from '@mediaseal-webui/hooks';
import { Content, SubmitButton as Submit } from '@mediaseal-webui/components';
import useStyles from './Create.styles';

const Create = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const mobile = useMobile();

    const { valid } = useEntityCreation();

    const { users, decryptorUsers, decryptorGroups, permissions, name } = useSelector(({ entity: { create } }) => create);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchPermissions());
        const args = {
            keyword: '',
            page: 0,
            isSort: true,
            firstCall: true
        };
        dispatch(fetchUsers(args));
        dispatch(fetchGroups(args));
        dispatch(fetchEncryptorUsers(args));
        return () => {
            dispatch(clearGroups());
            dispatch(clearUsers());
        };
    }, [dispatch]);

    const handleSubmit = () => {
        dispatch(postEncGroup(history));
        setOpen(!open);
    };

    const SubmitButton = (props) => (
        <Submit
            title='Save Group'
            id='create-enc-group-button'
            aria-label='Post Group'
            Icon={Send}
            data-testid='create-group-btn'
            {...props}
            disabled={!valid}
        />
    );
    const headerItems = {
        stateKey: 'create',
        permissions: permissions?.length > 0 ? {} : undefined,
        encUsers: users?.length > 0 ? {} : undefined,
        decUsers: decryptorUsers?.length > 0 ? {} : undefined,
        decGroups: decryptorGroups?.length > 0 ? {} : undefined
    };
    return (
        <div className={classes.viewRoot}>
            <Header
                defaultName='Create Encryptor Group'
                onSubmit={handleSubmit}
                date={new Date()}
                name={name}
                ActionButton={SubmitButton}
                breadcrumbProps={{
                    links: [{ id: 0, name: 'Encryptor Groups', to: '/groups/encryptor' }],
                    text: [{ id: 0, name: 'Create' }]
                }}
                headerItems={headerItems}
            />

            <Content>
                {(classes) => (
                    <div className={`${classes.wrapper} ${classes.cols}`}>
                        <CreateForm handleSubmit={handleSubmit} />
                        <div style={{ display: 'flex', minWidth: '350px', justifyContent: 'flex-end' }}>
                            <SummaryList subtitleEntity='group' />
                        </div>
                    </div>
                )}
            </Content>

            {!mobile && <ScrollToTopButton scrollStepInPx='50' delayInMs={10} />}
        </div>
    );
};

export default React.memo(Create);
