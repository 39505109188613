/**
 * Info flag for when settings are sent successfully
 */

export const SETTINGS_SENT_TO_QT = 'SETTINGS_SENT_TO_QT';

/**
 * Flag to store settings in state
 */
export const STORE_SERVER_SETTINGS = 'STORE_SERVER_SETTINGS';

/**
 * Flag to send performance to QT
 */

export const ENCRYPTION_PERFORMANCE_SENT_TO_QT = 'ENCRYPTION_PERFORMANCE_SENT_TO_QT';

/**
 * Flag to store perf in state
 */
export const SET_ENCRYPTION_PERFORMANCE = 'SET_ENCRYPTION_PERFORMANCE';

export const SET_THEME = 'SET_THEME';

export const SET_EMAIL_INVITES = 'SET_EMAIL_INVITES';
