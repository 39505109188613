import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    icon: {
        opacity: 0.75,
        textAlign: 'center',
        color: theme.palette.primary.main
    },
    inputContainer: {
        marginTop: theme.spacing(4)
    },
    bold: {
        fontWeight: 'bold'
    },
    titlePadding: {
        marginBottom: theme.spacing(2)
    }
}));

export default useStyles;
