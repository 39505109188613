export const ADD_DRAFT_INVITE = 'ADD_DRAFT_INVITE';
export const DELETE_DRAFT_INVITE = 'DELETE_DRAFT_INVITE';
export const UPDATE_DRAFT_INVITE = 'UPDATE_DRAFT_INVITE';
export const CLEAR_DRAFT_INVITES = 'CLEAR_DRAFT_INVITES';

export const DELETE_INVITE = 'DELETE_INVITE';
export const RESEND_INVITE = 'RESEND_INVITE';

export const SET_FILTERED_INVITES = 'SET_FILTERED_INVITES';
export const CLEAR_FILTERED_INVITES = 'CLEAR_FILTERED_INVITES';
export const SET_INVITE_PAGE_COUNT = 'SET_INVITE_PAGE_COUNT';
export const SET_TOTAL_INVITES = 'SET_TOTAL_INVITES';

export const SET_INVITE_PAGE_SEARCH_VALUE = 'SET_INVITE_PAGE_SEARCH_VALUE';
export const CLEAR_INVITE_PAGE_SEARCH_VALUE = 'CLEAR_INVITE_PAGE_SEARCH_VALUE';
export const SET_INVITE_ORDER = 'SET_INVITE_ORDER';

export const SET_PENDING_INVITES = 'SET_PENDING_INVITES';
export const SET_REJECTED_INVITES = 'SET_REJECTED_INVITES';
export const SET_ADDITIONAL_PENDING_INVITES = 'SET_ADDITIONAL_PENDING_INVITES';
export const SET_ADDITIONAL_REJECTED_INVITES = 'SET_ADDITIONAL_REJECTED_INVITES';

export const SET_TOTAL_PENDING_INVITES = 'SET_TOTAL_PENDING_INVITES';
export const SET_TOTAL_REJECTED_INVITES = 'SET_TOTAL_REJECTED_INVITES';
export const SET_PENDING_PAGE_COUNT = 'SET_PENDING_PAGE_COUNT';
export const SET_REJECTED_PAGE_COUNT = 'SET_REJECTED_PAGE_COUNT';
