import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MainTable } from '@mediaseal-webui/components';
import { InitialState } from '@mediaseal-webui/types/redux';
import { GenericEntity } from '@mediaseal-webui/types/schemas';
import { deleteEmailInvite, getEmailInvites, resendEmailInvite } from '@mediaseal-webui/actions';
import { emailInviteHeader } from 'data';
import { Replay as ReplayIcon, Delete as RemoveIcon } from '@material-ui/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import { useEmailStyles } from './Email.styles';
import { setConfirmDialogue } from 'actions/misc';

const Email = (): JSX.Element => {
    const classes = useEmailStyles();
    const noSortHeader = emailInviteHeader.map((col) => ({ ...col, disableSort: true }));
    const dispatch = useDispatch();
    const { emails } = useSelector((state: InitialState) => state.settings);

    useEffect(() => {
        dispatch(getEmailInvites());
    }, [dispatch]);

    return (
        <MainTable
            entities={emails.invites ?? []}
            searchValue=''
            tableActionsRenderer={(entity: GenericEntity) => (
                <>
                    <Tooltip title='Delete invite' aria-label='delete'>
                        <IconButton
                            aria-label='delete'
                            color='inherit'
                            className={classes.iconButton}
                            onClick={() => {
                                const action = () => {
                                    dispatch(deleteEmailInvite(Number(entity.id)));
                                    dispatch(getEmailInvites());
                                };
                                dispatch(
                                    setConfirmDialogue({
                                        action,
                                        icon: 'delete',
                                        title: 'Delete Invite',
                                        message: 'Are you sure you want to delete this invitation?',
                                        confirmButtonText: 'Delete'
                                    })
                                );
                            }}>
                            <RemoveIcon fontSize='inherit' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Resend invite' aria-label='resend'>
                        <IconButton
                            aria-label='resend'
                            color='inherit'
                            className={classes.iconButton}
                            onClick={() => dispatch(resendEmailInvite(Number(entity.id)))}>
                            <ReplayIcon fontSize='inherit' />
                        </IconButton>
                    </Tooltip>
                </>
            )}
            fetchFn={() => Promise.resolve()}
            totalPages={1}
            columns={noSortHeader}
            noResultsMessage='No email invites'
            onRowClick={() => () => null}
            id='email-invites-table'
        />
    );
};
export { Email };
