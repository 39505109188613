import { msApi } from '@mediaseal-webui/api';
import { ThunkResult, Dispatchable } from '@mediaseal-webui/types/redux';
import { SET_EMAIL_INVITES, SET_SNACKBAR } from '@mediaseal-webui/constants';

export const getEmailInvites = (): ThunkResult<Promise<Dispatchable | void>> => async (dispatch) => {
    try {
        const { data } = await msApi.get('/notifications/emails');
        dispatch({ type: SET_EMAIL_INVITES, payload: data });

        return data;
    } catch (error) {
        console.error(error);
    }
};

export const resendEmailInvite =
    (id: number): ThunkResult<Promise<Dispatchable | void>> =>
    async (dispatch) => {
        try {
            const { data } = await msApi.post(`/notifications/emails/${id}/resend`);
            dispatch({
                type: SET_SNACKBAR,
                payload: { success: true, message: 'Verification email resent' }
            });

            return data;
        } catch (error) {
            console.error(error);
        }
    };

export const sendEmailInvite =
    (address: string): ThunkResult<Promise<Dispatchable | void>> =>
    async (dispatch) => {
        try {
            await msApi.post(`/notifications/emails`, {
                emailAddress: address
            });
            dispatch({
                type: SET_SNACKBAR,
                payload: { success: true, message: 'Invitation sent' }
            });
            dispatch(getEmailInvites());
        } catch (error) {
            console.error(error);
        }
    };

export const deleteEmailInvite =
    (id: number): ThunkResult<Promise<Dispatchable | void>> =>
    async (dispatch) => {
        try {
            await msApi.delete(`/notifications/emails/${id}`);
            dispatch({
                type: SET_SNACKBAR,
                payload: { success: true, message: 'Invitation has been deleted' }
            });
            dispatch(getEmailInvites());
        } catch (error) {
            console.error(error);
        }
    };
