import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Modal, Typography, Fab, Button, Dialog, DialogActions, DialogTitle, CircularProgress } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import InviteForm from '../InviteForm';
import InviteTable from '../InviteTable';
import { addDraftInvite, deleteDraftInvite, clearDraftInvites, dispatchDraftInvites } from '@mediaseal-webui/actions';
import { useForm, useMobile } from '@mediaseal-webui/hooks';
import useStyles from './InviteModal.styles';

const InviteModal = ({ open, setModalOpen }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { clear } = useForm();

    const invitations = useSelector(({ invitations: { draftInvites } }) => draftInvites);
    const isMobile = useMobile();
    const [submittable, setSubmittable] = useState(false);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [sending, setSending] = useState(false);
    const [sendables, setSendables] = useState(0);
    const [acceptables, setAcceptables] = useState(0);

    useEffect(() => setSubmittable(sendables > 0), [sendables, setSubmittable]);

    // if all invites are sent correctly, close the modal
    useEffect(() => {
        const closable = acceptables > 0 && acceptables === invitations.length && sendables === 0;
        if (closable) {
            dispatch(clearDraftInvites());
            clear();
            setModalOpen(false);
        }
    }, [acceptables, sendables, setModalOpen, invitations, dispatch, clear]);

    useEffect(() => {
        setSendables(invitations.filter((invite) => invite.status === undefined).length);
        setAcceptables(invitations.filter((invite) => ['PENDING', 'ACCEPTED'].includes(invite.status)).length);
    }, [invitations, setModalOpen, setSubmittable, setSendables, setAcceptables]);

    const handleAdd = (formInputs) => dispatch(addDraftInvite(formInputs));
    const handleDelete = (invite) => dispatch(deleteDraftInvite(invite));

    const handleSubmit = () => {
        setSending(true);
        dispatch(dispatchDraftInvites(setSending));
        setSubmittable(false);
    };

    const handleCancel = () => {
        const sendables = invitations.filter((invite) => invite.status === undefined);
        if (sendables.length > 0) {
            setCancelDialogOpen(true);
        } else {
            dispatch(clearDraftInvites());
            clear();
            setModalOpen(false);
        }
    };

    const handleCancelConfirm = () => {
        dispatch(clearDraftInvites());
        setSubmittable(false);
        setCancelDialogOpen(false);
        clear();
        setModalOpen(false);
    };

    return (
        <Modal data-testid='invite-modal' id='invite-modal' aria-labelledby='modal-title' open={open}>
            <>
                <Grid container className={classes.container} direction='column' justify='center' alignItems='center'>
                    <Grid container className={classes.titleContainer} direction='row' justify='space-between' alignItems='flex-start'>
                        <Grid item>
                            <Typography color='textPrimary' className={classes.title} variant={isMobile ? 'h6' : 'h4'} id='modal-title'>
                                Invite Decryptor Users
                            </Typography>
                        </Grid>
                    </Grid>

                    <InviteForm classes={classes} addDraftInvite={handleAdd} />

                    {sending && (
                        <div className={classes.overlay}>
                            <CircularProgress size={24} />
                        </div>
                    )}

                    <InviteTable invites={invitations} deleteDraftInvite={handleDelete} />

                    <Grid container direction='row' alignItems='flex-end'>
                        <Grid item xs className={classes.buttonContainer}>
                            <Button id='cancel-btn' className={classes.cancelBtn} data-testid='cancel-btn' onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Fab
                                id='invite-users-btn'
                                variant='extended'
                                type='submit'
                                color='primary'
                                disabled={!submittable}
                                data-testid='inviteUsersBtn'
                                onClick={handleSubmit}>
                                <SendIcon className={classes.extendedIcon} />
                                Invite User{sendables > 1 && 's'}
                            </Fab>
                        </Grid>
                    </Grid>
                </Grid>

                <Dialog aria-labelledby='cancel-dialog-title' id='cancel-dialog' open={cancelDialogOpen}>
                    <DialogTitle id='cancel-dialog-title'>Are you sure you want to cancel sending invites?</DialogTitle>
                    <DialogActions>
                        <Button id='cancel-btn' data-testid='cancel-btn' onClick={() => setCancelDialogOpen(false)}>
                            Cancel
                        </Button>
                        <Fab variant='extended' data-testid='cancel-confirm-btn' onClick={handleCancelConfirm} color='primary'>
                            Confirm
                        </Fab>
                    </DialogActions>
                </Dialog>
            </>
        </Modal>
    );
};

InviteModal.propTypes = {
    open: PropTypes.bool,
    setModalOpen: PropTypes.func
};

InviteModal.defaultProps = {
    open: false
};

export default InviteModal;
