import React from 'react';
import { DialogContent, TextField, DialogActions, useMediaQuery } from '@material-ui/core';
import DateRangePicker from '../../../utility-components/DateRangePicker';
import { addDays } from 'date-fns';
import { Autocomplete } from '@material-ui/lab';
import timezones from '../../../../data/timezones';
import { setEditValues } from '../../../../actions/entity';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '@mediaseal-webui/components';
import { useParams } from 'react-router';
import { updateJob } from '../../../../actions/jobs';
import { useTheme } from '@material-ui/core/styles';
import { Theme, EntityState, SunsetSunriseProps } from '.';
import { setSecurtyOptions } from '../../../../actions/jobs';
import { InitialState } from '@mediaseal-webui/types/redux/state';
import FormGroup from '../../../utility-components/FormGroup';
import CheckBoxHeading from '../../../utility-components/CheckBoxHeading';
import { ActionContainer, Seperator, Dialog, CalenderContainer } from './SunsetSunrise.styles';

const SunsetSunrise = ({ openProps, startDate = new Date(), endDate = addDays(new Date(), 365), disabled }: SunsetSunriseProps) => {
    const [open, setOpen] = openProps;
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const theme = useTheme<Theme>();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const { defaultTimeZone, disableSunset } = useSelector(({ jobs }: InitialState) => jobs.securityOptions);
    const securityRestrictions = useSelector((state: EntityState) => state.entity.profile.securityRestrictions);
    const handleTimeZone = (event: React.ChangeEvent<{}>, value: string | null): any => dispatch(setEditValues({ timezone: value }));

    const handleCloseClick = (): void => setOpen(false);

    const handleSave = (): void => {
        dispatch(updateJob(id));
        setOpen(false);
    };
    const checkboxChangeHandler = (value: string) => (event: React.ChangeEvent<HTMLInputElement>) =>
        dispatch(setSecurtyOptions({ [value]: !event.target.checked }));
    // const getDates = (start: Date, end: Date) =>
    const sunrise = (): Date => (securityRestrictions && securityRestrictions.length > 0 ? new Date(securityRestrictions[0].sunrise) : startDate);
    const sunset = (): Date => (securityRestrictions && securityRestrictions.length > 0 ? new Date(securityRestrictions[0].sunset) : endDate);
    const timezone = (): string =>
        securityRestrictions && securityRestrictions.length > 0
            ? securityRestrictions[0].sunriseDateTimeZoneString.split(',')[1].trim()
            : defaultTimeZone || '';
    // useEffect(() => {
    //   timezones.forEach((zone, index) => zone === defaultTimeZone ? setDefaultZoneIndex(index) : null)
    // }, [securityRestrictions])
    const handleSunriseSunset = (value: string) => (date: Date) => dispatch(setEditValues({ [value]: date }));

    const readOnly = disableSunset || disabled;

    return (
        <Dialog open={open} maxWidth='md' fullScreen={matches}>
            <DialogContent data-testid='date-range-pickers'>
                <FormGroup
                    checkbox
                    heading={
                        <CheckBoxHeading
                            checked={!disableSunset}
                            changeFn={checkboxChangeHandler('disableSunset')}
                            text='Sunset / Sunrise time'
                            disabled={disabled}
                        />
                    }
                    subtitle='Determine the times in which Mediaseal Users may access encrypted files'>
                    <CalenderContainer>
                        <DateRangePicker
                            toDateSetter={handleSunriseSunset('sunset')}
                            fromDateSetter={handleSunriseSunset('sunrise')}
                            // submitFn={handle}
                            inline
                            minDate={new Date()}
                            maxDate={disableSunset ? new Date() : null}
                            showTime
                            disabled={readOnly}
                            defaultStartDate={sunrise()}
                            defaultEndDate={sunset()}
                        />
                    </CalenderContainer>
                </FormGroup>
                <FormGroup heading='Assign Timezone' subtitle='Select a relevant timezone for the set dates and times'>
                    <Autocomplete
                        id='timezone-select'
                        options={timezones}
                        // className={classes.timeZone}
                        defaultValue={timezone()}
                        onChange={handleTimeZone}
                        disabled={readOnly}
                        getOptionLabel={(option) => option}
                        style={{ width: '100%' }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ width: '100%', zIndex: 0 }}
                                label='Select Timezone'
                                variant='outlined'
                                disabled={disableSunset || disabled}
                            />
                        )}
                    />
                </FormGroup>
            </DialogContent>
            <ActionContainer>
                <Seperator />
                <DialogActions>
                    <Actions saveFn={handleSave} closeFn={handleCloseClick} />
                </DialogActions>
            </ActionContainer>
        </Dialog>
    );
};

export default SunsetSunrise;
