import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { MainTable } from '@mediaseal-webui/components';
import { removeEntityAndUpdate, removeDecryptorUserFromSummary } from '../../../../../actions/entity';
import { profileUserHeader, profileUserHeaderWithoutActions } from '../../../../../data/profile-table-headers';
import ActionContainer from '../../../../table-components/ActionContainer/ActionContainer';
import { useAuth } from '@mediaseal-webui/hooks';
import { InitialState } from '@mediaseal-webui/types/redux';
import { GenericEntity } from '@mediaseal-webui/types/schemas';
import { setConfirmDialogue, fetchUsers, updateGroup } from '@mediaseal-webui/actions';
import { hideRemoveUserConfirmation } from '@mediaseal-webui/constants';
import { useAppSelector } from 'store';

export const DecUserTable = () => {
    const { id } = useParams<{ id: string }>();
    const auth = useAuth();
    const isCreateAuth = auth.create || auth.administer;

    const { decryptorUsers } = useSelector(({ entity: { profile } }: InitialState) => profile);
    const { userPageCount } = useSelector(({ users }: InitialState) => users);
    const dispatch = useDispatch();
    const { items: permissions } = useAppSelector((state) => state.permissions);
    const hasEditEncryptorGroupsPermission = permissions.some((permission) => permission.permissionName === 'canEditEncryptorGroups');

    const decryptorUserRemoveHandler = (user: GenericEntity) => () =>
        dispatch(
            setConfirmDialogue({
                icon: 'warning',
                title: 'Remove User',
                confirmButtonText: 'Remove User',
                message: 'Are you sure you want to remove this user?',
                action: removeEntityAndUpdate({
                    entity: user,
                    id,
                    removeFn: removeDecryptorUserFromSummary,
                    updateFn: updateGroup
                }),
                memorable: hideRemoveUserConfirmation
            })
        );

    const handleDecUserRowClick = () => {};
    const handleRemoveDecryptorUser = useCallback(decryptorUserRemoveHandler, [dispatch, id]);

    return (
        <MainTable
            id='decUsersTable'
            entities={decryptorUsers || []}
            totalPages={userPageCount}
            // @ts-expect-error due to missing props ts(2322).
            fetchFn={fetchUsers}
            columns={hasEditEncryptorGroupsPermission ? profileUserHeader : profileUserHeaderWithoutActions}
            tableActionsRenderer={(entity: GenericEntity) => <ActionContainer create={isCreateAuth} removeAction={handleRemoveDecryptorUser(entity)} />}
            onRowClick={handleDecUserRowClick}
            noResultsMessage='No Decryptor Users are assigned to this group'
        />
    );
};
