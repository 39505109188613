export const NEW_FILES_AVAILABLE = 'NEW_FILES_AVAILABLE';

export const SET_STATUS_FILTER = 'SET_STATUS_FILTER';

export const CLEAR_STATUS_FILTER = 'CLEAR_STATUS_FILTER';

export const SET_ACTIVE_JOB_ID = 'SET_ACTIVE_JOB_ID';

/**
 * Flag to remove a feature
 */

export const REMOVE_JOB_FEATURE = 'REMOVE_JOB_FEATURE';
