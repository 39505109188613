import React from 'react';
import { useProfileState } from '@mediaseal-webui/hooks';
import { GenericHeaderItemProps, HeaderItem } from '..';
import { EditableHeaderItem } from '../..';
import { BadgeIcon } from '../../BadgeIcon';
import { EditableHeaderChildProps } from '../../EditableHeaderItem';
import { Attachment as AttachmentIcon } from '@material-ui/icons';
import { useAppSelector } from '@mediaseal-webui/online/src/store';

export const JobsHeaderItems = ({ isFetching, onClick, tooltipOpen }: GenericHeaderItemProps) => {
    // const { jobs } = useProfileState()
    const { jobs } = useAppSelector((state) => state.entity.profile);
    return (
        <EditableHeaderItem onViewClick={onClick} title='Job' total={jobs.length} open={tooltipOpen}>
            {({ hovering }: EditableHeaderChildProps) => (
                <HeaderItem
                    hovering={hovering}
                    Icon={() => <BadgeIcon Icon={AttachmentIcon} isHovered={hovering} content={jobs.length} />}
                    isFetching={isFetching}
                    id='title-jobs'
                />
            )}
        </EditableHeaderItem>
    );
};
