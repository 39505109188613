import Axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from 'axios';
import { SortOptions, SortOrders, SortStrings } from '@mediaseal-webui/types';
import { filterContentArray } from '../utils';
import { STOP_FETCHING } from '@mediaseal-webui/constants/src/misc';
import { genBasicAuth, genBearerAuth } from '@mediaseal-webui/utils';
import { isKeycloakActive } from '@mediaseal-webui/hooks/src/isKeycloakActive';

let location = '';
//@ts-ignore
if (typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope) {
    location = self.location.origin;
} else {
    location = window.location.origin;
}
const intialParams = {
    page: 0,
    sortOrder: 'desc' as SortOrders,
    sortBy: 'createDate' as SortOptions,
    sort: 'createDate,desc',
    size: 100,
    keyword: ''
};
const PROD_LOCATION = `${location}/EvsServices/api/`;
const LOCAL_LOCATION = process.env.REACT_APP_LOCAL_ENDPOINT + '/';
export interface BaseServiceParams {
    page: number;
    sortOrder: SortOrders;
    sortBy: SortOptions;
    sort: SortStrings | string;
    size: number;
    keyword?: string;
}
export class BaseService<T, K = {}> {
    baseService: AxiosInstance;
    baseParams: BaseServiceParams;
    constructor(public url: string, protected config?: AxiosRequestConfig) {
        this.baseService = this._createInstance();
        this.baseParams = intialParams;
    }

    private _createInstance(): AxiosInstance {
        const api = Axios.create({
            baseURL: process.env.NODE_ENV === 'production' ? PROD_LOCATION : LOCAL_LOCATION,
            ...this.config

            // auth: {
            //   username: this.config?.auth?.username || '',
            //   password: this.config?.auth?.password || '',
            // },
        });

        api.interceptors.request.use(async (request) => {
            if (typeof window !== 'undefined') {
                const { keycloak } = await import('../AuthService/keycloak');
                /**
                 * This is needed so no error is thrown in a webworker
                 */
                request.headers.authorization = isKeycloakActive()
                    ? genBearerAuth(keycloak.token)
                    : genBasicAuth({
                          username: window.$store?.getState().misc.userCredentials.username,
                          password: window.$store?.getState().misc.userCredentials.password
                      });
            } else {
                request.headers.authorization = this.config?.headers.authorization;
            }
            // window?.$store?.dispatch({ type: START_FETCHING });
            return request;
        });
        api.interceptors.response.use(
            (response) => {
                const { data } = response;
                const { content } = data;
                if (typeof window !== 'undefined') {
                    window?.$store?.dispatch({ type: STOP_FETCHING }); //can be removed
                }

                if (content && Array.isArray(content)) {
                    const filteredArr = filterContentArray(content);
                    return {
                        ...response,
                        data: {
                            ...data,
                            content: filteredArr
                        }
                    };
                }

                return response;
            },
            (error) => {
                const { response } = error;
                const shouldRefresh = response.status === 401 && (response.headers['www-authenticate'] || '').search(/invalid_token.*stale token/i) !== -1;

                if (shouldRefresh) {
                    window.location.reload();
                }
            }
        );

        return api;
    }

    async get(params?: string): Promise<AxiosResponse<T>> {
        try {
            return await this.baseService.get(`${this.url}${params || ''}`);
        } catch (error) {
            console.log(error);
            return error;
        }
    }

    async post(body: T): Promise<AxiosResponse<T>> {
        return await this.baseService.post(this.url, body);
    }

    async patch(params: string = '', body: Partial<T> | T) {
        try {
            return await this.baseService.patch(`${this.url}${params}`, body);
        } catch (error) {
            console.error(error);
        }
    }
    async put(id: String, body: T) {
        try {
            return await this.baseService.put(`${this.url}/${id}`, body);
        } catch (error) {
            console.error(error);
        }
    }

    async delete() {
        return await this.baseService.delete(this.url);
    }

    public setParams(params: Partial<BaseServiceParams>) {
        this.baseParams = {
            ...this.baseParams,
            ...params
        };
    }

    public resetParams() {
        this.baseParams = intialParams;
    }

    protected _createQueryString(childParams: K) {
        this._createSortString();
        const params = this._reducer(childParams);
        return params.slice(0, params.length - 1);
    }

    private _createSortString() {
        this.baseParams.sort = `${this.baseParams.sortBy},${this.baseParams.sortOrder}`;
    }

    private _reducer(params: BaseServiceParams | K) {
        return Object.entries(params).reduce((acc, [key, val]) => {
            if (key === 'sortBy' || key === 'sortOrder') return acc;
            return `${acc}${key}=${val}&`;
        }, '?');
    }
}
