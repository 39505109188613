import React from 'react';
import { Content } from '@mediaseal-webui/components';
import { Header } from '../../components/layout-components';
import { useStyles, useContentStyles } from '.';
import { serverSettingData, appSettings, themeSettings, emailSettings, encryptSettingData } from './Settings.data';
import { SettingsCard } from './SettingsCard';
import { Typography } from '@material-ui/core';
import { useAppSelector } from 'store';
import { EmailSettingsCard } from './SettingsCard/Email/Card/EmailSettingsCard';
import { isKeycloakActive } from '@mediaseal-webui/hooks/src/isKeycloakActive';

export const Settings = () => {
    const classes = useStyles();
    const contentClasses = useContentStyles();
    const { items: permissions } = useAppSelector((state) => state.permissions);
    const isSettingAuth = permissions.some((perm) => perm.permissionName === 'canAdministerGlobalSettings');

    return (
        <div className={classes.viewRoot}>
            <Header
                list
                name='Settings'
                ActionButton={false}
                displayingText=''
                breadcrumbProps={{
                    links: [{ id: 0, name: 'Settings', to: '/settings' }],
                    text: [{ id: 0, name: '' }]
                }}
            />

            <Content classes={contentClasses}>
                {(cx) => (
                    <div className={classes.formContainer}>
                        {isSettingAuth && (
                            <>
                                <Typography color='textPrimary' className={classes.heading} variant='overline'>
                                    Server Settings
                                </Typography>
                                {serverSettingData
                                    .filter((card, _) => isKeycloakActive() || !card.isKeycloakOnlyComponent)
                                    .map((card, index) => (
                                        <SettingsCard key={index} cardData={card} Component={card.Component ?? null} />
                                    ))}
                                {encryptSettingData
                                    .filter((card, _) => isKeycloakActive() || !card.isKeycloakOnlyComponent)
                                    .map((card, index) => (
                                        <SettingsCard key={index} cardData={card} Component={card.Component ?? null} />
                                    ))}
                            </>
                        )}
                        <Typography color='textPrimary' className={classes.heading} variant='overline'>
                            Email Notification Settings
                        </Typography>
                        {emailSettings.map((card, index) => (
                            <EmailSettingsCard key={index} cardData={card} Component={card.Component} />
                        ))}

                        <Typography color='textPrimary' className={classes.heading} variant='overline'>
                            Misc. SETTINGS
                        </Typography>
                        {themeSettings.map((card, index) => (
                            <SettingsCard key={index} cardData={card} Component={card.Component} />
                        ))}
                        {window.qt && (
                            <>
                                <Typography color='textPrimary' className={classes.heading} variant='overline'>
                                    APPLICATION SETTINGS
                                </Typography>
                                {appSettings.map((card, index) => (
                                    <SettingsCard key={index} cardData={card} Component={card.Component} />
                                ))}
                            </>
                        )}
                    </div>
                )}
            </Content>
        </div>
    );
};
