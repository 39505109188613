import { makeStyles } from '@material-ui/core';
const WIDTH = 72;
const HEIGHT = 32;
const useStyles = makeStyles((theme) => ({
    tableCell: {
        borderBottom: 'unset',
        // padding: '10px 10px 10px 0',
        [theme.breakpoints.down('sm')]: {
            padding: 5
        }
    },
    active: {
        width: WIDTH,
        height: HEIGHT,
        background: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    inactive: {
        width: WIDTH,
        height: HEIGHT,
        background: theme.palette.pills.inactive,
        color: theme.palette.common.white
    },
    implicitInactive: {
        width: WIDTH,
        height: HEIGHT,
        background: theme.palette.pills.inactive,
        color: theme.palette.common.white
    }
}));

export const useTooltipStyles = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.grey[200],
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11
    },
    arrow: {
        color: theme.palette.grey[200]
        // boxShadow: theme.shadows[1],
    },
    tooltipHover: {
        alignItems: 'baseline',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    tooltipActive: {
        color: theme.palette.success.main,
        opacity: 1
    },
    tooltipInactive: {
        color: 'red',
        opacity: 1
    },
    icon: {
        fontSize: theme.typography.pxToRem(15),
        marginLeft: theme.spacing(0.25),
        color: theme.palette.info.light,
        alignSelf: 'baseline',
        display: 'inline-flex'
    }
}));

export default useStyles;
