import { useKeycloakConditionally } from '@mediaseal-webui/hooks';
import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { useMemo } from 'react';
import { StyledIframe } from './index.styles';

export const AccountIframe = () => {
    const { keycloak } = useKeycloak();
    const iFrameUrl = useMemo(() => keycloak?.createAccountUrl(), [keycloak]);

    return <StyledIframe title='account-page' src={iFrameUrl} seamless sandbox='allow-same-origin allow-scripts allow-forms' />;
};
