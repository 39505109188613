import React from 'react';
import { MultiOptionBtnOptions } from '../MultipOptionBtn';
import Fade from '@material-ui/core/Fade';
import { StyledMenu, StyledMenuItem } from './FlyoutMenu.style';

export interface FlyoutMenuProps {
    setter: React.Dispatch<React.SetStateAction<Element | null>>;
    setStateFn: React.Dispatch<React.SetStateAction<MultiOptionBtnOptions>>;
    anchor: Element | null;
    option: MultiOptionBtnOptions;
}
export const FlyoutMenu = ({ setter, anchor, option, setStateFn }: FlyoutMenuProps) => {
    const handleClose = () => {
        setter(null);
    };

    const handleClick = () => {
        setStateFn(option);
        setter(null);
    };

    return (
        <StyledMenu
            id='flyout-menu'
            anchorEl={anchor}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            PaperProps={{
                style: {
                    width: 152
                }
            }}
            open={Boolean(anchor)}
            TransitionComponent={Fade}
            onClose={handleClose}>
            <StyledMenuItem id='styled-menu-item' onClick={handleClick}>
                <option.icon style={{ marginRight: 5 }} />
                {option.text.toLocaleUpperCase()}
            </StyledMenuItem>
        </StyledMenu>
    );
};
