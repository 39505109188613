import React, { FC } from 'react';
import { TooltipRoot, TooltipText } from './InputTooltip.style';

export interface InputTooltipProps {
    /** Flag for whether to show the tooltip */
    show?: boolean;
    /** Main text to display */
    title?: string;
    /**  */
    above?: boolean;
}

export const InputTooltip: FC<InputTooltipProps> = ({ show, title, above, children }) => {
    return (
        <TooltipRoot show={show} above={above}>
            {title && <TooltipText>{title}</TooltipText>}
            {children}
        </TooltipRoot>
    );
};
