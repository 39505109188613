import { makeStyles } from '@material-ui/core/styles';

const useBottomNavStyles = makeStyles((theme) => ({
    bottomNav: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        height: theme.sizes.bottomNav,
        zIndex: 100,
        backgroundColor: `${'#2a2d3e'}`,
        left: 0,
        color: theme.palette.common.white,
        textDecoration: 'none'
    },
    navLinks: {
        color: theme.palette.common.white,
        opacity: 0.65,
        fontSize: '1em',
        '&:active': {
            color: theme.palette.common.white
        },
        '&:hover': {
            color: theme.palette.common.white
        }
    },
    active: {
        color: theme.palette.common.white,
        borderBottom: `2px solid ${'#2f9eb3'}`,
        backgroundColor: '#323345'
    },
    label: {
        borderBottom: 'none'
    },
    normalize: {
        width: 20,
        height: 20,
        color: theme.palette.common.white
    },
    more: {
        width: 25,
        height: 25,
        fontSize: '1.5rem'
    }
}));

export default useBottomNavStyles;
