import { msApi } from '@mediaseal-webui/api';
import { ThunkResult, Dispatchable } from '@mediaseal-webui/types/redux';
import { SettingStateKeys } from '@mediaseal-webui/types/settings';
import { RootState } from 'packages/online/src/store';
import { setError } from '../entity';
import { initialFetch, stopFetching } from '../generic';
import { sendSettingsToQt } from './sendSettingsToQt';
import { storeServerSettings } from './storeServerSettings';

interface SettingsData {
    value: string;
    name: SettingStateKeys;
}
export const getServerSettings = (): ThunkResult<Promise<Dispatchable | void>> => async (dispatch, getState: () => RootState) => {
    try {
        const { data } = await msApi.get('/settings');
        const { content }: { content: SettingsData[] } = data;
        const encryptionPrefix = content.find((setting) => setting.name === 'encryptionPrefix')?.value;
        const studioName = content.find((setting) => setting.name === 'studioName')?.value;
        const username = getState().user.apiUser?.loginName;
        dispatch(storeServerSettings(content));
        dispatch(
            sendSettingsToQt({
                encryptionPrefix: encryptionPrefix || 'enc-',
                studioName: studioName || 'MEDIASEAL',
                username
            })
        );
        dispatch(initialFetch());
        return dispatch(stopFetching());
    } catch (error) {
        console.log(error);
    }
};
