import styled, { css, DefaultTheme } from 'styled-components';
import { ToggleData } from './ToggleSlider';
import { makeStyles } from '@material-ui/core/styles';

const findElementsIndex = (element: ToggleData, array: ToggleData[]) => {
    return array.indexOf(element);
};

interface ToggleOptionProps {
    selected: boolean;
    disabled: boolean;
    theme: DefaultTheme;
}
export const ToggleOption = styled.span`
    width: 85px;
    height: 100%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    z-index: 2;
    cursor: default;
    //@ts-ignore
    color: ${({ selected, theme }: ToggleOptionProps) => (selected ? 'white' : theme.palette.text.primary)};
    ${({ disabled, selected }) =>
        !disabled &&
        css`
            &:hover {
                cursor: pointer;
                background: ${selected ? '#024B78' : 'rgba(0, 126, 156, 0.5)'};
                transition: background-color 0.2s ease;
            }
        `}
`;

interface StyledSliderProps {
    data: ToggleData[];
    selectedValue: string;
    disabled: boolean;
}
export const StyledSlider = styled.div<StyledSliderProps>(
    ({ theme, disabled, data, selectedValue }) => css`
        width: 180px;
        height: 42px;
        border-radius: 20px;
        border: 1px solid #eee;
        padding: 2px;
        display: flex;
        margin-left: 8px;
        position: relative;
        justify-content: space-between;
        &:before {
            width: 85px;
            top: 2;
            content: '';
            height: calc(100% - 4px);
            border-radius: 20px;
            background: ${disabled ? 'rgba(0,0,0,0.24)' : 'linear-gradient(90deg, rgba(2, 75, 120, 1), rgba(11, 176, 190, 1))'};
            display: flex;
            position: absolute;
            left: ${findElementsIndex(data?.find((item) => item.value === selectedValue) || ({} as ToggleData), data) === 0 ? '2px' : '91px'};
            transition: left 0.2s linear;
        }
        @media (max-width: 1280px) {
            width: 88px;
            &:before {
                width: 40px;
                left: ${findElementsIndex(data?.find((item) => item.value === selectedValue) || ({} as ToggleData), data) === 0 ? '2px' : '44px'};
            }
            ${ToggleOption} {
                width: 40px;
            }
        }

        ${theme.breakpoints.down('xs')} {
            height: 180px;
            width: 42px;
            flex-direction: column;
            align-items: center;
            margin: 8px;
            &:before {
                height: 85px;
                left: 2px;
                display: flex;
                position: absolute;
                content: '';
                width: calc(100% - 4px);
                top: ${findElementsIndex(data?.find((item) => item.value === selectedValue) || ({} as ToggleData), data) === 0 ? '2px' : '91px'};
            }
        }
    `
);

export const useTooltipStyles = makeStyles((theme) => ({
    tooltip: {
        fontSize: 'small',
        color: theme.palette.common.black,
        borderRadius: 10,
        zIndex: 1,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #eee'
    },
    popper: {
        zIndex: 100
    }
}));
