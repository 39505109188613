import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
        '& .summary': {
            width: '100%',
            margin: 0,
            padding: 0
        },
        '& .progress': {
            display: 'inline-flex',
            width: '90%'
        },
        '& .iconButton': {
            display: 'flex',
            width: '25%',
            justifyContent: 'flex-end'
        },
        '& .encryptionInfo': {
            width: '75%'
        },
        '& .progressContainer': {
            display: 'flex',
            alignItems: 'flex-start'
        },
        '& .percentage': {
            margin: 0,
            width: '10%',
            paddingLeft: 15,
            lineHeight: 0,
            display: 'inline-flex'
        },
        display: 'flex',
        minWidth: 500,
        maxWidth: 500,
        padding: '10px 20px 10px 20px',
        flexWrap: 'wrap',
        alignItems: 'center',
        position: 'fixed',
        borderRadius: '2px 2px 0 0',
        bottom: 0,
        right: 200,
        transition: 'width 2s',
        minHeight: 75,
        zIndex: 20
    }
}));

export default useStyles;
