import { msApi } from '@mediaseal-webui/api';
import { NEW_FILES_AVAILABLE, STORE_ENCRYPTED_ACL } from '@mediaseal-webui/constants';
import { EncryptFile, FileInfo } from '@mediaseal-webui/types/encryptor';
import { ThunkResult } from '@mediaseal-webui/types/redux';
import { setError } from '../../entity';
import { processEncryptionWork } from '../processes';

export const handleBatchAcl =
    (fileInfo: FileInfo, workId: string | number): ThunkResult<Promise<ThunkResult<void>>> =>
    async (dispatch, getState) => {
        try {
            dispatch({ type: NEW_FILES_AVAILABLE, payload: true });
            await msApi.post('/files', fileInfo);
            const { data } = await msApi.get(`/files/${fileInfo.idFile}/fullEncryptedAcl`);
            const { encryptedAcl } = data;
            dispatch({
                type: STORE_ENCRYPTED_ACL,
                payload: { workId, batchEncryptedAcl: encryptedAcl }
            });
            console.log('Storing the ACL: ' + encryptedAcl);
        } catch (error) {
            dispatch(setError(error));
        }

        return dispatch(processEncryptionWork());
    };
