import React, { useState } from 'react';
import clsx from 'clsx';
import { Drawer, List, Grid, Fab, Divider } from '@material-ui/core';
import { ChevronLeft as ChevronLeftIcon } from '@material-ui/icons';
import { links, encryptorLinks, decryptorLinks } from '../../../routes/links';
import Link from './Link';
import { useLogout, useSideNav } from '@mediaseal-webui/hooks';
import HelpPopper from './HelpPopper/HelpPopper';
import ApplicationInformation from './ApplicationInformation/ApplicationInformation';
import { useSelector } from 'react-redux';
import useStyles from './SideNav.styles';
import { InitialState } from '@mediaseal-webui/types/redux';
import { INavLink } from '../../../routes';
import { useDefaultRoute } from 'hooks/useDefaultRoute';
import { SmallLogo } from '@mediaseal-webui/components';
import { useAppSelector } from 'store';
import { isKeycloakActive } from '@mediaseal-webui/hooks/src/isKeycloakActive';

const DOWNLOAD_LINK = 'https://mst.fortium.com/download-center';

const SideNav = () => {
    const classes = useStyles();

    /**
     * These states are used in useSideNav
     */
    const { sideNavOpen, setUserChoice } = useSideNav();
    const handleDrawerOpen = () => (sideNavOpen ? setUserChoice('in') : setUserChoice('out'));
    const [anchorEl, setAnchorEl] = useState<Element | null>(null); // popper
    const [popperOpen, setPopperOpen] = useState(false); // popper
    const { showAboutModal } = useSelector(({ misc }: InitialState) => misc);
    const { items: permissions } = useAppSelector((state) => state.permissions);
    const defaultRoute = useDefaultRoute();
    const handleLogout = useLogout();

    const handleHelpClick = (event: React.SyntheticEvent) => {
        // popper
        event.stopPropagation();
        event.preventDefault();

        setAnchorEl(sideNavOpen ? event.currentTarget : event.currentTarget.querySelector('.helpIcon'));
        setPopperOpen((prev) => !prev);
    };
    const renderDivider = (type: string, title: string) =>
        permissions.some((perm) => new RegExp(type, 'ig').test(perm.permissionName)) && (
            <>
                <p className={classes.subHeading}>{title}</p>
            </>
        );

    const NavLink = (link: INavLink) => (
        <Link key={link.id} link={link} id={link.navId} authCheck={link.authCheck} isRoot={defaultRoute.navId === link.navId} />
    );

    const handleDownloadClick = (event: React.SyntheticEvent) => {
        event.preventDefault();
        window.location.href = process.env.REACT_APP_DOWNLOAD_LINK || DOWNLOAD_LINK;
    };

    const determineUtilLayout = () =>
        clsx(classes.utils, {
            [classes.utilsClosed]: !sideNavOpen
        });

    const detemineLogoLayout = () =>
        clsx(classes.logo, {
            [classes.logoOpen]: sideNavOpen
        });

    return (
        <div className={classes.root}>
            <Drawer
                variant='permanent'
                open={sideNavOpen}
                className={clsx(classes.drawer, {
                    [classes.drawerClose]: !sideNavOpen
                })}
                classes={{
                    paper: clsx(classes.drawer, classes.paper, {
                        [classes.drawerClose]: !sideNavOpen
                    })
                }}>
                <div className={classes.logoChevContainer}>
                    {!window.qt && (
                        <div className={classes.logoContainer}>
                            <SmallLogo showText={sideNavOpen} className={detemineLogoLayout()} />
                        </div>
                    )}
                    <Fab
                        onClick={handleDrawerOpen}
                        id='navControl'
                        variant='round'
                        size='small'
                        classes={{
                            root: clsx(classes.chevron, {
                                [classes.chevronClosed]: !sideNavOpen
                            })
                        }}>
                        <ChevronLeftIcon fontSize='small' />
                    </Fab>
                </div>
                <Grid container className={classes.navContainer} direction='column' justify='space-between' alignItems='flex-start'>
                    <List className={window.qt ? classes.navLinkApp : classes.navLink}>
                        {/* {!window.qt ? <Divider className={classes.divider} /> : null} */}
                        <div className={classes.navItems}>
                            {links.map(NavLink)}
                            <Divider className={classes.divider} />
                            {renderDivider('canViewEncryptor', 'Encryptor')}
                            {encryptorLinks.filter((link, _) => isKeycloakActive() || !link.isKeycloakOnlyComponent).map(NavLink)}
                            <Divider className={classes.divider} />
                            {renderDivider('canViewDecryptor', 'Decryptor')}
                            {decryptorLinks.map(NavLink)}
                            {/* <Divider className={classes.divider} /> */}
                        </div>
                    </List>
                    <div className={determineUtilLayout()}>
                        <List>
                            <Link
                                id='settings'
                                useClass={classes.utilsLink}
                                authOverride
                                link={{
                                    id: 1,
                                    text: 'SETTINGS',
                                    link: '/settings',
                                    icon: 'fas fa-cog',
                                    navId: 'settingLink',
                                    authCheck: 'canAdministerGlobalSettings'
                                }}
                            />
                            <Link
                                id='help'
                                useClass={classes.utilsLink}
                                authOverride
                                help
                                link={{
                                    id: 2,
                                    text: 'HELP',
                                    link: '/help',
                                    icon: 'fas fa-question-circle',
                                    navId: 'helpLink'
                                }}
                                onClick={handleHelpClick}
                            />
                            {!window.qt ? (
                                <Link
                                    useClass={classes.utilsLink}
                                    authOverride
                                    onClick={handleDownloadClick}
                                    link={{
                                        id: 0,
                                        text: 'DOWNLOAD APP',
                                        icon: 'fas fa-download',
                                        navId: 'downloadApp',
                                        link: `${process.env.REACT_APP_DOWNLOAD_LINK}`
                                    }}
                                />
                            ) : null}
                            <Link
                                useClass={classes.utilsLink}
                                id='logout-btn'
                                authOverride
                                disableLink
                                onClick={handleLogout}
                                link={{
                                    id: 1,
                                    text: 'LOG OUT',
                                    icon: 'fas fa-sign-out-alt',
                                    navId: 'logout',
                                    link: ''
                                }}
                            />
                        </List>
                    </div>
                </Grid>
            </Drawer>
            {anchorEl && <HelpPopper anchorEl={anchorEl} open={popperOpen} setter={setPopperOpen} />}
            <ApplicationInformation open={showAboutModal} />
        </div>
    );
};

export default SideNav;
