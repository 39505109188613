export const SET_DEFAULT_DECRYPTOR_USER = 'SET_DEFAULT_DECRYPTOR_USER';
export const SET_REPORTING_FILTER = 'SET_REPORTING_FILTER';
export const SET_REPORT_DATA = 'SET_REPORT_DATA';
export const SET_MORE_REPORT_DATA = 'SET_MORE_REPORT_DATA';
export const SET_TOTAL_REPORT_PAGES = 'SET_TOTAL_REPORT_PAGES';
export const DISPLAY_ENTITY_SUMMARY = 'DISPLAY_ENTITY_SUMMARY';
export const SET_REPORT_ELEMENTS = 'SET_REPORT_ELEMENTS';
export const CLEAR_REPORT_DATA = 'CLEAR_REPORT_DATA';
export const CLEAR_REPORT_FILTER = 'CLEAR_REPORT_FILTER';
export const SET_AUDIT_TAB = 'SET_AUDIT_TAB';
