import React, { ComponentProps } from 'react';
import { DialogContent, Dialog } from '@material-ui/core';
import { StyledDialogue } from './CSVDialogue.styles';
import styled from 'styled-components';
export interface CSVDialogueProps extends ComponentProps<typeof Dialog> {
    status: {
        stage: string;
        progress: number;
    };
}

const Spinner = () => (
    <StyledSpinner viewBox='0 0 50 50'>
        <circle className='path' cx='25' cy='25' r='20' fill='none' strokeWidth='2' />
    </StyledSpinner>
);

const StyledSpinner = styled.svg`
    animation: rotate 1s linear infinite;
    margin: 40px;
    width: 100px;
    height: 100px;

    & .path {
        stroke: #007e9c;
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
`;

export const CSVDialogue = ({ ...others }: CSVDialogueProps) => {
    return (
        <StyledDialogue {...others}>
            <DialogContent>
                <Spinner />
            </DialogContent>
        </StyledDialogue>
    );
};
