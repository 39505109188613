import { msApi } from '@mediaseal-webui/api';
import { NEW_FILES_AVAILABLE } from '@mediaseal-webui/constants';
import { EncryptFile, EncryptionWork, FileInfo } from '@mediaseal-webui/types/encryptor';
import { setError } from '../../entity';
import { stopFetching } from '../../generic';
import { cancelAllWork } from './cancelAllWork';
import { processAllWork } from './processAllWork';
import { setStartJobType } from './setStartJobType';

export interface PostEncryptionFileProps {
    fileInfo: FileInfo;
    work: EncryptionWork;
}
export const postEncryptionFile = async ({ fileInfo, work }: PostEncryptionFileProps, item: EncryptFile, dispatch: any) => {
    const { idFile } = fileInfo;

    // // need to get the batchFileId and batchMediaKey from the EncryptionWork entry relative to the files id

    try {
        await msApi.post('/files', fileInfo);
        // lets call next actions
        // dispatch banner on job profile to signal new files POSTed
        dispatch({ type: NEW_FILES_AVAILABLE, payload: true });

        // if we find a job then lets start
        if (work) {
            await setStartJobType(
                {
                    id: idFile,
                    currentWork: work,
                    type: work.jobData.type,
                    mediaKey: item.mediaKey,
                    fileId: item.fileId
                },
                dispatch
            );
        }
    } catch (error) {
        dispatch(
            setError({
                message: `Something went wrong when notifying the server about the current file! Please try again.`
            })
        );
        dispatch(cancelAllWork(work));
        dispatch(processAllWork(work));
    }
    dispatch(stopFetching());
};
