import { combineReducers } from 'redux';
import { STORE_SERVER_SETTINGS, SET_ENCRYPTION_PERFORMANCE } from '@mediaseal-webui/constants';
import { ApplicationActions, EmailSettingsActions, MiscSettingsActions, ServerActions } from './types';
import { ApplicationState, MiscSettingState, ServerState } from '@mediaseal-webui/types/settings';
import { SET_THEME, SET_EMAIL_INVITES } from '@mediaseal-webui/constants/src/settings';
import { definitions } from '@mediaseal-webui/types/api';

type Email = definitions['NotificationEmailAddressResponseDTO'][];

const initialServerState = {
    encryptionPrefix: '',
    studioName: ''
};
export const server = (state: ServerState = initialServerState, action: ServerActions) => {
    switch (action.type) {
        case STORE_SERVER_SETTINGS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

const initialAppState = {
    encPerformanceLevel: 5
};

export const application = (state: ApplicationState = initialAppState, action: ApplicationActions) => {
    switch (action.type) {
        case SET_ENCRYPTION_PERFORMANCE:
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
};
const intiialMiscState = {
    theme: 'light'
};
export const misc = (state: MiscSettingState = intiialMiscState, action: MiscSettingsActions) => {
    switch (action.type) {
        case SET_THEME:
            return {
                ...state,
                theme: action.payload
            };
        default:
            return state;
    }
};

const initialEmailState: Email = [];
export const emails = (state: Email = initialEmailState, action: EmailSettingsActions) => {
    switch (action.type) {
        case SET_EMAIL_INVITES:
            return {
                ...state,
                invites: action.payload
            };
        default:
            return state;
    }
};

export default combineReducers({
    server,
    application,
    misc,
    emails
});
