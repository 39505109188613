import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Send } from '@material-ui/icons';
import { Header } from '../../../../components/layout-components';
import CreateForm from '../../../../components/users-components/creation-components/CreateForm';
import ScrollToTopButton from '../../../../components/utility-components/ScrollToTopButton';
import Summary from '../../../../components/utility-components/SummaryList/SummaryList';
import { createUser, clearGroups, fetchEncryptorGroups as fetchGroups } from '@mediaseal-webui/actions';
import { useEntityCreation, useMobile } from '@mediaseal-webui/hooks';
import { Content, SubmitButton as Submit } from '@mediaseal-webui/components';
import useStyles from './Create.styles';
import { useCleanEntity } from 'hooks/useCleanEntity';

const Create = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const mobile = useMobile();
    const { valid, entityCreationContext } = useEntityCreation();
    const { create } = useSelector(({ entity }) => entity);
    const { encryptorGroups: groups } = create;
    const { firstName, lastName, loginName, email } = entityCreationContext;
    const [open, setOpen] = useState(false);

    useEffect(() => {
        dispatch(
            fetchGroups({
                keyword: '',
                sort: 'id,asc',
                page: 0,
                scrolledToBottom: false,
                isSort: true,
                addToUsers: true
            })
        );
        return () => {
            dispatch(clearGroups());
        };
    }, [dispatch]);

    useCleanEntity();

    const name = () => (firstName?.value ? `${firstName.value} ${lastName.value}` : lastName?.value ?? '');
    const afterDate = () => (entityCreationContext ? ` - ${loginName?.value} ${email?.value}` : '');

    const handleSubmit = () => {
        dispatch(createUser(history));
        setOpen(!open);
    };

    const SubmitButton = (props) => (
        <Submit id='create-enc-user-button' data-testid='createEncUserBtn' title='Save User' Icon={Send} aria-label='Post User' {...props} disabled={!valid} />
    );
    const headerItems = {
        stateKey: 'create',
        encGroups: groups?.length > 0 ? {} : undefined
    };

    return (
        <div className={classes.viewRoot}>
            <Header
                defaultName='Create an Encryptor User'
                name={name()}
                date={new Date()}
                ActionButton={SubmitButton}
                onSubmit={handleSubmit}
                afterDate={afterDate()}
                breadcrumbProps={{
                    links: [{ id: 0, name: 'Encryptor Users', to: '/users/encryptor' }],
                    text: [{ id: 0, name: 'Create' }]
                }}
                headerItems={headerItems}
            />

            <Content>
                {(classes) => (
                    <div className={`${classes.wrapper} ${classes.cols}`}>
                        <CreateForm handleSubmit={handleSubmit} />
                        <div className={classes.summary}>
                            <Summary open={open} setOpen={setOpen} subtitleEntity='user' />
                        </div>
                    </div>
                )}
            </Content>

            {!mobile && <ScrollToTopButton scrollStepInPx='50' delayInMs={10} />}
        </div>
    );
};

export default React.memo(Create);
