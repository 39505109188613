import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormLabel, FormGroup, Typography } from '@material-ui/core';
import { setCreate, addDecryptorUserToSummary } from '../../../../actions/entity';
import { useDispatch, useSelector } from 'react-redux';
import TextArea from '../../../utility-components/TextArea';
import { MainTable, TextInput } from '@mediaseal-webui/components';
import { fetchUsers, clearUserPageSearchValue, setUserPageSearchValue } from '@mediaseal-webui/actions';
import useStyles from './CreateDecryptorFormStyle.styles';
import { actionableUserHeader } from '../../../../data';
import { useSearchWorker } from '../../../../hooks';
import { MSSearchBar as SearchBar } from '@mediaseal-webui/components';
import { useSummaryFilter, useEntityCreation } from '@mediaseal-webui/hooks';

const CreateDecryptorForm = ({ handleSubmit }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { filteredUsers, userPageCount, userPageSearchValue } = useSelector(({ users }) => users);
    const { filteredEntities } = useSummaryFilter({ entities: filteredUsers, type: 'decryptorUsers' });
    const { genericNameValidation } = useEntityCreation(['name']);
    const [userPage, setUserPage] = useState();
    const handleTextArea = (event) => dispatch(setCreate({ description: event.target.value }));
    const handleChange = (name) => (value) => dispatch(setCreate({ [name]: value }));
    const handleDecUserClick = (user) => dispatch(addDecryptorUserToSummary(user));
    const { searchWorker, token } = useSearchWorker();

    return (
        <form className={classes.root}>
            <div className={classes.container}>
                <Typography color='textPrimary' variant='h5' className={classes.title}>
                    Name and Description
                </Typography>
                <Typography variant='body1' className={classes.subtitle} color='textSecondary'>
                    Create a name and description for the group
                </Typography>
            </div>

            <div className={classes.container}>
                <FormLabel className={classes.label}>Name</FormLabel>
                <TextInput
                    id='group-name'
                    name='name'
                    validation={genericNameValidation}
                    setterFn={handleChange('name')}
                    tooltipText='Please enter a name between 2 and 64 characters'
                />
            </div>

            <div className={classes.container}>
                <FormLabel className={classes.label}>Description</FormLabel>
                <TextArea onChange={handleTextArea} name='description' />
            </div>

            <FormGroup className={`${classes.tableContainer} ${classes.container}`}>
                <Typography color='textPrimary' variant='h5' className={classes.title}>
                    Users
                </Typography>
                <Typography variant='body1' className={classes.spaceTop} color='textSecondary'>
                    Assign recipients to the group
                </Typography>
                <div className={classes.stack}>
                    <SearchBar
                        id='search'
                        setPage={setUserPage}
                        searchUtils={{
                            searchFn: searchWorker.searchDecryptorUsers,
                            token
                        }}
                        value={userPageSearchValue}
                        clearSearchValue={clearUserPageSearchValue}
                        setSearchValue={setUserPageSearchValue}
                        placeholder='Search for a user'
                    />
                    <MainTable
                        id='decryptor-users-table'
                        entities={filteredEntities}
                        searchValue={userPageSearchValue}
                        pageControl={{ page: userPage, setPage: setUserPage }}
                        fetchFn={fetchUsers}
                        totalPages={userPageCount}
                        columns={actionableUserHeader}
                        useContainer
                        height={600}
                        onRowClick={handleDecUserClick}
                    />
                </div>
            </FormGroup>
        </form>
    );
};

CreateDecryptorForm.propTypes = {
    handleSubmit: PropTypes.func
};

export default React.memo(CreateDecryptorForm);
