import React, { useState, useContext } from 'react';
import DatePicker from 'react-datepicker';
import { TextField, FormControl, Typography, useMediaQuery } from '@material-ui/core';
import useStyles from './DateRangePicker.styles';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays, format } from 'date-fns';
import { CalenderContext } from '@mediaseal-webui/components';
import { DateRangePickerProps } from '.';
import { useTheme } from '@material-ui/core/styles';

const DateRangePicker = ({
    readOnly,
    toDateSetter,
    fromDateSetter,
    defaultStartDate,
    defaultEndDate,
    showTime = false,
    fullWidth = false,
    disabled,
    portal = false,
    inputLabels = {
        startLabel: 'From',
        endLabel: 'To'
    },
    disableMinDate = false,
    inline = false,
    ...others
}: DateRangePickerProps) => {
    const [startDate, setStartDate] = useState<Date>(defaultStartDate);
    const [endDate, setEndDate] = useState<Date>(defaultEndDate);
    const { setCalenderShown } = useContext(CalenderContext);
    const theme = useTheme();
    const betweenMdLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));

    const validateEndDate = (date: Date) => date >= endDate;
    const handleEndDate = (date: Date) => {
        if (disabled) return;
        if (date) {
            setEndDate(date);
            toDateSetter(date);
        }
    };
    const handleStartDate = (date: Date) => {
        if (disabled) return;
        if (date) {
            setStartDate(date);
            fromDateSetter(date);
            //confirm end date > start date else set end date 30 days ahead
            if (validateEndDate(date)) {
                setEndDate(addDays(date, 30));
            }
        }
    };
    const classes = useStyles();
    const handleCalenderOpen = () => setCalenderShown(true);
    const handleCalenderClose = () => setCalenderShown(false);

    return (
        <FormControl className={fullWidth ? `${classes.fullWidth} ${classes.timeContainer}` : classes.timeContainer}>
            <div className={classes.calenderContainer}>
                {inline && (
                    <>
                        <Typography color='textPrimary' className={classes.overline} variant='overline'>
                            Start Date
                        </Typography>
                        <Typography color='textPrimary' className={classes.subtitle} variant='h6'>
                            {' '}
                            {startDate ? format(new Date(startDate), 'dd, LLL, yyyy') : 'Choose a start date'}
                        </Typography>
                    </>
                )}
                <DatePicker
                    selected={startDate}
                    onChange={handleStartDate}
                    selectsStart
                    inline={inline}
                    dateFormat={showTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={!disableMinDate ? startDate : null}
                    showTimeSelect={!disabled && showTime}
                    timeFormat='HH:mm'
                    withPortal={portal}
                    timeIntervals={15}
                    timeCaption='time'
                    onCalendarClose={handleCalenderClose}
                    onCalendarOpen={handleCalenderOpen}
                    popperClassName={`${classes.popper} popper`}
                    data-testid='date-range-pickers'
                    todayButton='Today'
                    disabled={disabled}
                    readOnly={readOnly}
                    popperPlacement='bottom-start'
                    dayClassName={(d: Date) => (disabled ? 'react-datepicker__day--disabled' : '')}
                    customInput={
                        <TextField
                            name='from-date'
                            disabled={disabled}
                            inputProps={{ 'data-testid': 'from-date', id: 'from-date' }}
                            className={classes.fromInput}
                            variant='outlined'
                            label={inputLabels.startLabel}
                        />
                    }
                    {...others}
                />
            </div>
            <div className={classes.calenderContainer}>
                {inline && (
                    <>
                        <Typography color='textPrimary' className={classes.overline} variant='overline'>
                            End Date
                        </Typography>
                        <Typography color='textPrimary' className={classes.subtitle} variant='h6'>
                            {' '}
                            {startDate ? format(new Date(endDate), 'dd, LLL, yyyy') : 'Choose a end date'}
                        </Typography>
                    </>
                )}
                <DatePicker
                    selected={endDate}
                    dateFormat={showTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'}
                    onChange={handleEndDate}
                    selectsEnd
                    inline={inline}
                    disabled={disabled}
                    readOnly={readOnly}
                    startDate={startDate}
                    endDate={endDate}
                    showTimeSelect={!disabled && showTime}
                    minDate={startDate}
                    withPortal={portal}
                    timeFormat='HH:mm'
                    timeIntervals={15}
                    timeCaption='time'
                    todayButton='Today'
                    onCalendarClose={handleCalenderClose}
                    onCalendarOpen={handleCalenderOpen}
                    dayClassName={(d: Date) => (disabled ? 'react-datepicker__day--disabled' : '')}
                    popperClassName={`${classes.popper} popper`}
                    popperModifiers={{
                        offset: {
                            enabled: true,
                            offset: betweenMdLg ? '-120px, 0px' : ''
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: 'viewport'
                        },
                        hide: {
                            enabled: false // turn off since needs preventOverflow to be enabled
                        }
                    }}
                    customInput={
                        <TextField
                            name='to-date'
                            disabled={disabled}
                            inputProps={{ 'data-testid': 'to-date', id: 'to-date' }}
                            className={classes.fromInput}
                            variant='outlined'
                            label={inputLabels.endLabel}
                        />
                    }
                    {...others}
                />
            </div>
        </FormControl>
    );
};

export default DateRangePicker;
