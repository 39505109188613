import React, { FC, useState, Dispatch, SetStateAction, createContext } from 'react';

export interface TabHandler {
    tab: string;
    ref: React.Ref<HTMLDivElement>;
    handler: () => void;
}

export interface ITabControlContext {
    tabContext: TabHandler[];
    setTabContext: Dispatch<SetStateAction<TabHandler[]>>;
}

const setTabContext: Dispatch<SetStateAction<TabHandler[]>> = (arg) => [];

export const TabControlContext = createContext<ITabControlContext>({
    tabContext: [],
    setTabContext
});

export const TabControlProvider: FC<{}> = ({ children }) => {
    const [tabContext, setTabContext] = useState<TabHandler[]>([]);

    return <TabControlContext.Provider value={{ tabContext, setTabContext }}>{children}</TabControlContext.Provider>;
};
