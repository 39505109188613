import { makeStyles } from '@material-ui/core';
const WIDTH = 85;
const HEIGHT = 32;
const useStyles = makeStyles((theme) => ({
    tableCell: {
        borderBottom: 'unset',

        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        // padding: '10px 10px 10px 0',
        [theme.breakpoints.down('sm')]: {
            padding: 5,
            justifyContent: 'flex-end',
            display: 'flex',
            width: '100%'
        }
    },
    active: {
        width: WIDTH,
        height: HEIGHT,
        background: theme.palette.primary.light,
        border: '2px',
        borderRadius: '20px !important',
        color: theme.palette.common.white,
        [theme.breakpoints.down('xs')]: {
            width: 10,
            height: 10
        }
    },
    inactive: {
        width: WIDTH,
        height: HEIGHT,
        background: theme.palette.pills.inactive,
        borderRadius: '20px !important',
        color: 'theme.palette.text.primary',
        [theme.breakpoints.down('xs')]: {
            width: 10,
            height: 10
        }
    },
    implicitInactive: {
        width: WIDTH,
        height: HEIGHT,
        background: theme.palette.pills.inactive,
        color: theme.palette.text.primary,

        [theme.breakpoints.down('xs')]: {
            width: 10,
            height: 10
        }
    },
    archived: {
        width: WIDTH,
        height: HEIGHT,
        background: theme.palette.pills.inactive,
        [theme.breakpoints.down('xs')]: {
            width: 10,
            height: 10
        }
    },
    icon: {
        border: '1px solid #eee',
        display: 'flex',
        padding: theme.spacing(1),
        transition: '1.5s',
        alignItems: 'center',
        borderRadius: '40px',
        justifyContent: 'space-between',
        width: 32,
        height: 32,
        marginRight: 5,
        display: 'flex',
        fontSize: '2.5rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        '& > svg': {
            color: theme.palette.text.primary,
            fontSize: '2rem'
        },
        [theme.breakpoints.down('sm')]: {}
    }
}));

export const useTooltipStyles = makeStyles((theme) => ({
    tooltip: {
        width: 130,
        background: theme.palette.background.default,

        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11
    },
    arrow: {
        color: theme.palette.background.default
    },
    tooltipHover: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    tooltipActive: {
        color: theme.palette.success.main,
        opacity: 1
    },
    tooltipInactive: {
        color: '#ff6666',
        opacity: 1
    },
    icon: {
        fontSize: theme.typography.pxToRem(15),
        marginLeft: theme.spacing(0.25),
        color: theme.palette.info.light,
        alignSelf: 'baseline',
        display: 'inline-flex'
    }
}));

export default useStyles;
