import React from 'react';
import { Column } from 'react-virtualized';
import clsx from 'clsx';
import { InfiniteScrollTable } from '../InfiniteScrollTable';
import useStyles from './SelectableTable.styles';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { EntityKey } from '@mediaseal-webui/types/redux';
import { useCellRenderer, useHeaderRenderer, IndexObj } from '.';
import { useBreakPointListener, useMobile } from '@mediaseal-webui/hooks';
import { useDispatch } from 'react-redux';
import { selectEntity, deselectEntity } from '@mediaseal-webui/actions';
import { TableColumns } from '@mediaseal-webui/types/generic';
import { GenericEntity } from '@mediaseal-webui/types/schemas';

export interface SelectableTableProps {
    /** data to be presented */
    entities: GenericEntity[];
    /** key identifier of the entity list  */
    entityKey: EntityKey;
    /** This is the search value used for the current view -- passed to the fetchFn */
    searchValue: string;
    /** used for infiniteScroll control and to ensure no overfetch errors */
    totalPages: number;
    /** this object should contain the page,setPage state from the view to control current page of requests */
    pageControl: {
        page: number;
        setPage: React.Dispatch<React.SetStateAction<number>>;
    };
    /** The columns to display */
    columns: TableColumns[];
    /** the raw action to be dispatched to fetch entities */
    fetchFn: () => Promise<void>;
    /** Sort data passed from parent, used in the table for sorting in the header */
    sortData: {
        sortBy: string;
        sortDirection: 'asc' | 'desc';
        createSortHandler: Function;
    };
    /** flag to filter the scrolls incoming results against a summary */
    filterFetch: boolean;
    /** message to display if no results */
    noResultsMessage: string;
    isFetching?: boolean;
}

export const SelectableTable = ({
    entities,
    entityKey,
    searchValue,
    totalPages,
    pageControl,
    columns,
    fetchFn,
    sortData,
    filterFetch,
    noResultsMessage,
    isFetching = false,
    ...others
}: SelectableTableProps) => {
    const { sortBy, createSortHandler } = sortData;
    const { page, setPage } = pageControl;

    const classes = useStyles();
    const dispatch = useDispatch();
    const { hide } = useBreakPointListener();
    const isMobile = useMobile();
    const rowGetter = ({ index }: IndexObj) => entities[index]; // returns current index from Table

    const headerRenderer = useHeaderRenderer({
        sortBy,
        createSortHandler,
        columns
    });
    const cellRenderer = useCellRenderer({
        columns,
        isFetching,
        isMobile,
        //@ts-ignore
        selectFn: selectEntity(entityKey),
        //@ts-ignore
        deselectFn: deselectEntity(entityKey)
    });

    const onRowClick = ({ id }: { id: GenericEntity['id'] }) => {
        const entity = entities.find((entity: GenericEntity) => entity.id === id);
        if (!entity) {
            console.error('Issue getting entity from list onRowClick');
            return;
        }
        const { selected } = entity;
        if (!selected) {
            dispatch(selectEntity(entityKey)(entity));
        } else {
            dispatch(deselectEntity(entityKey)(entity));
        }
    };

    return (
        <InfiniteScrollTable
            rowCount={entities.length}
            rowGetter={rowGetter}
            rowHeight={45}
            data={entities}
            pageControl={{ page, setPage }}
            fetchFn={fetchFn}
            totalPages={totalPages}
            rowClassName={({ index }: IndexObj) => (index !== -1 ? clsx([classes.tableRow, classes.tableRowHover]) : classes.tableRow)}
            columns={columns}
            onRowClick={onRowClick}
            noResultsMessage={noResultsMessage}
            {...others}>
            {(width: number | string, height: number | string) =>
                columns.map((col, index) => {
                    const width = () => (hide.some((bp: Breakpoint) => columns[index].hidden?.includes(bp)) ? 0 : col.width || 90);
                    const flexGrow = () => (hide.some((bp: Breakpoint) => columns[index].hidden?.includes(bp)) ? 0 : col.flexGrow);
                    return (
                        <Column
                            key={index}
                            // @ts-ignore
                            columns={columns}
                            index={index}
                            headerRenderer={(headerProps) =>
                                headerRenderer({
                                    ...headerProps,
                                    columnIndex: index
                                })
                            }
                            className={clsx([classes.flexContainer])}
                            cellRenderer={cellRenderer}
                            {...col}
                            width={width()}
                            flexGrow={flexGrow()}
                        />
                    );
                })
            }
        </InfiniteScrollTable>
    );
};

SelectableTable.defaultProps = {
    entities: [],
    searchValue: '',
    totalPages: 1,
    pageControl: {
        page: 0,
        setPage: () => {}
    },
    fetchFn: () => Promise.resolve(),
    sortData: {
        sortBy: 'createDate',
        sortDirection: 'desc',
        createSortHandler: () => null
    },
    filterFetch: true
} as Partial<SelectableTableProps>;
