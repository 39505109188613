import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingRight: theme.spacing(3)
    },
    label: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2)
    },
    title: {
        marginTop: theme.spacing(2)
    },
    subtitle: {
        marginTop: theme.spacing(2)
    },
    formGroup: {
        marginBottom: theme.spacing(2)
    },
    passwordInput: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    stack: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: theme.sizes.tables.forms - theme.spacing((32 + 24 + 72) / 8)
    },
    tableContainer: {
        flexWrap: 'unset',
        height: theme.sizes.tables.user.forms
    }
}));

export default useStyles;
