import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { BtnProps } from '..';
import { EncryptFile } from '@mediaseal-webui/types/encryptor';
// import { Test } from './CancelBtn.styles';

export const CancelBtn = ({ file }: BtnProps) => {
    const handleCancelClick = (file: EncryptFile) => {
        if (window.qt) {
            window.EncryptionWebInterface.cancelEncryptionWork(file.id);
        }
    };
    return (
        <Tooltip title='Cancel Job' placement='top'>
            <IconButton
                id='cancel-btn'
                onClick={() => {
                    handleCancelClick(file);
                }}>
                <Clear fontSize='small' />
            </IconButton>
        </Tooltip>
    );
};
