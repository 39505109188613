import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const AUTH_URL = process.env.NODE_ENV === 'production' ? `${document.location.origin}/auth` : process.env.REACT_APP_KEYCLOAK_URL;
const CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID || 'localhost';
export const REALM = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_KEYCLOAK_REALM : document.location.hostname.split('.')[0];

export const keycloak = Keycloak({
    realm: REALM ?? '',
    url: AUTH_URL ?? '',
    clientId: CLIENT_ID ?? ''
});
