import { IdentityProviderService } from '@mediaseal-webui/services';
import { useCallback, useContext, useMemo } from 'react';
import { IdentityProviderContext } from './context';

export const useIdentityProvider = () => {
    const context = useContext(IdentityProviderContext);
    const {
        configureProvider: { clientSecret, clientId, type, domain, tenantId }
    } = context;
    const service = useMemo(() => new IdentityProviderService(), []);
    const resetData = useCallback(() => {
        context.setData((ps) => ({
            ...ps,
            configuredProvider: {
                clientId: '',
                clientSecret: '',
                showModal: false,
                type: '',
                redirectUrl: ''
            }
        }));
    }, [context]);

    const submitFn = useCallback(async () => {
        const postIDPConfig = await service
            .createProvider({
                clientSecret,
                clientId,
                type,
                domain,
                tenantId
            })
            .then((response) => {
                return response;
            });
        const { data } = await service.fetchProvider();
        return { data, postIDPConfig };
    }, [service, clientSecret, clientId, type, domain, tenantId]);

    return { ...context, resetData, submitFn };
};
