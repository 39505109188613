import { ThunkResult } from '@mediaseal-webui/types/redux';
import { constructFilePayload } from '@mediaseal-webui/utils';
import { handleBatchedFiles } from '../batching';
import { getACL } from './getACL';
import { handleOfflineStart } from './handleOfflineStart';
import { postEncryptionFile } from './postEncryptionFile';

export const processEncryptionWork = (): ThunkResult<void> => (dispatch, getState) => {
    //get the work from state
    const { encryptionWork } = getState().encryptor;
    const { batched } = getState().entity.profile;
    encryptionWork.forEach((work) => {
        // if a batchFileId exists lets handle batches files
        if (batched) {
            work.fileList.map((item) => dispatch(handleBatchedFiles(item, work)));
        } else {
            (async () => {
                // we need to check to see which work hasnt yet been processed
                // we are looking at each file list.. we need to POST each file and then send a startEncryption signal to QT

                const batchSize = 10;
                for (let i = 0; i < work.fileList.length; i += batchSize) {
                    const batch = work.fileList.slice(i, i + batchSize);
                    batch.map(async (item) => {
                        if (!item.processed) {
                            const fileInfo = constructFilePayload(item, getState().jobs.activeJobId);
                            await postEncryptionFile({ fileInfo, work }, item, dispatch); //only if online
                        }
                        return Promise.resolve();
                    });
                    await Promise.all(batch);
                }
            })();
        }
    });
};
