import React, { createContext, FC, useState } from 'react';

export interface IPasswordScoreContext {
    score: number | null;
    caption: string;
}

type PasswordContextType = [IPasswordScoreContext, React.Dispatch<React.SetStateAction<IPasswordScoreContext>>];

const initState: IPasswordScoreContext = {
    score: null,
    caption: ''
};
export const PasswordScoreContext = createContext<PasswordContextType>([initState, () => {}] as PasswordContextType);

export const PasswordScoreProvider: FC = ({ children }) => {
    const state = useState<IPasswordScoreContext>(initState);
    return <PasswordScoreContext.Provider value={state}>{children}</PasswordScoreContext.Provider>;
};
