import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import useStyles from './Histogram.styles';
import { useTheme, fade } from '@material-ui/core/styles';

const getTotal = (month) => month.denied + month.granted;

const Histogram = ({ data, total, granted, denied }) => {
    const classes = useStyles();
    const theme = useTheme();
    const determineTotal = (month) => {
        switch (true) {
            case total:
                return getTotal(month);
            case granted:
                return month.granted;
            case denied:
                return month.denied;
            default:
                break;
        }
    };

    const chartData = () => ({
        labels: data.map((m) => m.label),
        datasets: [
            {
                label: '',
                data: data.map(determineTotal),
                backgroundColor: fade(theme.palette.common.white, 0.15),
                borderColor: fade(theme.palette.common.white, 0.45),
                pointBackgroundColor: fade(theme.palette.common.white, 0),
                pointBorderColor: fade(theme.palette.common.white, 0),
                pointRadius: 100
            }
        ]
    });
    return (
        <div className={classes.chart}>
            <Line
                data={chartData()}
                options={{
                    animation: {
                        duration: 1000,
                        easing: 'easeInOutQuad'
                    },
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [
                            {
                                gridLines: {
                                    // drawOnChartArea: false
                                    display: false
                                },
                                ticks: {
                                    display: false
                                }
                            }
                        ],
                        yAxes: [
                            {
                                gridLines: {
                                    // drawOnChartArea: false
                                    display: false
                                },
                                ticks: {
                                    display: false
                                }
                            }
                        ]
                    },
                    tooltips: {
                        displayColors: false,
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        titleFontColor: 'rgba(225, 225,225, 1)',
                        bodyFontColor: 'rgba(255, 255, 225, 1)',
                        titleFontStyle: 'bold',
                        titleFontSize: 10,
                        bodyFontSize: 22
                    }
                }}
            />
        </div>
    );
};

Histogram.propTypes = {
    data: PropTypes.shape({
        label: PropTypes.string,
        granted: PropTypes.number,
        denied: PropTypes.number
    }),
    total: PropTypes.bool,
    granted: PropTypes.bool,
    denied: PropTypes.bool
};

export default Histogram;
