import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
    return {
        searchContainer: {
            transition: 'all 0.4s',
            width: theme.sizes.searchBar.normal,
            minWidth: 250,
            [theme.breakpoints.down('xs')]: {
                width: `calc(100% -${theme.spacing(3)}px)`,
                position: 'absolute',
                top: theme.sizes.header.mobile / 2,
                right: 10
            }
        }
    };
});

export default useStyles;
