import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Send as SendIcon } from '@material-ui/icons';
import InviteModal from '../../../components/users-components/invite-components/InviteModal';
import { MainTable } from '@mediaseal-webui/components';
import InvitesTable from '../../../components/users-components/decryptor-components/InvitesTable';
import { Header } from '../../../components/layout-components';
import { clearUserPageSearchValue } from '@mediaseal-webui/actions';
import { genericUserNoActions } from '../../../data';
import { useTableSortV2, useMobile, useTabControl } from '@mediaseal-webui/hooks';
import { Content, SubmitButton } from '@mediaseal-webui/components';
import useStyles from './Decryptor.styles';
import { useAuth } from '@mediaseal-webui/hooks';
import ActionContainer from '../../../components/table-components/ActionContainer/ActionContainer';
import { useTableScroller } from '@mediaseal-webui/hooks';
import { RecipientService } from '@mediaseal-webui/services';
import { useAppDispatch, useAppSelector } from 'store';
import { fetchRecipients, recipientSlice, searchRecipients } from 'reducers/users/recipients';

const Table = () => {
    // const { filteredUsers: users, userPageCount, decryptorUserSearchValue } = useSelector((state) => state.users);
    const history = useHistory();
    const auth = useAuth();
    const service = useMemo(() => new RecipientService(), []);
    const dispatch = useAppDispatch();
    const { clearUsers } = recipientSlice.actions;

    const { content: users, page, searchValue: userPageSearchValue, pending } = useAppSelector((state) => state.recipients);

    const fetchFn = useCallback(() => dispatch(searchRecipients({ keyword: service.baseParams.keyword ?? '', service })), [service, dispatch]);
    // const isEditAuth = auth.edit || auth.administer
    const isCreateAuth = auth.create || auth.administer;

    const { headCells, sortFn, order, orderBy } = useTableSortV2({
        cells: genericUserNoActions,
        fetchFn,
        service
    });

    useEffect(() => {
        window.qt && window.GeneralWebInterface.allowFileDrop(false, { dropBehaviour: 'off' });
        dispatch(searchRecipients({ service, keyword: '' }));
        return () => {
            dispatch(clearUsers());
            dispatch(clearUserPageSearchValue());
        };
    }, [dispatch, clearUsers, service]);
    const fetchNextPage = useCallback(async () => {
        if (service.baseParams.page <= (page?.totalPages ?? 0)) {
            service.setParams({
                page: service.baseParams.page + 1
            });
            await dispatch(fetchRecipients(service));
        }
    }, [dispatch, page?.totalPages, service]);

    const onRowClick = ({ id }) => history.push(`/users/decryptor/${id}/profile`); // returns the rowData obj from Table
    return (
        <MainTable
            entities={users}
            id='decryptor-users-table'
            totalPages={page?.totalPages}
            searchValue={userPageSearchValue}
            isFetching={pending}
            noResultsMessage='No users returned'
            pageControl={{ page: service.baseParams.page, setPage: () => null }}
            entityLink='/users/decryptor'
            fetchFn={fetchNextPage}
            onRowClick={onRowClick}
            tableActionsRenderer={(entity) => <ActionContainer create={isCreateAuth} />}
            sortData={{
                sortBy: orderBy,
                sortDirection: order,
                createSortHandler: sortFn
            }}
            columns={headCells}
        />
    );
};

const tabs = [
    {
        id: 0,
        attrId: 'users-tab',
        label: 'Users',
        component: <Table />
    },
    {
        id: 1,
        label: 'Pending',
        attrId: 'pending-tab',
        component: <InvitesTable id='invite-table' status='PENDING' />
    },
    {
        id: 2,
        label: 'Rejected',
        attrId: 'rejected-tab',
        component: <InvitesTable id='rejected-table' status='REJECTED' />
    }
];

const Decryptor = () => {
    const classes = useStyles();
    const mobile = useMobile();
    const auth = useAuth();
    const { content: users, page, searchValue: userPageSearchValue, pending } = useAppSelector((state) => state.recipients);
    const { pendingInvites, rejectedInvites, totalPendingInvites, totalRejectedInvites } = useSelector(
        // @ts-expect-error due to "Property 'invitations' does not exist on type 'DefaultRootState'.ts(2339)"
        ({ invitations }) => invitations
    );
    const { setSearchValue, clearSearchValue } = recipientSlice.actions;
    const [tabValue, setTabValue] = useState(0);
    const [, setPage] = useState(1);
    const [inviteModalOpen, setInviteModelOpen] = useState(false);
    const { tabContext: tabControl, cardRef } = useTabControl(
        setTabValue,
        tabs.map((tab) => tab.label)
    );

    const handleClick = () => setInviteModelOpen(true);
    useTableScroller();
    const ActionButton = () => (
        <SubmitButton id='invite-users-button' aria-label='invite' Icon={SendIcon} title='Invite users' disabled={false} onClick={handleClick} />
    );
    const displayingText =
        tabValue === 0
            ? `Displaying ${users.length} of ${page?.totalElements} users`
            : `Displaying ${tabValue === 1 ? pendingInvites.length : rejectedInvites.length} of ${
                  tabValue === 1 ? totalPendingInvites : totalRejectedInvites
              } invitations`;

    return (
        <div className={classes.viewRoot}>
            <Header
                list
                skeleton
                name='Decryptor Users'
                displayingText={displayingText}
                ActionButton={(auth.create || auth.administer) && ActionButton}
                breadcrumbProps={{
                    links: [{ id: 0, name: 'Decryptor Users', to: '/users/decryptor' }],
                    text: [{ id: 0, name: '' }],
                    isFetching: pending
                }}
                searchProps={{
                    setPage,
                    searchFn: 'searchDecryptorUsers',
                    value: userPageSearchValue,
                    clear: clearSearchValue,
                    set: setSearchValue
                }}
                tabProps={{
                    tabValue,
                    setTabValue,
                    tabControl,
                    // @ts-expect-error due to mobileOnly not existing ts(2339).
                    tabs: !mobile ? tabs.filter((tab) => !tab.mobileOnly) : tabs
                }}
            />

            <Content classes={{ content: classes.disableScroll }}>
                {(cx) => (
                    <div ref={cardRef} className={cx.cardWrapper}>
                        {tabs.map((tab, index) => (
                            <div ref={tabControl[index]?.ref} className={cx.userTable}>
                                {/* // <Typography variant="h5">{tab.label}</Typography> */}
                                {tab.component}
                            </div>
                        ))}
                    </div>
                )}
            </Content>

            <InviteModal open={inviteModalOpen} setModalOpen={setInviteModelOpen} />
        </div>
    );
};

export default Decryptor;
