import { AxiosError, AxiosResponse } from 'axios';
import { KeyCloakService } from '../AuthService';
import { keycloak, REALM } from '../AuthService/keycloak';
import { KeycloakProfile } from 'keycloak-js';

export interface KeyCloakClient {
    clientId: string;
    clientName: string;
    inUse: boolean;
    offlineAccess: boolean;
    userConsentRequired: boolean;
}
export interface KeyCloakSession {
    browser: string;
    clients: KeyCloakClient[];
    current: boolean;
    expires: number; //TIMESTAMP
    id: string;
    ipAddress: string;
    lastAccess: number; //TIMESTAMP
    started: number; //TIMESTAMP
}
export interface KeyCloakDevice {
    current: boolean;
    device: string;
    lastAccess: number; //TIMESTAMP
    mobile: boolean;
    os: string;
    osVersion: string;
    sessions: KeyCloakSession[];
}
interface IUserInfo {
    sub: string;
}

export class AccountService extends KeyCloakService {
    constructor() {
        super(`/realms/${REALM}/account`);
    }
    public async getDevices(): Promise<AxiosResponse<KeyCloakDevice[] | AxiosError<Error>>> {
        try {
            return await this.service.get(`${this.url}/sessions/devices`);
        } catch (error) {
            console.log(error);
            return error;
        }
    }

    public async getUser(): Promise<KeycloakProfile | undefined> {
        try {
            const data = (await keycloak.loadUserInfo()) as IUserInfo;
            const user = await keycloak.loadUserProfile();
            return {
                ...user,
                id: data.sub
            };
        } catch (error) {
            console.error(error);
        }
    }
    public async updateUser(id, body: Partial<KeycloakProfile>) {
        return await this.service.post(`${this.url}`, body);
    }
}
