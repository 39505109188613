import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Card } from '@material-ui/core';
import { Header, HeaderItemsConfig } from '../../../components/layout-components';
import { fetchAllTitleData, updateTitle } from '../../../actions/titles';
import { nameLength, useAuth } from '@mediaseal-webui/hooks';
import { tabs } from '../../../components/titles-components/profile-view-components';
import { Content, AddItemButton } from '@mediaseal-webui/components';
import useStyles from './Title.styles';
import { InitialState } from '@mediaseal-webui/types/redux';
import { useTabControl } from '@mediaseal-webui/hooks/src';
import { useCleanEntity } from 'hooks/useCleanEntity';
import { useAppSelector } from 'store';

const Title = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const [tabValue, setTabValue] = useState<number>(0);
    const { tabContext: tabControl, cardRef } = useTabControl(
        setTabValue,
        tabs.map((tab) => tab.label)
    );
    const auth = useAuth();
    const isEdit = auth.edit || auth.administer;

    const { name, createDate, jobs, encryptorGroups: groups, users, allowedTokens } = useSelector(({ entity: { profile } }: InitialState) => profile);

    useCleanEntity();

    useEffect(() => {
        dispatch(fetchAllTitleData(id));
    }, [dispatch, id]);

    const headerItems: HeaderItemsConfig = {
        jobs: jobs?.length
            ? {
                  onClick: tabControl[0].handler
              }
            : undefined,
        encUsers: users?.length
            ? {
                  onClick: tabControl[1].handler
              }
            : undefined,
        encGroups: groups?.length
            ? {
                  onClick: tabControl[2].handler,
                  //@ts-expect-error due to undefined type ts(2322).
                  modal: null
              }
            : undefined,
        tokens: allowedTokens?.length
            ? {
                  onClick: tabControl[3].handler
              }
            : undefined,
        active: {
            updateFn: () => updateTitle(id),
            entity: 'title'
        }
    };

    const { items: permissions } = useAppSelector((state) => state.permissions);
    const hasEditTitlePermission = permissions.some((permission) => permission.permissionName === 'canEditTitles');

    return (
        <div className={classes.viewRoot}>
            <Header
                editable={hasEditTitlePermission}
                name={name}
                date={createDate ? new Date(createDate) : undefined}
                pageControl={{ tabValue, setTabValue }}
                titleProps={{ setterFn: updateTitle }}
                breadcrumbProps={{ links: [{ id: 0, name: 'Titles', to: '/titles' }], text: [{ id: 0, name }] }}
                tabProps={{
                    tabValue,
                    setTabValue,
                    tabControl,
                    tabs
                }}
                headerItems={headerItems}
                ActionButton={false}
                titleValidation={nameLength}
            />
            <Content>
                {(cx) => (
                    <div ref={cardRef} className={cx.cardWrapper}>
                        {tabs.map(({ label, modal, component, Icon, cardId, btnId }, index) => (
                            <Card id={cardId} elevation={0} className={cx.profileCard}>
                                <div ref={tabControl[index]?.ref || null} className={cx.titleBlock}>
                                    <Typography color='textPrimary' className={cx.cardTitle} variant='h5' id='card-text'>
                                        {label}
                                    </Typography>
                                    {modal && isEdit && hasEditTitlePermission && <AddItemButton Modal={modal} Icon={Icon} id={btnId} />}
                                </div>
                                {component}
                            </Card>
                        ))}
                    </div>
                )}
            </Content>
        </div>
    );
};

export default Title;
