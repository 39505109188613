import { setConfirmDialogue, setError } from '@mediaseal-webui/actions/src';
import { JobTypes } from '@mediaseal-webui/types/jobs';
import { ConfirmDialogIconKey } from '@mediaseal-webui/types/generic';
import { InitialState } from '@mediaseal-webui/types/redux';
import { useDispatch, useSelector } from 'react-redux';

export type ActiveStatus = 'active' | 'inactive';

export type Options = {
    [key: string]: {
        message: string;
        confirmButtonText: string;
        title: string;
        loadingText: string;
        icon: ConfirmDialogIconKey;
    };
};

export const statusOptions: Options = {
    deactivate: {
        message: 'Deactivating a job will mean that access to all files within this job will be blocked. Please confirm that you want to deactivate this job?',
        confirmButtonText: 'Deactivate',
        title: 'Deactivate Job',
        loadingText: 'Deactivating Job',
        icon: 'deactivate'
    },
    restoreAndReactivate: {
        message:
            'The job is currently archived.  If you reactivate this job it will be restored to a non-archived state. Please confirm that you want to restore and activate this job?',
        confirmButtonText: 'Restore & Reactivate',
        title: 'Restore & Reactivate Job',
        loadingText: 'Restoring & Reactivating Job',
        icon: 'restore'
    },
    reactivate: {
        message: 'Please confirm that you want to reactivate this job?',
        confirmButtonText: 'Reactivate',
        title: 'Reactivate Job',
        loadingText: 'Reactivating Job',
        icon: 'reactivate'
    }
};

export const getStatus = (status: ActiveStatus, archived?: boolean) => {
    if (status === 'inactive') {
        return statusOptions.deactivate;
    }
    return archived ? statusOptions.restoreAndReactivate : statusOptions.reactivate;
};

export const archivalOptions: Options = {
    archive: {
        message: 'Please confirm that you want to archive this job?',
        confirmButtonText: 'Archive',
        title: 'Archive Job',
        loadingText: 'Archiving Job',
        icon: 'archive'
    },
    archiveAndDeactivate: {
        message:
            'Archiving this job will also deactivate it so that access to all files within this job will be blocked.  Please confirm you want to archive and deactivate this job?',
        confirmButtonText: 'Archive & Deactivate',
        title: 'Archive & Deactivate Job',
        loadingText: 'Archiving & Deactivating Job',
        icon: 'archive'
    },
    restore: {
        message: 'Please confirm that you want to restore this job?',
        confirmButtonText: 'Restore',
        title: 'Restore Job',
        loadingText: 'Restoring Job',
        icon: 'restore'
    },
    restoreOnline: {
        message: 'Please confirm that you want to restore this job? Note: restoring this job will not reactivate it until you choose to do so.',
        confirmButtonText: 'Restore',
        title: 'Restore Job',
        loadingText: 'Restoring Job',
        icon: 'restore'
    }
};

export const getArchival = (flag: boolean, type: JobTypes, active: boolean) => {
    if (type !== 'ONLINE') {
        return flag ? archivalOptions.archive : archivalOptions.restore;
    }
    if (flag === true) {
        return !active ? archivalOptions.archive : archivalOptions.archiveAndDeactivate;
    }
    return archivalOptions.restoreOnline;
};

export const useJobArchiveStatusChange = () => {
    const dispatch = useDispatch();

    const profile = useSelector(({ entity: { profile } }: InitialState) => profile);
    const { active, archived, type } = profile;

    /**
     * This action is a callback triggered by the confirmationDialog on user confirm
     * @arg status => the requested status change 'active' | 'inactive'
     */
    type HandleStatusChangeAction = (status: ActiveStatus) => void;
    const handleStatusChange = (status: ActiveStatus, action: HandleStatusChangeAction) => () => {
        if (type !== 'ONLINE') {
            dispatch(setError(`Cannot change status of job type ${type}`));
        }
        const data = getStatus(status, archived);
        dispatch(
            setConfirmDialogue({
                ...data,
                action: () => action(status)
            })
        );
    };

    /**
     * This action is a callback triggered by the confirmationDialog on user confirm
     * @arg flag => the requested archival status => true (archive it) | false (unarchive it)
     */
    type HandleArchivalAction = (flag: boolean) => void;
    const handleArchival = (flag: boolean, action: HandleArchivalAction) => async () => {
        const data = getArchival(flag, type, active);
        dispatch(
            setConfirmDialogue({
                ...data,
                action: () => action(flag)
            })
        );
    };

    return {
        handleStatusChange,
        handleArchival
    };
};
