import React, { useState, useEffect, FC } from 'react';

import { BottomNav, SideNav } from '..';
import { Hidden } from '@material-ui/core';
import { resetSnackbar } from '../../../actions/misc';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import { DialogueState } from '@mediaseal-webui/types';
import { InitialState } from '@mediaseal-webui/types/redux/state';
import { FileIssuesModal, ErrorModal, Widget, CookieAlert } from '@mediaseal-webui/components';
import { UpdateAlert } from '@mediaseal-webui/components/src/Alerts/UpdateAlert/UpdateAlert';
export const Layout: FC = ({ children }) => {
    const [openError, setOpenError] = useState(false);
    const [dialogueState, setDialogueState] = useState<Pick<DialogueState, 'message'> | null>();
    const { success, message, error: snackBarError } = useSelector(({ misc: { snackbar } }: InitialState) => snackbar);
    const { swUpdateData } = useSelector(({ misc }: InitialState) => misc);
    const { message: errorMessage, error } = useSelector(({ misc: { errors } }: InitialState) => errors);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    useEffect(() => {
        if (success) {
            enqueueSnackbar(message, {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            });
        }
        if (error || snackBarError) {
            setDialogueState({ message: errorMessage || message });
            setOpenError(true);
        }
        return () => {
            dispatch(resetSnackbar());
        };
    }, [success, snackBarError, error, errorMessage, message, dispatch, enqueueSnackbar, swUpdateData.available]);

    return (
        <>
            <Hidden smUp>
                <BottomNav />
            </Hidden>
            <Hidden xsDown>
                <SideNav />
            </Hidden>
            {children}
            <Widget />
            <FileIssuesModal />
            <ErrorModal openProps={[openError, setOpenError]} message={dialogueState?.message} />
            {/* <TourDialogue /> */}
            <CookieAlert />
            <UpdateAlert show={swUpdateData.available} message={swUpdateData.message} worker={swUpdateData.worker} />
        </>
    );
};
