import { FileIssue } from '@mediaseal-webui/types/encryptor';
import { ThunkResult } from '@mediaseal-webui/types/redux';
import { RootState } from 'packages/online/src/store';

/**
 * Cancel all the files with issues for a given FileIssue
 */
export const cancelAllFilesWithIssue =
    (issue: FileIssue): ThunkResult<void> =>
    (dispatch, getState: () => RootState) => {
        if (window.qt) {
            console.log(`calling cancelAllFilesWithIssue for issue ${issue}`);
            const { issues } = getState().encryptor;
            issues?.filter((file) => file.workIssue.errorType === issue).forEach((file) => window.EncryptionWebInterface.cancelEncryptionWork(file.id));
        }
        return { type: '' };
    };
