import { useSummaryFilter } from '@mediaseal-webui/hooks';
import { actionableGroupHeader } from 'data';
import { useSearchWorker } from 'hooks';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { MainTable, MSSearchBar as SearchBarWrapper } from '@mediaseal-webui/components';
import { useAppSelector } from 'store';
import { GenericEntity } from '@mediaseal-webui/types';
import { EncryptorGroupService } from '@mediaseal-webui/services';
import { encryptorGroupsSlice, fetchEncryptorGroups, searchEncryptorGroups } from '../../../reducers/groups/groups';
import { addEncryptorGroupToSummary } from '@mediaseal-webui/actions';
import useStyles from '../../titles-components/creation-view-components/TabArea/TabArea.styles';

export const EncryptorGroupSelection = memo(() => {
    const { content: filteredGroups, searchValue, page, pending } = useAppSelector((state) => state.encryptorGroups);
    const { filteredEntities } = useSummaryFilter({
        entities: filteredGroups as GenericEntity[],
        type: 'encryptorGroups'
    });
    const classes = useStyles();
    const { clearSearchValue, clearGroups, setSearchValue } = encryptorGroupsSlice.actions;
    const service = useMemo(() => new EncryptorGroupService(), []);
    const { searchWorker, token } = useSearchWorker();
    const dispatch = useDispatch();
    const handleRowClick = useCallback((group) => dispatch(addEncryptorGroupToSummary(group)), [dispatch]);
    const fetchNextPage = useCallback(async () => {
        if (service.baseParams.page <= (page?.totalPages ?? 0)) {
            service.setParams({
                page: service.baseParams.page + 1
            });
            await dispatch(fetchEncryptorGroups(service));
        }
    }, [dispatch, page?.totalPages, service]);
    useEffect(() => {
        dispatch(searchEncryptorGroups({ service, keyword: '' }));
        return () => {
            dispatch(clearGroups());
        };
    }, [dispatch, service, clearGroups]);
    return (
        <div className={classes.stack}>
            <SearchBarWrapper
                setPage={() => null}
                searchUtils={{
                    searchFn: searchWorker.searchEncryptorGroups,
                    token
                }}
                value={searchValue}
                clearSearchValue={clearSearchValue}
                setSearchValue={setSearchValue}
                placeholder='Search for a group'
            />
            <MainTable
                id='groupsTable'
                entities={filteredEntities}
                searchValue={searchValue}
                isFetching={pending}
                noResultsMessage='No groups returned'
                pageControl={{ page: service.baseParams.page, setPage: () => null }}
                fetchFn={fetchNextPage}
                totalPages={page?.totalPages}
                columns={actionableGroupHeader}
                onRowClick={handleRowClick}
            />
        </div>
    );
});
