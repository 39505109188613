import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    viewRoot: {
        [theme.breakpoints.down('xs')]: {
            overflow: 'hidden'
        }
    },
    root: {
        height: '100%'
    },
    reportFilters: {
        marginTop: '10px',
        width: '100%'
    },
    content: {
        display: 'flex',
        height: '100%',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    blockScrolling: {
        overflow: 'hidden'
    },
    tab: { fontWeight: 600 }
}));
