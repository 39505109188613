import { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

export const useBreakPointListener = () => {
    const [hide, setHide] = useState<Breakpoint[]>([]);
    const theme = useTheme();

    const breakpoints: Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl'];

    const resize = () => {
        const matches = breakpoints.filter((bp) => {
            return window.matchMedia(theme.breakpoints.down(bp).split('@media ')[1].trim()).matches;
        });
        setHide(matches);
    };

    useEffect(() => {
        resize();
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize);
        //    eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { hide };
};
