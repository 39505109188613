import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Card } from '@material-ui/core';
import { Header, HeaderItemsConfig } from '../../../components/layout-components';
import { fetchAllJobData, setActiveJobId, clearJobPageSearchValue, resetSecurityOptions, updateJob } from '../../../actions/jobs';
import { useMobile, useAuth, useJobFeatures, useReadOnlyCheck, useFeatureFlag, useJobCompatible, nameLength } from '@mediaseal-webui/hooks';
import { tabs } from '../../../components/jobs-components/profile-components';
import ReauthTime from '../../../components/jobs-components/creation-view-components/ReauthTime';
import { setEditValues } from '../../../actions/entity';
import { FileList, Content, AddItemButton, WarningAlert, MultiOptionBtn } from '@mediaseal-webui/components';
import { setAllowEncryption } from '@mediaseal-webui/actions';
import { InitialState } from '@mediaseal-webui/types/redux';
import { validateJob } from '@mediaseal-webui/utils';
import { BiFileBlank } from 'react-icons/bi';
import { AiFillFolder } from 'react-icons/ai';
import { processAuthMode } from '../../../helpers';
import { useTabControl } from '@mediaseal-webui/hooks/src';
import { useCleanEntity } from 'hooks/useCleanEntity';
import { useAppSelector } from 'store';

const Job = () => {
    const dispatch = useDispatch();
    const mobile = useMobile();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const auth = useAuth();
    const isAuth = auth.edit || auth.administer;

    /**
     * Feature flag for add files btn
     */
    const { isCompatible } = useFeatureFlag('1.0.0');

    /**
     * Campatibility check for header and data versions
     */
    const [advancedOptionsOpen, setAdvancedOptionsOpen] = useState(false);
    const [tabValue, setTabValue] = useState<number>(0);

    const profile = useSelector(({ entity: { profile } }: InitialState) => profile);
    const {
        name,
        type,
        createDate,
        batched,
        headerVersion,
        additionalDataVersion,
        archived,
        title,
        createdBy,
        decryptorGroups: decGroups,
        decryptorUsers: decUsers,
        securityRestrictions,
        files
    } = profile;
    const { features } = useSelector(({ entity: { edit } }: InitialState) => edit);
    const { isCompatible: canEncrypt } = useJobCompatible({ headerVersion, additionalDataVersion });
    const { isReadOnly } = useReadOnlyCheck(profile, validateJob);
    const { tabContext: tabControl, cardRef } = useTabControl(setTabValue, ['Analytics', 'Files', 'Users', 'Groups']);
    const { isReauthEnabled, isRolloverEnabled, hasSecurePlayer, hasQrCode } = useJobFeatures(features);
    const encryptCheck = window.qt && isCompatible && canEncrypt;

    useCleanEntity();

    useEffect(() => {
        dispatch(fetchAllJobData(id));
        dispatch(setActiveJobId(id));
        dispatch(clearJobPageSearchValue());
        return () => {
            dispatch(resetSecurityOptions());
        };
    }, [dispatch, id]);

    useEffect(() => {
        dispatch(setAllowEncryption(canEncrypt));
        return () => {
            dispatch(setAllowEncryption(false));
        };
    }, [canEncrypt, dispatch]);

    const handleFileOpen = () => {
        window.EncryptionWebInterface.selectFilesForEncryption();
    };
    const handleFolderOpen = () => {
        window.EncryptionWebInterface.selectFolderForEncryption();
    };

    const options = [
        {
            id: 'add-files-btn',
            text: 'Add Files',
            fn: handleFileOpen,
            default: true,
            icon: BiFileBlank
        },
        {
            id: 'add-folder-btn',
            text: 'Add Folder',
            fn: handleFolderOpen,
            default: false,
            icon: AiFillFolder
        }
    ];
    const AddFileButton = (props: any) => <MultiOptionBtn options={options} {...props} />;

    const headerItems: HeaderItemsConfig = {
        files:
            files?.length > 0
                ? {
                      onClick: tabControl[1].handler
                  }
                : undefined,
        decUsers:
            decUsers?.length > 0
                ? {
                      onClick: tabControl[2].handler
                  }
                : undefined,
        decGroups:
            decGroups?.length > 0
                ? {
                      onClick: tabControl[3].handler
                  }
                : undefined,
        title: {
            onClick: () => title && history.push(`/titles/${title.id}/profile`)
        },
        authType: type
            ? {
                  title: processAuthMode(type) || '',
                  updateFn: updateJob,
                  disabled: type === 'PASSWORD_ONLY'
              }
            : undefined,
        jobNotes: {},
        jobContactInfo: {},
        batched: batched ? {} : undefined,
        sunriseSunset: type === 'ONLINE' ? {} : undefined,
        reauth: isReauthEnabled() ? {} : undefined,
        securePlayer: hasSecurePlayer() ? {} : undefined,
        qrCode: hasQrCode() ? {} : undefined,
        jobActive: isAuth ? {} : undefined,
        template: profile.template ? {} : undefined
    };

    const { items: permissions } = useAppSelector((state) => state.permissions);
    const hasEditJobAuth = permissions.some((permission) => permission.permissionName === 'canEditJobs');

    return (
        <>
            <WarningAlert
                show={(window?.qt && !canEncrypt) || false}
                message='Encryption is disabled for this job, some of its features are not supported and require an update. Please download the newest version of the application'
            />
            <WarningAlert
                show={isReadOnly}
                message='This job is currently in read-only mode, some of its security restrictions are not currently supported and can not be edited'
            />
            <WarningAlert show={archived || false} message='This job is currently in archived, please unarchive the job in order to modify it' />
            <Header
                editable
                isReadOnly={archived || isReadOnly}
                name={name}
                skeleton
                date={createDate ? new Date(createDate) : undefined}
                author={{
                    firstName: createdBy?.firstName,
                    lastName: createdBy?.lastName,
                    id: createdBy?.id
                }}
                pageControl={{ tabValue, setTabValue }}
                encryptButton
                ActionButton={encryptCheck ? AddFileButton : false}
                // ActionButton={AddFileButton}
                titleProps={{ setterFn: updateJob }}
                breadcrumbProps={{ links: [{ id: 0, name: 'Jobs', to: '/jobs' }], text: [{ id: 0, name }] }}
                tabProps={{
                    tabs: !mobile ? tabs.filter((tab) => !tab.mobileOnly) : tabs,
                    tabControl,
                    tabValue,
                    setTabValue
                }}
                headerItems={headerItems}
                titleValidation={nameLength}
            />

            <Content>
                {(cx) => (
                    <div ref={cardRef} className={cx.cardWrapper}>
                        {tabs.map(({ label, modal, component, addButtonTitle, Icon, btnId, cardId }, index) => (
                            <Card id={cardId} elevation={0} className={cx.profileCard}>
                                <div ref={tabControl[index]?.ref || null} className={cx.titleBlock}>
                                    <Typography color='textPrimary' className={cx.cardTitle} variant='h5'>
                                        {label}
                                    </Typography>
                                    {modal && isAuth && type === 'ONLINE' && hasEditJobAuth && (
                                        <AddItemButton id={btnId} Modal={modal} Icon={Icon} title={addButtonTitle} disabled={archived || isReadOnly} />
                                    )}
                                </div>
                                {component}
                            </Card>
                        ))}
                        {window?.qt && !mobile && <FileList />}
                        {advancedOptionsOpen && (
                            <ReauthTime
                                features={features}
                                openProps={[advancedOptionsOpen, setAdvancedOptionsOpen]}
                                rollOver={isRolloverEnabled()}
                                disabled={!isAuth || isReauthEnabled()}
                                isOffline={type === 'OFFLINE'}
                                setterFn={setEditValues}
                                active={isReauthEnabled()}
                            />
                        )}
                    </div>
                )}
            </Content>
        </>
    );
};

export default React.memo(Job);
