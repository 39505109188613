import { SET_ENCRYPTION_SUCCEEDED } from '@mediaseal-webui/constants';
import { EncryptFile } from '@mediaseal-webui/types/encryptor';
import { ThunkResult } from '@mediaseal-webui/types/redux';
import { findFile } from '@mediaseal-webui/utils';
import { RootState } from 'packages/online/src/store';

/**
 *
 * @param id string
 * @returns void
 *
 * Triggered by QT when a file succeeds and finishes encryption
 */

export const encryptionWorkSucceeded =
    (id: EncryptFile['id']): ThunkResult<void> =>
    (dispatch, getState: () => RootState) => {
        console.log(`Received Signal encryptionWorkSucceeded from QT with ID : ${id}`);

        dispatch({ type: SET_ENCRYPTION_SUCCEEDED, payload: { id } });
    };
