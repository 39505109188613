import { makeStyles, fade } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:hover': {
            backgroundColor: theme.palette.common.white
        },
        '&$focused': {
            backgroundColor: theme.palette.common.white,
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main
        },
        '& > label': {
            backgroundColor: theme.palette.common.white,
            zIndex: 22
        }
    },
    error: {
        '& fieldset': {
            borderColor: theme.palette.error.main
        }
    },
    valid: {
        '& label': {
            color: theme.palette.success.main
        },
        '& fieldset': {
            transition: theme.transitions.create(['all'], {
                duration: theme.transitions.duration.shortest
            }),
            borderColor: theme.palette.success.main
        }
    },
    icon: {
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.shorter
        })
    },
    faded: {
        opacity: 0
    },
    focused: {}
}));

export const useTooltipStyles = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.grey[200],
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11
    },
    arrow: {
        color: theme.palette.error.main
    }
}));

export const useTextFieldStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    }
}));

export default useStyles;
