import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useDefaultRoute } from 'hooks/useDefaultRoute';
import routes from '../../../routes';
import { isKeycloakActive } from '@mediaseal-webui/hooks/src/isKeycloakActive';

const Routes = () => {
    const { pathname } = useLocation();
    const defaultRoute = useDefaultRoute();
    return (
        <div>
            <Switch>
                {pathname === '/' && <Redirect to='/jobs' />}
                {routes(defaultRoute)
                    .filter((route) => isKeycloakActive() || !route.isKeycloakOnlyComponent)
                    .map((route) => (
                        <Route key={route.id} exact path={route.path} component={route.component} />
                    ))}
                {/* <ProtectedRoute key={1} path={'/'} exact component={Jobs} /> */}
            </Switch>
        </div>
    );
};

export default Routes;
