import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InitialState } from '@mediaseal-webui/types/redux';
import { setConfirmDialogue, setCreate, clearSummary, setSecurityOptions } from '@mediaseal-webui/actions/src';

export const useConfirmDialogue = () => {
    const dispatch = useDispatch();
    const { decryptorUsers, decryptorGroups, authMethod } = useSelector((state: InitialState) => state.entity.create);

    useEffect(() => {
        if (authMethod === 'Password Only') {
            dispatch(clearSummary());
        }
    }, [authMethod, dispatch]);

    const openDialog = (type: string) => {
        switch (type) {
            case 'password':
                if ((decryptorUsers && decryptorUsers?.length > 0) || (decryptorGroups && decryptorGroups?.length > 0)) {
                    dispatch(
                        setConfirmDialogue({
                            title: 'Reset users and groups',
                            message: 'This action will reset the users and groups associated to this job. Are you sure you want to do this?',
                            action: setCreate({
                                authMethod: 'Password Only',
                                type: 'PASSWORD_ONLY'
                            }),
                            icon: 'warning'
                        })
                    );
                } else {
                    dispatch(
                        setCreate({
                            authMethod: 'Password Only',
                            type: 'PASSWORD_ONLY',
                            sunrise: undefined,
                            sunset: undefined
                        })
                    );
                }
                dispatch(setSecurityOptions({ disableSunset: true }));
                break;
            case 'offline':
                dispatch(
                    setCreate({
                        authMethod: 'Password + User',
                        authMode: 3,
                        type: 'OFFLINE',
                        sunrise: undefined,
                        sunset: undefined
                    })
                );
                dispatch(setSecurityOptions({ disableSunset: true }));
                break;
            case 'online':
                dispatch(
                    setCreate({
                        authMethod: 'Password + User + Online',
                        authMode: 7,
                        type: 'ONLINE'
                    })
                );
                break;
            default:
                break;
        }
    };

    return {
        openDialog
    };
};
