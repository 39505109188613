import { msApi } from '@mediaseal-webui/api';
import { stopFetching } from '../generic';
import { setError } from '../entity';
import {
    ADD_DRAFT_INVITE,
    DELETE_DRAFT_INVITE,
    UPDATE_DRAFT_INVITE,
    CLEAR_DRAFT_INVITES,
    DELETE_INVITE,
    RESEND_INVITE,
    CLEAR_FILTERED_INVITES,
    SET_INVITE_PAGE_COUNT,
    SET_TOTAL_INVITES,
    SET_TOTAL_PENDING_INVITES,
    SET_TOTAL_REJECTED_INVITES,
    SET_INVITE_PAGE_SEARCH_VALUE,
    CLEAR_INVITE_PAGE_SEARCH_VALUE,
    SET_INVITE_ORDER,
    SET_PENDING_INVITES,
    SET_REJECTED_INVITES,
    SET_PENDING_PAGE_COUNT,
    SET_REJECTED_PAGE_COUNT,
    SET_ADDITIONAL_PENDING_INVITES,
    SET_ADDITIONAL_REJECTED_INVITES
} from '@mediaseal-webui/constants';
import { DuplicateInviteError } from './errors';
import { ThunkResult } from '@mediaseal-webui/types/redux';
import { EntityCreationInitialState, DraftInvite, Invite, SortOrders, SortOptions } from '@mediaseal-webui/types';

/**
 * Called from InviteModal on submit of entire list
 *
 * @param (bool) => {} setSending
 */
export const dispatchDraftInvites =
    (setSending: (val: boolean) => void): ThunkResult<void> =>
    async (dispatch, getState) => {
        const inviteUser = async (invite: DraftInvite) => {
            const { data } = await msApi.post('/invites', invite);
            dispatch({ type: UPDATE_DRAFT_INVITE, payload: data });
        };
        const requests = getState().invitations.draftInvites.map(async (invite: DraftInvite) => inviteUser(invite));
        await Promise.all(requests)
            .then(() => {
                setSending(false);
                dispatch(fetchInvites({ status: 'PENDING', page: 0, firstCall: true }));
            })
            .catch((error) => {
                error.message =
                    'There was an error that occured when trying to invite one of the users, please cancel and retry the invite or contact support for assistance';
                dispatch(setError(error));
                setSending(false);
            });
        dispatch(stopFetching());
    };

export const addDraftInvite =
    (context: EntityCreationInitialState): ThunkResult<void> =>
    async (dispatch, getState) => {
        const { firstName, lastName, email } = context;
        const { draftInvites } = getState().invitations;

        const invite = {
            email: email.value,
            firstName: firstName.value,
            lastName: lastName.value
        };

        if (draftInvites.some((i) => i.email === email.value)) {
            const error = new DuplicateInviteError();
            dispatch(setError(error));
        } else {
            dispatch({ type: ADD_DRAFT_INVITE, payload: invite });
        }
    };

/**
 * Delete from draftInvites
 * Called from InviteModal
 *
 * @param { email, firstName, lastName } invite
 */
export const deleteDraftInvite = (invite: DraftInvite) => ({
    type: DELETE_DRAFT_INVITE,
    payload: invite
});

/**
 * Fetch invites
 *
 * @param { type } all optional
 */
export const fetchInvites =
    (args = { status: 'PENDING', page: 0, firstCall: true }): ThunkResult<void> =>
    async (dispatch) => {
        try {
            /**
             * Other fields include:
             * { size, first, last, sort, number, numberOfElements }
             */
            const { data } = await msApi.get(`/invites?status=${args.status}&page=${args.page}&size=100`);
            const { content, totalPages, totalElements } = data;

            dispatch({
                type: args.firstCall
                    ? args.status === 'PENDING'
                        ? SET_PENDING_INVITES
                        : SET_REJECTED_INVITES
                    : args.status === 'PENDING'
                    ? SET_ADDITIONAL_PENDING_INVITES
                    : SET_ADDITIONAL_REJECTED_INVITES,
                payload: content
            });
            dispatch({ type: args.status === 'PENDING' ? SET_PENDING_PAGE_COUNT : SET_REJECTED_PAGE_COUNT, payload: totalPages });
            dispatch({ type: args.status === 'PENDING' ? SET_TOTAL_PENDING_INVITES : SET_TOTAL_REJECTED_INVITES, payload: totalElements });

            dispatch(stopFetching());
        } catch (error) {
            error.message = 'An error occurred whilst trying to fetch invitations';
            dispatch(setError(error));
        }
        dispatch(stopFetching());
    };

/**
 * Delete from filteredInvites
 * Called from Decyptor users (invite) tabs
 *
 * @param { id: Int, email: String, firstName: String?, lastName: String?, status: String } invite
 */
export const deleteInvite =
    (invite: Invite): ThunkResult<void> =>
    async (dispatch, getState) => {
        const { status } = invite;
        try {
            await msApi.delete(`invites/${invite.id}`);
            dispatch({ type: DELETE_INVITE, payload: invite });
            // refetch for the same status as before
            dispatch(fetchInvites({ status, page: 0, firstCall: true }));
            dispatch({
                type: SET_TOTAL_INVITES,
                payload: getState().invitations.totalInvites - 1
            });
            dispatch({
                type: 'SET_SNACKBAR',
                payload: { success: true, message: 'Invitation has been deleted' }
            });
        } catch (error) {
            error.message = 'Invitation could not be deleted.';
            dispatch(setError(error));
        }
        dispatch(stopFetching());
    };

/**
 * Called from Decyptor users (invite) tabs
 *
 * @param { id: Int, email: String, firstName: String?, lastName: String?, status: String } invite
 */
export const resendInvite =
    (invite: Invite): ThunkResult<void> =>
    async (dispatch) => {
        const { status } = invite;
        try {
            const { data } = await msApi.post(`invites/${invite.id}/resend`);
            dispatch({ type: RESEND_INVITE, payload: data });
            // refetch for the same status as before
            dispatch(fetchInvites({ status, page: 0, firstCall: true }));
            dispatch({
                type: 'SET_SNACKBAR',
                payload: { success: true, message: 'Invitation has been resent' }
            });
        } catch (error) {
            error.message = 'Invitation could not be resent.';
            dispatch(setError(error));
        }
        dispatch(stopFetching());
    };

/**
 * Clear invite lists
 */
export const clearInvites = () => ({ type: CLEAR_FILTERED_INVITES });
export const clearDraftInvites = () => ({ type: CLEAR_DRAFT_INVITES });

/**
 * Invite meta data
 */
export const setInvitePages = (pages: number) => ({
    type: SET_INVITE_PAGE_COUNT,
    payload: pages
});
export const setTotalInvites = (invites: number) => ({
    type: SET_TOTAL_INVITES,
    payload: invites
});
export const setInvitePageSearchValue = (value: string) => ({
    type: SET_INVITE_PAGE_SEARCH_VALUE,
    payload: value
});
export const clearInvitePageSearchValue = () => ({
    type: CLEAR_INVITE_PAGE_SEARCH_VALUE
});
export const setInviteSort = ({ sortDir, sortBy }: { sortDir: SortOrders; sortBy: SortOptions }) => ({ type: SET_INVITE_ORDER, payload: { sortDir, sortBy } });
