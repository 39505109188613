import { EncryptFile, EncryptionWork, FilePayload } from '@mediaseal-webui/types';

export const constructFilePayload = (item: EncryptFile, jobId: number | string): FilePayload => ({
    idFile: item.id,
    job: `api/jobs/${jobId}`,
    mediaKey: item.mediaKeyBase64,
    name: item.inputFileName,
    source: item.fullFilePath,
    fileSize: item.fileSize,
    fileType: item.fileType,
    encryptionSessionId: item.encryptionSessionId
});

export const findFile = (encryptionWork: EncryptionWork[], id: string | number) =>
    encryptionWork.flatMap((work) => work.fileList).find((file) => file?.id === id);
