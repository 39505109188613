import styled from 'styled-components';

export const MobileLeftContainer = styled.div`
    display: flex;
    width: 90%;
`;
export const MobileRightContainer = styled.div`
    display: flex;
    width: 10%;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-end;
    margin-top: 16px;
`;

export const MobileBodyRoot = styled.div(
    ({ theme }) => `
    width: 100%;
    flex-wrap: wrap;
    height: calc(100% - ${theme.spacing(6)}px);
    padding-top: ${theme.spacing(1)}px;
    display: flex;
`
);

export const MobileMiddleContainer = styled.div(
    ({ theme }) => `
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
`
);
