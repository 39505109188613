import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FaFileCsv } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { Tab, Tabs } from '@material-ui/core';
import { Content, SubmitButton } from '@mediaseal-webui/components';
import {
    SET_MORE_REPORT_DATA,
    SET_REPORT_DATA,
    SET_REPORT_ELEMENTS,
    SET_TOTAL_REPORT_PAGES,
    STOP_FETCHING,
    START_FETCHING,
    FETCH_ERROR,
    SET_AUDIT_TAB,
    SET_REPORTING_FILTER
} from '@mediaseal-webui/constants';
import { useAuditTableSort, useTableScroller } from '@mediaseal-webui/hooks';
import { csvService } from '@mediaseal-webui/services';
// eslint-disable-next-line import/no-webpack-loader-syntax
import CSVWorker from 'worker-loader!@mediaseal-webui/services/src/CSVService/worker';
import { AuditService } from '@mediaseal-webui/services/src/AuditService/AuditService';
import { AuditData } from '@mediaseal-webui/services/src/CSVService/types';
import { Header } from '../../components/layout-components';
import { clearReportData, displayEntitySummary } from '../../actions/reporting';
import { CSVDialogue } from '../../components/reporting-components/CSVDialogue/CSVDialogue';
import { FileDropDialogue } from '../../components/reporting-components/FileDropDialogue/FileDropDialogue';
import ReportingFilters from '../../components/reporting-components/ReportingFilters/ReportingFilters';
import ResponsiveSummaryModal from '../../components/reporting-components/ResponsiveSummaryModal/ResponsiveSummaryModal';
import ReportingTable from '../../components/table-components/ReportingTable';
import { reportingDecryptorHeader, reportingEncryptorHeader } from '../../data';
import reportingStyle from './Reporting.styles';
import { useAppSelector } from 'store';
import { useGenAuthTokenFn } from '@mediaseal-webui/hooks/src/useGenAuthTokenFn';

const worker = new CSVWorker();
const Reporting = () => {
    const classes = reportingStyle();
    const [tabIndex, setTabIndex] = useState(0);
    const [showCSVDialog, setShowCSVDialog] = useState<boolean>(false);
    const { data, totalPages, totalElements, isDecryptorTab } = useAppSelector((state) => state.reporting);
    const { isFetching } = useAppSelector((state) => state.misc);
    const [page, setPage] = useState(1);
    const [progress, setProgress] = useState<number>(1);
    const [stage, setStage] = useState<string>('');
    const dispatch = useDispatch();
    const auditService = useMemo(() => new AuditService(isDecryptorTab ? '/audits/fileAccess' : '/audits/activity'), [isDecryptorTab]);
    useTableScroller();
    const dispatchables = useCallback(
        (totalPages, totalElements) => {
            dispatch({ type: SET_TOTAL_REPORT_PAGES, payload: totalPages });
            dispatch({ type: SET_REPORT_ELEMENTS, payload: totalElements });
            dispatch({ type: STOP_FETCHING });
        },
        [dispatch]
    );

    const fetchReports = useCallback(async () => {
        auditService.setParams({
            [isDecryptorTab ? 'action' : 'access']: isDecryptorTab ? 'CREATE' : 'ANY',
            userId: ''
        });
        dispatch({ type: START_FETCHING });
        const { data } = await auditService.getAudits();
        dispatch({ type: SET_REPORT_DATA, payload: data.content });
        dispatchables(data.totalPages, data.totalElements);
    }, [isDecryptorTab, auditService, dispatch, dispatchables]);

    const fetchNextPage = useCallback(async () => {
        if (auditService.auditParams.page <= totalPages && data.length >= 100) {
            auditService.setParams({
                page: auditService.auditParams.page + 1
            });
            dispatch({ type: START_FETCHING });
            const { data } = await auditService.getAudits();
            dispatch({ type: SET_MORE_REPORT_DATA, payload: data.content });
            dispatchables(data.totalPages, data.totalElements);
        }
    }, [data.length, dispatch, dispatchables, totalPages, auditService]);

    const { createSortHandler } = useAuditTableSort(auditService, fetchReports);

    useEffect(() => {
        setTabIndex(isDecryptorTab ? 0 : 1);
        fetchReports();
        return () => {
            dispatch(clearReportData());
        };
    }, [dispatch, fetchReports, isDecryptorTab]);

    const handleRowClick = (item) => dispatch(displayEntitySummary(item));

    const urlType = () => {
        const url = auditService.url;
        if (url === '/audits/fileAccess') return 'fileAccess';
        if (url === '/audits/activity') return 'activity';
        if (url === '/audits/fileSave') return 'saveOut';
    };

    const authToken = useGenAuthTokenFn();

    const handleSubmit = () => {
        try {
            if (data.length > 0) {
                setShowCSVDialog(true);
                worker.postMessage({
                    pages: totalPages,
                    token: authToken,
                    service: JSON.stringify(auditService),
                    type: urlType()
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const generateCSV = useCallback(
        async (data: AuditData[]) => {
            const title = `AuditData-${auditService.auditParams.startDate}-${auditService.auditParams.endDate}`;
            await csvService.generateReport(data, title);
            setTimeout(() => {
                setProgress(0);
            }, 500);
        },
        [auditService.auditParams.startDate, auditService.auditParams.endDate]
    );

    useEffect(() => {
        window.qt && window.GeneralWebInterface.allowFileDrop(false, { dropBehaviour: 'passthrough' });
        worker.onmessage = (event) => {
            setStage(event.data.status);
            event.data.progress && setProgress(event.data.progress);
            if (event.data.complete) {
                setProgress(100);
                dispatch({ type: STOP_FETCHING });
                setTimeout(() => {
                    setShowCSVDialog(false);
                    generateCSV(event.data.reportData);
                }, 2000);
            }

            if (event.data.error) {
                dispatch({
                    type: FETCH_ERROR,
                    payload: event.data.error
                });
            }
        };
    }, [dispatch, generateCSV]);

    const CreateButton = () => (
        <SubmitButton
            onClick={handleSubmit}
            title='Download CSV'
            display='flex'
            alignItems='center'
            Icon={FaFileCsv}
            id='run-report-btn'
            disabled={data.length === 0}
        />
    );

    const handleChange = (_, tabIndex) => {
        setTabIndex(tabIndex);
        dispatch({ type: SET_REPORTING_FILTER, payload: 0 });
        dispatch({ type: SET_AUDIT_TAB, payload: tabIndex === 0 });
    };

    const columns = isDecryptorTab ? reportingDecryptorHeader(auditService.url === '/audits/fileSave') : reportingEncryptorHeader();

    const breadcrumbProps = {
        links: [{ id: 0, name: 'Audit', to: '/reporting' }],
        text: [{ id: 0, name: '' }]
    };

    return (
        <div className={classes.viewRoot}>
            <Header
                name='Audit'
                list
                displayingText={`Displaying ${data.length} of ${totalElements} records`}
                breadcrumbProps={breadcrumbProps}
                ActionButton={CreateButton}
                loaderText='Generating...'
            />
            <Content classes={{ content: classes.blockScrolling }}>
                {({ stack }) => (
                    <>
                        <div className={stack}>
                            <>
                                <Tabs value={tabIndex} onChange={handleChange}>
                                    <Tab label='Decryptors' disabled={isFetching} className={classes.tab} />
                                    <Tab label='Encryptors' disabled={isFetching} className={classes.tab} />
                                </Tabs>
                            </>
                            <div className={classes.reportFilters}>
                                <ReportingFilters service={auditService} pageControl={{ page, setPage }} />
                            </div>
                            <ReportingTable
                                entities={data}
                                id='reports-table'
                                columns={columns}
                                onRowClick={handleRowClick}
                                totalPages={totalPages}
                                fetchFn={fetchNextPage}
                                sortData={{ createSortHandler }}
                            />
                        </div>
                    </>
                )}
            </Content>
            <ResponsiveSummaryModal />
            <CSVDialogue open={showCSVDialog} />
            <FileDropDialogue />
        </div>
    );
};

export default Reporting;
