import { makeStyles } from '@material-ui/core';
const WIDTH = 85;
const HEIGHT = 32;

const MOBILE_WIDTH = 10;
const MOBILE_HEIGHT = 10;
export default makeStyles((theme) => ({
    tableCell: {
        borderBottom: 'unset',
        padding: '10px 10px 10px 0',
        [theme.breakpoints.down('sm')]: {
            padding: 5,
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
        }
    },
    pill: {
        width: WIDTH,
        height: HEIGHT,
        [theme.breakpoints.down('xs')]: {
            width: MOBILE_WIDTH,
            height: MOBILE_HEIGHT
        }
    },
    success: {
        textTransform: 'capitalize',
        background: theme.palette.primary.light,
        color: theme.palette.common.white
    },
    error: {
        textTransform: 'capitalize',
        background: theme.palette.error.main,
        color: '#ffffff'
    },
    info: {
        textTransform: 'capitalize',
        background: theme.palette.info.main,
        color: theme.palette.common.white
    }
}));
