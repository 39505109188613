import React from 'react';
import { MdInfoOutline as Info } from 'react-icons/md';
import useSharedStyles from './sharedStyles';

export const InfoIcon = () => {
    const classes = useSharedStyles();
    return (
        <div className={classes.iconRoot}>
            <Info className={classes.singleIcon} />
        </div>
    );
};
