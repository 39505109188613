import React, { useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearGroupPageSearchValue, setConfirmDialogue } from '@mediaseal-webui/actions';
import { Header } from '../../../components/layout-components';
import { genericHeader } from '../../../data';
import { useEntityDelete } from '@mediaseal-webui/hooks';
import useStyles from './Encryptor.styles';
import { useAuth, useTableSortV2 } from '@mediaseal-webui/hooks';
import ActionContainer from '../../../components/table-components/ActionContainer/ActionContainer';
import { Content, MainTable, SubmitButton } from '@mediaseal-webui/components';
import { useTableScroller } from '@mediaseal-webui/hooks';
import { EncryptorGroupService } from '@mediaseal-webui/services';
import { useAppSelector } from 'store';
import { encryptorGroupsSlice, searchEncryptorGroups, fetchEncryptorGroups } from 'reducers/groups/groups';
import { TableColumns } from '@mediaseal-webui/types';

const Encryptor = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useAuth();
    const service = useMemo(() => new EncryptorGroupService(), []);
    const isCreateAuth = auth.create;
    const { setSearchValue, clearSearchValue, clearGroups } = encryptorGroupsSlice.actions;

    const { content: groups, page, searchValue: groupPageSearchValue, pending } = useAppSelector((state) => state.encryptorGroups);
    useTableScroller();

    const fetchFn = useCallback(() => dispatch(searchEncryptorGroups({ keyword: service.baseParams.keyword ?? '', service })), [service, dispatch]);
    const { memoDeleteEntityAndReFetch } = useEntityDelete({ fetchFn });
    const { items: permissions } = useAppSelector((state) => state.permissions);
    const hasEditEncryptorGroupsPermission = permissions.some((permission) => permission.permissionName === 'canEditEncryptorGroups');
    const { headCells, sortFn, order, orderBy } = useTableSortV2({
        cells: genericHeader,
        fetchFn,
        service
    });

    useEffect(() => {
        window.qt && window.GeneralWebInterface.allowFileDrop(false, { dropBehaviour: 'off' });
        dispatch(searchEncryptorGroups({ service, keyword: '' }));
        return () => {
            dispatch(clearGroups());
            dispatch(clearGroupPageSearchValue());
        };
    }, [dispatch, clearGroups, service]);

    const handleCreateClick = () => history.push('/groups/encryptor/create');

    const handleRowClick = (group) => history.push(`/groups/encryptor/${group.id}/profile`);
    const handleGroupDelete = (entity) => () =>
        dispatch(
            setConfirmDialogue({
                icon: 'delete',
                title: 'Delete Group',
                confirmButtonText: 'Delete Group',
                message: 'Are you sure you want to delete this group?',
                action: memoDeleteEntityAndReFetch({ entity, endpoint: 'groups' })
            })
        );
    const CreateButton = () => <SubmitButton title='Create Group' id='create-enc-group-button' disabled={false} onClick={handleCreateClick} />;

    const fetchNextPage = useCallback(async () => {
        if (service.baseParams.page <= (page?.totalPages ?? 0)) {
            service.setParams({
                page: service.baseParams.page + 1
            });
            await dispatch(fetchEncryptorGroups(service));
        }
    }, [dispatch, page?.totalPages, service]);

    return (
        <div className={classes.viewRoot}>
            <Header
                list
                name='Encryptor Groups'
                ActionButton={auth.create && CreateButton}
                displayingText={`Displaying ${groups.length} of ${page?.totalElements} encryptor groups`}
                breadcrumbProps={{
                    links: [{ id: 0, name: 'Encryptor Groups', to: '/groups/encryptor' }],
                    text: [{ id: 0, name: '' }],
                    isFetching: pending
                }}
                searchProps={{
                    setPage: () => null,
                    searchFn: 'searchEncryptorGroups',
                    value: groupPageSearchValue,
                    set: setSearchValue,
                    clear: clearSearchValue
                }}
            />

            <Content>
                {() => (
                    <div className={classes.wrapper}>
                        <MainTable
                            entities={groups}
                            isFetching={pending}
                            id='encryptor-group-table'
                            totalPages={page?.totalPages}
                            searchValue={groupPageSearchValue}
                            noResultsMessage='No groups returned'
                            pageControl={{ page: service.baseParams.page, setPage: () => null }}
                            entityLink='/groups/encryptor'
                            fetchFn={fetchNextPage}
                            tableActionsRenderer={(entity) => <ActionContainer create={isCreateAuth} removeAction={handleGroupDelete(entity)} />}
                            sortData={{
                                sortBy: orderBy,
                                sortDirection: order,
                                createSortHandler: sortFn
                            }}
                            columns={hasEditEncryptorGroupsPermission ? headCells : headCells.filter((item: TableColumns) => item.label !== 'Actions')}
                            onRowClick={handleRowClick}
                        />
                    </div>
                )}
            </Content>
        </div>
    );
};

export default Encryptor;
