import React from 'react';
import clsx from 'clsx';
import { TextCell, StatusCell } from '../Cells';
import { processAuthMode, formatDateFns } from '@mediaseal-webui/utils';
import { Dispatchable } from '@mediaseal-webui/types/redux';
import { TableColumns } from '@mediaseal-webui/types/generic';
import { JobTypes } from '@mediaseal-webui/types/jobs';
import { ITableCellProps, ITableCellRenderer, SelectableRowData } from '.';
import { Check } from '../../Check';
import useStyles from './SelectableTable.styles';
import { TableCell } from '@material-ui/core';
import { PermissionsCell } from '../Cells/PermissionCell/PermissionCell';

export interface UseCellRendererProps {
    columns: TableColumns[];
    selectFn: (entity: SelectableRowData) => Dispatchable;
    deselectFn: (entity: SelectableRowData) => Dispatchable;
    isMobile: boolean;
    isFetching: boolean;
}

export const useCellRenderer = ({ columns, selectFn, deselectFn, isMobile, isFetching = false }: UseCellRendererProps): ITableCellRenderer => {
    const classes = useStyles();

    return ({ cellData, columnIndex, rowData }: ITableCellProps) => {
        const column = columns[columnIndex];
        const { label } = column;

        if (!rowData) {
            return null;
        }
        if (label === 'checkbox') {
            return <Check entity={rowData} selected={!!rowData.selected} selectFn={selectFn} deselectFn={deselectFn} />;
        }
        if (label === 'Created' || label === 'Last Modified') {
            return (
                <TextCell
                    hidden={columns[columnIndex].hidden}
                    output={rowData.createDate && !isMobile ? formatDateFns(cellData) : ''}
                    subtitle={processAuthMode(cellData) ?? ' '}
                    isFetching={isFetching}
                    skeletonHeight={15}
                    className={clsx([classes.flexContainer])}
                    //@ts-ignore
                    id={cellData}
                />
            );
        }
        if (label === 'Status') {
            return (
                <StatusCell
                    enableToolTip={!!rowData.title}
                    data={{ ...rowData, title: undefined }}
                    isFetching={isFetching}
                    skeletonHeight={24}
                    className={clsx([classes.flexContainer])}
                />
            );
        }
        if (label === 'ID') {
            return <TextCell output={cellData} isFetching={isFetching} skeletonHeight={15} className={clsx([classes.flexContainer])} />;
        }
        if (label === 'Last Used') {
            return (
                <TextCell
                    hidden={columns[columnIndex].hidden}
                    output={(rowData?.updateDate && formatDateFns(rowData.updateDate)) || ''}
                    isFetching={isFetching}
                    skeletonHeight={15}
                    className={classes.flexContainer}
                />
            );
        }
        if (label === 'Expires') {
            return (
                <TextCell
                    hidden={columns[columnIndex].hidden}
                    output={(rowData?.expirationDate && formatDateFns(rowData.expirationDate)) || ''}
                    isFetching={isFetching}
                    skeletonHeight={15}
                    className={classes.flexContainer}
                />
            );
        }
        if (label === 'Permissions') {
            const permissions = rowData.permissionNames;
            const permissionsCount = rowData.permissionNames.length;

            return (
                <TableCell component='div' align='right' className={classes.flexContainer} classes={{ root: `${classes.tableCell}` }}>
                    <PermissionsCell count={permissionsCount} permissions={permissions} />
                </TableCell>
            );
        }
        return (
            <TextCell
                output={cellData}
                isFetching={isFetching}
                subtitle={processAuthMode(rowData.authMode as JobTypes) ?? ' '}
                skeletonHeight={15}
                className={clsx([classes.flexContainer])}
            />
        );
    };
};
