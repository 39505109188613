import SimpleSelect from './SimpleSelect';
import { ComponentProps } from 'react';
import { Select } from '@material-ui/core';
import { ReportingActions } from '@mediaseal-webui/types';

export interface SelectOption {
    id: number | string;
    name: string;
    value?: number;
    query?: string;
    url?: string;
}

export interface SimpleSelectProps extends ComponentProps<typeof Select> {
    options: SelectOption[] | ReportingActions[];
    setter: (option: SelectOption | ReportingActions) => void;
    value?: SelectOption | ReportingActions;
    selectedOption?: SelectOption | ReportingActions;
    width?: string | number;
    label?: string;
    disabled?: boolean;
}

export default SimpleSelect;
