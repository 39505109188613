import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useMobile } from '@mediaseal-webui/hooks';
import { useSideNav } from '@mediaseal-webui/hooks';
import { InitialState } from '@mediaseal-webui/types/redux';
import { HeaderProps } from '.';
import { MobileHeaderBody } from './Mobile/Mobile';
import { DesktopHeaderBody } from './Desktop/Desktop';
import { HeaderTabs } from './Tabs/Tabs';
import useStyles from './Header.styles';

const Header = (props: HeaderProps) => {
    const { onSubmit = () => null, encryptButton = false, tabProps, profile } = props;
    const { error } = useSelector(({ misc: { errors } }: InitialState) => errors);
    const classes = useStyles();
    const mobile = useMobile();
    const { sideNavOpen } = useSideNav();
    const [isError, setIsError] = useState(false);

    const { isFetching } = useSelector(({ misc }: InitialState) => misc);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!isFetching) {
            setLoading(false);
        }
        if (error) {
            setIsError(true);
        } else setIsError(false);
    }, [isFetching, error]);

    const submitHandler = () => {
        !encryptButton && !isError && setLoading(true);
        onSubmit();
    };

    useEffect(() => {
        if (!isFetching) {
            setLoading(false);
        }
    }, [isFetching]);

    const bodyProps = { ...props, loading, submitHandler };

    return (
        <section
            className={clsx(classes.headerRoot, {
                [classes.sidebarOpen]: sideNavOpen,
                [classes.mobile]: mobile,
                [classes.headerWithTabs]: tabProps
            })}>
            {mobile ? <MobileHeaderBody {...bodyProps} /> : <DesktopHeaderBody {...bodyProps} />}

            {tabProps ? <HeaderTabs {...tabProps} /> : profile && <div className={classes.headerTabHeightPlaceholder} />}
        </section>
    );
};

export default React.memo(Header);
