import { TableColumns } from '@mediaseal-webui/types/generic';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

export const selectableUserHeader: TableColumns[] = [
    {
        label: 'checkbox',
        flexGrow: 0,
        disableSort: true,
        width: 50,
        order: 'desc'
    },
    {
        label: 'ID',
        dataKey: 'id',
        flexGrow: 0,
        order: 'asc',
        hidden: ['xs'] as Breakpoint[],
        disableSort: true
    },
    {
        flexGrow: 0.8,
        width: 75,
        label: 'First Name',
        dataKey: 'firstName',
        order: 'asc',
        disableSort: true
    },
    {
        flexGrow: 0.8,
        width: 75,
        label: 'Last Name',
        dataKey: 'lastName',
        order: 'asc',
        disableSort: true
    },
    {
        flexGrow: 2,
        flexShrink: 0,
        width: 150,
        label: 'Email Address',
        dataKey: 'email',
        order: 'asc',
        hidden: ['xs', 'sm'] as Breakpoint[],
        disableSort: true
    },
    {
        flexGrow: 1,
        flexShrink: 0,
        label: 'Created',
        dataKey: 'createDate',
        order: 'desc',
        hidden: ['xs', 'sm'] as Breakpoint[],
        disableSort: true
    },
    {
        flexGrow: 1,
        flexShrink: 0,
        label: 'Last Modified',
        dataKey: 'updateDate',
        order: 'desc',
        hidden: ['xs', 'sm', 'md'] as Breakpoint[],
        disableSort: true
    },
    {
        label: 'Status',
        flexGrow: 0,
        width: 90,
        dataKey: 'active',
        disableSort: true,
        hidden: ['xs'] as Breakpoint[],
        order: 'desc'
    }
];

export const selectableGenericHeader: TableColumns[] = [
    {
        label: 'checkbox',
        flexGrow: 0,
        disableSort: true,
        width: 50,
        order: 'desc'
    },
    {
        label: 'ID',
        dataKey: 'id',
        flexGrow: 0,
        order: 'asc',
        hidden: ['xs'] as Breakpoint[],
        disableSort: true
    },
    {
        flexGrow: 2,
        label: 'Name',
        dataKey: 'name',
        width: 150,
        display: 'flex',
        order: 'asc',
        disableSort: true
    },
    {
        flexGrow: 1,
        flexShrink: 0,
        label: 'Created',
        dataKey: 'createDate',
        order: 'desc',
        hidden: ['xs', 'sm'] as Breakpoint[],
        disableSort: true
    },
    {
        flexGrow: 1,
        flexShrink: 0,
        label: 'Last Modified',
        dataKey: 'updateDate',
        order: 'desc',
        hidden: ['xs', 'sm'] as Breakpoint[],
        disableSort: true
    },
    {
        label: 'Status',
        dataKey: 'active',
        disableSort: true,
        flexGrow: 0,
        width: 90,
        flexShrink: 0,
        hidden: ['xs'] as Breakpoint[],
        order: 'desc'
    }
];

export const tokensHeader: TableColumns[] = [
    {
        label: 'checkbox',
        flexGrow: 0,
        disableSort: true,
        width: 50,
        order: 'desc'
    },
    {
        label: 'Name',
        dataKey: 'name',
        flexGrow: 2,
        order: 'asc',
        disableSort: true
    },
    {
        flexGrow: 0.8,
        width: 75,
        label: 'Created',
        dataKey: 'createDate',
        order: 'asc',
        disableSort: true
    },
    {
        flexGrow: 0.8,
        width: 75,
        label: 'Last Used',
        dataKey: 'lastUsed',
        order: 'asc',
        hidden: ['xs', 'sm'] as Breakpoint[],
        disableSort: true
    },
    {
        label: 'Expires',
        flexGrow: 0.8,
        width: 75,
        dataKey: 'expires',
        disableSort: true,
        order: 'desc'
    },
    {
        flexGrow: 0.8,
        width: 25,
        label: 'Permissions',
        dataKey: 'permissions',
        justifyContent: 'center',
        disableSort: true,
        order: 'desc',
        hidden: ['xs'] as Breakpoint[]
    }
];
