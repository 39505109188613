import axios from 'axios';
import axiosRetry from 'axios-retry';

import { filterContentArray } from './utils';
import { startFetching } from '@mediaseal-webui/actions';
import { useGenAuthTokenFn } from '@mediaseal-webui/hooks/src/useGenAuthTokenFn';

const location = typeof window === 'undefined' ? self.location.origin : window.location.origin; // eslint-disable-line no-restricted-globals
export const msApi = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? `${location}/EvsServices/api/` : process.env.REACT_APP_LOCAL_ENDPOINT + '/'
});

axiosRetry(msApi, {
    retries: 2,
    retryDelay: (retryCount) => {
        return retryCount * 1000;
    },
    retryCondition: (error) => {
        return error.response?.status === 401 || error.response?.status === 500 || !error.response;
    }
});

// Add a request interceptor
msApi.interceptors.request.use(
    (config) => {
        config.headers.authorization = window.$store?.dispatch(startFetching());
        config.headers.authorization = useGenAuthTokenFn();
        return config;
    },
    (error) => Promise.reject(error)
);

//error interceptor
msApi.interceptors.response.use(
    (response) => {
        const { data } = response;
        const { content } = data;
        if (content && Array.isArray(content)) {
            const filteredArr = filterContentArray(content);
            return {
                ...response,
                data: {
                    ...data,
                    content: filteredArr
                }
            };
        }
        return response;
    }
    // (error) => {
    //     switch (error.response.status) {
    //         case 401:
    //             window.$store?.dispatch(setError({
    //                 message: '401: You do not have the required permissions to perform this action'
    //             }))
    //         case 500:
    //             window.$store?.dispatch(setError({
    //                 message: '500: Something went wrong on our end but we`re working on it.'
    //             }));
    //             break;
    //         default:
    //             return error;
    //     }
    // }
);
