import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    tableCell: {
        flex: 1,
        borderBottom: 'unset',
        padding: '0 10px',
        [theme.breakpoints.down('sm')]: {
            padding: 5
        }
    }
}));

export default useStyles;
