import React, { useEffect, useMemo } from 'react';
import { Card, Typography } from '@material-ui/core';
import { useState } from 'react';
import { EditableEntityTitle } from '@mediaseal-webui/components';
import { useSettingsCardData } from '@mediaseal-webui/hooks';
import { updateServerSettings } from '@mediaseal-webui/actions';
import { useStyles, useInputStyles } from './SettingsCard.style';
import { SettingsCardData } from '@mediaseal-webui/types/settings';
import { useAppSelector } from 'store';

export interface SettingsCardProps {
    cardData: SettingsCardData;
    Component?: React.ElementType | null;
}

export const SettingsCard = ({ cardData, Component }: SettingsCardProps) => {
    const classes = useStyles();
    const inputClasses = useInputStyles();
    const statefulData = useSettingsCardData(cardData);
    const [value, setValue] = useState<string>('');

    const { items: permissions } = useAppSelector((state) => state.permissions);
    const canView = useMemo(
        () => (cardData.permissions ? cardData.permissions.every((perm) => permissions.some((p) => p.permissionName === perm)) : true),
        [permissions, cardData.permissions]
    );
    useEffect(() => {
        setValue(statefulData.formValue);
    }, [statefulData]);
    return canView ? (
        <>
            <Card elevation={0} className={classes.settingsCard}>
                <div className={classes.cardContent}>
                    <div className={classes.headingContainer}>
                        <Typography color='textPrimary' className={classes.heading} variant='h5'>
                            {statefulData.heading}
                        </Typography>
                        <Typography color='textPrimary' variant='body1'>
                            {statefulData.description}
                        </Typography>
                    </div>
                </div>
                <div className={classes.formField}>
                    {Component ? (
                        <Component />
                    ) : (
                        <EditableEntityTitle
                            id={cardData.inputId}
                            maxWidth='250px'
                            validation={cardData.validation}
                            data-testid='editable-input'
                            value={value}
                            setterFn={updateServerSettings}
                            inputClasses={inputClasses.input}
                            showInputBackground
                            field={cardData.stateKey}
                            isReadOnly={false}
                        />
                    )}
                </div>
            </Card>
        </>
    ) : null;
};
