import {
    SET_SUMMARY,
    SET_EDIT,
    SET_PROFILE,
    SET_ERROR,
    ADD_DEC_GROUP_TO_SUMMARY,
    ADD_DEC_USER_TO_SUMMARY,
    ADD_ENC_USER_TO_SUMMARY,
    ADD_ENC_GROUP_TO_SUMMARY,
    REMOVE_DEC_GROUP_FROM_SUMMARY,
    REMOVE_DEC_USER_FROM_SUMMARY,
    REMOVE_ENC_USER_FROM_SUMMARY,
    REMOVE_ENC_GROUP_FROM_SUMMARY,
    CLEAR_ERRORS,
    CLEAR_ENTITY,
    SET_CREATE,
    CLEAR_SUMMARY,
    FILTER_USER_ASSIGNED_TO_ENTITY,
    FILTER_DECRYPTOR_GROUP_ASSIGNED_TO_ENTITY,
    FILTER_DECRYPTOR_USER_ASSIGNED_TO_ENTITY,
    SET_FILTERED_PROFILE_DECRYPTOR_USERS,
    SET_FILTERED_PROFILE_DECRYPTOR_GROUPS
} from '@mediaseal-webui/constants';
import { stopFetching } from '../generic';

export * from './select';

export const addDecryptorGroupToSummary = (group) => ({
    type: ADD_DEC_GROUP_TO_SUMMARY,
    payload: group
});
export const addEncryptorGroupToSummary = (group) => ({
    type: ADD_ENC_GROUP_TO_SUMMARY,
    payload: group
});
export const addDecryptorUserToSummary = (user) => ({
    type: ADD_DEC_USER_TO_SUMMARY,
    payload: user
});
export const addEncryptorUserToSummary = (user) => ({
    type: ADD_ENC_USER_TO_SUMMARY,
    payload: user
});

export const removeDecryptorGroupFromSummary = (group) => ({
    type: REMOVE_DEC_GROUP_FROM_SUMMARY,
    payload: group
});
export const removeEncryptorGroupFromSummary = (group) => ({
    type: REMOVE_ENC_GROUP_FROM_SUMMARY,
    payload: group
});
export const removeDecryptorUserFromSummary = (user) => ({
    type: REMOVE_DEC_USER_FROM_SUMMARY,
    payload: user
});
export const removeEncryptorUserFromSummary = (user) => ({
    type: REMOVE_ENC_USER_FROM_SUMMARY,
    payload: user
});

export const clearErrors = () => ({ type: CLEAR_ERRORS });
export const clearEntity = () => ({ type: CLEAR_ENTITY });

export const clearSummary = () => ({ type: CLEAR_SUMMARY });

/**
 * Add an error to the error stack
 *
 * @param Error error
 */
export const setError = (error) => {
    const axiosErrors = error?.response?.data?.errors;
    let errorPayload = error;
    if (axiosErrors) {
        errorPayload = { ...error, message: axiosErrors.join(', ') };
    }
    return { type: SET_ERROR, payload: errorPayload };
};

export const setProfile = (entity) => ({ type: SET_PROFILE, payload: entity });

export const setCreate = (payload) => ({ type: SET_CREATE, payload });

export const setEdit =
    (payload = {}) =>
    (dispatch, getState) => {
        const { profile } = getState().entity;
        dispatch({
            type: SET_EDIT,
            payload: {
                ...profile,
                ...payload
            }
        });
        dispatch(transferToSummary());
    };

export const setEditValues = (payload) => ({ type: SET_EDIT, payload });

export const transferToSummary = () => async (dispatch, getState) => {
    const { profile } = getState().entity;
    const { users, decryptorUsers, decryptorGroups, encryptorGroups, allowedTokens } = profile;
    dispatch({
        type: SET_SUMMARY,
        payload: {
            users,
            decryptorUsers,
            decryptorGroups,
            encryptorGroups,
            allowedTokens
        }
    });
    decryptorUsers?.length > 0 &&
        decryptorUsers.forEach((user) =>
            dispatch({
                type: FILTER_DECRYPTOR_USER_ASSIGNED_TO_ENTITY,
                payload: user
            })
        );
    users?.length > 0 && users.forEach((user) => dispatch({ type: FILTER_USER_ASSIGNED_TO_ENTITY, payload: user }));
    decryptorGroups?.length > 0 &&
        decryptorGroups.forEach((group) =>
            dispatch({
                type: FILTER_DECRYPTOR_GROUP_ASSIGNED_TO_ENTITY,
                payload: group
            })
        );
    encryptorGroups?.length > 0 &&
        encryptorGroups.forEach((group) =>
            dispatch({
                type: FILTER_DECRYPTOR_GROUP_ASSIGNED_TO_ENTITY,
                payload: group
            })
        );
    allowedTokens?.length > 0 &&
        allowedTokens.forEach((token) =>
            dispatch({
                type: FILTER_DECRYPTOR_GROUP_ASSIGNED_TO_ENTITY,
                payload: token
            })
        );
};

/**
 * Search Profile
 */
const filterArray = (array, value) => {
    // eslint-disable-next-line no-useless-escape
    const cleansedString = value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    const filtered = array.map((user) => (Object.values(user).some((item) => new RegExp(cleansedString, 'ig').test(item)) ? user : null));
    return filtered.filter((item) => item !== null);
};
export const searchProfileDecryptorUsers = (value) => (dispatch, getState) => {
    const { profile } = getState().entity;
    const { decryptorUsers } = profile;
    const results = filterArray(decryptorUsers, value);
    dispatch({ type: SET_FILTERED_PROFILE_DECRYPTOR_USERS, payload: results });
    dispatch(stopFetching());
};
export const searchProfileDecryptorGroups = (value) => (dispatch, getState) => {
    const { profile } = getState().entity;
    const { decryptorGroups } = profile;
    const results = filterArray(decryptorGroups, value);
    dispatch({ type: SET_FILTERED_PROFILE_DECRYPTOR_GROUPS, payload: results });
    dispatch(stopFetching());
};
export const removeEntityAndUpdate =
    ({ entity, id, removeFn, updateFn }) =>
    async (dispatch, getState) => {
        try {
            dispatch(removeFn(entity));
            await dispatch(updateFn(id));
        } catch (error) {
            dispatch(setError(error));
            console.error(error);
        }
        dispatch(stopFetching());
    };
