import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    dialogTitle: {
        paddingTop: theme.spacing(1)
    },

    dialogContainer: {
        textAlign: 'center'
    },

    icon: {
        width: 50,
        height: 50,
        color: theme.palette.primary.main
    }
}));

export default useStyles;
