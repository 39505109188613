import { BaseService } from '../BaseService/BaseService';
const ENDPOINT = '/identity-provider';

export interface IdentityProvider {
    clientId: string;
    clientSecret: string;
    domain?: string | null;
    type: string;
    groupId?: string | number;
    tenantId?: string;
    data?: string | {};
}

export class IdentityProviderService extends BaseService<IdentityProvider, {}> {
    constructor() {
        super(ENDPOINT);
    }

    public async createProvider(config: IdentityProvider) {
        return await this.post(config);
    }

    public async fetchProvider() {
        return await this.get();
    }

    public async updateProvider(config) {
        return await this.patch('', config);
    }
    public async removeProvider() {
        return await this.delete();
    }

    public async fetchDefaultGroup() {
        return await this.get('/default-group');
    }

    public async updateDefaultGroup({ groupId }: Partial<IdentityProvider>) {
        return await this.patch('/default-group', { groupId });
    }
}
