import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';

interface UseStyleProps {
    width?: number | string;
}

interface ExpansionSectionProps {
    open: boolean;
    height?: string;
}
export const ExpansionSection = styled.div<ExpansionSectionProps>(
    ({ open }) => `
    display: flex;
    width: 100%;
    max-height: ${open ? '500px' : 0};
    overflow: ${open ? 'visible' : 'hidden'};
    transition: max-height 0.35s ease;
    `
);

// export const ExpansionAnimation = styled.div<ExpansionSectionProps>(({ open }) => `
//     flex: ${open ? 1 : 0};
//     transition: flex 0.35s linear;
// `)

export const StyledButtonItems = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justifycontent: space-between;
`;
interface StyledButtonRoot extends ExpansionSectionProps {
    width?: number | string;
    disabled?: boolean;
}
export const StyledButtonRoot = styled.div<StyledButtonRoot>(
    ({ open, width, theme, disabled }) => `
    width: ${width || '100%'};
    border: 1px solid ${disabled ? 'none' : open ? theme.palette.primary.main : theme.palette.grey[300]};
    border-radius: ${theme.spacing(1)}px;
    //@ts-ignore
    background: ${theme.palette.background.paper};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: ${theme.spacing(1)}px;
    padding-right: 7px;
    margin-bottom: ${theme.spacing(2)}px;
    margin-top: ${theme.spacing(1)}px;
    color: ${disabled ? theme.palette.grey[300] : theme.palette.common.black} !important;
    &:hover {
        cursor: ${disabled ? 'default' : 'pointer'};
        border: ${disabled ? 'none' : `1px solid ${theme.palette.primary.main}`};
    }
`
);

export const StyledTooltip = styled(Tooltip)`
    ${({ theme }) => `
  & .MuiTooltip-tooltip {
    font-size: 4rem;
  background-color: ${theme.palette.primary.main};
  }
  `}
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: UseStyleProps) =>
    makeStyles((theme) => ({
        disabled: {
            // pointerEvents: 'none',
            cursor: 'default',
            opacity: 0.5,
            '&:hover': {
                border: 'none'
            }
        },
        titleContainer: {
            display: 'flex',
            width: '90%',
            flexWrap: 'wrap',
            padding: theme.spacing(1)
        },
        icon: {
            width: 24,
            height: 24
        },
        optionsIcon: {
            color: theme.palette.text.primary
        },
        btnTitle: {
            width: '100%',
            fontWeight: 600,
            display: 'flex'
        },
        btnSubtitle: {
            fontSize: '0.8rem',
            [theme.breakpoints.down('xs')]: {
                fontSize: '0.65rem'
            }
        },
        endIcon: {
            color: theme.palette.grey[400],
            opacity: 0.6,
            '&:hover': {
                opacity: 1
            }
        },

        //expandable-styles
        expandContainer: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
            width: '100%'
        },
        panel: {
            width: '100%',
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: theme.spacing(1),
            background: theme.palette.common.white,
            display: 'block',
            alignItems: 'center',
            boxShadow: theme.shadows[0],
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            '&:hover': {
                cursor: 'pointer',
                border: `1px solid ${theme.palette.primary.main}`
            },
            '&.MuiExpansionPanel-root': {
                borderRadius: theme.spacing(1)
            }
        },
        summaryPanel: {
            padding: 0
        },
        summaryContent: {
            margin: 0
        },
        doneIcon: {
            width: 24,
            height: 24
            // transform: 'rotate(180deg)'
        },

        selected: {
            border: `1px solid ${theme.palette.primary.main}`
        },

        expandedIcon: {
            color: theme.palette.primary.main
        }
    }))(props);
