import React, { useRef, useState } from 'react';
import { Button, Fab, Grid, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch } from 'react-redux';
import { useEntityCreation, useMobile } from '@mediaseal-webui/hooks';
import { addDraftInvite } from '@mediaseal-webui/actions';
import useStyles from './InviteFormStyle.styles';
import { TextInput } from '@mediaseal-webui/components';
import { EntityCreationInitialState } from '@mediaseal-webui/types';

const InviteForm: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isMobile = useMobile();
    const { emailValidation, firstNameValidation, lastNameValidation, valid, setEntityCreationContext, entityCreationContext } = useEntityCreation([
        'email',
        'firstName',
        'lastName'
    ]);
    const { firstName, lastName, email } = entityCreationContext;
    const [reset] = useState<EntityCreationInitialState>({
        firstName: {
            valid: false,
            value: ''
        },
        lastName: {
            valid: false,
            value: ''
        },
        email: {
            valid: false,
            value: ''
        }
    });
    const handleChange = (field: string) => (value: string) => {
        setEntityCreationContext((prevState) => ({ ...prevState, [field]: { value, valid: false } }));
    };
    const handleSubmit = () => {
        dispatch(addDraftInvite(entityCreationContext));
        setEntityCreationContext(reset);
    };

    return (
        <Grid container className={classes.formContainer} direction='row' justify='space-between' alignItems={!isMobile ? 'baseline' : 'flex-start'}>
            <Grid item xs={12} md={10}>
                <form className={classes.form}>
                    <TextInput
                        id='emailAddressInvite'
                        data-testid='emailAddress'
                        label='Email'
                        type='email'
                        value={email?.value || ''}
                        placeholder='Email address'
                        className={classes.emailField}
                        setterFn={handleChange('email')}
                        validation={emailValidation}
                        tooltipText='Please enter a valid email address'
                        onBlurDisabled={true}
                    />
                    <div className={classes.optionalInputs}>
                        <TextInput
                            id='firstNameInvite'
                            data-testid='firstNameInvite'
                            label='First name'
                            placeholder='First Name'
                            value={firstName?.value || ''}
                            className={classes.field}
                            onSubmit={handleSubmit}
                            validation={firstNameValidation}
                            tooltipText='Please enter a valid first name'
                            setterFn={handleChange('firstName')}
                            onBlurDisabled={true}
                        />
                        <TextInput
                            id='lastNameInvite'
                            data-testid='lastNameInvite'
                            label='Last name'
                            placeholder='Last Name'
                            value={lastName?.value || ''}
                            className={classes.field}
                            validation={lastNameValidation}
                            tooltipText='Please enter a valid last name'
                            setterFn={handleChange('lastName')}
                            onBlurDisabled={true}
                        />
                    </div>
                </form>
            </Grid>

            <Grid item xs className={classes.buttonContainer}>
                <Tooltip title='Add invite' placement='top'>
                    {isMobile ? (
                        <Button id='addInviteButton' data-testid='addInviteButton' type='submit' color='primary' disabled={!valid} onClick={handleSubmit}>
                            <AddIcon />
                            Add Invite
                        </Button>
                    ) : (
                        <Fab
                            variant='extended'
                            id='addInviteButton'
                            data-testid='addInviteButton'
                            type='submit'
                            color='primary'
                            disabled={!valid}
                            onClick={handleSubmit}>
                            <AddIcon style={{ marginRight: 5 }} />
                            Add Invite
                        </Fab>
                    )}
                </Tooltip>
            </Grid>
        </Grid>
    );
};

export default InviteForm;
