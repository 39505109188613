import React from 'react';
import { EncUserTable, DecUserTable, DecGroupTable } from '..';
import { GroupSelection, UserSelection } from '../../../utility-components/Modals';
import { updateGroup } from '@mediaseal-webui/actions';
import { IOpenProps } from '@mediaseal-webui/types/generic';
import { AddGroup, AddUser } from '@mediaseal-webui/components/src/icons';

export const encTabs = [
    // {
    //     id: 0,
    //     attrId: 'summary-tab',
    //     label: 'Summary',
    //     boxDisplay: 'flex',
    //     mobileOnly: true,
    //     component: () => <MobileSummary Items={EncHeaderItems} />,
    // },
    {
        id: 1,
        attrId: 'enc-users-tab',
        cardId: 'enc-users-panel',
        label: 'Encryptor Users',
        boxDisplay: 'flex',
        path: '#section1Ref',
        ref: 'section1Ref',
        btnId: 'add-users',
        Icon: AddUser,
        modal: ({ openProps }: IOpenProps) => <UserSelection encryptor openProps={openProps} title='Add encryptor users' updateFn={updateGroup} />,
        component: <EncUserTable />
    },
    {
        id: 2,
        attrId: 'dec-users-tab',
        label: 'Decryptor Users',
        cardId: 'dec-users-panel',
        boxDisplay: 'flex',
        path: '#section2Ref',
        btnId: 'add-dec-users',
        ref: 'section2Ref',
        modal: ({ openProps }: IOpenProps) => <UserSelection openProps={openProps} title='Add decryptor users' updateFn={updateGroup} />,
        Icon: AddUser,
        component: <DecUserTable />
    },
    {
        id: 3,
        attrId: 'dec-groups-tab',
        cardId: 'groups-panel',
        label: 'Decryptor Groups',
        boxDisplay: 'flex',
        path: '#section3Ref',
        btnId: 'add-groups',
        ref: 'section3Ref',
        Icon: AddGroup,
        modal: ({ openProps }: IOpenProps) => <GroupSelection openProps={openProps} title='Add decryptor groups' updateFn={updateGroup} />,
        component: <DecGroupTable />
    }
];
