import { useContext, createContext, useEffect, useState } from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { useStore } from 'react-redux';

type SetSideNavOpen = (x: boolean) => void | React.Dispatch<React.SetStateAction<boolean>>;

export interface SideNavContextProps {
    sideNavOpen: boolean;
    setSideNavOpen: SetSideNavOpen;
}

export const SideNavContext = createContext<SideNavContextProps>({
    sideNavOpen: true,
    setSideNavOpen: (x: boolean) => {}
});

/**
 * Can be used in any component lower in the tree than the SideNav
 */
export const useSideNav = () => {
    const [userChoice, setUserChoice] = useState<string>('');
    const { sideNavOpen, setSideNavOpen } = useContext(SideNavContext);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const resizeListener = () => {
        if (userChoice === 'out') {
            setSideNavOpen(true);
            return;
        } else if (userChoice === 'in') {
            setSideNavOpen(false);
            return;
        } else if (matches) {
            setSideNavOpen(!matches);
        }
    };

    useEffect(() => {
        resizeListener();
        window.addEventListener('resize', resizeListener);
        return () => window.removeEventListener('resize', resizeListener);
    }, [resizeListener, sideNavOpen, setSideNavOpen]);

    //@ts-ignore
    const width = sideNavOpen ? theme.sizes.sideNav.open : theme.sizes.sideNav.closed;

    return { sideNavOpen, setSideNavOpen, width, userChoice, setUserChoice };
};
