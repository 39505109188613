import { EncryptFileWithIssues } from '@mediaseal-webui/types/encryptor';
import { ThunkResult } from '@mediaseal-webui/types/redux';
import { CONFIRM_ENC_FILE_OVERWRITE, ADD_FILE_TO_EXISTING_WORK, REMOVE_ENC_FILE_ISSUE, NEW_FILES_AVAILABLE } from '@mediaseal-webui/constants';
import { processEncryptionWork } from '../processes';
import { constructFilePayload } from '@mediaseal-webui/utils';
import { handleBatchAcl } from '../batching';
import { RootState } from 'packages/online/src/store';

/**
 * Moves an issue to the tempFileList as user has selected overwrite the file
 */
export const confirmFileOverwrite =
    (file: EncryptFileWithIssues): ThunkResult<void> =>
        (dispatch, getState: () => RootState) => {
            const { issues, encryptionWork } = getState().encryptor;
            const { activeJobId } = getState().jobs;
            const { batched } = getState().entity.profile;
            const totalIssues = issues?.length ?? 0;
            console.log(`calling confirmFileOverwrite for file with ID : ${file.id}`);
            const currentWork = encryptionWork.find((work) => work.jobData.id === Number(activeJobId));
            if (currentWork) {
                // add it to already existing work
                dispatch({
                    type: ADD_FILE_TO_EXISTING_WORK,
                    payload: {
                        id: Number(activeJobId),
                        fileList: [file]
                    }
                });
                dispatch({ type: REMOVE_ENC_FILE_ISSUE, payload: file });
            } else {
                dispatch({ type: CONFIRM_ENC_FILE_OVERWRITE, payload: file });
            }
            // file accepting overwrite is the last issue
            if (totalIssues === 1) {
                if (batched) {
                    //get an ACL
                    dispatch({ type: NEW_FILES_AVAILABLE, payload: true });
                    const fileInfo = constructFilePayload(file, activeJobId);
                    dispatch(handleBatchAcl(fileInfo, currentWork?.jobData.id || ''));
                } else {
                    dispatch(processEncryptionWork());
                }
                window.EncryptionWebInterface.clearDragDropBlock();
            }
        };
