import { QWebChannel } from 'qwebchannel';
// import '@mediaseal-webui/types'
import { Dispatchable, Encryptor, General, Studio, ThunkResult, Versioning } from '@mediaseal-webui/types';
interface Store {
    dispatch: (args: () => void) => void;
}

interface Actions {
    element: (args: any) => void;
}

interface Dispatchables {
    [key: string]: Dispatchable | ((args: any) => ThunkResult<void>) | ((args: any) => Dispatchable);
}

// class QtEventEmitter extends EventEmiiter {
//   on: any
//   createEvents(qtInterfaces:ArrayLike<() => void>): void {
//     for (const key in qtInterfaces) {
//       if (qtInterfaces.hasOwnProperty(key)) {
//         const fn = qtInterfaces[key];
//         if(typeof fn === 'function') {
//           this.on(`${key}`, () => {
//             console.log(`recieved event: ${key}`)
//             fn();
//           })
//           console.log(`Setup EventListener: ${key}`)
//         }
//       }
//     }
//   }

// }
// export const qtEvents = new QtEventEmitter()

export class QtApi {
    webChannel: object;
    encryptionWebInterface: Encryptor;
    generalWebInterface: General;
    studioWebInterface: Studio;
    versionWebInterface: Versioning;

    constructor() {
        this.webChannel = {};
        this.encryptionWebInterface = null;
        this.generalWebInterface = null;
        this.studioWebInterface = null;
        this.versionWebInterface = null;
        this.connect = this.connect.bind(this);
        this.connectEncryptionSlots = this.connectEncryptionSlots.bind(this);
        this.connectGeneralSlots = this.connectGeneralSlots.bind(this);
        this.connectStudioSlots = this.connectStudioSlots.bind(this);
        this.connectVersionSlots = this.connectVersionSlots.bind(this);
    }

    connect(): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                //@ts-ignore
                new QWebChannel(window.qt.webChannelTransport, (channel) => {
                    this.encryptionWebInterface = channel.objects.EncryptionWebInterface;
                    this.generalWebInterface = channel.objects.GeneralWebInterface;
                    this.studioWebInterface = channel.objects.StudioWebInterface;
                    this.versionWebInterface = channel.objects.VersionWebInterface;
                    //@ts-ignore
                    window.EncryptionWebInterface = this.encryptionWebInterface;
                    //@ts-ignore
                    window.GeneralWebInterface = this.generalWebInterface;
                    //@ts-ignore
                    window.StudioWebInterface = this.studioWebInterface;
                    //@ts-ignore
                    window.VersionWebInterface = this.versionWebInterface;
                    resolve(console.log('Connected to QT'));
                });
            } catch (error) {
                reject(new Error(error));
            }
        });
    }
    /**
     *
     * @param store - Redux store
     * @param actions - All redux actions that are used to connect to the QT interface <EncryptionWebInterface>
     *
     * Used to connect actions to their respective QT Slots
     *
     * All Actions MUST have the same name as their corresponding SLOT
     *
     * see actions/encryption for examples
     *
     */
    connectEncryptionSlots(store: Store, actions: Dispatchables): void {
        for (const key in actions) {
            if (actions.hasOwnProperty(key)) {
                const element = actions[key];
                if (key in this.encryptionWebInterface) {
                    if (typeof this.encryptionWebInterface[key] === 'object') {
                        //check if slot object
                        this.encryptionWebInterface[key].connect(function () {
                            //@ts-ignore
                            store.dispatch(element.apply(this, arguments));
                        });
                        console.log(`Successfully Connected Encryptor Slot: ${Object.keys(this.encryptionWebInterface).indexOf(key)}, to Action: ${key}`);
                    }
                } else {
                    console.log(`Found action: ${key}, Please ensure actions are named the same as their respective slots to ensure connection`);
                }
            }
        }
    }
    /**
     *
     * @param store - Redux store
     * @param actions - All redux actions that are used to connect to the QT interface <GeneralWebInterface>
     *
     * Used to connect actions to their respective QT Slots
     *
     * All Actions MUST have the same name as their corresponding SLOT
     *
     * see actions/encryption for examples
     *
     */
    connectGeneralSlots(store: Store, actions: Dispatchables): void {
        for (const key in actions) {
            if (actions.hasOwnProperty(key)) {
                const element = actions[key];
                if (key in this.generalWebInterface) {
                    if (typeof this.generalWebInterface[key] === 'object') {
                        //check if slot object
                        this.generalWebInterface[key].connect(function () {
                            //@ts-ignore

                            store.dispatch(element.apply(this, arguments));
                        });
                        console.log(`Successfully Connected General Slot: ${Object.keys(this.generalWebInterface).indexOf(key)}, to Action: ${key}`);
                    }
                } else {
                    console.log(`Found action: ${key}, Please ensure actions are named the same as their respective slots to ensure connection`);
                }
            }
        }
    }
    /**
     *
     * @param store - Redux store
     * @param actions - All redux actions that are used to connect to the QT interface <StudioWebInterface>
     *
     * Used to connect actions to their respective QT Slots
     *
     * All Actions MUST have the same name as their corresponding SLOT
     *
     * see actions/encryption for examples
     *
     */
    connectStudioSlots(store: Store, actions: Dispatchables): void {
        for (const key in actions) {
            if (actions.hasOwnProperty(key)) {
                const element = actions[key];
                if (key in this.studioWebInterface) {
                    if (typeof this.studioWebInterface[key] === 'object') {
                        //check if slot object
                        this.studioWebInterface[key].connect(function () {
                            //@ts-ignore

                            store.dispatch(element.apply(this, arguments));
                        });
                        console.log(`Successfully Connected General Slot: ${Object.keys(this.studioWebInterface).indexOf(key)}, to Action: ${key}`);
                    }
                } else {
                    console.log(`Found action: ${key}, Please ensure actions are named the same as their respective slots to ensure connection`);
                }
            }
        }
    }
    /**
     *
     * @param store - Redux store
     * @param actions - All redux actions that are used to connect to the QT interface <VersionWebInterface>
     *
     * Used to connect actions to their respective QT Slots
     *
     * All Actions MUST have the same name as their corresponding SLOT
     *
     * see actions/encryption for examples
     *
     */
    connectVersionSlots(store: Store, actions: Dispatchables): void {
        for (const key in actions) {
            if (actions.hasOwnProperty(key)) {
                const element = actions[key];
                if (key in this.versionWebInterface) {
                    if (typeof this.versionWebInterface[key] === 'object') {
                        //check if slot object
                        this.versionWebInterface[key].connect(function () {
                            //@ts-ignore

                            store.dispatch(element.apply(this, arguments));
                        });
                        console.log(`Successfully Connected Version Slot: ${Object.keys(this.versionWebInterface).indexOf(key)}, to Action: ${key}`);
                    }
                } else {
                    console.log(`Found action: ${key}, Please ensure actions are named the same as their respective slots to ensure connection`);
                }
            }
        }
    }
}

const qtConnect = async (callback: (qtApi: QtApi) => void): Promise<void> => {
    const qtApi = new QtApi();
    await qtApi.connect();
    callback(qtApi);
};

export default qtConnect;

// export default QtApi
