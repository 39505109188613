import React from 'react';
import PropTypes from 'prop-types';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import Avatar from '@material-ui/core/Avatar';
import { makeStyles, Fade } from '@material-ui/core';
// import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ImageIcon from '@material-ui/icons/Image';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import { EncryptFile } from '@mediaseal-webui/types/encryptor';

const useStyles = makeStyles((theme) => ({
    fail: {
        color: 'red'
    },
    success: {
        color: theme.palette.primary.main
    },
    fabProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '-2px',
        left: '-2px',
        zIndex: 1
    },
    avatar: {
        overflow: 'inherit',
        width: 40,
        height: 40
    },
    generic: {
        opacity: 0.5
    }
}));

// const Loader = (file, classes) => {
//     if (file.loading) {
//         return <CircularProgress size={45} className={classes.fabProgress} />
//     }
// }

interface LoaderAvatarProps {
    file: EncryptFile;
}
export const LoaderAvatar = ({ file }: LoaderAvatarProps) => {
    const classes = useStyles();
    const avatarState = (file: EncryptFile) => {
        if (file.error) {
            return <CloseIcon className={classes.fail} />;
        }
        if (file.done) {
            return <CheckIcon className={classes.success} />;
        }
        if (file.started) {
            if (file.fileType.match(/image|video/gi)) {
                return <ImageIcon className={classes.generic} />;
            }
            return <InsertDriveFile className={classes.generic} />;
        }
        if (file.fileType.match(/image|video/gi)) {
            return <ImageIcon className={classes.generic} />;
        }
        return <InsertDriveFile className={classes.generic} />;
    };
    return (
        <div>
            <Fade in timeout={1000}>
                <ListItemAvatar>{avatarState(file)}</ListItemAvatar>
            </Fade>
        </div>
    );
};
