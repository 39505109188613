import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Fab, CircularProgress } from '@material-ui/core';
import { useForm, useAuth } from '@mediaseal-webui/hooks';
import { HeaderTitle, MobileHeaderBodyProps, Breadcrumbs, HeaderItems } from '..';
import { MobileLeftContainer, MobileRightContainer, MobileBodyRoot, MobileMiddleContainer } from './Mobile.style';
import { EditableEntityTitle } from '@mediaseal-webui/components';
import { InitialState } from '@mediaseal-webui/types/redux';
import SearchBar from '../SearchBar';
import useStyles from '../Header.styles';

export const MobileHeaderBody = (props: MobileHeaderBodyProps) => {
    const {
        ActionButton,
        name,
        defaultName,
        displayingText,
        skeleton = false,
        breadcrumbProps,
        titleProps,
        searchProps,
        pageControl,
        editable = false,
        isReadOnly,
        loading,
        date,
        submitHandler,
        headerItems,
        titleValidation
    } = props;
    const classes = useStyles();

    const { invalid } = useForm();
    const { isFetching } = useSelector(({ misc }: InitialState) => misc);
    const { edit, administer } = useAuth();
    const mobileButtonRef = useRef<HTMLDivElement | null>(null);
    const actionButtonWidth = mobileButtonRef.current ? mobileButtonRef.current.style.width : 0;

    return (
        <MobileBodyRoot>
            <Breadcrumbs links={breadcrumbProps.links} text={breadcrumbProps.text} displayingText={displayingText} />

            <MobileMiddleContainer>
                <MobileLeftContainer>
                    {editable && (edit || administer) ? (
                        <EditableEntityTitle value={name} setterFn={titleProps?.setterFn} isReadOnly={isReadOnly} validation={titleValidation} />
                    ) : (
                        <HeaderTitle text={name || defaultName} skeleton={false} className={clsx(classes.title)} />
                    )}
                </MobileLeftContainer>

                <MobileRightContainer>
                    {date && headerItems && (
                        <HeaderItems actionButtonWidth={actionButtonWidth} isFetching={isFetching} pageControl={pageControl} data={headerItems} />
                    )}
                </MobileRightContainer>
            </MobileMiddleContainer>

            {ActionButton && (
                <div ref={mobileButtonRef} className={classes.mobileSubmit}>
                    {loading ? (
                        <Fab variant='round' type='submit' color='primary'>
                            <CircularProgress data-testid='loader' size={24} className={classes.buttonProgress} />
                        </Fab>
                    ) : (
                        typeof ActionButton !== 'boolean' && <ActionButton onClick={submitHandler} disabled={invalid || (skeleton && isFetching)} />
                    )}
                </div>
            )}
            {searchProps && <SearchBar {...searchProps} name={name} />}
        </MobileBodyRoot>
    );
};
