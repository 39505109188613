import { SET_ENCRYPTION_PAUSED } from '@mediaseal-webui/constants';
import { EncryptFile } from '@mediaseal-webui/types/encryptor';
import { ThunkResult } from '@mediaseal-webui/types/redux';
import { findFile } from '@mediaseal-webui/utils';
import { RootState } from 'packages/online/src/store';
/**
 *
 * @param id string
 * @returns void
 *
 * Triggered by QT when a user has requested to pause a file while encrypting
 *
 */
export const encryptionWorkPaused =
    (id: EncryptFile['id']): ThunkResult<void> =>
    (dispatch, getState: () => RootState) => {
        const { encryptionWork } = getState().encryptor;
        const file = findFile(encryptionWork, id);
        console.log(`Received Signal encryptionWorkPaused from QT with ID : ${id}`);
        dispatch({
            type: 'SET_SNACKBAR',
            payload: {
                info: true,
                message: `File: ${file?.inputFileName} has been paused`
            }
        }); //alert user
        dispatch({ type: SET_ENCRYPTION_PAUSED, payload: { id } }); //set the file to a paused state
    };
