import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdUnarchive } from 'react-icons/md';
import { MainTable } from '@mediaseal-webui/components';
import { genericHeader } from '../../../../data';
import { useHistory, useParams } from 'react-router';
import { fetchTitleJobs } from '../../../../actions/titles';
import { useArchiveJob, useJobArchiveStatusChange, useTableSort } from '@mediaseal-webui/hooks';
import ActionContainer from '../../../table-components/ActionContainer/ActionContainer';
import { useAuth } from '@mediaseal-webui/hooks';
import { InitialState } from '@mediaseal-webui/types/redux/state';
import { GenericEntity } from '@mediaseal-webui/types/schemas';
import ArchiveIcon from '@material-ui/icons/Archive';
import { useAppSelector } from 'store';

export const JobsTable = () => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const [archiveJob] = useArchiveJob();
    const dispatch = useDispatch();
    const archiveStatusChange = useJobArchiveStatusChange();

    const auth = useAuth();
    const isCreateAuth = auth.edit || auth.administer;

    const [jobsPages, setJobsPages] = useState(1);

    const { jobs } = useSelector((state: InitialState) => state.entity.profile);
    const { totalTitleJobPages } = useSelector((state: InitialState) => state.titles);
    const { items: permissions } = useAppSelector((state) => state.permissions);
    const hasArchiveAuth = permissions.some((permission) => permission.permissionName === 'canArchiveJobs');

    const { order, orderBy, createSortHandler, headCells } = useTableSort({
        cells: genericHeader,
        setPage: setJobsPages,
        fetchFn: fetchTitleJobs,
        searchValue: ''
    });

    const archiveFn = useCallback(
        (entity, setArchive, setActive) => async () => {
            await archiveJob(entity, setArchive, setActive);
            dispatch(fetchTitleJobs({ id, page: 0, firstCall: true, sort: `${orderBy},${order}` }));
        },
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, archiveJob]
    );
    const handleJobArchive = (entity: GenericEntity, setArchived: boolean) =>
        archiveStatusChange.handleArchival(setArchived, archiveFn(entity, setArchived, entity.type !== 'ONLINE'));

    const handleJobClick = ({ id }: { id: number }) => history.push(`/jobs/${id}/profile`);
    const jobClickHandler = useCallback(handleJobClick, [history]);
    const fetchNextPages = useCallback(async () => {
        await dispatch(fetchTitleJobs({ id, page: jobsPages, firstCall: false, sort: `${orderBy},${order}` }));
    }, [dispatch, jobsPages, id, order, orderBy]);

    return (
        <MainTable
            entities={jobs || []}
            searchValue=''
            pageControl={{ page: jobsPages, setPage: setJobsPages }}
            fetchFn={fetchNextPages}
            totalPages={totalTitleJobPages}
            tableActionsRenderer={(entity) => (
                <ActionContainer
                    create={hasArchiveAuth && isCreateAuth}
                    tooltipTitle={entity.archived ? 'Unarchive' : 'Archive'}
                    removeAction={entity.archived ? handleJobArchive(entity, false) : handleJobArchive(entity, true)}
                    RemoveIcon={entity.archived ? MdUnarchive : ArchiveIcon}
                />
            )}
            sortData={{
                sortBy: orderBy,
                sortDirection: order,
                createSortHandler
            }}
            columns={headCells}
            noResultsMessage='No jobs are associated with this title'
            // @ts-expect-error due to unassignable type ts(2322).
            onRowClick={jobClickHandler}
            id='jobs-table'
        />
    );
};
