import React, { memo } from 'react';
import { Header } from 'components/layout-components';
import { Content } from '@mediaseal-webui/components';
import { AccountIframe } from './Iframe';
import { useKeycloakConditionally } from '@mediaseal-webui/hooks';
export const AccountPage = memo(() => {
    return (
        <div>
            <Header
                list
                name='Account'
                ActionButton={false}
                displayingText=''
                breadcrumbProps={{
                    links: [{ id: 0, name: 'Account', to: '/account' }],
                    text: [{ id: 0, name: '' }]
                }}
            />
            {useKeycloakConditionally() && <Content>{() => <AccountIframe />}</Content>}
        </div>
    );
});
