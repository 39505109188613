import React, { useEffect, useState } from 'react';
import './App.css';
import '@fontsource/montserrat';
import Main from './components/layout-components/Main';
import { useSearchWorker, useReportWorker } from './hooks';
import { useDispatch, useSelector } from 'react-redux';
import { DialogueState, InitialState } from '@mediaseal-webui/types/redux';
import { useKeycloakConditionally, useMobile, useOffline } from '@mediaseal-webui/hooks';
import { NetworkAlert } from '@mediaseal-webui/components';
import { Layout } from './components/layout-components/Layout';
import ConfirmDialogue from './components/utility-components/ConfirmDialogue';
import Login from 'views/Login';
import { isKeycloakActive } from '@mediaseal-webui/hooks/src/isKeycloakActive';

const App = () => {
    const [open, setOpen] = useState(false);
    const [dialogueState, setDialogueState] = useState<Pick<DialogueState, 'message'> | null>();
    const { confirmDialogue } = useSelector(({ misc }: InitialState) => misc);
    const { userCredentials } = useSelector((state: InitialState) => state.misc);
    const { loggedIn } = userCredentials;
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const { offline, online } = useSelector(({ network }: InitialState) => network.networkState);
    const { searchWorker } = useSearchWorker();
    const { reportWorker } = useReportWorker();
    useOffline();
    const { keycloak } = useKeycloakConditionally() ?? {};
    const dispatch = useDispatch();
    const isMobile = useMobile();
    const networkMessage = offline ? 'No internet connection detected.' : 'Connected.';
    const processActions = (event: MessageEvent) => {
        if (event.data.type !== 'RPC') {
            dispatch({ type: event.data.type, payload: event.data.payload });
            dispatch({ type: 'STOP_FETCHING' });
        }
    };
    useEffect(() => {
        isMobile && window.screen.orientation.lock('portrait');
        window.addEventListener(
            'dragover',
            (e) => {
                e.preventDefault();
            },
            false
        );
        window.addEventListener(
            'drop',
            (e) => {
                e.preventDefault();
            },
            false
        );
        searchWorker.onmessage = processActions;
        reportWorker.onmessage = processActions;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (confirmDialogue.message) {
            setDialogueState(confirmDialogue);
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [confirmDialogue]);

    useEffect(() => {
        if (loggedIn) {
            setUserLoggedIn(true);
        }
    }, [userCredentials, loggedIn]);

    if (keycloak && !keycloak?.authenticated) {
        window.location.href = keycloak.createLoginUrl();
        return <div />;
    } else if (!isKeycloakActive() && !userLoggedIn) {
        return <Login />;
    } else {
        return (
            <Layout>
                <NetworkAlert offline={offline} online={online} message={networkMessage} />
                <Main />
                {open && dialogueState && <ConfirmDialogue open={open} setOpen={setOpen} {...dialogueState} />}
            </Layout>
        );
    }
};
export default App;
