import { createMuiTheme, PaletteType, useMediaQuery } from '@material-ui/core';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InitialState } from '@mediaseal-webui/types';
import { SET_THEME } from '@mediaseal-webui/constants';
import { createTheme } from '@mediaseal-webui/online/src/theme';

type ThemeValue = {
    theme: 'light' | 'dark';
};

export const useThemeSelection = () => {
    const dispatch = useDispatch();
    const { theme: themeType } = useSelector((state: InitialState) => state.settings.misc);
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const checkOldValue = useCallback(() => {
        const theme = localStorage.getItem('ms-theme');
        if (typeof theme === 'string') {
            // use a regexp to check for a ':' this will indicate old value
            const regex = /:/g;

            //If this regex passes its using the old value and we want to cast it to the new value
            if (regex.test(theme)) {
                const parsed: ThemeValue = JSON.parse(theme);
                localStorage.setItem('ms-theme', parsed.theme);
            }
        }
    }, []);

    useEffect(() => {
        checkOldValue();
        const theme = localStorage.getItem('ms-theme') || 'light';
        dispatch({
            type: SET_THEME,
            payload: theme ? theme : prefersDarkMode ? 'dark' : 'light'
        });
    }, []);

    const theme = useMemo(() => createTheme(themeType), [themeType]);
    return {
        theme
    };
};
