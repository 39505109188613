import { combineReducers } from 'redux';
import { CHANGE_FIELD, ADD_FIELD, REMOVE_FIELD, ADD_FORM_ERROR, REMOVE_FORM_ERROR, CLEAR_FORM } from '@mediaseal-webui/constants';
const errors = (errors = [], { type, payload }) => {
    switch (type) {
        case ADD_FORM_ERROR:
            return [...errors.filter((err) => err.name !== payload.name), payload];
        case REMOVE_FORM_ERROR:
            return errors.filter((err) => err.name !== payload);
        case CLEAR_FORM:
            return [];
        default:
            return errors;
    }
};
const fields = (fields = [], { type, payload }) => {
    switch (type) {
        case ADD_FIELD:
            return [...fields, payload];
        case CHANGE_FIELD:
            return fields.map((field) => {
                return field.name === payload.name ? Object.assign(Object.assign({}, field), { value: payload.value }) : field;
            });
        case REMOVE_FIELD:
            return fields.filter((field) => field.name !== payload.name);
        case CLEAR_FORM:
            return [];
        default:
            return fields;
    }
};
export default combineReducers({
    errors,
    fields
});
