/**
 * JobCreationInitialStatus
 * @interface
 * this is the state structure of the job creation form's validity
 * All values need to be set to true for the form the be submitted
 */

import { EntityCreationInitialState } from '@mediaseal-webui/types';

interface CreationState {
    valid: boolean;
    value: string;
}

export const entityCreationInitialState: EntityCreationInitialState = {
    name: {
        valid: false,
        value: ''
    },
    title: {
        valid: false,
        value: ''
    },
    password: {
        valid: false,
        value: ''
    },
    confirmPassword: {
        valid: false,
        value: ''
    }
};
