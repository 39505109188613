import ReauthTime from './ReauthTime';
import { IOptionalOpenProps } from '@mediaseal-webui/types/generic';
import { JobFeature } from '@mediaseal-webui/types/jobs';

interface SetterArgs {
    [args: string]: any;
}
export interface ReauthTimeProps extends IOptionalOpenProps {
    features: JobFeature[];
    active: boolean;
    disabled?: boolean;
    rollOver: boolean;
    setterFn: (args: SetterArgs) => void;
    isOffline?: boolean;
}

export default ReauthTime;
