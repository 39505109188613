import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from '@material-ui/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import { EncryptFile } from '@mediaseal-webui/types/encryptor';
// import { Test } from './LinkToJob.styles';
interface LinkToJobProps {
    jobId: EncryptFile['id'];
}
export const LinkToJob = ({ jobId }: LinkToJobProps) => (
    <Tooltip title='Go To Job' placement='top'>
        <Link to={`/jobs/${jobId}/profile`}>
            <IconButton data-testid='go-to-job'>
                <ChevronRight />
            </IconButton>
        </Link>
    </Tooltip>
);
