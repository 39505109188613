export const SELECT_ENC_USER = 'encryptorUsers/setSelected';
export const SELECT_DEC_USER = 'recipients/setSelected';
export const SELECT_ENC_GROUP = 'encryptorGroups/setSelected';
export const SELECT_DEC_GROUP = 'decryptorGroups/setSelected';
export const SELECT_TOKEN = 'tokens/setSelected';

export const DESELECT_ENC_USER = 'encryptorUsers/deselect';
export const DESELECT_DEC_USER = 'recipients/deselect';
export const DESELECT_ENC_GROUP = 'encryptorGroups/deselect';
export const DESELECT_DEC_GROUP = 'decryptorGroups/deselect';
export const DESELECT_TOKEN = 'tokens/deselect';

export const CANCEL_SELECTION = 'CANCEL_SELECTION';

export const SUBMIT_SELECTION = 'SUBMIT_SELECTION';
export const SET_FEATURE = 'SET_FEATURE';
export const REMOVE_FEATURE = 'REMOVE_FEATURE';
