import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { InfiniteScrollTable, TextCell } from '@mediaseal-webui/components';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import StatusCell from '../../table-components/StatusCell/StatusCell';
import clsx from 'clsx';
import { formatDateFns, processAuthMode } from '../../../helpers';
import { Column } from 'react-virtualized';
import { useSelector } from 'react-redux';
import { Hidden } from '@material-ui/core';
import { useBreakPointListener } from '@mediaseal-webui/hooks';
import useStyles from './CustomActionsTable.styles';

/**
 * Variation on the MainTable which accepts custom table actions controlled by the parent
 *
 * @param {entities} array -- data to be presented
 * @param {searchValue} string -- This is the search value used for the current view -- passed to the fetchFn
 * @param {totalPages} number -- used for infiniteScroll control and to ensure no overfetch errors
 * @param {pageControl} object -- this object should contain the page,setPage state from the view to control current page of requests
 * @param {fetchFn} func -- the raw action to be dispatched to fetch entities
 * @param {sortData} obj -- Sort data passed from parent, used in the table for sorting in the header
 * @param {columns} array -- Header data for sortable table header. passed from parent due to different header labels for user views
 * @param {filterFetch} bool -- flag to filter the scrolls incoming results against a summary
 * @param {onRowClick} func -- function called with the entity clicked
 * @param {actions} func -- function which returns JSX Actions to render. The entity of the row is passed to the component as args
 * @param {noResultsMessage} string -- message to display if no results
 */
const CustomActionsTable = (props) => {
    const {
        entities,
        searchValue,
        totalPages,
        pageControl,
        onRowClick,
        actions,
        fetchFn,
        sortData,
        columns,
        filterFetch,
        noResultsMessage,
        status,
        ...others
    } = props;
    const classes = useStyles();

    const { sortBy, sortDirection, createSortHandler } = sortData;

    const { isFetching } = useSelector(({ misc }) => misc);

    const rowGetter = ({ index }) => entities[index]; // returns current index from Table

    const rowClickHandler = (entity) => onRowClick(entity); // returns the rowData obj from Table
    const handleRowClick = useCallback(rowClickHandler, [onRowClick]);
    const { hide } = useBreakPointListener();

    /**
     * Render column header with TableSortLabel for sortable columns, using the current sortBy/sortDirection for rendering indicators
     * @private
     */
    const headerRenderer = ({ columnData, label, columnIndex, dataKey }) => {
        const column = columns[columnIndex];
        const { disableSort, order } = column;
        return (
            <Hidden only={column.hidden}>
                <TableCell
                    component='div'
                    id={dataKey}
                    variant='head'
                    className={clsx(classes.tableCell, classes.flexContainer, {
                        [classes.alignFlexEnd]: columns[columnIndex].label === 'Actions'
                        // [classes.alignFlexEnd]: columns[columnIndex].label === 'Status',
                    })}>
                    {disableSort ? (
                        label
                    ) : (
                        <TableSortLabel active={dataKey === sortBy} direction={order} onClick={createSortHandler(column)}>
                            {label}
                        </TableSortLabel>
                    )}
                </TableCell>
            </Hidden>
        );
    };

    /**
     * Renders the table cell, determining if the cell is an Action, Status or regular content
     * @private
     */
    const cellRenderer = ({ cellData, columnIndex = null, rowData }) => {
        const column = columns[columnIndex];
        const { label } = column;
        if (label === 'Actions') {
            return (
                rowData.id && (
                    <TableCell component='div' align='right' className={classes.flexContainer} classes={{ root: classes.tableCell }}>
                        {actions(rowData)}
                    </TableCell>
                )
            );
        }
        if (label === 'Status') {
            return (
                <StatusCell
                    item={rowData.active || cellData}
                    isFetching={isFetching}
                    variant='body'
                    skeletonHeight={24}
                    className={clsx([classes.flexContainer])}
                />
            );
        }
        const output = label === 'Created' || label === 'Last Modified' ? (rowData.createDate ? formatDateFns(cellData) : '') : cellData;
        return (
            <TextCell
                id={cellData}
                output={output}
                isFetching={isFetching}
                subtitle={processAuthMode(cellData) || ' '}
                skeletonHeight={15}
                variant='body'
                className={clsx([classes.flexContainer])}
            />
        );
    };

    return (
        <InfiniteScrollTable
            rowCount={entities.length}
            rowGetter={rowGetter}
            rowHeight={45}
            data={entities}
            pageControl={pageControl}
            onRowClick={handleRowClick}
            fetchFn={fetchFn}
            totalPages={totalPages}
            rowClassName={({ index }) =>
                clsx(classes.tableRow, {
                    [classes.tableRowHover]: index !== -1
                })
            }
            columns={columns}
            noResultsMessage={noResultsMessage}
            {...others}>
            {(width, height) =>
                columns.map((props, index) => {
                    const width = () => (hide.some((bp) => columns[index].hidden?.includes(bp)) ? 0 : 90);
                    const flexGrow = () => (hide.some((bp) => columns[index].hidden?.includes(bp)) ? 0 : props.flexGrow);
                    return (
                        <Column
                            key={props.dataKey}
                            headerRenderer={(headerProps) =>
                                headerRenderer({
                                    ...headerProps,
                                    columnIndex: index
                                })
                            }
                            className={clsx(classes.flexContainer, props.className)}
                            cellRenderer={cellRenderer}
                            {...props}
                            width={width()}
                            flexGrow={flexGrow()}
                        />
                    );
                })
            }
        </InfiniteScrollTable>
    );
};

CustomActionsTable.propTypes = {
    entities: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            createDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            updateDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        })
    ),
    fetchFn: PropTypes.func,
    pageControl: PropTypes.shape({
        page: PropTypes.number,
        setPage: PropTypes.func
    }),
    sortData: PropTypes.shape({
        sortBy: PropTypes.string,
        sortDirection: PropTypes.string,
        createSortHandler: PropTypes.func
    }),
    searchValue: PropTypes.string,
    totalPages: PropTypes.number,
    columns: PropTypes.arrayOf(PropTypes.shape({})),
    filterFetch: PropTypes.bool,
    actions: PropTypes.func.isRequired,
    noResultsMessage: PropTypes.string
};

CustomActionsTable.defaultProps = {
    entities: [
        {
            id: 0,
            name: '',
            createDate: '',
            updateDate: ''
        }
    ],
    sortData: {
        sortBy: 'createDate',
        sortDirection: 'desc',
        createSortHandler: () => null
    },
    fetchFn: () => {},
    pageControl: {
        page: 0,
        setPage: () => {}
    },
    searchValue: '',
    totalPages: 0,
    filterFetch: false
};

export default React.memo(CustomActionsTable);
