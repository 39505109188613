import React from 'react';
import { Fade, ClickAwayListener, Paper, Popper } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setShowAboutModal } from '../../../../actions/misc';
import { useTheme } from '@material-ui/core/styles';
import { Item } from '../Link/Link';
import useStyles from '../Link/Link.styles';
import { useSideNav } from '@mediaseal-webui/hooks';
import { INavLink } from '../../../../routes';

const about: INavLink = { id: 0, text: 'About', icon: 'fas fa-info', navId: 'aboutApp', link: '' };
const support: INavLink = { id: 0, text: 'Support', icon: 'fas fa-headset', navId: 'support', link: '' };
const SUPPORT_URL = 'https://mediaseal.fortiumtech.com/support/home';

interface HelpPopperProps {
    anchorEl: Element;
    open: boolean;
    setter: (b: boolean) => void;
}

const HelpPopper = ({ anchorEl, open = false, setter = () => null }: HelpPopperProps) => {
    const { sideNavOpen } = useSideNav();
    const dispatch = useDispatch();
    const handleAboutClick = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        dispatch(setShowAboutModal(true));
    };
    const theme = useTheme();
    const classes = useStyles();
    const handleSupportClick = () => {
        window.qt ? window.GeneralWebInterface.openUrl(SUPPORT_URL) : window.open(SUPPORT_URL, '_newtab');
    };
    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => setter(false);

    return (
        <Popper open={open} anchorEl={anchorEl} placement='right' style={{ width: 140 }} transition>
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <ClickAwayListener onClickAway={handleClose}>
                        <Paper
                            style={{
                                backgroundColor: theme.palette.grey['100'],
                                marginLeft: !sideNavOpen ? 26 : 0,
                                width: '100%',
                                overflow: 'hidden'
                            }}>
                            <Item link={about} onClick={handleAboutClick} popper id='help-link' classes={classes} disableLink />
                            <Item link={support} onClick={handleSupportClick} popper id='support-link' classes={classes} disableLink />
                            {/* <Link link={about} onClick={handleAboutClick} sideNavOpen id='help-link' disableLink style={{ color: 'black' }} />
              <Link link={support} onClick={handleSupportClick} sideNavOpen id='support-link' disableLink style={{ color: 'black' }} /> */}
                        </Paper>
                    </ClickAwayListener>
                </Fade>
            )}
        </Popper>
    );
};

export default HelpPopper;
