import React, { useCallback, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@material-ui/core';
import { useIdentityProvider } from 'hooks/useIdentityProviders';
import { Form } from '@mediaseal-webui/components/src/Form';
import { CopyButton, PasswordInput, TextInput } from '@mediaseal-webui/components';
import FormGroup from '@mediaseal-webui/components/src/FormGroup';
import { ProviderContext } from 'hooks/useIdentityProviders/context';
import { ConfigureButton } from './ConfigureButton';
import { keycloak } from '@mediaseal-webui/services';
import { DefaultGroup } from '../DefaultGroup';

export const IdentityProviderModal = () => {
    const {
        setData,
        submitFn,
        configureProvider: { showModal, type, redirectUrl }
    } = useIdentityProvider();

    const REDIRECT_URL = `https://${window.location.host}/auth/realms/${keycloak.realm}/broker/${type}/endpoint`; //MS-3273
    const LOGOUT_URL = `https://${window.location.host}/auth/realms/${keycloak.realm}/broker/${type}/endpoint/logout_response`; //only for okta

    useEffect(() => {
        if (showModal) {
            setData((ps) => ({ ...ps, configureProvider: { ...ps.configureProvider, redirectUrl: REDIRECT_URL } }));
        }
    }, [REDIRECT_URL, setData, showModal]);

    const handleClose = useCallback(() => {
        setData((ps) => ({ ...ps, configureProvider: { ...ps.configureProvider, showModal: false } }));
    }, [setData]);

    const handleChange = useCallback(
        (type: keyof ProviderContext) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setData((ps) => ({ ...ps, configureProvider: { ...ps.configureProvider, [type]: event.target.value } }));
        },
        [setData]
    );

    const handleSubmit = useCallback(
        async (event: React.FormEvent) => {
            const data = await submitFn();
            setData((ps) => ({
                ...ps,
                configureProvider: { ...ps.configureProvider, showModal: false },
                configuredProvider: { ...ps.configuredProvider, ...data }
            }));
        },
        [submitFn, setData]
    );

    return (
        <Dialog data-testid='configure-dialog' role='dialog' open={showModal}>
            <DialogTitle>Configure {type} identity provider</DialogTitle>
            <DialogContent>
                <Form role='form' onSubmit={handleSubmit}>
                    <FormGroup heading='Redirect URL'>
                        <Tooltip title={REDIRECT_URL} placement='top'>
                            <div style={{ display: 'flex' }}>
                                <TextInput disabled data-testid='redirect-url' role='input' name='redirect-url' value={REDIRECT_URL} label='redirect url' />
                                <CopyButton value={redirectUrl} />
                            </div>
                        </Tooltip>
                    </FormGroup>
                    {type === 'okta' && (
                        <FormGroup heading='Logout URL'>
                            <Tooltip title={LOGOUT_URL} placement='top'>
                                <div style={{ display: 'flex' }}>
                                    <TextInput disabled data-testid='logout-url' role='input' name='logout-url' value={LOGOUT_URL} label='logout url' />
                                    <CopyButton value={LOGOUT_URL} />
                                </div>
                            </Tooltip>
                        </FormGroup>
                    )}
                    <FormGroup heading='Client Id'>
                        <TextInput
                            role='input'
                            disableValidation
                            data-testid='client-id'
                            name='client-id'
                            label='client id'
                            onChange={handleChange('clientId')}
                        />
                    </FormGroup>
                    {type === 'microsoft' && (
                        <FormGroup heading='Tenant Id'>
                            <TextInput
                                role='input'
                                disableValidation
                                data-testid='tenant-id'
                                name='tenant-id'
                                label='tenant id'
                                onChange={handleChange('tenantId')}
                            />
                        </FormGroup>
                    )}
                    <FormGroup heading='Client Secret'>
                        <PasswordInput
                            role='input'
                            data-testid='client-secret'
                            disableValidation
                            name='client-secret'
                            label='client secret'
                            onChange={handleChange('clientSecret')}
                        />
                    </FormGroup>
                    {type === 'okta' && (
                        <FormGroup heading='Domain'>
                            <TextInput role='input' data-testid='domain' disableValidation name='domain' label='domain' onChange={handleChange('domain')} />
                        </FormGroup>
                    )}
                    <FormGroup heading='Set default group'>
                        <DefaultGroup />
                    </FormGroup>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button variant='text' onClick={handleClose}>
                    CANCEL
                </Button>
                <ConfigureButton type='submit' handleSubmit={submitFn} variant='contained' color='primary' />
            </DialogActions>
        </Dialog>
    );
};
