import { formatDistanceToNow, format, getYear } from 'date-fns';
import { JobTypes } from '@mediaseal-webui/types/jobs';
import { IShortener } from '@mediaseal-webui/types/generic';
import { GenericEntity } from '@mediaseal-webui/types/schemas';
import Axios from 'axios';
import { Permission } from 'packages/types';
export * from './validators';
export * from './encryption';

export const formatDateFns = (createDate: string | number | Date) => {
    let newDate;
    if (typeof createDate === 'number') {
        newDate = new Date(createDate);
    } else if (createDate === 'string') {
        newDate = new Date(createDate.replace(/-/g, '/').split('T')[0]);
    } else {
        newDate = new Date(createDate);
    }
    return getYear(newDate) < new Date().getFullYear() ? format(newDate, 'P') : formatDistanceToNow(newDate, { addSuffix: true });
};
export const processAuthMode = (mode: JobTypes) => {
    switch (mode) {
        case 'ONLINE':
            return 'Password + User + Server';
        case 'OFFLINE':
            return 'Password + User';
        case 'PASSWORD_ONLY':
            return 'Password';
    }
};

export const processFileSize = (size: number, si: boolean) => {
    let bytes = size;
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
    }
    const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    do {
        bytes /= thresh;
        u += 1;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return `${bytes.toFixed(1)} ${units[u]}`;
};

export const shorteners: IShortener = {
    shortFilename: (output: string) => output.replace(/^.*[\\\/]/, '')
};

export const checkSelectedPermissions = (allPerms: Permission[], grpPerms: Permission[]) => {
    const returnedPerms = allPerms.map((perm: any) => {
        for (const grp of grpPerms) {
            if (grp.id === perm.id) {
                perm.checked = true;
            }
        }
        return perm;
    });
    return returnedPerms.filter((perm) => perm.checked);
};

const deleteDepartment = (permission: any) => permission.label === 'Departments';

const addLabelAndCheckItem = (permission: any) => {
    const { permissionName } = permission;
    let label = '';
    if (permissionName.includes('Jobs')) label = 'Jobs';
    if (permissionName.includes('Encryptor') || permissionName.includes('Decryptor')) label = 'User';
    if (permissionName.includes('Titles')) label = 'Titles';
    if (permissionName.includes('Groups')) label = 'Groups';
    if (permissionName.includes('Global')) label = 'Global';
    if (permissionName.includes('Personal')) label = 'Personal';
    if (permissionName.includes('Local')) label = 'Local';
    if (permissionName.includes('Reports') || permissionName.includes('Reporting')) label = 'Reports';

    return { ...permission, label };
};

export interface SerializedPermissions {
    id: number;
    label: string;
    options: Permission[];
}
export interface ISerializedPermissions {
    jobsTitles: SerializedPermissions[];
    encryptor: SerializedPermissions[];
    decryptor: SerializedPermissions[];
    settings: SerializedPermissions[];
    reporting: SerializedPermissions[];
}
export const serializePermissions = (permissions: Permission[]): ISerializedPermissions => {
    // define our object to return
    const obj = {
        jobsTitles: [
            { id: 0, label: 'View', options: [] },
            { id: 1, label: 'Create', options: [] },
            { id: 2, label: 'Edit', options: [] },
            { id: 3, label: 'Archive', options: [] },
            { id: 4, label: 'Copy', options: [] },
            { id: 5, label: 'Modify', options: [] }
        ],
        encryptor: [
            { id: 0, label: 'View', options: [] },
            { id: 1, label: 'Create', options: [] },
            { id: 2, label: 'Edit', options: [] },
            { id: 3, label: 'Activate', options: [] }
        ],
        decryptor: [
            { id: 0, label: 'View', options: [] },
            { id: 1, label: 'Create', options: [] },
            { id: 2, label: 'Edit', options: [] }
        ],
        settings: [{ id: 0, label: 'Edit', options: [] }],
        reporting: [
            { id: 0, label: 'View', options: [] },
            { id: 1, label: 'Export', options: [] }
        ]
    };
    // loop thru array and map out new arrays in to object
    permissions
        .filter(({ permissionName }: { permissionName: string }) => !permissionName.includes('Departments'))
        .forEach((permission: { permissionName: string }) => {
            const { permissionName } = permission;
            addLabelAndCheckItem(permission);
            switch (true) {
                case permissionName.includes('Jobs'):
                case permissionName.includes('Titles'):
                    //@ts-ignore
                    if (permissionName.includes('View')) obj.jobsTitles[0].options.push(permission);
                    //@ts-ignore
                    if (permissionName.includes('Create')) obj.jobsTitles[1].options.push(permission);
                    //@ts-ignore
                    if (permissionName.includes('Edit')) obj.jobsTitles[2].options.push(permission);
                    //@ts-ignore
                    if (permissionName.includes('Archive')) obj.jobsTitles[3].options.push(permission);
                    //@ts-ignore
                    if (permissionName.includes('Copy')) obj.jobsTitles[4].options.push(permission);
                    //@ts-ignore
                    if (permissionName.includes('Transcode')) obj.jobsTitles[5].options.push(permission);
                    break;
                case permissionName.includes('Encryptor'):
                    //@ts-ignore
                    if (permissionName.includes('View')) obj.encryptor[0].options.push(permission);
                    //@ts-ignore
                    if (permissionName.includes('Create')) obj.encryptor[1].options.push(permission);
                    //@ts-ignore
                    if (permissionName.includes('Edit')) obj.encryptor[2].options.push(permission);
                    //@ts-ignore
                    if (permissionName.includes('Activate')) obj.encryptor[3].options.push(permission);
                    break;
                case permissionName.includes('Decryptor'):
                    //@ts-ignore
                    if (permissionName.includes('View')) obj.decryptor[0].options.push(permission);
                    //@ts-ignore
                    if (permissionName.includes('Create')) obj.decryptor[1].options.push(permission);
                    //@ts-ignore
                    if (permissionName.includes('Edit')) obj.decryptor[2].options.push(permission);
                    break;
                case permissionName.includes('Settings'):
                case permissionName.includes('Identity'):
                case permissionName.includes('Personal'):
                case permissionName.includes('ApiTokens'):
                    //@ts-ignore
                    if (permissionName.includes('Administer')) obj.settings[0].options.push(permission);
                    break;
                case permissionName.includes('Reporting'):
                case permissionName.includes('Reports'):
                    //@ts-ignore
                    if (permissionName.includes('View')) obj.reporting[0].options.push(permission);
                    //@ts-ignore
                    if (permissionName.includes('Export')) obj.reporting[1].options.push(permission);
                    break;
                default:
                    break;
            }
        });
    return obj;
};
interface BadArray extends GenericEntity {
    relTargetType: null;
}
export const filterContentArray = (array: BadArray[]): GenericEntity[] => {
    const goodArray = array.filter((item) => !item.relTargetType);
    return goodArray;
};

export const getUserTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const seedData = () => {
    return Array.from({ length: 100 }, (_, key) => {
        return {
            fakeid: key,
            name: '',
            type: '',
            firstName: '',
            lastName: '',
            date: '',
            email: '',
            active: undefined,
            authMode: null
        };
    });
};

export const genBasicAuth = (values: { username: string; password: string }) => {
    const authString = `Basic ` + Buffer.from(values.username + ':' + values.password).toString('base64');
    return authString;
};

export const genBearerAuth = (token: any) => {
    const authToken = `bearer ${token}`;
    return authToken;
};
