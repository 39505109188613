import { combineReducers } from 'redux';
import { fileStart, fileSuccess, filePause, fileProgress, fileCancel, fileFail } from './utils';
import { GenericEncryptionErrorAction, StaticFileListActions, CompletedFileListAction, FileIssuesActions, BatchFileDataAction } from './types';
import { EncryptFile, EncryptFileWithIssues, EncryptionWork } from '@mediaseal-webui/types/encryptor';
import { GenericEncryptorError } from '@mediaseal-webui/types/generic';
import {
    SET_BATCH_DATA,
    CREATE_ENCRYPTION_WORK,
    CLEAR_ENCRYPTION_WORK,
    SET_FILE_PROCESSED,
    SET_ENCRYPTION_STARTED,
    SET_ENCRYPTION_SUCCEEDED,
    SET_ENCRYPTION_PAUSED,
    SET_ENCRYPTION_PROGRESS,
    SET_ENCRYPTION_CANCELLED,
    SET_ENCRYPTION_FAIL,
    SET_POST_BATCH_FILE,
    CONFIRM_ENC_FILE_OVERWRITE,
    ADD_FILE_TO_EXISTING_WORK,
    REMOVE_ENC_FILE_ISSUE,
    STORE_ENCRYPTED_ACL
} from '@mediaseal-webui/constants';

export interface EncryptionWorkAction {
    type: string;
    payload: EncryptionWork;
}
const encryptionWork = (state: EncryptionWork[] = [], action: EncryptionWorkAction) => {
    switch (action.type) {
        case CREATE_ENCRYPTION_WORK:
            return [...state, action.payload];
        case CLEAR_ENCRYPTION_WORK:
            return state.map((work) => ({
                ...work,
                fileList: work.fileList.filter((file) => file.percentage !== 100)
            }));
        case SET_POST_BATCH_FILE:
            return state.map((work) => (work.id === action.payload.id ? { ...work, posted: true } : work));
        case SET_FILE_PROCESSED:
            return state.map((work) => ({
                ...work,
                fileList: work.fileList.map((file) => {
                    if (file.id === action.payload.id) {
                        file.processed = true;
                        return file;
                    }
                    return file;
                })
            }));
        case STORE_ENCRYPTED_ACL:
            return state.map((work) => (work.jobData.id === action.payload.workId ? { ...work, batchEncryptedAcl: action.payload.batchEncryptedAcl } : work));
        case ADD_FILE_TO_EXISTING_WORK:
            return state.map((work) => (work.jobData.id === action.payload.id ? { ...work, fileList: [...work.fileList, ...action.payload.fileList] } : work));
        case CONFIRM_ENC_FILE_OVERWRITE:
            return state.map((work) => (work.id === action.payload.workId ? { ...work, fileList: [...work.fileList, action.payload] } : work));
        case SET_ENCRYPTION_STARTED:
            return state.map((work) => ({ ...work, fileList: work.fileList.map((file) => (file.id === action.payload.id ? fileStart(file) : file)) }));
        case SET_ENCRYPTION_SUCCEEDED:
            return state.map((work) => ({ ...work, fileList: work.fileList.map((file) => (file.id === action.payload.id ? fileSuccess(file) : file)) }));
        case SET_ENCRYPTION_PAUSED:
            return state.map((work) => ({ ...work, fileList: work.fileList.map((file) => (file.id === action.payload.id ? filePause(file) : file)) }));
        case SET_ENCRYPTION_PROGRESS:
            return state.map((work) => ({
                ...work,
                fileList: work.fileList.map((file) => (file.id === action.payload.id ? fileProgress(file, action.payload.percentage) : file))
            }));
        case SET_ENCRYPTION_CANCELLED:
            return state.map((work) => ({ ...work, fileList: work.fileList.map((file) => (file.id === action.payload.id ? fileCancel(file) : file)) }));
        case SET_ENCRYPTION_FAIL:
            return state.map((work) => ({
                ...work,
                fileList: work.fileList.map((file) => (file.id === action.payload.id ? fileFail(file, action.payload.message) : file))
            }));
        default:
            return state;
    }
};

const genericEncryptionError = (
    state: GenericEncryptorError = {
        error: false,
        message: ''
    },
    action: GenericEncryptionErrorAction
) => {
    switch (action.type) {
        case 'SET_ENCRYPTION_ERROR':
            return {
                ...state,
                error: action.payload.error,
                message: action.payload.message
            };
        default:
            return state;
    }
};

const staticFileList = (state: EncryptFile[] = [], action: StaticFileListActions) => {
    switch (action.type) {
        case 'ADD_TO_FILE_LIST':
            // case 'CONFIRM_ENC_FILE_OVERWRITE':
            return [...state, action.payload];
        case 'SET_ENCRYPTION_PROGRESS':
            return [
                ...state.map((file) => {
                    if (file.id === action.payload.id) file.percentage = action.payload.amount;
                    return file;
                })
            ];
        case 'CLEAR_STATIC_FILES':
            return [];
        default:
            return state;
    }
};
const completedFileList = (state: EncryptFile[] = [], action: CompletedFileListAction) => {
    switch (action.type) {
        case 'SET_COMPLETED_FILE':
            return [...state, action.payload];
        case 'CLEAR_COMPLETED_FILES':
            return [];
        default:
            return state;
    }
};

const issues = (state: EncryptFileWithIssues[] = [], action: FileIssuesActions) => {
    switch (action.type) {
        case 'ADD_ENC_FILE_ISSUES':
            return [...state, ...action.payload];
        case REMOVE_ENC_FILE_ISSUE:
        case 'FILE_COLLISION_ISSUE_RESOLVED':
        case CONFIRM_ENC_FILE_OVERWRITE:
            return state.filter((file) => file.id !== action.payload.id);
        default:
            return state;
    }
};

const processingFiles = (state = false, { type, payload }: any) => {
    switch (type) {
        case 'SET_PROCESSING_FILES':
            return payload;
        default:
            return state;
    }
};

const openPassword = (state = false, { type, payload }: any) => {
    switch (type) {
        case 'SET_PASSWORD_MODAL':
            return payload;
        case 'CLOSE_PASSWORD_MODAL':
            return payload;
        default:
            return state;
    }
};

const batchFileData = (state = {}, action: BatchFileDataAction) => {
    switch (action.type) {
        case SET_BATCH_DATA:
            return action.payload;
        default:
            return state;
    }
};

export default combineReducers({
    genericEncryptionError,
    completedFileList,
    staticFileList,
    issues,
    openPassword,
    batchFileData,
    encryptionWork
});
