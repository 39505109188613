import React from 'react';
import clsx from 'clsx';
import { TableCell, Hidden, TableSortLabel } from '@material-ui/core';
import { UseHeaderRendererProps, ITableHeaderProps, ITableHeaderRenderer } from '.';
import useStyles from './SelectableTable.styles';

export const useHeaderRenderer = ({ sortBy, columns, createSortHandler }: UseHeaderRendererProps): ITableHeaderRenderer => {
    const classes = useStyles();

    return ({ label, columnIndex, dataKey }: ITableHeaderProps) => {
        const column = columns[columnIndex];
        const { disableSort, order } = column;
        const inner = disableSort ? (
            label
        ) : (
            <TableSortLabel active={dataKey === sortBy} direction={order} onClick={createSortHandler(column)}>
                {label}
            </TableSortLabel>
        );
        return (
            <Hidden only={columns[columnIndex].hidden}>
                <TableCell
                    component='th'
                    variant='head'
                    id={dataKey}
                    className={clsx({
                        [classes.alignFlexEnd]: column.label === 'Status',
                        [classes.tableCell]: true,
                        [classes.flexContainer]: true
                    })}>
                    {label !== 'checkbox' ? inner : ''}
                </TableCell>
            </Hidden>
        );
    };
};
