import React, { memo } from 'react';
import Typography from '@material-ui/core/Typography';
import useStyles from './TabPanel.styles';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

interface TabPanelTypes {
    value: number;
    index: number;
    component: React.FC;
    panelClasses?: string;
    boxDisplay?: string;
    hidden?: boolean;
}

export const TabPanel: React.FC<TabPanelTypes> = memo((props) => {
    const { children, value, index, component, boxDisplay, hidden, ...other } = props;
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xl'));
    return (
        <Typography
            role='tabpanel'
            color='textPrimary'
            hidden={value !== index || hidden}
            className={classes.root}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}>
            {value === index && (
                <div
                    style={{
                        display: boxDisplay,
                        height: '100%',
                        width: '100%',
                        flexWrap: matches ? 'wrap' : 'inherit'
                    }}>
                    {component ? React.createElement(component) : children}
                </div>
            )}
        </Typography>
    );
});

export function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
}
