import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InitialState } from '@mediaseal-webui/types/redux';
import { EditableHeaderItem, EditableHeaderChildProps, BadgeIcon, HeaderItem, GenericHeaderItemProps } from '../..';
import { Person as PersonIcon } from '@material-ui/icons';

export const DecryptorUsersHeaderItems = ({
    isFetching,
    onModalChange,
    onClick = () => {},
    tooltipOpen,
    stateKey = 'profile',
    modal: UsersModal
}: GenericHeaderItemProps) => {
    const { decryptorUsers } = useSelector(({ entity }: InitialState) => entity[stateKey]);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        onModalChange(modalOpen);
    }, [modalOpen]);

    return decryptorUsers && decryptorUsers.length > 0 ? (
        <>
            <EditableHeaderItem title='User' total={decryptorUsers.length} onViewClick={onClick} open={tooltipOpen}>
                {({ hovering }: EditableHeaderChildProps) => (
                    <HeaderItem
                        hovering={hovering}
                        Icon={() => <BadgeIcon isHovered={hovering} Icon={PersonIcon} content={decryptorUsers.length} />}
                        isFetching={isFetching}
                        id='job-users'
                    />
                )}
            </EditableHeaderItem>
            {UsersModal && <UsersModal openProps={[modalOpen, setModalOpen]} />}
        </>
    ) : null;
};
