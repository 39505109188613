import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './MSSearchBar.styles';
import { TextField, InputAdornment, IconButton, CircularProgress } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { Dispatchable, InitialState } from '@mediaseal-webui/types/redux';
import { useMobile } from '@mediaseal-webui/hooks';
import { MOBILE_NAV_VISIBLE } from '@mediaseal-webui/constants';

export interface SearchTypes {
    id?: string;
    keyword: string;
    sort: string;
    page: number;
    firstCall: boolean;
    token?: string;
    filter: boolean;
    filters?: {
        archived: string;
        liveFolders: string;
        active: string;
    };
}

export interface MSSearchUtils {
    id?: string;
    searchFn: (props: SearchTypes) => void;
    token?: string;
    clearId?: string;
    filters?: {
        archived: string;
        liveFolders: string;
        active: string;
    };
}

export interface MSSearchBarProps {
    value: string;
    fullWidth?: boolean;
    inputWidth?: number | string;
    clearSearchValue: () => Dispatchable;
    searchUtils: MSSearchUtils;
    placeholder: string;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setSearchValue: (value: string) => Dispatchable;
    helperText?: string;
    endAdornment?: {};
    searchPadding?: number;
    staticAction?: (value: string) => Dispatchable;
}

export const MSSearchBar = React.memo((props: MSSearchBarProps) => {
    const forwardRef = useRef<HTMLInputElement>();
    const { mobileSearchVisible } = useSelector((state: InitialState) => state.misc);
    const {
        value,
        placeholder,
        searchUtils,
        setSearchValue,
        clearSearchValue,
        setPage,
        endAdornment,
        helperText,
        searchPadding,
        staticAction,
        fullWidth,
        inputWidth,
        ...others
    } = props;
    const [submitting, setSubmitting] = useState<boolean>(false);
    const { searchFn, id, filters, clearId, token } = searchUtils;
    const dispatch = useDispatch();
    const classes = useStyles({ searchPadding });
    const isMobile = useMobile();
    const timer = useRef<NodeJS.Timeout | null>(null);
    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        clearTimeout(timer.current as NodeJS.Timeout);
        setSubmitting(true);
        dispatch(setSearchValue(event.target.value));
        timer.current = setTimeout(async () => {
            staticAction
                ? staticAction(event.target.value)
                : searchFn({
                      keyword: event.target.value,
                      sort: 'createDate,desc',
                      page: 0,
                      firstCall: true,
                      token,
                      filter: true,
                      id,
                      filters
                  });
            setPage(1);
        }, 300);
        setSubmitting(false);
    }, []);

    const handleClose = () => {
        if (forwardRef && forwardRef.current) {
            forwardRef.current.value = '';
            forwardRef.current.focus();
        }

        setPage(1);

        if (clearSearchValue) dispatch(clearSearchValue());
        staticAction
            ? staticAction('')
            : searchFn({
                  keyword: '',
                  sort: 'createDate,desc',
                  page: 0,
                  firstCall: true,
                  token,
                  filter: true,
                  id,
                  filters
              });
    };

    useEffect(
        () => () => {
            clearTimeout(timer.current as NodeJS.Timeout);
            dispatch(clearSearchValue());
            dispatch({ type: MOBILE_NAV_VISIBLE, payload: false });
        },
        []
    );

    const handleViewSearch = (close: boolean) => () => dispatch({ type: MOBILE_NAV_VISIBLE, payload: close });

    return (
        <div className={classes.search}>
            {isMobile && mobileSearchVisible && (
                <IconButton onClick={handleViewSearch(false)}>
                    <ChevronRight />
                </IconButton>
            )}

            <TextField
                onDragEnd={handleViewSearch(false)}
                variant='filled'
                fullWidth={fullWidth}
                value={value}
                inputProps={{
                    ref: forwardRef,
                    style: { padding: '5px 12px 5px', height: 30 }
                }}
                style={{
                    width: fullWidth ? '100%' : inputWidth,
                    opacity: isMobile && !mobileSearchVisible ? 0 : 1
                }}
                InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                        <InputAdornment position='end'>
                            {submitting ? (
                                <CircularProgress />
                            ) : value !== '' ? (
                                <IconButton aria-label='clear input' onClick={handleClose} id={clearId}>
                                    <CloseIcon data-testid='close-icon' />
                                </IconButton>
                            ) : (
                                endAdornment || <SearchIcon />
                            )}
                        </InputAdornment>
                    )
                }}
                helperText={helperText}
                placeholder={placeholder}
                id={id}
                onChange={handleChange}
                name='search'
                {...others}
            />

            {isMobile && !mobileSearchVisible && (
                <IconButton onClick={handleViewSearch(true)}>
                    <SearchIcon />
                </IconButton>
            )}
        </div>
    );
});
