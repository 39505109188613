import React from 'react';
import { Toolbar, IconButton, Divider } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { SmallLogo } from '@mediaseal-webui/components';
import { links, encryptorLinks, decryptorLinks } from '../../../../routes/links';
import Link from '../../SideNav/Link';
import useStyles from './MoreMenu.styles';
import { MoreMenuProps } from '.';
import { INavLink } from '../../../../routes';
import { useLogout } from '@mediaseal-webui/hooks';

const MoreMenu = ({ moreClose }: MoreMenuProps) => {
    const classes = useStyles();
    const handleLogout = useLogout();
    const NavLink = (link: INavLink) => <Link key={link.id} link={link} id={link.navId} authCheck={link.authCheck} />;

    return (
        <div className={classes.moreMenuRoot}>
            <Toolbar className={classes.header}>
                <SmallLogo />
                <IconButton id='close' className={classes.close} onClick={moreClose}>
                    <Close />
                </IconButton>
            </Toolbar>
            <div className={classes.navItems} onClick={moreClose}>
                {links.map(NavLink)}
                <p className={classes.closedText}>Encryptor</p>
                <Divider />
                {encryptorLinks.map(NavLink)}
                <p className={classes.closedText}>Decryptor</p>
                <Divider />
                {decryptorLinks.map(NavLink)}
                <Divider />
                <Link
                    id='settings'
                    authOverride
                    link={{
                        id: 1,
                        text: 'SETTINGS',
                        link: '/settings',
                        icon: 'fas fa-cog',
                        navId: 'settingLink',
                        authCheck: 'canAdministerGlobalSettings'
                    }}
                />
                <Link
                    authOverride
                    disableLink
                    onClick={handleLogout}
                    link={
                        {
                            id: 0,
                            text: 'Log Out',
                            icon: 'fas fa-sign-out-alt',
                            link: '/logout',
                            navId: 'logout'
                        } as INavLink
                    }
                />
            </div>
        </div>
    );
};

export default MoreMenu;
