import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dialogRoot: {
        width: 450,
        zIndex: 100000
    },
    dialogTitleRoot: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(2),
        '& .titleContainer': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        '& span': {
            display: 'flex',
            '& .elipsis.loading': {
                width: theme.spacing(3)
            }
        }
    },
    content: {
        transition: `all 0.3s ease`,
        textAlign: 'center',
        '& .loading': {
            opacity: 0,
            height: 0,
            padding: 0
        }
    },
    actions: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end',
        borderTop: `1px solid #eee`,
        '& button': {
            marginLeft: theme.spacing(2),
            borderRadius: '30px',
            height: '40px',
            padding: '0 15px'
        }
    }
}));

export default useStyles;
