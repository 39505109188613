import styled, { css } from 'styled-components';
import CookieConsent from 'react-cookie-consent';

export interface StyledCookieConsentProps extends React.HTMLProps<HTMLDivElement> {}
export const StyledCookieConsent = styled(CookieConsent)<StyledCookieConsentProps>(
    ({ theme }) => css`
        background: #eee;
        z-index: 10006;
        color: black;
        box-shadow: 0px -7px 5px 0px rgba(0, 0, 0, 0.27);

        button {
            color: ${theme.palette.common.white};
            background: ${theme.palette.primary.main};
            fontsize: 13px;
            borderradius: 20;
            width: 100;
            height: 40;
        }
    `
);
