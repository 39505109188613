import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    '@global': {
        '.ReactVirtualized__Table__headerRow': {
            border: `1px solid ${theme.palette.grey[400]}`,
            borderRadius: '4px 4px 0  0',
            display: 'flex',
            alignItems: 'center',
            background: `rgba(29,28,29,0.25) !important`
        },
        '.ReactVirtualized__Table__row': {
            display: 'flex',
            alignItems: 'center'
            // outline: 'none !important',
        },
        '.ReactVirtualized__Table__Grid': {
            borderBottom: `1px solid ${theme.palette.table.main}`,
            borderLeft: `1px solid ${theme.palette.grey[400]}`,
            borderRight: `1px solid ${theme.palette.grey[400]}`,
            borderRadius: '0 0 4px 4px'
        },
        '.ReactVirtualized__Grid.ReactVirtualized__List': {
            willChange: 'unset !important'
        }
    },
    table: {
        tableLayout: 'auto',
        width: '100%',
        // outline: 'none',
        zIndex: -10
    },
    noClick: {
        cursor: 'initial'
    },
    zIndex: {
        '& .ReactVirtualized__Grid.ReactVirtualized__Table__Grid': {
            zIndex: '-10 !important'
        }
    },
    stepper: {
        width: '100%',
        height: '100%'
    }
}));

export default useStyles;
