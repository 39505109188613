import React from 'react';
import { Modal, Typography, DialogActions } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from './Table/Table';
import useStyles from './TableModal.styles';
import { InitialState, EntityKey, Dispatchable } from '@mediaseal-webui/types/redux';
import { IOpenProps } from '@mediaseal-webui/types/generic';
import { cancelSelection, submitSelection } from '@mediaseal-webui/actions';
import { MSSearchBar as SearchBar, Actions } from '../';
import { DecryptorGroupService, EncryptorGroupService, RecipientService, TokensService, UsersService } from 'packages/services/src';

export interface ISearchProps {
    setPage: React.Dispatch<React.SetStateAction<number>>;
    searchFn: ({ keyword: string }) => Promise<void>;
    token: string;
    value: string;
    clearFn: () => Dispatchable;
    setterFn: (value: string) => Dispatchable;
    placeholder: string;
}
export interface TableModalProps extends IOpenProps {
    title: string;
    service: UsersService & RecipientService & EncryptorGroupService & DecryptorGroupService & TokensService;
    entityKey: EntityKey;
    updateFn: any;
    searchProps: ISearchProps;
}

export const TableModal = React.memo((props: TableModalProps) => {
    const {
        openProps: [open, setOpen],
        title,
        service,
        entityKey,
        updateFn,
        searchProps
    } = props;

    const classes = useStyles();
    const dispatch = useDispatch();
    const { token, setterFn, clearFn, value, placeholder, searchFn, setPage } = searchProps;
    const { totalSelected } = useSelector((state: InitialState) => state.misc);

    const handleCancel = () => {
        dispatch(cancelSelection());
        setOpen(false);
    };

    const handleSubmit = () => {
        dispatch(updateFn());

        // dispatch(submitSelection(entityKey, updateFn))
        setOpen(false);
    };

    const tokenTableStyle = [[classes.tableHolder], [entityKey === 'allowedTokens' ? classes.tableHolderTokens : '']].join(' ');

    return (
        <Modal open={open} data-testid='table-modal' id='group-table-modal' aria-labelledby='modal-title'>
            <div className={classes.modalRoot}>
                <Typography color='textPrimary' className={classes.title} variant='overline' id='modal-title'>
                    {title}
                </Typography>
                {entityKey !== 'allowedTokens' && (
                    <div className={classes.searchWrapper}>
                        <div className={classes.searchbar}>
                            <SearchBar
                                setPage={setPage}
                                searchUtils={{
                                    searchFn,
                                    token
                                }}
                                value={value}
                                fullWidth
                                clearSearchValue={clearFn}
                                setSearchValue={setterFn}
                                placeholder={placeholder}
                            />
                        </div>
                    </div>
                )}
                <div className={tokenTableStyle}>
                    <Table service={service} entityKey={entityKey} />
                </div>
                <DialogActions>
                    <Actions saveFn={handleSubmit} closeFn={handleCancel} disableSave={!totalSelected} />
                </DialogActions>
            </div>
        </Modal>
    );
});
