// import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
    mainLayout: {
        height: '100vh',
        background: theme.palette.background.default,
        width: `calc(100% - ${theme.sizes.sideNav.open}px)`,
        marginLeft: theme.sizes.sideNav.open,
        transition: 'all 0.2s ease'
    },
    sideNavClosed: {
        width: `calc(100% - ${theme.sizes.sideNav.closed}px)`,
        marginLeft: theme.sizes.sideNav.closed,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            width: '100%'
        }
    }
}));

export default useStyles;

export const InitializeContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
`;

export const InitializeText = styled.h4``;
