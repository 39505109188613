import { useDispatch } from 'react-redux';
import {
    removeDecryptorGroupFromSummary,
    removeEncryptorGroupFromSummary,
    removeDecryptorUserFromSummary,
    removeEncryptorUserFromSummary
} from '@mediaseal-webui/actions';
import { Group, User } from '@mediaseal-webui/types/redux';

export const useSummaryList = () => {
    const dispatch = useDispatch();

    return {
        removeDecGroup: (group: Group) => () => dispatch(removeDecryptorGroupFromSummary(group)),
        removeEncGroup: (group: Group) => () => dispatch(removeEncryptorGroupFromSummary(group)),
        removeEncUser: (user: User) => () => dispatch(removeEncryptorUserFromSummary(user)),
        removeDecUser: (user: User) => () => dispatch(removeDecryptorUserFromSummary(user))
    };
};
