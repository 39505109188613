import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        boxShadow: 'none',
        marginBottom: theme.spacing(2),
        backgroundColor: 'unset',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(5)
        }
    },
    heading: {
        fontSize: theme.typography.pxToRem(10),
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.text.primary
    },
    icon: {
        color: theme.palette.text.primary,
        marginRight: 10
    },
    textArea: {
        display: 'block',
        width: '100%',
        boxSizing: 'border-box',
        resize: 'none',
        fontFamily: theme.typography.fontFamily,
        border: `1px solid ${theme.palette.divider}`,
        padding: 15,
        fontSize: 14
    },
    noPadding: {
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    details: {
        width: '100%',
        background: theme.palette.background.paper,
        display: 'flex',
        padding: theme.spacing(2),
        flexWrap: 'wrap',
        borderRadius: 10
    },

    title: {
        marginBottom: theme.spacing(1)
    },
    nameContainer: {
        paddingTop: theme.spacing(3),
        width: '100%'
    }
}));

export default useStyles;
