import { keyframes } from 'styled-components';
import styled, { css } from 'styled-components';

export const ripple = keyframes`
    from {
        background-size: 0 0;
    }
    to {
        background-size: 1000% 1000%;
    }
`;
export const fadeIn = keyframes`
 from {
  opacity: 0
 }
 to {
   opacity: 1
 }
`;
interface StyledMultiButtonProps {
    width?: number;
}

export const StyledMultiButton = styled.div`
    display: flex;
    width: ${({ width }: StyledMultiButtonProps) => (width ? width : '152px')};
    height: 40px;
    opacity: 1;
`;
export const SelectedOption = styled.button`
    display: flex;
    outline: none;
    border: none;
    font-family: Montserrat;
    border-radius: 30px 0 0 30px;
    background: #007e9c;
    text-align: center;
    color: white;
    align-items: center;
    justify-content: center;
    width: 75%;
    &:hover {
        background: #024b78;
        transition: 0.5s;
    }
    &:active {
        background: #0bb0be;
    }
`;

export const DropdownArrow = styled.button`
    display: flex;
    width: 25%;
    border-radius: 0 5px 5px 0;
    background: #007e9c;
    text-align: center;
    border-top: none;
    border-bottom: none;
    outline: none;
    color: white;
    border-right: none;
    border-left: 1px solid #024b78;
    align-items: center;
    justify-content: center;
    &:hover {
        background: #024b78;
        transition: 0.5s;
    }
`;
