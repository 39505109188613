import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccordionSummary, Accordion, AccordionDetails } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Settings from '@material-ui/icons/Settings';
import useStyles from './AdvancedOptions.styles';
import { setSecurtyOptions } from '../../../../actions/jobs';
import { InitialState } from '@mediaseal-webui/types/redux';
import { AdvancedOptionsProps } from '.';
import { Icon } from '@material-ui/core';
import { useJobFeatures } from '@mediaseal-webui/hooks';
import { removeJobFeature } from '@mediaseal-webui/actions';
import { SelectableBtn } from '@mediaseal-webui/components';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import { GiFiles } from 'react-icons/gi';
import { BiTime } from 'react-icons/bi';
import { AiFillFolder } from 'react-icons/ai';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import { CgNotes } from 'react-icons/cg';
import { QrOverlay } from './QrOverlay';
import { ReauthComponent } from './Reauth';
import { ContactDetails } from './ContactDetails';
import { Notes } from './Notes';
import { SunsetSunrise } from './SunriseSunset';
import { ReactComponent as SecurePlayerSvg } from '../../../../SecurePlayer.svg';
import { ReactComponent as SecurePlayerSvgAlt } from '../../../../SecurePlayerAlt.svg';

export const SecurePlayerIcon = (props: any) => {
    const currentTheme = useSelector((state: InitialState) => state.settings.misc.theme);
    if (currentTheme === 'light') {
        return <SecurePlayerSvg data-testid='logo-image' alt='Secure Player Logo' {...props} className={`${props.className} secure`} />;
    } else {
        return <SecurePlayerSvgAlt data-testid='logo-image' alt='Secure Player Logo' {...props} className={`${props.className} secure`} />;
    }
};

const DISABLED_TEXT = 'This option is disabled and can not be combined with live folders';

export const QRCode = ({ className }: { className: string }) => <Icon className={`${className} fas fa-qrcode`} />;

const AdvancedOptions = ({ setterFn, sunsetProps }: AdvancedOptionsProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { type, template, features } = useSelector((state: InitialState) => state.entity.create);
    const { addSecurePlayback, addReauthBehaviour } = useJobFeatures(features);

    const filesIcon = ({ className }: { className: string }) => <GiFiles className={`${className}`} size={24} />;

    const setDefaultSunriseSunset = (sunsetProps) => {
        dispatch(setterFn(sunsetProps));
    };

    const checkboxChangeHandler = (selected: boolean) => {
        dispatch(setSecurtyOptions({ disableSunset: !selected }));
        setDefaultSunriseSunset(sunsetProps);
    };
    const handleSecureSelection = (selected: boolean) => {
        dispatch(
            selected
                ? setterFn({
                      features: addSecurePlayback()
                  })
                : removeJobFeature('SecurePlayback')
        );
    };

    const handleBatching = (selected: boolean) => {
        dispatch(setterFn({ batched: selected }));
    };

    const handleLiveFolder = (selected: boolean) => {
        dispatch(setterFn({ template: selected }));
    };

    const handleAuth = (selected: boolean) => {
        dispatch(
            selected
                ? setterFn({
                      features: addReauthBehaviour()
                  })
                : removeJobFeature('ReauthBehaviour')
        );
    };

    const handleDetails = (selected: boolean) => {
        dispatch(
            setterFn({
                contactSelected: selected
            })
        );
    };

    const handleNotes = (selected: boolean) => {
        dispatch(setterFn({ notesSelected: selected }));
    };
    return (
        <Accordion className={classes.root}>
            <AccordionSummary className={classes.noPadding} expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='advanced-options'>
                <Settings fontSize='small' className={classes.icon} />
                <Typography color='textPrimary' variant='overline' className={classes.heading}>
                    Advanced Options
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <SelectableBtn
                    id='live-folders-btn'
                    tooltipLabel='Activating this setting will disable the addition of Secure Playback and Custom Reauthentication Time'
                    title='Live Folders'
                    Icon={AiFillFolder}
                    subtitle='Enable Live Folders'
                    setterFn={handleLiveFolder}
                    component={null}
                />
                <SelectableBtn
                    id='batching-btn'
                    disabled={template}
                    component={null}
                    deselect={template}
                    tooltipLabel={template ? DISABLED_TEXT : ''}
                    title='Batching'
                    Icon={filesIcon}
                    subtitle='Enable Batching'
                    setterFn={handleBatching}
                />
                <SelectableBtn
                    id='secure-playback-btn'
                    disabled={template}
                    tooltipLabel={template ? DISABLED_TEXT : ''}
                    title='Secure Playback'
                    Icon={SecurePlayerIcon}
                    subtitle='Limit playback to our MediaSeal Secure Viewer / Player'
                    setterFn={handleSecureSelection}
                    component={QrOverlay}
                    expandable
                    deselect={template}
                />
                <SelectableBtn
                    id='reauth-time-btn'
                    disabled={template}
                    tooltipLabel={template ? DISABLED_TEXT : ''}
                    title='Custom Reauthentication Time'
                    subtitle='Customize the reauthentication timeout'
                    Icon={BiTime}
                    setterFn={handleAuth}
                    component={ReauthComponent}
                    expandable
                    deselect={template}
                />
                <SelectableBtn
                    id='contact-details-btn'
                    title='Contact Details'
                    subtitle='Add contact details to this job'
                    Icon={ContactPhoneIcon}
                    setterFn={handleDetails}
                    component={ContactDetails}
                    expandable
                />
                <SelectableBtn
                    id='notes-btn'
                    title='Notes'
                    subtitle='Add notes to this job'
                    Icon={CgNotes}
                    setterFn={handleNotes}
                    component={Notes}
                    expandable
                />
                {type === 'ONLINE' && (
                    <SelectableBtn
                        id='sunrise-btn'
                        title='Sunset / Sunrise Time'
                        subtitle='Determine times a user can access files'
                        Icon={Brightness4Icon}
                        setterFn={checkboxChangeHandler}
                        component={SunsetSunrise}
                        expandable
                    />
                )}
            </AccordionDetails>
        </Accordion>
    );
};

export default AdvancedOptions;
