import { EncryptorGroupService, IdentityProvider, IdentityProviderService } from '@mediaseal-webui/services';
import { useIdentityProvider } from 'hooks/useIdentityProviders';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const useDefaultGroup = () => {
    const { setData, defaultGroup } = useIdentityProvider();
    const [value, setValue] = useState<string>(defaultGroup?.id ? defaultGroup?.name : '');
    const service = useMemo(() => new IdentityProviderService(), []);
    const groupsService = useMemo(() => new EncryptorGroupService(), []);
    const { enqueueSnackbar } = useSnackbar();

    const updateDefaultGroup = useCallback(
        async ({ groupId }: Partial<IdentityProvider>) => {
            try {
                await service.updateDefaultGroup({ groupId } as Partial<IdentityProvider>);
                const { data: groupData } = await groupsService.searchGroups(String(groupId));

                setData((previousState) => ({
                    ...previousState,
                    defaultGroup: {
                        id: String(groupData.content[0]?.groupId),
                        name: groupData.content[0].name ?? ''
                    }
                }));
                enqueueSnackbar('Successfully updated default group', {
                    variant: 'success'
                });
            } catch (error) {
                enqueueSnackbar('Failed to update default group', {
                    variant: 'error'
                });
            }
        },
        [enqueueSnackbar, service, setData, groupsService]
    );

    const fetchDefaultGroup = useCallback(async () => {
        try {
            const {
                data: { groupId }
            } = await service.fetchDefaultGroup();
            if (groupId) {
                const { data } = await groupsService.fetchGroup(groupId);
                setData((ps) => ({
                    ...ps,
                    defaultGroup: {
                        id: String(groupId),
                        name: data.name
                    }
                }));
                setValue((ps) => data.name ?? ps);
            }
        } catch (error) {
            console.log(error);
        }
    }, [setData, service, groupsService]);
    useEffect(() => {
        fetchDefaultGroup();
        return () => {
            setValue('');
        };
    }, [fetchDefaultGroup]);

    return {
        defaultGroup,
        value,
        setValue,
        updateDefaultGroup
    };
};
