import React from 'react';
import { useSelector } from 'react-redux';
import { FaUserShield } from 'react-icons/fa';
import { InitialState } from '@mediaseal-webui/types/redux';
import { GenericHeaderItemProps } from '..';
import { BadgeIcon } from '../../BadgeIcon';
import { EditableHeaderChildProps, EditableHeaderItem } from '../../EditableHeaderItem';
import { HeaderItem } from '../HeaderItem';

/**
 * Look, we had to. Deadlines OK. Don't judge us. Just fix it. You have more time than we do
 */
const PersonIcon = () => <FaUserShield style={{ width: 24, height: 24 }} />;
export const EncUsersHeaderItems = ({ isFetching, onClick, tooltipOpen, stateKey = 'profile' }: GenericHeaderItemProps) => {
    const { users } = useSelector(({ entity }: InitialState) => entity[stateKey]);
    return users && users.length > 0 ? (
        <EditableHeaderItem title='User' total={users.length} onViewClick={onClick} open={tooltipOpen}>
            {({ hovering }: EditableHeaderChildProps) => (
                <HeaderItem
                    hovering={hovering}
                    Icon={() => <BadgeIcon isHovered={hovering} Icon={PersonIcon} content={users.length} />}
                    isFetching={isFetching}
                    id='enc-users'
                />
            )}
        </EditableHeaderItem>
    ) : null;
};
