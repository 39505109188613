import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        opacity: 1,
        bottom: theme.spacing(2),
        right: theme.spacing(5),
        boxShadow: 'none',
        zIndex: 100,
        [theme.breakpoints.down('sm')]: {
            bottom: theme.spacing(10),
            left: theme.spacing(0.5)
        },
        '&:hover': {
            background: 'none'
        }
    },
    primary: {
        color: theme.palette.primary.main,
        background: 'none'
    }
}));

export default useStyles;
