import { combineReducers, Reducer } from 'redux';

export interface NetworkState {
    offline: boolean;
    online: boolean;
}

const initialNetworkState: NetworkState = {
    offline: false,
    online: false
};

export type NetworkStateAction = { type: 'ONLINE' | 'OFFLINE' };

export const networkState: Reducer<NetworkState, NetworkStateAction> = (state = initialNetworkState, action) => {
    switch (action.type) {
        case 'ONLINE':
            return {
                ...state,
                offline: false,
                online: true
            };
        case 'OFFLINE':
            return {
                ...state,
                online: false,
                offline: true
            };
        default:
            return initialNetworkState;
    }
};

export default combineReducers({
    networkState
});
