import { fadeIn } from '@mediaseal-webui/components';
import { AiFillCheckCircle } from 'react-icons/ai';
import styled, { css, DefaultTheme } from 'styled-components';

interface StyledProviderProps {
    configured: boolean;
    theme: DefaultTheme;
}
export const StyledProviderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
export const StyledProvider = styled.div`
    ${({ theme, configured }: StyledProviderProps) => css`
        color: #ffffff;
        display: flex;
        height: 60px;
        min-width: 250px;
        margin-bottom: ${theme.spacing(1)}px;
        border-radius: 30px;
        background: linear-gradient(90deg, ${theme.palette.primary.dark}, ${theme.palette.primary.light});
        border: ${theme.palette.divider};
        transition: all 0.35s ease;
        text-transform: uppercase;
        width: 100%;
        align-items: center;
        justify-content: center;
        animation: ${fadeIn} 0.35s ease;
        animation-fill-mode: forwards;
        ${!configured &&
        css`
            :hover {
                cursor: pointer;
                background: linear-gradient(90deg, ${theme.palette.primary.dark}, ${theme.palette.primary.dark});
            }
            :active {
                background: linear-gradient(90deg, ${theme.palette.primary.light}, ${theme.palette.primary.light});
            }
        `}
        svg {
            margin: ${theme.spacing(0.5)}px;
        }
        ${theme.breakpoints.down('sm')} {
        }
    `}
`;

export const StyledCheckMark = styled(AiFillCheckCircle)`
    ${({ theme }) => css`
        color: ${theme.palette.success.main};
        font-size: 20px;
    `}
`;
