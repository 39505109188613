import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 200,
        height: '50vh',
        width: `calc(100% - ${theme.spacing(4)}px)`,
        margin: theme.spacing(3),
        boxShadow: 'none',
        overflowY: 'scroll',
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            margin: theme.spacing(1)
        }
    },
    table: {},
    tableBody: {
        position: 'relative'
    },
    tableRow: {},
    tableCell: {},
    field: {
        zIndex: 3
    },
    errorMessage: {
        position: 'absolute',
        top: '50%',
        fontSize: '1.875rem',
        width: `calc(100% - ${theme.spacing(4)}px)`,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.25em'
        }
    },
    pill: {
        background: theme.palette.pills.dark,
        color: theme.palette.common.white
    },
    iconButton: {
        opacity: 0.2,
        '&:hover': {
            opacity: 1
        }
    }
}));

export default useStyles;
