import React, { memo } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import useStyles from './DisplayPermissions.styles';
import { SerializedPermissions } from '@mediaseal-webui/utils';
import { Typography } from '@material-ui/core';

interface DisplayPermissionsProps {
    groups: SerializedPermissions[];
    allowedPermissions?: string[];
}

const DisplayPermissions = memo(({ groups, allowedPermissions }: DisplayPermissionsProps) => {
    const classes = useStyles();
    const isDisabled = (option: { permissionName: string }) => !!(allowedPermissions && allowedPermissions.indexOf(option.permissionName) === -1);

    return (
        <>
            <div className={classes.root}>
                {groups
                    .sort((a, b) => a.id - b.id)
                    .map((category) => (
                        <FormControl key={category.id} required error={false} component='fieldset' className={classes.formControl}>
                            <FormLabel component='label'>{category.label}</FormLabel>
                            <FormGroup data-testid='selection-group'>
                                {category.options.map((option) => (
                                    <Typography
                                        color='textPrimary'
                                        className={isDisabled(option) ? classes.disabled : classes.standard}
                                        variant='body1'
                                        data-testid={option.permissionName}>
                                        {option.permissionName}
                                    </Typography>
                                ))}
                            </FormGroup>
                        </FormControl>
                    ))}
            </div>
        </>
    );
});

export default DisplayPermissions;
