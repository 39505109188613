import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { MainTable } from '@mediaseal-webui/components';
import { fetchGroups, updateGroup } from '@mediaseal-webui/actions';
import { removeEntityAndUpdate, removeDecryptorGroupFromSummary } from '../../../../../actions/entity';
import { profileGenericHeader, profileGenericHeaderWithoutActions } from '../../../../../data/profile-table-headers';
import ActionContainer from '../../../../table-components/ActionContainer/ActionContainer';
import { useAuth } from '@mediaseal-webui/hooks';
import { InitialState } from '@mediaseal-webui/types/redux';
import { GenericEntity } from '@mediaseal-webui/types/schemas';
import { setConfirmDialogue } from '@mediaseal-webui/actions';
import { hideRemoveGroupConfirmation } from '@mediaseal-webui/constants';
import { useAppSelector } from 'store';

export const DecGroupTable = () => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const auth = useAuth();
    const isCreateAuth = auth.create || auth.administer;

    const { decryptorGroups } = useSelector(({ entity: { profile } }: InitialState) => profile);
    const { totalEncyptorGroupPages } = useSelector(({ groups }: InitialState) => groups);
    const dispatch = useDispatch();
    const { items: permissions } = useAppSelector((state) => state.permissions);
    const hasEditEncryptorGroupsPermission = permissions.some((permission) => permission.permissionName === 'canEditEncryptorGroups');

    const decryptorGroupRemoveHandler = (group: GenericEntity) => () =>
        dispatch(
            setConfirmDialogue({
                icon: 'warning',
                title: 'Remove Group',
                confirmButtonText: 'Remove Group',
                message: 'Are you sure you want to remove this group?',
                action: removeEntityAndUpdate({
                    entity: group,
                    id,
                    removeFn: removeDecryptorGroupFromSummary,
                    updateFn: updateGroup
                }),
                memorable: hideRemoveGroupConfirmation
            })
        );

    const handleRemoveDecryptorGroup = useCallback(decryptorGroupRemoveHandler, [dispatch, id]);
    const handleDecGroupRowClick = (group: GenericEntity) => history.push(`/groups/decryptor/${group.id}/profile`);

    return (
        <MainTable
            id='decGroupsTable'
            entities={decryptorGroups || []}
            totalPages={totalEncyptorGroupPages}
            //@ts-expect-error due to missing props ts(2322).
            fetchFn={fetchGroups}
            columns={hasEditEncryptorGroupsPermission ? profileGenericHeader : profileGenericHeaderWithoutActions}
            onRowClick={handleDecGroupRowClick}
            tableActionsRenderer={(entity: GenericEntity) => <ActionContainer create={isCreateAuth} removeAction={handleRemoveDecryptorGroup(entity)} />}
            noResultsMessage='No Decryptor Groups are assigned to this group'
        />
    );
};
