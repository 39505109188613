import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    '@global': {
        '.react-datepicker__tab-loop': {
            display: 'inline-block'
        },
        '.react-datepicker__input-container': {
            padding: theme.spacing(1)
        },
        '.react-datepicker': {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        '.react-datepicker-popper': {
            zIndex: ' 9999 !important',
            backgroundColor: 'unset !important'
        },
        '.react-datepicker__triangle': {
            opacity: 0
        },
        '.react-datepicker__portal': {
            justifyContent: 'unset'
        },
        '.react-datepicker__day--selected': {
            backgroundColor: '#4f9db3 !important'
        },
        '.react-datepicker__day--in-range': {
            backgroundColor: '#4f9db3 !important'
        },
        '.react-datepicker__time-list-item--selected': {
            backgroundColor: '#4f9db3 !important'
        },
        '.react-datepicker__day, .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker__time-list-item, .react-datepicker__day-name':
            {
                color: `${theme.palette.text.primary} !important`
            },
        '.react-datepicker, .react-datepicker__time-list': {
            backgroundColor: `${theme.palette.background.paper} !important`
        },
        '.react-datepicker__header': {
            backgroundColor: `${theme.palette.background.default} !important`,
            color: `${theme.palette.text.primary} !important`
        },
        '.react-datepicker__today-button': {
            backgroundColor: `${theme.palette.background.default} !important`,
            color: `${theme.palette.text.primary} !important`
        },
        '.react-datepicker__time-list-item': {
            '&:hover': {
                backgroundColor: `${theme.palette.action.hover} !important`
            }
        }
    },
    timeContainer: {
        display: 'flex',
        flexDirection: 'row',
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    toInput: {
        width: '100%'
    },
    fromInput: {
        width: '100%'
    },
    fullWidth: {
        display: 'flex'
    },
    popper: {
        opacity: 1,
        background: 'white',
        zIndex: 100001
    },
    calenderContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    overline: {
        lineHeight: 1,
        paddingLeft: theme.spacing(2),
        marginTop: theme.spacing(3)
    },
    subtitle: {
        display: 'flex',
        width: '100%',
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            display: 'unset'
        }
    }
}));

export default useStyles;
