import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MainTable } from '@mediaseal-webui/components';
import { genericHeader, genericNoActions } from '../../../../data';
import { useParams } from 'react-router';
import { updateTitle } from '../../../../actions/titles';
import { removeEntityAndUpdate, removeEncryptorGroupFromSummary } from '../../../../actions/entity';
import { hideRemoveGroupConfirmation } from '../../../../constants/preferences';
import { setConfirmDialogue } from '../../../../actions/misc';
import ActionContainer from '../../../table-components/ActionContainer/ActionContainer';
import { useAuth } from '@mediaseal-webui/hooks';
import { InitialState } from '@mediaseal-webui/types/redux';
import { GenericEntity } from '@mediaseal-webui/types/schemas';
import { profileGenericHeaderWithoutActions } from 'data/profile-table-headers';
import { useAppSelector } from 'store';

export const GroupsTable = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();

    const auth = useAuth();
    const isCreateAuth = auth.edit || auth.administer;

    const [groupPage, setGroupPage] = useState(1);

    const { encryptorGroups } = useSelector((state: InitialState) => state.entity.profile);

    const { items: permissions } = useAppSelector((state) => state.permissions);
    const hasEditTitlePermission = permissions.some((permission) => permission.permissionName === 'canEditTitles');

    const noSortHeader = hasEditTitlePermission
        ? genericHeader.map((col) => ({ ...col, disableSort: true }))
        : genericNoActions.map((col) => ({ ...col, disableSort: true }));

    const groupRemoveHandler = (group: GenericEntity) => () =>
        dispatch(
            setConfirmDialogue({
                icon: 'warning',
                title: 'Remove Group',
                confirmButtonText: 'Remove Group',
                message: 'Are you sure you want to remove this group?',
                action: removeEntityAndUpdate({
                    entity: group,
                    id,
                    removeFn: removeEncryptorGroupFromSummary,
                    updateFn: updateTitle
                }),
                memorable: hideRemoveGroupConfirmation
            })
        );
    const handleGroupRemove = useCallback(groupRemoveHandler, [dispatch, id]);

    return (
        <MainTable
            entities={encryptorGroups || []}
            searchValue=''
            pageControl={{ page: groupPage, setPage: setGroupPage }}
            tableActionsRenderer={(entity: GenericEntity) => <ActionContainer create={isCreateAuth} removeAction={handleGroupRemove(entity)} />}
            fetchFn={() => Promise.resolve()}
            totalPages={1}
            columns={noSortHeader}
            noResultsMessage='No groups are associated with this title'
            onRowClick={() => () => null}
            id='groups-table'
        />
    );
};
