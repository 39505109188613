import CheckBoxHeading from './CheckBoxHeading';

export interface CheckBoxHeadingProps {
    text: string;
    checked?: boolean;
    disabled?: boolean;
    changeFn: (args: any) => void;
    [extra: string]: any;
}

export default CheckBoxHeading;
