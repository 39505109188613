import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RecipientService } from '@mediaseal-webui/services';
import { definitions } from '@mediaseal-webui/types/api';
import { seedData } from '@mediaseal-webui/utils';
import { SpringBootResponse } from '@mediaseal-webui/types';
import appendToPagedState from 'reducers/helpers/appendToPagedState';

export const searchRecipients = createAsyncThunk(
    'recipients/searchRecipients',
    async ({ service, keyword }: { service: RecipientService; keyword: string }, { rejectWithValue }) => {
        try {
            //@ts-expect-error due to data does not exist ts(2339).
            const { data } = await service.searchRecipients(keyword);
            return data as RecipientsState;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const fetchRecipients = createAsyncThunk('recipients/fetchRecipients', async (service: RecipientService, { rejectWithValue }) => {
    try {
        // @ts-expect-error due to data does not exist ts(2339).
        const { data } = await service.fetchRecipients();
        return data as RecipientsState;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export interface RecipientsState extends SpringBootResponse<definitions['Recipient'][]> {
    pending: boolean;
    searchValue: string;
}
const initialRecipientState: RecipientsState = {
    content: seedData(),
    searchValue: '',
    pending: false,
    page: {
        totalElements: 0,
        totalPages: 0
    }
};
export const recipientSlice = createSlice({
    name: 'recipients',
    reducers: {
        setSearchValue: (state, action) => ({
            ...state,
            searchValue: action.payload
        }),
        clearSearchValue: (state) => ({
            ...state,
            searchValue: ''
        }),
        setUsers: (state, action) => ({
            ...state,
            ...action.payload
        }),
        setSelected: (state, action) => ({
            ...state,
            content: state.content.map((item) => (item.id === action.payload.id ? { ...item, selected: true } : item))
        }),
        deselect: (state, action) => ({
            ...state,
            content: state.content.map((item) => (item.id === action.payload.id ? { ...item, selected: false } : item))
        }),

        clearUsers: () => initialRecipientState
    },
    initialState: initialRecipientState,
    extraReducers: (builder) => {
        builder.addCase(searchRecipients.fulfilled, (state, action) => ({
            ...state,
            page: {
                ...(action.payload.page ?? {
                    totalPages: 0,
                    totalElements: 0
                })
            },
            content: [...action.payload.content],
            pending: false
        }));
        builder.addCase(searchRecipients.pending, (state, { payload }) => {
            return {
                ...state,
                pending: true
            };
        });
        builder.addCase(fetchRecipients.fulfilled, (state: RecipientsState, action) => {
            return appendToPagedState<definitions['Recipient']>(action, state);
        });
        builder.addCase(fetchRecipients.pending, (state, { payload }) => {
            return {
                ...state,
                pending: true
            };
        });
    }
});
