import { ButtonProps, CircularProgress } from '@material-ui/core';
import { IdentityProvider } from '@mediaseal-webui/services';
import { useIdentityProvider } from 'hooks/useIdentityProviders';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyledConfigureButton } from './index.style';
import { useDefaultGroup } from 'hooks/useDefaultGroup';

interface Props extends ButtonProps {
    handleSubmit: () => Promise<IdentityProvider>;
}

export const ConfigureButton: React.FC<Props> = ({ handleSubmit, ...others }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const {
        configureProvider: { clientId, clientSecret, type, domain },
        setData
    } = useIdentityProvider();
    const { enqueueSnackbar } = useSnackbar();
    const clientExp = clientId !== '' && clientSecret !== '';
    const defaultGroup = useDefaultGroup();
    const isDefaultGroupSet = defaultGroup.defaultGroup !== undefined;

    const isDisabled = useMemo(
        () => (type === 'okta' ? !domain || !clientExp || !isDefaultGroupSet : !clientExp || !isDefaultGroupSet),
        [domain, clientExp, type, isDefaultGroupSet]
    );

    const handleClick = useCallback(
        async (event: React.MouseEvent) => {
            event.preventDefault();

            setLoading(true);
            await handleSubmit().then((response) => {
                const data = response.data;
                if (response.postIDPConfig && response.postIDPConfig.status === 201) {
                    setLoading(false);
                    enqueueSnackbar(`Successfully configured ${type} provider`, {
                        variant: 'success'
                    });
                    setData((ps) => ({
                        ...ps,
                        configureProvider: { ...ps.configureProvider, showModal: false },
                        configuredProvider: { ...ps.configuredProvider, ...data }
                    }));
                    return data;
                } else {
                    // TODO: response is undefined on error, investigate and fix. https://fortiumtech.atlassian.net/browse/MS-3933
                    setLoading(false);
                    enqueueSnackbar('Identitiy provider configuration failed, Please check details and try again.', {
                        variant: 'error'
                    });
                }
            });
        },
        [type, enqueueSnackbar, setData, handleSubmit]
    );
    useEffect(
        () => () => {
            setData((ps) => ({
                ...ps,
                configureProvider: {
                    ...ps.configureProvider,
                    clientId: '',
                    clientSecret: '',
                    domain: null,
                    showModal: false,
                    type: ''
                }
            }));
        },
        [setData]
    );
    return (
        <StyledConfigureButton {...others} onClick={handleClick} disabled={isDisabled} data-testid='configure-btn'>
            Configure
            {loading && <CircularProgress data-testid='loader' size='1rem' color='inherit' />}
        </StyledConfigureButton>
    );
};
