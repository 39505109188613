import React from 'react';
import { TextCell, StatusCell, VerifiedCell } from '../Cells';
import { TableCell } from '@material-ui/core';
import { CellRendererProps, RowData } from '.';
import { formatDateFns, processAuthMode, processFileSize } from '@mediaseal-webui/utils';
import { JobTypes } from '@mediaseal-webui/types/jobs';
import { NoResults } from '../../NoResults';
import { PermissionsCell } from '../Cells/PermissionCell/PermissionCell';

export const cellRenderer = ({
    cellData,
    columnIndex = 0,
    rowData = {} as RowData,
    columns,
    classes,
    isFetching,
    isMobile,
    entities,
    noResultsMessage,
    width,
    height,
    calenderShown,
    tableActionsRenderer = () => null
}: CellRendererProps) => {
    const checkIdCell = () => (columns[columnIndex].label === 'ID' ? rowData.id : String(cellData));
    if (entities && entities?.length < 0) {
        return (
            <TableCell component='div' align='right' className={classes.flexContainer} classes={{ root: `${classes.tableCell}` }}>
                <div
                    style={{
                        width,
                        height,
                        maxHeight: 600,
                        zIndex: calenderShown ? -10 : 3,
                        position: 'relative'
                    }}>
                    <NoResults message={noResultsMessage || ''} />
                </div>
            </TableCell>
        );
    }
    switch (columns[columnIndex].label) {
        case 'Actions':
            return (
                <TableCell component='div' align='right' className={classes.flexContainer} classes={{ root: `${classes.tableCell}` }}>
                    {tableActionsRenderer(rowData)}
                </TableCell>
            );
        case 'Created':
            return (
                <TextCell
                    hidden={columns[columnIndex].hidden}
                    output={(rowData?.createDate && formatDateFns(rowData.createDate)) || ''}
                    isFetching={isFetching}
                    skeletonHeight={15}
                    className={classes.flexContainer}
                />
            );
        case 'Last Modified':
            return (
                <TextCell
                    hidden={columns[columnIndex].hidden}
                    output={(rowData?.updateDate && formatDateFns(rowData.updateDate)) || ''}
                    isFetching={isFetching}
                    skeletonHeight={15}
                    className={classes.flexContainer}
                />
            );
        case 'Expires':
            return (
                <TextCell
                    hidden={columns[columnIndex].hidden}
                    output={(rowData?.expirationDate && formatDateFns(rowData.expirationDate)) || ''}
                    isFetching={isFetching}
                    skeletonHeight={15}
                    className={classes.flexContainer}
                />
            );
        case 'Last Used':
            return (
                <TextCell
                    hidden={columns[columnIndex].hidden}
                    output={(rowData?.lastUsed && formatDateFns(rowData.lastUsed)) || ''}
                    isFetching={isFetching}
                    skeletonHeight={15}
                    className={classes.flexContainer}
                />
            );
        case 'Permissions':
            const permissions = rowData.permissionNames;
            const permissionsCount = rowData.permissionNames.length;

            return (
                <TableCell component='div' align='right' className={classes.flexContainer} classes={{ root: `${classes.tableCell}` }}>
                    <PermissionsCell count={permissionsCount} permissions={permissions} />
                </TableCell>
            );
        case 'Status':
            return <StatusCell enableToolTip={!!rowData.title} data={rowData} isFetching={isFetching} skeletonHeight={24} className={classes.flexContainer} />;
        case 'Size':
            return (
                <TextCell
                    hidden={columns[columnIndex].hidden}
                    output={rowData.size ? rowData.size : processFileSize(Number(cellData) || 0, false)}
                    isFetching={isFetching}
                    skeletonHeight={15}
                    className={classes.flexContainer}
                />
            );
        case 'Name':
            return (
                <TextCell
                    output={checkIdCell() || ''}
                    subtitle={processAuthMode(rowData.type as JobTypes) || ' '}
                    isFetching={isFetching}
                    rowData={columns[columnIndex]}
                    hidden={columns[columnIndex].hidden}
                    skeletonHeight={15}
                    className={classes.flexContainer}
                    // tooltipText={rowData}
                />
            );
        case 'Verified':
            return <VerifiedCell isVerified={rowData.verified as boolean} />;

        default:
            return (
                <TextCell
                    output={checkIdCell() || ''}
                    // subtitle={processAuthMode(rowData.type as JobTypes) || ' '}
                    isFetching={isFetching}
                    rowData={columns[columnIndex]}
                    hidden={columns[columnIndex].hidden}
                    skeletonHeight={15}
                    className={classes.flexContainer}
                    // tooltipText={rowData}
                />
            );
    }
};
