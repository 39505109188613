import React from 'react';
import { useSelector } from 'react-redux';
import { Label as LabelIcon } from '@material-ui/icons';
import { GenericHeaderItemProps, EditableHeaderChildProps, EditableHeaderItem, HeaderItem } from '../..';
import { validateJob } from '@mediaseal-webui/utils';
import { InitialState } from '@mediaseal-webui/types/redux';
import { useReadOnlyCheck, useAuth } from '@mediaseal-webui/hooks';

export const TitleHeaderItems = ({ onClick, isFetching, tooltipOpen, stateKey = 'profile' }: GenericHeaderItemProps) => {
    const profile = useSelector(({ entity }: InitialState) => entity[stateKey]);
    const { title, archived } = profile;

    const { isReadOnly } = useReadOnlyCheck(profile, validateJob);

    const auth = useAuth();
    const isEditAuth = auth.edit || auth.administer;

    return (
        <EditableHeaderItem onViewClick={onClick} title={title?.name} disabled={archived || isReadOnly || !isEditAuth} open={tooltipOpen}>
            {({ hovering }: EditableHeaderChildProps) => (
                <HeaderItem hovering={hovering} Icon={'fas fa-tag'} iconType={'fa'} isFetching={isFetching} id='job-title' />
            )}
        </EditableHeaderItem>
    );
};
