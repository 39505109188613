import { IconButton } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const StyledIconButton = styled(IconButton)`
    ${({ theme }) => css`
        display: flex;
        width: 40px;
        height: 40px;
        margin: ${theme.spacing(1)}px;
        opacity: 0.5;
        :hover {
            opacity: 1;
        }
    `}
`;
