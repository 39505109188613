import React from 'react';
import { ToggleSlider } from '@mediaseal-webui/components/src/Toggle/ToggleSlider';
import { useDispatch, useSelector } from 'react-redux';
import { FiMoon, FiSun } from 'react-icons/fi';
import { InitialState } from '@mediaseal-webui/types';
import { SET_THEME } from '@mediaseal-webui/constants/src/settings';

export const ThemeSelection = () => {
    const dispatch = useDispatch();
    const { theme } = useSelector((state: InitialState) => state.settings.misc);
    const handleStatusChange = (value: string) => () => {
        dispatch({ type: SET_THEME, payload: value });
        localStorage.setItem('ms-theme', value);
    };
    return (
        <ToggleSlider
            tooltipOpen={false}
            data={[
                {
                    text: 'Light',
                    value: 'light',
                    id: 'light-mode',
                    setterFn: handleStatusChange('light'),
                    icon: FiSun
                },
                {
                    text: 'Dark',
                    value: 'dark',
                    id: 'dark-mode',
                    setterFn: handleStatusChange('dark'),
                    icon: FiMoon
                }
            ]}
            disabled={false}
            selectedValue={theme}
        />
    );
};
