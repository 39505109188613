import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    viewRoot: {
        [theme.breakpoints.down('xs')]: {
            overflow: 'hidden'
        }
    },
    title: {
        paddingTop: theme.spacing(2),
        fontWeight: 'bold'
    },
    tabs: {
        color: theme.palette.primary.main,
        width: '100%',
        paddingTop: theme.spacing(3)
    },
    tabContent: {
        minHeight: '400px'
    },

    wrapper: {
        display: '',
        height: '100%',
        width: '100%',
        position: 'sticky',
        '& > div': {
            '& > div': {
                '& > div': {
                    paddingTop: theme.spacing(1)
                }
            }
        }
    },

    moduleTitle: {
        fontSize: '1em',
        opacity: 0.5,
        paddingBottom: theme.spacing(2)
    },

    widgetPadding: {
        paddingLeft: theme.spacing(2)
    },

    module: {
        marginLeft: theme.spacing(4),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4)
    }
}));

export default useStyles;
