import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    link: {
        textDecoration: 'none',
        display: 'inline-flex',
        color: '#1d5d90',
        '&:hover': {
            color: '#1d5d90'
        }
    },
    linkIcon: {
        transform: 'rotate(-45deg)',
        marginLeft: theme.spacing(1)
    },
    expansionPanel: {
        boxShadow: 'none'
    },
    noPadding: {
        padding: 0
    },
    capitalize: {
        textTransform: 'uppercase'
    }
}));
