import React from 'react';
import { CircularProgress, TextField } from '@material-ui/core';

export const RenderInput =
    ({ submitting, label, ...others }: { submitting: boolean; label?: string }) =>
    (params) =>
        (
            <TextField
                {...params}
                variant='outlined'
                label={label ?? ''}
                fullWidth
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <>
                            {submitting && <CircularProgress color='inherit' size={20} />} {params.InputProps.endAdornment}
                        </>
                    )
                }}
                {...others}
            />
        );
