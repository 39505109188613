import React from 'react';
import { Checkbox } from '@material-ui/core';
import { CheckBoxHeadingProps } from '.';

const CheckBoxHeading = ({ text, checked, changeFn, disabled = false, ...others }: CheckBoxHeadingProps) => (
    <div>
        <Checkbox disabled={disabled} checked={checked} onChange={changeFn} {...others} />
        {text}
    </div>
);

export default CheckBoxHeading;
