import { useAppSelector } from '@mediaseal-webui/online/src/store';

export const useEncryptorAdministrator = () => {
    const allowedPermissions = useAppSelector((state) => state.permissions).items.map((allowedPermission) => allowedPermission.permissionName);
    const administerPermissions = ['canEditEncryptorGroups', 'canActivateEncryptorGroups', 'canCreateEncryptorGroups', 'canViewEncryptorGroups'];

    const canAdministerEncryptorGroups = administerPermissions.every((elem) => allowedPermissions.indexOf(elem) > -1);

    return canAdministerEncryptorGroups;
};
