import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tabs, Tab } from '@material-ui/core';
import useStyles from './TabArea.styles';
import { TabPanel } from '../../../utility-components/TabPanel/TabPanel';

import { clearSearches } from '../../../../actions/misc';
import { UsersSection } from 'components/table-components/UserSelection';
import { EncryptorGroupSelection } from 'components/table-components/EncryptorGroupSelection';
import { clearSummary } from '@mediaseal-webui/actions';

const TabArea = () => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const dispatch = useDispatch();

    const handleChange = (event, newValue) => {
        dispatch(clearSearches());

        setValue(newValue);
    };

    useEffect(
        () => () => {
            dispatch(clearSummary());
        },
        [dispatch]
    );

    return (
        <div className={classes.root}>
            <Tabs value={value} onChange={handleChange} indicatorColor='primary' textColor='secondary' className={classes.tabs} variant='fullWidth'>
                <Tab label='Users' id='titleUsers' data-testid='titleUsers' value={0} />
                <Tab label='Groups' id='groupsTab' data-testid='titleGroups' value={1} />
            </Tabs>

            <TabPanel value={value} index={0} component={UsersSection} />
            <TabPanel value={value} index={1} component={EncryptorGroupSelection} />
        </div>
    );
};

export default React.memo(TabArea);
