import React, { useEffect, useState } from 'react';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { List, Typography, IconButton, Grid, CircularProgress } from '@material-ui/core';
import { ListItem as FileListItem } from './ListItem';
import useStyles from './FileList.styles';
import { useActiveEncryptionWork, useFilesWithIssues, useGetTotalPercentage } from '@mediaseal-webui/hooks';
import { EncryptFile } from '@mediaseal-webui/types/encryptor';
import { BorderLinearProgress } from './ListItem/ListItem.styles';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
const fileStatusItem = (file: EncryptFile) => {
    if (file.error) {
        return (
            <FileListItem
                key={file.id}
                data={{
                    file,
                    style: { color: '#FF4136', fontSize: '1.0em' },
                    secondaryText: file.errorMessage || ''
                }}
            />
        );
    }
    if (file.cancelled) {
        return (
            <FileListItem
                key={file.id}
                data={{
                    file,
                    style: { color: '#FF4136', fontSize: '1.0em' },
                    secondaryText: 'Cancelled'
                }}
            />
        );
    }
    if (file.done) {
        return (
            <FileListItem
                key={file.id}
                data={{
                    file,
                    style: { color: '#2ECC40', fontSize: '1.0em' },
                    secondaryText: 'Completed'
                }}
            />
        );
    }
    if (file.paused) {
        return (
            <FileListItem
                key={file.id}
                data={{
                    file,
                    style: { color: '#0074D9', fontSize: '1.0em' },
                    secondaryText: 'Paused'
                }}
            />
        );
    }
    if (file.started) {
        return (
            <FileListItem
                key={file.id}
                data={{
                    file,
                    style: { color: '', fontSize: '1.0em' },
                    secondaryText: 'Encrypting'
                }}
            />
        );
    }
    return (
        <FileListItem
            key={file.id}
            data={{
                file,
                style: { color: '', fontSize: '1.0em' },
                secondaryText: 'Waiting'
            }}
        />
    );
};

export const FileList = () => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [activeList, setActiveList] = useState<EncryptFile[]>([] as EncryptFile[]);
    const {
        activeJobWork: { fileList }
    } = useActiveEncryptionWork();
    const { percentage } = useGetTotalPercentage();
    const classes = useStyles();
    const filesWithIssues = useFilesWithIssues();

    useEffect(() => {
        setActiveList(fileList ? fileList.filter((file) => file.started === true) : ([] as EncryptFile[]));

        if (percentage === 100 && filesWithIssues.length === 0 && activeList.length === 0) {
            const cancelledFiles = fileList.filter((file) => file.cancelled).length;
            const messageSuffix = cancelledFiles > 0 ? ' : ' + cancelledFiles + ' files cancelled' : '';
            dispatch({
                type: 'SET_SNACKBAR',
                payload: {
                    success: true,
                    message: `Encryption process completed!` + messageSuffix
                }
            });
            let timeout = setTimeout(() => {
                if (filesWithIssues.length > 0) {
                    return;
                } else if (percentage === 100 && filesWithIssues.length === 0 && activeList.length === 0) {
                    dispatch({ type: 'CLEAR_ENCRYPTION_WORK' });
                    clearTimeout(timeout);
                    console.log('fileList cleared');
                }
            }, 1000);
        }
        // moved out of the above block
    }, [fileList, percentage]);

    const handleClick = () => {
        setShowList(!showList);
    };

    const allFilesCancelled = () => fileList.every((file) => file.cancelled);

    return (
        <div className={classes.fileList}>
            <List classes={{ root: classes.root }}>
                <Grid alignItems='center' container>
                    <Grid item xs={11} className={classes.textContainer}>
                        {!fileList || fileList.every((file) => file.done) ? (
                            <Typography color='textPrimary' className={classes.summary} variant='h6'>
                                Drop files / folders to begin encryption
                            </Typography>
                        ) : (
                            <Typography color='textPrimary' className={classes.summary} variant='h6'>
                                <CircularProgress size={24} />
                                <span style={{ margin: '0 5px' }}> Encrypting...</span>
                            </Typography>
                        )}
                        <Typography color='textPrimary' className={classes.subtitle} variant='subtitle2'>
                            {fileList && fileList.filter((file) => file.done && !file.cancelled).length > 0 && (
                                <span className={`${classes.finishedItems} ${classes.items}`}>
                                    <DoneIcon className={classes.finishIcon} />
                                    {`${fileList.filter((file) => file.done && !file.cancelled).length} files completed`}
                                </span>
                            )}
                            {fileList && fileList.filter((file) => !file.done && !file.cancelled).length > 0 && (
                                <span className={`${classes.inProcessItems} ${classes.items}`}>
                                    {`${fileList.filter((file) => !file.done && !file.cancelled).length} files in process`}
                                </span>
                            )}
                            {fileList && fileList.filter((file) => file.cancelled).length > 0 && (
                                <span className={`${classes.cancelledItems} ${classes.items}`}>
                                    <CloseIcon color='error' className={classes.closeIcon} />
                                    {`${fileList.filter((file) => file.cancelled).length} files cancelled`}
                                </span>
                            )}
                            {fileList && fileList.length > 0 && !allFilesCancelled() ? `${percentage}%` : '0%'}
                        </Typography>
                    </Grid>
                    <Grid className={classes.iconContainer} item xs={1}>
                        <IconButton onClick={handleClick}>{showList ? <ExpandLess /> : <ExpandMore />}</IconButton>
                    </Grid>

                    <Grid item xs={12}>
                        <BorderLinearProgress className={classes.margin} variant='determinate' color='secondary' value={percentage || 0} />
                    </Grid>
                </Grid>
                {showList
                    ? activeList &&
                      activeList.length > 0 &&
                      activeList
                          .sort((first, second) => {
                              if (first && second) {
                                  return second?.percentage - first?.percentage;
                              } else {
                                  return 0;
                              }
                          })
                          .map((file) => fileStatusItem(file))
                    : null}
            </List>
        </div>
    );
};
