import React from 'react';
import clsx from 'clsx';
import { CircularProgress } from '@material-ui/core';
import { MdArrowDownward as DownArrow, MdCheck as Check } from 'react-icons/md';
import { GiFilmStrip } from 'react-icons/gi';
import { ConfirmDialogIconProps } from '../..';
import useStyles from './AnimatedArchiveIcon.style';
import useSharedStyles from '../sharedStyles';

export const AnimatedArchiveIcon = ({ loading, complete }: Omit<ConfirmDialogIconProps, 'icon'>) => {
    const shared = useSharedStyles();
    const classes = useStyles();
    return (
        <div className={clsx(shared.iconRoot, { loading, complete })}>
            {!loading && (
                <div className={classes.archiveFilmStrip}>
                    <div>
                        <GiFilmStrip />
                        <GiFilmStrip />
                        <GiFilmStrip />
                    </div>
                </div>
            )}
            <svg viewBox='0 0 100 100' className={classes.archiveBox}>
                <rect width='100' height='100' rx='2' />
                <rect width='90' height='90' x='5' y='5' fill='#fff' />
            </svg>
            <svg viewBox='0 0 120 25' className={clsx(classes.archiveLid, { loading })}>
                <rect width='120' height='25' rx='2' />
                <rect width='110' height='15' x='5' y='5' fill='#fff' />
            </svg>
            {!complete ? (
                !loading ? (
                    <DownArrow className={classes.archiveDownArrow} />
                ) : (
                    <CircularProgress size={12} className={classes.archiveLoading} />
                )
            ) : (
                <Check className={classes.archiveComplete} />
            )}
        </div>
    );
};
