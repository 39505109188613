import React, { useCallback } from 'react';
import { useCopyToClipboard } from 'react-use';
import clsx from 'clsx';
import { AiOutlineCopy } from 'react-icons/ai';
import { IconButton } from '@material-ui/core';
import useStyles from '../EditableEntityTitle/EditableEntityTitle.styles';
import { useSnackbar } from 'notistack';

enum CopiedState {
    Copied = 'Already copied',
    NotCopied = 'Copied'
}

type Copied = CopiedState.Copied | CopiedState.NotCopied;

interface CopyButtonProps {
    /**
     * value to be copied to clipboard
     */
    value: string;
    /**
     * optional flag for custom styling
     */
    classes?: {
        root: string;
    };
}

const CopyButton: React.FC<CopyButtonProps> = ({ value }) => {
    const [state, copyToClipboard] = useCopyToClipboard();
    const styles = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const classNames = clsx(styles.actionButtons, styles.editIcon, 'copyButton');

    const handleSnackBar = useCallback(
        (msg: Copied) => {
            enqueueSnackbar(msg, {
                variant: msg === CopiedState.Copied ? 'default' : 'info',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            });
        },

        [enqueueSnackbar]
    );

    const handleCopy = useCallback(() => {
        if (state.value === undefined) {
            handleSnackBar(CopiedState.NotCopied);
            copyToClipboard(value);
        }

        if (state.value !== undefined) {
            handleSnackBar(CopiedState.Copied);
        }
    }, [copyToClipboard, handleSnackBar, state.value, value]);

    return (
        <>
            <IconButton data-testid='copy-button' onClick={handleCopy} size='small' className={classNames}>
                <AiOutlineCopy />
            </IconButton>
        </>
    );
};

export { CopyButton };
