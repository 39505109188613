import { Pass, Fail } from '..';

/**
 * Validates that a value is an email address
 *
 * @param () Function from redux to access state
 * @param value String
 * @return { passes: Bool, message: String? }
 */
export const email = () => (value: string) => {
    const emailRegex = /^^[a-z0-9!#$%&'*/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    if (!value) {
        return Fail('Email is required');
    }
    if (!emailRegex.test(value)) {
        return Fail('Invalid email address');
    }
    return Pass;
};

export const emailCheck = email;
