import { BaseService, BaseServiceParams } from '../BaseService/BaseService';
import { AxiosRequestConfig } from 'axios';
import { MediaSealDTO, ReportingQueries } from '@mediaseal-webui/types';
// import { SortStrings } from '@mediaseal-webui/types/generic'
import { subDays } from 'date-fns';

export interface EncryptorAuditParams extends BaseServiceParams {
    action: ReportingQueries;
    startDate: string;
    endDate: string;
    userId?: string;
    tokenId?: number | string;
}
export interface DecryptorAuditParams extends BaseServiceParams {
    startDate: string;
    endDate: string;
    access: ReportingQueries;
    userId: number | string;
    fileId?: string;
}

type AuditParams = EncryptorAuditParams | DecryptorAuditParams;
export class AuditService extends BaseService<MediaSealDTO, {}> {
    auditParams: AuditParams;
    isDecryptor = true;
    decryptorParams: DecryptorAuditParams = {
        ...this.baseParams,
        startDate: subDays(new Date(), 365).toISOString(),
        endDate: new Date().toISOString(),
        sort: 'id,desc',
        access: 'ANY',
        userId: ''
    };
    encryptorParams: EncryptorAuditParams = {
        ...this.baseParams,
        startDate: subDays(new Date(), 365).toISOString(),
        endDate: new Date().toISOString(),
        sort: 'id,desc',
        action: 'CREATE'
    };

    constructor(public url: string, public config?: AxiosRequestConfig) {
        super(url, config);
        this.isDecryptor = url.includes('fileAccess');
        this.auditParams = this.isDecryptor ? (this.decryptorParams as DecryptorAuditParams) : (this.encryptorParams as EncryptorAuditParams);
    }

    public async getAudits() {
        return await this.get(this._createQueryString(this.auditParams));
    }

    public setParams(params: Partial<AuditParams>): void {
        if (this.isDecryptor) {
            const { action, ...encParams } = this.auditParams as EncryptorAuditParams;
            this.auditParams = {
                ...encParams,
                ...params
            } as DecryptorAuditParams;
        } else {
            const { access, userId, ...decParams } = this.auditParams as DecryptorAuditParams;
            this.auditParams = {
                ...decParams,
                ...params
            } as EncryptorAuditParams;
        }
    }
}
