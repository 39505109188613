import { debounce } from '@material-ui/core';
import { useContext, createRef, useEffect, useCallback, useState } from 'react';
import { TabControlContext, TabHandler } from './context';
export * from './context';

/**
 * @arg args -- string names to be applied to each tab
 */

interface ITabControl {
    tabContext: TabHandler[];
    cardRef: (node: HTMLDivElement) => void;
}

export const useTabControl = (tabSetter: React.Dispatch<React.SetStateAction<number>>, args: string[]): ITabControl => {
    const { tabContext, setTabContext } = useContext(TabControlContext);
    const [cardRef, setCardRef] = useState<HTMLDivElement | null>(null);

    const cardCb = useCallback((node: HTMLDivElement) => {
        if (node !== null) {
            setCardRef(node);
        }
    }, []);

    const handleScroll = () =>
        setTimeout(() => {
            tabContext.forEach(({ ref }, index) => {
                //@ts-ignore
                if (ref && ref.current) {
                    //@ts-ignore
                    const { top } = ref.current.getBoundingClientRect();
                    if (top <= 300) {
                        tabSetter(index);
                    }
                }
            });
        }, 150);
    // }, 150)()

    useEffect(() => {
        if (cardRef) {
            cardRef.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (cardRef) {
                cardRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [tabContext, cardRef]);

    useEffect(() => {
        args.forEach((tab) => {
            const ref = createRef<HTMLDivElement>();

            const handler = () => {
                if (ref && ref.current) {
                    ref.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    });
                }
            };
            setTabContext((prev) => [
                ...prev,
                {
                    tab,
                    ref,
                    handler
                }
            ]);
            return () => {
                setTabContext([]);
                setCardRef(null);
            };
        });
    }, []);

    return { tabContext, cardRef: cardCb };
};
