import React from 'react';
import { EditableHeaderItem } from '../../EditableHeaderItem';
import { HeaderItem } from '../HeaderItem';
import { AiFillFolder } from 'react-icons/ai';
import { GenericHeaderItemProps } from '..';

export const LiveFolderHeaderItem = ({ tooltipOpen }: GenericHeaderItemProps) => {
    const folder = () => <AiFillFolder size={24} />;
    return (
        <EditableHeaderItem title='Live Folders Enabled' open={tooltipOpen}>
            {() => <HeaderItem id='live-folder-item' hovering Icon={folder} />}
        </EditableHeaderItem>
    );
};
