import React from 'react';
import { Paper } from '@material-ui/core';
import { useSideNav } from '@mediaseal-webui/hooks';
import clsx from 'clsx';
import useStyles from './Content.styles';
import { ContentProps } from '.';

export const Content = ({ children, classes = {}, ...others }: ContentProps) => {
    const clarses = useStyles();
    const { sideNavOpen } = useSideNav();

    const { content } = classes;

    return (
        <Paper
            {...others}
            elevation={0}
            className={clsx(content, clarses.content, {
                [clarses.sidebarOpen]: sideNavOpen
            })}>
            {children(clarses)}
        </Paper>
    );
};
