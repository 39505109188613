import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography, FormGroup, FormLabel } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { PasswordInput, TextInput } from '@mediaseal-webui/components';
import { setCreate } from '@mediaseal-webui/actions';
import { useEntityCreation } from '@mediaseal-webui/hooks';
import useStyles from './EncryptorUserFormStyle.styles';
import { EncryptorGroupSelection } from 'components/table-components/EncryptorGroupSelection';

const EncryptorUserForm = ({ handleSubmit }) => {
    const classes = useStyles();
    const { passwordValidation, confirmPassword, emailValidation, loginNameValidation, lastNameValidation, firstNameValidation } = useEntityCreation([
        'firstName',
        'lastName',
        'loginName',
        'password',
        'confirmPassword',
        'email'
    ]);
    const dispatch = useDispatch();
    const changeHandler = (field) => (value) => dispatch(setCreate({ [field]: value }));
    const handleChange = useCallback(changeHandler, [dispatch]);

    return (
        <form className={classes.root}>
            <FormGroup>
                <Typography color='textPrimary' variant='h5'>
                    User Details
                </Typography>
                <Typography className={classes.subtitle} variant='body1' color='textSecondary'>
                    Enter a login name, first and last names for the new user
                </Typography>

                <FormLabel color='primary' className={classes.label}>
                    Login Name
                </FormLabel>
                <TextInput
                    color='textPrimary'
                    id='loginName'
                    data-testid='loginName'
                    setterFn={handleChange('loginName')}
                    validation={loginNameValidation}
                    tooltipText='Please enter a login name between 2 and 64 characters'
                />

                <FormLabel color='primary' className={classes.label}>
                    First Name
                </FormLabel>
                <TextInput
                    id='firstName'
                    data-testid='firstName'
                    setterFn={handleChange('firstName')}
                    validation={firstNameValidation}
                    tooltipText='Please enter a first name between 2 and 64 characters'
                />

                <FormLabel color='primary' className={classes.label}>
                    Last Name
                </FormLabel>
                <TextInput
                    id='lastName'
                    data-testid='lastName'
                    setterFn={handleChange('lastName')}
                    validation={lastNameValidation}
                    tooltipText='Please enter a last name between 2 and 64 characters'
                />
            </FormGroup>

            <FormGroup>
                <Typography color='textPrimary' className={classes.title} variant='h5'>
                    Password
                </Typography>
                <Typography className={classes.subtitle} variant='body1' color='textSecondary'>
                    Enter a password for the user
                </Typography>
                <PasswordInput id='createPassword' data-testid='createPassword' validation={passwordValidation} setterFn={handleChange('password')} />

                <br />

                <PasswordInput
                    id='confirmPassword'
                    data-testid='confirmPassword'
                    setterFn={handleChange('confirmPassword')}
                    tooltipText='Please ensure both passwords match'
                    validation={confirmPassword}
                />
            </FormGroup>

            <FormGroup>
                <Typography color='textPrimary' className={classes.title} variant='h5'>
                    Contact Details
                </Typography>
                <Typography className={classes.subtitle} variant='body1' color='textSecondary'>
                    Enter the contact details of the new user
                </Typography>

                <FormLabel color='primary' className={classes.label}>
                    Email Address
                </FormLabel>
                <TextInput
                    type='email'
                    id='emailAddress'
                    data-testid='emailAddress'
                    validation={emailValidation}
                    tooltipText='Please enter a valid email address'
                    setterFn={handleChange('emailAddress')}
                />

                <FormLabel color='primary' className={classes.label}>
                    Telephone Number (optional)
                </FormLabel>
                <TextInput type='number' id='contactNumber' data-testid='contactNumber' setterFn={handleChange('contactNumber')} maxLength={11} />
            </FormGroup>

            <FormGroup className={classes.tableContainer}>
                <Typography color='textPrimary' className={classes.subtitle} variant='h5'>
                    Encryptor Groups
                </Typography>
                <Typography className={classes.subtitle} variant='body1' color='textSecondary'>
                    Assign new user to encryptor groups, these groups will determine the users permissions
                </Typography>
                <EncryptorGroupSelection classes={classes} />
            </FormGroup>
        </form>
    );
};

EncryptorUserForm.propTypes = {
    handleSubmit: PropTypes.func
};

export default EncryptorUserForm;
