import { ConfirmDialogIconKey } from '@mediaseal-webui/types/generic';
import ConfirmDialogue from './ConfirmDialogue';

export interface ConfirmDialogIconProps {
    icon: ConfirmDialogIconKey;
    loading?: boolean;
    complete?: boolean;
}

export interface ConfirmDialogueProps {
    /** The title to display */
    title?: string;
    /** Text whilst in loading state */
    loadingText?: string;
    /** The icon to display with the title */
    icon?: ConfirmDialogIconKey;
    /** The message to display */
    message?: string;
    /** Redux action to call once confirm is clicked */
    action?: any;
    /** Whether confirm is visible */
    open?: boolean;
    /** Sets whether dialogue is visible */
    setOpen?: (open: boolean) => void;
    /** Optional text for the confirm button */
    confirmButtonText?: string;
}

export default ConfirmDialogue;
