import styled from 'styled-components';
import { fadeIn } from '../../Button';

export const StyledDialogue = styled.div`
    &:before {
        content: ' ';
        width: 100vw;
        height: 100vh;
        background: #000000;
        opacity: 0.7;
        z-index: 10001;
        left: 0;
        top: 0;
        position: fixed;
    }
`;

export const StyledContainer = styled.div`
    width: 40%;
    height: 300px;
    border-radius: 20px;
    background: white;
    position: fixed;
    top: calc(50% - 150px);
    left: 0;
    right: 0;
    opacity: 0;
    animation: ${fadeIn} 0.5s ease;
    animation-fill-mode: forwards;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    z-index: 10002;
    flex-direction: column;
    padding: 32px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    position: absolute;
    bottom: 50px;
    justify-content: center;
    flex-direction: row;
`;

export const StyledButton = styled.button(
    ({ theme }) => `
    width: 150px;
    height: 50px;
    outline: none;
    border: none; 
    background: ${theme.palette.primary.main};
    color: white;
    border-radius: 25px;
    &:hover {
        cursor: pointer;
    }
`
);

export const NoButton = styled.button(
    ({ theme }) => `
width: 150px;
height: 40px;
outline: none;
border: none; 
background: none;
color: ${theme.palette.grey[300]};
border-radius: 10px;
&:hover {
    cursor: pointer;
    color: ${theme.palette.grey[600]};
}
`
);

export const StyledDialogueText = styled.p`
    font-size: 12pt;
    text-align: center;
`;
export const StyledDialogueHeader = styled.h2`
    font-size: 18pt;
    text-align: center;
`;
