import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300
    },
    popper: {
        zIndex: 10001
    },
    dialogue: {
        padding: theme.spacing(2)
    }
}));
