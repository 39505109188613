import React, { useEffect, useState } from 'react';
import useStyles, { ExpansionSection, StyledButtonItems, StyledButtonRoot, StyledTooltip } from './SelectableBtn.styles';
import { Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import clsx from 'clsx';
// import Title from '../../../views/Titles/Title';

interface SelectableBtnProps {
    title: string;
    subtitle?: string;
    Icon: React.ElementType;
    setterFn: (args: any) => void;
    disabled?: boolean;
    width?: number | string;
    component: React.ElementType | null;
    expandable?: boolean;
    tooltipLabel?: string;
    deselect?: boolean;
    id?: string;
}

const SelectableBtn = ({
    title,
    subtitle,
    Icon,
    setterFn,
    disabled,
    width = '100%',
    component,
    expandable,
    tooltipLabel,
    deselect,
    id
}: SelectableBtnProps) => {
    const [selected, setSelected] = useState<boolean>(false);
    const classes = useStyles({ width });
    const Component = component;

    const handleClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        if (disabled) return;
        setSelected(!selected);
        setterFn(!selected);
    };

    useEffect(() => {
        if (deselect && selected) {
            setSelected(false);
            setterFn(false);
        }
    }, [deselect]);

    const Text = () => (
        <div className={classes.titleContainer}>
            <Typography color='textPrimary' className={classes.btnTitle} variant='body2'>
                {title}
            </Typography>
            <Typography color='textPrimary' className={classes.btnSubtitle} variant='subtitle1'>
                {subtitle}
            </Typography>
        </div>
    );

    const determineIcon = () =>
        selected ? (
            <DoneIcon color='primary' className={expandable ? classes.doneIcon : classes.icon} />
        ) : (
            <AddIcon className={`${classes.icon} ${classes.endIcon}`} />
        );

    return (
        <StyledTooltip arrow title={tooltipLabel || ''} placement='top'>
            <StyledButtonRoot
                id={id}
                open={selected}
                className={selected ? 'active' : disabled ? 'disabled' : ''}
                data-testid='selectableContainer'
                disabled={disabled}>
                <StyledButtonItems onClick={handleClick}>
                    <Icon
                        className={clsx(classes.icon, {
                            [classes.expandedIcon]: selected,
                            [classes.optionsIcon]: !selected,
                            [classes.disabled]: disabled
                        })}
                    />
                    <Text />
                    {determineIcon()}
                </StyledButtonItems>
                {expandable && <ExpansionSection open={selected}>{Component ? <Component /> : <div />}</ExpansionSection>}
            </StyledButtonRoot>
        </StyledTooltip>
    );
};

export default SelectableBtn;
