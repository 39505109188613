import { EncryptFileWithIssues } from '@mediaseal-webui/types/encryptor';
import { AnyAction } from 'redux';

export const cancelFileWithIssue = (file: EncryptFileWithIssues): AnyAction => {
    if (window.qt) {
        console.log(`calling cancelFileWithIssue for file ID: ${file.id}`);
        window.EncryptionWebInterface.cancelEncryptionWork(file.id);
    }
    return { type: '' };
};
