import Widget from './Widget';

export default Widget;

export interface WidgetProps {
    title: string;
    children: React.ReactNode;
    open: boolean;
    className: string;
    openable: boolean;
    showSettings: boolean;
    width: number | string;
    height: number | string;
    padding: number;
}
