import { definitions, SpringBootResponse } from '@mediaseal-webui/types/api';
import { AxiosRequestConfig } from 'axios';
import { BaseService } from '../BaseService/BaseService';

export class TitleService extends BaseService<SpringBootResponse<definitions['Title'][]>, {}> {
    constructor(public config?: AxiosRequestConfig) {
        super('/titles', config);
    }

    public async fetchTitles() {
        return await this.get(`/search/findAllContainingKeyword${this._createQueryString(this.baseParams)}`);
    }

    public async searchTitles(keyword: string) {
        this.baseParams = {
            ...this.baseParams,
            keyword
        };
        return await this.fetchTitles();
    }
    public async fetchUsersByTitle(id: string) {
        return await this.get(`/${id}/allowedUsers`);
    }
    public async fetchGroupsByTitle(id: string) {
        return await this.get(`/${id}/allowedGroups`);
    }

    public async fetchTitle(id: string) {
        const users = this.fetchUsersByTitle(id);
        const groups = this.fetchGroupsByTitle(id);
        const title = this.get(`/${id}`);

        return Promise.all([title, groups, users]);
    }
}
