import React, { useCallback, useMemo } from 'react';
import { Tooltip } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { StyledIconButton } from './index.styles';
import { IdentityProviderService } from '@mediaseal-webui/services';
import { useSnackbar } from 'notistack';
import { useIdentityProvider } from 'hooks/useIdentityProviders';
import { useAppDispatch } from 'store';
import { setConfirmDialogue } from '@mediaseal-webui/actions';

export const DeleteBtn = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { resetData } = useIdentityProvider();
    const dispatch = useAppDispatch();
    const service = useMemo(() => new IdentityProviderService(), []);
    const handleDelete = useCallback(async () => {
        // handle DELETE req
        try {
            await service.removeProvider();
            resetData();
            enqueueSnackbar('Successfully removed identity provider', {
                variant: 'success'
            });
        } catch (error) {
            enqueueSnackbar(error.response.data.errors[0] ?? 'Failed to remove identity provider', {
                variant: 'error'
            });
        }
    }, [service, enqueueSnackbar, resetData]);
    const deleteFn = useCallback(() => {
        dispatch(
            setConfirmDialogue({
                icon: 'delete',
                title: 'Delete Identity Provider',
                confirmButtonText: 'Delete Provider',
                message: 'Are you sure you want to delete this identity provider? Existing imported users will no longer be able to connect to the server.',
                action: handleDelete
            })
        );
    }, [dispatch, handleDelete]);
    return (
        <Tooltip placement='top' title='Remove Identity Provider'>
            <StyledIconButton onClick={deleteFn}>
                <Delete role='button' data-testid='delete-btn' />
            </StyledIconButton>
        </Tooltip>
    );
};
