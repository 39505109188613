import { combineReducers } from 'redux';
import {
    REMOVE_DEC_GROUP_FROM_SUMMARY,
    ADD_DEC_GROUP_TO_SUMMARY,
    FETCH_GROUPS,
    SET_FILTERED_GROUPS,
    CLEAR_GROUPS,
    REMOVE_RECIPIENT_GROUP,
    REMOVE_SELECTED_GROUPS,
    SET_GROUP_PAGE_COUNT,
    SET_GROUP_PAGE_SEARCH_VALUE,
    CLEAR_GROUP_PAGE_SEARCH_VALUE,
    SET_FILTERED_ENC_GROUPS,
    FETCH_ENC_GROUPS,
    REMOVE_ENC_GROUP,
    REMOVE_GROUP_EDIT_TITLE_TABLE,
    FILTER_USERS_AND_GROUPS,
    REMOVE_USER_GROUP,
    SET_ENCRYPTOR_GROUP_PAGE_COUNT,
    SET_TOTAL_DEC_GROUPS,
    SET_TOTAL_ENC_GROUPS,
    FETCH_PERMISSIONS,
    CLEAR_PERMISSION_DATA,
    SET_UNSERIALIZED_PERMISSIONS,
    SET_PERMISSION_STATUS,
    FILTER_DECRYPTOR_GROUP_ASSIGNED_TO_ENTITY,
    FILTER_ENCRYPTOR_GROUP_ASSIGNED_TO_ENTITY,
    ADD_ENC_GROUP_TO_SUMMARY,
    REMOVE_ENC_GROUP_FROM_SUMMARY
} from '../../constants';
import { CLEAR_SEARCHES } from '../../constants/misc';
import { SELECT_DEC_GROUP, DESELECT_DEC_GROUP, CANCEL_SELECTION, SUBMIT_SELECTION, SELECT_ENC_GROUP, DESELECT_ENC_GROUP } from '../../constants/entity';

const filteredGroups = (filteredDecGroups = [], { type, payload }) => {
    switch (type) {
        case FETCH_GROUPS:
            return [...filteredDecGroups, ...payload];
        case SET_FILTERED_GROUPS:
            return [...payload];
        case CLEAR_GROUPS:
            return [];
        case REMOVE_RECIPIENT_GROUP:
        case REMOVE_SELECTED_GROUPS:
        case ADD_DEC_GROUP_TO_SUMMARY:
            return filteredDecGroups.filter((group) => group.id !== payload.id);
        case FILTER_DECRYPTOR_GROUP_ASSIGNED_TO_ENTITY:
            return filteredDecGroups.filter((group) => group.id !== payload.id);
        case REMOVE_DEC_GROUP_FROM_SUMMARY:
            return [...filteredDecGroups, payload];
        case SELECT_DEC_GROUP:
            return filteredDecGroups.map((group) => {
                if (group.id === payload) {
                    return { ...group, selected: true };
                }
                return group;
            });
        case DESELECT_DEC_GROUP:
            return filteredDecGroups.map((group) => {
                if (group.id === payload) {
                    return { ...group, selected: false };
                }
                return group;
            });
        case CANCEL_SELECTION:
        case SUBMIT_SELECTION:
            return filteredDecGroups.map((group) => ({ ...group, selected: false }));
        default:
            return filteredDecGroups;
    }
};

const totalDecryptorGroupPages = (groupPages = 0, { type, payload }) => {
    switch (type) {
        case SET_GROUP_PAGE_COUNT:
            return payload;
        default:
            return groupPages;
    }
};

const groupPageSearchValue = (value = '', { type, payload }) => {
    switch (type) {
        case SET_GROUP_PAGE_SEARCH_VALUE:
            return payload;
        case CLEAR_GROUP_PAGE_SEARCH_VALUE:
        case CLEAR_SEARCHES:
            return '';
        default:
            return value;
    }
};

const totalPages = (pages = 0, { type, payload }) => {
    switch (type) {
        case SET_GROUP_PAGE_COUNT:
            return payload;
        default:
            return pages;
    }
};

const encryptorGroups = (encGroups = [], { type, payload }) => {
    switch (type) {
        case SET_FILTERED_ENC_GROUPS:
            return [...payload];
        case FETCH_ENC_GROUPS:
            return [...encGroups, ...payload];
        case CLEAR_GROUPS:
            return [];
        case REMOVE_ENC_GROUP:
        case REMOVE_GROUP_EDIT_TITLE_TABLE:
        case FILTER_USERS_AND_GROUPS:
        case ADD_ENC_GROUP_TO_SUMMARY:
            return encGroups.filter((group) => group.id !== payload.id);
        case FILTER_ENCRYPTOR_GROUP_ASSIGNED_TO_ENTITY:
            return encGroups.filter((group) => (group.id !== payload.id ? group : null));
        case REMOVE_USER_GROUP:
            return [...encGroups, payload];
        case REMOVE_ENC_GROUP_FROM_SUMMARY:
            return [...encGroups, payload];
        case SELECT_ENC_GROUP:
            return encGroups.map((group) => {
                if (group.id === payload) {
                    return { ...group, selected: true };
                }
                return group;
            });
        case DESELECT_ENC_GROUP:
            return encGroups.map((group) => {
                if (group.id === payload) {
                    return { ...group, selected: false };
                }
                return group;
            });
        case CANCEL_SELECTION:
        case SUBMIT_SELECTION:
            return encGroups.map((group) => ({ ...group, selected: false }));
        default:
            return encGroups;
    }
};

const totalEncyptorGroupPages = (totalPages = 0, { type, payload }) => {
    switch (type) {
        case SET_ENCRYPTOR_GROUP_PAGE_COUNT:
            return payload;
        default:
            return totalPages;
    }
};

const totalDecGroups = (state = 0, { type, payload }) => {
    switch (type) {
        case SET_TOTAL_DEC_GROUPS:
            return payload;
        default:
            return state;
    }
};

const totalEncGroups = (state = 0, { type, payload }) => {
    switch (type) {
        case SET_TOTAL_ENC_GROUPS:
            return payload;
        default:
            return state;
    }
};

const initialPermissions = {
    jobsTitles: [],
    encryptor: [],
    decryptor: [],
    reporting: [],
    settings: []
};
const permissions = (state = initialPermissions, { type, payload }) => {
    switch (type) {
        case FETCH_PERMISSIONS:
            return {
                ...state,
                ...payload
            };
        case 'UPDATE_PERMISSIONS':
            return {
                ...state,
                [payload.stateKey]: [...state[payload.stateKey].filter((item) => item.label !== payload.item.label), payload.item]
            };
        case CLEAR_PERMISSION_DATA:
            return {};
        default:
            return state;
    }
};

const unserializedPermissions = (state = [], { type, payload }) => {
    switch (type) {
        case SET_UNSERIALIZED_PERMISSIONS:
            return [...payload];
        case SET_PERMISSION_STATUS:
            return state.map((item) => {
                if (item.id === payload.id) {
                    item.checked = payload.checked;
                }
                return item;
            });
        case CLEAR_PERMISSION_DATA:
            return [];
        default:
            return state;
    }
};

export default combineReducers({
    filteredGroups,
    totalDecryptorGroupPages,
    encryptorGroups,
    totalEncyptorGroupPages,
    totalPages,
    groupPageSearchValue,
    totalDecGroups,
    totalEncGroups,
    permissions,
    unserializedPermissions
});
