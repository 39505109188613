import { combineReducers } from 'redux';

const loginName = (state = '', { type, payload }) => {
    switch (type) {
        case 'SET_USER_LOGIN_NAME_VALUE':
            return payload;
        default:
            return state;
    }
};

const studioName = (state = '', { type, payload }) => {
    switch (type) {
        case 'SET_STUDIO_NAME':
            return payload;

        default:
            return state;
    }
};

export default combineReducers({
    loginName,
    studioName
});
