import React from 'react';
import { definitions } from '@mediaseal-webui/types/api';
import { createContext, useState } from 'react';

interface IAuditFileContext {
    file: definitions['File'] | null;
    setFile: React.Dispatch<React.SetStateAction<definitions['File'] | null>>;
}
export const AuditFileContext = createContext<IAuditFileContext>({
    file: null,
    setFile: () => null
});

export const AuditFileContextProvider = ({ children }) => {
    const [file, setFile] = useState<definitions['File'] | null>(null);
    return <AuditFileContext.Provider value={{ file, setFile }}>{children}</AuditFileContext.Provider>;
};
