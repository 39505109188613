import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Tooltip } from '@material-ui/core';
import { Form } from '@mediaseal-webui/components/src/Form';
import { CopyButton, TextInput } from '@mediaseal-webui/components';
import FormGroup from '@mediaseal-webui/components/src/FormGroup';
import { useHistory } from 'react-router';

interface ConfirmationModalProps {
    tokenId: string;
    isOpen: boolean;
    onClose: () => void;
}

const ConfirmationModal = ({ tokenId, isOpen, onClose }: ConfirmationModalProps): JSX.Element => {
    const history = useHistory();

    const handleClose = () => {
        onClose();
        history.push('/tokens');
    };
    return (
        <Dialog data-testid='tokenid-dialog' role='dialog' open={isOpen}>
            <DialogTitle>Token Created</DialogTitle>
            <DialogContent>
                <Form role='form'>
                    <FormGroup heading='Token ID:'>
                        <Tooltip title={'Token Id'} placement='top'>
                            <div style={{ display: 'flex' }}>
                                <TextInput disabled data-testid='token-id' role='input' name='token-id' value={tokenId} label='token id' />
                                <CopyButton value={tokenId} />
                            </div>
                        </Tooltip>
                    </FormGroup>
                </Form>
                <DialogContentText style={{ marginTop: 25 }}>
                    This token is secret and will not be accesible after this window is closed. Ensure you have saved it and if lost, you must create a new
                    token.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};
export { ConfirmationModal };
