import { BaseService } from '../BaseService/BaseService';

import { AxiosRequestConfig } from 'axios';
import { definitions } from '@mediaseal-webui/types/api';
import { SpringBootResponse } from '@mediaseal-webui/types';
export class RecipientService extends BaseService<SpringBootResponse<definitions['Recipient']>, {}> {
    constructor(public config?: AxiosRequestConfig) {
        super('/recipients', config);
    }
    public async fetchRecipients() {
        try {
            return await this.get(`/search/findAllContainingKeyword${this._createQueryString(this.baseParams)}`);
        } catch (error) {
            console.error(error);
        }
    }

    public async searchRecipients(keyword: string) {
        try {
            this.baseParams = {
                ...this.baseParams,
                keyword
            };
            return await this.fetchRecipients();
        } catch (error) {
            console.error(error);
        }
    }
}
