import { ThunkResult } from '@mediaseal-webui/types/redux';
import { RootState } from 'packages/online/src/store';
/**
 *
 * @returns void
 *
 * Used to send a cancel signal to QT -> cancels the file
 */
export const cancelEncryptionJob = (): ThunkResult<void> => (dispatch, getState: () => RootState) => {
    const { issues, encryptionWork } = getState().encryptor;
    const { activeJobId } = getState().jobs;
    const currentJob = encryptionWork.find((work) => work.jobData.id === Number(activeJobId));
    const ids = currentJob?.fileList.concat(issues ?? []).map((file) => file.id);
    if (window.qt) {
        window.EncryptionWebInterface.cancelEncryptionWorkList(ids || ['']);
        window.EncryptionWebInterface.clearDragDropBlock();
    }
    return { type: '' };
};
