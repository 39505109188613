import React, { useEffect, useMemo, useRef } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { RenderInput } from './Input';
import { useDefaultGroup } from 'hooks/useDefaultGroup';
import { EncryptorGroupService } from '@mediaseal-webui/services';
import { searchEncryptorGroups } from 'reducers/groups/groups';
import { definitions } from '@mediaseal-webui/types/api';
import { Group } from '@mediaseal-webui/types';
import { clearGroupPageSearchValue, clearGroups } from '@mediaseal-webui/actions';

export const DefaultGroup = () => {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const { value, setValue, updateDefaultGroup } = useDefaultGroup();
    // @ts-expect-error due to "Property 'content' does not exist on type 'EncryptorGroupsState'.ts(2339)".
    const { content: groups } = useAppSelector((state) => state.encryptorGroups);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const dispatch = useAppDispatch();
    const service = useMemo(() => new EncryptorGroupService(), []);

    useEffect(() => {
        dispatch(searchEncryptorGroups({ service, keyword: '' }));
        return () => {
            dispatch(clearGroups());
            dispatch(clearGroupPageSearchValue());
        };
    }, [dispatch, service]);

    const handleInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            clearTimeout(timeoutRef.current as NodeJS.Timeout);
            event?.target?.value ? setValue(event.target.value) : setValue('');
            setSubmitting(true);
            timeoutRef.current = setTimeout(async () => {
                await dispatch(searchEncryptorGroups({ keyword: event?.target?.value ?? '', service }));
            }, 300);
            setSubmitting(false);
        },
        [dispatch, service, setValue]
    );
    const setGroup = useDefaultGroup().defaultGroup?.name;
    const onBlur = useCallback(() => {
        setGroup && setValue(setGroup);
    }, [setValue, setGroup]);

    const handleSelection = useCallback(
        async (event: React.ChangeEvent<{}>, value: (definitions['Group'] & { id: string }) | null) => {
            if (value) {
                setValue((ps) => value?.name ?? ps);
                // update the group
                await updateDefaultGroup({ groupId: value.id });
            }
        },
        [setValue, updateDefaultGroup]
    );

    return (
        <Autocomplete
            aria-label='Select default group'
            id='groups-select'
            style={{ marginTop: 16 }}
            // Handle input value change
            // @ts-expect-error due to type assigned 'void' ts(2322).
            onInputChange={handleInputChange}
            //handle the selection change
            onChange={handleSelection}
            //on blur
            onBlur={onBlur}
            //handle the input value
            inputValue={value}
            //loading state
            loading={submitting}
            // loading text
            loadingText='Searching groups...'
            // feed it title state items
            options={groups as Group[]}
            // No options text
            noOptionsText="Couldn't find any groups.."
            //handle the input
            renderInput={RenderInput({ submitting })}
            //handle the options in dropdown
            renderOption={(option) => option.name}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name as string}
            // set this to stop the filtering
            filterOptions={(options) => options}
            data-testid='default-group-combo'
            disableClearable
        />
    );
};
