import React, { useState, useEffect } from 'react';
import { InitialState } from '@mediaseal-webui/types/redux';
import { useSelector } from 'react-redux';
import { EditableHeaderChildProps, EditableHeaderItem } from '../../EditableHeaderItem';
import { HeaderItem } from '../HeaderItem';
import { CgNotes } from 'react-icons/cg';
import { GenericHeaderItemProps } from '..';
import { useAuth, useReadOnlyCheck } from '@mediaseal-webui/hooks';
import { validateJob } from '@mediaseal-webui/utils';

export const NotesHeaderItems = ({ isFetching, modal: Modal, tooltipOpen, onModalChange, stateKey = 'profile' }: GenericHeaderItemProps) => {
    const profile = useSelector(({ entity }: InitialState) => entity[stateKey]);
    const { notes, archived } = profile;
    const { isReadOnly } = useReadOnlyCheck(profile, validateJob);
    const [showNotes, setShowNotes] = useState(false);
    const onClick = () => setShowNotes(true);
    const auth = useAuth();
    const isEditAuth = auth.edit || auth.administer;

    const NotesIcon = () => <CgNotes size={24} />;

    useEffect(() => {
        onModalChange(showNotes);
    }, [showNotes]);

    return (
        <>
            <EditableHeaderItem title={notes ? 'Notes' : 'Add Notes'} onViewClick={onClick} open={tooltipOpen}>
                {({ hovering }: EditableHeaderChildProps) => <HeaderItem hovering={hovering} Icon={NotesIcon} isFetching={isFetching} id='notes' />}
            </EditableHeaderItem>
            {Modal && stateKey !== 'create' && <Modal notesModal openProps={[showNotes, setShowNotes]} disabled={archived || !isEditAuth || isReadOnly} />}
        </>
    );
};
