import styled, { css } from 'styled-components';

export const StyledCaption = styled.p`
    font-size: 9pt;
    text-align: center;
    position: absolute;
    top: -11px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    color: white;
    font-weight: bold;
`;

export const StyledContainer = styled.div`
    position: relative;
`;
export interface StyledSliderProps extends React.HTMLProps<HTMLDivElement> {
    score: number | null;
}
export const StyledSlider = styled.div<StyledSliderProps>(
    ({ theme, score }) => css`
        margin-top: 8px;
        width: 100%;
        height: 18px;
        border-radius: 17.5px;
        position: relative;
        background-color: #ddd;

        &:before {
            content: '';
            transition: width 0.35s ease;
            width: ${(score ?? 0) * 25}%;
            max-width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 18px;
            border-radius: 9px;
            background-color: ${score ? (score >= 4 ? '#007e9c' : score > 2 ? '#ffb366' : '#ff6666') : 'grey'};
        }
    `
);
