import { msApi } from '@mediaseal-webui/api';
import { ISetJobStartTypeProps } from '@mediaseal-webui/types/encryptor';
import { ThunkResult } from '@mediaseal-webui/types/redux';
import { setError } from '../../entity';
import { stopFetching } from '../../generic';
import { startOfflineEncryptionWork, startPasswordOnlyWithAclEncryptionWork } from '../slots';
import { cancelAllWork } from './cancelAllWork';
import { processAllWork } from './processAllWork';

interface GetACLProps extends ISetJobStartTypeProps {}
export const getACL = async ({ currentWork, id, mediaKey, fileId }: GetACLProps, passwordOnly = false, dispatch: any) => {
    try {
        const { jobData } = currentWork;
        let acl = null;
        // if its batch ... check that it hasn't been fetched
        if (!currentWork.batchEncryptedAcl) {
            const { data } = await msApi.get(`/files/${id}/fullEncryptedAcl`);
            const { encryptedAcl } = data;
            acl = encryptedAcl;
        }
        const payload = {
            id,
            fileSettings: {
                fileId,
                mediaKey,
                headerVersion: jobData.headerVersion,
                additionalDataVersion: jobData.additionalDataVersion,
                contactName: jobData.contactName || '',
                contactNumber: jobData.contactNumber || ''
            },
            fileFeatures: jobData.features,
            encryptedAcl: acl || currentWork.batchEncryptedAcl
        };
        if (acl || currentWork.batchEncryptedAcl) {
            if (passwordOnly) {
                dispatch(startPasswordOnlyWithAclEncryptionWork(payload));
            } else {
                dispatch(startOfflineEncryptionWork(payload));
            }
        }
    } catch (error) {
        dispatch(setError({ message: `Failed to retrieve ACL for file: ${fileId} ` + error }));
        dispatch(cancelAllWork(currentWork));
        dispatch(processAllWork(currentWork));
    }

    dispatch(stopFetching());
};
