import { SET_DEFAULT_DECRYPTOR_USER, DISPLAY_ENTITY_SUMMARY, CLEAR_REPORT_DATA, CLEAR_REPORT_FILTER } from '../../constants/reporting';
import { SET_PROFILE } from '../../constants';

export const setDefaultDecryptorUser = (payload) => ({ type: SET_DEFAULT_DECRYPTOR_USER, payload });

export const displayEntitySummary = (item) => (dispatch) => {
    dispatch({ type: SET_PROFILE, payload: item });
    dispatch({ type: DISPLAY_ENTITY_SUMMARY, payload: true });
};

export const closeEntitySummary = () => ({ type: DISPLAY_ENTITY_SUMMARY, payload: false });

export const clearReportData = () => ({ type: CLEAR_REPORT_DATA });
export const clearReportFilter = () => ({ type: CLEAR_REPORT_FILTER });
