import React from 'react';
import clsx from 'clsx';
// import { CircularProgress } from '@material-ui/core';
// import { MdCheck as Check } from 'react-icons/md'
import { ConfirmDialogIconProps } from '../..';
import useStyles from './AnimatedDeactivateIcon.styles';
import useSharedStyles from '../sharedStyles';

export const AnimatedDeactivateIcon = ({ loading, complete }: Omit<ConfirmDialogIconProps, 'icon'>) => {
    const shared = useSharedStyles();
    const classes = useStyles();
    return (
        <div className={clsx(shared.iconRoot, { loading, complete })} style={{ overflow: 'visible' }}>
            <svg viewBox='0 0 16 16' className={clsx(classes.lockBody, { loading })}>
                <rect width='11' height='9' x='2.5' y='7' rx='2' />
            </svg>
            <svg viewBox='2 0 12 12' className={clsx(classes.lockArm, { loading })}>
                <path fillRule='evenodd' d='M4.5 4a3.5 3.5 0 117 0v3h-1V4a2.5 2.5 0 00-5 0v3h-1V4z' clipRule='evenodd' />
            </svg>
            {/* {loading ? (
                <CircularProgress size={12} className={classes.loading} />
            ) : complete && (
                <Check />
            )} */}
        </div>
    );
};
