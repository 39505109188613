import React, { useState, useEffect } from 'react';
import { IconButton, Tooltip, Typography, Card } from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Settings';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';
import { WidgetProps } from '.';
import useStyles from './Widget.styles';

const Widget = (props: WidgetProps) => {
    const { title, children, open, className, openable, showSettings, width, height, padding, ...others } = props;

    const classes = useStyles({ width, height, padding });

    const [contentOpen, setContentOpen] = useState(open);
    useEffect(() => setContentOpen(open), [open, setContentOpen]);

    const handleEditClick = () => {};
    const handleOpen = () => setContentOpen(!contentOpen);

    return (
        <Card
            variant='outlined'
            className={clsx(className, {
                [classes.root]: true,
                [classes.openRoot]: contentOpen
            })}
            {...others}>
            {showSettings && (
                <Tooltip placement='top' title='Edit this widget'>
                    <IconButton className={classes.icon} onClick={handleEditClick} size='small'>
                        <BuildIcon fontSize='small' />
                    </IconButton>
                </Tooltip>
            )}
            <div className={classes.titleContainer}>
                {openable && (
                    <IconButton className={classes.caret} onClick={handleOpen}>
                        <KeyboardArrowDownIcon
                            data-testid='openButton'
                            className={clsx({
                                [classes.openCaret]: contentOpen
                            })}
                        />
                    </IconButton>
                )}
                <Typography color='textPrimary' variant='h6' className={classes.title}>
                    {title}
                </Typography>
            </div>
            <div className={classes.content} data-testid='widgetContent'>
                {children}
            </div>
        </Card>
    );
};

Widget.defaultProps = {
    title: '',
    padding: 0,
    width: 360,
    open: true,
    openable: false,
    showSettings: false
} as Partial<WidgetProps>;

export default Widget;
