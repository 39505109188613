import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    logo: {
        transition: 'all 1s'
    },
    container: {
        textAlign: 'center'
    }
}));

export default useStyles;
