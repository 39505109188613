import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { HashRouter } from 'react-router-dom';
import qtConnect from '@mediaseal-webui/webchannel-api';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as encryptorActions from '@mediaseal-webui/actions/src/encryptor';
import * as studioActions from './actions/login';
import { applicationVersionRequested } from './actions/misc';
import { sendCurrentQtVersions, applicationSettings } from '@mediaseal-webui/actions';
import store from './store';
import { ContextWrapper } from './components/layout-components/Main/ContextWrappers';
import smoothscroll from 'smoothscroll-polyfill';
import { CookiesProvider } from 'react-cookie';
import { reportWebVitals, sendToAnalytics } from './reportWebVitals';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'react-table-filter/lib/styles.css';
import './analytics';
import { SW_UPDATE_AVAILABLE, SW_UPDATE_COMPLETE } from '@mediaseal-webui/constants';
import KeycloakProvider from '@mediaseal-webui/services/src/AuthService/provider';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/solid';
import { isKeycloakActive } from '@mediaseal-webui/hooks/src/isKeycloakActive';

// kick off the polyfill!
smoothscroll.polyfill();

// if (process.env.NODE_ENV !== 'development') {
//     hotjar.initialize(2139224, 6);
// }

const internal = (
    <Provider store={store}>
        <CssBaseline />
        <HashRouter basename='/'>
            <ContextWrapper>
                <CookiesProvider>
                    <App />
                </CookiesProvider>
            </ContextWrapper>
        </HashRouter>
    </Provider>
);

ReactDOM.render(
    isKeycloakActive() ? <KeycloakProvider> {internal} </KeycloakProvider> : internal,

    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// This current snippet is used to control the service worker update feature.. any time new assets are recieved by the SW... a snackbar will appear to force a refresh
serviceWorkerRegistration.register({
    onSuccess: (registration: ServiceWorkerRegistration) =>
        store.dispatch({
            type: SW_UPDATE_COMPLETE,
            payload: { worker: registration, message: 'Successfully updated!' }
        }),
    onUpdate: (registration: ServiceWorkerRegistration) => {
        console.log('update!');
        store.dispatch({
            type: SW_UPDATE_AVAILABLE,
            payload: { worker: registration, message: 'New content updates available! refresh to update.' }
        });
    }
});

// serviceWorkerRegistration.register();
// Ifyou want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToAnalytics);

(async () => {
    if (window.qt) {
        qtConnect((qtApi) => {
            //@ts-expect-error due to argument of type is not assibnable "ts(2345)".
            qtApi.connectEncryptionSlots(store, encryptorActions);
            qtApi.connectStudioSlots(store, studioActions);
            const { generalError } = encryptorActions;
            qtApi.connectGeneralSlots(store, { applicationVersionRequested, generalError, applicationSettings });
            qtApi.connectVersionSlots(store, { sendCurrentQtVersions });
            console.log('Getting Performance Setting');
            window.GeneralWebInterface.getApplicationSettings();
            console.log('Getting username');
            window.StudioWebInterface.getUsernameForStudio();
            console.log('Sending SPA App Versions');
            window.VersionWebInterface.sendCurrentSpaVersions({
                spaVersion: process.env.REACT_APP_VERSION || '1.0.0',
                engineMinSupportedVersion: process.env.REACT_APP_MIN_ENGINE_VERSION || '1.0.0'
            }); // get the current version
        });
    }
})();

// qtConnect();
