export const genBasicAuth = (values) => {
    const authString = `Basic ${btoa(`${values.username}:${values.password}`)}`;
    return authString;
};

export const filterContentArray = (array) => {
    const goodArray = array.filter((item) => !item.relTargetType);
    return goodArray;
};

export const genBearerAuth = (token) => {
    const authToken = `bearer ${token}`;
    return authToken;
};
