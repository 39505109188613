import { useSelector } from 'react-redux';
import { selectableGenericHeader, selectableUserHeader, tokensHeader } from './data';
import { InitialState, EntityKey } from '@mediaseal-webui/types/redux';
// import { fetchGroups, fetchEncryptorGroups, fetchEncryptorUsers, fetchUsers } from '@mediaseal-webui/actions/src'
import { TableColumns } from '@mediaseal-webui/types/generic';
import { GenericEntity } from '@mediaseal-webui/types/schemas';
import { RootState, useAppSelector } from '@mediaseal-webui/online/src/store';
import { fetchEncryptorUsers, searchEncryptorUsers } from '@mediaseal-webui/online/src/reducers/users/encryptorUsers';
import { fetchRecipients, searchRecipients } from '@mediaseal-webui/online/src/reducers/users/recipients';
import { fetchDecryptorGroups, fetchEncryptorGroups, searchDecryptorGroups, searchEncryptorGroups } from '@mediaseal-webui/online/src/reducers/groups/groups';
import { fetchTokens, searchTokens } from '../../../online/src/reducers/tokens/tokens';

export const useEntity = (entityKey: EntityKey) => {
    const items = useAppSelector(stateAccessFunction(entityKey));
    const fetchFn = fetchFunction(entityKey);
    const searchFn = _searchFn(entityKey);
    const cells = tableHeaders(entityKey);

    return {
        cells,
        fetchFn,
        items,
        searchFn
    };
};

export type EntityReduxFn = (s: RootState) => GenericEntity[];

export const stateAccessFunction = (key: EntityKey): EntityReduxFn | never => {
    switch (key) {
        case 'decryptorUsers':
            return (state: RootState) => state.recipients.content as GenericEntity[];
        case 'encryptorUsers':
            return (state: RootState) => state.encryptorUsers.content as GenericEntity[];
        case 'decryptorGroups':
            return (state: RootState) => state.decryptorGroups.content as GenericEntity[];
        case 'encryptorGroups':
            return (state: RootState) => state.encryptorGroups.content as GenericEntity[];
        case 'allowedTokens':
            return (state: RootState) => state.tokens.content as GenericEntity[];
    }

    throw new Error();
};

export const fetchFunction = (key: EntityKey) => {
    switch (key) {
        case 'decryptorUsers':
            return fetchRecipients;
        case 'encryptorUsers':
            return fetchEncryptorUsers;
        case 'decryptorGroups':
            return fetchDecryptorGroups;
        case 'encryptorGroups':
            return fetchEncryptorGroups;
        case 'allowedTokens':
            return fetchTokens;
    }
};
export const _searchFn = (key: EntityKey) => {
    switch (key) {
        case 'decryptorUsers':
            return searchRecipients;
        case 'encryptorUsers':
            return searchEncryptorUsers;
        case 'decryptorGroups':
            return searchDecryptorGroups;
        case 'encryptorGroups':
            return searchEncryptorGroups;
        case 'allowedTokens':
            return searchTokens;
    }
};

export const tableHeaders = (key: EntityKey): TableColumns[] => {
    switch (key) {
        case 'decryptorUsers':
        case 'encryptorUsers':
            return selectableUserHeader as TableColumns[];
        case 'decryptorGroups':
        case 'encryptorGroups':
            return selectableGenericHeader as TableColumns[];
        case 'allowedTokens':
            return tokensHeader;
    }
    return selectableGenericHeader;
};
