import React, { useEffect, useState } from 'react';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import { EditableHeaderChildProps, EditableHeaderItem } from '../../EditableHeaderItem';
import { PasswordModal as PasswordPopUp } from '../../PasswordModal';
import { HeaderItem } from '../HeaderItem';
import { useSelector } from 'react-redux';
import { InitialState } from '@mediaseal-webui/types/redux';
import { AuthHeaderItemsProps } from '.';
import { type } from 'os';

export const AuthHeaderItems = ({
    isFetching,
    title,
    updateFn,
    tooltipOpen,
    onModalChange,
    subtitle,
    confirmSubtitle,
    disabled,
    stateKey = 'profile',
    hidden = false
}: AuthHeaderItemsProps) => {
    const { archived, type } = useSelector(({ entity: { profile } }: InitialState) => profile);
    const [open, setOpen] = useState(false);

    const onClick = () => type !== 'OFFLINE' && setOpen(true);
    useEffect(() => {
        onModalChange(open);
    }, [open]);

    return !hidden ? (
        <>
            <EditableHeaderItem title={title || ''} onViewClick={onClick} open={tooltipOpen}>
                {({ hovering }: EditableHeaderChildProps) => (
                    <HeaderItem hovering={hovering} Icon={EnhancedEncryptionIcon} isFetching={isFetching} id='jobAuth' />
                )}
            </EditableHeaderItem>
            {stateKey !== 'create' && (
                <PasswordPopUp
                    //@ts-ignore
                    updateFn={updateFn}
                    disabled={disabled || archived}
                    openProps={[open, setOpen]}
                    subtitle={subtitle}
                    confirmSubtitle={confirmSubtitle}
                />
            )}
        </>
    ) : null;
};
