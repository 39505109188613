import React from 'react';
import Jobs from 'views/Jobs';
import DecryptorUsers from 'views/Users/Decryptor';
import EncryptorUsers from 'views/Users/Encryptor';
import DecryptorGroups from 'views/Groups/Decryptor';
import EncryptorGroups from 'views/Groups/Encryptor';
import { Permission } from '@mediaseal-webui/types';
import { FC } from 'react';
import { useAppSelector } from 'store';

interface DefaultRoute {
    path: string;
    navId: string;
    Component: FC | null;
}

/**
 * Ordered list of default routes
 * First in list the users permissions allow will be the / route
 */
const DefaultRoutesMap: DefaultRoute[] = [
    {
        path: '/jobs',
        navId: 'jobsLink',
        Component: Jobs
    },
    {
        path: '/users/encryptor',
        navId: 'encryptorUsersLink',
        Component: EncryptorUsers
    },
    {
        path: '/groups/encryptor',
        navId: 'encryptorGroupsLink',
        Component: EncryptorGroups
    },
    {
        path: '/users/decryptor',
        navId: 'decryptorUsersLink',
        Component: DecryptorUsers
    },
    {
        path: '/groups/decryptor',
        navId: 'decryptorGroupsLink',
        Component: DecryptorGroups
    }
];

const matcher = (permission: Permission, path: string): boolean => new RegExp(path, 'ig').test(permission.permissionName);

const permFrom =
    (path: string) =>
    (permission: Permission): boolean =>
        matcher(permission, path);

/**
 * returns true if route has permissions to view
 */
const filterPerms =
    (permissions: Permission[]) =>
    ({ path }: DefaultRoute) => {
        const [, firstPath, lastPath] = path.split('/');
        return (
            permissions
                ?.filter(permFrom(firstPath))
                // if there is a second path (/users/encryptor) filter again
                .filter(lastPath === 'encryptor' || lastPath === 'decryptor' ? permFrom(lastPath) : () => true)
                .filter((perm) => matcher(perm, 'view')).length > 0
        );
    };

/**
 * @returns {DefaultRoute} -- first route allowed by user's permissions
 */
export const useDefaultRoute = (): DefaultRoute => {
    const { items: permissions } = useAppSelector((state) => state.permissions);
    const filterer = filterPerms(permissions);
    return (
        DefaultRoutesMap.find(filterer) ?? {
            path: '',
            navId: '',
            Component: () => <div />
        }
    );
};
