import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Typography } from '@material-ui/core';

const HeaderTitle = ({ text, className, skeleton }) =>
    skeleton ? (
        <Skeleton animation='pulse' height={35} width='35%' className={className} />
    ) : (
        <Typography color='textPrimary' variant='h4' id='headerTitle' className={className} data-testid='headerTitle'>
            {text}
        </Typography>
    );

export default HeaderTitle;
