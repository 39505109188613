import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { InitialState } from '@mediaseal-webui/types/redux';

/**
 * Checks an input versiion against the current engine to determine if a component should load.
 */
export const useFeatureFlag = (version: string) => {
    const [isCompatible, setIsCompatible] = useState<boolean>(false);
    const { applicationInformation } = useSelector((state: InitialState) => state.versioning);

    useEffect(() => {
        applicationInformation?.engineVersion >= version ? setIsCompatible(true) : setIsCompatible(false);
        return () => {
            setIsCompatible(false);
        };
    }, [version, applicationInformation]);
    return {
        isCompatible
    };
};
