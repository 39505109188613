import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: ({ width }) => width,
        padding: theme.spacing(2),
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        transition: 'all 0.3s ease',
        paddingLeft: ({ padding }) => padding,
        height: ({ height }) => height || theme.spacing(10),
        [theme.breakpoints.down('sm')]: {
            margin: 'unset',
            marginBottom: theme.spacing(2)
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    openRoot: {
        height: 'unset'
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(2)
    },
    title: {
        paddingRight: theme.spacing(5),
        cursor: 'pointer'
    },
    caret: {
        opacity: 0.4,
        transition: 'all 0.3s ease',
        transform: 'rotate(0deg)',
        '&:hover': {
            opacity: 0.7
        }
    },
    openCaret: {
        transform: 'rotate(180deg)'
    },
    icon: {
        position: 'absolute',
        top: theme.spacing(0.5),
        right: theme.spacing(0.5),
        opacity: 0.4,
        '&:hover': {
            opacity: 0.7
        }
    },
    content: {
        // marginLeft: theme.spacing(2),
        // marginTop: theme.spacing(2),
        height: '100%',
        '& label': {
            fontSize: '0.9em'
        },
        '& p': {
            // padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        }
    }
}));

export default useStyles;
