import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InitialState } from '@mediaseal-webui/types/redux';
import { EncryptFile } from '@mediaseal-webui/types/encryptor';
import { useActiveEncryptionWork } from '../useActiveEncryptionWork';

/**
 *
 * @param {staticFileList} array -- bring in all files to calculate the total fileSize
 */
const TOTAL_PERCENTAGE = 100;

const calcTotalSize = (list: EncryptFile[]) => list.reduce((total, file) => file.fileSize + total, 0);

const calcTotalPercentage = (fileList: EncryptFile[], totalSize: number) => {
    let sumOfCurrent = 0;
    fileList.forEach(({ percentage, fileSize }) => {
        if (percentage) {
            sumOfCurrent += (percentage / TOTAL_PERCENTAGE) * fileSize;
        }
    });

    return Math.floor((sumOfCurrent / totalSize) * TOTAL_PERCENTAGE);
};

export const useGetTotalPercentage = () => {
    const [percentage, setPercentage] = useState<number | null>(0);
    const [totalPercentage, setTotalPercentage] = useState<number | null>(0);
    const {
        activeJobWork: { fileList },
        allActiveWork
    } = useActiveEncryptionWork();
    const allFiles = allActiveWork.flatMap((work) => work.fileList);
    // calc the overall fileSize
    useEffect(() => {
        if (fileList && fileList.length > 0) {
            const totalSize = calcTotalSize(fileList);
            setPercentage(calcTotalPercentage(fileList, totalSize));
        }
        if (allFiles && allFiles.length > 0) {
            const totalSize = calcTotalSize(allFiles);
            setTotalPercentage(calcTotalPercentage(allFiles, totalSize));
        }
        return () => {
            setPercentage(null);
            setTotalPercentage(null);
        };
    }, [fileList, allActiveWork]);
    return { percentage, allTotalPercentage: totalPercentage };
};
