import {
    SELECT_ENC_USER,
    SELECT_DEC_USER,
    SELECT_ENC_GROUP,
    SELECT_DEC_GROUP,
    DESELECT_ENC_USER,
    DESELECT_DEC_USER,
    DESELECT_ENC_GROUP,
    DESELECT_DEC_GROUP,
    SUBMIT_SELECTION,
    CANCEL_SELECTION,
    SET_SUMMARY,
    SET_PROFILE,
    SELECT_TOKEN,
    DESELECT_TOKEN
} from '@mediaseal-webui/constants';
import { EntityKey, InitialState, SummaryState, ThunkResult, Dispatchable } from '@mediaseal-webui/types/redux';
import { GenericEntity } from '@mediaseal-webui/types';
import { RootState } from '@mediaseal-webui/online/src/store';
import { Dispatch, AnyAction } from 'redux';
/**
 * Selectable functionality
 */
export const selectEntity = (key: EntityKey) => (entity: GenericEntity) => {
    switch (key) {
        case 'decryptorGroups':
            return { type: SELECT_DEC_GROUP, payload: entity };
        case 'encryptorGroups':
            return { type: SELECT_ENC_GROUP, payload: entity };
        case 'decryptorUsers':
            return { type: SELECT_DEC_USER, payload: entity };
        case 'encryptorUsers':
            return { type: SELECT_ENC_USER, payload: entity };
        case 'allowedTokens':
            return { type: SELECT_TOKEN, payload: entity };
    }
};

export const deselectEntity = (key: EntityKey) => (entity: GenericEntity) => {
    switch (key) {
        case 'decryptorGroups':
            return { type: DESELECT_DEC_GROUP, payload: entity };
        case 'encryptorGroups':
            return { type: DESELECT_ENC_GROUP, payload: entity };
        case 'decryptorUsers':
            return { type: DESELECT_DEC_USER, payload: entity };
        case 'encryptorUsers':
            return { type: DESELECT_ENC_USER, payload: entity };
        case 'allowedTokens': {
            return { type: DESELECT_TOKEN, payload: entity };
        }
    }
};

export const setCheckedEntities = (key: EntityKey) => async (dispatch: Dispatch, getState: () => RootState) => {
    const state: GenericEntity[] = getState().entity.edit[key];
    let type = '';
    switch (key) {
        case 'encryptorUsers':
        case 'users':
            type = 'encryptorUsers/setSelected';
            break;
        case 'decryptorUsers':
            type = 'recipients/setSelected';
            break;
        case 'encryptorGroups':
            type = 'encryptorGroups/setSelected';
            break;
        case 'decryptorGroups':
            type = 'decryptorGroups/setSelected';
            break;
        case 'allowedTokens':
            type = 'tokens/setSelected';
            break;
        default:
            throw new Error('Please provide the correct entity key');
    }
    state.forEach((item) => {
        dispatch({ type, payload: item });
    });
};

export const cancelSelection = (): AnyAction => ({ type: CANCEL_SELECTION });

/**
 * Uses the Redux getState function to return the entities for a given EntityKey
 */
const getEntitySummary = (key: EntityKey, getState: () => InitialState): Partial<SummaryState> | never => {
    const { summary } = getState().entity;
    switch (key) {
        case 'decryptorGroups':
            return {
                ...summary,
                decryptorGroups: [...(summary.decryptorGroups ?? []), ...getState().groups.filteredGroups.filter((item) => item.selected)]
            };
        case 'encryptorGroups':
            return {
                ...summary,
                encryptorGroups: [...(summary.encryptorGroups ?? []), ...getState().groups.encryptorGroups.filter((item) => item.selected)]
            };
        case 'decryptorUsers':
            return {
                ...summary,
                decryptorUsers: [...(summary.decryptorUsers ?? []), ...getState().users.filteredUsers.filter((item) => item.selected)]
            };
        case 'encryptorUsers':
            return {
                ...summary,
                users: [...(summary.users ?? []), ...getState().users.encryptorUsers.filter((item) => item.selected)]
            };
        case 'allowedTokens':
            return {
                ...summary,
                allowedTokens: [...(summary.allowedTokens ?? []), ...getState().tokens.filter((item) => item.selected)]
            };
    }

    throw new Error();
};

/**
 *
 * @param key -- which entity is selected ready for submission
 * @param update -- function to update the profile
 */
export const submitSelection =
    (key: EntityKey, update: Dispatchable): ThunkResult<Promise<void>> =>
    async (dispatch: Dispatch, getState: () => InitialState) => {
        const payload = getEntitySummary(key, getState);
        dispatch({ type: SET_SUMMARY, payload });
        // dispatch({ type: SET_EDIT, payload })
        //@ts-ignore -- keeps throwing a type error
        dispatch(update());
        dispatch({ type: SET_PROFILE, payload });
        dispatch({ type: SUBMIT_SELECTION });
    };
