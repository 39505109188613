import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingRight: theme.spacing(3),
        width: '100%'
    },
    container: {
        paddingTop: theme.spacing(3)
    },
    label: {
        marginBottom: theme.spacing(2)
    },
    title: {
        marginBottom: theme.spacing(1),
        fontWeight: 'bold'
    },
    subtitle: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    tabSection: {
        background: theme.palette.background.default,
        height: theme.sizes.tables.forms,
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: '4px 4px 0 0',
        width: '100%',
        flexWrap: 'unset'
    },

    spaceBottom: {
        marginBottom: theme.spacing(2)
    },
    spaceTop: {
        marginTop: theme.spacing(4)
    },
    stack: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: theme.sizes.tables.forms - theme.spacing((32 + 24 + 72) / 8)
    },
    tableContainer: {
        height: theme.sizes.tables.user.forms
    }
}));

export default useStyles;
