import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    progressContainer: {
        minHeight: '400px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            minHeight: '100%',
            flexDirection: 'column',
            marginBottom: 55
        }
    },
    tabZone: {
        minHeight: '400px',
        // maxHeight: 800,
        width: '100%',
        overflow: 'scroll'
    },
    lightBg: {
        backgroundColor: theme.palette.background.default,
        borderRadius: '0 0 25px 25px'
    },
    accessProgress: {
        color: theme.palette.progress.main,
        strokeLinecap: 'round'
    },
    deniedProgress: {
        strokeLinecap: 'round'
    },
    grantedProgress: {
        color: theme.palette.progress.dark,
        strokeLinecap: 'round'
        // margin: 10
    },
    stack: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: theme.sizes.tables.forms - theme.spacing((72 + 48) / 8)
    }
}));

export default useStyles;
