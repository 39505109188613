import { formatDistanceToNow, format, getYear } from 'date-fns';
// import faker from 'faker'
import Axios from 'axios';

export const getUserTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const fetchFn = async (url, payload) => {
    try {
        const { data } = await Axios.get(url, {
            headers: {
                authorization: payload.token
            }
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const formatDate = (date) => {
    const formattedDate = date.split('T')[0];
    const isoDate = new Date(formattedDate).toISOString();
    const dateString = new Date(isoDate).toDateString();
    const timestamp = new Date(isoDate).getTime();
    const daysDifference = Math.floor((timestamp - Date.now()) / 1000 / 60 / 60 / 24);
    if (daysDifference >= 7 && daysDifference <= 30) {
        return 'Over a week ago';
    }
    if (daysDifference > 7) {
        return 'Within a week';
    }
    return dateString;
};

export const formateDateStyle = () => {
    const today = new Date();
    const dd = today.getDate();
    const mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();

    const date = dd + '/' + mm + '/' + yyyy;
    return date;
};

export const processAuthMode = (mode) => {
    switch (mode) {
        case 'ONLINE':
            return 'Password + User + Server';
        case 'OFFLINE':
            return 'Password + User';
        case 'PASSWORD_ONLY':
            return 'Password';
        default:
            return null;
    }
};

export const getTotalPercentage = (files) => {
    let fileDeduction = 0;
    let percentage = 0;
    if (files.length > 0) {
        // eslint-disable-next-line no-unused-vars
        files.forEach((file) => {
            if (file.cancelled || file.error) {
                fileDeduction += 1;
            }
            percentage += file.percentage;
        });
        percentage = fileDeduction === files.length ? 0 : Math.round(percentage / (files.length - fileDeduction));
        return percentage;
    }
    return percentage;
};

export const trimDate = (date) => date.split('GMT')[0].trim();

export const processFileSize = (size, si) => {
    let bytes = size;
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
    }
    const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    do {
        bytes /= thresh;
        u += 1;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return `${bytes.toFixed(1)} ${units[u]}`;
};

export const formatDateFns = (createDate) => {
    const date = createDate !== '' ? new Date(createDate) : new Date();
    return getYear(date) < new Date().getFullYear() ? format(date, 'P') : formatDistanceToNow(date, { addSuffix: true });
};

export const seedData = () => {
    return Array.from({ length: 100 }, (_, key) => {
        return {
            fakeid: key,
            name: '',
            firstName: '',
            lastName: '',
            date: '',
            email: '',
            active: null,
            authMode: null
        };
    });
};
export const seedReportData = () => {
    return Array.from({ length: 100 }, (_, key) => {
        return {
            id: key,
            auditId: ' ',
            fileAccess: {
                fileName: ' ',
                jobName: ' ',
                userName: ' ',
                processName: ' '
            },
            createdBy: '',
            auditType: ' '
        };
    });
};
export const validate = (values) => {
    const errors = {};
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$£€%&\'()*+,-./:;<=>?@[\\]^_`{|}~])(?=.{8,})');
    const nameRegex = new RegExp("^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$");
    if (!values.jobPassword) {
        errors.jobPassword = 'Please enter a password!';
    } else if (values.jobPassword && !strongRegex.test(values.jobPassword)) {
        errors.jobPassword =
            'The password must contain a minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character';
    }
    if (values.editJobPassword && !strongRegex.test(values.editJobPassword)) {
        errors.editJobPassword =
            'The password must contain a minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character';
    }
    if (!values.jobName) {
        errors.jobName = 'Required!';
    } else if (values.jobName.length < 6) {
        errors.jobName = 'Please enter a more descriptive name';
    }
    if (!values.encGroupName) {
        errors.encGroupName = 'Required!';
    } else if (values.encGroupName.length < 3) {
        errors.encGroupName = 'Please enter a name between 2 and 64 characters';
    } else if (values.encGroupName.length > 64) {
        errors.encGroupName = 'Please enter a name between 2 and 64 characters';
    }
    if (!values.loginName) {
        errors.loginName = 'Required';
    } else if (values.loginName.length < 5) {
        errors.loginName = 'Login Name needs to be longer';
    }

    if (!values.firstName) {
        errors.firstName = 'Required';
    } else if (values.firstName.length < 3) {
        errors.firstName = 'First Name needs to be longer';
    } else if (values.firstName && !nameRegex.test(values.firstName)) {
        errors.firstName = 'First name cannot have special characters';
    }

    if (!values.lastName) {
        errors.lastName = 'Required';
    }

    if (!values.passwordUser) {
        errors.passwordUser = 'Required';
    } else if (!strongRegex.test(values.passwordUser)) {
        errors.passwordUser =
            'The password must contain a minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character';
    }

    if (!values.email) {
        errors.email = 'Required';
    } else if (
        !/^^[a-z0-9!#$%&'*/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
            values.email
        )
    ) {
        errors.email = 'Invalid email address';
    }
    return errors;
};

export const validateEmail = (values) => {
    const errors = {};

    if (!values.email) {
        errors.email = 'Required';
    } else if (
        !/^^[a-z0-9!#$%&'*/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
            values.email
        )
    ) {
        errors.email = 'Invalid email address';
    }
    return errors;
};

// eslint-disable-next-line no-param-reassign
const addLabelAndCheckItem = (permission) => {
    const { permissionName } = permission;
    if (permissionName.includes('Jobs')) permission.label = 'Jobs';
    if (permissionName.includes('Encryptor') || permissionName.includes('Decryptor')) permission.label = 'User';
    if (permissionName.includes('Titles')) permission.label = 'Titles';
    if (permissionName.includes('Departments')) permission.label = 'Departments';
    if (permissionName.includes('Groups')) permission.label = 'Groups';
    if (permissionName.includes('Global')) permission.label = 'Global';
    if (permissionName.includes('Personal')) permission.label = 'Personal';
    if (permissionName.includes('Local')) permission.label = 'Local';
    if (permissionName.includes('Reports') || permissionName.includes('Reporting')) permission.label = 'Reports';

    return permission;
};
export const serializePermissions = (permissions, currentPerms) => {
    // define our object to return
    const obj = {
        jobsTitles: [
            { id: 0, label: 'View', options: [] },
            { id: 1, label: 'Create', options: [] },
            { id: 2, label: 'Edit', options: [] },
            { id: 3, label: 'Archive', options: [] },
            { id: 4, label: 'Copy', options: [] },
            { id: 5, label: 'Modify', options: [] }
        ],
        encryptor: [
            { id: 0, label: 'View', options: [] },
            { id: 1, label: 'Create', options: [] },
            { id: 2, label: 'Edit', options: [] },
            { id: 3, label: 'Activate', options: [] }
        ],
        decryptor: [
            { id: 0, label: 'View', options: [] },
            { id: 1, label: 'Create', options: [] },
            { id: 2, label: 'Edit', options: [] }
        ],
        settings: [{ id: 0, label: 'Edit', options: [] }],
        reporting: [
            { id: 0, label: 'View', options: [] },
            { id: 1, label: 'Export', options: [] }
        ]
    };
    // loop thru array and map out new arrays in to object
    permissions.forEach((permission) => {
        const { permissionName } = permission;
        addLabelAndCheckItem(permission);
        switch (true) {
            case permissionName.includes('Jobs'):
            case permissionName.includes('Titles'):
                if (permissionName.includes('View')) obj.jobsTitles[0].options.push(permission);
                if (permissionName.includes('Create')) obj.jobsTitles[1].options.push(permission);
                if (permissionName.includes('Edit')) obj.jobsTitles[2].options.push(permission);
                if (permissionName.includes('Archive')) obj.jobsTitles[3].options.push(permission);
                if (permissionName.includes('Copy')) obj.jobsTitles[4].options.push(permission);
                if (permissionName.includes('Transcode')) obj.jobsTitles[5].options.push(permission);
                break;
            case permissionName.includes('Encryptor'):
                if (permissionName.includes('View')) obj.encryptor[0].options.push(permission);
                if (permissionName.includes('Create')) obj.encryptor[1].options.push(permission);
                if (permissionName.includes('Edit')) obj.encryptor[2].options.push(permission);
                if (permissionName.includes('Activate')) obj.encryptor[3].options.push(permission);
                break;
            case permissionName.includes('Decryptor'):
                if (permissionName.includes('View')) obj.decryptor[0].options.push(permission);
                if (permissionName.includes('Create')) obj.decryptor[1].options.push(permission);
                if (permissionName.includes('Edit')) obj.decryptor[2].options.push(permission);
                break;
            case permissionName.includes('Settings'):
            case permissionName.includes('Personal'):
                if (permissionName.includes('Administer')) obj.settings[0].options.push(permission);
                break;
            case permissionName.includes('Reporting'):
            case permissionName.includes('Reports'):
                if (permissionName.includes('View')) obj.reporting[0].options.push(permission);
                if (permissionName.includes('Export')) obj.reporting[1].options.push(permission);
                break;
            default:
                break;
        }
    });
    return obj;
};

export const filterContentArray = (array) => {
    const goodArray = array.filter((item) => !item.relTargetType);
    return goodArray;
};

export const checkSelectedPermissions = (allPerms, grpPerms) => {
    const returnedPerms = allPerms.map((perm) => {
        for (const grp of grpPerms) {
            if (grp.id === perm.id) {
                perm.checked = true;
            }
        }
        return perm;
    });
    return returnedPerms;
};
