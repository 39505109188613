import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    viewRoot: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            height: 'unset'
        },
        [theme.breakpoints.down('xs')]: {
            overflow: 'scroll'
        }
    },
    formContainer: {
        width: '100%',
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.background.paper
    },
    heading: {
        margin: `0 ${theme.spacing(2)}px 0 0`,
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px 0 ${theme.spacing(4)}px`,
        fontWeight: 'bold'
    }
}));

export const useContentStyles = makeStyles((theme) => ({
    content: {
        [theme.breakpoints.down('sm')]: {
            height: '100%'
        },
        [theme.breakpoints.down('xs')]: {
            height: '120vh'
        }
    }
}));
