import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Send } from '@material-ui/icons';
import { Header } from '../../../../components/layout-components';
import ScrollToTopButton from '../../../../components/utility-components/ScrollToTopButton';
import CreateForm from '../../../../components/groups-components/creation-view-components/CreateDecryptorForm';
import SummaryList from '../../../../components/utility-components/SummaryList';
import { postDecGroup, fetchUsers } from '@mediaseal-webui/actions';
import { useMobile, useEntityCreation } from '@mediaseal-webui/hooks';
import useStyles from './CreateDecryptorGroupStyle.styles';
import { SubmitButton as Submit, Content } from '@mediaseal-webui/components';
import { clearSummary } from 'actions/entity';
import { useCleanEntity } from 'hooks/useCleanEntity';

const CreateDecryptorGroup = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const mobile = useMobile();
    const { decryptorUsers, name } = useSelector(({ entity: { create } }) => create);
    const { valid } = useEntityCreation();
    const [open, setOpen] = useState(false);
    const handleSubmit = () => {
        dispatch(postDecGroup(history));
        setOpen(!open);
    };
    const SubmitButton = (props) => <Submit title='Save Group' id='create-dec-user' Icon={Send} aria-label='Post Group' {...props} disabled={!valid} />;
    const headerItems = {
        stateKey: 'create',
        decUsers: decryptorUsers?.length > 0 ? {} : undefined
    };
    useCleanEntity();

    useEffect(() => {
        dispatch(
            fetchUsers({
                keyword: '',
                page: 0,
                isSort: true,
                firstCall: true
            })
        );
        return () => {
            dispatch(clearSummary());
        };
    }, [dispatch]);

    return (
        <div className={classes.viewRoot}>
            <Header
                defaultName='Create Decryptor Group'
                onSubmit={handleSubmit}
                date={new Date()}
                name={name}
                ActionButton={SubmitButton}
                breadcrumbProps={{
                    links: [{ id: 0, name: 'Decryptor Groups', to: '/groups/decryptor' }],
                    text: [{ id: 0, name: 'Create' }]
                }}
                headerItems={headerItems}
            />

            <Content>
                {(classes) => (
                    <div className={`${classes.wrapper} ${classes.cols}`}>
                        <CreateForm handleSubmit={handleSubmit} />
                        <div className={classes.summary}>
                            <SummaryList open={open} setOpen={setOpen} subtitleEntity='group' />
                        </div>
                    </div>
                )}
            </Content>

            {!mobile && <ScrollToTopButton scrollStepInPx='50' delayInMs={10} />}
        </div>
    );
};

export default React.memo(CreateDecryptorGroup);
