import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { msApi } from '@mediaseal-webui/api';
import { Permission } from '@mediaseal-webui/types';

export const fetchUserPermissions = createAsyncThunk('permissions/fetchUserPermissions', async (id: string, { rejectWithValue }) => {
    try {
        const { data } = await msApi.get(`/permissions/search/findByUserId?userId=${id}`);
        const { content } = data;
        return content;
    } catch (error) {
        rejectWithValue(error.response.data);
    }
});
export interface IPermissions {
    pending: boolean;
    items: Permission[];
}
export const permissionsSlice = createSlice({
    name: 'permissions',
    initialState: {
        pending: false,
        fulfilled: false,
        items: [] as Permission[]
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUserPermissions.fulfilled, (state, { payload }) => {
            return {
                ...state,
                items: payload,
                pending: false,
                fulfilled: true
            };
        });
        builder.addCase(fetchUserPermissions.pending, (state, { payload }) => {
            return {
                ...state,
                pending: true
            };
        });
    }
});
