import { keycloak } from './keycloak';
import Axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from 'axios';
import { genBearerAuth } from '@mediaseal-webui/utils';
import { isKeycloakActive } from '@mediaseal-webui/hooks/src/isKeycloakActive';

export class KeyCloakService {
    config?: AxiosRequestConfig;
    service: AxiosInstance;
    constructor(public url: string) {
        this.service = this._createInstance();
        this.config = {
            headers: {
                authorization: isKeycloakActive() && genBearerAuth(keycloak.token)
            }
        };
    }

    private _createInstance() {
        return Axios.create({
            baseURL: `http://localhost:9990/auth/`,
            ...this.config
        });
    }
}
