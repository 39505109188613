export const SELECT_ENC_USER = 'SELECT_ENC_USER';
export const SELECT_DEC_USER = 'SELECT_DEC_USER';
export const SELECT_ENC_GROUP = 'SELECT_ENC_GROUP';
export const SELECT_DEC_GROUP = 'SELECT_DEC_GROUP';

export const DESELECT_ENC_USER = 'DESELECT_ENC_USER';
export const DESELECT_DEC_USER = 'DESELECT_DEC_USER';
export const DESELECT_ENC_GROUP = 'DESELECT_ENC_GROUP';
export const DESELECT_DEC_GROUP = 'DESELECT_DEC_GROUP';

export const CANCEL_SELECTION = 'CANCEL_SELECTION';

export const SUBMIT_SELECTION = 'SUBMIT_SELECTION';
export const SET_FEATURE = 'SET_FEATURE';
export const REMOVE_FEATURE = 'REMOVE_FEATURE';
