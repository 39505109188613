import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingRight: theme.spacing(3)
    },
    formElement: {
        marginBottom: theme.spacing(4)
    },
    label: {
        marginBottom: theme.spacing(2)
    },
    margin: {
        marginTop: theme.spacing(2)
    },
    tabSection: {
        background: theme.palette.background.default,
        height: theme.sizes.tables.forms,
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: '4px 4px 0 0',
        width: '100%',
        flexWrap: 'unset'
    }
}));

export default useStyles;
