import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    chart: {
        position: 'relative',
        marginLeft: '-10px',
        marginRight: '-21px'
    },
    graphHeader: {
        position: 'relative'
    },

    fileText: {
        opacity: 0.35,
        fontSize: 20
    },

    accessedText: {
        opacity: 0.65,
        fontSize: 50
    }
}));

export default useStyles;
