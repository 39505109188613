import { combineReducers } from '@reduxjs/toolkit';
import encryptor from './encryptor';
import jobs from './jobs';
import misc from './misc';
import form from './form';
import entity from './entity';
import versioning from './versioning';
import filters from './filters';
import settings from './settings';
import network from './network';
import { userSlice } from './user';
import userReducers from './users';
import { encryptorUsersSlice } from './users/encryptorUsers';
import groupsReducers from './groups';
import { decryptorGroupsSlice, encryptorGroupsSlice } from './groups/groups';
import { titlesSlice } from './titles';
import { titleSlice } from './title';
import loginReducers from './login';
import invitations from './invitations';
import reporting from './reporting';
import { permissionsSlice } from './permissions';
import { recipientSlice } from './users/recipients';
import { tokensSlice } from './tokens/tokens';
export default combineReducers({
    jobs,
    users: userReducers,
    groups: groupsReducers,
    misc,
    entity,
    encryptor,
    form,
    titles: titlesSlice.reducer,
    title: titleSlice.reducer,
    login: loginReducers,
    invitations,
    reporting,
    versioning,
    filters,
    settings,
    network,
    tokens: tokensSlice.reducer,
    recipients: recipientSlice.reducer,
    permissions: permissionsSlice.reducer,
    user: userSlice.reducer,
    encryptorGroups: encryptorGroupsSlice.reducer,
    decryptorGroups: decryptorGroupsSlice.reducer,
    encryptorUsers: encryptorUsersSlice.reducer
});
