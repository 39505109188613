import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import reducers from '../reducers/index';
import { offlineMiddleware } from '../middleware/offlineMiddleware';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const middlewares = [offlineMiddleware, thunk];

export const store = configureStore({
    reducer: reducers,
    middleware: [...middlewares]
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
// Export a hook that can be reused to resolve types
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

window.$store = store;
// @ts-expect-error due to "Property '$msDispatchQueue' does not exist on type 'Window & typeof globalThis'.ts(2339)".
window.$msDispatchQueue = [];
export default store;
