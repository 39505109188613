import React from 'react';
import RemoveAction, { RemoveActionProps } from '../RemoveAction';

interface Props extends RemoveActionProps {
    create: boolean;
}

const ActionContainer = ({ create, ...others }: Props) => (
    <div tabIndex={-1} style={{ display: 'flex' }}>
        <>{create && <RemoveAction {...others} />}</>
    </div>
);

export default ActionContainer;
