import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Send } from '@material-ui/icons';
import { Header, HeaderItemsConfig } from '../../../components/layout-components';
import ScrollToTopButton from '../../../components/utility-components/ScrollToTopButton';
import { clearEntity, fetchPermissions } from '@mediaseal-webui/actions';
import { useEntityCreation, useMobile } from '@mediaseal-webui/hooks';
import { Content, SubmitButton as Submit } from '@mediaseal-webui/components';
import useStyles from './TokensCreate.styles';
import { CreateForm } from './CreateForm';
import { createTokens } from 'reducers/tokens/tokens';
import { TokensService } from '@mediaseal-webui/services';
import { InitialState } from '@mediaseal-webui/types/redux';
import { addDays } from 'date-fns/esm';
import { ConfirmationModal } from '../../../components/token-components/ConfirmationModal/ConfirmationModal';

const Create = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();
    const mobile = useMobile();

    const { valid } = useEntityCreation();
    const service = useMemo(() => new TokensService(), []);

    const { permissions, name, description, sunset } = useSelector(({ entity: { create } }: InitialState) => create);
    const { fulfilled, tokenId } = useSelector(({ tokens }: InitialState) => tokens);

    const permissionNames = permissions ? permissions.map((permission) => permission.permissionName) : [];

    useEffect(() => {
        dispatch(fetchPermissions());
        return () => {
            dispatch(clearEntity());
        };
    }, [dispatch, fulfilled]);

    const handleSubmit = () => {
        dispatch(
            createTokens({
                body: {
                    description: description ?? '',
                    expirationDate: sunset ?? addDays(new Date(), 30).toISOString(),
                    name: name,
                    permissionNames: permissionNames
                },
                service: service
            })
        );
        setIsModalOpen(true);
    };

    const SubmitButton = (props) => (
        <Submit
            title='Save Token'
            id='create-token-save-btn'
            aria-label='Save Token'
            Icon={Send}
            data-testid='save-token-btn'
            {...props}
            disabled={!valid || permissionNames.length < 1}
        />
    );
    const headerItems: HeaderItemsConfig = {
        stateKey: 'create',
        permissions: permissions?.length > 0 ? {} : undefined
    };

    const handleModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className={classes.root}>
            <ConfirmationModal tokenId={tokenId} isOpen={isModalOpen} onClose={handleModal} />
            <Header
                defaultName='Create Token'
                onSubmit={handleSubmit}
                date={new Date()}
                name={name}
                ActionButton={SubmitButton}
                breadcrumbProps={{
                    links: [{ id: 0, name: 'Tokens', to: '/tokens' }],
                    text: [{ id: 0, name: 'Create' }]
                }}
                headerItems={headerItems}
            />

            <Content>
                {(classes) => (
                    <div className={`${classes.wrapper} ${classes.cols}`}>
                        <CreateForm handleSubmit={handleSubmit} />
                    </div>
                )}
            </Content>

            {!mobile && <ScrollToTopButton scrollStepInPx='50' delayInMs={10} />}
        </div>
    );
};

export default React.memo(Create);
