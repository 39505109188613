/* eslint import/no-webpack-loader-syntax: off */
import search from 'workerize-loader!../../workers/search';
import { useGenAuthTokenFn } from '@mediaseal-webui/hooks/src/useGenAuthTokenFn';

const searchWorker = search();

export const useSearchWorker = () => {
    const token = useGenAuthTokenFn();

    return {
        searchWorker,
        token
    };
};
