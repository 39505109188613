import { connect } from 'react-redux';
import Profile from './Profile';
import { fetchSpecificDecGroup, fetchPermissions } from '@mediaseal-webui/actions';

const mapDispatchToProps = {
    fetchSpecificDecGroup,
    fetchPermissions
};

const mapStateToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
