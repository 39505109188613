import React from 'react';
import { MdWarning } from 'react-icons/md';
import useSharedStyles from './sharedStyles';

export const WarningIcon = () => {
    const classes = useSharedStyles();
    return (
        <div className={classes.iconRoot}>
            <MdWarning className={`${classes.singleIcon} danger`} />
        </div>
    );
};
