import React, { useCallback, createContext } from 'react';
import useStyles from './InfiniteScrollTable.styles';
import { useDispatch } from 'react-redux';
import { AutoSizer, Table, InfiniteLoader } from 'react-virtualized';
import { InfiniteTableProps } from '.';
import clsx from 'clsx';
import { GenericEntity } from '@mediaseal-webui/types/schemas';

const ROW_HEIGHT = 45;
const HEADER_HEIGHT = 50;

export const CalenderContext = createContext<{
    calenderShown: boolean;
    setCalenderShown: React.Dispatch<React.SetStateAction<boolean>>;
}>({
    calenderShown: false,
    setCalenderShown: () => {}
});

const isRowLoaded =
    (data: GenericEntity[]) =>
    ({ index }: { index: number }) =>
        !!data[index];

export const InfiniteScrollTable = (props: InfiniteTableProps) => {
    const {
        children,
        rowCount,
        data,
        fetchFn,
        totalPages,
        pageControl = {
            page: 0,
            setPage: () => null
        },
        onRowClick = () => null,
        rowGetter,
        height,
        // window,
        noResultsMessage,
        columns,
        reporting,
        ...tableProps
    } = props;
    const { page, setPage } = pageControl;
    const classes = useStyles();
    const dispatch = useDispatch();
    const getMoreData = (): Promise<void> => {
        if (totalPages && page < totalPages && data.length >= 100) {
            setPage((prevPage) => prevPage + 1);
            dispatch(fetchFn);
        }
        return Promise.resolve();
    };

    const handleRowClick = ({ rowData }: { rowData: GenericEntity }) => {
        if (rowData.id || rowData.auditId) {
            onRowClick(rowData);
        } else {
            return;
        }
    };
    const rowClickHandler = useCallback(handleRowClick, [data]);

    return (
        <InfiniteLoader isRowLoaded={isRowLoaded(data)} loadMoreRows={getMoreData} rowCount={99999} threshold={5}>
            {({ onRowsRendered, registerChild }) => {
                return (
                    <AutoSizer>
                        {({ width, height }) => (
                            <Table
                                onRowsRendered={onRowsRendered}
                                ref={registerChild}
                                width={width}
                                height={height - 65}
                                className={clsx(classes.table)}
                                tabIndex={0}
                                onRowClick={rowClickHandler}
                                rowCount={rowCount}
                                rowHeight={ROW_HEIGHT}
                                headerHeight={HEADER_HEIGHT}
                                rowGetter={rowGetter}
                                columns={columns}
                                {...tableProps}>
                                {children && children(width, height)}
                            </Table>
                        )}
                    </AutoSizer>
                );
            }}
        </InfiniteLoader>
    );
};
