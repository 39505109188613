import styled, { css } from 'styled-components';
import { FaRegEye } from 'react-icons/fa';

export interface EyeToggleProps extends React.HTMLProps<SVGElement> {}

export const EyeToggle = styled(FaRegEye)<EyeToggleProps>(
    ({ theme }) => css`
        position: absolute;
        right: 50px;
        height: 20px;
        width: 20px;
        opacity: 0.3;
        top: calc(50% - 10px);
        transition: all 0.3s linear;
        &:hover {
            cursor: pointer;
            opacity: 0.5;
        }
    `
);

export const StyledContainer = styled.div`
    position: relative;
    display: flex;
    width: 100%;
`;
