import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    dialogue: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        margin: theme.spacing(1)
    }
}));
