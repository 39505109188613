import { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FileParserService } from '@mediaseal-webui/services/src/FileParserService';
import { definitions } from '@mediaseal-webui/types/api';
import { FileService } from '@mediaseal-webui/services';
import { InitialState } from '@mediaseal-webui/types';
import { useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { AuditFileContext } from './context';
export * from './context';
export interface FileReaderErrorState {
    message: string;
}

export const useAuditFileDrop = () => {
    const { file, setFile } = useContext(AuditFileContext);
    const [job, setJob] = useState<(definitions['Job'] & definitions['File']) | null>(null);
    const [isComplete, setIsComplete] = useState<boolean>(false);
    const [mediaSealFileId, setMediaSealFileId] = useState<string>('');
    const { username, password } = useSelector((state: InitialState) => state.misc.userCredentials);
    const [filePermissionError, setFilePermissionError] = useState<FileReaderErrorState | null>(null);
    const [genericError, setGenericError] = useState<FileReaderErrorState | null>(null);
    const [subscribed, setSubscribed] = useState<boolean>(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fileService = new FileService('/files');

    const errorHandler = useCallback((error: AxiosError<Error & { errors: string[] }>) => {
        const errorMessage = error?.response?.data.message || 'That file does not belong to this studio.';

        if (error.response?.status === 403) {
            setFilePermissionError({
                message: errorMessage
            });
        } else {
            setGenericError({
                message: errorMessage
            });
        }
    }, []);

    const onDrop = async (acceptedFiles) => {
        setSubscribed(true);
        if (subscribed) {
            acceptedFiles.forEach((file: File) => {
                setFile(file);
                const service = new FileParserService();

                service.reader.onerror = (event: ProgressEvent) => {
                    setGenericError({
                        message: 'Error reading file, please try again'
                    });
                };
                service.reader.onload = async () => {
                    const binaryStr = service.reader.result as ArrayBuffer;
                    const buffer = binaryStr.slice(0, 8) as ArrayBuffer;
                    const byteArr = new Uint8Array(buffer);
                    if (service.isMediasealFile(byteArr)) {
                        const fileId = service.extractFileId(new Uint8Array(binaryStr));
                        setMediaSealFileId(fileId);
                        try {
                            // const { data } = await fileService.getFile(fileId)
                            const { data: job } = await fileService.getJobForFile(fileId);
                            setJob(job);
                        } catch (error) {
                            errorHandler(error);
                        }
                        setIsComplete(true);
                    } else {
                        setGenericError({
                            message: 'Not a valid MediaSeal file'
                        });
                    }
                };
                service.parseFile(file.slice(0, 128));
            });
        }
        // Do something with the files
    };

    useEffect(() => {
        return () => {
            setFile(null);
            setIsComplete(false);
            setFilePermissionError(null);
            setGenericError(null);
            setSubscribed(false);
        };
    }, []);

    const dropzone = useDropzone({
        onDrop,
        noClick: true,
        noDragEventsBubbling: true,
        noKeyboard: true
    });
    return {
        file,
        job,
        setJob,
        setFile,
        isComplete,
        setIsComplete,
        filePermissionError,
        setGenericError,
        genericError,
        mediaSealFileId,
        setMediaSealFileId,
        setFilePermissionError,
        onDrop, // internal useOnly -> exported for testing
        ...dropzone
    };
};
