import { useContext, useCallback, useEffect } from 'react';
import { PasswordScoreContext } from './context';
export * from './context';

const getCaption = (score: number) => {
    switch (score) {
        case 4:
            return 'Strong';
        case 3:
            return 'Moderate';
        case 2:
        case 1:
            return 'Weak';
        default:
            return '';
    }
};

export const usePasswordScore = () => {
    const [{ score, caption }, setContext] = useContext(PasswordScoreContext);

    const updatePassword = useCallback(async (password: string) => {
        const z = await import('zxcvbn');
        const check = z.default;
        const { score } = check(password);
        const caption = getCaption(score);
        setContext({ score, caption });
    }, []);
    useEffect(() => {
        return () => {
            setContext({ score: null, caption: '' });
        };
    }, []);
    return {
        score,
        caption,
        updatePassword
    };
};
