import { setError, stopFetching } from '@mediaseal-webui/actions';
import { msApi } from '@mediaseal-webui/api';
import { SET_STATUS_FILTER, CLEAR_STATUS_FILTER, SET_STATUS_FLAGS } from '@mediaseal-webui/constants';
import { FetchArgs } from '@mediaseal-webui/types/generic';
import { StatusFilterPayload } from '@mediaseal-webui/types/jobs';
import { Dispatchable } from '@mediaseal-webui/types/redux';
import { setTotalJobPages } from '.';

export const setStatusFilter =
    (payload: StatusFilterPayload): Dispatchable =>
    (dispatch) => {
        dispatch({ type: SET_STATUS_FILTER, payload: payload.queryStringData });
        dispatch({ type: SET_STATUS_FLAGS, payload: payload.flagData });
    };

export const clearStatusFilter = () => ({ type: CLEAR_STATUS_FILTER });

/**
 * Fetch
 */

interface GetJobsPayload extends FetchArgs {
    size?: number;
}
export const getJobs =
    (payload: GetJobsPayload): Dispatchable =>
    async (dispatch, getState) => {
        try {
            const { statusFilter, jobPageSearchValue } = getState().jobs;
            const { archived, liveFolders, active, batched } = statusFilter;
            const { data } = await msApi.get(
                `/jobs/search/findAllWithQueryParameters?${batched || ''}${archived || ''}${liveFolders || ''}${active || ''}${
                    jobPageSearchValue !== '' ? `&keyword=${window.encodeURI(jobPageSearchValue.trim())}` : ''
                }&projection=simple&page=${payload.page}&size=${payload.size ? payload.size : 100}&sort=${payload.sort ? payload.sort : 'id,desc'} `
            );
            // const { data } = await msApi.get('/jobs?projection=compact&sort=createDate,desc')
            const { content } = data;
            const { page } = data;
            const { firstCall } = payload;
            const { totalPages } = page;
            const { totalElements } = page;

            dispatch({ type: firstCall ? 'FETCH_JOBS_FILTERED' : 'FETCH_JOBS', payload: content });
            dispatch(setTotalJobPages(totalPages));
            dispatch({ type: 'SET_TOTAL_JOB_ELEMENTS', payload: totalElements });
        } catch (error) {
            dispatch(setError(error));
        }
        dispatch(stopFetching());
    };
