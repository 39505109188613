import styled, { css, DefaultTheme, keyframes } from 'styled-components';
import { fade, IconButton } from '@material-ui/core';
import { BiRocket } from 'react-icons/bi';
export const StyledIconButton = styled(IconButton)`
    ${({ theme }) => css`
        width: 40px;
        height: 40px;
    `}
`;

export const StyledReresh = styled.button`
    ${({ theme }) => css`
        width: 40px;
        height: 40px;
        margin-right: 4px;
        border-radius: 20px;
        background: ${theme.palette.background.paper};
        outline: none;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background 0.35s ease;
        &:hover {
            background: ${fade(theme.palette.action.hover, 0.05)};
            cursor: pointer;
        }
        svg {
            color: ${theme.palette.text.primary};
            opacity: 0.5;
            font-size: 16px;
            &:hover {
                opacity: 1;
            }
        }
    `}
`;
const slideInToThoseDms = keyframes`
    from {
        right: -350px;
    }

    to {
        right: 10px;
    }
`;
interface StyledUpdateAlertProps {
    show: boolean;
    theme: DefaultTheme;
}
export const StyledUpdateAlert = styled.div`
    ${({ theme, show }: StyledUpdateAlertProps) => css`
        width: 300px;
        height: 50px;
        align-items: center;
        display: ${show ? 'flex' : 'none'};
        /* justify-content: space-between; */
        border-radius: 5px;
        background ${theme.palette.background.paper};
        border-left: 5px solid ${theme.palette.info.main};
        animation: ${slideInToThoseDms} 0.35s linear;
        animation-fill-mode: forwards;
        position: fixed;
        right: -350px;
        bottom: 50px;
        right: ${show ? '10px' : '-350px'};
        box-shadow: ${theme.shadows[9]};
    `}
`;

export const StyledAlertTitle = styled.p`
    ${({ theme }) => css`
        font-size: 14px;
        margin: 2px;
        display: flex;
        color: ${theme.palette.text.primary};
    `}
`;

export const StyledStartIcon = styled(BiRocket)`
    ${({ theme }) => css`
        color: ${theme.palette.text.primary};
        opacity: 0.5;
        /* display: flex; */
        font-size: 25px;
        margin: ${theme.spacing(1)}px;
        padding: ${theme.spacing(0.5)}px;
    `}
`;
export const StyledRefreshContainer = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
`;

export const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 75%;
`;

export const StyledSubText = styled.sub`
    ${({ theme }) => css`
        font-family: 'Raleway', sans-serif;
        font-size: 12px;
        margin: 2px;
        color: ${theme.palette.info.light};
    `}
`;
