import React, { useMemo } from 'react';
import TabSection from '../../utility-components/TabSection';
import useStyles from './EditableGroupPermissions.styles.js';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { InitialState, IOpenProps } from '@mediaseal-webui/types';
import { Actions } from '@mediaseal-webui/components';
import { updateGroup } from '@mediaseal-webui/actions';
import { tabs } from './tabs';
import { useEncryptorAdministrator } from '@mediaseal-webui/hooks';
import { useAppSelector } from 'store';

const EditableGroupPermissions = (props: IOpenProps) => {
    const {
        openProps: [open, setOpen]
    } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const { permissions } = useSelector((state: InitialState) => state.groups);
    const canAdministerEncryptorGroups = useEncryptorAdministrator();
    const allowedPermissions = useAppSelector((state) => state.permissions).items.map((allowedPermission) => allowedPermission.permissionName);

    const permissionTabs = useMemo(
        () =>
            tabs({
                permissions: permissions,
                canAdminister: { value: !canAdministerEncryptorGroups, allowedPermissions: allowedPermissions }
            }),
        [allowedPermissions, canAdministerEncryptorGroups, permissions]
    );

    const handleCancel = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        dispatch(updateGroup(id));
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleCancel} fullScreen={false}>
            <div className={classes.modalPaper}>
                <DialogContent>
                    {/*
                    // @ts-expect-error due to missing props ts(2739). */}
                    <TabSection variant='scrollable' boxDisplay='block' tabs={permissionTabs} />
                </DialogContent>
            </div>
            <DialogActions>
                <Actions saveFn={handleSubmit} closeFn={handleCancel} disableSave={!canAdministerEncryptorGroups} />
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(EditableGroupPermissions);
