import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { TableCell, Chip, Fade, Tooltip, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import useStyles, { useTooltipStyles } from './StatusCell.styles';
import { useHistory } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

// import { Test } from './StatusCell.styles';
const CustomToolTip = ({ data }) => {
    const history = useHistory();
    const [showIconTitle, setShowIconTitle] = useState(false);
    const [showIconJob, setShowIconJob] = useState(false);
    const tooltipClasses = useTooltipStyles();

    const clickAction = (data, url) => (event) => {
        event.stopPropagation();
        history.push(url);
    };

    const statusCheck = (item) => (item.active ? 'Active' : 'Inactive');
    const determineTooltipClass = (data) => (data.active ? tooltipClasses.tooltipActive : tooltipClasses.tooltipInactive);

    const handleClick = useCallback(clickAction, [history]);
    const handleMouseEnterTitle = useCallback(() => setShowIconTitle(true), []);
    const handleMouseExitTitle = useCallback(() => setShowIconTitle(false), []);
    const handleMouseEnterJob = useCallback(() => setShowIconJob(true), []);
    const handleMouseExitJob = useCallback(() => setShowIconJob(false), []);
    return (
        data.title && (
            <>
                <Typography
                    color='textPrimary'
                    onMouseEnter={handleMouseEnterJob}
                    onMouseLeave={handleMouseExitJob}
                    className={tooltipClasses.tooltipHover}
                    variant='subtitle1'
                    onClick={handleClick(data, `/jobs/${data.id}/profile`)}>
                    Job: <span className={determineTooltipClass(data)}>{statusCheck(data)}</span>
                    {showIconJob && <OpenInNewIcon className={tooltipClasses.icon} />}
                </Typography>
                <Typography
                    color='textPrimary'
                    onMouseEnter={handleMouseEnterTitle}
                    onMouseLeave={handleMouseExitTitle}
                    variant='subtitle1'
                    className={tooltipClasses.tooltipHover}
                    onClick={handleClick(data, `/titles/${data.title.id}/profile`)}>
                    Title: <span className={determineTooltipClass(data.title)}>{statusCheck(data.title)}</span>
                    {showIconTitle && <OpenInNewIcon className={tooltipClasses.icon} />}
                </Typography>
            </>
        )
    );
};
const StatusCell = (props) => {
    const { data, isFetching, skeletonWidth, skeletonHeight, enableToolTip, ...others } = props;
    const classes = useStyles();
    const tooltipClasses = useTooltipStyles();

    const determineLabel = () => {
        if (data.title) {
            return data.active ? (data.title?.active ? 'Active' : 'Inactive') : 'Inactive';
        } else {
            return data.active ? 'Active' : 'Inactive';
        }
    };
    const determineClassName = () => {
        if (data.title) {
            return data.active ? (data.title?.active ? classes.active : classes.implicitInactive) : classes.inactive;
        } else {
            return data.active ? classes.active : classes.inactive;
        }
    };
    if (isFetching) {
        return (
            <Fade in timeout={1000}>
                <TableCell component='div' align='center' {...others} classes={{ root: classes.tableCell }} scope='row'>
                    <Skeleton
                        data-testid='loading-skeleton'
                        animation='pulse'
                        variant='rect'
                        width={70}
                        height={skeletonHeight}
                        style={{ marginBottom: 6, borderRadius: '16px' }}
                    />
                </TableCell>
            </Fade>
        );
    } else {
        return (
            <TableCell component='div' align='center' {...others} classes={{ root: classes.tableCell }} scope='row'>
                {enableToolTip ? (
                    <Tooltip
                        classes={{ tooltip: tooltipClasses.tooltip, arrow: tooltipClasses.arrow }}
                        interactive
                        arrow
                        placement='top'
                        title={<CustomToolTip data={data} />}>
                        <Chip variant='default' className={determineClassName()} label={determineLabel()} />
                    </Tooltip>
                ) : (
                    <Chip variant='default' className={determineClassName()} size='small' label={determineLabel()} />
                )}
            </TableCell>
        );
    }
};

StatusCell.propTypes = {
    data: PropTypes.shape({
        active: PropTypes.bool,
        title: PropTypes.shape({
            active: PropTypes.bool
        })
    }),
    enableToolTip: PropTypes.bool,
    isFetching: PropTypes.bool,
    subtitle: PropTypes.string,
    skeletonWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    skeletonHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

StatusCell.defaultProps = {
    isFetching: false,
    subtitle: '',
    skeletonWidth: 100,
    skeletonHeight: 10,
    enableToolTip: false,
    data: {
        active: false,
        title: {
            active: true
        }
    }
};

export default React.memo(StatusCell);
