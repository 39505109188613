import { msApi } from '@mediaseal-webui/api';
import { useDispatch } from 'react-redux';
import { GenericEntity } from '@mediaseal-webui/types/schemas';

type ArchiveFn = (entity: GenericEntity, setArchived: boolean, setActive: boolean) => Promise<void>;
type ArchiveJob = ArchiveFn[];

export const useArchiveJob = (): ArchiveJob => {
    const dispatch = useDispatch();

    const fn = async (entity: GenericEntity, archived: boolean, active: boolean = !archived) => {
        try {
            await msApi.patch(`/jobs/${entity.id}?projection=simple`, {
                archived: archived,
                active: active
            });
            dispatch({
                type: 'SET_SNACKBAR',
                payload: {
                    success: true,
                    message: `Successfully ${archived ? 'archived' : 'unarchived'} job: ${entity.name}`
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    return [fn];
};
