import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import useStyles from './RemoveAction.styles';
import { RemoveActionProps } from '.';
import DeleteIcon from '@material-ui/icons/Delete';

const RemoveAction = (props: RemoveActionProps) => {
    const { removeAction, disabled, RemoveIcon = DeleteIcon, tooltipTitle = 'Remove' } = props;
    const classes = useStyles();
    const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        removeAction();
    };
    return (
        <div className={classes.wrapper}>
            <Tooltip title={tooltipTitle} placement='top'>
                <span>
                    <IconButton disabled={disabled} data-testid='delete-icon' className={classes.root} onClick={clickHandler}>
                        <RemoveIcon className='remove-icon' />
                    </IconButton>
                </span>
            </Tooltip>
        </div>
    );
};

export default React.memo(RemoveAction);
