import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    breadCrumbs: {
        padding: `0 ${theme.spacing(2)}px`,
        zIndex: 535,
        cursor: 'default',
        [theme.breakpoints.down('xs')]: {
            padding: `0  ${theme.spacing(2)}px`
        }
    },
    link: {
        textDecoration: 'none',
        opacity: 1,
        color: theme.palette.text.primary,
        fontFamily: 'inherit',
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.8
        }
    },
    displayingText: {
        opacity: 0.5,
        color: theme.palette.text.primary,
        fontFamily: 'inherit',
        fontSize: '1em'
    },
    breadCrumbsContainer: {
        display: 'flex',
        width: '100%',
        height: 30,
        marginBottom: theme.spacing(3),
        justifyContent: 'space-between',
        '& .MuiBreadcrumbs-ol': {
            flexWrap: 'nowrap',
            whiteSpace: 'nowrap'
        },
        [theme.breakpoints.down('md')]: {
            '& p': {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: 'calc(100vw - 500px)'
            }
        },
        [theme.breakpoints.down('sm')]: {
            '& p': {
                width: 'calc(100vw - 300px)'
            }
        }
    }
}));

export default useStyles;
