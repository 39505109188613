import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    contactDetailsContainer: {
        display: 'block',
        width: '100%',
        paddingBottom: theme.spacing(3)
    },
    nameContainer: {
        paddingTop: theme.spacing(3),
        width: '100%'
    },
    title: {
        marginBottom: theme.spacing(1)
    }
}));
