import React, { FC, HTMLProps } from 'react';
import { useTheme } from '@material-ui/core';
import { StyledCookieConsent } from './CookieAlert.style';

export interface CookieAlertProps extends HTMLProps<HTMLDivElement> {}

export const CookieAlert: FC<CookieAlertProps> = ({}) => {
    const theme = useTheme();
    const buttonStyles = {
        color: theme.palette.common.white,
        background: theme.palette.primary.main,
        fontSize: 13,
        borderRadius: 20,
        width: 100,
        height: 40
    };
    const bannerStyles = {
        background: '#eee',
        zIndex: 10006,
        color: 'black',
        boxShadow: '0px -7px 5px 0px rgba(0, 0, 0, 0.17)'
    };
    return window.qt ? null : (
        <StyledCookieConsent location='bottom' buttonText='Accept' cookieName='MS-Cookie-Consent' style={bannerStyles} buttonStyle={buttonStyles}>
            This application uses cookies to enhance the user experience.
        </StyledCookieConsent>
    );
};
