import { definitions, SpringBootResponse } from '@mediaseal-webui/types/api';
import { AxiosRequestConfig } from 'axios';
import { BaseService } from '../BaseService/BaseService';

export class EncryptorGroupService extends BaseService<SpringBootResponse<definitions['Group']>, {}> {
    constructor(public config?: AxiosRequestConfig) {
        super('/groups', config);
    }
    public async fetchGroups() {
        return await this.get(`/search/findAllContainingKeyword${this._createQueryString(this.baseParams)}`);
    }
    public async fetchGroup(id: number) {
        return await this.get(`/${id}`);
    }
    public async searchGroups(keyword: string) {
        this.baseParams = {
            ...this.baseParams,
            keyword
        };
        return await this.fetchGroups();
    }
}
