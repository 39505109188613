import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    alert: {
        zIndex: 11,
        position: 'fixed',
        top: 0,
        width: '100%'
    }
}));
