import { SET_FILE_PROCESSED } from '@mediaseal-webui/constants';
import { IEncryptionStartProps } from '@mediaseal-webui/types/encryptor';

/**
 *
 * @param param0
 * @returns
 *
 *  Used to signal QT to start password only encryption (online app)
 *
 */
export const startPasswordOnlyWithAclEncryptionWork = ({ id, fileSettings, fileFeatures = [], encryptedAcl }: IEncryptionStartProps) => {
    console.log('calling startPasswordOnlyWithAclEncryptionWork');

    window.EncryptionWebInterface.startPasswordOnlyWithAclEncryptionWork(id, fileSettings, fileFeatures, encryptedAcl || '');
    // setEncryptionStart(id, 'dadwaa', 'mp3', 'dwadwadawda', 'dwadawdwd', 0)
    return { type: SET_FILE_PROCESSED, payload: { id } };
};
