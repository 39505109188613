import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    formControl: {
        margin: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            margin: '18px 22px 16px 5px',
            height: 'auto'
        },
        '& .Mui-disabled': {
            cursor: 'not-allowed'
        }
    },
    selectAllLabel: {
        marginLeft: 11,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        }
    },
    standard: {
        height: '42px',
        paddingTop: '20px',
        fontWeight: 600
    },
    disabled: {
        height: '42px',
        paddingTop: '20px',
        color: theme.palette.grey[300],
        textDecoration: 'line-through'
    }
}));

export default useStyles;
