import React from 'react';
import { ISerializedPermissions } from '@mediaseal-webui/utils';
import PermissionSelection from '../../../components/utility-components/PermissionSelection';
interface PermissionsTabs {
    id: number;
    label: string;
    attrId: string;
    component: React.FC;
}

export const tabs = (props: { permissions: ISerializedPermissions; readOnly: boolean }): PermissionsTabs[] => [
    {
        id: 0,
        label: 'Jobs/Titles',
        attrId: 'job-titles-btn',
        component: () => <PermissionSelection stateKey='jobsTitles' groups={props.permissions?.jobsTitles ?? []} />
    },
    {
        id: 1,
        label: 'Encryptor',
        attrId: 'encryptor-permissions-btn',
        component: () => <PermissionSelection stateKey='encryptor' groups={props.permissions?.encryptor ?? []} />
    },
    {
        id: 2,
        label: 'Decryptor',
        attrId: 'decryptor-permissions-btn',
        component: () => <PermissionSelection stateKey='decryptor' groups={props.permissions?.decryptor ?? []} />
    },
    {
        id: 3,
        label: 'Settings',
        attrId: 'settings-permissions-btn',
        component: () => <PermissionSelection stateKey='settings' groups={props.permissions?.settings ?? []} />
    },
    {
        id: 4,
        label: 'Reporting',
        attrId: 'reporting-permissions-btn',
        component: () => <PermissionSelection stateKey='reporting' groups={props.permissions?.reporting ?? []} />
    }
];
