import { definitions } from '@mediaseal-webui/types/api';
import { BaseService } from '../BaseService/BaseService';
import { AxiosRequestConfig } from 'axios';

export class FileService extends BaseService<definitions['File'] & definitions['Job']> {
    constructor(public url: string, public config?: AxiosRequestConfig) {
        super(url, config);
    }

    public async getFile(id: string) {
        return await this.get(`/${id}`);
    }

    public async getJobForFile(id: string) {
        return await this.get(`/${id}/job`);
    }

    //   public setParams(params: Partial<AuditParams>) {
    //     this.auditParams = {
    //       ...this.auditParams,
    //       ...params,
    //     }
    //   }
}
