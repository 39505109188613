import { UsersService } from '@mediaseal-webui/services';
import { SpringBootResponse } from '@mediaseal-webui/types';
import { definitions } from '@mediaseal-webui/types/api';
import { seedData } from '@mediaseal-webui/utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import appendToPagedState from 'reducers/helpers/appendToPagedState';

export const fetchEncryptorUsers = createAsyncThunk('users/fetchEncryptorUsers', async (service: UsersService, { rejectWithValue }) => {
    try {
        const { data } = await service.fetchUsers();
        return data as EncryptorUsersState;
    } catch (error) {
        return rejectWithValue(error);
    }
});
export const searchEncryptorUsers = createAsyncThunk(
    'users/searchEncryptorUsers',
    async ({ keyword, service }: { keyword: string; service: UsersService }, { rejectWithValue }) => {
        try {
            const { data } = await service.searchUsers(keyword);
            return data as EncryptorUsersState;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export interface EncryptorUsersState extends SpringBootResponse<definitions['User'][]> {
    pending: boolean;
    searchValue: string;
}
const initialState: EncryptorUsersState = {
    content: seedData(),
    searchValue: '',
    page: {
        totalElements: 0,
        totalPages: 0
    },
    pending: false
};
export const encryptorUsersSlice = createSlice({
    name: 'encryptorUsers',
    initialState,
    reducers: {
        setSearchValue: (state, action) => ({
            ...state,
            searchValue: action.payload
        }),
        clearSearchValue: (state) => ({
            ...state,
            searchValue: ''
        }),
        setUsers: (state, action) => ({
            ...state,
            ...action.payload
        }),
        setSelected: (state, action) => ({
            ...state,
            content: state.content.map((item) => (item.id === action.payload.id ? { ...item, selected: true } : item))
        }),
        deselect: (state, action) => ({
            ...state,
            content: state.content.map((item) => (item.id === action.payload.id ? { ...item, selected: false } : item))
        }),
        clearUsers: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(fetchEncryptorUsers.fulfilled, (state: EncryptorUsersState, action) => {
            return appendToPagedState<definitions['User']>(action, state);
        });
        builder.addCase(fetchEncryptorUsers.pending, (state, action) => ({
            ...state,
            pending: false
        }));
        builder.addCase(searchEncryptorUsers.fulfilled, (state, action) => ({
            ...state,
            page: {
                ...(action.payload.page ?? {
                    totalPages: 0,
                    totalElements: 0
                })
            },
            content: [...action.payload.content],
            pending: false
        }));
        builder.addCase(searchEncryptorUsers.pending, (state, action) => ({
            ...state,
            pending: true
        }));
    }
});
