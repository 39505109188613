import styled, { css, keyframes } from 'styled-components';
import { FiWifiOff, FiWifi, FiCheckCircle } from 'react-icons/fi';
import { CircularProgress, makeStyles } from '@material-ui/core';
export interface StyledNetworkAlertProps extends React.HTMLProps<HTMLDivElement> {
    offline: boolean;
    online: boolean;
}

const slideIn = keyframes`
    from {
        top: -150px;
    }
    to {
        top: 50px;
    }
`;
const slideOut = keyframes`
    0% {
        opacity: 0;
    }
    15% {
        opacity: 1;
    }
    75%{
        opacity: 1;
        top: 50px;
    }
    100% {
        opacity: 1;
        top: -150px;
    }
`;

const icons = css`
    display: flex;
    font-size: 40pt;
    margin: 24px;
    color: white;
    opacity: 0.5;
    /* width: 20%; */
    height: 100%;
`;
export const StyledSuccessIcon = styled(FiWifi)`
    ${icons}
`;
export const StyledErrorIcon = styled(FiWifiOff)`
    ${icons}
`;

export const StyledMessage = styled.p`
    font-size: 12pt;
    color: white;
    display: flex;
    flex: 0 100%;
    opacity: 0.75;
    font-family: 'Raleway';
`;

export const StyledCircularProgress = styled(CircularProgress)`
    display: flex;
    justify-self: flex-end;
    height: 20px;
    width: 20px;
    color: white;
    opacity: 0.5;
`;

export const StyledCheckMark = styled(FiCheckCircle)`
    ${icons}
    display: flex;
    justify-self: flex-end;
    height: 20px;
    width: 20px;
`;
export const StyledNetworkAlert = styled.div<StyledNetworkAlertProps>(
    ({ theme, offline, online }) => css`
        display: flex;
        width: 50%;
        height: 50px;
        background: ${theme.palette.error.light};
        background: ${offline
            ? `linear-gradient(45deg, ${theme.palette.error.light} 35%, ${theme.palette.error.main} 100%)`
            : `linear-gradient(45deg, ${theme.palette.success.light} 35%, ${theme.palette.success.main} 100%)`};
        border-radius: 10px;
        box-shadow: ${theme.shadows[15]};
        opacity: ${online ? 0 : offline ? 1 : 0};
        visibility: ${!offline && !online ? 'hidden' : 'visible'};
        align-items: center;
        position: fixed;
        top: ${online ? '50px' : '-150px'};
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        z-index: 100;
        animation: ${online ? slideOut : slideIn} linear;
        animation-duration: ${online ? '2s' : '0.35s'};
        animation-fill-mode: forwards;
        transition: all 0.55s ease-in-out;
        /* transition-delay: 3s; */
    `
);
export const ProgressContainer = styled.div`
    display: flex;
    flex: 0 100%;
    justify-content: flex-end;
`;

export const useStyles = makeStyles((theme) => ({
    progress: {
        width: '20px !important',
        height: '20px !important',
        margin: 24,
        // fontSize: '12pt',
        opacity: 0.5,
        color: theme.palette.common.white
    }
}));
