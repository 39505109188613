import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        height: '100%',
        width: '100%'
    }
}));

export default useStyles;
