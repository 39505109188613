import React, { useCallback } from 'react';
import { StyledAccountWidget, StyledName, StyledAvatar } from './index.styles';
import { useAppSelector } from 'store';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useExternalUser } from '@mediaseal-webui/hooks';
import { isKeycloakActive } from '@mediaseal-webui/hooks/src/isKeycloakActive';

export const AccountWidget = () => {
    const { isExternal } = useExternalUser();
    const user = useAppSelector((state) => state.user);
    const history = useHistory();
    const handleClick = useCallback(() => {
        if (isExternal) return;
        if (isKeycloakActive()) {
            // keycloak.accountManagement();
            history.push('/account');
        } else if (user.apiUser?.id !== undefined) {
            history.push(`/users/encryptor/${user.apiUser?.id}/profile`);
        }
    }, [history, isExternal, user]);

    const Widget = () => {
        return (
            <StyledAccountWidget onClick={handleClick} external={isExternal} {...(isExternal ? {} : { role: 'button' })} data-testid='account-button'>
                <StyledName> {user.apiUser?.loginName}</StyledName>
                <StyledAvatar>
                    <div className='circle'>{user.apiUser?.loginName?.charAt(0).toLocaleUpperCase()}</div>
                </StyledAvatar>
            </StyledAccountWidget>
        );
    };

    const Tooltip = () => {
        if (isExternal) {
            return <Widget />;
        } else {
            return (
                <MuiTooltip placement='left' title='Go to account management'>
                    {Widget()}
                </MuiTooltip>
            );
        }
    };

    return (
        <>
            <Tooltip />
        </>
    );
};
