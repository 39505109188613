import React from 'react';
import { Button } from '@material-ui/core';
import { useStyles, FirstRowProps } from '..';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { cancelAllFilesWithIssue, confirmAllFileOverwrite } from '@mediaseal-webui/actions';

export const FirstRow = ({ issue }: FirstRowProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleCancelAll = () => dispatch(cancelAllFilesWithIssue(issue));
    const handleOverwriteAll = () => dispatch(confirmAllFileOverwrite());

    return (
        <div data-testid='file-issue-row' className={clsx(classes.listItem, classes.firstRow)}>
            <div
                className={clsx(classes.buttons, {
                    [classes.buttonsWithOverwrite]: issue === 'OutputConflict'
                })}>
                {issue === 'OutputConflict' && (
                    <Button onClick={handleOverwriteAll} color='primary' data-testid='overwrite-file-button'>
                        Overwrite All
                    </Button>
                )}
                <Button onClick={handleCancelAll} data-testid='cancel-file-button' className={classes.itemCancelButton}>
                    Cancel All
                </Button>
            </div>
        </div>
    );
};
