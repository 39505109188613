import { makeStyles } from '@material-ui/core';

export const useEmailStyles = makeStyles((theme) => ({
    iconButton: {
        opacity: 0.5,
        '&:hover': {
            opacity: 1
        }
    },
    input: {
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 0,
        width: '75%',
        padding: theme.spacing(0, 0, 2, 2),
        textAlign: 'right'
    },
    actionButtons: {
        background: theme.palette.action.hover,
        borderRadius: 5,
        marginLeft: theme.spacing(1),
        width: 60,
        [theme.breakpoints.down('xs')]: {
            maxHeight: 50,
            alignItems: 'center'
        }
    },
    editIcon: {
        '&:hover': {
            background: theme.palette.buttons.light,
            color: theme.palette.common.white
        },
        [theme.breakpoints.down('xs')]: {
            background: `${theme.palette.buttons.light} !important`,
            color: theme.palette.common.white
        }
    },
    settingsCard: {
        display: 'inline-flex',
        minHeight: 250,
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2),
        margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        [theme.breakpoints.down('sm')]: {
            overflow: 'unset !important',
            flexDirection: 'column',
            justifyContent: 'unset',
            height: '100%'
        }
    },
    settingTitle: {
        width: '35%'
    },
    cardContent: {
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    formField: {
        display: 'inline-block',
        minHeight: 'inherit',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: theme.spacing(2)
        }
    },
    headingContainer: {
        display: 'inline-block'
    },
    heading: {
        marginBottom: theme.spacing(1)
    }
}));

export const useEmailInputStyles = makeStyles((theme) => ({
    input: {
        fontSize: 'x-large',
        padding: theme.spacing(2)
    }
}));
