import React from 'react';
import { GrRefresh } from 'react-icons/gr';
import {
    StyledAlertTitle,
    StyledReresh,
    StyledRefreshContainer,
    StyledStartIcon,
    StyledSubText,
    StyledTextContainer,
    StyledUpdateAlert
} from './UpdateAlert.styles';
// import { GrRefresh } from 'react-icons/gr'

export interface UpdateAlertProps {
    show: boolean;
    message: string;
    worker: ServiceWorkerRegistration;
}
export const UpdateAlert = ({ show = false, message, worker }: UpdateAlertProps) => {
    const updateServiceWorker = () => {
        const registrationWaiting = worker.waiting;

        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

            registrationWaiting.addEventListener('statechange', (event) => {
                //@ts-ignore
                if (event?.target?.state === 'activated') {
                    window.location.reload();
                }
            });
        }
    };

    return (
        <StyledUpdateAlert show={show}>
            <StyledStartIcon />
            <StyledTextContainer>
                <StyledAlertTitle color='textPrimary'>{message}</StyledAlertTitle>
            </StyledTextContainer>
            <StyledRefreshContainer>
                <StyledReresh onClick={updateServiceWorker}>
                    <GrRefresh />
                </StyledReresh>
            </StyledRefreshContainer>
        </StyledUpdateAlert>
    );
};
