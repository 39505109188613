// useAppSleector - takes a callback, state.entity.profile

import { useAppSelector } from '@mediaseal-webui/online/src/store';
import { useEffect, useState } from 'react';

export const useExternalUser = () => {
    const { external } = useAppSelector((state) => state.user);
    const [isExternal, setIsExternal] = useState(false);

    useEffect(() => {
        if (external) {
            setIsExternal(true);
        }
        if (!external) {
            setIsExternal(false);
        }
    }, [external]);

    return {
        isExternal
    };
};
