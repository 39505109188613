import { useKeycloakConditionally } from '../useKeycloakConditionally';

export const useLogout = () => {
    const { keycloak } = useKeycloakConditionally() ?? {};
    const handler = (event?: React.SyntheticEvent) => {
        event?.preventDefault();
        event?.stopPropagation();
        window.qt && window.GeneralWebInterface.allowFileDrop(false, { dropBehaviour: 'off' });
        keycloak ? keycloak.logout() : window.location.reload();
    };

    return handler;
};
