import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    itemContainer: {
        maxWidth: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        margin: theme.spacing(1)
    },
    avatarContainer: {
        width: '20%',
        display: 'inline-flex'
    },
    nameContainer: {
        display: 'flex',
        width: '80%',
        flexWrap: 'wrap'
    },
    userAvatar: {
        width: 30,
        height: 30,
        fontSize: 'small'
    },
    nameEmail: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    clearIconContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    removeIcon: {
        color: theme.palette.text.main,
        opacity: 0.8
    }
}));
