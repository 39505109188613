import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import { Person } from '@material-ui/icons';
import DnsIcon from '@material-ui/icons/Dns';
import Option from './Option';
import useStyles from './AuthMethods.styles';
import { useConfirmDialogue } from '@mediaseal-webui/hooks';
import { InitialState } from '@mediaseal-webui/types/redux';

const AuthMethods = () => {
    const classes = useStyles();

    const { type } = useSelector((state: InitialState) => state.entity.create);
    const { openDialog } = useConfirmDialogue();

    const [overlap] = useState('rectangle');

    const handleSelected = (authType: string) => () => openDialog(authType);

    return (
        <Grid alignItems='center' container>
            <Grid item xs={12} md={12} lg={12}>
                <Typography color='textPrimary' variant='h5' className={`${classes.bold} ${classes.titlePadding}`}>
                    Security
                </Typography>
                <Typography variant='body1' color='textSecondary'>
                    Here are three different security options you can choose from to protect the files associated with this job. To find out more about each
                    just hover over each option.
                </Typography>
            </Grid>
            <Grid className={classes.inputContainer} item xs={12} md={12} lg={12}>
                <Grid container spacing={2}>
                    <Option id='password' selected={type === 'PASSWORD_ONLY'} overlap={overlap} title='Password' onClick={handleSelected('password')}>
                        <EnhancedEncryptionIcon fontSize='large' className={classes.icon} />
                    </Option>
                    <Option id='offline' selected={type === 'OFFLINE'} overlap={overlap} title='Password + User' onClick={handleSelected('offline')}>
                        <EnhancedEncryptionIcon fontSize='large' className={classes.icon} />
                        <Person fontSize='large' className={classes.icon} />
                    </Option>
                    <Option id='online' selected={type === 'ONLINE'} overlap={overlap} title='Password + User + Server' onClick={handleSelected('online')}>
                        <EnhancedEncryptionIcon fontSize='large' className={classes.icon} />
                        <Person fontSize='large' className={classes.icon} />
                        <DnsIcon fontSize='large' className={classes.icon} />
                    </Option>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AuthMethods;
