import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Send } from '@material-ui/icons';
import CreateForm from '../../../components/titles-components/creation-view-components/CreateForm';
import { Header, HeaderItemsConfig } from '../../../components/layout-components';
import SummaryList from '../../../components/utility-components/SummaryList';
import { fetchEncryptorGroups, clearGroups, fetchEncryptorUsers, clearUsers } from '@mediaseal-webui/actions';
import { clearTitleData, postTitle } from '../../../actions/titles';
import { useEntityCreation } from '@mediaseal-webui/hooks';
import useStyles from './Create.styles';
import { Content, SubmitButton as Submit } from '@mediaseal-webui/components';
import { InitialState } from '@mediaseal-webui/types/redux';
import { useCleanEntity } from 'hooks/useCleanEntity';

const Create = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const { users, encryptorGroups, name } = useSelector(({ entity: { create } }: InitialState) => create);
    const { valid } = useEntityCreation();

    const [open, setOpen] = useState(false);

    useCleanEntity();

    useEffect(() => {
        dispatch(
            fetchEncryptorUsers({
                keyword: '',
                sort: 'id,asc',
                page: 0,
                firstCall: true
            })
        );
        dispatch(
            fetchEncryptorGroups({
                keyword: '',
                sort: 'id,asc',
                page: 0,
                firstCall: true
            })
        );
        return () => {
            dispatch(clearGroups());
            dispatch(clearUsers());
            dispatch(clearTitleData());
        };
    }, [dispatch]);

    const handleSubmit = () => {
        setOpen(!open);
        dispatch(postTitle(history));
    };

    const SubmitButton = (props: any) => <Submit title='Save Title' Icon={Send} aria-label='Post Title' {...props} disabled={!valid} />;
    const headerItems: HeaderItemsConfig = {
        stateKey: 'create',
        encUsers: users && users?.length > 0 ? {} : undefined,
        encGroups: encryptorGroups && encryptorGroups?.length ? {} : undefined
    };
    return (
        <div className={classes.viewRoot}>
            <Header
                defaultName='Create a Title'
                name={name}
                onSubmit={handleSubmit}
                ActionButton={SubmitButton}
                date={new Date()}
                breadcrumbProps={{
                    links: [{ id: 0, name: 'Titles', to: '/titles' }],
                    text: [{ id: 0, name: 'Create' }]
                }}
                headerItems={headerItems}
            />

            <Content>
                {(classes) => (
                    <div className={`${classes.wrapper} ${classes.cols}`}>
                        <CreateForm handleSubmit={handleSubmit} />
                        <div className={classes.summary}>
                            <SummaryList open={open} setOpen={setOpen} subtitleEntity='title' />
                        </div>
                    </div>
                )}
            </Content>
        </div>
    );
};

export default Create;
