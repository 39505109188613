/* eslint import/no-webpack-loader-syntax: off */
// @ts-expect-error due to no default export ts(2613).
import heartbeat from 'workerize-loader!../../workers/heartbeat';

const heartbeatWorker = heartbeat();

export const useHeartbeatWorker = () => {
    return {
        heartbeatWorker
    };
};
