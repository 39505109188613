import {
    SET_CONFIRM_DIALOGUE,
    RESET_CONFIRM_DIALOGUE,
    RESET_SNACKBAR,
    START_FETCHING,
    STOP_FETCHING,
    INIT_FETCH,
    SET_PREFERENCE,
    CLEAR_SEARCHES,
    SET_VERSION_NUMBER,
    SHOW_ABOUT_MODAL,
    SET_API_VERSION,
    SET_USER_PERMISSIONS,
    CLEAR_ERRORS
} from '@mediaseal-webui/constants';
import { msApi } from '@mediaseal-webui/api';

/**
 * Reset
 */
export const resetSnackbar = () => ({ type: RESET_SNACKBAR });
export const resetErrors = () => ({ type: CLEAR_ERRORS });

/**
 * Confirm Dialogue
 */
export const setConfirmDialogue = (payload) => ({
    type: SET_CONFIRM_DIALOGUE,
    payload
});
export const resetConfirmDialogue = () => ({ type: RESET_CONFIRM_DIALOGUE });

/**
 * Fetching
 */
export const startFetching = () => ({ type: START_FETCHING });
export const stopFetching = () => ({ type: STOP_FETCHING });
export const initialFetch = () => ({ type: INIT_FETCH });

/**
 * Preferences
 */
export const setPreference = (key) => ({
    type: SET_PREFERENCE,
    payload: { [key]: true }
});
export const removePreference = (key) => ({
    type: SET_PREFERENCE,
    payload: { [key]: false }
});

/**
 * Search
 */
export const clearSearches = () => ({ type: CLEAR_SEARCHES });

/**
 * QT versioning
 */
export const applicationVersionRequested = (version) => (dispatch) => {
    console.log(`Recieved version: ${version} from QT`);
    dispatch({ type: SET_VERSION_NUMBER, payload: { clientVersion: version } });
};

/**
 *
 * API Version
 */

export const fetchApiVersion = () => async (dispatch) => {
    try {
        const { data } = await msApi.get('/services/version');
        dispatch({ type: SET_API_VERSION, payload: { apiVersion: data.version } });
    } catch (error) {
        console.error(error);
    }
};

export const setShowAboutModal = (bool) => ({
    type: SHOW_ABOUT_MODAL,
    payload: bool
});

/**
 * Permissions
 */

export const fetchUserPermissions = (id) => async (dispatch, getState) => {
    try {
        const { data } = await msApi.get(`/permissions/search/findByUserId?userId=${id}`);
        const { content } = data;
        dispatch({ type: SET_USER_PERMISSIONS, payload: content });
    } catch (error) {
        console.error(error);
    }
};

export const setOfflineApp = () => ({ type: 'OFFLINE' });

export const openJobHelpModal = (bool) => ({
    type: OPEN_SECURITY_METHOD_MODAL,
    payload: bool
});
