import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RowData, TextCell } from '@mediaseal-webui/components';
import { InitialState } from '@mediaseal-webui/types';

/**
 * @param {object} entry -- entity passed from reporting table
 * @param {boolean} isFetching --
 */
interface FileNameCellProps {
    entry?: RowData;
}

const FileNameCell = ({ entry }: FileNameCellProps) => {
    const isSaveOut = !!entry?.openAssets;
    const [output, setOutput] = useState('');
    const { isFetching } = useSelector((state: InitialState) => state.misc);
    const removeFilePath = (path) => path.substring(path.lastIndexOf('/') + 1 || path.lastIndexOf('\\') + 1);

    useEffect(() => {
        if (isSaveOut) {
            setOutput(removeFilePath(entry?.outFilePath));
        } else {
            setOutput(removeFilePath(entry?.userFilePath === null ? entry?.file?.path ?? '' : entry?.userFilePath ?? ''));
        }
        return () => {
            setOutput('');
        };
    }, [entry, isSaveOut]);

    return <TextCell output={output} subtitle={!isSaveOut && entry?.fileId ? `ID: ${entry.fileId}` : ''} skeletonHeight={15} isFetching={isFetching} />;
};

export default FileNameCell;
