import { SpringBootResponse } from '@mediaseal-webui/types';
import { definitions } from '@mediaseal-webui/types/api';
import { AxiosRequestConfig } from 'axios';
import { BaseService } from '..';

class TokensService extends BaseService<SpringBootResponse<definitions['Page«TokenGetDTO»'][]>, {}> {
    constructor(public config?: AxiosRequestConfig) {
        super('/tokens', config);
    }

    public async fetchTokens() {
        return await this.get(this._createQueryString(this.baseParams));
    }

    public async searchTokens(keyword: string) {
        this.baseParams = {
            ...this.baseParams,
            keyword
        };
        return await this.fetchTokens();
    }

    public async createToken(body: definitions['TokenPostDTO']) {
        return await this.baseService.post(`${this.url}`, body);
    }
}

export { TokensService };
