import { SET_FILE_PROCESSED } from '@mediaseal-webui/constants';
import { IOnlineEncryptionWork } from '@mediaseal-webui/types/encryptor';
/**
 *
 * @param param0
 * @returns
 *
 * Used to signal to QT to start online encryption work
 */
export const startOnlineEncryptionWork = ({ id, fileSettings, fileFeatures = [] }: IOnlineEncryptionWork, dispatch: any) => {
    console.log('calling startOnlineEncryptionWork with id: ' + id);
    window.EncryptionWebInterface.startOnlineEncryptionWork(id, fileSettings, fileFeatures);
    dispatch({ type: SET_FILE_PROCESSED, payload: { id } });
};
