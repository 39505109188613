import React from 'react';
import { useSelector } from 'react-redux';
import useStyles from './SummaryList.styles';
import { List, Typography, Grid, useMediaQuery, useTheme, Modal, Paper, Button } from '@material-ui/core/';
import { useSummaryList } from '@mediaseal-webui/hooks';
import FormGroup from '../FormGroup';
import { formateDateStyle } from '../../../helpers';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import clsx from 'clsx';
import { UserItem } from './UserItem/UserItem';
import { Group, InitialState, User } from '@mediaseal-webui/types/redux';

interface SummaryListProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    subtitleEntity: string;
}

const SummaryList = (props: SummaryListProps) => {
    const classes = useStyles();
    const { setOpen, open, subtitleEntity } = props;
    const { decryptorUsers, users, decryptorGroups, encryptorGroups } = useSelector(({ entity: { summary } }: InitialState) => summary);
    const { name } = useSelector(({ entity: { profile } }: InitialState) => profile);
    const { isFetching } = useSelector(({ misc }: InitialState) => misc);
    const { removeEncUser, removeDecUser, removeDecGroup, removeEncGroup } = useSummaryList();

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

    const handleClose = () => {
        setOpen(!open);
    };

    const handleRemoveEncUser = (user: User) => removeEncUser(user);
    const handleRemoveDecUser = (user: User) => removeDecUser(user);
    const handleRemoveDecGroup = (group: Group) => removeDecGroup(group);
    const handleRemoveEncGroup = (group: Group) => removeEncGroup(group);

    const NoResults = () => {
        return (
            <>
                <Typography color='textPrimary' className={clsx([classes.noResults, classes.root])} variant='body2'>
                    No users or groups assigned.
                </Typography>
            </>
        );
    };

    const Component = () => {
        if (
            !isFetching &&
            (!users || users.length === 0) &&
            (!decryptorGroups || decryptorGroups.length === 0) &&
            (!decryptorUsers || decryptorUsers.length === 0) &&
            (!encryptorGroups || encryptorGroups.length === 0)
        ) {
            return <NoResults />;
        }
        return (
            <>
                <List className={classes.root} id='summaryList'>
                    {users && users.length > 0 ? (
                        <FormGroup classes={classes} heading='Encryptor Users' subtitle={`Encryptor users assigned to the ${subtitleEntity}`}>
                            <List data-cy='user-list' className={classes.userList}>
                                {users.map((user) => (
                                    <li key={user.id} className={classes.listItem}>
                                        <UserItem isFetching={isFetching} data={user} setterFn={handleRemoveEncUser} />
                                    </li>
                                ))}
                            </List>
                        </FormGroup>
                    ) : null}
                    {decryptorUsers && decryptorUsers.length > 0 ? (
                        <FormGroup classes={classes} heading='Decryptor Users' subtitle={`Decryptor users assigned to the ${subtitleEntity}`}>
                            <List id='recipient-list' className={classes.userList}>
                                {decryptorUsers.map((user) => (
                                    <li key={user.id} className={classes.listItem}>
                                        <UserItem isFetching={isFetching} data={user} setterFn={handleRemoveDecUser} />
                                    </li>
                                ))}
                            </List>
                        </FormGroup>
                    ) : null}
                    {decryptorGroups && decryptorGroups.length > 0 ? (
                        <FormGroup classes={classes} heading='Decryptor Groups' subtitle={`Decryptor groups assigned to the ${subtitleEntity}`}>
                            <List id='recipient-group-list' className={classes.userList}>
                                {decryptorGroups.map((group) => (
                                    <li key={group.id} className={classes.listItem}>
                                        <UserItem isFetching={isFetching} data={group} setterFn={handleRemoveDecGroup} />
                                    </li>
                                ))}
                            </List>
                        </FormGroup>
                    ) : null}
                    {encryptorGroups && encryptorGroups?.length > 0 && (
                        <FormGroup classes={classes} heading='Encryptor Groups' subtitle={`Encryptor groups assigned to the ${subtitleEntity}`}>
                            <List data-cy='group-list' className={classes.userList}>
                                {encryptorGroups?.map((group) => (
                                    <li key={group.id} className={classes.listItem}>
                                        <UserItem isFetching={isFetching} data={group} setterFn={handleRemoveEncGroup} />
                                    </li>
                                ))}
                            </List>
                        </FormGroup>
                    )}
                </List>
            </>
        );
    };

    return mobile ? (
        <Modal data-cy='mobileModal' open={open} onClose={handleClose}>
            <Paper className={classes.paper} style={{ outline: 'none' }}>
                <Grid container className={classes.titleContainer} alignItems='center'>
                    <Grid item xs={9}>
                        <Typography color='textPrimary' id='modalName' variant='h6'>
                            {name}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.tables}>
                    <div>
                        <Typography id='summaryDate' variant='subtitle2' color='textSecondary'>
                            <CalendarTodayIcon className={classes.headerIcon} />
                            {formateDateStyle()}
                        </Typography>
                        {users && users.length > 0 ? (
                            <Typography id='usersSelected' color='textSecondary' variant='subtitle2'>
                                <PersonIcon className={classes.headerIcon} />
                                {users.length === 1 ? '1 Decryptor User' : `${users.length} Decryptor Users`}
                            </Typography>
                        ) : null}
                        {decryptorGroups && decryptorGroups.length > 0 ? (
                            <Typography id='recipentGroupsSelected' color='textSecondary' variant='subtitle2'>
                                <GroupIcon className={classes.headerIcon} />
                                {decryptorGroups.length === 1 ? '1 Decryptor Group' : `${decryptorGroups.length} Decryptor Groups`}
                            </Typography>
                        ) : null}
                    </div>
                    <Component />
                </Grid>
                <Grid container className={classes.bottomButtons}>
                    <Grid item xs={6}>
                        <Button id='cancelBtn' fullWidth onClick={() => setOpen(!open)}>
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    ) : (
        <Component />
    );
};

export default SummaryList;
