import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    archiveBox: {
        position: 'absolute',
        bottom: 0,
        left: 12,
        width: 36,
        fill: theme.palette.text.secondary
    },
    archiveLid: {
        position: 'absolute',
        bottom: 32,
        width: 48,
        left: 6,
        fill: theme.palette.text.secondary,
        transition: `all 0.3s ease`,
        animation: `$lid-open 0.4s forwards`,
        animationDelay: '1s',
        '&.loading': {
            animation: `$lid-close 0.4s forwards`
        }
    },
    '@keyframes lid-open': {
        from: {
            transform: `rotate(0deg) translate(0,0)`
        },
        to: {
            transform: `rotate(82deg) translate(-4px, -25px)`
        }
    },
    '@keyframes lid-close': {
        from: {
            transform: `rotate(82deg) translate(-4px, -25px)`
        },
        to: {
            transform: `rotate(0deg) translate(0,0)`
        }
    },
    archiveDownArrow: {
        position: 'absolute',
        bottom: -2,
        width: 19,
        left: 21,
        fill: theme.palette.text.secondary
    },
    archiveLoading: {
        position: 'absolute',
        bottom: 12,
        width: 12,
        left: 24,
        fill: theme.palette.text.secondary
    },
    archiveComplete: {
        position: 'absolute',
        bottom: -2,
        width: 19,
        left: 21,
        fill: theme.palette.success.main
    },
    archiveFilmStrip: {
        opacity: 0,
        animation: `$appear 0.1s forwards`,
        animationDelay: '1.5s',
        position: 'absolute',
        bottom: 32,
        left: 12,
        overflow: 'hidden',
        '& div': {
            display: 'flex',
            flexDirection: 'column'
        },
        '& svg': {
            width: 36,
            marginTop: -13,
            animation: `$film-strip 0.7s infinite linear`
        }
    },
    '@keyframes appear': {
        from: {
            opacity: 0
        },
        to: {
            opacity: 1
        }
    },
    '@keyframes film-strip': {
        '100%': {
            transform: `translateY(66%)`
        }
    }
}));

export default useStyles;
