import { makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((theme) => {
    const width = `calc(100% - ${2 * theme.spacing(4)}px)`;
    return {
        container: {
            width,
            position: 'relative',
            margin: theme.spacing(4),
            padding: theme.spacing(2),
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: theme.spacing(1),
            [theme.breakpoints.down('xs')]: {
                width: 'unset',
                margin: theme.spacing(1),
                padding: theme.spacing(1),
                alignItems: 'end'
            }
        },
        cancelBtn: {
            borderRadius: '30px',
            height: '40px',
            margin: '0 5px',
            padding: '0 16px'
        },
        titleContainer: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            paddingBottom: theme.spacing(2)
        },
        title: {},
        buttonContainer: {
            textAlign: 'right'
        },
        extendedIcon: {
            marginRight: theme.spacing(1)
        },
        formActions: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%'
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: '100%',
            width: '100%',
            backgroundColor: fade(theme.palette.common.white, 0.5),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 50
        }
    };
});

export default useStyles;
