import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    wrapper: {
        padding: theme.spacing(2),
        textAlign: 'center'
    },
    item: {
        margin: theme.spacing(2)
    },
    label: {
        flexWrap: 'wrap',
        textAlign: 'center'
    },
    downloadWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'center'
    },
    downloadButton: {
        display: 'flex',
        flexWrap: 'wrap',
        minHeight: 200,
        width: '50%'
    },
    downloadText: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
    },
    downloadHeader: {
        width: '100%',
        margin: theme.spacing(2)
    }
}));
