import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    modalRoot: {
        padding: theme.spacing(3),
        margin: theme.spacing(5),
        height: `calc(100vh - ${theme.spacing(10)}px)`,
        background: theme.palette.background.paper,
        borderRadius: theme.spacing(1 / 2),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2),
            padding: theme.spacing(2)
        }
    },
    title: {
        height: theme.spacing(6),
        paddingBottom: theme.spacing(2)
    },
    tableHolder: {
        height: `calc(100% - ${theme.spacing(19)}px)`, // modal height - margin - title - buttons
        width: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2)
    },
    tableHolderTokens: {
        height: `calc(100% - ${theme.spacing(10)}px)` // modal height - margin - title - buttons
    },
    buttons: {
        width: '100%',
        textAlign: 'right'
    },
    totalsHolder: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: theme.spacing(5),
        paddingBottom: theme.spacing(2)
    },
    searchWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: theme.spacing(2)
    },
    searchbar: {
        width: '100%'
    }
}));

export default useStyles;
