import React from 'react';
import { EditableHeaderChildProps, EditableHeaderItem } from '../../EditableHeaderItem';
import { HeaderItem, GenericHeaderItemProps } from '..';

export const SecurePlayerHeaderItems = ({ isFetching, icon, tooltipOpen, title = 'Secure Playback', id }: GenericHeaderItemProps) => {
    return (
        <EditableHeaderItem title={title} open={tooltipOpen}>
            {({ hovering }: EditableHeaderChildProps) => <HeaderItem hovering={hovering} Icon={icon} isFetching={isFetching} id={id} />}
        </EditableHeaderItem>
    );
};
