import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import useStyles from './ReportingFilters.styles';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import DateFilter from '../DateFilter/DateFilter';
import ActionsFilter from '../ActionsFilter/ActionsFilter';
import FilterUsers from '../FilterUsers/FilterUsers';
import { PageControlProps, ReportingActions } from '@mediaseal-webui/types';
import { SET_REPORT_DATA, SET_REPORT_ELEMENTS, SET_TOTAL_REPORT_PAGES, FETCH_ERROR, START_FETCHING, STOP_FETCHING } from '@mediaseal-webui/constants';
import { FilterTag } from '@mediaseal-webui/components';
import { AuditFileContext } from '@mediaseal-webui/hooks';
import { AuditService } from '@mediaseal-webui/services';
import { useAppSelector } from 'store';
interface ReportingFiltersProps {
    pageControl: PageControlProps;
    service: AuditService;
}

const ReportingFilters = ({ pageControl, service: auditService, ...others }: ReportingFiltersProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const matchesSm = useMediaQuery('(max-width: 800px)');
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const { file } = useContext(AuditFileContext);
    const { isDecryptorTab } = useAppSelector((state) => state.reporting);

    const fetchReports = useCallback(async () => {
        try {
            dispatch({ type: START_FETCHING });
            const { data } = await auditService.getAudits();
            dispatch({ type: SET_TOTAL_REPORT_PAGES, payload: data.totalPages });
            dispatch({ type: SET_REPORT_ELEMENTS, payload: data.totalElements });
            dispatch({ type: SET_REPORT_DATA, payload: data.content });
            dispatch({ type: STOP_FETCHING });
        } catch (error) {
            dispatch({
                type: FETCH_ERROR,
                payload: { error: true, message: 'The request could not be made, please try again' }
            });
        }
    }, [dispatch, auditService]);

    const handleRemoval =
        ({ tagName }) =>
        () => {
            auditService.setParams({
                [tagName]: ''
            });
            fetchReports();
        };
    const setToDate = (date: Date) => {
        auditService.setParams({
            page: 0,
            endDate: date.toISOString()
        });
        fetchReports();
    };

    const setFromDate = (date: Date) => {
        auditService.setParams({
            page: 0,
            startDate: date.toISOString()
        });
        fetchReports();
    };

    const setFilterDecryptorUser = useCallback(
        async (value: { id: number | string }) => {
            auditService.setParams({
                page: 0,
                userId: value?.id || ''
            });
            fetchReports();
        },
        [auditService, fetchReports]
    );

    const setFilterAction = useCallback(
        (option: ReportingActions) => {
            if (option.query === '') {
                auditService.url = '/audits/fileSave';
                auditService.setParams({
                    page: 0,
                    ...(isDecryptorTab ? { access: '' } : { action: '' }),
                    sort: 'id,desc',
                    sortOrder: 'desc',
                    sortBy: 'id'
                });
            } else {
                auditService.url = isDecryptorTab ? '/audits/fileAccess' : '/audits/activity';
                auditService.setParams({
                    page: 0,
                    ...(isDecryptorTab ? { access: option.query } : { action: option.query })
                });
            }
            fetchReports();
        },
        [fetchReports, auditService, isDecryptorTab]
    );

    return (
        <div className={classes.container}>
            <FilterUsers {...others} pageControl={pageControl} setterFn={setFilterDecryptorUser} responsive={matchesXs} placeholder='Select user' />
            <ActionsFilter setterFn={setFilterAction} responsive={matchesSm} />
            <DateFilter
                data-testid='date-range-pickers'
                responsive={matchesMd}
                toDateSetter={setToDate}
                to={new Date(auditService.auditParams.endDate!)}
                from={new Date(auditService.auditParams.startDate!)}
                fromDateSetter={setFromDate}
            />
            {Object.entries(auditService.auditParams)
                .filter(([key]) => key === 'fileId')
                .map(
                    ([key, value]) =>
                        value !== '' && <FilterTag key={value} tag={{ tagName: file?.name, tagValue: value }} handleClose={handleRemoval({ tagName: key })} />
                )}
        </div>
    );
};

export default React.memo(ReportingFilters);
