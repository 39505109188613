import React from 'react';
import Link from './Link';
import { INavLink } from '../../../../routes';
import { ListItemProps } from '@material-ui/core';

export interface NavItemProps extends Pick<ListItemProps, 'id' | 'onClick'> {
    link: INavLink;
    classes: {
        linkContainer: string;
        helpContainer: string;
        icon: string;
        textLink: string;
        textWhenClosed: string;
        popperText: string;
    };
    style?: {
        [key: string]: any;
    };
    popper?: boolean;
    disableLink?: boolean;
}

export interface LinkProps {
    link: INavLink;
    id?: string;
    disableLink?: boolean;
    authCheck?: string;
    authOverride?: boolean;
    onClick?: (event: React.SyntheticEvent) => void;
    help?: boolean;
    useClass?: any;
    isRoot?: boolean;
}

export default Link;
