import { InitialState } from '@mediaseal-webui/types/redux';
import { SettingsCardData, SettingStateKeys } from '@mediaseal-webui/types/settings';
import { useSelector } from 'react-redux';

export const useSettingsCardData = (data: SettingsCardData) => {
    const { server } = useSelector((state: InitialState) => state.settings);
    const formValue = server[data.stateKey];
    const isBlank = formValue === '';
    const prefixBlankStatement =
        'Encrypted files and folders will be prefixed with this value. Leaving the value blank will mean that files will retain their original filename.';
    return {
        ...data,
        formValue,
        description:
            typeof data.description === 'string'
                ? data.stateKey === 'encryptionPrefix'
                    ? isBlank
                        ? prefixBlankStatement
                        : data.description.replace('enc-', formValue)
                    : data.description
                : data.description
    };
};
