import React, { memo } from 'react';
import { TableCell, Typography, Fade, Tooltip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import useStyles from './TextCell.styles';
import { shorteners } from '@mediaseal-webui/utils';
import { TextCellProps } from '.';
import { TableColumns } from '@mediaseal-webui/types/generic';

export const TextCell = memo(
    ({
        output = '',
        isFetching = true,
        subtitle = '',
        rowData = {
            enableTooltip: false
        } as TableColumns,
        skeletonHeight = 10,
        hidden,
        ...others
    }: TextCellProps) => {
        const classes = useStyles();
        const { enableTooltip } = rowData;
        const textOutput = () => (enableTooltip && rowData.utilFn ? shorteners[rowData?.utilFn](String(output)) : output);
        const typOverflowStyles: React.CSSProperties = {
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        };

        return isFetching ? (
            <Fade in timeout={100}>
                <TableCell component='div' {...others} classes={{ root: classes.tableCell }} scope='row'>
                    <Skeleton data-testid='loading-skeleton' animation='pulse' width='100%' height={15} style={{ marginBottom: 6 }} />
                    <Skeleton animation='pulse' height={15} width='50%' />
                </TableCell>
            </Fade>
        ) : (
            <TableCell component='div' {...others} classes={{ root: classes.tableCell }} scope='row'>
                <div id='tooltip-container' style={{ position: 'relative' }}>
                    <Tooltip title={enableTooltip ? output : ''} placement='top'>
                        <span>
                            <Typography color='textPrimary' variant='body1' style={typOverflowStyles}>
                                {textOutput()}
                            </Typography>
                            <Typography color='textPrimary' variant='body2' color='textSecondary' className={classes.authSubtitle}>
                                {subtitle}
                            </Typography>
                        </span>
                    </Tooltip>
                </div>
            </TableCell>
        );
    }
);
