import React from 'react';
import { Typography, Button, Tooltip } from '@material-ui/core';
import { Folder as FileIcon } from '@material-ui/icons';
import { FileIssuesRowProps, useStyles } from '..';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { cancelFileWithIssue, confirmFileOverwrite } from '@mediaseal-webui/actions';

export const FileRow = ({ file }: FileIssuesRowProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleCancel = () => dispatch(cancelFileWithIssue(file));
    const handleOverwrite = () => dispatch(confirmFileOverwrite(file));

    return (
        <div data-testid='file-issue-row' className={classes.listItem}>
            <Tooltip title={file.workIssue.errorMessage}>
                <div className={classes.listItemText}>
                    <FileIcon />
                    <Typography color='textPrimary' variant='body1'>
                        {file.inputFileName}
                    </Typography>
                </div>
            </Tooltip>
            <div
                className={clsx(classes.buttons, {
                    [classes.buttonsWithOverwrite]: file.workIssue.errorType === 'OutputConflict'
                })}>
                {file.workIssue.errorType === 'OutputConflict' && (
                    <Button onClick={handleOverwrite} color='primary' data-testid='overwrite-file-button'>
                        Overwrite
                    </Button>
                )}
                <Button onClick={handleCancel} data-testid='cancel-file-button' className={classes.itemCancelButton}>
                    Cancel
                </Button>
            </div>
        </div>
    );
};
