import { format } from 'date-fns';
import React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Typography, useMediaQuery, DialogTitle, DialogContent, DialogActions, Button, Dialog, IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PersonIcon from '@material-ui/icons/Person';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import { closeEntitySummary } from '../../../actions/reporting';
import { CLEAR_ENTITY } from '../../../constants';

import responsiveModalStyle from './ResponsiveSummaryModal.styles';
import MainSummaryInfo from './MainSummaryInfo/MainSummaryInfo';

const ResponsiveSummaryModal = () => {
    const [expanded, setExpanded] = React.useState('panel0');
    const { showSummary, isDecryptorTab } = useSelector((state) => state.reporting);
    const { profile } = useSelector((state) => state.entity);
    const theme = useTheme();
    const isSaveOut = !!profile.openAssets && isDecryptorTab;
    const isFileAccess = !profile.openAssets && isDecryptorTab;
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = responsiveModalStyle();
    const dispatch = useDispatch();

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleSummaryClose = () => {
        dispatch(closeEntitySummary());
        setTimeout(() => dispatch({ type: CLEAR_ENTITY }), 500);
    };

    useEffect(
        () => () => {
            dispatch(closeEntitySummary());
        },
        [dispatch]
    );

    return (
        <Dialog open={showSummary} onClose={handleSummaryClose} aria-labelledby='entity-info-dialog' fullWidth className={classes.blur}>
            <div className={classes.modalHeader}>
                <DialogTitle id='entity-info-dialog-title'>Item Summary</DialogTitle>
                {fullScreen && (
                    <div style={{ display: 'flex' }}>
                        <IconButton onClick={handleSummaryClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                )}
            </div>

            <DialogContent>
                {isSaveOut && (
                    <Typography color='textPrimary' className={classes.mainSubtitle} variant='subtitle1'>
                        <strong>Save Out Information</strong>
                    </Typography>
                )}

                <div className={classes.scrollContainer}>
                    {isSaveOut &&
                        profile.openAssets.map((item, i) => (
                            <MainSummaryInfo square expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)} item={item} key={i} />
                        ))}
                </div>
                {isFileAccess && (
                    <>
                        <Typography color='textPrimary' className={classes.mainSubtitle} variant='subtitle1'>
                            <strong>File Access Information</strong>
                        </Typography>
                        <MainSummaryInfo expanded item={profile} />
                    </>
                )}
                {!isDecryptorTab && (
                    <>
                        <Typography color='textPrimary' className={classes.mainSubtitle} variant='subtitle1'>
                            <strong>User Activity Information</strong>
                        </Typography>
                        <MainSummaryInfo expanded item={profile} />
                    </>
                )}
                {(isFileAccess || !isDecryptorTab) && (
                    <>
                        <Typography color='textPrimary' variant='subtitle1' className={classes.mainSubtitle}>
                            <strong>System Info</strong>
                        </Typography>
                        {
                            <DialogContent>
                                <Typography color='textPrimary' variant='body1'>
                                    IP Addresses: {profile.ipAddresses || profile.machineInfo || 'Unavailable'}
                                </Typography>
                                {isDecryptorTab && (
                                    <>
                                        <Typography color='textPrimary' variant='body1'>
                                            MAC Addresses: {profile.macAddresses || 'Unavailable'}
                                        </Typography>
                                        <Typography color='textPrimary' variant='body1'>
                                            Host Username: {profile.hostUserName || 'Unavailable'}
                                        </Typography>
                                    </>
                                )}
                            </DialogContent>
                        }
                    </>
                )}
                {!fullScreen && (
                    <DialogActions>
                        <Button autoFocus onClick={handleSummaryClose} className={classes.button}>
                            Close
                        </Button>
                    </DialogActions>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ResponsiveSummaryModal;
