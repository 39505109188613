import { CreateJobSchema, UpdateJobSchema, SortStrings } from '@mediaseal-webui/types';
import { BaseService, BaseServiceParams } from '../BaseService/BaseService';
import { AxiosRequestConfig } from 'axios';
import { definitions, SpringBootResponse } from '@mediaseal-webui/types/api';

interface JobsServiceConfig {
    url?: string;
    config?: AxiosRequestConfig;
    params?: string[];
}

export interface AllJobsParams {
    isArchived?: 'false' | 'true' | '';
    isTemplate?: 'false' | 'true' | '';
    isBatched?: 'false' | 'true' | '';
    isActive?: 'false' | 'true' | '';
    projection: 'simple';
}
const initParams = {
    projection: 'simple',
    isArchived: 'false'
};
export class JobsService extends BaseService<SpringBootResponse<definitions['Job'][]>, {}> {
    jobsParams: AllJobsParams;
    constructor(public config?: AxiosRequestConfig) {
        super('/jobs/', config);
        this.jobsParams = {
            projection: 'simple',
            isArchived: 'false'
        };
    }

    /**
     *
     * All Jobs - fetches all jobs with base params, extra can be provided
     */
    public async fetchJobs(extraParams?: string) {
        const query = this.createJobsQueryString({
            ...this.baseParams,
            ...this.jobsParams
        });
        return await this.get(`search/${extraParams}${query}`);
    }

    public async searchJobs({ extraParams, keyword }: { extraParams?: string; keyword: string }) {
        this.baseParams = {
            ...this.baseParams,
            keyword
        };
        return await this.fetchJobs(extraParams);
    }

    /**
     *  Fetch Jobs by Title Id
     * @returns List of jobs by title
     */
    public async fetchJobsByTitle(id: string) {
        const query = this.createJobsQueryString({
            ...this.baseParams,
            ...this.jobsParams
        });

        return await this.get(`search/findByTitleId${query}&titleId=${id}`);
    }

    /**
     *
     * Individual Job
     */
    public async getIndividualJob(id: string) {
        return await this.get(`${id}?projection=simple`);
    }

    public getJobProfileData(id: string) {
        const profile = this.getIndividualJob(id);
        const groups = this.getGroups(id);
        const recipients = this.getRecipients(id);
        const stats = this.getJobStats(id);
        const histogram = this.getJobHistogram(id);
        return Promise.all([profile, groups, recipients, stats, histogram]);
    }
    /**
     * getGroups
     */
    public getGroups(id: string | number) {
        return this.get(`search/${id}/stationGroups`);
    }

    /**
     * getRecipients
     */
    public getRecipients(id: string | number) {
        return this.get(`search/${id}/recipients`);
    }

    /**
     * getJobStats
     */
    public getJobStats(id: string | number) {
        return this.get(`search/${id}/stats`);
    }

    /**
     * getJobHistogram
     */
    public getJobHistogram(id: string | number) {
        return this.get(`search/${id}/histogram`);
    }
    /**
     * update - PATCH a job to update its data
     */
    public update(id: number | string, body: Partial<definitions['Job']>) {
        return this.patch(`${id}?projection=simple`, body);
    }
    /**
     *  create - used to create a job
     */
    public create(params: definitions['Job']) {
        return this.post(params);
    }

    // public setParams(params: Partial<BaseServiceParams>) {
    //   super.setParams(params)
    //   this.jobsParams = {
    //     ...this.jobsParams,
    //     ...params,
    //   }
    // }

    public resetParams() {
        super.resetParams();
        this.jobsParams = {
            projection: 'simple',
            isArchived: 'false'
        };
    }

    private createJobsQueryString(params: BaseServiceParams & AllJobsParams) {
        return `${this._createQueryString(params)}`;
    }
}
