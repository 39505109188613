import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { Typography, FormLabel, Card, FormGroup } from '@material-ui/core';
import { Header } from '../../../../components/layout-components';
import { AddGroup, MainTable } from '@mediaseal-webui/components';
import { fetchGroupsForDecUser, fetchDecryptorUser, updateDecryptorUser, setConfirmDialogue } from '@mediaseal-webui/actions';
import { profileGenericHeader, profileGenericHeaderWithoutActions } from '../../../../data/profile-table-headers';
import { removeEntityAndUpdate, removeDecryptorGroupFromSummary } from '../../../../actions/entity';
import ActionContainer from '../../../../components/table-components/ActionContainer/ActionContainer';
import { nameLength, useAuth, emailCheck, useProfileCheck } from '@mediaseal-webui/hooks';
import { EditableEntityTitle, Content, AddItemButton } from '@mediaseal-webui/components';
import { GroupSelection } from '../../../../components/utility-components/Modals';
import useStyles from './Profile.styles';
import { hideRemoveGroupConfirmation } from '@mediaseal-webui/constants';
import { useCleanEntity } from 'hooks/useCleanEntity';
import { useAppSelector } from 'store';

const GroupsModal = ({ openProps }) => (
    <GroupSelection id='addDecGroupModal' encryptor={false} openProps={openProps} title='Add decryptor groups' updateFn={updateDecryptorUser} />
);

const Profile = () => {
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();
    const auth = useAuth();

    const isEditAuth = auth.edit || auth.administer;
    const isCreateAuth = auth.create || auth.administer;
    const { userPageCount, userPageSearchValue } = useSelector(({ users }) => users);
    const { fullName, email, decryptorGroups, firstName, lastName, createDate } = useSelector(({ entity: { profile } }) => profile);
    const loggedInUser = useAppSelector((state) => state.user);
    const { isOwnProfile } = useProfileCheck(loggedInUser.apiUser);
    const fetchFn = () => fetchGroupsForDecUser(id);

    useCleanEntity();

    useEffect(() => {
        dispatch(fetchDecryptorUser(id));
    }, [dispatch, id]);

    const handleRowClick = ({ id }) => history.push(`/groups/decryptor/${id}/profile`);

    const { items: permissions } = useAppSelector((state) => state.permissions);
    const hasEditDecryptorUsersPermission = permissions.some((permission) => permission.permissionName === 'canEditDecryptorUsers');

    const removeHandler = (group) => () =>
        dispatch(
            setConfirmDialogue({
                icon: 'warning',
                title: 'Remove Group',
                confirmButtonText: 'Remove Group',
                message: 'Are you sure you want to remove this group?',
                action: removeEntityAndUpdate({
                    entity: group,
                    id,
                    removeFn: removeDecryptorGroupFromSummary,
                    updateFn: updateDecryptorUser
                }),
                memorable: hideRemoveGroupConfirmation
            })
        );

    const handleRemove = useCallback(removeHandler, [dispatch, id]);

    const headerItems = {
        decGroups: {
            onClick: () => null
        },
        active: {
            updateFn: () => updateDecryptorUser(id),
            entity: 'user',
            isReadOnly: !hasEditDecryptorUsersPermission || isOwnProfile
        }
    };

    return (
        <div className={classes.viewRoot}>
            <Header
                skeleton
                profile
                date={createDate ? new Date(createDate) : undefined}
                name={fullName}
                breadcrumbProps={{
                    links: [{ id: 0, name: 'Decryptor Users', to: '/users/decryptor' }],
                    text: [{ id: 0, name: fullName }]
                }}
                headerItems={headerItems}
            />

            <Content>
                {(cl) => (
                    <div className={`${cl.stack} ${cl.noWrap} ${cl.cardWrapper}`}>
                        <div className={cl.flex}>
                            <Card elevation={0} className={`${cl.smallProfileCard} ${cl.leftCard}`}>
                                <Typography color='textPrimary' variant='subtitle2'>
                                    Account Details
                                </Typography>
                                {isEditAuth && hasEditDecryptorUsersPermission ? (
                                    <EditableEntityTitle
                                        id='email-edit'
                                        value={email}
                                        showInputBackground
                                        maxWidth='250px'
                                        setterFn={updateDecryptorUser}
                                        label='Email'
                                        field='email'
                                        validation={emailCheck}
                                    />
                                ) : (
                                    <>
                                        <FormGroup style={{ padding: 16 }}>
                                            <FormLabel>Email</FormLabel>
                                            <Typography color='textPrimary' variant='body1'>
                                                {email}
                                            </Typography>
                                        </FormGroup>
                                    </>
                                )}
                            </Card>
                            <Card elevation={0} className={`${cl.smallProfileCard} ${cl.rightCard}`}>
                                <Typography color='textPrimary' variant='subtitle2'>
                                    Contact Details
                                </Typography>
                                {isEditAuth && hasEditDecryptorUsersPermission ? (
                                    <div id='edit-first-name'>
                                        <EditableEntityTitle
                                            id='edit-first-name'
                                            maxWidth='250px'
                                            showInputBackground
                                            value={firstName}
                                            setterFn={updateDecryptorUser}
                                            label='First Name'
                                            field='firstName'
                                            validation={nameLength}
                                        />
                                        <EditableEntityTitle
                                            maxWidth='250px'
                                            id='edit-last-name'
                                            showInputBackground
                                            value={lastName}
                                            setterFn={updateDecryptorUser}
                                            label='Last Name'
                                            field='lastName'
                                            validation={nameLength}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <FormGroup style={{ padding: 16 }}>
                                            <FormLabel>Name</FormLabel>
                                            <Typography color='textPrimary' variant='body1'>
                                                {fullName}
                                            </Typography>
                                        </FormGroup>
                                    </>
                                )}
                            </Card>
                        </div>

                        <Card elevation={0} className={cl.profileCard}>
                            <div className={cl.titleBlock}>
                                <Typography color='textPrimary' className={cl.cardTitle} variant='h5' id='card-text'>
                                    Groups
                                </Typography>
                                {isEditAuth && hasEditDecryptorUsersPermission && <AddItemButton id='addGroupBtn' Modal={GroupsModal} Icon={AddGroup} />}
                            </div>
                            <MainTable
                                id='decGroupsTable'
                                entities={decryptorGroups || []}
                                searchValue={userPageSearchValue}
                                totalPages={userPageCount}
                                fetchFn={fetchFn}
                                entityLink='/groups/decryptor'
                                columns={hasEditDecryptorUsersPermission ? profileGenericHeader : profileGenericHeaderWithoutActions}
                                onRowClick={handleRowClick}
                                useContainer
                                height={decryptorGroups ? 450 : 200}
                                tableActionsRenderer={(entity) => (
                                    <ActionContainer
                                        edit={false}
                                        create={isCreateAuth}
                                        editLink={`/groups/decryptor/${entity.id}/edit`}
                                        removeAction={handleRemove(entity)}
                                    />
                                )}
                                noResultsMessage='No groups are associated with this user'
                            />
                        </Card>
                    </div>
                )}
            </Content>
        </div>
    );
};

export default React.memo(Profile);
