import React, { useEffect, useState } from 'react';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { EditableHeaderItem, EditableHeaderChildProps } from '../../EditableHeaderItem';
import { HeaderItem } from '../HeaderItem';
import { BadgeIcon } from '../../BadgeIcon';
import { GenericHeaderItemProps } from '..';
import { useAppSelector } from '@mediaseal-webui/online/src/store';

export const PermissionsHeaderItems = ({
    isFetching,
    modal: PermissionSelection,
    tooltipOpen,
    onModalChange,
    stateKey = 'profile'
}: GenericHeaderItemProps) => {
    const [modalOpen, setModalOpen] = useState(false);
    const { permissions } = useAppSelector((state) => state.entity['edit']);

    const onClick = () => setModalOpen(true);

    useEffect(() => {
        onModalChange(modalOpen);
    }, [modalOpen]);

    return (
        <>
            <EditableHeaderItem title='Permissions' onViewClick={onClick} open={tooltipOpen}>
                {({ hovering }: EditableHeaderChildProps) => (
                    <HeaderItem
                        id='permissions-item'
                        hovering={hovering}
                        Icon={() => (
                            <BadgeIcon Icon={VpnKeyIcon} isHovered={hovering} content={permissions && permissions.filter((perm) => perm.checked).length} />
                        )}
                        isFetching={isFetching}
                    />
                )}
            </EditableHeaderItem>
            {PermissionSelection && stateKey !== 'create' && <PermissionSelection openProps={[modalOpen, setModalOpen]} />}
        </>
    );
};
