import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    checkboxContainer: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    listText: {
        marginRight: theme.spacing(1)
    },
    popover: {
        position: 'relative'
    },
    close: {
        zIndex: 100,
        position: 'absolute',
        top: 5,
        right: 5
    }
}));
