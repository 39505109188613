import { CgMenuGridO } from 'react-icons/cg';
import { BiArchiveIn, BiArchiveOut } from 'react-icons/bi';
import { AiFillFolder, AiOutlineFolder } from 'react-icons/ai';
import { TiTick } from 'react-icons/ti';
import { RiCloseLine } from 'react-icons/ri';
import { TableColumns } from '@mediaseal-webui/types/generic';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { GiFiles } from 'react-icons/gi';
import { BiFileBlank } from 'react-icons/bi';
import { searchJobs } from 'reducers/jobs/jobs';
import { JobsService } from '@mediaseal-webui/services';

export const jobHeader = (service: JobsService): TableColumns[] => [
    { label: 'ID', dataKey: 'id', flexGrow: 0, order: 'asc' },
    { flexGrow: 2, label: 'Name', dataKey: 'name', display: 'flex', order: 'asc' },
    {
        flexGrow: 0.5,
        flexShrink: 0,
        label: 'Created',
        dataKey: 'createDate',
        order: 'asc',
        hidden: ['xs', 'sm'] as Breakpoint[]
    },
    {
        flexGrow: 0.5,
        flexShrink: 0,
        label: 'Last Modified',
        dataKey: 'updateDate',
        order: 'asc',
        hidden: ['xs', 'sm', 'md'] as Breakpoint[]
    },
    {
        label: 'Status',
        dataKey: 'active',
        disableSort: true,
        flexGrow: 0.5,
        flexShrink: 0,
        order: 'asc',
        filter: {
            enabled: true,
            setterFn: (cb: (serv: JobsService) => void) => {
                cb(service);
            },
            fetchFn: searchJobs,
            getterKey: 'jobs',
            getterSubKey: 'status',
            data: [
                {
                    name: 'Archived',
                    setterKey: 'isArchived',
                    buttonData: [
                        {
                            icon: CgMenuGridO,
                            tooltipText: 'Show All',
                            defaultKey: 'allArchived',
                            value: 'allArchived',
                            queryString: ''
                        },
                        {
                            icon: BiArchiveIn,
                            tooltipText: 'Archived Jobs',
                            value: 'archived',
                            queryString: 'true'
                        },
                        {
                            icon: BiArchiveOut,
                            tooltipText: 'Unarchived Jobs',
                            value: 'unarchived',
                            queryString: 'false'
                        }
                    ]
                },
                {
                    name: 'Live Folders',
                    setterKey: 'isTemplate',
                    buttonData: [
                        {
                            icon: CgMenuGridO,
                            tooltipText: 'Show All',
                            defaultKey: 'allLiveFolders',
                            value: 'allLiveFolders',
                            queryString: ''
                        },
                        {
                            icon: AiFillFolder,
                            tooltipText: 'Live Folders Enabled',
                            value: 'liveFolders',
                            queryString: 'true'
                        },
                        {
                            icon: AiOutlineFolder,
                            tooltipText: 'Live Folders Disabled',
                            value: 'liveFoldersDisabled',
                            queryString: 'false'
                        }
                    ]
                },
                {
                    name: 'Active',
                    setterKey: 'isActive',
                    buttonData: [
                        {
                            icon: CgMenuGridO,
                            tooltipText: 'Show All',
                            value: 'allActive',
                            defaultKey: 'allActive',
                            queryString: ''
                        },
                        {
                            icon: TiTick,
                            tooltipText: 'Active Jobs',
                            value: 'active',
                            queryString: 'true'
                        },
                        {
                            icon: RiCloseLine,
                            tooltipText: 'Inactive Jobs',
                            value: 'inactive',
                            queryString: 'false'
                        }
                    ]
                },
                {
                    name: 'Batched',
                    setterKey: 'isBatched',
                    buttonData: [
                        {
                            icon: CgMenuGridO,
                            tooltipText: 'Show All',
                            value: 'allBatched',
                            defaultKey: 'allBatched',
                            queryString: ''
                        },
                        {
                            icon: GiFiles,
                            tooltipText: 'Batched Jobs',
                            value: 'batched',
                            queryString: 'true'
                        },
                        {
                            icon: BiFileBlank,
                            tooltipText: 'Non Batched Jobs',
                            value: 'nonbatched',
                            queryString: 'false'
                        }
                    ]
                }
            ]
        }
    },
    {
        flexGrow: 0,
        flexShrink: 0,
        label: 'Actions',
        dataKey: 'id',
        disableSort: true,
        hidden: ['xs'] as Breakpoint[],
        order: 'asc'
    }
];
