import { useCallback, useState } from 'react';
import { AuditService } from '@mediaseal-webui/services';
import { TableColumns, SortStrings, SortOrders } from '@mediaseal-webui/types';
import { useTableScroller } from '../useTableScroller';

export const useAuditTableSort = (service: AuditService, fetchReports: () => void) => {
    const { scrollTableToTop } = useTableScroller();

    const createSortHandler = useCallback(
        (header: TableColumns) => async () => {
            const direction = service.auditParams.sortOrder === 'desc' ? 'asc' : 'desc';
            service.setParams({
                page: 0,
                sortBy: header.dataKey,
                sortOrder: direction,
                sort: `${header.dataKey},${direction}` as SortStrings
            });
            scrollTableToTop();
            fetchReports();
        },
        [service]
    );

    return {
        service,
        createSortHandler
    };
};
