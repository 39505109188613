//@ts-ignore
import { useDispatch, useSelector } from 'react-redux';
import { changeField, addField, removeField, clearForm, submitForm } from '@mediaseal-webui/actions';
import { InitialState, FormField, FormState } from '@mediaseal-webui/types/redux';
import { IUseForm, AddFieldArgs, Values, passing } from '.';

const useForm = (): IUseForm => {
    const dispatch = useDispatch();
    const { errors, fields } = useSelector((state: InitialState) => state.form) as FormState;

    const exists = (name: string): boolean => fields.some((field: FormField) => field.name === name);

    const checkFieldValid = (name: string): string => {
        const error = errors?.find((field) => field.name === name);
        return error ? error.message : '';
    };

    const getValues = (fields: FormField[]) =>
        fields.map((field: FormField) => ({
            name: field.name,
            value: field.value
        })) as Values[];

    const add = ({ name, validation = passing, required, value }: AddFieldArgs): void => {
        if (!exists(name)) {
            dispatch(addField({ name, required, validation, value }));
        }
    };

    const remove = (name: string): void => {
        if (exists(name)) {
            dispatch(removeField(name));
        }
    };
    const clear = () => dispatch(clearForm());
    const change =
        (name: string) =>
        (value: string): ((dispatch: any, getState: any) => void) =>
            dispatch(changeField({ name, value }));
    const submit = (action: any): ((dispatch: any, getState: any) => void) => dispatch(submitForm(action));

    const empty = (field: FormField) => field.required && !field.value;
    const invalid = errors?.length > 0 || (fields?.length > 0 && fields.some(empty));

    return {
        addField: add,
        removeField: remove,
        clear,
        change,
        errors,
        isFieldValid: checkFieldValid,
        invalid,
        values: getValues(fields),
        submit
    };
};

export default useForm;
