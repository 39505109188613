import React from 'react';
import PropTypes from 'prop-types';
import { Fab, useMediaQuery, CircularProgress } from '@material-ui/core';
import { Add as PlusIcon } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useMobile } from '@mediaseal-webui/hooks';
import clsx from 'clsx';
import useStyles from './SubmitButton.styles';
import { SubmitButtonProps } from '.';
import { InitialState } from '@mediaseal-webui/types/redux';

export const SubmitButton = ({ title = 'Create', disabled = true, Icon = PlusIcon, display = 'inline', alignItems, ...others }: SubmitButtonProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMobile();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <span style={{ display, alignItems: alignItems ?? undefined }}>
            <Fab
                data-testid='submit-button'
                variant={matches ? 'round' : 'extended'}
                aria-label='submit'
                disableRipple={true}
                disabled={disabled}
                classes={{
                    root: clsx(classes.fab, {
                        [classes.hideFab]: !mobile
                    })
                }}
                {...others}>
                <Icon className={classes.icon} />
                {!matches ? title : null}
            </Fab>
        </span>
    );
};
