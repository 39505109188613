import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    ToggleSlider,
    FilesHeaderItems,
    DecryptorUsersHeaderItems,
    DecryptorGroupsHeaderItems,
    AuthHeaderItems,
    TitleHeaderItems,
    SunsetSunriseHeaderItems,
    SecurePlayerHeaderItems,
    ReauthTimeHeaderItems,
    NotesHeaderItems,
    ContactInfoHeaderItems,
    EncGroupsHeaderItems,
    PermissionsHeaderItems,
    EncUsersHeaderItems,
    JobsHeaderItems,
    BatchedHeaderItems,
    LiveFolderHeaderItem,
    SelectableBtnStyles as useSelectableBtnStyles
} from '@mediaseal-webui/components';
import { useParams } from 'react-router';
import SunsetSunrise from '../../../jobs-components/edit-jobs-components/SunsetSunrise';
import { SecurePlayerIcon } from '../../../jobs-components/creation-view-components/AdvancedOptions/AdvancedOptions';
import { QRCode } from '../../../jobs-components/creation-view-components/AdvancedOptions/AdvancedOptions';
import {
    useReadOnlyCheck,
    useArchiveJob,
    useJobArchiveStatusChange,
    useAuth,
    useStatusChange,
    useProfileState,
    useMobile,
    useSideNav
} from '@mediaseal-webui/hooks';
import ContactInfoModal from '../../../jobs-components/edit-jobs-components/ContactInfoModal';
import { validateJob } from '@mediaseal-webui/utils';
import { fetchAllJobData, updateJob } from '../../../../actions/jobs';
import { HeaderItemsProps, ActiveSliderProps } from '..';
import { setEdit } from '@mediaseal-webui/actions';
import ReauthTime from '../../../jobs-components/creation-view-components/ReauthTime';
import { RiCloseLine } from 'react-icons/ri';
import { BiArchiveIn, BiArchiveOut } from 'react-icons/bi';
import { TiTick } from 'react-icons/ti';
import { MdClose as CloseIcon, MdMoreHoriz as MoreIcon } from 'react-icons/md';
import useStyles, { HeaderItemsContainer, HeaderItemsInner, OverflowItem } from './HeaderItem.styles';
import { PermissionSelectionModal } from '../../../groups-components/profile-view-components/Modals/PermissionSelectionModal';
import { setConfirmDialogue } from '@mediaseal-webui/actions';
import { useMediaQuery } from '@material-ui/core';
import { useAppSelector } from 'store';

export const SecurePlayer = () => {
    const classes = useSelectableBtnStyles({ width: 20 });
    return <SecurePlayerIcon className={`${classes.optionsIcon} ${classes.icon}`} />;
};

export const HeaderItems = ({ isFetching, data, actionButtonWidth = 0 }: HeaderItemsProps) => {
    const {
        files,
        encGroups,
        encUsers,
        decUsers,
        decGroups,
        title,
        authType,
        reauth,
        securePlayer,
        qrCode,
        jobActive,
        permissions,
        sunriseSunset,
        active,
        jobs,
        jobNotes,
        jobContactInfo,
        batched,
        stateKey,
        template
    } = data;

    const innerRef = useRef<HTMLDivElement | null>(null);

    const classes = useStyles();
    const { sideNavOpen } = useSideNav();
    const [open, setOpen] = useState<boolean>(false);
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const mobile = useMobile();
    const overflowClick = () => setOpen(!open);
    const onModalChange = (open: boolean) => {
        if (open) {
            setTooltipOpen(false);
        } else {
            setOpen(false);
        }
    };

    const isSmall = useMediaQuery('@media (max-width: 1305px)');

    useEffect(() => {
        setOpen(!isSmall);
    }, [isSmall]);

    useEffect(() => {
        if (mobile && open) {
            setTooltipOpen(true);
        }
    }, [mobile, open]);

    const itemProps = {
        isFetching,
        tooltipOpen: tooltipOpen && open,
        onModalChange,
        stateKey
    };

    const handleScroll = useCallback(() => {
        const event = new CustomEvent('headerItemMoved', {});
        dispatchEvent(event);
    }, []);

    const handleClickAway = useCallback(
        (event: MouseEvent) => {
            if (innerRef.current && event.target && open) {
                const node = event.target as Node;
                if (!innerRef.current.contains(node)) {
                    setTimeout(() => setOpen(false), 250);
                }
            }
        },
        [open, innerRef]
    );

    useEffect(() => {
        const ref = innerRef.current;
        if (ref) {
            ref.addEventListener('scroll', handleScroll);
            window.addEventListener('mousedown', handleClickAway);
        }
        return () => {
            if (ref) {
                ref.removeEventListener('scroll', handleScroll);
                window.removeEventListener('mousedown', handleClickAway);
            }
        };
    }, [innerRef, handleScroll, handleClickAway]);

    return (
        <>
            <HeaderItemsContainer open={open}>
                <HeaderItemsInner ref={innerRef} open={open} sideNavOpen={sideNavOpen} actionButtonWidth={actionButtonWidth}>
                    {files && <FilesHeaderItems {...files} {...itemProps} />}

                    {encUsers && <EncUsersHeaderItems {...encUsers} {...itemProps} />}

                    {decUsers && <DecryptorUsersHeaderItems {...decUsers} {...itemProps} />}

                    {encGroups && <EncGroupsHeaderItems {...encGroups} {...itemProps} />}

                    {decGroups && <DecryptorGroupsHeaderItems {...decGroups} {...itemProps} />}

                    {title && <TitleHeaderItems {...title} {...itemProps} />}

                    {jobs && <JobsHeaderItems {...jobs} {...itemProps} />}

                    {authType && <AuthHeaderItems {...authType} {...itemProps} />}

                    {sunriseSunset && <SunsetSunriseHeaderItems modal={SunsetSunrise} {...itemProps} />}

                    {securePlayer && <SecurePlayerHeaderItems icon={SecurePlayer} id='secure-playback' {...itemProps} />}

                    {qrCode && <SecurePlayerHeaderItems icon={QRCode} title='Watermarking Enabled' id='qr-header-item' {...itemProps} />}

                    {permissions && <PermissionsHeaderItems {...permissions} modal={PermissionSelectionModal} {...itemProps} />}

                    {jobContactInfo && <ContactInfoHeaderItems modal={ContactInfoModal} {...itemProps} />}
                    {jobNotes && <NotesHeaderItems modal={ContactInfoModal} {...itemProps} />}

                    {reauth && <ReauthTimeHeaderItems modal={ReauthTime} {...itemProps} />}

                    {batched && <BatchedHeaderItems {...itemProps} />}
                    {template && <LiveFolderHeaderItem {...itemProps} />}

                    {mobile && jobActive && (
                        <>
                            {jobActive && <JobActiveSliders open={open} />}
                            {active && <ActiveSliders {...active} open={open} />}
                        </>
                    )}
                </HeaderItemsInner>
                {
                    /** If there is some header item, render overflow */
                    Object.entries(data).some(([key, value]) => value && key !== 'stateKey') && (
                        <OverflowItem onClick={overflowClick} open={open}>
                            {!open ? <MoreIcon /> : <CloseIcon />}
                        </OverflowItem>
                    )
                }
            </HeaderItemsContainer>

            {!mobile && (
                <div className={classes.additionalItems}>
                    {jobActive && <JobActiveSliders />}
                    {active && <ActiveSliders {...active} />}
                </div>
            )}
        </>
    );
};

const ActiveSliders = ({ updateFn, entity, isReadOnly, open }: ActiveSliderProps) => {
    const dispatch = useDispatch();
    const auth = useAuth();
    const isEditAuth = auth.edit || auth.adminster;
    const mobile = useMobile();

    const { id } = useParams<{ id: string }>();

    const { active } = useProfileState();

    const changeStatus = useStatusChange(Number(id), updateFn);

    const handleStatusChange = (status: 'active' | 'inactive') => () => {
        const action = changeStatus(status);
        const message =
            status === 'active' ? `Please confirm that you want to reactivate this ${entity}?` : `Please confirm that you want to deactivate this ${entity}?`;
        const confirmButtonText = status === 'active' ? 'Activate' : 'Deactivate';
        const title = `${confirmButtonText} ${entity}`;
        const icon = status === 'active' ? 'reactivate' : 'deactivate';
        const loadingText = status === 'active' ? `Reactivating ${entity}` : `Deactivating ${entity}`;
        dispatch(setConfirmDialogue({ message, action, confirmButtonText, title, icon, loadingText }));
    };

    return (
        <>
            {isEditAuth && (
                <ToggleSlider
                    tooltipOpen={open || false}
                    data={[
                        {
                            text: active ? 'deactivate' : 'inactive',
                            value: 'inactive',
                            id: 'deactivate',
                            setterFn: handleStatusChange('inactive'),
                            icon: RiCloseLine
                        },
                        {
                            text: active ? 'active' : 'activate',
                            value: 'active',
                            id: 'activate',
                            setterFn: handleStatusChange('active'),
                            icon: TiTick
                        }
                    ]}
                    selectedValue={active ? 'active' : 'inactive'}
                    disabled={isReadOnly || !isEditAuth}
                    tooltipText={mobile ? (active ? 'active' : 'inactive') : undefined}
                />
            )}
        </>
    );
};

const JobActiveSliders = ({ open }: { open?: boolean }) => {
    const dispatch = useDispatch();
    const mobile = useMobile();

    const profile = useProfileState();
    const { archived, active, type } = profile;

    const { id } = useParams<{ id: string }>();

    const { isReadOnly } = useReadOnlyCheck(profile, validateJob);

    const [archiveJob] = useArchiveJob();

    const archiveStatusChange = useJobArchiveStatusChange();

    const handleStatusAction = (status: 'active' | 'inactive') => {
        dispatch(setEdit({ active: status === 'active' }));
        dispatch(updateJob(id, { active: status === 'active' }));
    };

    const handleStatusChange = (status: 'active' | 'inactive') => archiveStatusChange.handleStatusChange(status, handleStatusAction);

    const handleArchivalAction = async (flag: boolean) => {
        await archiveJob(profile, flag, type !== 'ONLINE');
        dispatch(fetchAllJobData(id));
    };

    const handleArchival = (flag: boolean) => archiveStatusChange.handleArchival(flag, handleArchivalAction);
    const { items: permissions } = useAppSelector((state) => state.permissions);
    const hasArchiveAuth = permissions.some((permission) => permission.permissionName === 'canArchiveJobs');

    return (
        <>
            <ToggleSlider
                data={[
                    {
                        text: active ? 'deactivate' : 'inactive',
                        setterFn: handleStatusChange('inactive'),
                        value: 'inactive',
                        id: 'deactivate',
                        icon: RiCloseLine
                    },
                    {
                        text: active ? 'active' : 'activate',
                        setterFn: handleStatusChange('active'),
                        value: 'active',
                        id: 'activate',
                        icon: TiTick
                    }
                ]}
                selectedValue={active ? 'active' : 'inactive'}
                disabled={archived || isReadOnly || type !== 'ONLINE'}
                tooltipText={mobile ? (active ? 'active' : 'inactive') : undefined}
                tooltipOpen={open || false}
            />
            <ToggleSlider
                data={[
                    {
                        text: archived ? 'archived' : 'archive',
                        value: 'archived',
                        id: 'archive',
                        setterFn: handleArchival(true),
                        icon: BiArchiveIn
                    },
                    {
                        text: archived ? 'restore' : 'live',
                        value: 'live',
                        id: 'restore',
                        setterFn: handleArchival(false),
                        icon: BiArchiveOut
                    }
                ]}
                selectedValue={archived ? 'archived' : 'live'}
                disabled={!hasArchiveAuth || isReadOnly}
                tooltipText={mobile ? (archived ? 'archived' : 'live') : undefined}
                tooltipOpen={open || false}
            />
        </>
    );
};
