import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        transition: theme.transitions.create(['border-color']),
        backgroundColor: theme.palette.common.white,
        borderRadius: 10,
        border: `1px solid #eee`,

        outline: 'none',
        width: '100%',
        '&:hover': {
            backgroundColor: theme.palette.background.paper,
            borderColor: '#ccc'
        }
    },
    focus: {
        backgroundColor: theme.palette.common.white,
        border: `2px solid ${theme.palette.primary.main} !important`
    },
    textArea: {
        display: 'block',
        width: '100%',
        boxSizing: 'border-box',
        resize: 'none',
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        borderRadius: 10,
        padding: theme.spacing(2),
        fontSize: '1rem',
        backgroundColor: theme.palette.background.paper,
        outline: 'unset'
    }
}));

export default useStyles;
