import { makeStyles } from '@material-ui/core';
const WIDTH = 72;
const HEIGHT = 32;
const useStyles = makeStyles((theme) => ({
    tableCell: {
        borderBottom: 'unset',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: 5,
            justifyContent: 'flex-end',
            display: 'flex',
            width: '100%'
        }
    },
    active: {
        width: WIDTH,
        height: HEIGHT,
        background: theme.palette.success.main,
        color: theme.palette.common.white,
        [theme.breakpoints.down('xs')]: {
            width: 10,
            height: 10
        }
    },
    inactive: {
        width: WIDTH,
        height: HEIGHT,
        background: theme.palette.error.main,
        color: theme.palette.common.white,
        [theme.breakpoints.down('xs')]: {
            width: 10,
            height: 10
        }
    },
    implicitInactive: {
        width: WIDTH,
        height: HEIGHT,
        background: theme.palette.warning.main,
        color: theme.palette.common.white,
        [theme.breakpoints.down('xs')]: {
            width: 10,
            height: 10
        }
    },
    archived: {
        width: WIDTH,
        height: HEIGHT,
        background: theme.palette.background.default,
        [theme.breakpoints.down('xs')]: {
            width: 10,
            height: 10
        }
    },
    icon: {
        border: '1px solid #eee',
        padding: theme.spacing(1),
        transition: '1.5s',
        alignItems: 'center',
        borderRadius: '40px',
        justifyContent: 'space-between',
        width: 32,
        height: 32,
        marginRight: 5,
        display: 'flex',
        fontSize: '2.5rem',
        flexShrink: 0,
        '& > svg': {
            color: theme.palette.grey[600],
            fontSize: '2rem'
        },
        [theme.breakpoints.down('sm')]: {}
    },

    dialogRoot: {
        maxWidth: 'unset'
    },
    dialogTitle: {
        '&> *': {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.warning.main
        },
        '& svg': {
            marginRight: theme.spacing(1)
        }
    },
    listWrapper: {
        padding: theme.spacing(3),
        paddingTop: 0
    },
    list: {
        border: `1px solid ${theme.palette.grey[400]}`,
        marginTop: theme.spacing(2)
    },

    listItem: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1)
    },
    firstRow: {
        justifyContent: 'flex-end'
    },
    listItemText: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        '& > svg': {
            marginRight: theme.spacing(1)
        }
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
        width: 125
    },
    buttonsWithOverwrite: {
        justifyContent: 'space-around',
        width: 250
    },
    itemCancelButton: {
        marginLeft: theme.spacing(2),
        color: theme.palette.error.main
    },
    warning: {
        color: theme.palette.primary.main
    }
}));

export const useTooltipStyles = makeStyles((theme) => ({
    tooltip: {
        width: 100,
        background: theme.palette.background.default,

        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11
    },
    arrow: {
        color: theme.palette.background.default
    },
    tooltipHover: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    tooltipActive: {
        color: theme.palette.success.main,
        opacity: 1
    },
    tooltipInactive: {
        color: theme.palette.error.main,
        opacity: 1
    },
    icon: {
        fontSize: theme.typography.pxToRem(15),
        marginLeft: theme.spacing(0.25),
        color: theme.palette.info.light,
        alignSelf: 'baseline',
        display: 'inline-flex'
    }
}));

export default useStyles;
