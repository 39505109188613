import React from 'react';
import { useIdentityProvider } from 'hooks/useIdentityProviders';
import { useCallback } from 'react';
import { providers } from '../';
import { StyledProvider, StyledCheckMark, StyledProviderContainer } from './index.style';
import { DeleteBtn } from './DeleteBtn';
import { DefaultGroup } from '../DefaultGroup';
interface Props {
    provider: typeof providers[0] | typeof providers[1];
    configured?: boolean;
}
export const IdentityProvider: React.FC<Props> = ({ provider, configured }) => {
    const { setData } = useIdentityProvider();
    const handleClick = useCallback(() => {
        if (!configured) {
            setData((ps) => ({
                ...ps,
                configureProvider: { ...ps.configureProvider, showModal: true, type: provider.type }
            }));
        }
    }, [setData, provider.type, configured]);
    return (
        <>
            <StyledProviderContainer>
                <StyledProvider configured={configured ?? false} className={provider.type} onClick={handleClick} data-testid={provider.type}>
                    <provider.Icon />
                    {provider.type}
                    {configured && <StyledCheckMark data-testid='check' />}
                </StyledProvider>
                {configured && <DeleteBtn />}
            </StyledProviderContainer>
            {configured && <DefaultGroup />}
        </>
    );
};
