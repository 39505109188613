import { format } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Typography, DialogContent, Tooltip, Accordion, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinkIcon from '@material-ui/icons/Link';

import mainSummaryStyle from './MainSummaryInfo.styles';

interface MainSummaryInfoProps {
    readonly fileId: string;
    readonly fileName: string;
    readonly jobId: number;
    readonly jobName: string;
    readonly userId: number;
    readonly userName: string;
}

const MainSummaryInfo = ({ item, style, ...others }: MainSummaryInfoProps) => {
    const classes = mainSummaryStyle();
    const removeFilePath = (path) => path.substring(path.lastIndexOf('/') + 1);
    const { isDecryptorTab } = useSelector((state) => state.reporting);
    const { profile } = useSelector((state) => state.entity);
    const isSaveOut = !!profile.openAssets && isDecryptorTab;

    return (
        <span style={style} className={classes.section}>
            <Accordion {...others} classes={{ root: classes.expansionPanel }}>
                {isDecryptorTab && (
                    <>
                        {' '}
                        <Typography color='textPrimary' variant='subtitle2'>
                            Attempted Access:
                        </Typography>
                        <DialogContent aria-controls='file-info'>
                            <Typography color='textPrimary'>File: {removeFilePath(item.userFilePath || '')}</Typography>
                            <Typography color='textPrimary' component='p' variant='body1'>
                                Access: <span className={classes.capitalize}>{item.accessType}</span>
                            </Typography>
                            <Typography color='textPrimary' component='p' variant='body1'>
                                Process: {item.processName ? item.processName : 'Unavailable'}
                            </Typography>
                            {isSaveOut ? (
                                <Typography color='textPrimary' component='p' variant='body1'>{`${profile.openAssets.length} Users`}</Typography>
                            ) : (
                                <Typography color='textPrimary' component='p' variant='body1'>
                                    User: {profile.user?.fullName || profile.actionBy?.name}
                                </Typography>
                            )}
                            <Typography color='textPrimary' component='p' variant='body1'>
                                Date: {format(new Date(profile?.date || profile?.actionDate || new Date()), 'dd-MM-yyyy pp')}
                            </Typography>
                        </DialogContent>
                        <Typography color='textPrimary' variant='subtitle2'>
                            Associated File
                        </Typography>
                        <DialogContent>
                            <Typography color='textPrimary' component='p' variant='body1'>
                                ID: {item.file?.id || item.fileId}
                            </Typography>
                            <Typography color='textPrimary' component='p' variant='body1'>
                                Name: {item.file?.path || item.userFilePath}
                            </Typography>
                        </DialogContent>
                    </>
                )}

                {isDecryptorTab && (
                    <Tooltip title='Go to Job' placement='right'>
                        <Link className={classes.link} style={{ textDecoration: 'none' }} data-testid='job-link' to={`/jobs/${item.job?.id}/profile`}>
                            <Typography color='textPrimary' variant='subtitle2'>
                                Associated Job
                            </Typography>
                            <LinkIcon className={classes.linkIcon} />
                        </Link>
                    </Tooltip>
                )}

                {isDecryptorTab && (
                    <DialogContent>
                        <Typography color='textPrimary' component='p' variant='body1'>
                            ID: {item.job?.id || 'Unavailable'}
                        </Typography>
                        <Typography color='textPrimary' component='p' variant='body1'>
                            Name: {item.job?.name || 'Unavailable'}
                        </Typography>
                    </DialogContent>
                )}

                {item.user ? (
                    <Tooltip title='Go to User' placement='right'>
                        <Link
                            className={classes.link}
                            style={{ textDecoration: 'none' }}
                            data-testid='user-link'
                            to={`/users/decryptor/${item.user?.id}/profile`}>
                            <Typography color='textPrimary' variant='subtitle2'>
                                Associated User
                            </Typography>
                            <LinkIcon className={classes.linkIcon} />
                        </Link>
                    </Tooltip>
                ) : (
                    <Typography color='textPrimary' variant='subtitle2'>
                        Associated User
                    </Typography>
                )}
                <DialogContent>
                    <Typography color='textPrimary' component='p' variant='body1'>
                        ID: {item.user?.id || item.actionBy?.userId || 'Unavailable'}
                    </Typography>
                    <Typography color='textPrimary' component='p' variant='body1'>
                        Name: {item.user?.fullName || item.actionBy?.name || 'Unavailable'}
                    </Typography>
                </DialogContent>

                {!isDecryptorTab && (
                    <>
                        <Typography color='textPrimary' variant='subtitle2'>
                            Activity Details
                        </Typography>
                        <DialogContent>
                            <Typography color='textPrimary' component='p' variant='body1'>
                                Action: {item.action.toUpperCase() || 'Unavailable'}
                            </Typography>
                            <Typography color='textPrimary' component='p' variant='body1'>
                                Date: {format(new Date(item.actionDate), 'dd-MM-yyyy pp') || 'Unavailable'}
                            </Typography>
                            <Typography color='textPrimary' component='p' variant='body1'>
                                Type: {item.entityType || 'Unavailable'}
                            </Typography>
                            <Typography color='textPrimary' component='p' variant='body1'>
                                Type ID: {item.entityId || 'Unavailable'}
                            </Typography>
                            <Typography color='textPrimary' component='p' variant='body1'>
                                Audit ID: {item.auditId || 'Unavailable'}
                            </Typography>
                        </DialogContent>
                    </>
                )}
            </Accordion>
        </span>
    );
};

export default MainSummaryInfo;
