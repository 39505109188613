import React from 'react';
import useStyles from './ErrorModal.styles';
import ErrorIcon from '@material-ui/icons/Error';
import { Dialog, DialogContent, DialogActions, Button, DialogContentText, DialogTitle } from '@material-ui/core';
import { IOpenProps } from '@mediaseal-webui/types/generic';
import { useDispatch } from 'react-redux';
import { resetErrors } from '@mediaseal-webui/actions';

interface ErrorModalProps extends IOpenProps {
    message?: string;
}

export const ErrorModal = (props: ErrorModalProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        openProps: [open, setOpen],
        message
    } = props;

    const handleClose = () => {
        setOpen(false);
        dispatch(resetErrors());
    };

    return (
        <Dialog open={open} maxWidth='xs' id='error-dialogue'>
            <DialogContent className={classes.dialogContainer}>
                <ErrorIcon className={classes.icon} />
                <div className={classes.titleContainer}>
                    <DialogTitle className={classes.dialogTitle}>Oops, something went wrong</DialogTitle>
                </div>
                <DialogContentText id='error-dialogue-text'>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button id='close-button' data-testid='close' aria-label='Close' onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
