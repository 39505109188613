import React, { useState, useEffect } from 'react';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import { EditableHeaderChildProps, EditableHeaderItem } from '../../EditableHeaderItem';
import { HeaderItem, GenericHeaderItemProps } from '..';
import { useSelector } from 'react-redux';
import { InitialState } from '@mediaseal-webui/types/redux';
import { useAuth } from '@mediaseal-webui/hooks';

export const ContactInfoHeaderItems = ({ isFetching, modal: Modal, tooltipOpen, onModalChange, stateKey = 'profile' }: GenericHeaderItemProps) => {
    const [showModal, setShowModal] = useState(false);
    const profile = useSelector(({ entity }: InitialState) => entity[stateKey]);
    const { contactName, contactInfo } = profile;
    const archived = profile.archived ?? false;

    const auth = useAuth();
    const isEditAuth = auth.edit || auth.administer;

    const onClick = () => setShowModal(true);

    useEffect(() => {
        onModalChange(showModal);
    }, [showModal]);

    return (
        <>
            <EditableHeaderItem title={contactName ? `${contactName}, ${contactInfo}` : 'Contact Info'} onViewClick={onClick} open={tooltipOpen}>
                {({ hovering }: EditableHeaderChildProps) => (
                    <HeaderItem hovering={hovering} Icon={ContactPhoneIcon} isFetching={isFetching} id='contact-info' />
                )}
            </EditableHeaderItem>
            {Modal && stateKey !== 'create' && <Modal disabled={archived || !isEditAuth} openProps={[showModal, setShowModal]} />}
        </>
    );
};
