import React from 'react';
import { CircularProgress, TextField } from '@material-ui/core';
import { FaUserAlt } from 'react-icons/fa';

export const RenderInput =
    ({ submitting, label }: { submitting: boolean; label: string }) =>
    (params) =>
        (
            <>
                <TextField
                    {...params}
                    variant='outlined'
                    fullWidth
                    id='receipient-selection'
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: <FaUserAlt />,
                        endAdornment: (
                            <>
                                {submitting && <CircularProgress color='inherit' size={20} />} {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                />
            </>
        );
