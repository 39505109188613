import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GenericHeaderItemProps, HeaderItem } from '..';
import { EditableHeaderItem, EditableHeaderChildProps } from '../..';
import { BiTime } from 'react-icons/bi';
import { InitialState } from '@mediaseal-webui/types/redux';
import { setEditValues } from '@mediaseal-webui/actions';
import { useJobFeatures, useAuth } from '@mediaseal-webui/hooks';

export const ReauthTimeHeaderItems = ({ isFetching, modal: ReauthTime, tooltipOpen, onModalChange, stateKey = 'profile' }: GenericHeaderItemProps) => {
    const [advancedOptionsOpen, setAdvancedOptionsOpen] = useState(false);

    const profile = useSelector(({ entity }: InitialState) => entity[stateKey]);
    const { type, features, archived } = profile;

    const isBackground = (features ?? []).every((option) => option.data.rollover);

    const { isReauthEnabled, isRolloverEnabled, reauthTime } = useJobFeatures(features);
    const time = Math.floor(reauthTime() / 3600);

    const auth = useAuth();
    const isEditAuth = auth.edit || auth.administer;

    const onClick = () => setAdvancedOptionsOpen(true);

    const TimeIcon = () => <BiTime size={24} />;

    useEffect(() => {
        onModalChange(advancedOptionsOpen);
    }, [advancedOptionsOpen]);

    return (
        <>
            <EditableHeaderItem
                title={isBackground ? `Reauthenticate in the background every ${time} hours` : `Reauthenticate every ${time} hours`}
                onViewClick={onClick}
                open={tooltipOpen}>
                {({ hovering }: EditableHeaderChildProps) => <HeaderItem hovering={hovering} Icon={TimeIcon} isFetching={isFetching} id='reauth-item' />}
            </EditableHeaderItem>
            {advancedOptionsOpen && stateKey !== 'create' && (
                //@ts-ignore
                <ReauthTime
                    features={features}
                    openProps={[advancedOptionsOpen, setAdvancedOptionsOpen]}
                    rollOver={isRolloverEnabled()}
                    disabled={archived || !isEditAuth || isReauthEnabled()}
                    isOffline={type === 'OFFLINE'}
                    setterFn={setEditValues}
                    active={isReauthEnabled()}
                />
            )}
        </>
    );
};
