import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { Typography, FormLabel, Card, FormGroup } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Header } from '../../../../components/layout-components/';
import { fetchGroupsForEncUser, fetchEncryptorUser, updateUser, setConfirmDialogue } from '@mediaseal-webui/actions';
import { profileGenericHeader, profileGenericHeaderWithoutActions } from '../../../../data/profile-table-headers';
import { removeEncryptorGroupFromSummary, removeEntityAndUpdate } from '../../../../actions/entity';
import { nameLength, useAuth, useProfileCheck, emailCheck } from '@mediaseal-webui/hooks';
import ActionContainer from '../../../../components/table-components/ActionContainer/ActionContainer';
import useStyles from './Profile.styles';
import { EditableEntityTitle, Content, MainTable, AddItemButton, WarningAlert } from '@mediaseal-webui/components';
import { GroupSelection } from '../../../../components/utility-components/Modals';
import { CLEAR_ENTITY, hideRemoveGroupConfirmation } from '@mediaseal-webui/constants';
import { AddGroup } from '@mediaseal-webui/components/src/icons';
import { useAppSelector } from 'store';

const GroupsModal = ({ openProps }) => <GroupSelection encryptor openProps={openProps} title='Add encryptor groups' updateFn={updateUser} />;

const Profile = () => {
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();

    const auth = useAuth();
    const isEditAuth = auth.edit || auth.administer;
    const isCreateAuth = auth.create || auth.administer;

    const { userPageCount, userPageSearchValue } = useSelector(({ users }) => users);
    const loggedInUser = useAppSelector((state) => state.user);
    const { isOwnProfile } = useProfileCheck(loggedInUser?.apiUser);
    const { loginName, firstName, lastName, email, encryptorGroups, createDate, external } = useSelector(({ entity: { profile } }) => profile);

    const fullName = (firstName || lastName) && `${firstName} ${lastName}`;
    const fetchFn = () => fetchGroupsForEncUser(id);
    const isReadOnly = external;

    const { items: permissions } = useAppSelector((state) => state.permissions);
    const hasEditEncryptorPermission = permissions.some((permission) => permission.permissionName === 'canEditEncryptorUsers');

    const removeHandler = (group) => () =>
        dispatch(
            setConfirmDialogue({
                icon: 'warning',
                title: 'Remove Group',
                confirmButtonText: 'Remove Group',
                message: 'Are you sure you want to remove this group?',
                action: removeEntityAndUpdate({
                    entity: group,
                    id,
                    removeFn: removeEncryptorGroupFromSummary,
                    updateFn: updateUser
                }),
                memorable: hideRemoveGroupConfirmation
            })
        );

    const handleRemove = useCallback(removeHandler, [dispatch, id]);

    useEffect(() => {
        dispatch(fetchEncryptorUser(id));
        return () => {
            dispatch({ type: CLEAR_ENTITY });
        };
    }, [dispatch, id]);

    const handleRowClick = ({ id }) => history.push(`/groups/encryptor/${id}/profile`);

    const headerItems = {
        authType: {
            title: 'Change Password',
            updateFn: () => updateUser(id, isOwnProfile ? 'put' : 'patch'),
            disabled: !isOwnProfile ? false : !isEditAuth || !hasEditEncryptorPermission || isReadOnly,
            subtitle: "Edit the user's password",
            confirmSubtitle: "Confirm the user's new password",
            hidden: isReadOnly || !hasEditEncryptorPermission
        },
        encGroups: {
            onClick: () => null
        },
        active: {
            updateFn: () => updateUser(id),
            entity: 'user',
            isReadOnly: isReadOnly || !hasEditEncryptorPermission || isOwnProfile
        }
    };

    return (
        <div className={classes.viewRoot}>
            <WarningAlert
                show={isReadOnly ?? false}
                message='Current user is an external user from an identity provider. This users contact and personal information can not be edited.'
            />
            <Header
                name={fullName}
                skeleton
                profile
                date={createDate ? new Date(createDate) : undefined}
                breadcrumbProps={{
                    links: [{ id: 0, name: 'Encryptor Users', to: '/users/encryptor' }],
                    text: [{ id: 0, name: fullName }]
                }}
                headerItems={headerItems}
            />

            <Content>
                {(cl) => (
                    <div className={`${cl.stack} ${cl.noWrap} ${cl.cardWrapper}`}>
                        <div className={cl.flex}>
                            <Card elevation={0} className={`${cl.smallProfileCard} ${cl.leftCard}`}>
                                {isEditAuth && !isReadOnly && hasEditEncryptorPermission ? (
                                    <>
                                        <EditableEntityTitle
                                            maxWidth='250px'
                                            value={loginName}
                                            setterFn={updateUser}
                                            label='Login Name'
                                            field='loginName'
                                            isReadOnly
                                        />
                                        <EditableEntityTitle
                                            maxWidth='250px'
                                            showInputBackground
                                            fullWidth
                                            value={email}
                                            setterFn={updateUser}
                                            id='emailAddress'
                                            data-testid='emailAddress'
                                            label='Email'
                                            field='email'
                                            validation={emailCheck}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <FormGroup style={{ padding: 16 }}>
                                            <FormLabel className={classes.label}>Login Name</FormLabel>
                                            <Typography color='textPrimary' variant='body1'>
                                                {loginName}
                                            </Typography>
                                        </FormGroup>
                                        <FormGroup style={{ padding: 16 }}>
                                            <FormLabel className={`${classes.label} readonly-email`}>Email</FormLabel>
                                            <Typography color='textPrimary' variant='body1'>
                                                {email}
                                            </Typography>
                                        </FormGroup>
                                    </>
                                )}
                            </Card>
                            <Card elevation={0} className={`${cl.smallProfileCard} ${cl.rightCard}`}>
                                {isEditAuth && !isReadOnly && hasEditEncryptorPermission ? (
                                    <>
                                        <EditableEntityTitle
                                            maxWidth='250px'
                                            showInputBackground
                                            value={firstName}
                                            setterFn={updateUser}
                                            label='First Name'
                                            id='firstName'
                                            data-testid='firstName'
                                            field='firstName'
                                            validation={nameLength}
                                        />

                                        <EditableEntityTitle
                                            maxWidth='250px'
                                            showInputBackground
                                            value={lastName}
                                            setterFn={updateUser}
                                            label='Last Name'
                                            id='lastName'
                                            data-testid='lastName'
                                            field='lastName'
                                            validation={nameLength}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <FormGroup style={{ padding: 16 }}>
                                            <FormLabel className={`${classes.label} readonly-name`}>Name</FormLabel>
                                            <Typography color='textPrimary' variant='body1'>
                                                {fullName}
                                            </Typography>
                                        </FormGroup>
                                    </>
                                )}
                            </Card>
                        </div>
                        <Card elevation={0} className={cl.profileCard}>
                            <div className={cl.titleBlock}>
                                <Typography color='textPrimary' className={cl.cardTitle} variant='h5' id='card-text'>
                                    Groups
                                </Typography>
                                {isEditAuth && <AddItemButton id='addGroupBtn' Modal={GroupsModal} Icon={AddGroup} />}
                            </div>
                            <MainTable
                                id='encGroupsTable'
                                entities={encryptorGroups ?? []}
                                searchValue={userPageSearchValue}
                                totalPages={userPageCount}
                                fetchFn={fetchFn}
                                entityLink='/groups/encryptor'
                                columns={hasEditEncryptorPermission ? profileGenericHeader : profileGenericHeaderWithoutActions}
                                onRowClick={handleRowClick}
                                useContainer
                                tableActionsRenderer={(entity) => (
                                    <ActionContainer
                                        edit={false}
                                        create={isCreateAuth && hasEditEncryptorPermission}
                                        RemoveIcon={Close}
                                        editLink={`/groups/encryptor/${entity.id}/edit`}
                                        removeAction={handleRemove(entity)}
                                    />
                                )}
                                height={encryptorGroups ? 450 : 200}
                                noResultsMessage='No groups are associated with this user'
                            />
                        </Card>
                    </div>
                )}
            </Content>
        </div>
    );
};

export default React.memo(Profile);
