export * from './dialogues';
export * from './entity';
export * from './http';
export * from './invitations';
export * from './misc';
export * from './preferences';
export * from './reporting';
export * from './users';
export * from './jobs';
export * from './versioning';
export * from './filters';
export * from './encryption';
export * from './settings';
export * from './network';
/**
 * Form
 */
export const CHANGE_FIELD = 'CHANGE_FIELD';
export const ADD_FIELD = 'ADD_FIELD';
export const REMOVE_FIELD = 'REMOVE_FIELD';
export const ADD_FORM_ERROR = 'ADD_FORM_ERROR';
export const REMOVE_FORM_ERROR = 'REMOVE_FORM_ERROR';
export const CLEAR_FORM = 'CLEAR_FORM';
export const SUBMIT_FORM = 'SUBMIT_FORM';
export const CLEAR_EDIT = 'CLEAR_EDIT';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
// export const PATCH_PROFILE = 'PATCH_PROFILE'
/**
 * Errors
 */
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

/**
 * Entity
 */
export const SET_PROFILE = 'SET_PROFILE';
export const SET_EDIT = 'SET_EDIT';
export const SET_CREATE = 'SET_CREATE';
export const CLEAR_ENTITY = 'CLEAR_ENTITY';
export const SET_FILTERED_PROFILE_DECRYPTOR_USERS = 'SET_FILTERED_PROFILE_DECRYPTOR_USERS';
export const SET_FILTERED_PROFILE_DECRYPTOR_GROUPS = 'SET_FILTERED_PROFILE_DECRYPTOR_GROUPS';

/**
 * Summary
 */
export const SET_SUMMARY = 'SET_SUMMARY';
export const CLEAR_SUMMARY = 'CLEAR_SUMMARY';

export const ADD_DEC_GROUP_TO_SUMMARY = 'ADD_DEC_GROUP_TO_SUMMARY';
export const ADD_DEC_USER_TO_SUMMARY = 'ADD_DEC_USER_TO_SUMMARY';
export const ADD_ENC_USER_TO_SUMMARY = 'ADD_ENC_USER_TO_SUMMARY';
export const ADD_ENC_GROUP_TO_SUMMARY = 'ADD_ENC_GROUP_TO_SUMMARY';
export const ADD_TOKEN_TO_SUMMARY = 'ADD_TOKEN_TO_SUMMARY';

export const REMOVE_DEC_USER_FROM_SUMMARY = 'REMOVE_DEC_USER_FROM_SUMMARY';
export const REMOVE_ENC_USER_FROM_SUMMARY = 'REMOVE_ENC_USER_FROM_SUMMARY';
export const REMOVE_DEC_GROUP_FROM_SUMMARY = 'REMOVE_DEC_GROUP_FROM_SUMMARY';
export const REMOVE_ENC_GROUP_FROM_SUMMARY = 'REMOVE_ENC_GROUP_FROM_SUMMARY';
export const REMOVE_TOKEN_FROM_SUMMARY = 'REMOVE_TOKEN_FROM_SUMMARY';

export const FILTER_USER_ASSIGNED_TO_ENTITY = 'FILTER_USER_ASSIGNED_TO_ENTITY';
export const FILTER_DECRYPTOR_USER_ASSIGNED_TO_ENTITY = 'FILTER_DECRYPTOR_USER_ASSIGNED_TO_ENTITY';
export const FILTER_DECRYPTOR_GROUP_ASSIGNED_TO_ENTITY = 'FILTER_DECRYPTOR_GROUP_ASSIGNED_TO_ENTITY';
export const FILTER_ENCRYPTOR_GROUP_ASSIGNED_TO_ENTITY = 'FILTER_ENCRYPTOR_GROUP_ASSIGNED_TO_ENTITY';
/**
 * Titles
 */
export const FETCH_TITLES = 'FETCH_TITLES';
export const FETCH_TITLES_FILTERED = 'FETCH_TITLES_FILTERED';
export const SET_TITLES_PAGES = 'SET_TITLES_PAGES';
export const SET_TOTAL_TITLE_ELEMENTS = 'SET_TOTAL_TITLE_ELEMENTS';

export const REMOVE_SELECTED_USER = 'REMOVE_SELECTED_USER';

export const SET_TITLE_PAGE_SEARCH_VALUE = 'SET_TITLE_PAGE_SEARCH_VALUE';
export const CLEAR_TITLES_PAGE_SEARCH_VALUE = 'CLEAR_TITLES_PAGE_SEARCH_VALUE';

export const CLEAR_TITLES = 'CLEAR_TITLES';
export const CLEAR_TITLE_DATA = 'CLEAR_TITLE_DATA';

export const REMOVE_USER_FROM_CREATE_TITLE_TABLE = 'REMOVE_USER_FROM_CREATE_TITLE_TABLE';
export const REMOVE_ENC_USER_FROM_EDIT_TITLE_TABLE = 'REMOVE_ENC_USER_FROM_EDIT_TITLE_TABLE';
export const SET_TOTAL_TITLE_JOB_PAGES = 'SET_TOTAL_TITLE_JOB_PAGES';
/**
 * Groups
 */
export const FETCH_GROUPS = 'FETCH_GROUPS';
export const FETCH_ENC_GROUPS = 'FETCH_ENC_GROUPS';

export const SET_FILTERED_GROUPS = 'SET_FILTERED_GROUPS';
export const SET_FILTERED_ENC_GROUPS = 'SET_FILTERED_ENC_GROUPS';

export const REMOVE_RECIPIENT_GROUP = 'REMOVE_RECIPIENT_GROUP';
export const REMOVE_SELECTED_GROUPS = 'REMOVE_SELECTED_GROUPS';

export const SET_GROUP_PAGE_COUNT = 'SET_GROUP_PAGE_COUNT';
export const SET_ENCRYPTOR_GROUP_PAGE_COUNT = 'SET_ENCRYPTOR_GROUP_PAGE_COUNT';

export const SET_GROUP_PAGE_SEARCH_VALUE = 'SET_GROUP_PAGE_SEARCH_VALUE';
export const CLEAR_GROUP_PAGE_SEARCH_VALUE = 'CLEAR_GROUP_PAGE_SEARCH_VALUE';

export const SET_TOKEN_PAGE_SEARCH_VALUE = 'SET_TOKEN_PAGE_SEARCH_VALUE';
export const CLEAR_TOKEN_PAGE_SEARCH_VALUE = 'CLEAR_TOKEN_PAGE_SEARCH_VALUE';

export const REMOVE_ENC_GROUP = 'REMOVE_ENC_GROUP';
export const REMOVE_GROUP_EDIT_TITLE_TABLE = 'REMOVE_GROUP_EDIT_TITLE_TABLE';
export const REMOVE_USER_GROUP = 'REMOVE_USER_GROUP';

export const FILTER_USERS_AND_GROUPS = 'FILTER_USERS_AND_GROUPS';

export const SET_TOTAL_DEC_GROUPS = 'SET_TOTAL_DEC_GROUPS';
export const SET_TOTAL_ENC_GROUPS = 'SET_TOTAL_ENC_GROUPS';

export const CLEAR_GROUPS = 'CLEAR_GROUPS';

/**
 * Permissions
 */
export const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS';
export const CLEAR_PERMISSION_DATA = 'REMOVE_ENC_USER_FROM_EDIT_TITLE_TABLE';
export const SET_UNSERIALIZED_PERMISSIONS = 'SET_UNSERIALIZED_PERMISSIONS';
export const SET_PERMISSION_STATUS = 'SET_PERMISSION_STATUS';
export const REMOVE_PERMISSION = 'REMOVE_PERMISSION';
export const SET_PERMISSION = 'SET_PERMISSION';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';

/**
 *  Jobs
 */
export const FETCH_JOB_FILES = 'FETCH_JOB_FILES';
export const FETCH_JOB_FILES_SORTED = 'FETCH_JOB_FILES_SORTED';
export const SET_TOTAL_FILES = 'SET_TOTAL_FILES';
export const OPEN_SECURITY_METHOD_MODAL = 'OPEN_SECURITY_METHOD_MODAL';
export const SET_SECURITY_OPTIONS = 'SET_SECURITY_OPTIONS';
export const RESET_SECURITY_OPTIONS = 'RESET_SECURITY_OPTIONS';

/**
 * FETCHING
 *
 */

export const INIT_FETCH = 'INIT_FETCH';
export const STOP_FETCHING = 'STOP_FETCHING';

/**
 * ENCRYPT
 */

export const ALLOW_ENCRYPTION = 'ALLOW_ENCRYPTION';
