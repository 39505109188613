import React, { useEffect, useMemo, useCallback, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { Header } from '../../components/layout-components';
import { MainTable } from '@mediaseal-webui/components';
import { clearTitles } from '../../actions/titles';
import { genericNoActions } from '../../data';
import { useTableSortV2, useAuth } from '@mediaseal-webui/hooks';
import { Content, SubmitButton } from '@mediaseal-webui/components';
import ActionContainer from '../../components/table-components/ActionContainer/ActionContainer';
import useStyles from './Titles.styles';
import { useTableScroller } from '@mediaseal-webui/hooks';
import { useAppDispatch, useAppSelector } from 'store';
import { fetchTitles, searchTitles, titlesSlice } from 'reducers/titles';
import { TitleService } from '@mediaseal-webui/services';
const Titles = memo(() => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const auth = useAuth();
    const isCreateAuth = auth.create;
    const { setSearchValue, clearSearchValue } = titlesSlice.actions;
    useTableScroller();
    const { searchValue, content: titles, pending, page } = useAppSelector((state) => state.titles);
    const service = useMemo(() => new TitleService(), []);
    const fetchFn = useCallback(() => dispatch(searchTitles({ keyword: service.baseParams.keyword ?? '', service })), [service, dispatch]);
    const { headCells, sortFn, order, orderBy } = useTableSortV2<TitleService>({
        service,
        fetchFn,
        cells: genericNoActions
    });
    useEffect(() => {
        window.qt && window.GeneralWebInterface.allowFileDrop(false, { dropBehaviour: 'off' });
        dispatch(clearTitles());
        // dispatch(clearTitlesPageSearchValue());
    }, [dispatch]);

    const onRowClick = ({ id }) => history.push(`titles/${id}/profile`); // returns the rowData obj from Table

    const handleCreateClick = () => history.push('/titles/create');
    const CreateButton = () => <SubmitButton title='Create Title' id='create-title-btn' disabled={false} onClick={handleCreateClick} />;

    const fetchNextPage = useCallback(async () => {
        if (service.baseParams.page <= (page?.totalPages ?? 0) && titles.length >= 100) {
            service.setParams({
                page: service.baseParams.page + 1
            });
            await dispatch(fetchTitles(service));
        }
    }, [titles.length, dispatch, page?.totalPages, service]);
    const initFetch = useCallback(async () => {
        await dispatch(searchTitles({ service, keyword: '' }));
    }, [dispatch, service]);

    useEffect(() => {
        initFetch();
    }, [initFetch]);
    return (
        <div className={classes.viewRoot}>
            <Header
                list
                name='Titles'
                ActionButton={isCreateAuth && CreateButton}
                displayingText={`Displaying ${titles.length} of ${page?.totalElements} titles`}
                breadcrumbProps={{
                    links: [{ id: 0, name: 'Titles', to: '/titles' }],
                    text: [{ id: 0, name: '' }],
                    isFetching: pending
                }}
                searchProps={{
                    setPage: () => null,
                    searchFn: 'searchTitles',
                    value: searchValue,
                    clear: clearSearchValue,
                    set: setSearchValue
                }}
            />

            <Content>
                {() => (
                    <MainTable
                        entities={titles}
                        id='titles-table'
                        isFetching={pending}
                        totalPages={page?.totalPages}
                        onRowClick={onRowClick}
                        noResultsMessage='No results returned'
                        searchValue={searchValue}
                        pageControl={{ page: service.baseParams.page, setPage: () => null }}
                        entityLink='/titles'
                        tableActionsRenderer={(entity) => <ActionContainer create={isCreateAuth} />}
                        columns={headCells}
                        sortData={{
                            sortBy: orderBy,
                            sortDirection: order,
                            createSortHandler: sortFn
                        }}
                        fetchFn={fetchNextPage}
                    />
                )}
            </Content>
        </div>
    );
});

export default Titles;
