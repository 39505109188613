/**
 *  BATCH FILE CONSTS
 */
export const SET_BATCH_DATA = 'SET_BATCH_DATA';

export const CREATE_ENCRYPTION_WORK = 'CREATE_ENCRYPTION_WORK';

/**
 *  used to signal that the file has been POSTed and sent to QT for encrpytion
 */

export const CLEAR_ENCRYPTION_WORK = 'CLEAR_ENCRYPTION_WORK';

export const SET_FILE_PROCESSED = 'SET_FILE_PROCESSED';

/**
 * Signal used to set a file in a started state
 *
 */

export const SET_ENCRYPTION_STARTED = 'SET_ENCRYPTION_STARTED';

/**
 *  flag to set the file to completed state
 */

export const SET_ENCRYPTION_SUCCEEDED = 'SET_ENCRYPTION_SUCCEEDED';

/**
 *  flag to set the file to paused state
 */

export const SET_ENCRYPTION_PAUSED = 'SET_ENCRYPTION_PAUSED';

/**
 *  flag to update the files  progress state
 */

export const SET_ENCRYPTION_PROGRESS = 'SET_ENCRYPTION_PROGRESS';

/**
 *  flag to set the file to cancelled state
 */

export const SET_ENCRYPTION_CANCELLED = 'SET_ENCRYPTION_CANCELLED';

/**
 *  flag to set the file to failed state
 */

export const SET_ENCRYPTION_FAIL = 'SET_ENCRYPTION_FAIL';

/**
 *  used to set any files that are currently active in encryption
 */

export const SET_POST_BATCH_FILE = 'SET_POST_BATCH_FILE';

/**
 *  flag to set a error with any encryption file
 */
export const SET_ENCRYPTION_ERROR = 'SET_ENCRYPTION_ERROR';

/**
 * flag used to confirm the overwrite of a file
 */
export const CONFIRM_ENC_FILE_OVERWRITE = 'CONFIRM_ENC_FILE_OVERWRITE';

/***
 *  Flag used to add a file to an existing encryptionWork
 */
export const ADD_FILE_TO_EXISTING_WORK = 'ADD_FILE_TO_EXISTING_WORK';

/**
 * flag to remove a issue once resolved
 */

export const REMOVE_ENC_FILE_ISSUE = 'REMOVE_ENC_FILE_ISSUE';

/**
 * flag to set ACL in Encryption Work
 */
export const STORE_ENCRYPTED_ACL = 'STORE_ENCRYPTED_ACL';
