import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
//@ts-ignore
import { useDispatch } from 'react-redux';
import { removeEncryptionFile } from '@mediaseal-webui/actions';
import { BtnProps } from '..';

export const RemoveBtn = ({ file }: BtnProps) => {
    const dispatch = useDispatch();
    const handleRemoveClick = () => {
        console.log('Removing file');
        dispatch(removeEncryptionFile(file));
    };
    return (
        <Tooltip title='Remove Job' placement='top'>
            <IconButton
                className='delete-btn'
                onClick={() => {
                    handleRemoveClick();
                }}>
                <Delete fontSize='small' />
            </IconButton>
        </Tooltip>
    );
};
