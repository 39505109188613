import { CHANGE_FIELD, ADD_FIELD, REMOVE_FIELD, ADD_FORM_ERROR, REMOVE_FORM_ERROR, CLEAR_FORM, SUBMIT_FORM } from '@mediaseal-webui/constants';

const validateFields = async (fields, dispatch, getState) => {
    const reducer = async (acc, current) => {
        const { validation, value, name, required } = current; // current iteration during reduce
        const { passes, message } = await validation(getState)(value);
        if (!passes || (required && !value)) {
            const err = message ?? (required && !value) ? 'Field is required' : '';
            dispatch({ type: ADD_FORM_ERROR, payload: { name, message: err } });
            return acc.then((errors) => errors.concat(err));
        } else {
            dispatch({ type: REMOVE_FORM_ERROR, payload: name });
        }
        return acc;
    };
    const errors = await fields.reduce(reducer, Promise.resolve([]));
    return errors;
};

export const changeField =
    ({ name, value }) =>
    async (dispatch, getState) => {
        const field = getState().form.fields.find((field) => field.name === name);
        const { validation } = field;
        const { passes, message } = validation(getState)(value);
        if (!passes) {
            dispatch({ type: ADD_FORM_ERROR, payload: { name, message } });
        } else {
            dispatch({ type: REMOVE_FORM_ERROR, payload: name });
        }
        dispatch({ type: CHANGE_FIELD, payload: { name, value } });

        // if password, revalidate other password field
        const passwordFieldNames = ['editable-password', 'editable-confirmPassword', 'password', 'confirmPassword'];
        if (passwordFieldNames.includes(field.name)) {
            const passwordfields = getState().form.fields.filter(({ name }) => passwordFieldNames.includes(name) && field.name !== name);
            if (passwordfields?.length > 1) {
                await validateFields(passwordfields, dispatch, getState);
            }
        }
    };

export const addField = ({ name, validation, required, value }) => ({
    type: ADD_FIELD,
    payload: { name, validation, required, value }
});

export const removeField = (name) => ({ type: REMOVE_FIELD, payload: name });

export const clearForm = () => ({ type: CLEAR_FORM });

export const submitForm = (action) => async (dispatch, getState) => {
    const { fields } = getState().form;
    const errors = await validateFields(fields, dispatch, getState);
    if (errors.length <= 0) {
        dispatch({ type: SUBMIT_FORM });
        action();
    }
};
