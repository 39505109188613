import { fadeIn } from '@mediaseal-webui/components';
import styled, { css } from 'styled-components';

const nameAnimation = css`
    animation: ${fadeIn} 1.5s ease;
    animation-fill-mode: forwards;
`;

export const JobType = styled.h5`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        opacity: 0;
        ${nameAnimation};
        color: ${theme.palette.text.primary};
        font-size: 14px;
    `}
`;

export const Half = styled.div`
    ${({ theme }) => css`
        width: 50%;
        display: flex;
        padding: ${theme.spacing(1)}px;
        flex-direction: column;

        ${theme.breakpoints.down('sm')} {
            width: 100%;
        }
    `}
`;

export const MetaInfo = styled.div`
    ${({ theme }) => css`
        display: flex;
        width: 100%;
        opacity: 0;
        ${nameAnimation};
        margin-bottom: ${theme.spacing(2)}px;

        ${theme.breakpoints.down('sm')} {
            flex-direction: column;
        }
    `}
`;

export const InfoHeader = styled.h4`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        margin-bottom: ${theme.spacing(1)}px;
        color: ${theme.palette.text.primary};
        font-size: 20px;

        svg {
            margin-right: ${theme.spacing(1)}px;
        }
    `}
`;

export const InfoField = styled.p`
    ${({ theme }) => css`
        padding: ${theme.spacing(0.5)}px;
        height: 30px;
        white-space: nowrap;
        border-radius: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: ${theme.spacing(1)}px;
        background: ${theme.palette.divider};

        &:hover {
            cursor: default;
        }
    `}
`;
