import axios from 'axios';
import { msApi } from '@mediaseal-webui/api';
import { serializePermissions, checkSelectedPermissions } from '@mediaseal-webui/utils';
import { setUserPages, fetchUsers, fetchEncryptorUsers } from '../users';
import { stopFetching } from '@mediaseal-webui/actions';
import { setError, setEdit } from '../entity';
import {
    SET_PROFILE,
    SET_TOTAL_ENC_GROUPS,
    SET_FILTERED_ENC_GROUPS,
    FETCH_ENC_GROUPS,
    SET_TOTAL_DEC_GROUPS,
    SET_FILTERED_GROUPS,
    FETCH_GROUPS,
    FETCH_PERMISSIONS,
    SET_UNSERIALIZED_PERMISSIONS,
    SET_PERMISSION,
    REMOVE_PERMISSION,
    SET_GROUP_PAGE_SEARCH_VALUE,
    SET_SNACKBAR,
    SET_PERMISSIONS,
    SET_TOKEN_PAGE_SEARCH_VALUE
} from '@mediaseal-webui/constants';

/**
 * Fetch
 */
export const fetchGroups = (payload) => async (dispatch, getState) => {
    try {
        const { data } = await msApi.get(
            `recipientgroups/search/findAllContainingKeyword?keyword=${payload.keyword ? window.encodeURI(payload.keyword) : ''}&page=${payload.page}&size=${
                payload.size ? payload.size : 100
            }&sort=${payload.sort ? payload.sort : 'createDate,desc'}`
        );
        const { page, content } = data;
        const { totalPages, totalElements } = page;
        // const summaryIds = payload.filter ? getState().entity.summary.decryptorGroups?.map(({ id }) => id) : [];
        // const fetchedGroups = content.filter((user) => !summaryIds.find((id) => user.id === id));
        dispatch({
            type: payload.firstCall ? SET_FILTERED_GROUPS : FETCH_GROUPS,
            payload: content
        });
        dispatch(setGroupPages(totalPages));
        dispatch({ type: SET_TOTAL_DEC_GROUPS, payload: totalElements });
    } catch (error) {
        console.log(error);
        dispatch(setError(error));
    }
    dispatch(stopFetching());
};

export const fetchEncryptorGroups = (payload) => async (dispatch, getState) => {
    try {
        const { data } = await msApi.get(
            `groups/search/findAllContainingKeyword?keyword=${payload.keyword ? window.encodeURI(payload.keyword) : ''}&page=${payload.page}&size=${
                payload.size ? payload.size : 100
            }&sort=${payload.sort ? payload.sort : 'createDate,desc'}`
        );
        const { page, content } = data;
        const { totalPages, totalElements } = page;
        // const summaryIds = payload.filter ? getState().entity.summary.encryptorGroups.map(({ id }) => id) : [];
        // const fetchedGroups = content.filter((user) => !summaryIds.find((id) => user.id === id));
        dispatch({
            type: payload.firstCall ? SET_FILTERED_ENC_GROUPS : FETCH_ENC_GROUPS,
            payload: content
        });
        dispatch(setEncryptorGroupPages(totalPages));
        dispatch({ type: SET_TOTAL_ENC_GROUPS, payload: totalElements });
    } catch (error) {
        dispatch(setError(error));
    }
    dispatch(stopFetching());
};

export const fetchPermissions = () => async (dispatch) => {
    try {
        const { data } = await msApi.get('/permissions?size=100');
        const permssions = serializePermissions(data.content);

        dispatch({ type: FETCH_PERMISSIONS, payload: permssions });
        dispatch({ type: 'SET_UNSERIALIZED_PERMISSIONS', payload: data.content });
    } catch (error) {
        dispatch(setError(error));
    }
    dispatch(stopFetching());
};

export const fetchAndCheckPermissions = (id) => async (dispatch) => {
    try {
        const { data } = await msApi.get('/permissions?size=100');
        const grpData = await msApi.get(`/groups/${id}/permissions`);
        const groupPermissions = grpData.data.content;
        const permissions = checkSelectedPermissions(data.content, groupPermissions);
        const serPermissions = serializePermissions(data.content);
        dispatch({ type: FETCH_PERMISSIONS, payload: serPermissions });
        dispatch({ type: SET_UNSERIALIZED_PERMISSIONS, payload: permissions });
        // dispatch({ type: SET_UNSERIALIZED_PERMISSIONS, payload: permissions })
    } catch (error) {
        dispatch(setError(error));
    }
    dispatch(stopFetching());
};

export const fetchSpecificGroup = (id) => async (dispatch) => {
    try {
        const group = msApi.get(`/groups/${id}`);
        const recips = msApi.get('/recipients/search/findAllContainingKeyword?keyword=&page=0&size=100&sort=id,desc');
        const [groups, recipients] = await axios.all([group, recips]);

        dispatch({ type: SET_PROFILE, payload: groups.data });

        const { page, content } = recipients.data;
        const { totalPages, totalElements } = page;
        dispatch({ type: 'SET_RECIPIENTS', payload: content });
        dispatch({ type: 'SET_TOTAL_DEC_USER', payload: totalElements });
        dispatch(setUserPages(totalPages));

        dispatch(fetchDecUsersForEncGroup(id));
        dispatch(fetchEncUsersForGroup(id));
        dispatch(fetchDecGroupsForGroup(id));

        dispatch(
            fetchUsers({
                keyword: '',
                page: 0,
                filter: true,
                firstCall: true
            })
        );
        dispatch(
            fetchGroups({
                keyword: '',
                page: 0,
                filter: true,
                firstCall: true
            })
        );
        dispatch(
            fetchEncryptorUsers({
                keyword: '',
                page: 0,
                filter: true,
                firstCall: true
            })
        );
    } catch (error) {
        dispatch(setError(error));
    }
    dispatch(stopFetching());
};

export const fetchSpecificDecGroup = (id) => async (dispatch, getState) => {
    try {
        const group = msApi.get(`/recipientgroups/${id}`);
        const recips = msApi.get('/recipients/search/findAllContainingKeyword?keyword=&page=0&size=20&sort=id,desc');
        const [groups, recipients] = await axios.all([group, recips]);

        dispatch({ type: SET_PROFILE, payload: groups.data });

        const { page } = recipients.data;
        const { totalPages, totalElements } = page;
        dispatch({ type: 'SET_RECIPIENTS', payload: recipients.data.content });
        dispatch({ type: 'SET_TOTAL_DEC_USER', payload: totalElements });
        dispatch(setUserPages(totalPages));

        dispatch(fetchDecUsersForDecGroup(id));
    } catch (error) {
        dispatch(setError(error));
    }
    dispatch(stopFetching());
};

export const fetchDecUsersForDecGroup = (id) => async (dispatch, getState) => {
    try {
        const { data } = await msApi.get(`/recipientgroups/${id}/recipientCollection`);
        const decryptorUsers = data.content;
        const { profile } = getState().entity;
        const payload = {
            ...profile,
            decryptorUsers
        };
        dispatch({ type: SET_PROFILE, payload });
        dispatch(setEdit());
    } catch (error) {
        dispatch(setError(error));
    }
    dispatch(stopFetching());
};

export const fetchDecUsersForEncGroup = (id) => async (dispatch, getState) => {
    try {
        const { data } = await msApi.get(`/groups/${id}/allowedRecipients`);
        const decryptorUsers = data.content;
        const { profile } = getState().entity;
        const payload = {
            ...profile,
            decryptorUsers
        };
        dispatch({ type: SET_PROFILE, payload });
        dispatch(setEdit());
    } catch (error) {
        dispatch(setError(error));
    }
    dispatch(stopFetching());
};

export const fetchEncUsersForGroup = (id) => async (dispatch, getState) => {
    try {
        const { data } = await msApi.get(`/groups/${id}/userCollection`);
        const users = data.content;
        const { profile } = getState().entity;
        const payload = {
            ...profile,
            users
        };
        dispatch({ type: SET_PROFILE, payload });
        dispatch(setEdit());
    } catch (error) {
        dispatch(setError(error));
    }
    dispatch(stopFetching());
};

export const fetchDecGroupsForGroup = (id) => async (dispatch, getState) => {
    try {
        const { data } = await msApi.get(`/groups/${id}/allowedStationGroups`);
        const decryptorGroups = data.content;
        const { profile } = getState().entity;
        const payload = {
            ...profile,
            decryptorGroups
        };
        dispatch({ type: SET_PROFILE, payload });
        dispatch(setEdit());
    } catch (error) {
        dispatch(setError(error));
    }
    dispatch(stopFetching());
};

/**
 * Create
 */
export const postEncGroup = (history) => async (dispatch, getState) => {
    try {
        const { create } = getState().entity;
        const { name, description, decryptorUsers, decryptorGroups, users, permissions } = create;
        // const { unserializedPermissions } = getState().groups
        const activePermissions = permissions && permissions.filter((perm) => (perm.checked ? perm : null));
        const permissionSet = activePermissions ? activePermissions.map((permission) => `/api/permissions/${permission.id}`) : [];
        const content = {
            active: true,
            allowedDepartments: [],
            allowedRecipients: decryptorUsers?.length > 0 ? decryptorUsers.map((recipient) => `/api/recipients/${recipient.id}`) : [],
            allowedStationGroups: decryptorGroups?.length > 0 ? decryptorGroups.map((group) => `/api/recipientgroups/${group.id}`) : [],
            description: description !== '' ? description : '',
            name,
            permissions: permissionSet,
            userCollection: users?.length > 0 ? users.map((user) => `/api/users/${user.id}`) : []
        };
        await msApi.post('/groups', content);
        history.push('/groups/encryptor');
        dispatch({
            type: SET_SNACKBAR,
            payload: {
                success: true,
                message: `Successfully created group: ${name}`
            }
        });
    } catch (error) {
        dispatch(setError(error));
    }
};

export const postDecGroup = (history) => async (dispatch, getState) => {
    try {
        const { name, description, decryptorUsers } = getState().entity.create;
        const content = {
            active: true,
            description,
            name,
            recipientCollection: decryptorUsers?.map((user) => `/api/users/${user.id}`) ?? []
        };
        await msApi.post('/recipientgroups', content);
        history.push('/groups/decryptor');
        dispatch({
            type: SET_SNACKBAR,
            payload: {
                success: true,
                message: `Successfully created group: ${name}`
            }
        });
    } catch (error) {
        dispatch(setError(error));
    }
};

/**
 * Update
 */
const isAdminPermission = (perm) => perm.permissionName.includes('Administer');
export const updateGroup = (id) => async (dispatch, getState) => {
    try {
        const { edit } = getState().entity;
        const { name, description, decryptorUsers, decryptorGroups, users, createDate, updateDate, active, permissions } = edit;
        const userIds = users ? users.map((user) => `/api/users/${user.id}`) : null;
        const decUserIds = decryptorUsers ? decryptorUsers.map((user) => `/api/recipients/${user.id}`) : null;
        const decGroupsIds = decryptorGroups ? decryptorGroups.map((group) => `/api/groups/${group.id}`) : null;
        // transfer all checked perms
        const permissionSet = permissions?.length > 0 ? permissions.map((permission) => `/api/permissions/${permission.id}`) : [];
        const content = {
            active: true,
            allowedDepartments: [],
            allowedRecipients: decUserIds,
            allowedStationGroups: decGroupsIds,
            createDate,
            description,
            id,
            active,
            name,
            permissions: permissionSet,
            updateDate,
            userCollection: userIds
        };
        await msApi.put(`/groups/${id}`, content);
        dispatch({
            type: SET_SNACKBAR,
            payload: { success: true, message: `Successfully updated ${name}` }
        });
    } catch (error) {
        // error.message = 'You do not have permission to edit this group'
        console.log(error);
        dispatch(setError(error));
    }
    dispatch(stopFetching());
    dispatch(fetchSpecificGroup(id));
};

export const updateEncryptorGroupAndRedirect = (id, history) => async (dispatch) => {
    await dispatch(updateGroup(id));
    history.push('/groups/encryptor');
};

export const updateDecGroup = (id) => async (dispatch, getState) => {
    try {
        const { edit } = getState().entity;
        const { name, description, decryptorUsers, createDate, updateDate, active } = edit;
        const usersIds = decryptorUsers ? decryptorUsers.map((user) => `/api/recipients/${user.id}`) : [];
        const content = {
            active: true,
            createDate,
            description,
            id,
            name,
            active,
            recipientCollection: [...usersIds],
            updateDate
        };
        await msApi.put(`/recipientgroups/${id}`, content);
        dispatch({
            type: SET_SNACKBAR,
            payload: {
                success: true,
                message: `Successfully updated ${content.name}`
            }
        });
    } catch (error) {
        error.message = 'You do not have permission to edit this group';
        dispatch(setError(error));
    }
    dispatch(stopFetching());
    dispatch(fetchSpecificDecGroup(id));
};

export const updateDecGroupAndRedirect = (id, history) => async (dispatch) => {
    await dispatch(updateDecGroup(id));
    history.push('/groups/decryptor');
};

/**
 * Permissions
 */
export const transferCurrentGroupPermissions = () => (dispatch, getState) => {
    const { profile } = getState().entity;
    const { permissions } = profile;
    dispatch({
        type: 'TRANSFER_CURRENT_PERMISSIONS_TO_GROUPS',
        payload: permissions
    });
};

export const setPermissionStatus = (payload) => (dispatch, getState) => {
    // set for state to display checked / not checked
    dispatch({ type: 'SET_PERMISSION_STATUS', payload });
    const { unserializedPermissions } = getState().groups;
    const permissions = serializePermissions(unserializedPermissions);
    dispatch({ type: 'FETCH_PERMISSIONS', payload: permissions });
};

export const removeGroupPermissions = (permission) => ({
    type: REMOVE_PERMISSION,
    payload: permission
});
export const setGroupPermissions = (permission) => ({
    type: SET_PERMISSION,
    payload: permission
});

export const clearPermissionData = () => ({ type: 'CLEAR_PERMISSION_DATA' });

/**
 * Pages
 */
export const setGroupPages = (pages) => ({
    type: 'SET_GROUP_PAGE_COUNT',
    payload: pages
});

export const setEncryptorGroupPages = (pages) => ({
    type: 'SET_ENCRYPTOR_GROUP_PAGE_COUNT',
    payload: pages
});

/**
 * Search
 */
export const setGroupPageSearchValue = (value) => ({
    type: SET_GROUP_PAGE_SEARCH_VALUE,
    payload: value
});
export const clearGroupPageSearchValue = () => ({
    type: 'CLEAR_GROUP_PAGE_SEARCH_VALUE'
});

export const setTokenPageSearchValue = (value) => ({
    type: SET_TOKEN_PAGE_SEARCH_VALUE,
    payload: value
});
export const clearTokenPageSearchValue = () => ({
    type: 'CLEAR_TOKEN_PAGE_SEARCH_VALUE'
});

/**
 * Filter
 */
export const filterUsersSelected = () => (dispatch, getState) => {
    const { users } = getState().entity.create;
    users?.length > 0 && users.forEach((user) => dispatch({ type: 'REMOVE_SELECTED_USER', payload: user }));
};

export const filterCurrentDecUsers = () => (dispatch, getState) => {
    const { profile } = getState().entity;
    const { decryptorUsers } = profile;
    decryptorUsers?.length > 0 && decryptorUsers.forEach((user) => dispatch({ type: 'REMOVE_FILTERED_DEC_USERS_FROM_GROUP', payload: user }));
};

export const filterCurrentDecUsersForDecGroup = () => (dispatch, getState) => {
    const { update } = getState().entity;
    const { decryptorUsers } = update;
    decryptorUsers?.length > 0 &&
        decryptorUsers.forEach((user) =>
            dispatch({
                type: 'REMOVE_FILTERED_DEC_USERS_FROM_DEC_GROUP',
                payload: user
            })
        );
};

export const filterCurrentDecGroups = () => (dispatch, getState) => {
    const { profile } = getState().entity;
    const { decryptorGroups } = profile;
    decryptorGroups?.length > 0 && decryptorGroups.forEach((group) => dispatch({ type: 'REMOVE_FILTERED_DEC_GROUP_FROM_GROUP', payload: group }));
};

export const filterRecipientsSelected = () => (dispatch, getState) => {
    const { recipients } = getState().entity.create;
    recipients?.length > 0 && recipients.forEach((user) => dispatch({ type: 'REMOVE_RECIPIENT', payload: user }));
};

export const filterRecipientGroupsSelected = () => (dispatch, getState) => {
    const { recipientGroups } = getState().entity.create;
    recipientGroups && recipientGroups.forEach((group) => dispatch({ type: 'REMOVE_SELECTED_GROUPS', payload: group }));
};

export const filterUsersAndGroups = () => (dispatch, getState) => {
    const { users, decryptorUsers, decryptorGroups, groups } = getState().entity.profile;
    users?.length > 0 && users.forEach((user) => dispatch({ type: 'FILTER_USERS_AND_GROUPS', payload: user }));
    decryptorUsers?.length > 0 && decryptorUsers.forEach((user) => dispatch({ type: 'FILTER_USERS_AND_GROUPS', payload: user }));
    decryptorGroups?.length > 0 && decryptorGroups.forEach((group) => dispatch({ type: 'FILTER_USERS_AND_GROUPS', payload: group }));
    groups?.length > 0 && groups.forEach((group) => dispatch({ type: 'FILTER_USERS_AND_GROUPS', payload: group }));
};

/**
 * Clear
 */
export const clearGroups = () => ({ type: 'CLEAR_GROUPS' });

/**
 * Misc
 */
export const removeSelectedGroups = (group) => ({
    type: 'REMOVE_SELECTED_GROUPS',
    payload: group
});

export const removeEncryptorGroup = (group) => ({
    type: 'REMOVE_ENC_GROUP',
    payload: group
});
