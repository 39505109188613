import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel, FormGroup } from '@material-ui/core';
import TabArea from '../TabArea';
import { TextInput } from '@mediaseal-webui/components';
import TextArea from '../../../utility-components/TextArea';
import { useDispatch } from 'react-redux';
import { setCreate } from '../../../../actions/entity';
import { useEntityCreation } from '@mediaseal-webui/hooks';
import useStyles from './CreateForm.styles';

const CreateForm = ({ handleSubmit }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { genericNameValidation } = useEntityCreation(['name']);

    const handleChange = (name) => (value) => dispatch(setCreate({ [name]: value }));
    const handleDesc = (event) => dispatch(setCreate({ description: event.target.value }));
    return (
        <form id='createTitleForm' className={classes.form} data-testid='create-title-form'>
            <FormGroup>
                <FormLabel className={classes.label}>Name</FormLabel>
                <TextInput
                    id='title-name'
                    name='name'
                    validation={genericNameValidation}
                    setterFn={handleChange('name')}
                    tooltipText='Please enter a name between 2 and 64 characters'
                />
            </FormGroup>

            <FormGroup className={classes.margin}>
                <FormLabel className={classes.label}>Description</FormLabel>
                <TextArea
                    aria-label='title description'
                    id='description'
                    placeholder='Enter description for the title..'
                    name='description'
                    onChange={handleDesc}
                    className={classes.formElement}
                />
            </FormGroup>
            <FormGroup>
                <div className={classes.tabSection}>
                    <TabArea />
                </div>
            </FormGroup>
        </form>
    );
};

CreateForm.propTypes = {
    handleSubmit: PropTypes.func
};

export default CreateForm;
