import Axios from 'axios';
import { msApi } from '@mediaseal-webui/api';
import { stopFetching } from '../misc';
import { setError, setEdit } from '../entity';
import {
    SET_PROFILE,
    FETCH_TITLES,
    FETCH_TITLES_FILTERED,
    SET_TITLES_PAGES,
    SET_TOTAL_TITLE_ELEMENTS,
    REMOVE_SELECTED_USER,
    REMOVE_ENC_GROUP,
    SET_TITLE_PAGE_SEARCH_VALUE,
    CLEAR_TITLES_PAGE_SEARCH_VALUE,
    CLEAR_TITLES,
    CLEAR_TITLE_DATA,
    REMOVE_GROUP_EDIT_TITLE_TABLE,
    REMOVE_USER_FROM_CREATE_TITLE_TABLE,
    REMOVE_ENC_USER_FROM_EDIT_TITLE_TABLE,
    SET_TOTAL_TITLE_JOB_PAGES,
    DELETE_SUCCESS,
    SET_SNACKBAR
} from '@mediaseal-webui/constants';
import { fetchEncryptorUsers, fetchEncryptorGroups } from '@mediaseal-webui/actions';
import { fetchTokens } from 'reducers/tokens/tokens';
import { isKeycloakActive } from '@mediaseal-webui/hooks/src/isKeycloakActive';

/**
 * Fetch
 */
export const fetchTitles = (payload) => async (dispatch, getState) => {
    try {
        const { data } = await msApi.get(
            `titles/search/findAllContainingKeyword?keyword=${payload.keyword || ''}&page=${payload.page || 0}&size=100&sort=${payload.sort}&projection=compact`
        );
        const { content } = data;
        const { page } = data;
        const { totalPages } = page;
        const { totalElements } = page;

        dispatch({ type: payload.firstCall ? FETCH_TITLES_FILTERED : FETCH_TITLES, payload: content });
        dispatch({ type: SET_TITLES_PAGES, payload: totalPages });
        dispatch({ type: SET_TOTAL_TITLE_ELEMENTS, payload: totalElements });
    } catch (error) {
        dispatch(setError(error));
    }
    dispatch(stopFetching());
};

export const fetchAllTitleData = (id) => async (dispatch, getState) => {
    try {
        const titleReq = msApi.get(`/titles/${id}`);
        const titleUsers = msApi.get(`/titles/${id}/allowedUsers`);
        const titleGroups = msApi.get(`/titles/${id}/allowedGroups`);
        const titleTokens = isKeycloakActive() && msApi.get(`/titles/${id}/allowedTokens`);
        const titlejobs = msApi.get(`/jobs/search/findByTitleId?titleId=${id}&isArchived=false&isTemplate=false&sort=id,desc`);
        const [title, users, groups, jobs, tokens] = await Axios.all([titleReq, titleUsers, titleGroups, titlejobs, titleTokens]);
        const body = {
            ...title.data,
            users: users.data.content,
            encryptorGroups: groups.data.content,
            jobs: jobs.data.content,
            ...(isKeycloakActive() && { allowedTokens: tokens.data.content })
        };
        dispatch({ type: SET_PROFILE, payload: body });
        dispatch(setEdit());
        const args = { keyword: '', sort: 'id,asc', page: 0, firstCall: true, filter: true };
        dispatch(fetchEncryptorUsers(args));
        dispatch(fetchEncryptorGroups(args));
        isKeycloakActive() && dispatch(fetchTokens(args));
    } catch (error) {
        dispatch(setError(error));
    }
    dispatch(stopFetching());
};

export const fetchTitleJobs =
    ({ id, page, sort, firstCall }) =>
    async (dispatch, getState) => {
        try {
            const { data } = await msApi.get(`/jobs/search/findByTitleId?titleId=${id}&page=${page}&sort=${sort}&isArchived=false&isTemplate=false`);
            const { content } = data;
            const { totalPages } = data.page;
            const { profile } = getState().entity;
            dispatch({ type: SET_TOTAL_TITLE_JOB_PAGES, payload: totalPages });
            dispatch({
                type: SET_PROFILE,
                payload: firstCall
                    ? {
                          ...profile,
                          jobs: [...content]
                      }
                    : {
                          ...profile,
                          jobs: [...profile.jobs, ...content]
                      }
            });
        } catch (error) {
            dispatch(setError(error));
        }
        dispatch(stopFetching());
    };

/**
 * Delete
 */
export const deleteTitle = (name, id, history) => async (dispatch, getState) => {
    try {
        await msApi.delete(`/titles/${id}`);
        dispatch({ type: DELETE_SUCCESS, payload: `Successfully deleted ${name}` });
        history.push('/titles');
    } catch (error) {
        console.log('ERROR', error.response);
        dispatch(setError(error));
    }
};

/**
 * Update
 */
export const postTitle = (history) => async (dispatch, getState) => {
    const { create } = getState().entity;
    const { name, users, encryptorGroups, description } = create;
    const body = {
        name,
        allowedUsers: users ? users.map((user) => `/api/users/${user.id}`) : null,
        allowedGroups: encryptorGroups ? encryptorGroups.map((group) => `/api/groups/${group.id}`) : null,
        active: true,
        description: description || '',
        versionNumber: '',
        workOrderNumber: ''
    };
    try {
        await msApi.post('/titles', body);
        history.push('/titles');
        dispatch({ type: SET_SNACKBAR, payload: { success: true, message: `Successfully created title: ${name}` } });
    } catch (error) {
        dispatch(setError(error));
    }
};

export const updateTitle = (id, params) => async (dispatch, getState) => {
    const { edit, profile } = getState().entity;
    const { name, description, users, encryptorGroups, active, allowedTokens } = edit;
    const body = {
        name: name === description ? profile.name : name,
        description: description,
        allowedUsers: users ? users.map((user) => `/api/users/${user.id}`) : null,
        allowedGroups: encryptorGroups ? encryptorGroups.map((group) => `/api/groups/${group.id}`) : null,
        ...(isKeycloakActive() && { allowedTokens: allowedTokens ? allowedTokens.map((token) => `/api/tokens/${token.id}`) : null }),
        active
    };
    try {
        await msApi.patch(`/titles/${id}`, params ? params : body);
        dispatch({ type: SET_SNACKBAR, payload: { success: true, message: `Successfully updated title: ${name}` } });
    } catch (error) {
        dispatch(setError(error));
    }

    dispatch(stopFetching());
    dispatch(fetchAllTitleData(id));
};

export const updateTitleAndRedirect = (id, history) => async (dispatch) => {
    await dispatch(updateTitle(id));
    history.push('/titles');
};
/**
 * Filter
 */
export const filterTitleUsers = () => (dispatch, getState) => {
    const { summary } = getState().entity;
    const { users } = summary;
    users?.length > 0 && users.forEach((user) => dispatch({ type: REMOVE_SELECTED_USER, payload: user }));
};

export const filterTitleGroups = () => (dispatch, getState) => {
    const { summary } = getState().entity;
    const { groups } = summary;
    groups?.length > 0 && groups.forEach((group) => dispatch({ type: REMOVE_ENC_GROUP, payload: group }));
};

/**
 * Search
 */
export const setTitlePageSearchValue = (value) => ({ type: SET_TITLE_PAGE_SEARCH_VALUE, payload: value });
export const clearTitlesPageSearchValue = () => ({ type: CLEAR_TITLES_PAGE_SEARCH_VALUE });

/**
 * Misc
 */
export const clearTitles = () => ({ type: CLEAR_TITLES });
export const clearTitleData = () => ({ type: CLEAR_TITLE_DATA });

export const removeEditTitleEncGroup = (group) => ({ type: REMOVE_GROUP_EDIT_TITLE_TABLE, payload: group });
export const removeUserFromCreateTitleTable = (user) => ({ type: REMOVE_USER_FROM_CREATE_TITLE_TABLE, payload: user });

export const removeUserFromList = (user) => ({ type: REMOVE_ENC_USER_FROM_EDIT_TITLE_TABLE, payload: user });
